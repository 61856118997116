import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const MobilePhoneWithApp: FC<IProps> = ({ className = '', id = '', fill, stroke }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }mobile-phone-with-app` }
    id={ id }
    width={ 229 }
    height={ 229 }
    fill={ fill || 'white' }
    stroke={ stroke || '#001C20' }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M166.436 54.4357C167.986 46.4163 162.734 38.6468 154.714 37.0965L99.7028 29.3635C91.6834 27.8132 83.9139 33.0654 82.3636 41.0849L48.5455 171.853C46.9951 179.873 52.2475 187.642 60.267 189.192L120.102 200C128.122 201.55 135.891 196.298 137.442 188.278L166.436 54.4357Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1.17515' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M162.251 53.1855C163.704 45.6649 160.717 41.7112 153.424 40.3014L100.924 32.7521C93.6326 31.3425 87.2293 33.5734 85.7755 41.0939L52.9217 169.752C51.4678 177.273 52.5665 181.265 59.8585 182.674L118.588 193.087C125.88 194.497 132.579 191.392 134.033 183.871L162.251 53.1855Z' stroke='#5CC87F' strokeWidth='1.17451' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M137.369 43.1954C137.582 41.6316 136.486 40.189 134.922 39.9757L117.059 37.5402C115.495 37.327 114.052 38.4235 113.839 39.9873C113.626 41.5511 114.723 42.9938 116.286 43.207L134.149 45.6425C135.714 45.8558 137.156 44.7592 137.369 43.1954Z' stroke='#5CC87F' strokeWidth='0.896081' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M159.1 55.2709C159.412 53.3108 158.072 51.4665 156.112 51.1551L91.4027 42.5098C89.4423 42.1983 87.5972 43.5367 87.2858 45.4968L79.8037 76.7629C79.4923 78.7231 80.8318 80.5674 82.7922 80.8788L147.692 90.5449C149.65 90.856 151.495 89.5177 151.807 87.558L159.1 55.2709Z' stroke='#5CC87F' strokeWidth='0.795386' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M111.703 92.5033C111.991 90.6868 110.751 88.9785 108.934 88.6902L81.1464 84.2801C79.3302 83.9919 77.6215 85.2321 77.3332 87.0486C77.0449 88.8652 78.2856 90.5735 80.1019 90.8617L107.89 95.2718C109.706 95.5601 111.415 94.3198 111.703 92.5033Z' stroke='#5CC87F' strokeWidth='0.881241' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M149.153 98.3713C149.441 96.5548 148.2 94.8465 146.384 94.5582L118.596 90.1481C116.78 89.8599 115.071 91.1001 114.783 92.9166C114.494 94.7332 115.735 96.4415 117.551 96.7297L145.339 101.14C147.156 101.428 148.864 100.188 149.153 98.3713Z' stroke='#5CC87F' strokeWidth='0.881241' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M139.491 116.248C139.779 114.431 138.539 112.723 136.723 112.435L106.22 107.594C104.403 107.306 102.695 108.546 102.406 110.362C102.118 112.179 103.359 113.887 105.175 114.175L135.679 119.016C137.494 119.305 139.203 118.064 139.491 116.248Z' stroke='#5CC87F' strokeWidth='0.871065' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M135.214 150.854C135.528 149.041 134.312 147.316 132.5 147.002L71.0058 136.349C69.1937 136.035 67.4676 137.251 67.1537 139.064C66.8398 140.876 68.0562 142.602 69.8683 142.916L131.363 153.568C133.175 153.882 134.901 152.666 135.214 150.854Z' stroke='#5CC87F' strokeWidth='0.733735' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M146.527 108.673C146.835 106.966 145.676 105.326 143.939 105.012L78.6211 94.8272C76.8848 94.5136 75.2248 95.645 74.9165 97.3516L71.8686 108.832C71.5602 110.54 72.7195 112.18 74.4558 112.494L140.205 123.309C141.941 123.623 143.602 122.492 143.91 120.785L146.527 108.673Z' stroke='#5CC87F' strokeWidth='0.897356' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M141.305 133.368C141.857 130.623 140.878 128.108 139.12 127.754L72.7561 117.016C70.9978 116.662 69.1219 118.603 68.5699 121.347L63.8454 139.888C63.2931 142.634 64.2722 145.15 66.0305 145.503L132.756 157.209C134.514 157.563 136.39 155.623 136.942 152.878L141.305 133.368Z' stroke='#5CC87F' strokeWidth='0.858318' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M133.245 167.255C133.817 164.514 132.856 161.992 131.1 161.626L64.8144 150.416C63.0587 150.049 61.169 151.977 60.5975 154.717L58.002 164.609L130.836 177.678L133.245 167.255Z' stroke='#5CC87F' strokeWidth='0.858318' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M97.4126 103.019C97.5331 102.233 96.9925 101.497 96.2066 101.376L80.3758 98.9479C79.5897 98.8273 78.8536 99.3674 78.7331 100.153L76.8576 107.769C76.7371 108.555 77.2774 109.291 78.0635 109.412L93.8943 111.84C94.6802 111.961 95.4165 111.421 95.5371 110.635L97.4126 103.019Z' stroke='#5CC87F' strokeWidth='0.895981' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M149.725 80.2837C149.807 79.726 149.412 79.2056 148.844 79.1223L137.408 77.4443C136.84 77.361 136.312 77.7461 136.23 78.3038L134.927 83.7106C134.845 84.2683 135.24 84.7887 135.808 84.872L147.244 86.55C147.812 86.6333 148.34 86.2482 148.422 85.6905L149.725 80.2837Z' stroke='#5CC87F' strokeWidth='0.895063' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M98.1691 128.395L76.4544 125L75.1521 130.359L96.8663 133.754L98.1691 128.395Z' stroke='#5CC87F' strokeWidth='0.857466' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M54.696 164.354L134.676 178.482' stroke='#5CC87F' strokeWidth='1.17514' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M65.1702 171.455C65.3079 170.586 64.7136 169.768 63.8441 169.63L60.693 169.131C59.8234 168.994 59.0056 169.588 58.8679 170.458L57.7426 174.966C57.6049 175.836 58.1991 176.653 59.0687 176.791L62.2199 177.29C63.0894 177.428 63.9072 176.833 64.0449 175.964L65.1702 171.455Z' stroke='#5CC87F' strokeWidth='1.16581' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M85.7388 175.131C85.8765 174.261 85.2823 173.443 84.4127 173.306L81.2616 172.807C80.392 172.669 79.5742 173.263 79.4365 174.133L78.3112 178.641C78.1735 179.511 78.7677 180.329 79.6373 180.467L82.7885 180.965C83.658 181.103 84.4758 180.509 84.6135 179.639L85.7388 175.131Z' stroke='#5CC87F' strokeWidth='1.16581' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M107.319 179.065C107.457 178.195 106.863 177.378 105.993 177.24L102.842 176.741C101.972 176.603 101.155 177.198 101.017 178.067L99.8915 182.576C99.7538 183.445 100.348 184.263 101.218 184.401L104.369 184.9C105.238 185.037 106.056 184.443 106.194 183.573L107.319 179.065Z' stroke='#5CC87F' strokeWidth='1.16581' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M127.261 182.5C127.399 181.63 126.805 180.812 125.935 180.675L122.784 180.176C121.914 180.038 121.097 180.633 120.959 181.502L119.834 186.011C119.696 186.88 120.29 187.698 121.16 187.836L124.311 188.335C125.181 188.472 125.998 187.878 126.136 187.008L127.261 182.5Z' stroke='#5CC87F' strokeWidth='1.16581' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M131.085 47.8964C131.085 47.8964 113.7 61.7012 111.233 67.9512C108.766 74.2013 115.998 85.6875 115.998 85.6875L131.303 88.1088C131.303 88.1088 124.34 75.8739 126.256 69.4948C128.17 63.1157 142.959 49.4306 142.959 49.4306L131.085 47.8964Z' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M118.466 59.3971C119.535 65.2383 122.329 70.6325 126.208 75.2281' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M146.816 61.9585C147.235 62.0517 147.987 60.2766 148.495 57.9937C149.003 55.7107 149.075 53.7844 148.656 53.6912C148.237 53.598 147.485 55.3731 146.977 57.656C146.469 59.9389 146.397 61.8652 146.816 61.9585Z' fill='#001C20' stroke='#5CC87F' strokeWidth='0.476176' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M153.669 54.3649C153.937 54.6364 151.258 56.8471 151.16 58.4586C151.055 60.1902 152.834 62.5763 152.552 62.72C152.083 62.9605 149.067 59.9768 149.266 58.3649C149.503 56.4497 153.128 53.8197 153.669 54.3649Z' fill='#001C20' stroke='#5CC87F' strokeWidth='0.481008' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M134.169 140.339C135.367 140.339 136.339 139.367 136.339 138.169C136.339 136.971 135.367 136 134.169 136C132.971 136 132 136.971 132 138.169C132 139.367 132.971 140.339 134.169 140.339Z' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M133.708 44.526C134.718 44.526 135.537 43.7071 135.537 42.6969C135.537 41.6867 134.718 40.8678 133.708 40.8678C132.698 40.8678 131.879 41.6867 131.879 42.6969C131.879 43.7071 132.698 44.526 133.708 44.526Z' stroke='#5CC87F' strokeWidth='0.756917' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.8464 131.516L96.4007 135.777' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M104.738 104.173L139.648 109.775' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M62.7949 160.452L89.3492 164.713' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M99.145 60.9002C99.3365 59.8528 98.6417 58.8472 97.5945 58.6557L91.1115 57.9886C90.0643 57.7971 89.0587 58.4919 88.8671 59.5393L87.8944 63.5461C87.703 64.5931 88.3977 65.5987 89.4449 65.7902L95.8505 66.7102C96.8977 66.9017 97.9033 66.2069 98.0947 65.16L99.145 60.9002Z' fill='#001C20' stroke='#5CC87F' strokeWidth='0.612702' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.9615 65.0636C93.8628 65.2317 94.8275 64.1135 95.1162 62.5659C95.4049 61.0184 94.9082 59.6275 94.0069 59.4594C93.1056 59.2913 92.141 60.4095 91.8523 61.9571C91.5636 63.5046 92.0602 64.8955 92.9615 65.0636Z' fill='#001C20' stroke='#5CC87F' strokeWidth='0.609332' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.2397 61.1001L98.7752 61.5198' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M88.6996 60.4863L92.007 60.8506' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.9211 63.5913L98.2301 64.0485' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M88.3607 63.0357L91.5121 63.368' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M101.967 62.2344C102.34 63.2241 102.084 64.1377 101.305 64.9893L101.967 62.2344Z' fill='#001C20' />
    <path d='M101.967 62.2344C102.34 63.2241 102.084 64.1377 101.305 64.9893' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M103.478 61.9105C103.948 62.8468 103.55 64.8522 102.542 65.7819L103.478 61.9105Z' fill='#001C20' />
    <path d='M103.478 61.9105C103.948 62.8468 103.55 64.8522 102.542 65.7819' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M104.913 61.3462C105.515 62.4785 104.836 65.6053 103.605 66.5599L104.913 61.3462Z' fill='#001C20' />
    <path d='M104.913 61.3462C105.515 62.4785 104.836 65.6053 103.605 66.5599' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M106.551 60.6976C107.405 62.0653 106.354 66.2876 104.757 67.4451L106.551 60.6976Z' fill='#001C20' />
    <path d='M106.551 60.6976C107.405 62.0653 106.354 66.2876 104.757 67.4451' stroke='#5CC87F' strokeWidth='0.613022' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M48.4709 173.051C48.4709 173.051 49.9997 181.561 55.5848 184.025C61.6305 186.693 89.4915 191.019 89.4915 191.019C89.4915 191.019 117.493 196.967 124.44 196.799C134.064 196.566 138.291 184.182 138.291 184.182' stroke='#5CC87F' strokeWidth='1.17514' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M127.049 174.072L128.552 173.082L127.708 171.335' stroke='#5CC87F' strokeWidth='1.17514' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M91.6264 100.814C91.6264 100.814 87.1478 104.288 86.0747 105.878C85.0016 107.469 85.4928 110.375 85.4928 110.375L89.6638 111.027C89.6638 111.027 89.0444 108.023 89.7234 106.727C90.5745 105.104 94.6859 101.293 94.6859 101.293L91.6264 100.814Z' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.8904 125.27C73.1102 124.072 71.1894 124.636 70.9945 125.726C70.7751 126.952 73.5279 126.185 73.0592 127.981C72.8804 128.667 72.3065 128.875 71.6119 128.868C70.9118 128.86 70.2229 128.205 70.2229 128.205' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M72.9288 123.734L71.3733 129.735' stroke='#5CC87F' strokeWidth='0.897678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M129.193 69.2464C128.974 69.1486 130.033 68.1753 130.165 68.1091' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M142.026 71.713C142.013 71.685 142.044 71.6287 142.044 71.6905' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M148.784 71.9414C149.068 72.1015 149.324 71.5877 149.396 71.372' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M131.904 76.6588C131.779 76.6225 131.878 77.2749 131.896 77.34C132.005 77.739 132.269 77.9773 132.617 78.176' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M132.913 80.124C132.9 80.1438 132.909 80.1358 132.876 80.1358' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M119.26 80.5992C119.077 80.5925 119.346 80.1918 119.384 80.1361C119.705 79.655 119.947 79.4439 120.422 79.1271' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M122.017 77.9948C122 78.0105 122.012 78.0039 121.978 78.0039' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M127.953 57.1504C127.937 57.1042 127.975 57.1302 127.975 57.1009' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M129.646 55.7657C129.652 55.7115 129.722 55.7091 129.763 55.6882' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.332 72.7514C105.265 72.7567 105.251 72.7838 105.315 72.7838' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.753 75.844C105.721 76.184 106.059 76.5676 106.383 76.6486' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.846 79.3542C107.854 79.3936 107.836 79.4146 107.881 79.4146' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.9494 38.74C92.6425 38.7507 93.0318 38.2408 93.0669 38.1814C93.3533 37.7039 94.1457 37.2804 94.6354 37.1171' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M96.4448 36.5762C96.5085 36.5453 96.4742 36.5673 96.5403 36.5009' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.9194 48.6844C91.1855 48.1943 92.3182 47.5714 92.7678 47.1967' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.4456 49.4334C91.5761 49.1135 92.0951 48.8774 92.3506 48.6926C92.7472 48.406 93.0777 48.0898 93.5029 47.8346' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M86.0667 72.5745C86.0226 72.5724 85.9973 72.5959 86.0504 72.5959' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.5173 77.838C87.4578 77.8491 88.0241 78.2381 88.0608 78.2586C88.6508 78.5889 89.2376 78.9669 89.9239 79.0431' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.604 52.3903C108.581 52.3701 108.558 52.3451 108.604 52.3451' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M99.0974 55.4323C99.1945 55.4438 99.3251 55.276 99.3871 55.214' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M114.659 67.9521C114.663 68.311 114.766 68.5708 114.905 68.8944' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M130.528 41.9274C130.489 41.9183 130.452 41.8938 130.452 41.9421' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M118.162 40.6466C118.15 40.477 118.559 40.2787 118.687 40.2015' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M119.467 41.175C119.441 41.1805 119.474 41.1781 119.486 41.1781' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M155.503 43.4211C155.519 43.4153 155.513 43.4186 155.483 43.4186' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M156.886 44.6267C156.857 44.6327 157.073 44.7876 157.17 44.8971C157.417 45.1742 157.623 45.5445 157.725 45.8994' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M158.016 47.746C158.025 47.746 157.999 47.746 157.989 47.746' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M147.245 45.3649C147.254 45.3432 147.326 45.5281 147.464 45.8725C147.6 46.2123 147.738 46.4848 147.965 46.7694' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M147.976 45.0701C147.946 45.0205 148 45.1839 148.019 45.239C148.066 45.3816 148.119 45.5226 148.177 45.6611C148.367 46.1133 148.644 46.5727 148.916 46.9799' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M140.852 96.4405C140.707 96.4087 140.738 96.4144 140.902 96.4144' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.793 97.4394C110.736 97.4655 110.713 97.4549 110.669 97.4981' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M111.924 98.1759C111.961 98.1791 111.996 98.1791 112.033 98.1791' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M100.233 105.027C100.212 104.681 100.393 104.377 100.625 104.027' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M100.032 103.809C99.8411 103.822 100.128 103.463 100.159 103.418' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.4236 88.8764C93.4081 88.8381 93.4089 88.8593 93.4505 88.8177' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.959 90.6564C102.862 90.6441 102.813 90.6139 102.813 90.7192' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.632 111.891C111.104 111.78 111.76 111.954 112.247 111.954' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M113.495 112.442C113.555 112.46 113.571 112.491 113.611 112.491' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M135.674 116.769C135.625 116.758 135.649 116.76 135.6 116.76' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M136.973 115.775C136.959 115.745 136.964 115.763 136.964 115.72' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M142.42 112.333C142.395 112.024 143.08 111.532 143.313 111.417' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M143.038 113.619C142.752 113.532 143.471 112.938 143.599 112.851' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M148.635 104.199C148.562 104.211 148.58 104.206 148.513 104.206' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M145.243 103.837C145.465 103.889 145.741 103.847 145.967 103.847' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.3237 121.984C83.2943 121.946 83.2535 121.965 83.309 121.965' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.6282 122.652C84.6543 122.723 84.6559 122.743 84.7122 122.799' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.7 126.897C102.833 127.473 103.561 127.878 103.862 128.381' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M103.93 126.506C103.921 126.972 104.469 127.448 104.799 127.712' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M99.6807 118.694C99.6864 118.78 99.6847 118.758 99.6847 118.825' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.5171 115.659C79.5157 115.658 79.923 115.265 80.0004 115.214' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.6859 115.792C80.6589 115.777 80.6752 115.783 80.6353 115.783' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M126.059 129.039C126.01 129.043 125.87 129.083 125.919 129.083' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M116.92 132.878C116.589 133.067 117.754 133.824 117.965 133.894' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M119.39 134.911C119.413 134.889 119.384 134.897 119.37 134.897' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.1323 81.7923C79.1329 81.7964 79.1336 81.8005 79.1342 81.8032' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.4444 82.6032C73.4529 82.5713 73.4534 82.5885 73.3958 82.5885' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M81.0564 53.6418C80.8868 53.6122 81.0281 53.5542 81.0281 53.4789' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.4647 54.9334C80.4341 54.9707 80.4554 54.9459 80.3972 55.0041' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.1538 102.677C68.0828 102.396 68.3533 101.926 68.4476 101.643' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.7881 100.303C68.7508 100.339 68.7636 100.295 68.7636 100.273' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M153.44 105.417C153.448 105.326 153.302 105.301 153.392 105.301' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M152.83 107.941C152.731 107.894 152.642 108.884 152.642 109.001' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M151.297 114.652C151.305 114.613 151.301 114.664 151.301 114.684' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M161.556 66.7603C161.409 66.6224 161.729 67.1256 161.863 67.1256' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M162.09 65.3036C162.142 65.3727 162.144 65.4365 162.186 65.5197' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M122.061 163.777C121.997 163.804 121.955 163.779 121.955 163.849' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M123.719 164.436C123.724 164.515 123.702 164.5 123.77 164.5' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.392 166.735C106.192 166.735 107.12 165.252 107.715 164.827' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M106.649 167.457C106.778 167.098 107.348 166.695 107.669 166.504' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M101.293 164.547C101.277 164.547 101.293 164.516 101.293 164.5' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.272 144.986C107.309 145.012 107.296 144.978 107.296 144.957' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.8701 157.373C83.8253 157.387 83.9395 157.31 83.9811 157.289' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.3752 157.934C85.3766 157.936 85.3779 157.94 85.3793 157.943' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M89.3628 177.56C89.2281 177.591 89.1938 177.68 89.1938 177.81' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.4716 181.884C90.3198 182.044 91.1024 182.42 91.1947 182.465' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.5742 180.648C91.8166 180.943 92.1757 181.225 92.5445 181.347' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.8837 172.718C69.8138 172.709 69.7354 172.667 69.7354 172.748' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.1992 175.83C69.15 175.875 69.1661 175.928 69.1661 175.992' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.6021 156.315C64.5887 156.314 64.7066 156.281 64.7832 156.223C65.0776 156.002 65.4076 155.736 65.7476 155.566' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.6284 157.432C65.5897 157.355 65.8338 157.275 65.9222 157.231' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.585 167.728C56.5566 167.714 56.5051 167.708 56.5417 167.708' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.1996 169.717C56.1969 169.721 56.1943 169.723 56.1917 169.728' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M62.8605 180.677C62.8506 180.616 62.9287 180.624 62.9857 180.638C63.488 180.763 63.9894 180.813 64.52 180.813' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.5665 147.286C64.5048 147.277 64.5034 147.28 64.5632 147.28' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M66.162 147.801C66.1337 147.87 66.1285 147.838 66.1852 147.895' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M55.5286 153.523C55.5523 153.213 55.6086 152.972 55.7086 152.672' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M72.6174 140.324C72.6865 140.306 72.6534 140.31 72.7161 140.31' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M128.035 150.77C128.018 150.433 129.148 150.14 129.393 150.042' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M129.691 150.992C129.728 150.865 130.154 150.753 130.314 150.7' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.2231 143.943C93.2273 143.947 93.2314 143.951 93.2341 143.954' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.5007 129.729C91.5309 129.76 91.5211 129.739 91.5211 129.792' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M112.564 189.104C112.621 189.095 112.734 188.967 112.779 188.922' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M114.237 189.329C114.247 189.325 114.259 189.32 114.269 189.316' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.802 192.407C102.776 192.439 102.794 192.428 102.742 192.428' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.1587 188.522C68.1536 188.541 68.1592 188.527 68.1135 188.527' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M138.467 173.096C138.452 173.76 138.625 174.885 138.334 175.466' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M137.985 177.772C137.985 177.762 137.985 177.753 137.983 177.746' stroke='#5CC87F' strokeWidth='0.440678' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
