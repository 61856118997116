import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const SocialIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }social-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    stroke={ stroke }
    fill={ 'none' }
  >
    <path d='M7.5 10C7.5 11.3807 6.38071 12.5 5 12.5C3.61929 12.5 2.5 11.3807 2.5 10C2.5 8.61929 3.61929 7.5 5 7.5C6.38071 7.5 7.5 8.61929 7.5 10Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15 7.5C13.6193 7.5 12.5 6.38071 12.5 5C12.5 3.61929 13.6193 2.5 15 2.5C16.3807 2.5 17.5 3.61929 17.5 5C17.5 6.38071 16.3807 7.5 15 7.5Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15 17.5C13.6193 17.5 12.5 16.3807 12.5 15C12.5 13.6193 13.6193 12.5 15 12.5C16.3807 12.5 17.5 13.6193 17.5 15C17.5 16.3807 16.3807 17.5 15 17.5Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.5 8.75L12.5 6.25' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.5 14.1665L7.5 11.6665' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
