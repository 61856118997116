import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const LinkedInIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }linkedin-icon` }
    id={ id }
    width={ 50 }
    height={ 50 }
    stroke={ 'none' }
    fill={ fill }
  >
    <path d='M50 3.61905V46.4286C50 48.4286 48.381 50.0476 46.4286 50.0476H3.57143C1.57143 50 0 48.381 0 46.381V3.61905C0 1.61905 1.57143 0 3.57143 0H46.4286C48.381 0 50 1.61905 50 3.61905ZM15.7143 11.4286C15.7143 9.04762 13.7619 7.14286 11.4286 7.14286C9.04762 7.14286 7.14286 9.09524 7.14286 11.4286C7.14286 13.7619 9.04762 15.7143 11.4286 15.7143C13.7619 15.7143 15.7143 13.8095 15.7143 11.4286ZM15.1429 42.8571V19H7.71429V42.8571H15.1429ZM42.9048 29.7619C42.9048 23.3333 41.5238 18.381 34 18.381C30.381 18.381 27.9524 20.381 27 22.2381H26.8571V19H19.7619V42.8571H27.1905V31.0476C27.1905 27.9524 27.7619 24.9048 31.6667 24.9048C35.4762 24.9048 35.5238 28.4762 35.5238 31.2381V42.8571H42.9524V29.7619H42.9048Z' />
  </SvgIcon>
);
