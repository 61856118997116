import React from 'react';
import { LoadingSpinnerContainer, SpinnerSize } from './styles';

interface IProps {
  className?: string;
  size?: SpinnerSize;
  color?: string;
}

export const LoadingSpinner: React.FC<IProps> = ({
  className = '',
  size,
  color,
}) => (
  <LoadingSpinnerContainer
    className={ className }
    size={ size }
    color={ color }
  >
    <div className='spinner' />
  </LoadingSpinnerContainer>
);
