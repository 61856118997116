import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
  ariaHidden?: boolean;
}

export const AccountInformationIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
  ariaHidden,
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}account-information-icon` }
    id={ id }
    height={ 20 }
    width={ 20 }
    fill={ fill }
    aria-hidden={ ariaHidden }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M5.29163 11.7231C7.09361 11.1522 9.02387 10.9951 10.2642 11.0235C10.6624 11.0326 10.9777 11.3628 10.9686 11.7609C10.9595 12.1591 10.6293 12.4744 10.2312 12.4653C9.11163 12.4397 7.3453 12.5853 5.72722 13.0979C4.13797 13.6014 2.86046 14.4028 2.32182 15.5689V17.5626H10.2477C10.646 17.5626 10.9688 17.8854 10.9688 18.2837C10.9688 18.6819 10.646 19.0048 10.2477 19.0048H1.60073C1.40949 19.0048 1.22607 18.9288 1.09084 18.7936C0.955611 18.6583 0.879639 18.4749 0.879639 18.2837V15.2778L0.933175 15.1467C1.68334 13.3101 3.51613 12.2856 5.29163 11.7231Z' fill={ fill } />
    <path fillRule='evenodd' clipRule='evenodd' d='M10.2998 1.44219C8.26477 1.44219 6.61504 3.09192 6.61504 5.12697C6.61504 7.16202 8.26477 8.81176 10.2998 8.81176C12.3349 8.81176 13.9846 7.16202 13.9846 5.12697C13.9846 3.09192 12.3349 1.44219 10.2998 1.44219ZM5.17285 5.12697C5.17285 2.29542 7.46827 0 10.2998 0C13.1314 0 15.4268 2.29542 15.4268 5.12697C15.4268 7.95852 13.1314 10.2539 10.2998 10.2539C7.46827 10.2539 5.17285 7.95852 5.17285 5.12697Z' fill={ fill } />
    <path fillRule='evenodd' clipRule='evenodd' d='M16.5883 10.8945C16.5883 10.4963 16.2654 10.1734 15.8672 10.1734C15.469 10.1734 15.1461 10.4963 15.1461 10.8945V11.8207C14.757 11.9062 14.3933 12.0595 14.0681 12.2676L13.4129 11.6125C13.1313 11.3309 12.6748 11.3309 12.3932 11.6125C12.1116 11.8941 12.1116 12.3507 12.3932 12.6323L13.0484 13.2875C12.8404 13.6128 12.6871 13.9765 12.6017 14.3656H11.6752C11.2769 14.3656 10.9541 14.6885 10.9541 15.0867C10.9541 15.485 11.2769 15.8078 11.6752 15.8078H12.6019C12.6875 16.1967 12.8407 16.5603 13.0487 16.8854L12.3931 17.5411C12.1115 17.8227 12.1115 18.2793 12.3931 18.5609C12.6747 18.8425 13.1313 18.8425 13.4129 18.5609L14.0686 17.9051C14.3938 18.113 14.7573 18.2662 15.1461 18.3517V19.2789C15.1461 19.6772 15.469 20 15.8672 20C16.2654 20 16.5883 19.6772 16.5883 19.2789V18.3517C16.9772 18.2662 17.3408 18.113 17.666 17.9051L18.3218 18.561C18.6034 18.8426 19.06 18.8426 19.3416 18.561C19.6232 18.2793 19.6232 17.8228 19.3416 17.5412L18.6858 16.8854C18.8938 16.5602 19.0471 16.1967 19.1326 15.8078H20.0596C20.4578 15.8078 20.7807 15.485 20.7807 15.0867C20.7807 14.6885 20.4578 14.3656 20.0596 14.3656H19.1329C19.0474 13.9765 18.8942 13.6128 18.6862 13.2875L19.3415 12.6322C19.6231 12.3506 19.6231 11.894 19.3415 11.6124C19.0599 11.3308 18.6033 11.3308 18.3217 11.6124L17.6665 12.2676C17.3412 12.0595 16.9775 11.9062 16.5883 11.8207V10.8945ZM13.966 15.0862C13.966 14.0361 14.8172 13.1849 15.8673 13.1849C16.9173 13.1849 17.7685 14.0361 17.7685 15.0862C17.7685 16.1362 16.9173 16.9874 15.8673 16.9874C14.8172 16.9874 13.966 16.1362 13.966 15.0862Z' fill={ fill } />
  </SvgIcon>
);
