import { observer } from 'mobx-react';
import React, { RefAttributes } from 'react';
import { LinkProps } from 'react-router-dom';
import { CompanyModel } from '../../models/companies';
import { CompanyLogo } from '../CompanyLogo';
import { CompanyRatingIndicator } from '../CompanyRatingIndicator';
import { Cashback, CompanySearchResultItemContainer, OwnedBy, PrimaryInfo, PrimaryInfoContainer, SecondaryInfoContainer } from './styles';

interface IProps extends LinkProps, RefAttributes<HTMLAnchorElement> {
  className?: string;
  company: CompanyModel;
}

const CompanySearchResultItemBase: React.FC<IProps> = ({
  className = '',
  company,
  ...restProps
}) => {
  const renderSectors = () => {
    const unique = new Set(company.sectors.map(s => s.sector.name));
    return Array.from(unique).join(', ');
  };

  return (
    <CompanySearchResultItemContainer
      { ...restProps }
      className={ className }
    >
      <PrimaryInfoContainer>
        <CompanyLogo
          className='search-result-company-logo'
          company={ company }
        />
        <PrimaryInfo>
          <p className='company-name'>{ company.companyName }</p>
          <p className='company-sectors'>{ renderSectors() }</p>
          <div className='badge-holder'>
            {
              !!company.parentCompany && !company.parentCompany.hidden.status && (
                <OwnedBy className='pill'>
                  { `Owned by ${company.parentCompany.companyName}` }
                </OwnedBy>
              )
            }
            {
              !!company.hasCashback && (
                <Cashback className='pill'>
                  Cashback Offers
                </Cashback>
              )
            }
          </div>
        </PrimaryInfo>
      </PrimaryInfoContainer>
      <SecondaryInfoContainer>
        <CompanyRatingIndicator className='company-rating-indicator' rating={ company.rating } />
      </SecondaryInfoContainer>
    </CompanySearchResultItemContainer>
  );
};

export const CompanySearchResultItem = observer(CompanySearchResultItemBase);
