import styled from 'styled-components';
import { AbsoluteCenter, FlexCenter, FlexCol, FlexRow } from '../../../../styles/styles';
import { TextLG, TextSM, TextXS } from '../../../../styles/components/text';

interface IProps {
  image: string;
}

export const TeamMemberCardContainer = styled.a`
  ${FlexRow}
  background-image: url(${(props: IProps) => props.image});
  background-size: cover;
  background-position: center;
  gap: 10px;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 164px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    height: 288px;
    width: 221px;
  }

  .info-container {
    ${FlexCol}
    align-self: flex-end;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    height: 55%;
    justify-content: flex-end;
    position: relative;
    padding: 10px;
    text-align: center;
    width: 100%;
    z-index: 1;

    .name {
      color: ${({ theme }) => theme.colors.white};
      font-size: 15px;
      font-weight: 600;

      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        ${TextLG}
      }
    }

    .title {
      ${TextXS}
      color: ${({ theme }) => theme.colors.grey3};

      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        ${TextSM}
      }
    }
  }

  &:hover,
    &:focus-visible {
      .mask {
        opacity: 0.65;
      }
    }

    .mask {
      ${ AbsoluteCenter }
      ${ FlexCenter }
      background: ${({ theme }) => theme.colors.primary};
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      width: 100%;

      svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
`;
