import React, { createContext } from 'react';
import { HeaderModel } from '../models/header';

export const HeaderContext = createContext<HeaderModel>(null);

export const useHeader = () => React.useContext(HeaderContext);

export const HeaderStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <HeaderContext.Provider value={ new HeaderModel() }>
    { children }
  </HeaderContext.Provider>
);
