import React from 'react';
import { H2, H4 } from '../../styles/components/header';
import { ProjectCardErrorContainer } from './styles';
import rareLogo from '../../../public/assets/images/rare-logo.png';

const ErrorText = () => (
  <p className='error-text'>
    This content isn’t loading. Check your internet connection and try refreshing. If you still have problems, contact us at <a href='mailto:support@karmawallet.io?subject=Something%20went%20wrong'>support@karmawallet.io</a>.
  </p>
);

export const ProjectCardError = ({
  className = '',
}) => (
  <ProjectCardErrorContainer className={ `has-border ${className}` }>
    <div>
      <H2>Oops.</H2>
      <H4>Something went wrong.</H4>
      <ErrorText />
    </div>
    <div>
      <img className='rare-logo' src={ rareLogo } alt='rare-logo' />
    </div>
  </ProjectCardErrorContainer>
);
