import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../../../styles/styles';

interface IProps {
  mobileOpen: boolean;
}

export const SentimentCategoriesMobileMenuContainer = styled.div<IProps>`
  ${FlexCol}
  gap: 5px;
  height: ${ ({ mobileOpen }) => mobileOpen ? '424px' : '48px' };
  overflow: hidden;
  transition: height 250ms;
  width: 100%;

  .mobile-category-card {
    border: 1px solid ${({ theme }) => theme.colors.grey2 };
  }
`;

export const SentimentCategoriesMenuContainer = styled.div`
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.lgMin }) {
    max-width: 458px;
    width: 30%;
  }
`;

export const CategoryContainer = styled.div`
  ${FlexCol}
  gap: 10px;
  margin-top: 20px;

  .categories-header-graphic {
    height: auto;
    width: 26px;
  }
`;

export const CategoryHeaderContainer = styled.div`
  ${FlexRow}
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .category-header-text {
    font-size: 20px;
    font-weight: 700;
  }
`;
