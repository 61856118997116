import React from 'react';
import { CompanyModel } from '../../models/companies';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { DonutSegment, CircleBarChartContainer, DonutSegmentFill, DonutCenterDisc } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  company?: CompanyModel;
}

interface ISubcategoryData {
  subcategory: string;
  subcategoryScore: number;
  radius: number;
  color: string;
  yCoordinate: number;
}

const CircleBarChartBase: React.FC<IProps> = ({
  company,
  theme,
}) => {
  const [ communityWellfare, diversityInclusion, climateAction, sustainabillity ] = company.subcategoryScores;

  const data: ISubcategoryData[] = [
    {
      subcategory: 'Climate Action',
      subcategoryScore: climateAction.score,
      radius: 90,
      color: theme.colors.blue,
      yCoordinate: 7.5,
    },
    {
      subcategory: 'Sustainability',
      subcategoryScore: sustainabillity.score,
      radius: 70,
      color: theme.colors.gold,
      yCoordinate: 27,
    },
    {
      subcategory: 'Community Welfare',
      subcategoryScore: communityWellfare.score,
      radius: 50,
      color: theme.colors.secondary,
      yCoordinate: 47,
    },
    {
      subcategory: 'Diversity & Inclusion',
      subcategoryScore: diversityInclusion.score,
      radius: 30,
      color: theme.colors.primary,
      yCoordinate: 68,
    },
  ];

  const negativeRotation = ( score: number ): number => {
    let rotation = -90;
    if (score < 0) rotation = (score * 90) - 90;
    return rotation;
  };

  const totalScore: number = company.categoryScores.reduce((acc, curr) => acc + curr.score, 0);

  const radiusFill = (radius: number, score: number): number => (2 * Math.PI * radius) / 100 * score * 25;

  const renderScoreTextBox = (score: number, yCoordinate: number): JSX.Element => {
    const xCoordinate: number = score >= 0 ? 103 : 85;
    return (
      <g transform={ `translate(${xCoordinate}, ${yCoordinate})` }>
        <rect width='10' height='7' fill='white' rx='1' />
        <text
          fontSize='4'
          fontWeight='700'
          x='5'
          y='4'
          alignmentBaseline='middle'
          textAnchor='middle'
        >
          { score }
        </text>
      </g>
    );
  };

  const renderDonutSegment = (radius: number, yCoordinate: number, color: string, subcategoryScore: number): JSX.Element => (
    <React.Fragment key={ yCoordinate + radius + Math.random() }>
      <DonutSegment
        r={ radius }
        stroke={ color + '55' }
      />
      <DonutSegmentFill
        r={ radius }
        stroke={ subcategoryScore >= 0 ? color : theme.colors.red }
        strokeDasharray={ `${Math.abs(radiusFill(radius, subcategoryScore))}, 1000` }
        style={ { transform: `rotate(${negativeRotation(subcategoryScore)}deg)` } }
      />
      { 
        renderScoreTextBox(subcategoryScore, yCoordinate) 
      }
    </React.Fragment>
  );

  const renderDonutBarGraph = (): JSX.Element[] => data.map((d: ISubcategoryData) => renderDonutSegment(d.radius, d.yCoordinate, d.color, d.subcategoryScore));

  return (
    <CircleBarChartContainer>
      <svg
        viewBox='0 0 200 200'
        xmlns='http://www.w3.org/2000/svg'
      >
        {
          renderDonutBarGraph()
        }
        <DonutCenterDisc>
          <circle
            r='18'
            fill={ theme.colors.offWhite }
            cx='100'
            cy='100'
          />
          <text
            fontSize='7'
            fontWeight={ 700 }
            alignmentBaseline='middle'
            textAnchor='middle'
            x='50%'
            y='47.5%'
          >
            Total
          </text>
          <text
            fontSize='14'
            fontWeight={ 700 }
            x='50%'
            y='53%'
            alignmentBaseline='middle'
            textAnchor='middle'
          >
            { totalScore }
          </text>
        </DonutCenterDisc>
      </svg>
    </CircleBarChartContainer>
  );
};

export const CircleBarChart = withTheme(CircleBarChartBase);
