import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const UnsdgFailedIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }failed-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    fill={ fill }
  >
    <path d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z' fill={ fill } />
    <path d='M13.2913 5.99998L6.00006 13.2913L7.20861 14.4998L14.4999 7.20852L13.2913 5.99998Z' fill='white' />
    <path d='M7.20861 5.99995L6.00006 7.2085L13.2913 14.4998L14.4999 13.2912L7.20861 5.99995Z' fill='white' />
  </SvgIcon>
);
