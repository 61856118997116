import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { FlexCenter } from '../../styles/styles';

export interface ICompanyLogoProps {
  withBorder?: boolean;
}

export const CompanyLogoContainer = styled.div<ICompanyLogoProps>`
  ${ FlexCenter }
  
  & > * {
    max-width: 100%;
    max-height: 100%;
    width: 90%;
    object-fit: contain;
  }

    .default-company-logo-container {
      text-align: center;
      max-height: 64px;
      min-width: 64px;

      svg {
        max-height: 64px;
      }

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        max-height: 110px;

        svg {
          max-height: 110px;
        }
      }
    }

  ${({ withBorder, theme }) => withBorder
    ? `
      border: 2px solid ${theme.colors.darkGray1};
      border-radius: 50%;
    `
    : null}
`;

export const CompanyLogoSkeletonContainer = styled(Skeleton)<ICompanyLogoProps>`
  background: ${({ theme }) => theme.colors.lightGray1};
`;
