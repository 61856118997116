import { observer } from 'mobx-react';
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { ErrorMessageContainer, BenefitCardsContainer, BenefitSectionContainer, InnerWrapper, MembershipBenefitsContainer, LoadingSpinnerContainer } from './styles';
import { BenefitCard } from '../BenefitCard';
import { useAnalytics } from '../../contexts/analytics-store';
import { Tooltip } from '../Tooltip';
import { BenefitCardVariant } from '../BenefitCard/styles';
import { useDoneGoodVersion } from '../../contexts/doneGoodVersion';
import { KarmaCardBenefitsModel, IKarmaCardBenefitsGraphic, IKarmaCardBenefitsLearnMore } from '../../models/karmaCard';
import { componentsLookup } from '../../utils/componentsLookup'; // Import the lookup map
import { LoadingSpinner } from '../loading/LoadingSpinner';

interface IProps {
  className?: string;
  location: 'Apply' | 'App';
}

const MembershipBenefitsBase: React.FC<IProps> = ({
  className,
  location,
}) => {
  const { isDoneGood } = useDoneGoodVersion();
  const karmaCardBenefitsModel = useRef(new KarmaCardBenefitsModel()).current;
  const analytics = useAnalytics();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getBenefits = useCallback(async () => {
    try {
      await karmaCardBenefitsModel.getBenefits();
    } catch (err: any) { 
      setErrorMessage('Error displaying benefits. \nPlease refresh the page.'); 
    }
  }, []);

  useEffect(() => {
    getBenefits();
  }, []);

  const onWhatIsDoneGoodClick = useCallback(() => {
    analytics.fireEvent(`${location}_WhatIs_DoneGood_Click`);
  }, [location]);

  const onShopHereClick = useCallback(() => {
    analytics.fireEvent(`${location}_DoneGood_ShopHere_Click`);
  }, [location]);

  const renderDescription = useCallback((description: string) => {
    const regex = /<logo>(.*?)<\/logo>/;
    const match = description.match(regex);

    if (match) {
      const logo = match[1];
      
      // Match with the svg referenced in componentsLookup.ts
      const IconComponent = componentsLookup[logo]; 
      
      const logoStartIndex = description.indexOf('<logo>');
      const logoEndIndex = description.indexOf('</logo>');

      const textBeforeLogo = description.substring(0, logoStartIndex);
      const textAfterLogo = description.substring(logoEndIndex + '</logo>'.length);

      return (
        <p className='donegood-description'>
          { textBeforeLogo } <IconComponent /> { textAfterLogo }
        </p>
      );
    } else {
      return description;
    }
  }, []);

  const renderLearnMoreToolTipSubText = useCallback((learnMore: IKarmaCardBenefitsLearnMore) => (
    <p className='tooltip-text'>
      { learnMore.toolTipSubText }
      <a
        href={ learnMore.toolTipUrl }
        target='_blank'
        rel='noreferrer'
        onClick={ onShopHereClick }
      >
        { learnMore.toolTipUrlText }
      </a>
    </p>
  ), [onShopHereClick]);

  const renderLearnMore = useCallback((learnMore: IKarmaCardBenefitsLearnMore) => { 
    if (!learnMore) return null;

    switch (learnMore.type) {
      case 'subText':
        return (
          <p className='cashback-disclaimer'>
            { learnMore.text }
          </p>
        );
      case 'toolTip':
        return (
          <Tooltip
            className='tooltip'
            text={ renderLearnMoreToolTipSubText(learnMore) }
            withBorder
          >
            <p onClick={ onWhatIsDoneGoodClick } className='learn-more'>{ learnMore.text }</p>
          </Tooltip>
        );
    }
  }, [renderLearnMoreToolTipSubText, onWhatIsDoneGoodClick]);

  const renderGraphic = useCallback((graphic: IKarmaCardBenefitsGraphic) => {
    if (!graphic) return null;

    return <img src={ graphic.src } className={ graphic.className } />;
  }, []);

  const currentBenefits = useMemo(() => {
    let content: JSX.Element | JSX.Element[];

    if ( karmaCardBenefitsModel.busy ) {
      content = (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );
    } else if ( !!errorMessage ) {
      content = (
        <ErrorMessageContainer>
          {
            errorMessage.split('\n').map((line, index) => (
              <p key={ index }>
                { line }
                <br />
              </p>
            ))
          }
        </ErrorMessageContainer>
      );
    } else {
      content = Object.keys(karmaCardBenefitsModel.benefits).map((header) => (
        <BenefitSectionContainer key={ header }>
          <p className='header'>{ header }</p>
          <BenefitCardsContainer>
            {
              karmaCardBenefitsModel.benefits[header].map(({ title, description, graphic, learnMore }) => (
                <BenefitCard
                  key={ title }
                  title={ title }
                  description={ renderDescription(description) }
                  learnMore={ renderLearnMore(learnMore) }
                  graphic={ renderGraphic(graphic) }
                  variant={ isDoneGood ? BenefitCardVariant.Orange : BenefitCardVariant.Green }
                />
              ))
            }
          </BenefitCardsContainer>
        </BenefitSectionContainer>
      ));
    }

    return content;
  }, [karmaCardBenefitsModel.benefits, karmaCardBenefitsModel.busy, errorMessage]);

  return (
    <MembershipBenefitsContainer className={ className }>
      <InnerWrapper>
        { currentBenefits }
      </InnerWrapper>
    </MembershipBenefitsContainer>
  );
};

export const MembershipBenefits = observer(MembershipBenefitsBase);
