import React, { createContext } from 'react';
import { BrowseByQueryModel } from '../models/browse-query';

export const BrowseQueryContext = createContext<BrowseByQueryModel>(null);

export const useBrowseQuery = () => React.useContext(BrowseQueryContext);

export const BrowseQueryStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <BrowseQueryContext.Provider value={ new BrowseByQueryModel() }>
    { children }
  </BrowseQueryContext.Provider>
);
