import { animated } from 'react-spring';
import styled from 'styled-components';
import { FlexCol } from '../../../styles/styles';

export const ModalContainer = styled(animated.div)`
  ${FlexCol}
  box-shadow: 0 24px 38px 3px #00000023, 0 9px 46px 8px #0000001E, 0 11px 15px -7px #00000033;
  position: absolute;
  align-items: center;
  left: 50%;
  padding: 35px;
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
  border-radius: 8px;
  transform: translate(-50%, -25%);
  max-height: 90vh;

  .processing-state-background {
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.light}88;
  }

  .modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 11px;
    height: 11px;
  }

  .modal-body {
    width: 100%;
    height: 100%;
  }
`;

export const ModalOverlay = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  backdrop-filter: blur(4px);
`;

export const ModalScrim = styled(animated.div)`
  background: ${({ theme }) => theme.colors.black};
  width: 100vw;
  height: 100vh;
`;
