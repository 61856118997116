import React from 'react';
import { H1, H2, H3, H4, H5 } from '../../../styles/components/header';

type HeadingComponentProps = {
  node: HTMLElement,
  children: string[],
  level: number;
};

export const HeadingsComponent: React.FC<HeadingComponentProps> = ({ children, level }) => {
  // Access actual (string) value of heading
  const heading = children?.[0] || '';

  switch (level) {
    case 1: return <H1>{ heading }</H1>;
    case 2: return <H2>{ heading }</H2>;
    case 3: return <H3>{ heading }</H3>;
    case 4: return <H4>{ heading }</H4>;
    case 5: return <H5>{ heading }</H5>;
    default: return <p>{ heading }</p>;
  }
};
