import React, { useCallback } from 'react';
import { KarmaScoreContainer } from './styles';
import { RatingFilterButton } from '../RatingFilterButton';
import { Ratings } from '../../models/impact';
import { useAnalytics } from '../../contexts/analytics-store';
import { useBrowseQuery } from '../../contexts/browse-query';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
}

const KarmaScoreFilterBase: React.FC<IProps> = ({
  className = '',
}) => {
  const requestQuery = useBrowseQuery();
  const analytics = useAnalytics();

  const onRatingClick = useCallback((rating: Ratings) => () => {
    requestQuery.updateRatings(rating);
    analytics.fireEvent(`BC_Rating_Select_${rating}`);
  }, [requestQuery.ratings]);

  const renderRatingFilterButton = useCallback((rating: Ratings) => (
    <RatingFilterButton
      rating={ rating }
      onRatingClick={ onRatingClick }
      selected={ requestQuery.ratings.includes(rating) }
    />
  ), [requestQuery.ratings, onRatingClick]);

  return (
    <KarmaScoreContainer className={ className }>
      <div className='checkbox-container'>
        { renderRatingFilterButton(Ratings.Positive) }
        { renderRatingFilterButton(Ratings.Neutral) }
        { renderRatingFilterButton(Ratings.Negative) }
      </div>
    </KarmaScoreContainer>
  );
};

export const KarmaScoreFilter = observer(KarmaScoreFilterBase);
