import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../../styles/styles';
import { Section } from '../../../styles/components/containers';

export const AsFeaturedInSectionContainer = styled(Section)`
  padding: 40px 15px;
`;

export const ArticlesContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 32px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    gap: 20px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    gap: 56px;
  }
`;

export const ArticleContainer = styled.div`
  ${FlexHorizontalCenter}
  gap: 8px;

  img {
    max-width: 130px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexCol}
    align-items: start;
    gap: 32px;

    img {
      max-width: 230px;
    }
  }
`;

export const ArticleTextContainer = styled.div`
  ${FlexCol}
  align-items: start;
  gap: 12px;
  max-width: 230px;

  .read-post {
    font-weight: 600;
  }
`;
