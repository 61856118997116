import styled from 'styled-components';

interface IProps {
  outlineFill?: string;
}

export const GoodBadTextContainer = styled.div<IProps>`
  .the-bad-wrapper {
    display: flex;

    .the-bad-text {
      margin-left: 10px;
      color: ${ props => props.outlineFill ? props.outlineFill : ({ theme }) => theme.colors.dark };
      text-shadow:-1px -1px 0 #fff, 
                  1px -1px 0 #fff, 
                  -1px  1px 0 #fff, 
                  1px  1px 0 #fff, 
                  1px  0px 0 #fff, 
                  -1px  0px 0 #fff, 
                  0px  1px 0 #fff, 
                  0px -1px 0 #fff;
    }
  }

  h1 {
    color: #fff;
    font-size: 72px;
    line-height: 72px;
  }
`;
