import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AccountHubSlide, SectorCarouselContainer, SectorSlider, Slide } from './styles';
import { SectorsModel } from '../../models/sectors';
import { useAnalytics } from '../../contexts/analytics-store';
import { useBrowseQuery } from '../../contexts/browse-query';
import { Settings as ReactSlickSettings } from 'react-slick';
import { SectorCarouselLocation } from '../SectorCarousel/styles';

export interface ICarouselSector {
  id: string;
  img: string | JSX.Element;
  name: string;
}

interface IProps {
  className?: string;
  isAccountHubFilter?: boolean;
  onSectorClick: (sector: string) => void;
}

const accountHubSliderSettings: ReactSlickSettings = {
  className: 'slider variable-width',
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  variableWidth: true,
};

export const SectorFilter: React.FC<IProps> = ({
  className = '',
  isAccountHubFilter,
  onSectorClick,
}) => {
  const analytics = useAnalytics();
  const requestQueryModel = useBrowseQuery();
  const [sectors, setSectors] = useState([]);
  const [activeSector, setActiveSector] = useState('');
  const sectorsModel = useRef(new SectorsModel()).current;
  const location = isAccountHubFilter && SectorCarouselLocation.AccountHub;

  const loadSectors = async () => {
    try { 
      await sectorsModel.sectors.loadMore({ tier: 1 });
      setSectors(sectorsModel.sectors.results);
    } catch (error) {
      analytics.fireEvent('Sector_Filter_Error_Loading_Sectors');
    }
  };

  useEffect(() => {
    loadSectors();
  }, []);

  const accountHubSectors = useMemo(() => {
    if (!sectors.length) return null;
    
    const sectorIds = [
      '',
      '621b9ada5f87e75f53666f38',
      '621b9ada5f87e75f53666faa',
      '621b9adb5f87e75f53666fde',
      '621b9adb5f87e75f53667018',
      '621b9adb5f87e75f53667030',
      '621b9adb5f87e75f53667046',
      '621b9adb5f87e75f53667078',
      '621b9adb5f87e75f536670ac',
      '621b9adc5f87e75f536670cc',
    ];
  
    const sectorMap = Object.fromEntries(sectors.map(sector => [sector._id, sector.name]));
  
    return sectorIds.map(id => ({
      id,
      name: id === '' ? 'All' : sectorMap[id] || '',
    }));
  }, [sectors]);

  const handleSectorClick = (id: string) => {
    if (isAccountHubFilter) {
      setActiveSector(id);
    }
    onSectorClick(id);
  };

  const setSelectedSector = useCallback((id: string) => {
    if (isAccountHubFilter) return '';
    return requestQueryModel.sectors.includes(id) ? ' selected' : '';
  }, [requestQueryModel?.sectors]);

  const renderSectorIcons = useCallback(() =>sectors?.map(({ _id, name, icon }) => (
    <Slide
      key={ _id }
      className={ 'sector-card' + setSelectedSector(_id) }
      id={ _id }
      onClick ={ () => handleSectorClick(_id) }
    >
      <img
        className='img-container'
        src={ icon }
        alt={ `${ name } sector graphic ` }
      />
      <p className='sector-name'>{ name }</p>
    </Slide>
  )), [sectors]);
  
  const renderAccountHubSectors = useCallback(() => accountHubSectors?.map(({ id, name }) => (
    <AccountHubSlide
      key={ id }
      className={ 'sector-card' + (id === activeSector ? ' selected' : '') }
      id={ id }
      onClick ={ () => handleSectorClick(id) }
    >
      <p className='sector-name'>{ name }</p>
    </AccountHubSlide>
  )), [sectors, activeSector]);

  return (
    <SectorCarouselContainer className={ className } location={ location }>
      <SectorSlider location={ location } settings={ isAccountHubFilter && accountHubSliderSettings }>
        { isAccountHubFilter ? renderAccountHubSectors() : renderSectorIcons() }
      </SectorSlider>
    </SectorCarouselContainer>
  );
};
