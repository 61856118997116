import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';

export enum Source {
  JustCapital = 'just-capital',
  BCorp = 'b-corp',
  Certificates = 'certificates'
}

export interface ISource {
  name: string;
  _id: string;
  logoUrl: string;
  rank: number;
  description: string;
  url: string;
}

type PrivateFields = '_sources' |
'_busy';

export class SourcesModel extends BaseModel {
  private _busy = false;
  private _sources: ISource[] = [];

  constructor () {
    super();
    makeObservable<SourcesModel, PrivateFields>(this, {
      _busy: observable,
      _sources: observable,
      sources: computed,
      loading: computed,
      loadSources: action.bound,
    });
  }

  get busy() { return this._busy; }
  get sources() { return this._sources; }
  get loading() { return this._busy; }

  public loadSources = async (queryString?: string) => {
    this._busy = true;
    
    const result = await this.webServiceHelper.sendRequest<ISource[]>({
      path: `/data-source${queryString ? `?${queryString}` : ''}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._busy = false;
        this._sources = result.value;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}
