import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const Food: FC<IProps> = ({
  className = '',
  id = '',
  fill = 'white',
  stroke = 'black',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}food-graphic` }
    id={ id }
    height={ 103 } 
    width={ 149 }
    fill={ fill }
    stroke={ stroke }
  >
    <g transform='matrix(1,0,0,1,-9.46923,-41.9316)'>
      <g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M121.697,118.712C124.558,119.329 127.284,117.942 127.786,115.614C128.289,113.286 126.378,110.898 123.518,110.281C120.657,109.663 117.931,111.05 117.429,113.378C116.926,115.706 118.837,118.094 121.697,118.712Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.802,154.885C56.968,155.324 58.925,160.975 60.716,161.622C67.488,164.065 84.417,165.174 96.04,163.074C107.811,160.946 125.375,153.314 130.504,148.487C132.42,146.684 132.568,140.778 132.568,140.778C132.568,140.778 55.954,152.626 56.802,154.885Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M17.481,122.438C17.481,122.438 21.664,133.535 30.175,142.305C34.938,147.217 41.666,152.034 47.716,154.214C60.433,158.799 81.599,163.188 104.396,157.046C129.023,150.408 142.345,134.764 147.079,128.25C151.142,122.667 154.682,116.949 156.172,110.089C158.493,99.406 156.143,88.678 156.143,88.678C156.143,88.678 150.628,100.484 132.645,111.075C120.568,118.19 102.821,123.629 92.896,126.145C81.194,129.11 62.62,132.186 46.226,131.354C30.689,130.562 17.481,122.438 17.481,122.438Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M92.264,132.989C130.928,124.716 159.524,105.171 156.135,89.335C152.746,73.499 118.656,67.369 79.992,75.642C41.329,83.916 12.733,103.46 16.121,119.296C19.51,135.132 53.6,141.263 92.264,132.989Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M90.696,127.95C126.705,120.244 153.538,102.98 150.63,89.39C147.722,75.799 116.173,71.028 80.164,78.734C44.155,86.439 17.321,103.703 20.229,117.294C23.137,130.885 54.686,135.656 90.696,127.95Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M85.286,101.061C84.587,99.673 81.016,97.944 78.054,96.745C74.781,95.419 72.673,94.57 70.892,95.174C69.484,95.649 69.012,98.422 67.993,101.311C66.723,104.905 65.081,107.737 66.559,109.328C68.579,111.507 76.224,114.621 79.098,114.521C81.044,114.452 81.499,111.941 83.002,108.084C84.286,104.791 85.99,102.45 85.286,101.061Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M70.706,95.279L84.647,101.867L90.672,100.978C90.672,100.978 78.818,94.852 75.551,93.874C74.057,93.429 70.706,95.279 70.706,95.279Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M85.255,101.781L79.817,114.386L85.318,113.125L90.737,101.057L85.255,101.781Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M68.227,110.525C67.901,110.454 68.524,109.185 68.601,109.025C69.099,107.981 70.659,106.902 71.797,106.647C72.615,106.469 73.481,106.469 74.317,106.362C76.316,106.113 78.978,105.087 79.939,103.16C80.443,102.14 80.093,101.043 80.229,100.053' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M79.162,99.104C79.477,98.037 80.716,96.59 81.949,96.762' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M68.394,100.906C68.477,100.8 68.903,100.906 69.022,100.912C69.491,100.936 70.066,100.746 70.499,100.568C71.726,100.07 72.45,99.222 73.173,98.178C73.398,97.846 73.701,97.514 73.754,97.153' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M125.393,112.796C128.253,113.414 130.979,112.027 131.482,109.699C131.984,107.371 130.073,104.983 127.213,104.366C124.353,103.748 121.626,105.135 121.124,107.463C120.621,109.791 122.533,112.179 125.393,112.796Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M114.264,118.849C117.125,119.466 119.851,118.079 120.353,115.751C120.856,113.423 118.945,111.035 116.084,110.418C113.224,109.8 110.498,111.187 109.995,113.515C109.493,115.843 111.404,118.231 114.264,118.849Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M118.032,112.846C120.893,113.464 123.619,112.077 124.121,109.749C124.624,107.421 122.713,105.033 119.852,104.415C116.992,103.798 114.266,105.185 113.763,107.513C113.261,109.841 115.172,112.229 118.032,112.846Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M107.682,114.336C110.542,114.953 113.268,113.566 113.771,111.238C114.273,108.91 112.362,106.522 109.502,105.905C106.642,105.287 103.916,106.674 103.413,109.002C102.91,111.33 104.822,113.718 107.682,114.336Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M103.275,111.315C106.135,111.932 108.861,110.545 109.364,108.217C109.866,105.889 107.955,103.501 105.095,102.884C102.234,102.266 99.508,103.653 99.006,105.981C98.503,108.309 100.414,110.697 103.275,111.315Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M95.843,113.617C98.703,114.234 101.429,112.848 101.932,110.519C102.434,108.191 100.523,105.803 97.663,105.186C94.802,104.568 92.076,105.955 91.574,108.283C91.071,110.611 92.982,112.999 95.843,113.617Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M143.905,100.222C146.766,100.839 149.492,99.453 149.994,97.124C150.497,94.796 148.586,92.408 145.726,91.791C142.865,91.173 140.139,92.56 139.637,94.888C139.134,97.216 141.045,99.604 143.905,100.222Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M27.17,125.669C23.873,123.599 22.764,122.14 22.379,121.642C20.398,119.057 20.119,115.143 20.155,114.764C20.309,113.317 24.887,112.03 25.172,111.668C26.737,109.682 33.349,112.587 34.067,112.398C34.861,112.184 32.732,105.43 33.954,105.157C34.909,104.938 36.474,100.152 37.69,100.093C41.912,99.88 46.614,104.79 50.919,106.622C55.224,108.454 59.126,107.215 59.191,107.203C59.648,107.114 60.288,107.262 60.692,107.464C61.984,108.11 61.925,110.607 63.876,109.439C64.309,109.178 64.487,108.644 64.902,108.365C66.088,107.577 67.808,106.509 69.243,107.375C70.102,107.891 70.689,110.079 71.864,109.783C72.557,109.611 73.073,108.78 73.631,108.365C74.52,107.695 75.807,107.345 76.934,107.506C78.564,107.737 78.564,110.061 80.029,110.429C80.474,110.536 81.583,108.982 81.921,108.709C83.03,107.82 85.147,107.737 86.433,108.104C88.378,108.662 87.578,111.787 89.742,112.143C90.863,112.332 91.462,110.897 92.102,110.257C92.885,109.474 94.284,109.071 95.37,109.308C96.158,109.486 96.911,109.996 97.392,110.642C98.05,111.52 98.174,112.742 99.497,112.961C100.487,113.127 100.991,112.285 101.513,111.627C102.124,110.868 103.559,109.777 104.608,110.038C105.403,110.239 106.251,110.945 106.417,111.757C106.577,112.587 106.583,113.821 107.36,114.337C108.267,114.942 108.878,114.372 109.637,113.993C110.479,113.572 111.54,113.145 112.471,113.518C113.224,113.821 113.675,114.562 113.503,115.41C113.444,115.707 112.952,116.709 113.248,116.999C113.551,117.302 114.007,117.035 114.321,116.958C114.636,116.881 115.045,117.041 115.312,117.171C116.355,117.693 116.759,118.891 116.569,119.952C116.462,120.522 111.309,122.176 109.162,122.882C108.617,123.066 102.094,125.639 90.406,128.059C85.615,129.049 79.869,130.146 73.435,130.786C65.335,131.599 53.718,132.144 45.446,130.952C36.533,129.666 33.278,128.895 27.17,125.669Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M73.313,79.64L61.305,68.84C53.879,70.352 56.019,90.457 62.62,92.992C66.804,94.604 74.981,90.11 74.981,90.11L73.313,79.64Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M60.911,68.967C56.776,78.548 61.038,90.376 66.436,90.192L60.911,68.967Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M60.911,68.967C56.776,78.548 61.038,90.376 66.436,90.192' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M82.156,79.648L74.136,65.629C66.61,64.737 62.343,84.501 67.817,88.977C71.285,91.818 80.458,90.112 80.458,90.112L82.156,79.648Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M73.721,65.626C66.792,73.43 67.134,85.998 72.317,87.514L73.721,65.626Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M73.721,65.626C66.792,73.43 67.134,85.998 72.317,87.514' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M91.115,81.357L83.095,67.338C75.569,66.446 71.302,86.21 76.776,90.685C80.244,93.527 89.417,91.821 89.417,91.821L91.115,81.357Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M82.681,67.335C75.751,75.138 76.093,87.706 81.277,89.223L82.681,67.335Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M82.681,67.335C75.751,75.138 76.093,87.706 81.277,89.223' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M99.678,78.541L91.659,64.522C84.133,63.631 79.866,83.394 85.34,87.87C88.808,90.711 97.981,89.006 97.981,89.006L99.678,78.541Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M91.244,64.519C84.315,72.323 84.656,84.891 89.84,86.407L91.244,64.519Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M91.244,64.519C84.315,72.323 84.656,84.891 89.84,86.407' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M108.502,77.566L100.483,63.547C92.957,62.655 88.689,82.419 94.163,86.895C97.631,89.736 106.805,88.03 106.805,88.03L108.502,77.566Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M100.068,63.544C93.139,71.347 93.48,83.915 98.664,85.432L100.068,63.544Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M100.068,63.544C93.139,71.347 93.48,83.915 98.664,85.432' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M103,83.137C101.516,82.659 97.776,83.99 94.841,85.26C91.598,86.663 89.513,87.569 88.697,89.265C88.05,90.603 89.706,92.89 91.061,95.649C92.744,99.083 93.622,102.247 95.796,102.312C98.772,102.403 106.359,99.139 108.302,97.019C109.616,95.582 108.136,93.492 106.429,89.706C104.97,86.474 104.488,83.612 103,83.137Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M88.643,89.471L103.13,84.16L106.715,79.236C106.715,79.236 94.022,83.384 91.032,85.026C89.666,85.779 88.643,89.471 88.643,89.471Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M103.494,83.666L108.71,96.412L111.661,91.6L106.817,79.246L103.494,83.666Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M94.349,87.767C94.301,87.8 95.71,88.522 95.901,88.659C96.743,89.27 97.423,90.549 97.67,91.539C98.352,94.306 97.752,97.372 96.372,99.838C96.064,100.383 95.535,101.427 95.038,101.66' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M104.991,99.543C104.809,99.719 104.26,99.061 104.169,98.94C103.447,98.027 102.821,97.197 102.682,95.991C102.497,94.437 103.128,92.847 104.082,91.634C104.584,91.001 105.391,90.264 105.654,89.522' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M105.642,88.714C105.487,88.183 105.727,87.753 105.911,87.251C106.264,86.267 107.14,84.664 108.139,84.191' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M92.731,87.658C92.777,87.709 92.589,87.553 92.555,87.536C92.406,87.457 92.234,87.473 92.084,87.418C91.563,87.233 91.364,86.934 91.088,86.493C91.017,86.381 90.82,85.882 91.003,85.797' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M91.941,96.838C91.912,96.784 92.256,96.176 92.293,96.09C92.661,95.303 92.888,94.622 92.946,93.739C93.025,92.502 92.669,91.031 91.945,89.985C91.766,89.725 91.393,89.285 91.297,89.075' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M99.796,107.158C102.656,107.775 105.382,106.389 105.885,104.06C106.388,101.732 104.476,99.344 101.616,98.727C98.756,98.11 96.03,99.496 95.527,101.824C95.025,104.152 96.936,106.54 99.796,107.158Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M103.026,82.246C107.045,67.337 112.964,60.99 120.76,62.704C128.329,64.369 131.763,75.262 130.971,88.34C130.945,88.716 130.825,89.078 130.744,89.449C127.968,102.071 119.818,109.857 112.022,108.143C104.226,106.428 99.661,94.726 103.026,82.246Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M122.178,99.156C126.821,95.475 128.074,89.323 124.975,85.414C121.876,81.504 115.6,81.319 110.957,84.999C106.314,88.679 105.062,94.832 108.16,98.741C111.259,102.651 117.535,102.836 122.178,99.156Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M129.352,78.748C142.001,69.917 150.609,68.863 155.48,75.186C160.21,81.326 155.846,91.873 146.845,101.376C146.583,101.647 146.259,101.848 145.959,102.079C135.729,109.959 124.488,110.68 119.617,104.357C114.747,98.033 118.762,86.139 129.352,78.748Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M58.441,84.709C57.219,83.47 52.979,82.876 49.549,82.635C45.759,82.368 43.321,82.201 41.753,83.473C40.513,84.476 41.073,87.608 41.127,91.056C41.191,95.345 40.596,98.952 42.678,100.134C45.525,101.753 54.384,102.396 57.239,101.279C59.173,100.521 58.692,97.676 58.765,93.022C58.828,89.049 59.668,85.948 58.441,84.709Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M41.605,83.651L58.099,85.796L63.831,82.727C63.831,82.727 49.611,80.343 45.957,80.445C44.286,80.495 41.605,83.651 41.605,83.651Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M58.678,85.49L57.912,100.882L62.978,97.6L63.925,82.788L58.678,85.49Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.626,84.319C44.543,84.324 44.552,84.457 44.521,84.523C44.412,84.82 44.328,85.105 44.321,85.424C44.292,86.693 44.731,87.845 45.493,88.863C46.047,89.604 47.136,90.063 47.93,90.39C49.846,91.189 51.957,91.912 53.23,93.744C53.928,94.754 54.26,95.951 54.3,97.162C54.322,97.721 54.172,98.23 54.086,98.767C54.028,99.112 53.99,99.45 53.849,99.773C53.659,100.216 53.547,100.699 53.261,101.079C53.23,101.125 53.12,101.37 53.094,101.376' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.028,101.227C47.022,101.253 47.035,100.888 47.04,100.801C47.066,100.476 47.111,100.144 47.093,99.812C47.031,98.813 46.756,97.983 46.295,97.097C46.15,96.809 45.904,96.572 45.709,96.315C45.091,95.526 44.24,94.866 43.257,94.682C42.823,94.601 42.41,94.587 41.983,94.539C41.83,94.523 41.513,94.564 41.39,94.462' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.8,84.206C45.749,84.218 45.911,84.009 45.961,83.97C46.118,83.826 46.353,83.804 46.535,83.707C47.059,83.451 47.657,83.063 48.011,82.59C48.247,82.275 48.355,81.912 48.508,81.549C48.545,81.456 48.536,81.336 48.522,81.23C48.514,81.176 48.512,81.057 48.512,81.063' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.11,85.898C54.994,85.809 54.976,86.248 54.965,86.381C54.929,86.879 54.945,87.384 55.01,87.878C55.15,88.977 55.634,90.123 56.317,90.987C56.454,91.162 56.647,91.285 56.796,91.454C56.991,91.677 57.228,91.781 57.454,91.965C57.544,92.04 57.707,92.269 57.853,92.258' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M58.814,91.558C58.763,91.543 58.928,91.54 58.954,91.54C59.094,91.549 59.24,91.552 59.374,91.588C59.783,91.695 60.175,91.908 60.541,92.127C61.57,92.731 62.126,94.005 62.469,95.115C62.571,95.442 62.659,95.757 62.741,96.084C62.748,96.117 62.821,96.338 62.777,96.344' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.995,85.229C55.956,85.175 56.056,85.071 56.074,85.018C56.146,84.8 56.226,84.648 56.383,84.485C56.895,83.955 57.527,83.721 58.118,83.312C58.325,83.176 58.594,82.922 58.724,82.698C58.743,82.665 58.827,82.368 58.757,82.379' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M41.291,112.823C41.032,112.657 43.244,109.323 43.263,109.061C43.323,108.09 42.939,107.067 42.277,106.316C41.431,105.35 40.665,106.663 39.821,106.834C38.943,107.012 43.12,103.922 42.527,103.32C42.095,102.874 40.645,102.348 40.543,101.773C40.209,99.894 36.785,98.859 35.083,97.65C33.366,96.436 32.69,95.112 31.281,95.462C30.803,95.583 33.331,96.268 33.395,95.807C34.087,90.717 29.852,88.044 26.67,91.537C25.964,92.301 25.514,86.92 24.598,87.82C24.122,88.281 22.556,90.181 21.678,89.882C19.516,89.148 19.191,86.012 16.652,85.93C15.274,85.882 14.716,87.373 14.641,88.503C14.559,89.829 14.783,91.227 15.361,92.426C15.918,93.602 17.074,94.59 17.03,95.986C16.991,97.255 15.629,97.269 14.936,98.079C14.106,99.052 15.727,102.179 15.659,103.298C15.396,107.582 20.607,103.017 18.987,105.147C17.802,106.708 23.545,104.145 25.147,105.36C27.571,107.199 24.534,109.761 25.555,112.175C25.691,112.495 25.197,114.316 25.451,114.536C25.833,114.862 26.921,113.391 27.232,113.359C27.928,113.301 28.621,112.502 30.07,111.536C30.49,111.256 34.053,110.394 35.924,110.585C38.523,110.849 41.291,112.823 41.291,112.823Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.196,111.228C41.921,111.228 41.975,107.653 41.862,107.447C41.439,106.688 40.656,106.104 39.795,105.859C38.694,105.541 38.751,106.899 38.199,107.434C37.625,107.992 36.611,107.992 35.879,107.823C35.335,107.699 34.9,107.055 34.547,106.672C33.383,105.422 32.065,102.904 30.203,102.817C28.325,102.732 28.855,104.67 27.964,105.613C27.664,105.935 26.519,106.212 26.345,105.835C24.408,101.673 21.631,98.119 20.925,102.282C20.763,103.197 17.826,99.369 17.572,100.487C17.438,101.064 17.177,103.248 16.372,103.448C14.392,103.939 12.634,101.739 10.685,102.903C9.625,103.532 9.926,104.923 10.415,105.808C10.994,106.845 11.837,107.788 12.85,108.411C13.837,109.026 15.184,109.211 15.824,110.281C16.409,111.255 15.391,111.923 15.26,112.866C15.107,113.998 15.961,114.792 16.83,115.298C18.275,116.136 19.721,109.347 20.314,116.297C20.461,118.038 24.564,114.952 26.351,115.095C29.066,115.305 26.296,117.442 28.23,118.764C28.486,118.939 30.815,120.121 31.113,120.164C31.557,120.224 31.666,118.593 31.883,118.419C32.379,118.039 32.514,117.104 33.137,115.678C33.318,115.265 35.581,112.896 37.08,112.137C39.162,111.08 42.196,111.228 42.196,111.228Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.149,109.204C54.89,109.038 58.348,109.818 58.366,109.556C58.426,108.59 56.638,107.797 55.976,107.045C55.125,106.079 54.105,103.841 53.26,104.011C52.388,104.19 51.846,102.78 51.259,102.178C50.822,101.732 50.802,100.863 50.7,100.288C50.36,98.408 50.642,95.24 48.941,94.032C47.223,92.817 46.551,94.962 45.142,95.312C44.665,95.433 43.42,95.002 43.483,94.541C44.176,89.45 43.709,84.425 40.528,87.918C39.822,88.682 39.371,83.301 38.456,84.201C37.98,84.662 36.414,86.562 35.535,86.263C33.374,85.529 33.049,82.393 30.51,82.311C29.131,82.263 28.573,83.754 28.498,84.884C28.417,86.21 28.641,87.608 29.218,88.807C29.776,89.983 30.932,90.971 30.888,92.367C30.849,93.637 29.487,93.65 28.793,94.46C27.964,95.433 28.289,96.697 28.802,97.699C29.655,99.362 36.495,94.626 32.845,101.528C31.93,103.262 37.403,100.526 39.005,101.741C41.429,103.58 37.252,105.759 38.274,108.173C38.409,108.492 39.054,110.697 39.309,110.918C39.691,111.243 42.259,112.458 42.569,112.432C43.265,112.374 44.159,110.754 45.602,109.787C46.023,109.501 47.911,106.775 49.782,106.967C52.381,107.23 55.149,109.204 55.149,109.204Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M83.079,115.686C83.24,114.964 84.245,113.373 85.341,113.407C87.007,113.457 91.656,114.625 93.071,116.002C94.459,117.352 94.425,120.152 93.83,121.653C93.235,123.153 91.25,124.521 89.497,124.994C87.74,125.466 84.74,124.855 83.294,124.489C82.32,124.241 81.731,123.217 80.828,122.784C79.825,122.309 77.539,122.264 77.289,121.633C77.039,121.002 78.563,119.033 79.318,118.995C80.067,118.96 80.726,120.969 81.804,121.41C82.931,121.874 85.04,121.9 86.092,121.784C86.859,121.701 87.885,121.322 88.123,120.727C88.361,120.132 88.138,118.718 87.512,118.218C86.889,117.725 85.107,118.163 84.372,117.742C83.661,117.333 82.919,116.408 83.079,115.686Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M82.777,121.676L82.154,123.105' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M87.194,117.81C87.983,116.606 88.635,115.23 87.965,113.955' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M88.464,119.903C90.545,120.68 92.431,120.36 94.138,119.032' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M86.839,121.623C86.542,122.761 86.916,123.84 87.918,124.866' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M72.148,121.004C72.456,121.676 72.617,123.552 71.723,124.186C70.366,125.154 65.955,127.032 63.996,126.789C62.074,126.553 60.409,124.301 59.976,122.746C59.544,121.191 60.299,118.901 61.41,117.465C62.525,116.028 65.285,114.701 66.658,114.119C67.583,113.729 68.672,114.189 69.653,113.988C70.739,113.76 72.588,112.415 73.168,112.767C73.749,113.119 73.724,115.608 73.145,116.095C72.569,116.575 70.831,115.373 69.705,115.673C68.527,115.984 66.831,117.237 66.063,117.965C65.502,118.495 64.913,119.417 65.083,120.035C65.253,120.653 66.285,121.645 67.086,121.665C67.88,121.682 69.035,120.256 69.876,120.147C70.689,120.044 71.839,120.332 72.148,121.004Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M68.77,116.049L68.403,114.534' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M67.586,121.799C67.685,123.235 67.997,124.725 69.301,125.336' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M65.31,120.898C63.182,121.537 61.874,122.931 61.315,125.022' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M65.566,118.546C65.114,117.46 64.165,116.826 62.746,116.614' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.548,120.237C56.269,119.844 59.777,115.698 59.384,110.977C58.991,106.256 54.845,102.747 50.124,103.14C45.403,103.533 41.895,107.679 42.288,112.4C42.681,117.121 46.827,120.63 51.548,120.237Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.299,110.046C50.323,110.12 50.258,109.921 50.255,109.885C50.231,109.758 50.216,109.63 50.196,109.498C50.148,109.137 50.113,108.774 50.061,108.413C49.961,107.695 49.987,106.94 49.856,106.225C49.809,105.979 49.696,105.476 49.842,105.255C49.97,105.07 50.45,104.959 50.648,104.925C50.824,104.892 51.012,104.903 51.19,104.902C51.742,104.9 52.692,104.701 53.043,105.278C53.301,105.702 53.046,106.222 52.953,106.649C52.849,107.112 52.707,107.551 52.58,108.007C52.395,108.673 52.322,109.401 52.196,110.084' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.299,110.046C50.323,110.12 50.258,109.921 50.255,109.885C50.231,109.758 50.216,109.63 50.196,109.498C50.148,109.137 50.113,108.774 50.061,108.413C49.961,107.695 49.987,106.94 49.856,106.225C49.809,105.979 49.696,105.476 49.842,105.255C49.97,105.07 50.45,104.959 50.648,104.925C50.824,104.892 51.012,104.903 51.19,104.902C51.742,104.9 52.692,104.701 53.043,105.278C53.301,105.702 53.046,106.222 52.953,106.649C52.849,107.112 52.707,107.551 52.58,108.007C52.395,108.673 52.322,109.401 52.196,110.084' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.826,110.25C49.546,110.211 49.304,109.866 49.06,109.721C48.281,109.242 47.526,108.739 46.778,108.214C46.509,108.027 46.23,107.618 45.871,107.594C45.567,107.579 45.314,107.917 45.192,108.158C45.042,108.452 44.88,108.746 44.758,109.05C44.621,109.387 44.564,109.761 44.441,110.106C44.354,110.349 44.276,110.806 44.494,111.015C44.916,111.421 45.705,111.431 46.253,111.479C47.289,111.571 48.409,111.638 49.41,111.942' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.826,110.25C49.546,110.211 49.304,109.866 49.06,109.721C48.281,109.242 47.526,108.739 46.778,108.214C46.509,108.027 46.23,107.618 45.871,107.594C45.567,107.579 45.314,107.917 45.192,108.158C45.042,108.452 44.88,108.746 44.758,109.05C44.621,109.387 44.564,109.761 44.441,110.106C44.354,110.349 44.276,110.806 44.494,111.015C44.916,111.421 45.705,111.431 46.253,111.479C47.289,111.571 48.409,111.638 49.41,111.942' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.392,112.639C49.219,112.533 49.06,112.823 48.936,112.94C48.623,113.242 48.283,113.547 47.95,113.828C47.451,114.257 46.913,114.699 46.544,115.242C46.444,115.384 46.328,115.487 46.298,115.663C46.261,115.907 46.459,116.153 46.578,116.344C46.898,116.87 47.231,117.346 47.742,117.704C47.923,117.836 48.184,118.037 48.433,117.972C48.659,117.908 48.789,117.648 48.922,117.481C49.301,117 49.522,116.438 49.723,115.864C49.788,115.68 49.891,115.52 49.956,115.337C50.19,114.675 50.321,113.943 50.679,113.325' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.392,112.639C49.219,112.533 49.06,112.823 48.936,112.94C48.623,113.242 48.283,113.547 47.95,113.828C47.451,114.257 46.913,114.699 46.544,115.242C46.444,115.384 46.328,115.487 46.298,115.663C46.261,115.907 46.459,116.153 46.578,116.344C46.898,116.87 47.231,117.346 47.742,117.704C47.923,117.836 48.184,118.037 48.433,117.972C48.659,117.908 48.789,117.648 48.922,117.481C49.301,117 49.522,116.438 49.723,115.864C49.788,115.68 49.891,115.52 49.956,115.337C50.19,114.675 50.321,113.943 50.679,113.325' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.188,113.283C51.165,113.169 51.324,113.583 51.347,113.648C51.482,113.975 51.581,114.31 51.664,114.66C51.802,115.25 52.045,115.769 52.274,116.324C52.398,116.63 52.497,116.961 52.659,117.25C52.837,117.569 53.157,117.931 53.565,117.754C53.94,117.598 54.312,117.411 54.639,117.165C54.928,116.95 55.172,116.662 55.427,116.414C55.575,116.263 55.764,116.127 55.804,115.91C55.887,115.458 55.564,115.222 55.315,114.908C55.032,114.553 54.778,114.178 54.444,113.862C53.955,113.395 53.311,113.048 52.763,112.666' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.188,113.283C51.165,113.169 51.324,113.583 51.347,113.648C51.482,113.975 51.581,114.31 51.664,114.66C51.802,115.25 52.045,115.769 52.274,116.324C52.398,116.63 52.497,116.961 52.659,117.25C52.837,117.569 53.157,117.931 53.565,117.754C53.94,117.598 54.312,117.411 54.639,117.165C54.928,116.95 55.172,116.662 55.427,116.414C55.575,116.263 55.764,116.127 55.804,115.91C55.887,115.458 55.564,115.222 55.315,114.908C55.032,114.553 54.778,114.178 54.444,113.862C53.955,113.395 53.311,113.048 52.763,112.666' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.536,110.368C52.514,110.325 52.704,110.247 52.725,110.236C52.965,110.114 53.177,109.931 53.42,109.8C54.17,109.394 54.965,109.038 55.759,108.727C55.974,108.642 56.258,108.409 56.505,108.438C56.915,108.484 56.929,108.652 57.104,108.994C57.375,109.515 57.607,110.164 57.656,110.757C57.684,111.089 57.722,111.433 57.418,111.641C56.862,112.026 55.887,111.719 55.278,111.672C54.551,111.612 53.872,111.803 53.159,111.862' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.536,110.368C52.514,110.325 52.704,110.247 52.725,110.236C52.965,110.114 53.177,109.931 53.42,109.8C54.17,109.394 54.965,109.038 55.759,108.727C55.974,108.642 56.258,108.409 56.505,108.438C56.915,108.484 56.929,108.652 57.104,108.994C57.375,109.515 57.607,110.164 57.656,110.757C57.684,111.089 57.722,111.433 57.418,111.641C56.862,112.026 55.887,111.719 55.278,111.672C54.551,111.612 53.872,111.803 53.159,111.862' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.992,110.528C45.162,110.585 45.323,110.705 45.509,110.69L44.992,110.528Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.992,110.528C45.162,110.585 45.323,110.705 45.509,110.69' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.258,109.811C45.435,109.85 45.533,109.957 45.732,109.941L45.258,109.811Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.258,109.811C45.435,109.85 45.533,109.957 45.732,109.941' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.7,108.852C45.683,108.764 45.926,108.949 46.018,108.986L45.7,108.852Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.7,108.852C45.683,108.764 45.926,108.949 46.018,108.986' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.038,115.985C46.999,115.997 47.101,115.886 47.117,115.863C47.188,115.759 47.264,115.654 47.379,115.596' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.038,115.985C46.999,115.997 47.101,115.886 47.117,115.863C47.188,115.759 47.264,115.654 47.379,115.596' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.599,116.473C47.605,116.539 47.69,116.381 47.697,116.367C47.76,116.268 47.853,116.153 47.962,116.117' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.599,116.473C47.605,116.539 47.69,116.381 47.697,116.367C47.76,116.268 47.853,116.153 47.962,116.117' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.121,117.009C48.111,117.05 48.185,116.928 48.189,116.919C48.241,116.852 48.294,116.794 48.351,116.731' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.121,117.009C48.111,117.05 48.185,116.928 48.189,116.919C48.241,116.852 48.294,116.794 48.351,116.731' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.095,116.233C53.042,116.291 53.253,116.635 53.29,116.698L53.095,116.233Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.095,116.233C53.042,116.291 53.253,116.635 53.29,116.698' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M54.027,115.862C54.077,115.831 54.223,116.086 54.241,116.134L54.027,115.862Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M54.027,115.862C54.077,115.831 54.223,116.086 54.241,116.134' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M54.651,115.378C54.649,115.36 54.734,115.473 54.739,115.482C54.814,115.578 54.874,115.658 54.965,115.735' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M54.651,115.378C54.649,115.36 54.734,115.473 54.739,115.482C54.814,115.578 54.874,115.658 54.965,115.735' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.851,109.624C55.838,109.625 56.07,109.526 56.135,109.507L55.851,109.624Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.851,109.624C55.838,109.625 56.07,109.526 56.135,109.507' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.193,110.357C56.275,110.328 56.37,110.298 56.454,110.291L56.193,110.357Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.193,110.357C56.275,110.328 56.37,110.298 56.454,110.291' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.611,111.049L56.617,111.048L56.611,111.049Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.611,111.049L56.617,111.048' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.46,105.823C50.512,105.805 50.599,106.257 50.603,106.31L50.46,105.823Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.46,105.823C50.512,105.805 50.599,106.257 50.603,106.31' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.344,105.798C51.419,105.889 51.399,106.136 51.409,106.256L51.344,105.798Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.344,105.798C51.419,105.889 51.399,106.136 51.409,106.256' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.546,105.671C52.559,105.777 52.55,105.885 52.559,105.995L52.546,105.671Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.546,105.671C52.559,105.777 52.55,105.885 52.559,105.995' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.329,110.178C47.317,110.143 47.303,110.135 47.343,110.132L47.329,110.178Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.329,110.178C47.317,110.143 47.303,110.135 47.343,110.132' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.755,113.472C45.718,113.461 45.736,113.464 45.755,113.463L45.755,113.472Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.755,113.472C45.718,113.461 45.736,113.464 45.755,113.463' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.489,112.603C44.461,112.596 44.47,112.595 44.468,112.572L44.489,112.603Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.489,112.603C44.461,112.596 44.47,112.595 44.468,112.572' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.204,106.402C48.181,106.342 48.164,106.294 48.235,106.288L48.204,106.402Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.204,106.402C48.181,106.342 48.164,106.294 48.235,106.288' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.225,108.705C49.216,108.706 49.221,108.706 49.236,108.719L49.225,108.705Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.225,108.705C49.216,108.706 49.221,108.706 49.236,108.719' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.216,106.977C55.181,106.98 55.118,106.976 55.178,106.94L55.216,106.977Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.216,106.977C55.181,106.98 55.118,106.976 55.178,106.94' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.03,112.707C56.992,112.733 56.996,112.723 56.999,112.759L57.03,112.707Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.03,112.707C56.992,112.733 56.996,112.723 56.999,112.759' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.01,112.848C54.997,112.854 54.868,112.851 54.901,112.875L55.01,112.848Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.01,112.848C54.997,112.854 54.868,112.851 54.901,112.875' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.737,116.965C50.697,116.968 50.716,116.98 50.737,117.018L50.737,116.965Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.737,116.965C50.697,116.968 50.716,116.98 50.737,117.018' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.867,118.847C50.864,118.861 50.863,118.857 50.865,118.87L50.867,118.847Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.867,118.847C50.864,118.861 50.863,118.857 50.865,118.87' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.989,118.648C53.013,118.656 53.003,118.652 53.021,118.65L52.989,118.648Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.989,118.648C53.013,118.656 53.003,118.652 53.021,118.65' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.548,108.278C53.548,108.278 53.539,108.289 53.535,108.294L53.548,108.278Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.548,108.278C53.548,108.278 53.539,108.289 53.535,108.294' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M41.221,127.481C45.942,127.087 49.45,122.942 49.057,118.221C48.664,113.499 44.518,109.991 39.797,110.384C35.076,110.777 31.568,114.923 31.961,119.644C32.354,124.365 36.5,127.874 41.221,127.481Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M39.972,117.291C39.996,117.364 39.931,117.165 39.928,117.13C39.904,117.002 39.889,116.874 39.869,116.742C39.821,116.381 39.786,116.019 39.734,115.658C39.634,114.94 39.66,114.184 39.529,113.469C39.482,113.224 39.369,112.721 39.515,112.499C39.643,112.315 40.123,112.203 40.321,112.169C40.497,112.137 40.685,112.148 40.863,112.146C41.415,112.145 42.365,111.945 42.716,112.522C42.974,112.946 42.719,113.466 42.626,113.893C42.521,114.356 42.38,114.796 42.253,115.252C42.068,115.918 41.995,116.646 41.869,117.329' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M39.972,117.291C39.996,117.364 39.931,117.165 39.928,117.13C39.904,117.002 39.889,116.874 39.869,116.742C39.821,116.381 39.786,116.019 39.734,115.658C39.634,114.94 39.66,114.184 39.529,113.469C39.482,113.224 39.369,112.721 39.515,112.499C39.643,112.315 40.123,112.203 40.321,112.169C40.497,112.137 40.685,112.148 40.863,112.146C41.415,112.145 42.365,111.945 42.716,112.522C42.974,112.946 42.719,113.466 42.626,113.893C42.521,114.356 42.38,114.796 42.253,115.252C42.068,115.918 41.995,116.646 41.869,117.329' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M39.499,117.495C39.219,117.456 38.977,117.111 38.733,116.966C37.954,116.487 37.199,115.985 36.451,115.459C36.182,115.272 35.903,114.863 35.544,114.839C35.24,114.824 34.987,115.162 34.865,115.404C34.716,115.697 34.553,115.991 34.431,116.295C34.295,116.632 34.237,117.006 34.114,117.351C34.027,117.594 33.949,118.051 34.167,118.26C34.589,118.666 35.378,118.676 35.926,118.724C36.962,118.816 38.082,118.883 39.083,119.187' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M39.499,117.495C39.219,117.456 38.977,117.111 38.733,116.966C37.954,116.487 37.199,115.985 36.451,115.459C36.182,115.272 35.903,114.863 35.544,114.839C35.24,114.824 34.987,115.162 34.865,115.404C34.716,115.697 34.553,115.991 34.431,116.295C34.295,116.632 34.237,117.006 34.114,117.351C34.027,117.594 33.949,118.051 34.167,118.26C34.589,118.666 35.378,118.676 35.926,118.724C36.962,118.816 38.082,118.883 39.083,119.187' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M39.065,119.883C38.892,119.777 38.733,120.067 38.609,120.184C38.296,120.487 37.956,120.791 37.623,121.073C37.124,121.502 36.586,121.943 36.217,122.486C36.117,122.628 36.001,122.731 35.971,122.908C35.934,123.151 36.132,123.398 36.251,123.588C36.571,124.114 36.904,124.59 37.415,124.948C37.596,125.08 37.857,125.281 38.106,125.216C38.332,125.153 38.462,124.892 38.595,124.725C38.974,124.244 39.195,123.682 39.396,123.108C39.461,122.925 39.564,122.765 39.629,122.581C39.863,121.92 39.994,121.187 40.352,120.569' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M39.065,119.883C38.892,119.777 38.733,120.067 38.609,120.184C38.296,120.487 37.956,120.791 37.623,121.073C37.124,121.502 36.586,121.943 36.217,122.486C36.117,122.628 36.001,122.731 35.971,122.908C35.934,123.151 36.132,123.398 36.251,123.588C36.571,124.114 36.904,124.59 37.415,124.948C37.596,125.08 37.857,125.281 38.106,125.216C38.332,125.153 38.462,124.892 38.595,124.725C38.974,124.244 39.195,123.682 39.396,123.108C39.461,122.925 39.564,122.765 39.629,122.581C39.863,121.92 39.994,121.187 40.352,120.569' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.861,120.528C40.838,120.414 40.997,120.828 41.021,120.893C41.155,121.221 41.254,121.555 41.337,121.905C41.475,122.495 41.719,123.014 41.947,123.569C42.071,123.875 42.17,124.206 42.332,124.495C42.51,124.815 42.83,125.176 43.238,124.999C43.613,124.843 43.985,124.656 44.312,124.411C44.602,124.195 44.845,123.907 45.1,123.659C45.248,123.508 45.438,123.372 45.477,123.155C45.56,122.703 45.237,122.467 44.989,122.153C44.705,121.798 44.451,121.423 44.117,121.108C43.628,120.64 42.984,120.293 42.436,119.911' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.861,120.528C40.838,120.414 40.997,120.828 41.021,120.893C41.155,121.221 41.254,121.555 41.337,121.905C41.475,122.495 41.719,123.014 41.947,123.569C42.071,123.875 42.17,124.206 42.332,124.495C42.51,124.815 42.83,125.176 43.238,124.999C43.613,124.843 43.985,124.656 44.312,124.411C44.602,124.195 44.845,123.907 45.1,123.659C45.248,123.508 45.438,123.372 45.477,123.155C45.56,122.703 45.237,122.467 44.989,122.153C44.705,121.798 44.451,121.423 44.117,121.108C43.628,120.64 42.984,120.293 42.436,119.911' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.209,117.613C42.187,117.57 42.377,117.492 42.398,117.481C42.638,117.359 42.85,117.176 43.093,117.045C43.843,116.639 44.638,116.283 45.432,115.972C45.647,115.887 45.931,115.654 46.178,115.683C46.588,115.729 46.602,115.897 46.777,116.239C47.048,116.76 47.28,117.409 47.329,118.002C47.357,118.333 47.394,118.678 47.091,118.886C46.535,119.271 45.56,118.964 44.951,118.917C44.224,118.857 43.545,119.047 42.832,119.107' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.209,117.613C42.187,117.57 42.377,117.492 42.398,117.481C42.638,117.359 42.85,117.176 43.093,117.045C43.843,116.639 44.638,116.283 45.432,115.972C45.647,115.887 45.931,115.654 46.178,115.683C46.588,115.729 46.602,115.897 46.777,116.239C47.048,116.76 47.28,117.409 47.329,118.002C47.357,118.333 47.394,118.678 47.091,118.886C46.535,119.271 45.56,118.964 44.951,118.917C44.224,118.857 43.545,119.047 42.832,119.107' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M34.666,117.773C34.836,117.83 34.997,117.95 35.183,117.935L34.666,117.773Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M34.666,117.773C34.836,117.83 34.997,117.95 35.183,117.935' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M34.931,117.056C35.108,117.094 35.206,117.202 35.405,117.185L34.931,117.056Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M34.931,117.056C35.108,117.094 35.206,117.202 35.405,117.185' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M35.373,116.096C35.356,116.009 35.599,116.193 35.691,116.23L35.373,116.096Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M35.373,116.096C35.356,116.009 35.599,116.193 35.691,116.23' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M36.711,123.23C36.672,123.242 36.774,123.131 36.789,123.108C36.861,123.004 36.937,122.899 37.052,122.841' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M36.711,123.23C36.672,123.242 36.774,123.131 36.789,123.108C36.861,123.004 36.937,122.899 37.052,122.841' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.272,123.717C37.278,123.784 37.363,123.625 37.371,123.611C37.434,123.512 37.527,123.398 37.635,123.362' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.272,123.717C37.278,123.784 37.363,123.625 37.371,123.611C37.434,123.512 37.527,123.398 37.635,123.362' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.794,124.253C37.784,124.294 37.858,124.172 37.862,124.163C37.914,124.096 37.967,124.038 38.025,123.976' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.794,124.253C37.784,124.294 37.858,124.172 37.862,124.163C37.914,124.096 37.967,124.038 38.025,123.976' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.768,123.478C42.715,123.536 42.926,123.879 42.963,123.943L42.768,123.478Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.768,123.478C42.715,123.536 42.926,123.879 42.963,123.943' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M43.7,123.106C43.751,123.075 43.897,123.331 43.914,123.378L43.7,123.106Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M43.7,123.106C43.751,123.075 43.897,123.331 43.914,123.378' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.324,122.623C44.322,122.605 44.407,122.718 44.412,122.727C44.487,122.823 44.547,122.902 44.638,122.98' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.324,122.623C44.322,122.605 44.407,122.718 44.412,122.727C44.487,122.823 44.547,122.902 44.638,122.98' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.524,116.868C45.511,116.87 45.743,116.77 45.808,116.751L45.524,116.868Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.524,116.868C45.511,116.87 45.743,116.77 45.808,116.751' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.866,117.602C45.948,117.573 46.043,117.543 46.127,117.536L45.866,117.602Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.866,117.602C45.948,117.573 46.043,117.543 46.127,117.536' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M46.285,118.293L46.29,118.293L46.285,118.293Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M46.285,118.293L46.29,118.293' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.133,113.067C40.185,113.049 40.272,113.501 40.276,113.554L40.133,113.067Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.133,113.067C40.185,113.049 40.272,113.501 40.276,113.554' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M41.017,113.043C41.092,113.135 41.072,113.381 41.082,113.501L41.017,113.043Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M41.017,113.043C41.092,113.135 41.072,113.381 41.082,113.501' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.219,112.915C42.232,113.021 42.223,113.129 42.232,113.24L42.219,112.915Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.219,112.915C42.232,113.021 42.223,113.129 42.232,113.24' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.003,117.422C36.991,117.387 36.977,117.379 37.017,117.376L37.003,117.422Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.003,117.422C36.991,117.387 36.977,117.379 37.017,117.376' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M35.428,120.717C35.391,120.706 35.409,120.709 35.428,120.708L35.428,120.717Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M35.428,120.717C35.391,120.706 35.409,120.709 35.428,120.708' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M34.162,119.848C34.134,119.841 34.143,119.84 34.141,119.817L34.162,119.848Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M34.162,119.848C34.134,119.841 34.143,119.84 34.141,119.817' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.877,113.647C37.855,113.586 37.837,113.539 37.908,113.533L37.877,113.647Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.877,113.647C37.855,113.586 37.837,113.539 37.908,113.533' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M38.898,115.95C38.889,115.951 38.894,115.95 38.909,115.963L38.898,115.95Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M38.898,115.95C38.889,115.951 38.894,115.95 38.909,115.963' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.889,114.221C44.854,114.224 44.791,114.22 44.851,114.184L44.889,114.221Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.889,114.221C44.854,114.224 44.791,114.22 44.851,114.184' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M46.703,119.952C46.665,119.977 46.669,119.968 46.672,120.003L46.703,119.952Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M46.703,119.952C46.665,119.977 46.669,119.968 46.672,120.003' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.683,120.093C44.67,120.099 44.541,120.096 44.574,120.12L44.683,120.093Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M44.683,120.093C44.67,120.099 44.541,120.096 44.574,120.12' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.41,124.21C40.37,124.213 40.389,124.225 40.41,124.263L40.41,124.21Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.41,124.21C40.37,124.213 40.389,124.225 40.41,124.263' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.54,126.092C40.537,126.106 40.536,126.102 40.538,126.115L40.54,126.092Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M40.54,126.092C40.537,126.106 40.536,126.102 40.538,126.115' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.662,125.893C42.686,125.9 42.677,125.896 42.695,125.895L42.662,125.893Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.662,125.893C42.686,125.9 42.677,125.896 42.695,125.895' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M43.221,115.523C43.221,115.523 43.212,115.534 43.208,115.539L43.221,115.523Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M43.221,115.523C43.221,115.523 43.212,115.534 43.208,115.539' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M54.203,131.165C58.924,130.772 62.433,126.626 62.04,121.905C61.647,117.184 57.501,113.675 52.78,114.068C48.059,114.461 44.55,118.607 44.943,123.328C45.336,128.049 49.482,131.558 54.203,131.165Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.955,120.974C52.978,121.048 52.913,120.849 52.91,120.813C52.886,120.686 52.871,120.558 52.851,120.426C52.803,120.065 52.768,119.702 52.716,119.341C52.616,118.623 52.642,117.868 52.512,117.153C52.464,116.907 52.351,116.404 52.498,116.183C52.625,115.998 53.106,115.887 53.303,115.853C53.479,115.82 53.667,115.831 53.845,115.83C54.397,115.828 55.348,115.629 55.699,116.206C55.957,116.63 55.701,117.15 55.608,117.577C55.504,118.04 55.362,118.479 55.235,118.935C55.05,119.601 54.977,120.329 54.851,121.012' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.955,120.974C52.978,121.048 52.913,120.849 52.91,120.813C52.886,120.686 52.871,120.558 52.851,120.426C52.803,120.065 52.768,119.702 52.716,119.341C52.616,118.623 52.642,117.868 52.512,117.153C52.464,116.907 52.351,116.404 52.498,116.183C52.625,115.998 53.106,115.887 53.303,115.853C53.479,115.82 53.667,115.831 53.845,115.83C54.397,115.828 55.348,115.629 55.699,116.206C55.957,116.63 55.701,117.15 55.608,117.577C55.504,118.04 55.362,118.479 55.235,118.935C55.05,119.601 54.977,120.329 54.851,121.012' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.481,121.178C52.202,121.139 51.959,120.794 51.716,120.649C50.936,120.17 50.181,119.667 49.434,119.142C49.164,118.955 48.885,118.546 48.527,118.522C48.222,118.507 47.97,118.845 47.847,119.086C47.698,119.38 47.535,119.674 47.414,119.978C47.277,120.315 47.219,120.689 47.096,121.034C47.009,121.277 46.932,121.733 47.149,121.943C47.571,122.349 48.36,122.359 48.908,122.407C49.945,122.499 51.064,122.566 52.065,122.87' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.481,121.178C52.202,121.139 51.959,120.794 51.716,120.649C50.936,120.17 50.181,119.667 49.434,119.142C49.164,118.955 48.885,118.546 48.527,118.522C48.222,118.507 47.97,118.845 47.847,119.086C47.698,119.38 47.535,119.674 47.414,119.978C47.277,120.315 47.219,120.689 47.096,121.034C47.009,121.277 46.932,121.733 47.149,121.943C47.571,122.349 48.36,122.359 48.908,122.407C49.945,122.499 51.064,122.566 52.065,122.87' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.048,123.567C51.874,123.461 51.715,123.751 51.592,123.868C51.278,124.17 50.938,124.475 50.605,124.756C50.106,125.185 49.568,125.627 49.199,126.17C49.1,126.312 48.983,126.415 48.953,126.591C48.916,126.835 49.115,127.081 49.233,127.272C49.553,127.798 49.887,128.274 50.398,128.632C50.578,128.764 50.84,128.965 51.088,128.9C51.315,128.836 51.444,128.576 51.578,128.409C51.956,127.927 52.177,127.366 52.379,126.792C52.444,126.608 52.546,126.448 52.611,126.265C52.845,125.603 52.976,124.871 53.335,124.253' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.048,123.567C51.874,123.461 51.715,123.751 51.592,123.868C51.278,124.17 50.938,124.475 50.605,124.756C50.106,125.185 49.568,125.627 49.199,126.17C49.1,126.312 48.983,126.415 48.953,126.591C48.916,126.835 49.115,127.081 49.233,127.272C49.553,127.798 49.887,128.274 50.398,128.632C50.578,128.764 50.84,128.965 51.088,128.9C51.315,128.836 51.444,128.576 51.578,128.409C51.956,127.927 52.177,127.366 52.379,126.792C52.444,126.608 52.546,126.448 52.611,126.265C52.845,125.603 52.976,124.871 53.335,124.253' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.843,124.211C53.82,124.097 53.98,124.511 54.003,124.576C54.137,124.903 54.236,125.238 54.319,125.588C54.457,126.178 54.701,126.697 54.93,127.252C55.053,127.558 55.152,127.889 55.314,128.178C55.492,128.497 55.812,128.859 56.221,128.682C56.595,128.526 56.967,128.339 57.294,128.093C57.584,127.878 57.827,127.59 58.083,127.342C58.231,127.191 58.42,127.055 58.46,126.838C58.542,126.386 58.22,126.15 57.971,125.836C57.687,125.481 57.433,125.106 57.1,124.79C56.611,124.323 55.967,123.976 55.418,123.594' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.843,124.211C53.82,124.097 53.98,124.511 54.003,124.576C54.137,124.903 54.236,125.238 54.319,125.588C54.457,126.178 54.701,126.697 54.93,127.252C55.053,127.558 55.152,127.889 55.314,128.178C55.492,128.497 55.812,128.859 56.221,128.682C56.595,128.526 56.967,128.339 57.294,128.093C57.584,127.878 57.827,127.59 58.083,127.342C58.231,127.191 58.42,127.055 58.46,126.838C58.542,126.386 58.22,126.15 57.971,125.836C57.687,125.481 57.433,125.106 57.1,124.79C56.611,124.323 55.967,123.976 55.418,123.594' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.191,121.296C55.17,121.253 55.359,121.175 55.381,121.164C55.62,121.042 55.832,120.859 56.075,120.728C56.825,120.322 57.62,119.966 58.414,119.655C58.63,119.57 58.913,119.337 59.161,119.366C59.57,119.412 59.584,119.58 59.759,119.922C60.03,120.443 60.262,121.092 60.312,121.685C60.339,122.017 60.377,122.361 60.073,122.569C59.517,122.954 58.543,122.647 57.933,122.6C57.206,122.54 56.527,122.731 55.814,122.79' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.191,121.296C55.17,121.253 55.359,121.175 55.381,121.164C55.62,121.042 55.832,120.859 56.075,120.728C56.825,120.322 57.62,119.966 58.414,119.655C58.63,119.57 58.913,119.337 59.161,119.366C59.57,119.412 59.584,119.58 59.759,119.922C60.03,120.443 60.262,121.092 60.312,121.685C60.339,122.017 60.377,122.361 60.073,122.569C59.517,122.954 58.543,122.647 57.933,122.6C57.206,122.54 56.527,122.731 55.814,122.79' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.648,121.456C47.818,121.513 47.979,121.633 48.165,121.618L47.648,121.456Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.648,121.456C47.818,121.513 47.979,121.633 48.165,121.618' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.914,120.739C48.09,120.777 48.189,120.885 48.388,120.869L47.914,120.739Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.914,120.739C48.09,120.777 48.189,120.885 48.388,120.869' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.355,119.78C48.339,119.692 48.581,119.877 48.673,119.913L48.355,119.78Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.355,119.78C48.339,119.692 48.581,119.877 48.673,119.913' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.693,126.913C49.654,126.925 49.756,126.814 49.772,126.791C49.843,126.687 49.919,126.582 50.035,126.524' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.693,126.913C49.654,126.925 49.756,126.814 49.772,126.791C49.843,126.687 49.919,126.582 50.035,126.524' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.255,127.401C50.26,127.467 50.345,127.309 50.353,127.295C50.416,127.196 50.509,127.081 50.617,127.045' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.255,127.401C50.26,127.467 50.345,127.309 50.353,127.295C50.416,127.196 50.509,127.081 50.617,127.045' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.777,127.937C50.767,127.978 50.841,127.856 50.845,127.847C50.897,127.78 50.95,127.722 51.007,127.659' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.777,127.937C50.767,127.978 50.841,127.856 50.845,127.847C50.897,127.78 50.95,127.722 51.007,127.659' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.75,127.161C55.697,127.219 55.909,127.563 55.945,127.626L55.75,127.161Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.75,127.161C55.697,127.219 55.909,127.563 55.945,127.626' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.682,126.79C56.733,126.759 56.879,127.014 56.897,127.062L56.682,126.79Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.682,126.79C56.733,126.759 56.879,127.014 56.897,127.062' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.306,126.306C57.304,126.288 57.39,126.401 57.395,126.41C57.469,126.506 57.53,126.586 57.621,126.663' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.306,126.306C57.304,126.288 57.39,126.401 57.395,126.41C57.469,126.506 57.53,126.586 57.621,126.663' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M58.506,120.552C58.493,120.553 58.725,120.454 58.791,120.435L58.506,120.552Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M58.506,120.552C58.493,120.553 58.725,120.454 58.791,120.435' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M58.848,121.285C58.93,121.256 59.026,121.226 59.11,121.219L58.848,121.285Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M58.848,121.285C58.93,121.256 59.026,121.226 59.11,121.219' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M59.267,121.977C59.267,121.977 59.267,121.977 59.272,121.976L59.267,121.977Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M59.267,121.977C59.267,121.977 59.267,121.977 59.272,121.976' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.115,116.751C53.167,116.733 53.254,117.185 53.259,117.238L53.115,116.751Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.115,116.751C53.167,116.733 53.254,117.185 53.259,117.238' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M54,116.726C54.074,116.817 54.054,117.064 54.064,117.184L54,116.726Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M54,116.726C54.074,116.817 54.054,117.064 54.064,117.184' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.201,116.599C55.214,116.705 55.205,116.812 55.215,116.923L55.201,116.599Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.201,116.599C55.214,116.705 55.205,116.812 55.215,116.923' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.985,121.106C49.973,121.071 49.959,121.063 49.999,121.06L49.985,121.106Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.985,121.106C49.973,121.071 49.959,121.063 49.999,121.06' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.411,124.4C48.373,124.389 48.392,124.392 48.41,124.391L48.411,124.4Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.411,124.4C48.373,124.389 48.392,124.392 48.41,124.391' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.144,123.531C47.116,123.524 47.125,123.523 47.123,123.5L47.144,123.531Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M47.144,123.531C47.116,123.524 47.125,123.523 47.123,123.5' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.86,117.33C50.837,117.27 50.82,117.222 50.89,117.216L50.86,117.33Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M50.86,117.33C50.837,117.27 50.82,117.222 50.89,117.216' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.881,119.633C51.871,119.634 51.876,119.634 51.891,119.647L51.881,119.633Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.881,119.633C51.871,119.634 51.876,119.634 51.891,119.647' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.872,117.905C57.836,117.908 57.774,117.904 57.833,117.868L57.872,117.905Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.872,117.905C57.836,117.908 57.774,117.904 57.833,117.868' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M59.685,123.635C59.647,123.661 59.651,123.651 59.654,123.687L59.685,123.635Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M59.685,123.635C59.647,123.661 59.651,123.651 59.654,123.687' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.665,123.776C57.652,123.782 57.523,123.779 57.556,123.803L57.665,123.776Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.665,123.776C57.652,123.782 57.523,123.779 57.556,123.803' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.392,127.893C53.352,127.896 53.371,127.908 53.392,127.946L53.392,127.893Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.392,127.893C53.352,127.896 53.371,127.908 53.392,127.946' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.523,129.775C53.519,129.789 53.519,129.785 53.52,129.798L53.523,129.775Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M53.523,129.775C53.519,129.789 53.519,129.785 53.52,129.798' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.645,129.576C55.668,129.583 55.659,129.579 55.677,129.578L55.645,129.576Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M55.645,129.576C55.668,129.583 55.659,129.579 55.677,129.578' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.203,119.207C56.203,119.207 56.194,119.217 56.19,119.222L56.203,119.207Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='1px' strokeLinecap='butt' strokeMiterlimit='2' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M56.203,119.207C56.203,119.207 56.194,119.217 56.19,119.222' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M132.306,103.667C138.206,104.208 143.359,100.62 143.815,95.653C144.271,90.685 139.857,86.219 133.957,85.678C128.057,85.136 122.904,88.725 122.449,93.692C121.993,98.66 126.406,103.125 132.306,103.667Z' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M97.313,116.532C97.307,116.556 97.373,116.508 97.391,116.496C97.426,116.473 97.485,116.467 97.521,116.443C97.61,116.378 97.96,116.372 98.043,116.401C98.161,116.443 98.274,116.461 98.393,116.49C98.76,116.591 99.122,116.947 99.383,117.208' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M100.907,120.783C100.936,120.843 101.031,120.866 101.085,120.902' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M104.317,118.056C104.311,118.044 104.376,118.038 104.388,118.038C104.418,118.032 104.441,117.996 104.465,117.978C104.554,117.89 104.667,117.812 104.732,117.706C104.827,117.557 104.963,117.368 104.981,117.19C104.993,117.042 104.933,116.87 104.981,116.727' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M72.859,126.66C73.203,126.233 73.399,125.907 73.956,125.682C74.075,125.634 74.211,125.51 74.335,125.51' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M76.245,113.799C76.227,113.805 76.233,113.799 76.233,113.817' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M79.329,113.696C79.304,113.672 79.311,113.666 79.311,113.696' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M73.423,118.885C73.263,118.517 73.898,118.132 74.129,117.99' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M63.982,128.409C63.982,128.409 63.988,128.409 63.988,128.416' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M80.816,127.021C81.024,127.566 81.878,127.453 82.269,127.299' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M108.598,119.691C108.23,119.448 108.936,118.743 109.268,118.577' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M62.061,102.749C61.818,102.761 62.15,102.085 62.173,101.973C62.274,101.439 62.891,101.391 63.158,101.042' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M66.663,96.685C66.65,96.673 66.657,96.679 66.638,96.679' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M63.574,105.105L63.574,105.118' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M57.821,103.456C58.023,103.557 58.206,103.622 58.438,103.587' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M81.528,93.744C81.658,93.969 82.103,94.052 82.34,94.052' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M86.628,94.815C86.628,94.815 86.734,94.691 86.794,94.655C86.948,94.566 87.179,94.572 87.345,94.602C87.879,94.703 88.282,95.094 88.543,95.551' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M89.541,97.366C89.523,97.36 89.523,97.384 89.505,97.39' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M14.046,105.525C14.094,105.626 14.438,105.881 14.533,105.857' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M16.857,106.92C16.845,107.056 17.557,107.347 17.663,107.37C18.654,107.596 19.697,107.845 20.634,108.224C21.666,108.633 22.775,108.865 23.789,109.321C25.538,110.104 27.187,111.053 28.818,112.043C29.482,112.446 30.49,112.844 31.006,113.448' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M17.794,90.417C17.729,90.381 17.782,90.428 17.8,90.44' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M19.111,91.839C19.123,91.874 19.07,91.91 19.141,91.952' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M20.528,93.447C20.54,93.601 20.884,93.779 20.991,93.88C21.465,94.319 22.023,94.669 22.479,95.114C24.341,96.928 26.298,98.577 27.982,100.563' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M28.878,101.701C28.913,101.742 28.901,101.814 28.961,101.802' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M31.392,85.892C31.398,86.183 31.54,86.313 31.665,86.544' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M33.028,88.393C33.989,90.67 36.005,92.526 37.719,94.258C38.567,95.118 39.373,96.019 40.304,96.802C40.571,97.033 40.743,97.353 40.98,97.608C41.858,98.51 42.795,99.482 43.453,100.567C43.791,101.131 44.016,101.759 44.36,102.311' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M20.403,105.904C20.409,105.703 20.166,105.406 20.119,105.199' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M18.234,111.372C18.542,111.502 19.022,111.01 19.212,110.826' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M22.271,113.75C22.277,113.667 22.633,113.543 22.698,113.489C23.054,113.187 23.481,112.991 23.801,112.635' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M30.384,109.914C30.241,109.102 30.401,108.147 30.585,107.346' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M24.34,107.619C24.316,107.423 24.227,107.227 24.221,107.008' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M23.439,93.671C23.617,93.256 23.7,92.502 23.783,92.01' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M25.834,95.327C25.828,95.368 25.84,95.267 25.876,95.208C25.941,95.101 26.006,94.994 26.06,94.87' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M19.52,97.082C19.532,97.153 19.841,97.189 19.912,97.201C20.41,97.284 20.908,97.147 21.394,97.029' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M25.307,100.45C25.277,100.444 25.473,100.397 25.514,100.391' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M32.21,94.454C32.198,94.376 32.477,94.459 32.506,94.459C32.672,94.471 32.898,94.442 33.052,94.424C33.621,94.359 34.22,94.127 34.748,93.914' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M36.74,98.795C36.687,98.795 36.989,98.658 37.019,98.646C37.25,98.569 37.487,98.516 37.718,98.427' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M37.339,91.234C37.244,90.273 37.416,89.093 37.873,88.233' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M41.046,94.241C40.933,93.957 41.093,93.565 41.188,93.287' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M45.588,99.495C45.86,99.092 46.097,98.629 46.406,98.273' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M42.57,103.646C42.576,103.593 43.038,103.474 43.109,103.438' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M74.519,102.418C74.495,102.399 74.507,102.424 74.507,102.443' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.467,88.061C52.39,88.067 52.515,88.026 52.574,88.026' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M52.111,83.626C52.123,83.603 52.069,83.543 52.123,83.591' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M86.256,105.046C86.25,104.957 86.499,104.797 86.564,104.732' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M95.452,95.248C95.381,95.207 95.607,95.201 95.678,95.165' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M98.826,89.787C98.838,89.544 99.099,89.319 99.211,89.105' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M99.681,90.435C99.706,90.404 99.675,90.441 99.662,90.453' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M100.213,75.105C100.219,74.993 100.93,75.005 101.049,74.963' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M100.7,73.071C100.813,73.041 101.151,73.248 101.258,73.302' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M81.244,76.688C81.279,76.711 81.226,76.699 81.19,76.699' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M72.877,74.748C72.906,74.393 72.96,74.102 73.049,73.752' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M73.742,72.39C73.742,72.419 73.748,72.402 73.707,72.437' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M62.926,74.742C62.872,74.766 62.831,74.748 62.831,74.826' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M62.553,78.148C62.547,78.225 62.559,78.237 62.589,78.326' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M112.903,87.843C112.832,87.872 112.945,87.855 112.968,87.855' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M114.487,87.007C114.41,87.084 114.594,86.74 114.659,86.68C114.801,86.532 115.044,86.449 115.228,86.36' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M116.355,71.589C116.343,71.518 116.337,71.518 116.408,71.518' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M107.075,78.711C107.11,77.768 107.614,76.902 108.095,76.102' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M109.357,74.215C109.369,74.15 109.369,74.18 109.369,74.126' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M125.576,74.619C125.558,74.59 125.914,74.192 125.997,74.192' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M125.973,75.704C126.05,75.656 126.163,75.639 126.252,75.591' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M155.167,79.327C155.066,79.333 155.143,79.499 155.137,79.553' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M155.023,81.777C154.958,81.848 155.041,82.24 155.035,82.352C154.999,82.933 154.863,83.366 154.673,83.912' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M153.418,86.699L153.405,86.699' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M142.53,78.567C142.553,78.508 142.524,78.609 142.565,78.662' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M143.982,85.489C143.798,84.842 144.409,86.337 144.474,86.467' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M144.967,87.974C144.96,87.974 144.96,87.967 144.96,87.961' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M147.771,77.566C147.972,77.589 148.358,77.34 148.548,77.233' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M148.169,78.391C148.151,78.462 148.471,78.379 148.537,78.367' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M134.999,92.498C134.981,92.48 134.999,92.515 135.023,92.533' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M128.814,99.037C129.027,99.156 129.763,98.711 129.935,98.586' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M130.052,99.685C129.916,99.768 130.348,99.561 130.497,99.507' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M122.629,103.45C122.523,103.438 122.552,103.486 122.535,103.586' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M113.726,104.873C113.958,104.933 114.278,105.17 114.586,105.217' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M113.835,97.869C113.817,97.857 113.829,97.863 113.823,97.899' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M130.118,83.265C130.113,83.004 130.617,82.477 130.866,82.293' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M49.543,142.752C49.472,142.764 49.395,142.853 49.478,142.835' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M51.125,143.506C51.137,143.548 51.149,143.589 51.161,143.625' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M66.555,152.282C66.377,152.365 66.721,152.057 66.751,152.009C66.846,151.849 66.911,151.653 66.952,151.464' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M67.646,152.383C67.575,152.157 67.712,152.027 67.759,151.795' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M75.86,139.995C76.755,140.102 77.307,140.244 78.25,140.007' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M79.643,140.149C79.69,140.138 79.679,140.179 79.732,140.167' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M130.83,132.204C130.735,132.174 130.747,132.263 130.729,132.34' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M133.438,131.031C133.527,130.533 134.696,129.969 135.045,129.578' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M134.902,132.251L134.902,132.263' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M106.392,139.024C106.884,139.006 107.163,138.852 107.619,138.585' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M109.406,137.654C109.4,137.648 109.394,137.648 109.387,137.641' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M106.743,151.957C106.725,151.903 106.695,151.844 106.766,151.868' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M150.219,111.688C150.35,111.729 150.907,111.545 151.097,111.504' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M150.748,112.854C150.826,112.812 151.051,112.919 151.128,112.937' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M133.214,115.493C133.238,115.44 133.226,115.398 133.285,115.416' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M135.105,114.408C135.135,114.355 135.123,114.385 135.135,114.325' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M20.61,124.553C20.462,124.695 20.628,124.654 20.759,124.695' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M27.596,122.93C27.513,122.645 28.284,122.242 28.515,122.111' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M48.386,133.657C48.38,133.551 48.356,133.669 48.35,133.705' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
        <g transform='matrix(1,0,0,1,0,-20)'>
          <path d='M98.69,160.846C98.696,160.829 98.702,160.811 98.708,160.793' fill={ fill === 'none' ? 'white' : fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px;' />
        </g>
      </g>
    </g>
  </SvgIcon>
);
