import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { ILogos } from '../../models/unsdgs';
import { ButtonKind } from '../Button/styles';
import { UNSDGItem, UNSDGsFilterPickerContainer } from './styles';

export interface IUNSDGItem {
  id: string;
  onClick: () => void;
  selected: boolean;
  logos: ILogos;
  name: string;
}

interface IProps {
  className?: string;
  unsdgItems: IUNSDGItem[];
}

const UNSDGsFilterPickerBase: React.FC<IProps> = ({
  className = '',
  unsdgItems,
}) => {
  const unsdgs = useMemo(() => unsdgItems.map((unsdgItem, index) => {
    if (index === 16) return null;

    return (
      <UNSDGItem
        key={ unsdgItem.id }
        onClick={ unsdgItem.onClick }
        className={ unsdgItem.selected ? 'selected' : '' }
        label={ unsdgItem.name }
        kind={ ButtonKind.Blank }
      >
        <img src={ unsdgItem.logos.outline } />
      </UNSDGItem>
    );
  }), [unsdgItems]);

  return (
    <UNSDGsFilterPickerContainer className={ className }>
      { unsdgs }
    </UNSDGsFilterPickerContainer>
  );
};

export const UNSDGsFilterPicker = observer(UNSDGsFilterPickerBase);
