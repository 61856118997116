import React, { useRef } from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../../styles/themes';
import { SvgIcon } from '../../SvgIcon';

interface IProps extends IThemeProps {
  className?: string;
  stroke?: string;
  fill?: string;
  id?: string;
}

export const ClapsBase: React.FC<IProps> = ({
  className = '',
  fill = '',
  stroke = '',
  id = '',
  theme,
}) => {
  const _stroke = useRef(stroke || theme.colors.darkGray1).current;
  const _fill = useRef(fill || '#fff').current;

  return (
    <SvgIcon
      className={ `${className ? className + ' ' : ''}claps-graphic icon` }
      id={ id }
      height={ 84 }
      width={ 63 }
      fill='none'
    >
      <path d='M21.8588 68.5709C17.7165 68.7285 17.1564 68.5709 17.1564 68.5709C15.8432 68.3831 14.5395 68.0164 13.2791 67.4638C7.16059 64.7797 3.57348 58.3541 4.49978 51.7375L8.08311 26.1386C8.26586 24.8343 9.40378 23.8778 10.7201 23.9224C13.4361 24.0147 15.538 26.3358 15.3609 29.0481L15.0356 34.0338L30.1144 15.4483C31.2435 14.0566 33.2154 13.6893 34.7697 14.5804C36.7096 15.6926 37.2044 18.2711 35.8141 20.0226L32.5158 24.1774L38.1866 17.0327C39.528 15.3428 41.9803 15.0482 43.6841 16.3727C45.4243 17.726 45.7131 20.2443 44.3253 21.9562L40.5309 26.6379L41.6437 25.51C42.8884 24.0028 45.1002 23.7466 46.6564 24.9291C48.2735 26.1581 48.5724 28.4729 47.3208 30.0724L41.5276 36.3492' fill='#fff' />
      <path d='M21.8588 68.5709C17.7165 68.7285 17.1564 68.5709 17.1564 68.5709C15.8432 68.3831 14.5395 68.0164 13.2791 67.4638C7.16059 64.7797 3.57348 58.3541 4.49978 51.7375L8.08311 26.1386C8.26586 24.8343 9.40378 23.8778 10.7201 23.9224C13.4361 24.0147 15.538 26.3358 15.3609 29.0481L15.0356 34.0338L30.1144 15.4483C31.2435 14.0566 33.2154 13.6893 34.7697 14.5804C36.7096 15.6926 37.2044 18.2711 35.8141 20.0226L32.5158 24.1774L38.1866 17.0327C39.528 15.3428 41.9803 15.0482 43.6841 16.3727C45.4243 17.726 45.7131 20.2443 44.3253 21.9562L40.5309 26.6379L41.6437 25.51C42.8884 24.0028 45.1002 23.7466 46.6564 24.9291C48.2735 26.1581 48.5724 28.4729 47.3208 30.0724L41.5276 36.3492' stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.281 82.8715C45.5593 82.4709 47.8967 82.1142 50.003 80.9034C55.9275 77.4978 58.7799 70.5007 56.9248 63.9237L49.3116 36.9313C48.7627 34.9857 46.6904 33.9025 44.7794 34.5625C43.0656 35.1547 42.0828 36.9533 42.5104 38.7154L44.7304 47.8628L40.1272 31.3114C39.5827 29.3545 37.543 28.221 35.5937 28.7925C33.7179 29.342 32.6107 31.2743 33.0849 33.1708L37.234 49.7668L31.8176 28.903C31.2637 26.7697 29.0657 25.5074 26.9437 26.104C24.8663 26.6881 23.6474 28.8364 24.2107 30.9195L29.8563 51.7896L25.2079 34.6039C24.6239 32.4455 22.2557 31.3126 20.2091 32.2132C18.5688 32.9347 17.694 34.7396 18.1436 36.4747L24.146 59.6427L20.3272 56.4204C18.2498 54.6677 15.1343 54.9811 13.4475 57.1119C12.6298 58.1449 12.7234 59.6282 13.6648 60.5495L32.136 78.6313C33.2136 79.6864 34.42 80.5505 35.7092 81.218C35.7092 81.218 39.3309 83.5661 43.281 82.8715Z' fill='#fff' stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M41.6449 25.5104C42.0707 24.9942 42.6102 24.6255 43.198 24.4101C42.9656 24.3247 42.7263 24.2645 42.4852 24.228L40.5315 26.6383L41.6449 25.5104Z' fill={ _fill } stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M35.8147 20.0226L38.1879 17.0333C38.6783 16.4154 39.3183 15.9846 40.0147 15.751C38.7725 15.3321 37.3834 15.5607 36.3384 16.3765C36.8119 17.5477 36.6725 18.9424 35.8147 20.0226Z' fill={ _fill } stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.0359 34.0337L30.1147 15.4482C30.5436 14.9201 31.0937 14.5395 31.6959 14.3191C30.2522 13.7897 28.5993 14.2193 27.6027 15.4482L15.2557 30.6664L15.0359 34.0337Z' fill={ _fill } stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.1571 68.5709C15.844 68.3831 14.5403 68.0164 13.2799 67.4637C7.16133 64.7797 3.57423 58.354 4.50052 51.7375L8.08386 26.1386C8.20883 25.2468 8.77967 24.5184 9.54897 24.1604C9.12633 24.0216 8.67731 23.9381 8.20883 23.9224C6.89255 23.8778 5.754 24.8342 5.57188 26.1386L1.98855 51.7375C1.06225 58.354 4.64936 64.7797 10.7679 67.4637C12.0289 68.017 13.3326 68.3831 14.6451 68.5709C14.6451 68.5709 15.0515 68.6852 17.7305 68.6218C17.2639 68.601 17.1571 68.5709 17.1571 68.5709Z' fill={ _stroke } stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M46.6182 11.0421L51.4531 4.51099' stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M34.4221 9.11933L34.7531 1' stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M52.2385 21.5108L60.1305 19.5735' stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.4839 31.3264L61.0889 34.1888' stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M24.6899 13.622L18.4119 8.4624' stroke={ _stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
};
  
export const Claps = withTheme(ClapsBase);
