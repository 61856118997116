import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection } from '../../styles/components/containers';

export const DigitalWalletTermsContainer = styled(MainContainer)``;

export const DigitalWalletTermsWrapper = styled(MaxBoundarySection)`
  h1, h4, h5 {
    margin-bottom: 10px;
  }

  .center {
    text-align: center;
  }

  .last-update {
    margin-bottom: 2rem;
  }

  .section-title-text {
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
