import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useUserSession } from '../../contexts/user';
import { UserGroupRole } from '../../models/user-groups';
import { UserRoles } from '../../models/users';

interface IProps {
  roles?: UserRoles[];
  groups?: string[];
  loggedIn?: boolean;
  karmaCardCardholder?: boolean | null;
  children: React.ReactNode;
  /**
   * a list of roles the user must have within a specified
   * group in order to access the component.
   * 
   * @important must be used with a group. if no group
   * is set, groupRoles is ignored.
   */
  groupRoles?: UserGroupRole[];
}

const ConditionalAccessBase: React.FC<IProps> = ({
  children,
  roles = [],
  groups = [],
  groupRoles = [],
  loggedIn,
  karmaCardCardholder = null,
}) => {
  const userSession = useUserSession();

  const hasKarmaCard = useMemo(() => {
    if (!userSession.isLoggedIn || !userSession.hasKarmaWalletCard) return false;
    return true;
  }, [userSession.hasKarmaWalletCard, userSession.isLoggedIn]);

  if (!userSession || !userSession.initialized) return null;

  if (typeof loggedIn === 'boolean' && userSession.isLoggedIn !== loggedIn) return null;

  if (karmaCardCardholder !== null) {
    if (!!userSession.initialized && !!userSession.isLoggedIn && !userSession.cardsLoaded) return null;
    if (!karmaCardCardholder && !!hasKarmaCard) return null;
    if (!!karmaCardCardholder && !hasKarmaCard) return null;
  }

  if (!!roles.length && !roles.find(role => role === userSession.role)) return null;
  if (!!groups.length) {
    const group = groups.find(group => !!userSession.groups.find(userGroup => userGroup.group._id === group));

    // if user is not a member of any of the groups provived, restrict access
    if (!group) return null;

    // if user is does not have one of the specified group roles, restrict access
    if (!!groupRoles.length) {
      const userGroup = userSession.groups.find(ug => ug.group._id === group);
      
      if (!groupRoles.find(role => role === userGroup.role)) return null;
    }
  }

  return <>{ children }</>;
};

export const ConditionalAccess = observer(ConditionalAccessBase);
