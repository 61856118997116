import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexCenter, FlexColCenter, BasicContainer } from '../../styles/styles';

export const TrustedSourcesContainer = styled(MaxBoundarySection)`
  ${FlexColCenter}
  align-items: center;

  & h2 {
    text-align: center;
  }

  .error-text {
    color: ${({ theme }) => theme.colors.disabled};
    margin: 40px auto 80px;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    max-width: 375px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    max-width: 450px;
  }
`;

export const SourceContainer = styled.div`
  ${FlexColCenter}
  padding-bottom: 20px;

  a {
    ${FlexCenter}
    ${BasicContainer}
    width: 100%;
    width: 100px;
    height: 100px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 150px;
      height: 150px;
    }

    svg, img {
      max-width: 80%;
      max-height: 90px;
      object-fit: container;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        max-height: 130px;
      }
    }
  }

  @media (max-width: 444px) {
    &:nth-of-type(10), &:nth-of-type(11), &:nth-of-type(12) {
      display: none;
    }
  }
`;

export const LoadingContainer = styled.div`
  height: 200px;
  position: relative;
`;
