/* eslint-disable no-debugger */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { WebServiceHelper } from '../lib/webServiceHelper';
import { BaseModel } from './base';
import { CollectionModel, ICollectionConfig } from './collection';
import { ISector } from './sectors';
import { IUNSDG, IUNSDGCategory, IUNSDGSubCategory } from './unsdgs';

type PrivateCompanyFields = '_busy' |
'_companiesOwned' |
'_company' |
'_companyDataSources' |
'_evaluatedUnsdgs' |
'_evaluatedUnsdgsCount' |
'_loadingMerchantRates' |
'_loadingPositiveImpactCompanies' |
'_loadingUnsdgs' |
'_merchantRates' |
'_positiveImpactCompanies' |
'_unsdgs' |
'_uploadingLogo' |
'_selectedUnsdg';

type PrivateFields = '_busy' |
'_companies' |
'_init';

type KarmaCollectiveFields = '_karmaCollectiveCompanies' |
'_loadingKarmaCollectiveCompanies';

export enum CompanyRating {
  Positive = 'positive',
  Neutral = 'neutral',
  Negative = 'negative',
}

export enum ImpactCategory {
  Planet = 'planet',
  People = 'people',
}

enum WildfireKind {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE',
}

export enum MerchantRateType {
  Flat = 'flat',
  Percent = 'percent',
  None = '',
}

enum OfferType {
  INSTORE = 'INSTORE',
  ONLINE = 'ONLINE',
}

enum OfferSource {
  LOCAL = 'LOCAL',
  NATIONAL = 'NATIONAL',
}

enum CommissionType {
  PERCENT = 'PERCENT',
  FLAT = 'FLAT',
}

interface IImage {
  fileName: string;
  fileSize: number;
  id: string;
  mimetype: string;
  owner: string;
  url: string;
}

export interface ICategoryScore {
  category: IUNSDGCategory;
  score: number;
}

export interface ISubcategoryScore {
  subcategory: IUNSDGSubCategory;
  score: number;
}

export interface ICompanySector {
  primary: boolean;
  sector: ISector;
}

export interface ICompanyHidden {
  status: boolean;
  reason: string;
  lastModified: Date;
}

export interface IUNSDGLogo {
  outline: string;
  solid: string;
  color: string;
  _id: string;
}

export interface ICompanyEvaluatedUnsdg {
  _id: string;
  title: string;
  subCategory: IUNSDGSubCategory;
  subCategoryIndex: number;
  goalNum: number;
  img: string;
  sourceUrl: string;
  description: string;
  subTitle: string;
  howToAcquire: string;
  lastModified: Date;
  logos: IUNSDGLogo;
}

export interface IEvaluatedUnsdg {
  unsdg: ICompanyEvaluatedUnsdg;
  evaluated: boolean;
  score: number;
  _id: string;
}

export interface IDataSource {
  name: string;
  url?: string;
  logoUrl: string;
  description?: string;
}

export interface ICompanyRef {
  _id: string;
  combinedScore?: number;
  companyName?: string;
  evaluatedUnsdgs: IEvaluatedUnsdg[];
  hidden: ICompanyHidden;
  isPartner?: boolean;
  logo?: string;
  merchant?: IMerchant;
  parentCompany?: ICompanyRef;
  rating: CompanyRating;
  sectors?: ICompanySector[];
  slug?: string;
  url?: string;
  selectedUnsdg: ICompanyEvaluatedUnsdg;
}

export interface ICompany extends ICompanyRef {
  categoryScores: ICategoryScore[];
  subcategoryScores: ISubcategoryScore[];
}

export interface ICompanyPartnerStatus {
  active: boolean;
  description: string;
}

export interface IPartnerCompany extends ICompanyRef {
  partnerStatus: ICompanyPartnerStatus;
}

export interface ICompanyUNSDG {
  _id: string;
  companyId: number; // TODO: this will need to updated once we update the compnay objects with ObjectId
  unsdg: IUNSDG;
  value: boolean; // TODO: update to number when new db is implemented
  year: number;
  createdAt: string;
  lastModified: string;
}

export interface ICompanyUnsdgScore {
  unsdg: IUNSDG;
  value: number;
}

export interface IGetCompanyResponse {
  company: ICompany;
  companyDataSources: IDataSource[];
  unsdgs: ICompanyUnsdgScore[];
  companiesOwned: ICompanyRef[];
}

export interface IWildfireOverview {
  merchantId: string;
  Name: string;
  PaysNewCustomersOnly: boolean;
  ShareAndEarnDisabled: boolean;
  domain: string;
  maxRate?: {
    type: MerchantRateType;
    amount: number;
  };
}

export interface IKardOverview {
  merchantId: string;
  Name: string;
  PaysNewCustomersOnly: boolean;
  domain: string;
  maxRate?: {
    type: MerchantRateType;
    amount: number;
  };
}

interface IWildfireMerchantRates {
  merchantId: string;
  ID: number;
  Name: string;
  Kind: WildfireKind;
  Amount: number;
  _id: string;
}

interface KardIntegration {
  id: string;
  name: string;
  merchantId: string;
  merchantLocationIds?: string[]; // location ids when isLocationSpecific is true
  offerType: OfferType;
  source?: OfferSource;
  commissionType: CommissionType;
  isLocationSpecific: boolean;
  optInRequired?: boolean;
  terms?: string;
  expirationDate: string;
  createdDate: string;
  lastModified: string;
  startDate?: string;
  redeemableOnce?: boolean;
  totalCommission: number;
  minRewardAmount?: number;
  maxRewardAmount?: number;
  minTransactionAmount?: number;
  maxTransactionAmount?: number;
}

interface IMerchantIntegrations {
  wildfire: IWildfireOverview;
  kard: IKardOverview;
}

interface IMerchantRatesIntegrations {
  wildfire: IWildfireMerchantRates;
  kard?: KardIntegration;
}

export interface IMerchant {
  _id: string;
  name: string;
  integrations: IMerchantIntegrations;
  karmaCollectiveMember?: boolean;
  maxDescription: string;
  maxAmount: string;
}

export interface IMerchantRate {
  _id: string;
  merchant: string;
  maxDescription: string;
  maxRateType: MerchantRateType;
  maxAmount: string;
  name: string;
  integrations: IMerchantRatesIntegrations;
}

export interface IGroupedEvaluatedUnsdgSubcategory {
  subcategory: string;
  unsdgs: IEvaluatedUnsdg[];
}

export interface IGroupedEvaluatedUnsdg {
  category: string;
  subcategories: IGroupedEvaluatedUnsdgSubcategory[];
}

export class CompanyModel extends BaseModel {
  private _busy = false;
  private _uploadingLogo = false;
  private _company: ICompanyRef | ICompany = null;
  private _companyDataSources: IDataSource[] = [];
  private _companiesOwned: CompanyModel[] = [];
  private _evaluatedUnsdgs: IGroupedEvaluatedUnsdg[] = [];
  private _evaluatedUnsdgsCount = 0;
  private _loadingMerchantRates = false;
  private _loadingPositiveImpactCompanies = false;
  private _merchantRates: IMerchantRate[] = [];
  private _positiveImpactCompanies: CompanyModel[] = [];
  private _unsdgs: ICompanyUnsdgScore[] = [];
  private _isAdmin = false;
  private _loadingUnsdgs = false;
  private _selectedUnsdg: IEvaluatedUnsdg = null;

  constructor(companyInfo: ICompanyRef, isAdmin = false, evaluatedUnsdgs?: IEvaluatedUnsdg[], unsdgInfo?: ICompanyUnsdgScore[], companiesOwned?: ICompanyRef[], companyDataSources?: IDataSource[]) {
    super({ basePath: '/company' });
    makeObservable<CompanyModel, PrivateCompanyFields>(this, {
      _busy: observable,
      _companiesOwned: observable,
      _company: observable,
      _companyDataSources: observable,
      _evaluatedUnsdgs: observable,
      _evaluatedUnsdgsCount: observable,
      _loadingMerchantRates: observable,
      _loadingPositiveImpactCompanies: observable,
      _merchantRates: observable,
      _positiveImpactCompanies: observable,
      _unsdgs: observable,
      _uploadingLogo: observable,
      _selectedUnsdg: observable,
      _loadingUnsdgs: observable,
      _id: computed,
      busy: computed,
      categoryScores: computed,
      combinedScore: computed,
      companiesOwned: computed,
      companyName: computed,
      companyDataSources: computed,
      evaluatedUnsdgs: computed,
      evaluatedUnsdgsCount: computed,
      hidden: computed,
      isPartner: computed,
      isKarmaCollectiveMember: computed,
      loaded: computed,
      loadingPositiveImpactCompanies: computed,
      loadingMerchantRates: computed,
      logo: computed,
      maxRate: computed,
      parentCompany: computed,
      positiveImpactCompanies: computed,
      rating: computed,
      sectors: computed,
      selectedUnsdg: computed,
      slug: computed,
      subcategoryScores: computed,
      unsdgs: computed,
      uploadingLogo: computed,
      url: computed,
      load: action.bound,
      loadMerchantRates: action.bound,
      loadPositiveImpactCompanies: action.bound,
      update: action.bound,
      uploadLogo: action.bound,
    });

    this._isAdmin = !!isAdmin;
    this._company = companyInfo;
    this._companyDataSources = companyDataSources;
    this._unsdgs = unsdgInfo;
    if (!!evaluatedUnsdgs) {
      const groupedUnsdgs = CompanyModel.groupEvaluatedUnsdgs(evaluatedUnsdgs);
      this._evaluatedUnsdgs = groupedUnsdgs;
      this._evaluatedUnsdgsCount = evaluatedUnsdgs.filter((evaluatedUnsdg) => evaluatedUnsdg.evaluated && evaluatedUnsdg.score !== null).length;
    }
    
    this._companiesOwned = (companiesOwned || []).map(c => new CompanyModel(c));
  }

  get _id() { return this._company._id; }
  get busy() { return this._busy || this._loadingUnsdgs; }
  get categoryScores() { return (this._company as ICompany).categoryScores || []; }
  get combinedScore() { return this._company.combinedScore; }
  get companiesOwned() { return this._companiesOwned; }
  get companyName() { return this._company.companyName; }
  get companyDataSources() { return this._companyDataSources ?? []; }
  get maxDescription() { return this._company.merchant?.maxDescription; }
  get maxRate() { return this._company.merchant?.maxAmount; }
  get merchantRates() { return this._merchantRates; }
  get merchant() { return this._company?.merchant; }
  get evaluatedUnsdgs() { return this._evaluatedUnsdgs; }
  get evaluatedUnsdgsCount() { return this._evaluatedUnsdgsCount; }
  get selectedUnsdg() { return this._selectedUnsdg; }
  get hidden() { return this._company.hidden; }
  // launching with karma wallet cashback hidden, remove this later
  get hasCashback() {
    return !!this._company.merchant?.maxAmount && this._company.rating !== CompanyRating.Negative;
  }
  get isKarmaCollectiveMember() { return !!this._company.merchant?.karmaCollectiveMember; }
  get isPartner() { return !!this._company.isPartner; }
  get loaded() { return !!this._company.companyName; }
  get loadingMerchantRates() { return this._loadingMerchantRates; }
  get loadingPositiveImpactCompanies() { return this._loadingPositiveImpactCompanies; }
  get logo() { return this._company.logo; }
  get parentCompany() { return this._company.parentCompany; }
  get positiveImpactCompanies() { return this._positiveImpactCompanies; }
  get rating() { return this._company.rating; }
  get sectors() { return this._company.sectors; }
  get slug() { return this._company.slug; }
  get subcategoryScores() { return (this._company as ICompany).subcategoryScores || []; }
  get unsdgs() { return this._unsdgs ?? []; }
  get uploadingLogo() { return this._uploadingLogo; }
  get url() { return this._company.url; }

  toJs = () => this._company;

  public setSelectedUnsdg = async (item: IEvaluatedUnsdg) => {
    runInAction(() => {
      this._selectedUnsdg = item;
    });
  };

  public loadMerchantRates = async () => {
    if (this._loadingMerchantRates) return;
    this._loadingMerchantRates = true;

    const result = await this.webServiceHelper.sendRequest<IMerchantRate[]>({
      path: `/${this._id}/merchant-rates`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._merchantRates = result.value;
        this._loadingMerchantRates = false;
      });
    } else {
      runInAction(() => {
        this._loadingMerchantRates = false;
      });

      throw new Error(result.error);
    }
  };

  public loadPositiveImpactCompanies = async (queryParams: object) => {
    if (this.loadingPositiveImpactCompanies) return;
    this._loadingPositiveImpactCompanies = true;

    const result = await this.webServiceHelper.sendRequest<ICompanyRef[]>({
      path: '/sample',
      method: 'GET',
      queryParams,
    });

    if (result.success) {
      runInAction(() => {
        this._positiveImpactCompanies = result.value.map(r => new CompanyModel(r, false, r.evaluatedUnsdgs));
        this._loadingPositiveImpactCompanies = false;
      });
    } else {
      runInAction(() => {
        this._loadingPositiveImpactCompanies = false;
      });

      throw new Error(result.error);
    }
  };

  load = async () => {
    if (!this._busy) {
      this._busy = true;

      const result = await this.webServiceHelper.sendRequest<IGetCompanyResponse>({
        path: `/${this._id}`,
        method: 'GET',
      });

      // TODO: api not returning 404 when not found...
      // need to fix -> https://github.com/karmawallet/karmawallet/issues/143
      if (result.success && !!result.value) {
        runInAction(() => {
          this._company = result.value.company;
          this._companyDataSources = result.value.companyDataSources;
          this._unsdgs = result.value.unsdgs;
          this._evaluatedUnsdgs = CompanyModel.groupEvaluatedUnsdgs(result.value.company.evaluatedUnsdgs);
          this._companiesOwned = result.value.companiesOwned.map(c => new CompanyModel(c));
          this._busy = false;
        });
      } else {
        runInAction(() => {
          this._busy = false;
        });

        throw new Error((result.success && !result.value) ? 'Company not found' : result.error);
      }
    }
  };

  update = async (data: Partial<ICompany>) => {
    if (this._busy || !this._isAdmin) return;

    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<ICompany>(
      {
        path: `/admin/company/${this._id}`,
        method: 'PUT',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._company = result.value;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  uploadLogo = async (files: FileList) => {
    if (!this._uploadingLogo) {
      this._uploadingLogo = true;

      const formData = new FormData();
      formData.append('file', files[0]);

      const result = await this.webServiceHelper.sendRequest<IImage>({
        path: '/logo',
        method: 'POST',
        data: formData,
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      if (result.success) {
        runInAction(() => {
          this._uploadingLogo = false;
        });
      } else {
        runInAction(() => {
          this._uploadingLogo = false;
        });

        throw new Error(result.error);
      }
    }
  };

  static loadPartnerCompany = async (companyId: string) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IPartnerCompany>({
      path: `/company/partner${ !!companyId ? `?companyId=${companyId}` : ''}`,
      method: 'GET',
    });

    if (result.success) {
      return result.value;
    } else {
      throw new Error(result.error);
    }
  };

  static loadCompany = async (companyId: string) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IGetCompanyResponse>({
      path: `/company/${companyId}`,
      method: 'GET',
    });

    if (result.success) {
      return new CompanyModel(result.value.company, false, result.value.company.evaluatedUnsdgs, result.value.unsdgs, result.value.companiesOwned, result.value.companyDataSources);
    } else {
      throw new Error(result.error);
    }
  };

  static groupEvaluatedUnsdgs = (unsdgs: IEvaluatedUnsdg[]) => unsdgs.reduce((acc, eu) => {
    const { unsdg } = eu;

    if (unsdg.goalNum === 17) return acc;

    const { subCategory } = unsdg;
    // guard clause for handling unpopulated evaluated unsdgs
    if (!subCategory) return acc;
    const { category } = subCategory;
    const { name: categoryName } = category;
    const { name: subcategoryName } = subCategory;
    let categoryObject = acc.find(c => c?.category === categoryName);

    if (!categoryObject) {
      categoryObject = { category: categoryName, subcategories: [] };
      acc.push(categoryObject);
    }

    let subcategoryObject = categoryObject.subcategories.find((s: IGroupedEvaluatedUnsdgSubcategory) => s?.subcategory === subcategoryName);

    if (!subcategoryObject) {
      subcategoryObject = { subcategory: subcategoryName, unsdgs: [] };
      categoryObject.subcategories.push(subcategoryObject);
    }

    subcategoryObject.unsdgs.push(eu);
    return acc;
  }, []);
}

export class CompaniesModel extends BaseModel {
  private _busy = false;
  private _config: ICollectionConfig = {};
  private _companies: CollectionModel<ICompany, CompanyModel> = null;
  private _isAdmin = false;

  constructor(config: ICollectionConfig = {}, isAdmin = false) {
    super({ basePath: '/company' });
    makeObservable<CompaniesModel, PrivateFields>(this, {
      _busy: observable,
      _companies: observable,
      companies: computed,
      busy: computed,
      _init: action.bound,
    });
    this._config = config || {};
    this._isAdmin = !!isAdmin;
    this._init();
  }

  get busy() { return this._busy; }
  get companies() { return this._companies; }

  private _init = () => {
    const url = '/company';
    this._companies = new CollectionModel<ICompany, CompanyModel>(
      url,
      (companyInfo: ICompany) => new CompanyModel(companyInfo, this._isAdmin, companyInfo.evaluatedUnsdgs, null),
      this._config,
    );
  };
}

export class KarmaCollectiveCompaniesModel extends BaseModel {
  private _karmaCollectiveCompanies : CompanyModel[] = [];
  private _loadingKarmaCollectiveCompanies = false;
  private _isAdmin = false;

  constructor(isAdmin = false) {
    super();
    makeObservable<KarmaCollectiveCompaniesModel, KarmaCollectiveFields>(this, {
      _karmaCollectiveCompanies: observable,
      _loadingKarmaCollectiveCompanies: observable,
      karmaCollectiveCompanies: computed,
      loadingKarmaCollectiveCompanies: computed,
      loadKarmaCollectiveCompanies: action.bound,
    });
    this._isAdmin = !!isAdmin;
  }

  get karmaCollectiveCompanies() { return this._karmaCollectiveCompanies; }
  get loadingKarmaCollectiveCompanies() { return this._loadingKarmaCollectiveCompanies; }

  public loadKarmaCollectiveCompanies = async () => {
    if (this.loadingKarmaCollectiveCompanies) return;
    this._loadingKarmaCollectiveCompanies = true;

    const result = await this.webServiceHelper.sendRequest<ICompany[]>({
      path: '/company/karma-collective',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._karmaCollectiveCompanies = result.value.map(r => new CompanyModel(r, this._isAdmin, r.evaluatedUnsdgs));
        this._loadingKarmaCollectiveCompanies = false;
      });
    } else {
      runInAction(() => {
        this._loadingKarmaCollectiveCompanies = false;
      });
      throw new Error(result.error);
    }
  };
}
