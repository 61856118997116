import React from 'react';
import { AppButtonsContainer, CardholderViewContainer } from './styles';
import { H2M, H5 } from '../../../styles/components/header';
import CardAndCoins from '../../../../public/assets/images/card-and-coins.png';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import AppStoreBadge from '../../../../public/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GooglePlayBadge from '../../../../public/assets/images/google-play-badge.svg';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps {
  className?: string;
}

export const CardholderView: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();

  const onAppleClick = () => {
    analytics.fireEvent('ApplyPage_AppleStore_Click');
  };

  const onGoogleClick = () => {
    analytics.fireEvent('ApplyPage_GoogleStore_Click');
  };
  
  const renderAppStoreButtons = () =>(
    <AppButtonsContainer>
      <ButtonLink
        className='app-store-link'
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onAppleClick }
        href='https://apps.apple.com/app/karma-wallet/id6451440075'
      >
        <img src={ AppStoreBadge } alt='App Store Badge' />
      </ButtonLink>
      <ButtonLink
        className='app-store-link'
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onGoogleClick }
        href='https://play.google.com/store/apps/details?id=com.karmawalletnative.app'
      >
        <img src={ GooglePlayBadge } alt='Google Play Badge' />
      </ButtonLink>
    </AppButtonsContainer>
  );

  return (
    <CardholderViewContainer className={ className }>
      <H2M>Thanks for being a part <br /> of the community!</H2M>
      <img src={ CardAndCoins } alt='Karma wallet card and coins graphic' />
      <H5>It looks like you already have a Karma Wallet Card. Way to go! Please access or download the Karma Wallet Mobile App to use and manage your card.</H5>
      {
        renderAppStoreButtons()
      }
      <p>If you have a question for our support team, reach out at <a href='mailto:support@karmawallet.io?subject=I have questions regarding my Karma Wallet Card or Account.'>support@karmawallet.io</a>.</p>
    </CardholderViewContainer>
  );
};
