import styled from 'styled-components';
import { BasicContainer, FlexRow, TruncateText } from '../../../../../styles/styles';
import { TextMDDemiBold } from '../../../../../styles/components/text';

interface ISubcategoryCardProps {
  isSummary?: boolean;
  active?: boolean;
}

export const SentimentSubcategoryCardContainer = styled.button<ISubcategoryCardProps>`
  ${BasicContainer}
  ${FlexRow}
  ${({ active, theme }) => active && `border: 1px solid ${theme.colors.primary};`}
  cursor: pointer;
  align-items: center;
  gap: 12px;
  height: ${({ isSummary }) => isSummary ? '60px' : '48px' };
  padding: 12px 16px;
  width: 100%;

  img {
    height: ${({ isSummary }) => isSummary ? '36px' : '24px' };
    width: auto;
  }

  .sentiment-subcategory-card-title {
    ${TextMDDemiBold}
    ${TruncateText}
    text-align: left;
    width: 100%;
  }
`;
