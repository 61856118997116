import styled from 'styled-components';
import { Section } from '../../styles/components/containers';
import { FlexCol } from '../../styles/styles';

export const GroupHeroContainer = styled(Section)`
  background: ${({ theme }) => theme.colors.light};
  padding: 80px 15px;
`;

export const GroupHeroInner = styled.div`
  ${FlexCol}
  align-items: center;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;

  .group-logo {
    width: 100%;
    max-width: 120px;
    max-height: 120px;

    .default-group-logo {
      width: 100px;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      max-width: 200px;
      max-height: 200px;

      & > img:not(.file-uploader) {
        max-width: 200px;
        max-height: 200px;
      }
    }
  }

  .header {
    text-align: center;
    margin-top: 20px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-top: 50px;
    }
  }

  & > img:not(.file-uploader) {
    max-width: 120px;
    max-height: 120px;
  }
`;

export const GroupHeroSkeletonContainer = styled(GroupHeroContainer)`
  .group-logo-skeleton {
    width: 120px;
    height: 120px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 200px;
      height: 200px;
    }
  }

  .group-name-skeleton {
    width: 80vw;
    max-width: 700px;
    height: 35px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 700px;
      height: 55px;
    }
  }
`;
