import React, { useEffect, useState } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { H1M, H5 } from '../../styles/components/header';
import { ErrorPageContainer, ErrorSectionWrapper } from './styles';
import { Skyscape } from '../../components/svgs/graphics/Skyscape';
import { useLocation, useNavigate } from 'react-router';
import { ButtonLink } from '../../components/Button';
import { ButtonKind } from '../../components/Button/styles';

interface IProps {
  className?: string;
}

export const ErrorPage: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(5);
  const location = useLocation();

  const onCtaClick = () => () => {
    analytics.fireEvent('ErrorPage_GoToHomepage_Click');
  };

  useEffect(() => {
    analytics.fireEvent('ErrorPage_Redirect_From', location.pathname);
    const interval = setInterval(() => {
      if (seconds === 0) {
        navigate('/');
      } else {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <ErrorPageContainer className={ className }>
      <ErrorSectionWrapper>
        <Skyscape />
        <H1M className='center'>Oops! Looks like we took a wrong turn.</H1M>
        <H5>Let’s get you back on your sustainability journey.</H5>
        <ButtonLink
          kind= { ButtonKind.Primary }
          href='/'
          onClick={ onCtaClick }
        >
          Go To Homepage
        </ButtonLink>
        <p className='redirect-text'>We are redirecting you in...{ seconds }</p>
      </ErrorSectionWrapper>
    </ErrorPageContainer>
  );
};
