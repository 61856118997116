import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { usePromos } from '../../contexts/promos';
import { useUserSession } from '../../contexts/user';
import { PromoContentContainer, PromoHeaderContainer, PromosContentContainer } from './styles';

const CreateAccountPromoContainerBase: React.FC = () => {
  const promosContext = usePromos();
  const userSession = useUserSession();

  const header = useMemo(() => {
    if (userSession.isLoggedIn) return null;

    return (
      <PromoHeaderContainer>
        <h1 className='giveaway-amount'>${ promosContext?.activeCreateAccountPromo?.amount }</h1>
        <h1>{ promosContext?.activeCreateAccountPromo?.headerText }</h1>
      </PromoHeaderContainer>
    );
  }, [userSession.isLoggedIn, promosContext.activeCreateAccountPromo]);

  const body = useMemo(() => {
    if (!userSession.initialized || !!userSession.authenticating || !promosContext.activeCreateAccountPromo) return null;

    let promoText;

    if (!!userSession.isLoggedIn) promoText = <p>{ promosContext.activeCreateAccountPromo.successText }</p>;
    if (!userSession.isLoggedIn) promoText = <p>{ promosContext.activeCreateAccountPromo.promoText }</p>;

    return (
      <PromoContentContainer className={ `${userSession?.isLoggedIn ? 'success-state' : '' }` }>
        { header }
        { promoText }
      </PromoContentContainer>
    );
  }, [userSession.isLoggedIn, userSession.initialized, userSession.authenticating, promosContext.activeCreateAccountPromo]);

  if (!promosContext?.activeCreateAccountPromo) return null;

  return (
    <PromosContentContainer>
      <img src={ promosContext.activeCreateAccountPromo.imageUrl } />
      { body }
    </PromosContentContainer>
  );
};

export const CreateAccountPromoContainer = observer(CreateAccountPromoContainerBase);
