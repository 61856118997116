import styled from 'styled-components';
import { Button } from '../Button';

export const EditButton = styled(Button)`
  padding: 0;

  input {
    display: none;
  }
`;
