import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import { RedirectContainer } from './styles';
import { CashbackRedirect } from '../../components/CashbackRedirect';

interface IProps {
  className?: string;
}

export enum RedirectTypes {
  Cashback = 'cashback',
}

const RedirectBase: React.FC<IProps> = ({
  className = '',
}) => {
  const { type, companyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!type) navigate('/');
  }, []);

  const content = useMemo(() => {
    if (type === RedirectTypes.Cashback) {
      if (!companyId) navigate('/');
      return <CashbackRedirect companyId={ companyId } />;
    }
  }, []);

  return (
    <RedirectContainer title={ `Redirect ${type}` } className={ className }>
      { content }
    </RedirectContainer>
  );
};

export const Redirect = observer(RedirectBase);
