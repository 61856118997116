import React, { useCallback, useMemo } from 'react';
import { ArticleCardContainer } from './styles';
import { ButtonLink } from '../Button';
import { slugify } from '../../lib/formatters';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { useLocation } from 'react-router-dom';
import { IArticleResponse, IArticleType } from '../../models/article';

interface IProps {
  className?: string;
  article: IArticleResponse;
  colorMode?: string;
}

export const ArticleCard: React.FC<IProps> = ({
  className = '',
  article,
  colorMode,
}) => {
  const location = useLocation();
  const analytics = useAnalytics();

  const onCtaClick = useCallback((title: string) => () => {
    if (location.pathname === '/industry-reports') {
      analytics.fireEvent('IndustryReports_Article_Click', title);
    } else {
      analytics.fireEvent('HP_Article_Click', title);
    }
  }, []);

  const buildArticleSlug = useMemo(() => {
    let articleSlug;
    if (!!article.company) articleSlug = article.company.slug;
    else articleSlug = slugify(article.headerTitle);
    return `/industry-report/${article.type}/${articleSlug}/${[slugify(article.title)]}/${article?._id}`;
  }, []);

  const imageSource = useMemo(() => {
    if (article.type === IArticleType.GoodAndBad || article.type === IArticleType.CompanySpotlight) return article.company.logo;
    return article.listViewImage || article.headerLogo || article.headerBackground;
  }, [article.type, article?.company]);

  return (
    <ArticleCardContainer className={ className } textColor={ colorMode }>
      <div className='image-container'>
        <img src={ imageSource } alt={ article.company?.companyName + ' logo' } />
      </div>
      <p className='article-title'>{ article.type === IArticleType.GoodAndBad ? article.company?.companyName : article.title }</p>
      <p>{ article.description || article.introParagraph }</p>
      <ButtonLink
        className='cta'
        href={ buildArticleSlug }
        kind={ ButtonKind.Primary }
        onClick={ onCtaClick(article.title) }
      >
        Read Report
      </ButtonLink>
    </ArticleCardContainer>
  );
};
