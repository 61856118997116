import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import { IThemeProps } from '../../styles/themes';

export const ReactTinyPopover = styled(Popover)<IThemeProps>`
    .tiny-popover-anchor {
        &:hover,
        & > *:hover {
            cursor: default;
        }
    }
`;

export const ReactTinyPopoverContent = styled.div<IThemeProps>`
    margin-top: 3px;
`;
