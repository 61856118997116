import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { CompanySearchResultItem } from '../../components/CompanySearchResultItem';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { CompaniesModel, CompanyModel } from '../../models/companies';
import { H1 } from '../../styles/components/header';
import { LeftCol, LoadingSpinnerContainer, NoResults, SearchResultsContainer, SearchResultsInner, SearchResultsList } from './styles';

interface IProps {
  className?: string;
}

const SearchResultsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const location = useLocation();
  const companiesModel = useRef(new CompaniesModel({ limit: 10 })).current;
  const errorMessages = useErrorMessages();
  const analytics = useAnalytics();
  const search = useLocation().search;
  const [query, setQuery] = useState(new URLSearchParams(search).get('query'));

  const loadMore = (refresh = false, altQuery?: string) => () => {
    companiesModel.companies[refresh ? 'refresh' : 'loadMore']({ companyName: (!!altQuery || !!query) ? altQuery || query : null })
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Companies',
          message: err.message,
        });
      });
    analytics.fireEvent('SearchResultScroll');
  };

  useEffect(() => {
    loadMore()();
  }, []);

  useEffect(() => {
    const newQuery = new URLSearchParams(search).get('query');
    
    if (!!newQuery && query !== newQuery) {
      setQuery(newQuery);
      loadMore(true, newQuery)();
    }
  }, [location]);

  const onSearchResultClick = useCallback((company: CompanyModel) => () => {
    analytics.fireEvent('SearchResultClick', `${company._id}`);
  }, []);

  const renderResults = () => {
    if (!companiesModel.companies.busy && !companiesModel.companies.firstPageLoaded) return null;

    let content: JSX.Element[] = [];

    if (companiesModel.companies.results.length) {
      content = companiesModel.companies.results.map(company => (
        <CompanySearchResultItem
          key={ company._id }
          className='search-result'
          company={ company }
          to={ `/company/${company._id}/${company.slug}` }
          onClick={ onSearchResultClick(company) }
        />
      ));
    } else if (!companiesModel.companies.busy && companiesModel.companies.firstPageLoaded) {
      content.push((
        <NoResults key='no-companies'>
          No companies found
        </NoResults>
      ));
    }

    if (companiesModel.companies.busy) {
      content.push((
        <LoadingSpinnerContainer key='loading-spinner'>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ));
    }

    if (!companiesModel.companies.allResultsFetched && !companiesModel.companies.busy) {
      content.push(<Waypoint key='waypoint' onEnter={ loadMore() } topOffset={ 200 } />);
      content.push(<div key='waypoint-support' className='waypoint-support' />);
    }

    return content;
  };

  return (
    <SearchResultsContainer className={ className } title='Search Results'>
      <SearchResultsInner>
        <LeftCol>
          <H1>Search Results</H1>
          <div className='query-text'>"{ query }"</div>
          { companiesModel.companies.firstPageLoaded && <p>{ companiesModel.companies.total } Companies</p> }
        </LeftCol>
        <SearchResultsList>
          { renderResults() }
        </SearchResultsList>
      </SearchResultsInner>
    </SearchResultsContainer>
  );
};

export const SearchResults = observer(SearchResultsBase);
