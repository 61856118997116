import styled from 'styled-components';
import { FlexColCenter } from '../../styles/styles';

export const AsFeaturedInSectionContainer = styled.div`
  ${FlexColCenter}
  margin: 0 auto;
  max-width: 1400px;
  padding: 80px 0;
  
  .header-text {
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
  }

  img {
    margin: 0 auto;

    @media (${({ theme }) => theme.breakpoints.xsMax}) {
      max-width: 70% !important;
    }

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      max-width: 400px;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      max-width: 90%;
    }
  }

  .press-button {
    display: block;
    margin: 40px auto 0;
  }
`;
