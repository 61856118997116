import React, { useEffect, useMemo, useRef } from 'react';
import { ContentWrapper, ArticlesSectionContainer, ImageSection, LogoContainer, TextSection, TopSection, FeaturedArticleSectionContainer, ArticleContainer } from './styles';
import { GoodBadText } from '../GoodBadText';
import { observer } from 'mobx-react';
import { ButtonKind } from '../Button/styles';
import { ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { slugify } from '../../lib/formatters';
import { ArticleCard } from '../ArticleCard';
import { ArticleModel } from '../../models/article';

interface IProps {
  className?: string;
  backgroundColor?: string;
  theBadFillColor?: string;
}

const ArticlesSectionBase: React.FC<IProps> = ({
  className = '',
  backgroundColor,
  theBadFillColor,
}) => {
  const articleModel = useRef(new ArticleModel(null)).current;
  const analytics = useAnalytics();
  
  useEffect(() => {
    articleModel.loadAllArticles();
  }, []);

  const articlesContainer = useMemo(() => {
    if (!articleModel.allArticles.length) return null;

    if (articleModel.errorLoadingAllArticles) return (
      <div className='error-text'>Error loading articles...</div>
    );

    const content = articleModel.allArticles.map((article) => {
      if (article.enabled === false) return null;
      if (article.featured === true) return null;
      return (
        <ArticleCard article={ article } key={ article._id } />
      );
    });

    return (
      <ArticleContainer>
        { content } 
      </ArticleContainer>
    );
  }, [articleModel.allArticles, articleModel.errorLoadingAllArticles]);

  const topSection = useMemo(() => {
    if (!articleModel.allArticles.length) return null;

    if (articleModel.errorLoadingAllArticles) return (
      <TopSection>
        <div className='error-text'>Error loading articles...</div>
      </TopSection>
    );
    
    const featuredArticle = articleModel.allArticles.filter((article) => article.featured === true)[0];

    const onCtaClick = () => {
      analytics.fireEvent('IndustryReports_FeaturedArticle_Click', featuredArticle._id);
    };

    return (
      <TopSection>
        <TextSection>
          <div className='company-name'>{ featuredArticle.company.companyName }</div>
          <GoodBadText outlineFill={ theBadFillColor } />
          <p>{ featuredArticle.description || featuredArticle.introParagraph }</p>
          <ButtonLink
            className='cta'
            href={ `/industry-report/${featuredArticle.type}/${featuredArticle.company.slug || null}/${[slugify(featuredArticle.title)]}/${featuredArticle._id}` }
            kind={ ButtonKind.Secondary }
            onClick={ onCtaClick }
          >
            Read Report
          </ButtonLink>
        </TextSection>
        <ImageSection>
          <img className='featured-image' src={ featuredArticle.headerBackground } />
          <LogoContainer>
            <img className='company-logo' src={ featuredArticle.company.logo || featuredArticle.headerLogo } />
          </LogoContainer>
        </ImageSection>
      </TopSection>
    );
  }, [articleModel.allArticles, articleModel.errorLoadingAllArticles]);

  if (!articleModel.allArticles.length || articleModel.busy) return null;

  return (
    <>
      <FeaturedArticleSectionContainer className={ className } backgroundColor={ backgroundColor }>
        <ContentWrapper>
          { topSection }
        </ContentWrapper>
      </FeaturedArticleSectionContainer>
      <ArticlesSectionContainer className={ className } >
        <ContentWrapper>
          { articlesContainer }
        </ContentWrapper>
      </ArticlesSectionContainer>
    </>
  );
};

export const ArticlesSection = observer(ArticlesSectionBase);
