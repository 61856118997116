import styled from 'styled-components';

export const ValueRowContainer = styled.div`
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey3};
  justify-content: space-between;
  padding: 25px 0;

  .cell {
    align-items: center;
    justify-content: center;
  }

  .value-title {
    font-weight: 600;
    font-size: 16px;
    min-width: 100px;
    text-align: left;
  }

  .value-comparison-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    min-width: 70%;
  }
`;
