import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const PhoneWithLoupe: FC<IProps> = ({ className = '', id = '', fill = 'none' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }phone-with-loupe` }
    id={ id }
    width={ 200 }
    height={ 200 }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M35.6204 157.709C39.3025 133.113 58.535 43.2548 64.3472 19.3311C64.9786 16.7309 67.8315 14.4463 70.4922 14.1666C80.3779 13.1281 113.361 13.0555 123.658 13.0996C128.12 13.1187 132.299 18.9761 131.73 23.2656C128.324 48.9526 109.599 142.408 103.224 167.221C102.319 170.744 97.1222 172.161 93.485 172.143C83.3229 172.09 51.8985 169.315 42.2545 166.909C38.586 165.994 35.0606 161.448 35.6204 157.709Z' fill='#C0E0CE' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.4311 19.2292C64.4311 19.2292 68.0818 16.4639 70.2418 16.3342C80.1226 15.7415 114.243 14.9223 123.715 15.6729C125.877 15.8441 127.888 18.8061 127.527 20.9441C123.298 45.9634 104.266 141.113 98.3447 165.789C97.7918 168.093 94.369 169.066 92.0004 168.994C82.3626 168.701 49.9227 165.643 40.5175 164.031C38.2738 163.647 36.4708 160.765 35.5693 159.324' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M80.7515 18.5465C84.7715 18.2747 101.277 17.9501 105.306 18.1402C107.382 18.2381 106.635 23.9497 104.848 24.092C100.747 24.4185 84.5442 24.4358 80.4826 24.1915C78.772 24.0885 78.8292 18.6765 80.7515 18.5465Z' stroke='#001C20' strokeWidth='0.997793' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M55.9541 95.0726L103.691 97.1104L101.892 107.351L54.0986 104.71L55.9541 95.0726Z' fill='#C0E0CE' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M57.7109 97.5698L100.765 99.2572L99.9355 105.036L56.8242 102.831L57.7109 97.5698Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.99979' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M100.718 99.3048C85.1619 116.789 87.5262 141.086 87.6169 166.074C87.6476 174.663 85.3383 186.828 75.9183 188.863C75.9183 188.863 39.3655 188.392 31.8627 184.522C29.2511 183.175 28.9517 179.276 30.3101 175.084C31.6926 170.818 30.8643 166.341 30.8643 166.341C30.8643 166.341 21.2811 166.454 18.5639 164.718C15.8468 162.981 14.5986 155.222 14.5986 155.222L16.6158 156.888L17.7459 155.17L18.9715 156.785C19.5265 156.318 20.4965 155.267 20.4965 155.267L21.7918 156.923L23.1035 155.428L24.5177 157.138L26.0059 155.662L27.1413 157.283L28.5024 155.89L29.5298 157.412L30.8018 155.946L32.0718 157.543L33.467 155.976L34.9217 157.658L36.2604 156.199L37.4387 157.821L39.0156 156.247L40.1677 157.875L41.6584 156.668L42.7972 158.081L44.1266 156.901C43.9438 152.389 43.7738 148.514 43.6698 144.328C43.2578 127.733 48.0226 112.298 57.72 97.6455L100.718 99.3048Z' fill='#C0E0CE' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.4688 123.683L85.3673 126.478' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M47.9482 142.418L83.8468 145.213' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.4443 131.793L84.3908 133.113' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.2979 135.704L84.2443 137.024' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.043 139.765L83.9894 141.085' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M44.1016 157.006L45.0565 158.033L45.8026 156.907L47.1768 158.207L48.2947 157.074L49.5327 158.451L50.7255 157.203L51.9038 158.433L53.1666 157.217L54.5062 158.632L56.0971 157.462C56.0971 157.462 57.2686 167.479 62.1254 167.854C64.7518 168.058 68.4668 158.684 72.3811 161.982C74.0383 163.377 72.6104 171.539 71.4004 179.132C70.3997 185.407 73.8083 188.668 73.8083 188.668' fill='#C0E0CE' />
    <path d='M44.1016 157.006L45.0565 158.033L45.8026 156.907L47.1768 158.207L48.2947 157.074L49.5327 158.451L50.7255 157.203L51.9038 158.433L53.1666 157.217L54.5062 158.632L56.0971 157.462C56.0971 157.462 57.2686 167.479 62.1254 167.854C64.7518 168.058 68.4668 158.684 72.3811 161.982C74.0383 163.377 72.6104 171.539 71.4004 179.132C70.3997 185.407 73.8083 188.668 73.8083 188.668' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M112.106 24.105C113.678 24.105 114.953 22.8304 114.953 21.2581C114.953 19.6858 113.678 18.4111 112.106 18.4111C110.533 18.4111 109.259 19.6858 109.259 21.2581C109.259 22.8304 110.533 24.105 112.106 24.105Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.0004' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M50.5469 120.469L72.8162 122.133' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M54.1377 109.637L88.9703 112.011' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M30.5576 166.347L62.0174 167.866' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.7461 160.18L70.717 161.311' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M89.168 62.055C96.611 62.926 103.347 57.6351 104.212 50.2374C105.078 42.8397 99.746 36.1366 92.303 35.2656C84.86 34.3946 78.1244 39.6855 77.2587 47.0832C76.393 54.4809 81.7249 61.184 89.168 62.055Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.999993' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.167 48.6605L89.988 48.8346L90.76 35.2223' stroke='#001C20' strokeWidth='0.999993' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.165 48.9268L99.9821 58.4601' stroke='#001C20' strokeWidth='0.999993' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.6133 48.5165L101.359 40.4485' stroke='#001C20' strokeWidth='0.999993' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M62.0884 64.6187L57.4268 87.147L104.528 88.832' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M67.958 64.7529L63.2617 87.1762L68.3116 87.3177L72.7866 64.8239L67.958 64.7529Z' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M77.4765 71.8749L74.1035 87.6485L79.1534 87.7899L82.3055 71.9456L77.4765 71.8749Z' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M87.9115 75.0311L85.0713 88.1261L90.1212 88.2676L92.7404 75.1018L87.9115 75.0311Z' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M96.6557 82.2441L95.1943 88.4084L100.244 88.5499L101.484 82.3149L96.6557 82.2441Z' stroke='#001C20' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.8874 129.26C95.8553 130.028 96.9146 130.794 97.4639 131.16' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.1083 133.095C98.0447 133.065 98.0469 133.075 98.0469 133.15' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.5234 144.403C94.9834 143.802 96.0684 143.092 96.6742 142.485' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.809 144.823C95.7404 144.819 97.1804 143.981 97.4969 143.755' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M99.4203 141.382C99.3475 141.391 99.3496 141.428 99.3496 141.49' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.4066 150.666C80.2016 150.733 80.7702 150.825 80.853 150.825' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.9922 152.91C80.9851 152.929 80.9922 152.918 80.9551 152.918' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.002 136.666C107.022 135.256 107.93 133.821 107.93 132.361' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.403 130.662C108.412 130.557 108.376 130.531 108.376 130.439' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.898 137.034C107.802 137.019 107.793 137.139 107.793 137.234' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M96.709 113.776C96.8297 113.721 97.1654 113.833 97.3597 113.761C97.8711 113.568 98.3983 113.379 98.904 113.177' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.4775 115.306C97.8433 115.286 98.189 115.18 98.5618 115.133' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M103.645 112.764C103.629 112.764 103.641 112.766 103.641 112.73' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M103.168 124.333C103.15 124.327 103.16 124.329 103.16 124.365' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.913 153.183C94.8781 153.198 94.8802 153.193 94.923 153.193' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.754 151.904C102.716 152.04 102.804 151.595 102.817 151.545C102.914 151.192 103.003 150.835 103.194 150.516' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M60.454 150.114C60.3295 150.093 61.3289 151.284 61.7017 151.408' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M61.7266 150.387C61.9344 150.456 62.0787 150.744 62.3187 150.824' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.0371 104.275C84.0514 104.227 84.1507 104.307 84.1807 104.322C84.33 104.397 84.5321 104.404 84.6957 104.425C85.1064 104.477 85.5228 104.391 85.9307 104.391' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.228 105.719C85.1637 105.757 85.3729 105.811 85.4157 105.833' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M55.6406 116.039C56.1486 115.694 56.5156 115.211 56.9996 114.824' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.6963 116.682C56.8942 116.427 57.1648 116.195 57.447 116.026' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.4912 115.324C68.5026 115.349 68.4926 115.339 68.5326 115.339' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M52.1364 133.979C51.8639 134.746 53.8923 134.809 54.2879 134.695' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M52.8879 135.946C52.6361 135.894 53.0726 136.026 53.1571 136.068C53.4712 136.225 53.8874 136.184 54.221 136.136' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.3691 135.668C56.3739 135.664 56.3787 135.66 56.3836 135.657' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.082 121.14C83.5656 120.641 84.242 120.171 84.6263 119.595' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.3566 121.499C84.3202 121.677 84.5338 121.152 84.6966 121.07' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M50.4692 151.576C49.7632 151.409 51.741 152.356 51.8864 152.403' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.6865 153.11C50.0182 153.534 50.9288 153.673 51.4402 153.673' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.7843 175.045C49.7781 175.045 50.0511 175.145 50.1675 175.198C50.4344 175.32 50.7279 175.39 51.0066 175.476C51.8985 175.752 52.9469 175.869 53.8424 175.523' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.4999 176.236C49.4616 176.201 49.5973 176.274 49.6428 176.299C49.8683 176.423 50.084 176.568 50.3207 176.67C51.0692 176.991 51.7801 177.174 52.5935 177.194' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.3115 177.761C56.3011 177.804 56.2734 177.836 56.2819 177.877' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M74.7328 178.064C74.6257 178.991 75.0921 180.162 76.1107 180.434' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M76.8289 180.944C76.8232 181.003 76.7975 180.988 76.8082 181.042' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M82.753 175.23C82.7152 175.211 82.6588 175.236 82.6182 175.244' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.7489 172.791C83.6625 172.648 83.8904 172.117 83.9218 171.939C84.0132 171.432 83.9232 170.923 83.8204 170.426' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.6006 167.128C68.942 167.095 69.2642 167.383 69.7034 167.293' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.9428 168.182C68.9243 168.175 69.0907 168.198 69.1443 168.22C69.2793 168.275 69.4143 168.318 69.5607 168.345' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.6912 180.701C67.647 180.704 67.6541 180.692 67.6627 180.732' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.8779 184.86C65.3065 184.679 65.6129 184.189 65.8622 183.809' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.9144 185.173C65.7972 185.223 65.9987 185.004 66.0137 184.98C66.1694 184.744 66.2958 184.574 66.5244 184.394' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M62.6393 162.587C62.5636 162.6 62.5729 162.603 62.59 162.685' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M48.8368 162.009C48.3886 161.711 50.1098 162.071 50.3671 162.019' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.3623 163.11C49.2802 163.111 49.963 163.301 50.0979 163.337' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M33.7384 177.884C33.2989 177.064 33.8881 178.205 34.9631 178.564' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M33.3928 178.982C33.3321 178.968 33.9589 179.506 34.1395 179.615' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M35.9964 180.322C35.9514 180.288 35.9768 180.292 35.9229 180.346' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M39.7988 171.21C40.028 171.181 40.3465 170.757 40.4948 170.608' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M41.1846 171.926C41.3074 171.938 41.2626 171.898 41.3636 171.798' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M39.9494 151.676C39.9453 151.743 39.9285 151.728 39.9919 151.728' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M40.6449 149.766C40.617 149.759 40.6319 149.767 40.6319 149.717' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M47.3521 130.677C47.0877 130.16 47.2211 129.384 47.4138 128.796' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M48.5105 106.842C48.4339 106.822 48.5556 106.621 48.5691 106.589C48.8206 106 48.9896 105.438 49.3732 104.906C49.425 104.835 49.7099 104.502 49.674 104.374' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.8224 106.249C49.8059 106.195 49.8134 106.221 49.7998 106.172' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M47.1673 113.039C47.0906 113.016 47.0591 112.993 47.0692 113.03' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M58.0059 91.6861C58.1256 92.0968 59.1963 91.4375 59.4515 91.3668' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M58.9663 92.4908C58.9299 92.5415 59.5857 92.3772 59.7209 92.3394' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M109.954 61.9311C109.927 61.854 110.595 61.4461 112.138 60.6245' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M111.317 62.5989C111.125 62.6371 111.777 62.4758 111.917 62.4015' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M114.205 64.9667C114.121 64.9708 114.14 64.9747 114.062 64.967' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M118.517 82.7803C118.458 82.7268 118.914 80.2825 118.948 79.9368' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M119.384 77.9005C119.384 77.8827 119.388 77.8206 119.384 77.8577' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.354 81.9975C110.351 81.751 111.142 81.3246 111.349 81.2153' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M111.045 82.8372C110.974 82.8444 111.681 82.3872 111.792 82.3201' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.672 77.0369C107.626 76.9826 107.612 76.9997 107.62 76.9248' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.1797 57.23C67.3233 57.2882 68.199 56.333 68.2854 56.196' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.4642 58.3168C67.36 58.3418 67.6618 58.0348 67.6947 57.9823' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M112.363 46.8216C112.364 46.7054 112.345 46.3882 112.318 46.663' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.5508 66.2525C97.5579 66.3728 98.9551 65.3383 99.1322 65.2439' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.569 67.3376C98.389 67.3761 98.8612 67.0043 98.9154 66.9753' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.4066 67.4525C80.423 67.3929 80.4344 67.4091 80.373 67.4031' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M55.4814 81.0773C55.678 80.5787 55.7095 79.803 55.7622 79.2709' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.2731 77.1331C56.2438 77.0924 56.2517 77.1167 56.2573 77.0596' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M81.8223 83.9633C81.9994 84.0061 82.0508 84.3154 82.2973 84.3404' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M82.793 82.9691C82.8001 83.0269 82.7894 82.9962 82.8386 83.0562' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.5655 78.6871C77.5362 78.7128 77.5555 78.7035 77.502 78.6985' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.1338 73.225C68.1045 73.2864 68.4128 73.3242 68.4828 73.3313' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.0144 74.805C67.9337 74.8021 68.2156 74.8378 68.2841 74.8806' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M121.172 39.8612C121.153 39.813 121.043 39.632 121.028 39.7735' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M124.361 53.3342C124.126 52.5455 124.656 51.1477 124.736 50.3363' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M125.079 48.8564C125.055 48.8369 125.067 48.8516 125.072 48.8009' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M99.7617 49.5174C100.064 49.6109 100.306 49.4191 100.531 49.2345' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M100.144 50.4869C100.345 50.5155 100.496 50.3597 100.702 50.3129' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.2939 42.718C85.2997 42.6946 85.3032 42.73 85.3011 42.7462' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M86.3391 52.6385C86.2528 52.6561 86.2506 52.6422 86.2592 52.7315' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.7764 54.6991C87.7856 54.759 87.7821 54.7302 87.7878 54.7856' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M71.3369 32.9062C71.5312 32.9234 72.2762 31.6372 72.7398 31.2122' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M72.2598 33.0017C72.3433 32.8963 72.4624 32.7994 72.5674 32.7133' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.9681 39.1721C68.8803 39.1699 68.9174 39.2073 68.9652 39.2462' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.128 29.1999C108.127 29.1964 108.75 28.8879 109.06 28.6577' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.997 29.5387C109.036 29.5441 109.07 29.5391 109.108 29.5353' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M119.228 23.4599C119.201 23.4322 119.211 23.4502 119.206 23.402' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.164 28.3396C93.049 28.9055 94.2518 28.7207 94.5076 28.6952' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.8516 30.3833C95.8865 30.3565 95.8751 30.3545 95.8794 30.4043' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M72.5566 21.7766C72.6552 21.0319 73.1752 20.6015 73.8152 20.1981' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M71.1279 22.274C71.2429 21.805 71.2358 21.4821 71.6608 21.1334' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.7415 30.379C67.7144 30.3908 67.7315 30.385 67.6865 30.3894' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M61.9222 54.744C61.8151 54.2488 62.1458 53.4901 62.3515 53.0548' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M62.5306 55.0932C62.4134 54.8269 62.7106 54.4421 62.8713 54.2462' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.0344 61.76C78.9181 61.7584 78.8132 61.7486 78.8275 61.8894' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.9782 70.7061C91.9139 70.9911 93.0739 71.1046 93.2503 71.0875' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M116.911 53.6283C116.838 53.2183 117.419 52.4796 117.643 52.1497' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.4328 57.9534C95.4142 57.9582 95.3735 57.9643 95.4007 57.9616' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.5589 39.3714C94.3532 39.1643 95.3482 39.8503 95.7761 39.8788' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.5654 42.2175C94.6025 42.6927 93.9668 43.228 93.7354 43.5692' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M127.379 29.671C127.279 29.516 127.464 29.7755 127.507 29.8009C127.698 29.9121 127.89 29.975 128.102 30.0428' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M127.69 29.1251C127.563 29.0308 127.914 29.547 128.462 29.5823' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M129.053 25.3535C128.996 25.3781 128.988 25.3807 128.983 25.4449' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M126.806 42.9407C126.883 42.413 126.753 41.8685 126.788 41.3275' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M126.892 38.9332C126.889 38.9552 126.893 38.9459 126.867 38.9164' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M120.771 68.0681C120.768 68.0717 120.766 68.0753 120.764 68.0789' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M121.072 65.8544C121.031 65.8297 120.993 65.8211 121.038 65.7815' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M115.23 97.9764C115.177 97.8585 115.229 97.8814 115.202 97.9907C114.869 99.3214 114.431 100.874 114.431 102.233' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M114.12 104.18C114.138 104.396 113.893 104.625 113.893 104.932' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M115.784 94.0261C115.577 94.1847 116.54 94.7647 116.669 94.829' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M116.315 93.4932C116.265 93.4104 116.592 93.7494 116.669 93.8257' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.297 93.9442C108.256 93.895 108.263 93.7373 108.263 93.9035' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.4629 91.4985C91.3115 91.6985 92.1057 91.7442 93.2229 91.7442' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.3721 93.4525C91.5921 93.4182 91.7128 93.5175 91.9121 93.5175' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.4922 103.647C65.0308 103.305 65.7743 103 66.385 102.695' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.8916 104.517C66.0037 104.345 66.443 104.2 66.6166 104.083' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.2566 113.88C64.1987 113.876 64.2244 113.867 64.2244 113.954' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M70.8978 152.576C70.5414 152.628 71.0957 153.08 71.1678 153.441C71.5021 155.115 70.7678 155.787 72.4014 156.603' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.7267 158.052C73.696 158.088 73.7124 158.076 73.7124 157.992' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M30.584 162.33C31.2848 162.762 31.3411 162.866 32.1431 162.465' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M34.5664 163.733C34.6598 163.66 34.5915 163.853 34.6869 163.853' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M51.5713 184.283C51.2269 184.318 52.9096 184.94 53.6599 184.753' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.5273 163.752C92.9895 163.892 93.3231 163.369 93.6095 163.082' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.4375 170.467C94.8611 170.55 95.2768 170.409 95.6918 170.409' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.8092 168.695C98.7985 168.7 98.7871 168.706 98.7764 168.712' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.8839 137.951C90.5161 137.865 91.0468 136.491 91.2182 136.32' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M63.5308 128.782C63.4173 128.639 63.3566 128.608 63.5315 128.608' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.8643 106.512C92.1328 105.862 92.4793 105.135 92.9914 104.623' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M100.607 98.408C100.615 98.398 100.622 98.3873 100.628 98.3766' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M81.2705 97.2194C81.3898 97.2194 81.5062 97.3736 81.7319 97.3736' stroke='#001C20' strokeWidth='0.385714' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <g filter='url(#filter0_b_3947_14968)'>
      <path d='M137.3 75.4901C139.5 84.7901 134.5 99.9901 113.6 100.89C99.8483 100.89 86.2002 86.2583 86.2002 73.3901C86.2002 60.5219 96.7483 50.4901 110.5 50.4901C124.252 50.4901 137.3 62.6219 137.3 75.4901Z' fill='url(#paint0_linear_3947_14968)' fillOpacity='0.2' />
    </g>
    <path fillRule='evenodd' clipRule='evenodd' d='M135.827 96.6951C135.827 96.6951 165.75 130.826 171.948 138.045C172.433 138.61 173.399 139.376 173.01 140.011C172.518 140.812 171.878 142.64 170.416 144.071C169.393 145.071 167.912 145.784 166.939 146.214C166.242 146.523 165.152 146.866 164.653 146.289C158.35 138.988 129.119 102.405 129.119 102.405L135.827 96.6951Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.09995' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M164.828 146.318C167.353 142.675 169.737 140.774 172.976 139.452' stroke='#001C20' strokeWidth='1.09995' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M122.59 103.137C122.59 103.137 131.088 103.62 133.342 101.696C136.966 98.602 138.243 91.1272 138.243 91.1272L122.59 103.137Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.09995' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M84.8238 67.5022C82.1483 83.7952 90.5467 90.6755 91.2251 91.4264C93.3866 93.8196 98.2832 98.8696 104.138 100.941C111.913 103.693 121.342 101.303 121.375 101.219C121.583 100.696 122.237 99.8649 122.094 99.8757C112.082 100.615 103.904 98.1865 97.3496 92.4302C90.5819 86.487 86.3319 77.78 87.207 65.8084C87.213 65.7264 86.1573 65.5302 85.6222 65.4571C85.373 65.4229 85.0074 67.3492 84.8238 67.5022Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.09995' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M89.0344 56.7813C98.7161 44.3266 117.237 42.5025 130.368 52.7102C143.499 62.9184 146.3 81.3168 136.618 93.7716C126.936 106.226 108.415 108.05 95.2841 97.8422C82.1531 87.6345 79.3527 69.2356 89.0344 56.7813ZM90.8178 59.1896C98.9875 48.6802 115.138 47.547 126.862 56.6609C138.585 65.7744 141.471 81.7057 133.301 92.2151C125.131 102.724 108.981 103.858 97.2569 94.7442C85.5333 85.6307 82.6481 69.6994 90.8178 59.1896Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.09995' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M136.837 101.53C136.838 101.534 137.538 102.108 137.57 102.131' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M138.443 103.08C138.423 103.083 138.369 103.1 138.407 103.069' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M140.947 113.522C140.94 113.527 140.956 113.506 140.957 113.498' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M140.632 113.086C140.608 113.051 140.583 113.059 140.588 113.01' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M148.491 115.549C148.481 115.547 147.91 115.014 147.874 114.97' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M147.383 114.702C147.39 114.692 147.386 114.706 147.385 114.712' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M169.707 143.305C169.671 143.429 169.395 143.159 169.348 143.129' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M170.139 143.004C170.336 143.062 170.087 142.933 170.042 142.878' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M125.621 100.474C125.685 100.593 125.309 100.154 125.32 100.05' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M126.419 100.007C126.348 100.083 126.269 99.9196 126.225 99.866' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M106.175 101.32C106.145 101.358 106.146 101.37 106.142 101.416' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.219 100.89C105.164 100.89 105.133 100.895 105.097 100.851' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M109.458 102.276C109.492 102.237 109.543 102.197 109.586 102.162' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M124.563 51.3904C124.482 51.4052 124.352 51.2549 124.284 51.2114C123.936 50.9915 123.548 50.88 123.165 50.7424' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M122.07 50.3366C122.038 50.3375 122.008 50.334 121.977 50.3308' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.466 48.2063C108.437 48.3563 108.388 47.9899 108.365 47.886' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M109.034 48.3205C108.944 48.2802 108.974 47.9383 108.983 47.8506' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M133.947 60.3636C133.9 60.3368 133.916 60.3523 133.923 60.2818' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M133.478 59.7764C133.495 59.7922 133.489 59.7689 133.49 59.7529' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.4619 85.7166C87.5394 85.6134 87.5263 85.474 87.5391 85.3471' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.1317 84.937C87.13 84.8673 87.1941 84.8445 87.2012 84.7743' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.9353 93.4261C92.8747 93.4593 92.8814 93.4347 92.8428 93.3875' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.2873 97.8151C98.297 97.6684 98.4382 97.4348 98.4578 97.2403' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.5566 97.1296C97.6497 96.9899 97.735 96.8274 97.752 96.6593' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M139.562 81.4307C139.556 81.4256 139.728 81.1319 139.771 80.9313C139.833 80.6427 139.79 80.3064 139.762 80.016' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M139.636 79.2652C139.635 79.2657 139.635 79.2657 139.635 79.2662' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M139.968 72.9146C139.973 72.9336 139.967 72.9249 139.996 72.9278' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M139.892 72.005C139.89 72.0039 139.889 72.0028 139.887 72.0017' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M137.564 89.378C137.586 89.4719 137.424 89.238 137.39 89.1483' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M138.24 88.8012C138.22 88.9229 137.822 88.5326 137.786 88.5028' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.6465 56.0595C91.6653 56.1155 91.654 56.1112 91.6592 56.0598' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.7088 55.1904C92.7079 55.1862 92.7071 55.1825 92.7062 55.1787' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M135.872 108.086C136.067 108.121 136.298 107.578 136.314 107.423' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M135.462 107.573C135.498 107.419 135.694 107.198 135.761 107.011' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M130.97 100.945C130.942 101.001 130.957 100.993 130.985 101.028' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.1926 73.9429C85.0254 73.7278 84.9425 73.3834 84.901 73.0979' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M149.64 123.564C149.623 123.616 149.366 123.568 149.33 123.561C149.038 123.507 148.711 123.426 148.45 123.281' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M149.455 122.993C149.492 123.013 149.261 122.997 149.226 122.993C149.02 122.972 148.819 122.907 148.627 122.83' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M164.357 139.685C163.975 139.75 163.592 139.653 163.267 139.447' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M162.133 138.808C162.151 138.818 162.14 138.814 162.169 138.817' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M158.483 126.835C158.481 126.853 158.485 126.845 158.457 126.843' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M157.832 126.042C157.828 126.04 157.826 126.039 157.823 126.037' stroke='#001C20' strokeWidth='0.41798' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <defs>
      <filter id='filter0_b_3947_14968' x='84.8502' y='49.1401' width='54.2771' height='53.1' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='0.675' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_3947_14968' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_3947_14968' result='shape' />
      </filter>
      <linearGradient id='paint0_linear_3947_14968' x1='98.9641' y1='56.0549' x2='120.167' y2='93.1449' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FCFDEC' />
        <stop offset='1' stopColor='#254830' stopOpacity='0' />
      </linearGradient>
    </defs>
  </SvgIcon>
);
