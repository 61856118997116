import React, { useEffect } from 'react';
import { AccountHubGridContainer, GridWrapper, MainWrapper } from './styles';
import { AppHeader } from '../../components/AppHeader';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { AccountHubNav } from './AccountHubNav';
import { useUserSession } from '../../contexts/user';
import { observer } from 'mobx-react';
import { PencilBanners } from '../../components/PencilBanners';

interface IProps {
  className?: string;
}

export const AccountHubBase: React.FC<IProps> = ({
  className = '',
}) => {
  const navigate = useNavigate();
  const userSession = useUserSession();
  const location = useLocation();
  
  useEffect(() => {
    if (!userSession.isLoggedIn) {
      navigate('/');
    }
  }, [userSession.name]);

  return (
    <AccountHubGridContainer className={ className } title='Account Dashboard'>
      <GridWrapper>
        <AccountHubNav />
        <AppHeader className='account-header' />
        <MainWrapper>
          { location.pathname.includes('/account') ? <PencilBanners /> : null }
          <Outlet />
        </MainWrapper>
      </GridWrapper>
    </AccountHubGridContainer>
  );
};

export const AccountHub = observer(AccountHubBase);
