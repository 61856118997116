import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { BasicContainer, FlexCol } from '../../styles/styles';

export const RareProjectCardContainer = styled.div`
  ${BasicContainer}
  padding: 0;
  white-space: pre-wrap;
  margin-top: 30px;  
`;

export const ProjectOverview = styled.div`
  img {
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    object-fit: contain;
    width: 100%;
  }

  .content {
    padding: 15px;
  }

  .project-name {
    font-weight: bold;
  }

  p {
    font-weight: regular;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: flex;
    background-color: ${({ theme }) => theme.colors.lightGray2};
    padding: 18px;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;

    img {
      max-width: 300px;
      border-radius: 20px;
    }
  }
`;

export const ProjectDetails = styled.div`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 0px 20px 30px;
  ${FlexCol}

  .heading {
    font-weight: bold;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding-top: 20px;
    flex-direction: row;
  }
`;

export const DataBullets = styled.div`
  width: 100%;

  .heading {
    margin-bottom: 0;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 40%;
    margin-right: 10px;
  }
`;

export const KeyStats = styled.div`
  margin-bottom: 0;

  .stat {
    margin-bottom: 10px;
  }
`;

export const VerifiedBy = styled.div`
  margin-top: 30px;

  .verifier-link {
    display: block;
    text-decoration: underline;
  }
`;

export const WhyConsider = styled.div`
  width: 100%;
  margin-top: 20px;

  .short-description {
    margin-bottom: 10px;
    font-weight: 400;
  }

  ul {
    margin-bottom: 0px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-top: 0;
    width: 60%;
  }
`;

export const ProjectCardErrorContainer = styled.div`
  ${BasicContainer}
  overflow: hidden;
  margin-top: 30px;
  padding: 0;

  .error-text {
    text-align: center;
  }

  & > div {
    display: flex;
    text-align: center;

    &:first-child {
      flex-direction: column;
      align-items: center;
      padding: 90px 20px 60px;
      background: ${({ theme }) => theme.colors.lightGray1 };

      & > * {
        max-width: 540px;
      }

      a {
        color: ${({ theme }) => theme.colors.darkGreen1 };

        &:hover,
        &:focus-visible {
          text-decoration: underline;
        }
      }
    }

    &:last-child {
      justify-content: center;
      align-items: center;
      padding: 65px 20px 90px;
    }
  }
`;

export const RareProjectCardSkeletonContainer = styled(Skeleton)`
  width: 100%;
  height: 600px;
  border-radius: 20px;
  margin-top: 30px;
`;
