import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserSession } from '../../contexts/user';
import { UserGroupRole } from '../../models/user-groups';
import { UserRoles } from '../../models/users';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';

interface IRequirements {
  // an array of the roles that have access to this route
  roles?: UserRoles[];
  // if true, will require that the use access to the current
  // group id param
  group?: boolean;
  // an array of roles required for a group in order to view
  // a group's page.
  groupRoles?: UserGroupRole[];
}

// specific redirects for when a specific requirement fails
interface IRedirect {
  // will be used if the user is not signed in
  authPath?: string;
  // will be used if the user is signed in but does not have a
  // required role
  rolesPath?: string;
  // will be used if the user is signed in and no role issues were
  // found, but does not meet the specified group requirements
  groupsPath?: string;
  // will be used if the user is signed in and no role issues were
  // found, if they are a member of the group, but they lack the required
  // role for that group.
  groupRolesPath?: string;
}

export interface IPrivatePageProps {
  className?: string;
  element: JSX.Element;
  redirect?: string | IRedirect;
  requirements?: IRequirements;
}

const PrivatePageBase: React.FC<IPrivatePageProps> = ({
  element,
  redirect = '/login',
  requirements = {},
}) => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const userSession = useUserSession();
  const [requirementsMet, setRequirementsMet] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    if (!!redirectTo) {
      if (redirectTo.includes('http')) {
        window.location.href = redirectTo;
      } else {
        navigate(redirectTo);
      }
    }
  }, [redirectTo]);

  useEffect(() => {
    setRequirementsMet(false);
  }, [pathname]);

  const checkRequirements = () => {
    let requirementsMet = true;
    let _redirectTo = '';

    // if (process.env.NODE_ENV === 'development') return requirementsMet;
 
    // user has to be logged in
    if (!userSession.isLoggedIn) {
      requirementsMet = false;
      _redirectTo = typeof redirect === 'string' ? redirect : redirect.authPath;
    }

    // if any required roles are specified, check that the
    // user's role is listed
    if (
      requirementsMet
      && !!requirements.roles?.length
      && !requirements.roles.find(r => r === userSession.role)
    ) {
      requirementsMet = false;
      _redirectTo = typeof redirect === 'string' ? redirect : redirect.rolesPath;
    }

    // if any required groups are specified, check that the
    // user is a member of at least one of them
    if (requirementsMet && !!requirements.group) {
      if (!params.groupId) {
        throw new Error('A group requirement has been specified, but no group id was found.');
      }

      if (!userSession.groups.find(userGroup => userGroup.group._id === params.groupId)) {
        requirementsMet = false;
        _redirectTo = typeof redirect === 'string' ? redirect : redirect.groupsPath;
      }

      if (requirementsMet && !!requirements.groupRoles?.length) {
        const group = userSession.groups.find(userGroup => userGroup.group._id === params.groupId);

        if (!group || !requirements.groupRoles.find(role => role === group.role)) {
          requirementsMet = false;
          _redirectTo = typeof redirect === 'string' ? redirect : redirect.groupRolesPath;
        }
      }
    }

    if (!!_redirectTo) {
      if (typeof _redirectTo !== 'string') throw new Error(`Invalid redirect path found: ${_redirectTo}`);

      setRedirectTo(_redirectTo);
    }

    return requirementsMet;
  };

  useEffect(() => {
    if (
      !!userSession
      && userSession.initialized
      && !userSession.authenticating
      && (!requirements.group || (requirements.group && userSession.groupsLoaded))
    ) {
      if (checkRequirements()) setRequirementsMet(true);
    }
  }, [
    requirementsMet,
    userSession, 
    userSession.initialized, 
    userSession.authenticating, 
    userSession.isLoggedIn, 
    userSession.role, 
    userSession.groups, 
    userSession.loadingGroups, 
    userSession.groupsLoaded,
  ]);

  return requirementsMet ? element : <LoadingSpinner />;
};

export const PrivatePage = observer(PrivatePageBase);
