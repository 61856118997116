import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

export const CoreValuesSectionContainer = styled(Section)`
  background-color: ${props => props.theme.colors.secondary};
  background-image: url('https://cdn.karmawallet.io/uploads/jkf_PtERAG-city-green-v2.png');
  background-size: 300%;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 75px 15px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    background-size: 200%;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    background-size: contain;
  }
`;

export const CoreValuesSectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
`;

export const CoreValuesHeader = styled.div`
  text-align: center;

  h2 {
    font-size: 40px;
  }
`;

export const CoreValuesContainer = styled.div`
  ${FlexCol}
  gap: 24px;
  margin-top: 60px;

  .trust-explore-text {
    display: block;
    margin-top: 2rem;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
  }
`;
