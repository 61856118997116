import React, { createContext } from 'react';
import { AppType } from '../constants';
import { KarmaCardLegalModel } from '../models/karmaCard';

interface IProps {
  app?: AppType;
  children: React.ReactNode;
}

export const KarmaCardLegalContext = createContext<KarmaCardLegalModel>(null);

export const useKarmaCardLegal = () => React.useContext(KarmaCardLegalContext);

export const KarmaCardLegalStore: React.FC<IProps> = ({ children }) => (
  <KarmaCardLegalContext.Provider value={ new KarmaCardLegalModel() }>
    { children }
  </KarmaCardLegalContext.Provider>
);
