import ReactGA from 'react-ga4';
import { BaseModel } from './base';

interface IWindow extends Window {
  heap: any;
  fbq: any;
}

type GAEventType = 'pageview' | 'event';

interface IQueueEvent {
  eventName: string;
  eventType: GAEventType;
  // use for additional data in google analytics
  label?: string;
}

const isProduction = (
  !window.location.hostname.includes('staging') &&
  !window.location.hostname.includes('sandbox') &&
  !window.location.hostname.includes('localhost')
);

// when switch to GA4 will be G-C7X56E6VNL for prod
export const GATrackingId = isProduction ? 'G-C7X56E6VNL' : 'G-ZQ03LP6GRX';

export class AnalyticsModel extends BaseModel {
  private _eventQueue: IQueueEvent[] = [];
  private _gaTimeout: number = null;
  private _gaExecuting = false;

  static MAX_POLL_COUNT = 100;

  private get _heap() { return (window as (IWindow & typeof globalThis)).heap; }
  private get _fbq() { return (window as (IWindow & typeof globalThis)).fbq; }

  /**
   * Fires a page view event into Google Analytics.
   * @param path - path to the current URL
   * @param queryParams - All query parameters found in url. Must include leading question mark
   */
  public firePageViewEvent = (path: string, queryParams: string) => {
    this._eventQueue.push({
      eventName: `${path}${queryParams}`,
      eventType: 'pageview',
    });

    this._fireGAEvent();
  };

  public fireEvent = (eventName: string, label?: string) => {
    if (!!eventName) {
      const data: IQueueEvent = {
        eventName,
        eventType: 'event',
      };

      if (!!label) data.label = label;

      this._eventQueue.push(data);
    } 

    this._heap.track(eventName);
    this._fbq('trackCustom', eventName);
    this._fireGAEvent();
  };

  public fireHeapIdentify = (userId: string, name: string, email: string) => {
    this._heap.identify(userId);
    this._heap.addUserProperties({ 'Name': name, 'Email': email });
  };

  // will ensure that ga has finished loading before firing any events
  private _fireGAEvent = (count = 0) => {
    this._gaExecuting = true;
    window.clearTimeout(this._gaTimeout);

    if (count >= AnalyticsModel.MAX_POLL_COUNT) {
      this._gaExecuting = false;
      this._clearQueue();
      return;
    }

    if (!(window as any).gaGlobal) {
      this._gaTimeout = window.setTimeout(() => this._fireGAEvent(count + 1), 50);
      return;
    }

    if (!this._eventQueue.length) {
      this._gaExecuting = false;
      return;
    }

    for (const _event of this._eventQueue) {
      const { eventName, eventType, label } = _event;

      if (eventType === 'pageview') {
        ReactGA.send({ hitType: 'pageview', title: window.document.title }); // Record a pageview for the given page 
      }
      
      if (eventType === 'event') {
        const eventData: any = {
          category: 'Event',
          action: eventName,
        };

        if (!!label) eventData.label = label;
        ReactGA.event(eventData);
      }
    }

    this._gaExecuting = false;
    this._clearQueue();
  };

  private _clearQueue = () => {
    if (this._gaExecuting) return;

    this._eventQueue = [];
  };
}
