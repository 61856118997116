import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  type?: 'color' | 'bw';
}

export const Clipboard: FC<IProps> = ({ className = '', type = 'color', id = '' }) => type === 'color'
  ? (
    <SvgIcon
      className={ `${className ? className + ' ' : ''}clipboard-graphic` }
      id={ id }
      width={ 79 }
      height={ 108 }
      fill='none'
    >
      <path d='M77.3504 21.1972V94.694C77.3504 97.4839 75.0904 99.7456 72.3026 99.7456H13.2959C10.508 99.7456 8.24805 97.4839 8.24805 94.694L8.24805 21.1972C8.24805 18.4072 10.508 16.1455 13.2959 16.1455H29.2146C29.1651 16.2446 29.1652 16.3766 29.1652 16.5087V24.4329C29.1652 26.0012 30.4518 27.2889 32.019 27.2889H53.5629C55.1301 27.2889 56.4168 26.0012 56.4168 24.4329V16.5087C56.4168 16.3766 56.4168 16.2446 56.3673 16.1455L72.2861 16.1455C75.0904 16.1455 77.3504 18.4072 77.3504 21.1972Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.24787 94.694L8.24787 22.749H6.69723C3.90938 22.749 1.64941 25.0107 1.64941 27.8007L1.64941 101.298C1.64941 104.087 3.90938 106.349 6.69723 106.349H65.7039C68.4918 106.349 70.7517 104.087 70.7517 101.298V99.7457H13.2957C10.5078 99.7457 8.24787 97.484 8.24787 94.694Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M56.4168 16.5086V24.4328C56.4168 26.0011 55.1301 27.2888 53.5629 27.2888H32.0025C30.4353 27.2888 29.1816 26.0011 29.1816 24.4328V16.5086C29.1816 16.3765 29.1816 16.2445 29.2311 16.1454C29.3796 14.7257 30.5838 13.6526 32.019 13.6526H53.5959C55.0311 13.6526 56.2353 14.7257 56.4003 16.1454C56.4168 16.2445 56.4168 16.3765 56.4168 16.5086Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M47.9044 13.6527H38.2212V9.44296C38.2212 7.70954 39.6234 6.28979 41.372 6.28979H44.7702C46.5023 6.28979 47.9209 7.69304 47.9209 9.44296V13.6527H47.9044Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M70.5866 23.3433V92.5478H14.9946L14.9946 23.3433H29.1813V24.4328C29.1813 26.0012 30.4515 27.2888 32.0021 27.2888H53.5791C55.1462 27.2888 56.4329 26.0012 56.4329 24.4328V23.3433H70.5866Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M70.5866 23.3433V92.5478H14.9946L14.9946 23.3433H29.1813V24.4328C29.1813 26.0012 30.4515 27.2888 32.0021 27.2888H53.5791C55.1462 27.2888 56.4329 26.0012 56.4329 24.4328V23.3433H70.5866Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M61.3527 47.0138H51.6612C50.5309 47.0138 49.6099 46.0921 49.6099 44.9609V35.2621C49.6099 34.1309 50.5309 33.2092 51.6612 33.2092H61.3527C62.483 33.2092 63.404 34.1309 63.404 35.2621V44.9609C63.4249 46.0921 62.483 47.0138 61.3527 47.0138Z' fill='#81B189' />
      <path d='M61.3527 66.2201H51.6612C50.5309 66.2201 49.6099 65.2984 49.6099 64.1673V54.4684C49.6099 53.3372 50.5309 52.4155 51.6612 52.4155H61.3527C62.483 52.4155 63.404 53.3372 63.404 54.4684V64.1673C63.4249 65.2984 62.483 66.2201 61.3527 66.2201Z' fill='#81B189' />
      <path d='M61.3527 85.4471H51.6612C50.5309 85.4471 49.6099 84.5254 49.6099 83.3943V73.6745C49.6099 72.5433 50.5309 71.6216 51.6612 71.6216H61.3527C62.483 71.6216 63.404 72.5433 63.404 73.6745V83.3733C63.4249 84.5254 62.483 85.4471 61.3527 85.4471Z' fill='#81B189' />
      <path d='M43.0711 37.4253H22.6489' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.0711 43.9956H22.6489' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.0711 56.2947H22.6489' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.0711 62.8652H22.6489' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.0711 75.1641H22.6489' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.0711 81.7346H22.6489' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.4897 40.5428L55.2141 42.2677L59.5248 37.9556' stroke='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.3984 59.7491L55.1227 61.474L59.4335 57.1619' stroke='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.3989 78.9657L55.1232 80.6905L59.434 76.3784' stroke='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  )
  : (
    <SvgIcon
      className={ `${className ? className + ' ' : ''}clipboard-graphic` }
      id={ id }
      width={ 101 }
      height={ 136 }
      fill='none'
    >
      <path d='M97.9346 26.6927L97.9346 119.244C97.9347 122.757 95.0739 125.606 91.545 125.606H16.8531C13.3242 125.606 10.4635 122.757 10.4635 119.244L10.4634 26.6927C10.4634 23.1794 13.3241 20.3313 16.853 20.3313H37.0033C36.9407 20.456 36.9407 20.6223 36.9407 20.7887L36.9407 30.7673C36.9407 32.7422 38.5694 34.3637 40.5531 34.3637H67.8239C69.8076 34.3637 71.4364 32.7422 71.4364 30.7673L71.4364 20.7887C71.4364 20.6223 71.4364 20.456 71.3737 20.3313H91.524C95.0738 20.3313 97.9346 23.1794 97.9346 26.6927Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.4634 119.244L10.4633 28.6467H8.50048C4.97156 28.6467 2.11084 31.4948 2.11085 35.0081L2.11093 127.56C2.11093 131.073 4.97166 133.921 8.50057 133.921H83.1925C86.7214 133.921 89.5821 131.073 89.5821 127.56L89.5821 125.605H16.853C13.3241 125.605 10.4634 122.757 10.4634 119.244Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M71.4362 20.7886L71.4362 30.7672C71.4362 32.7421 69.8075 34.3636 67.8238 34.3636H40.5321C38.5484 34.3636 36.9614 32.7421 36.9614 30.7672L36.9614 20.7886C36.9614 20.6223 36.9614 20.456 37.0241 20.3312C37.212 18.5434 38.7363 17.1921 40.553 17.1921H67.8655C69.6822 17.1921 71.2065 18.5434 71.4153 20.3312C71.4362 20.456 71.4362 20.6223 71.4362 20.7886Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M60.6616 17.1922H48.4043L48.4043 11.8911C48.4043 9.70824 50.1792 7.92041 52.3926 7.92041H56.6941C58.8866 7.92041 60.6824 9.68745 60.6824 11.8911L60.6824 17.1922H60.6616Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.3729 29.3953L89.373 116.542H19.0035L19.0034 29.3953H36.9612L36.9612 30.7673C36.9612 32.7423 38.5691 34.3638 40.5319 34.3638H67.8445C69.8282 34.3638 71.4569 32.7423 71.4569 30.7673L71.4569 29.3953H89.3729Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.3729 29.3953L89.373 116.542H19.0035L19.0034 29.3953H36.9612L36.9612 30.7673C36.9612 32.7423 38.5691 34.3638 40.5319 34.3638H67.8445C69.8282 34.3638 71.4569 32.7423 71.4569 30.7673L71.4569 29.3953H89.3729Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.2808 58.1253H66.6128C65.4852 58.1253 64.5664 57.2106 64.5664 56.088L64.5664 46.4628C64.5664 45.3402 65.4852 44.4255 66.6128 44.4255H76.2808C77.4083 44.4255 78.3271 45.3402 78.3271 46.4628L78.3271 56.088C78.348 57.2106 77.4083 58.1253 76.2808 58.1253Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.2812 81.8868H66.6133C65.4857 81.8868 64.5669 80.9721 64.5669 79.8495L64.5669 70.2243C64.5669 69.1017 65.4857 68.187 66.6132 68.187H76.2812C77.4088 68.187 78.3276 69.1017 78.3276 70.2243L78.3276 79.8495C78.3485 80.9721 77.4088 81.8868 76.2812 81.8868Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.2812 105.648H66.6133C65.4857 105.648 64.5669 104.734 64.5669 103.611L64.5669 93.965C64.5669 92.8424 65.4857 91.9277 66.6132 91.9277H76.2812C77.4088 91.9277 78.3276 92.8424 78.3276 93.965L78.3276 103.59C78.3485 104.734 77.4088 105.648 76.2812 105.648Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.5433 47.1279H28.6924' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.5437 55.4019H28.6929' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.5433 70.8896H28.6924' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.5437 79.1636H28.6929' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.5433 94.6511H28.6924' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.5437 102.925H28.6929' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
