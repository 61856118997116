import styled, { css } from 'styled-components';
import { PrimaryFont } from '../styles';

const _PMarketing = css`
  ${PrimaryFont}
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 29px;
  margin-top: 15px;
`;

const _PMarketingMobile = css`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 23px;
  margin-top: 10px;
`;

export const PMarketing = styled.p`
  ${_PMarketing}
  
  @media (max-width: 992px) {
    ${_PMarketingMobile}
  }
`;

export const DisclaimerText = styled.p`
  color: ${({ theme }) => theme.colors.grey4};
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 0;
`;
