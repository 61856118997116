import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { FlexCol, FlexRow } from '../../styles/styles';

export const BlogSectionContainer = styled(Section)`
  background: ${({ theme }) => theme.colors.grey1};
  position: relative;
  z-index: 1;
`;

export const ContentWrapper = styled(MaxBoundaryContainerLarge)``;

export const TopSection = styled.div`
  ${FlexCol}

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: center;
    justify-content: space-between;
  }
`;

export const TextSection = styled.div`
  ${FlexCol}
  align-items: flex-start;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto 40px;

  h2 {
    font-size: 40px;
    line-height: 2.5rem;
    max-width: 500px;
    margin: unset;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 71px;
      line-height: 4rem;
    }
  }

  p {
    margin: 10px 0 30px;
    max-width: 400px;
  }

  .explore-blog-button {
    margin-top: 20px;
    text-align: center;
  
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      align-self: flex-start;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    margin: unset;
  }
`;

export const ImageSection = styled.div`
  ${FlexRow}
  justify-content: center;

  img {
    margin: 0 auto;
    max-width: 830px;
    width: 100%;
  }

  .article-images {
    margin: 0 auto;
    max-width: 350px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      max-width: 830px;
    }
  }
`;
