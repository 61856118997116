import styled from 'styled-components';
import { FlexCenter, FlexHorizontalCenter } from '../../styles/styles';

interface IProps {
  darkTheme?: boolean;
}

export const PasswordRule = styled.div<IProps>`
  ${FlexHorizontalCenter}
  align-items: start;
  margin-bottom: 20px;


  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    margin-bottom: 10px;
  }

  &.valid {
    span {
      color: ${({ theme, darkTheme }) => darkTheme ? theme.colors.grey3 : theme.colors.darkGray1};
    }
  }

  &.invalid {
    span {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  span {
    text-align: left;
    
    &:first-child {
      ${FlexCenter}
      margin-right: 10px;

      svg {
        width: 12px;
        height: auto;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    span:first-child svg {
      width: 15px;
    }
  }
`;

export const PasswordRulesWrapper = styled.div`
  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(auto-fill, 30px);
    grid-template-columns: auto 40%;
    grid-auto-rows: 100px;
    height: 95px;
    margin-bottom: 10px;
  }
`;

export const PasswordRulesContainer = styled.div<IProps>`
  margin-top: 20px;
  padding-left: 15px;

  H5 {
    color: ${({ theme, darkTheme }) => darkTheme ? theme.colors.grey2 : theme.colors.black};
  }

  & > *:not(:last-child) {
    margin-bottom: 10px;
    font-weight: 600;
  }
`;
