import { animated } from 'react-spring';
import styled from 'styled-components';
import { FlexCenter, FlexCol, FlexHorizontalCenter, InsetAndOuterBoxShadow, InsetBoxShadow, PrimaryFont } from '../../styles/styles';
import { GlobalHeader } from '../GlobalHeader';

interface IMobileNavOffset {
  offset: number;
}

export const AppHeaderContainer = styled.div`
  height: auto;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

export const NavWrapper = styled(GlobalHeader)`
  .global-app-header {
    justify-content: flex-end;
  }

  .nav-link {
    color: ${({ theme }) => theme.colors.darkGray1};
    
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        color: #0A4A53;
      }
    }
  }

  #signin-button {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;

    &:hover {
      color: #0A4A53;
    }
  }

  #create-account-button {
    color: ${({ theme }) => theme.colors.white};
    padding: 10px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding: 10px 24px;
    }
  }
`;

export const DesktopNavContainer = styled.div`
  ${FlexHorizontalCenter}
  align-self: stretch;
  align-items: stretch;
  display: none;
  justify-content: flex-end;
  min-width: 100%;
  padding-left: 50px;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: flex;
  }
`;

export const MobileHeaderNavContainer = styled.div`
  ${FlexHorizontalCenter}
  padding-right: 0px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: none;
  }
`;

export const MobileNav = styled(animated.div)<IMobileNavOffset>`
  ${FlexCol}
  ${InsetBoxShadow}
  background: ${({ theme }) => theme.colors.white};
  height: 0;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: ${({ offset }) => `${offset}px`};
  width: 100vw;
  z-index: 10;

  .mobile-nav-links {
    ${FlexCol}
    padding: 15px;

    a {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray2}`};
      justify-content: flex-start;
      padding: 15px 30px;

      &.active:before {
        display: none;
      }
    }
  }

  .mobile-nav-alt-buttons {
    ${FlexCenter}
    ${InsetBoxShadow}
    background: ${({ theme }) => theme.colors.white};
    margin: 20px auto;
    padding: 15px 15px 0 15px;
    width: 100% !important; // needed in order to override react-sprint styling
  }

  .mobile-nav-global-search {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray2}`};
    padding: 25px 15px;
  }

  .mobile-nav-body {
    ${FlexCol}
    height: 100%;
    justify-content: space-between;
  }
`;

export const MobileNavFooter = styled.div`
  padding-bottom: 15px;

  p {
    color: ${({ theme }) => theme.colors.darkGray2};
    margin-bottom: 5px;
    text-align: center;
  }

  .mobile-nav-sub-links {
    ${FlexHorizontalCenter}
    justify-content: center;

    a {
      color: ${({ theme }) => theme.colors.darkGray2};
      display: block;
      padding: 0 10px;

      &:not(:last-child) {
        border-right: ${({ theme }) => `1px solid ${theme.colors.darkGray2}`}
      }
    }
  }
`;

export const MobileNavItemsContainer = styled.div`
  ${FlexHorizontalCenter}
  align-self: stretch;
  align-items: center;
  min-width: 36px;
  position: relative;

  .mobile-nav-item {
    align-self: stretch;
    margin-left: 20px;
    overflow: visible;
    position: relative;

    &.mobile-avatar {
      height: auto;
    }
  }

  button.mobile-nav-item {
    ${FlexCenter}
    border-radius: 0;
    width: 30px;
  }
`;

export const MobileUserAccountMenu = styled(MobileNav)`
  ${InsetAndOuterBoxShadow};

  .user-account-menu-options {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;

    button {
      padding: 15px 30px;

      &:not(:last-child) {
        border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray2}`};
      }
    }
  }
`;

export const MobileUserAccountMenuOverlay = styled(animated.div)`
  display: block;
  height: 0;
  overflow: hidden;
  z-index: 9;
`;

export const NavItemsContainer = styled(animated.nav)`
  ${FlexHorizontalCenter}

  .active {
    display: flex;
  }

  .hidden {
    display: none;
  }

  &.desktop-nav-links * {
    white-space: nowrap;
  }

  &.desktop-nav-links {
    flex-grow: 2;
    justify-content: center;
  }
`;

export const AltHeaderItemsContainer = styled(NavItemsContainer)`
  box-shadow: none !important;
  justify-content: flex-end;
  min-width: 150px;

  #create-account-button {
    font-weight: 600;
    margin-left: 15px;
    white-space: nowrap;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-left: 22px;
    }
  }


  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    .desktop-search-button {
      display: none;
    }
  }
`;

export const SocialContainer = styled.div`
  ${FlexCol}
  align-items: center;
  margin-bottom: 30px;

  .social-header {
    ${PrimaryFont}
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .mobile-nav-social-links {
    ${FlexHorizontalCenter}

    .social-icon {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
    }
  }
`;
