import React from 'react';
import { H2, H5 } from '../../../styles/components/header';
import { useAnalytics } from '../../../contexts/analytics-store';
import { ArticleContainer, ArticleDataContainer, ArticlesContainer, JoinTheMovementInnerSectionContainer, JoinTheMovementSectionContainer, QuoteContainer } from './styles';
import { theme } from '../../../styles/themes';
import { ApplyButton } from '../styles';
import { ButtonKind } from '../../../components/Button/styles';
import { QuotationMark } from '../../../components/svgs/graphics/QuotationMark';
import { useAppData } from '../../../contexts/app';
import { GenericTag } from '../../../components/GenericTag';

interface IProps {
  className?: string;
}

interface IArticle {
  title: string;
  image: string;
  url: string;
  type: 'Post' | 'Spotlight';
}

const articlesData: IArticle[] = [
  {
    title: 'What is the Karma Collective? Join the Community',
    type: 'Post',
    image: 'https://cdn.karmawallet.io/uploads/DuF0ZSM75N-karma-collective-article-photo.png',
    url: 'https://karmawallet.io/blog/2023/10/what-is-the-karma-collective-join-the-community/',
  },
  {
    title: 'Company Spotlight: J&L Naturals',
    type: 'Spotlight',
    image: 'https://cdn.karmawallet.io/uploads/P7GwNDr5-X-j-and-l-article-photo.png',
    url: 'https://karmawallet.io/blog/2024/05/company-spotlight-jl-naturals/',
  },
  {
    title: 'Company Spotlight: Axiology',
    type: 'Spotlight',
    image: 'https://cdn.karmawallet.io/uploads/0npoA7mBKy-axiology-article-photo.png',
    url: 'https://karmawallet.io/blog/2024/03/company-spotlight-axiology/',
  },
];

export const JoinTheMovementSection: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const appData = useAppData();

  const onArticleClick = () => {
    analytics.fireEvent('KarmaCollectivePage_Article_Click');
  };

  const onJoinNowClick = () => {
    analytics.fireEvent('KarmaCollectivePage_JoinNow_Click');
  };

  const articlesContent = articlesData.map(({ title, type, image, url }) => (
    <ArticleContainer key={ title }>
      <img alt={ `${title} Photo` } src={ image } />
      <ArticleDataContainer
        href={ url }
        onClick={ onArticleClick }
      >
        <GenericTag className='article-type' tagText={ type } color={ theme.colors.primary } />
        <H5>{ title }</H5>
      </ArticleDataContainer>
    </ArticleContainer>
  ));

  return (
    <JoinTheMovementSectionContainer className={ className } backgroundColor={ `${theme.colors.primary}33` }>
      <JoinTheMovementInnerSectionContainer>
        <H2>Join The Movement</H2>
        <QuoteContainer>
          <QuotationMark />
          <div className='quote-content'>
            <p className='quote-text'>
              { !appData.isDesktop && '"' }
              Creating awareness is the critical to the change we need, by creating an opportunity in the financial space Karma Wallet is helping reach those who otherwise might not know about all of the solutions that do exist and need support to grow.
              { !appData.isDesktop && '"' }
            </p>
            <QuotationMark className='quotation-right' />
            <div className='CEO-section'>
              <img alt='Plaine Products CEO Photo' className='CEO-image' src='https://cdn.karmawallet.io/uploads/pkAU80w9Hu-plaine-products-ceo.png' />
              <cite className='CEO-info'>
                <p className='CEO-name'>Lindsey</p>
                <p className='CEO-company'>CEO at Plaine Products</p>
              </cite>
            </div>
          </div>
        </QuoteContainer>
        <ArticlesContainer>
          { articlesContent }
        </ArticlesContainer>
        <ApplyButton
          href='https://docs.google.com/forms/d/e/1FAIpQLSd6ViYM9iVkK_T_HWNDnNUDtQwFjmT4k2p8613IwOO7KQOEeg/viewform'
          kind={ ButtonKind.Primary }
          onClick={ onJoinNowClick }
          target='_blank'
          rel='noreferrer'
        >
          Join Now
        </ApplyButton>
      </JoinTheMovementInnerSectionContainer>
    </JoinTheMovementSectionContainer>
  );
};
