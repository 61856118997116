import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundaryContainer } from '../../styles/components/containers';
import { FlexCol, FlexHorizontalCenter } from '../../styles/styles';
import { IThemeProps } from '../../styles/themes';

interface IProps extends IThemeProps {
  itemsPerRow: number;
}

export const SourcesWrapper = styled.div<IProps>`
  position: relative;

  & > *:not(:last-child) {
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 0;
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    ${ FlexCol }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${ FlexHorizontalCenter }
    flex-wrap: wrap;
    align-items: flex-start;

    & > * {
      min-width: ${({ itemsPerRow }) => `${100 / itemsPerRow}%`};
      max-width: ${({ itemsPerRow }) => `${100 / itemsPerRow}%`};
      padding: 0 15px;
    }

    .unsdg .img-container {
      height: 91px;
    }
  }

  .error-text {
    color: ${({ theme }) => theme.colors.disabled};
  }
`;

export const SourcesContainer = styled(MainContainer)`
  h5 > a {
    color: ${({ theme }) => theme.colors.success};
    font-weight: 700;
  }

  h2 {
    text-align: center;
    margin-bottom: 60px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 40px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    h3 {
      padding-left: 15px;
    }
  }
`;

export const SourcesInner = styled(MaxBoundaryContainer)``;
