import styled from 'styled-components';
import { FlexCol, FlexHorizontalCenter, SecondaryFont } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const OptionsContainer = styled.div`
  ${FlexCol}
  align-items: center;

  button {
    ${FlexHorizontalCenter}
    justify-content: flex-start;
    width: 150px;
    padding: 10px 0 !important; // required to override react-share stylings
    text-align: left;

    &.copied {
      &.with-stroke {
        svg {
          stroke: ${({ theme }) => theme.colors.darkGreen1};
        }
      }

      span {
        color: ${({ theme }) => theme.colors.darkGreen1};
      }
    }

    &:not(.with-stroke) {
      svg {
        fill: ${({ theme }) => theme.colors.darkGray1};
      }
    }

    &.with-stroke {
      svg {
        stroke: ${({ theme }) => theme.colors.darkGray1};
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }

    span {
      ${SecondaryFont}
      padding-left: 15px;
      font-size: 18px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.darkGray1};
      text-align: left;
    }
  }
`;

export const SocialShareModalContainer = styled(CustomModal)`
  width: 80vw;
  max-width: 525px;
  text-align: center;

  p {
    margin-top: 10px;
  }
`;
