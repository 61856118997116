import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const DoneGoodIcon: FC<IProps> = ({ className = '', id = '', fill='#E75929' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }done-good-icon` }
    id={ id }
    width={ 132 }
    height={ 132 }
    fill={ fill }
  >
    <path d='M-9.04826e-05 66.0741C-0.238863 30.6617 28.1257 0.996255 64.1474 0.0246983C100.885 -0.971559 131.835 28.1422 132 65.9506C132.156 102.409 102.491 132 66.0328 132C29.4265 132 0.0246101 102.656 -9.04826e-05 66.0741ZM122.836 66.0494C122.861 34.7867 97.345 9.17215 66.1399 9.13922C34.6713 9.09805 9.36144 34.4738 9.1556 65.9094C8.95799 96.7275 34.5231 123.701 67.449 122.885C97.3861 122.144 122.605 97.7732 122.836 66.0576V66.0494Z' fill={ fill } />
    <path d='M51.7807 93.2859C46.223 87.7859 40.2702 81.8907 34.4161 76.0943C36.5239 74.0277 38.714 71.8705 41.1429 69.491C44.5269 72.9903 48.1332 76.7365 51.6736 80.3922C64.9214 67.2515 77.8892 54.3825 90.7335 41.6452C93.0636 43.9918 95.2125 46.1736 97.378 48.3555C82.3435 63.1759 67.0045 78.2927 51.7889 93.2941L51.7807 93.2859Z' fill={ fill } />
  </SvgIcon>
);
