import styled from 'styled-components';
import { FlexColCenter } from '../../styles/styles';

export const OurImpactCardContainer = styled.div`
  ${FlexColCenter}
  background-color: ${(props) => props.color};
  width: 100%;
  max-width: 397px;
  max-height: 510px;
  min-width: 286px;
  min-height: 372px;
  padding: 20px;
  border-radius: 12px;
  align-items: center;
  justify-content: space-evenly;

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    max-width: 305px;
  }
`;

export const TextContainer = styled.div`
  color: ${(props) => props.color};
  margin-top: 5%;

  .score {
    font-weight: 700;
    font-size: 57px;
    line-height: 55px;
    text-align: center;
    color: inherit;
  }

  .text {    
    font-weight: 600;
    font-size: 21px;
    text-align: center;
    color: inherit;
  }

  .subtext {
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    color: inherit;
  }
`;

export const IconContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  border-radius: 10px;
  max-width: 250px;

  img {
    width: 100%;
  }
`;
