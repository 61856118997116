import styled from 'styled-components';
import { _H3, _H4 } from '../../../styles/components/header';
import { FlexCenter, FlexCol, SecondaryFont } from '../../../styles/styles';
import { CompanyLogo } from '../../CompanyLogo';
import { Modal } from '../Modal';

interface ICompanyCashbackModalProps {
  windowHeight: number;
}

export const CashbackValuesContainer = styled.div`
${FlexCol}
  position: relative;
  min-height: 100px;

  ul {
    padding: 0;
    max-height: 200px;
  }

  .error-text {
    color: ${({ theme }) => theme.colors.disabled};
    margin-top: 20px;
    text-align: center;
  }

  .offer-item {
    list-style: none;
  }

  .offer {
    ${FlexCenter}
    ${SecondaryFont}
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray1};
    justify-content: space-between;
    padding: 15px 0px;

    span:first-of-type {
      padding-right: 20px;
    }

    span:last-of-type {
      font-weight: 600;
    }
  }

  .terms {
    font-size: 12px;
    font-style: italic;
    margin-top: 3px;
  }
`;

export const CompanyCashbackLogo = styled(CompanyLogo)`
  height: 100px;
  max-width: 250px;
  max-height: 100px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    height: 150px;
    max-height: 150px;
  }
`;

export const CompanyCashbackModalContainer = styled(Modal)<ICompanyCashbackModalProps>`
  border: solid 1px ${({ theme }) => theme.colors.darkGray1};
  background: ${({ theme }) => theme.colors.white};
  max-width: 700px;
  height: auto;
  max-height: ${({ windowHeight }) => `calc(${windowHeight}px - 75px)`};
  position: absolute;
  overflow-x: hidden;
  padding: 0;
  top: 75px;
  width: 90%;
  z-index: 1;

  .main-content-container {
    padding: 35px;
    max-height: 90%;
    overflow: auto;
    overflow-x: hidden;

    .cashback-text {
      font-style: italic;
    }
  }

  p {
    font-size: 14px;
    text-align: center;
  }

  @media(min-width: 500px) {
    padding: 0;

    p {
      font-size: 16px;
    }
  }

  .modal-section {
    padding: 15px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 20px;
    }

    .filter-header {
      ${_H3}
      margin-bottom: 15px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    height: auto;
  }
`;

export const Header = styled.div`
  ${FlexCol}
  align-items: center;
  padding: 10px;

  .header-text {
    ${_H4}
    color: ${({ theme }) => theme.colors.darkGray2};
    text-align: center;
    margin: 20px 0 10px;
  }

  .x-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    width: 12px;
  }
`;

export const Footer = styled.div`
  ${FlexCol}
  align-items: center;
  background-color: white;
  bottom: -1px;
  padding: 20px;
  position: sticky;
  
  .start-shopping-button {
    color: ${({ theme }) => theme.colors.dark};
    border: 1px solid ${({ theme }) => theme.colors.dark};
    padding: 8px 22px;
    width: fit-content;
    font-weight: 600px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 35px;
  }
`;
