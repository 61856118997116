import React, { createContext } from 'react';
import { BannersModel } from '../models/banners';

export const BannersContext = createContext<BannersModel>(null);

export const useBanners = () => React.useContext(BannersContext);

export const BannersStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <BannersContext.Provider value={ new BannersModel({ limit: 50 }, false) }>
    { children }
  </BannersContext.Provider>
);
