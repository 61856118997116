import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexColCenter } from '../../styles/styles';
import { ButtonLink } from '../Button';

export const ChildrenCompaniesSectionContainer = styled(MaxBoundarySection)`
  & > h2 {
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const CompaniesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ChildCompany = styled(ButtonLink)`
  ${FlexColCenter}
  text-align: center;
  width: 50%;
  padding: 20px;
  margin-bottom: 20px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    width: 25%;
  }

  .logo-wrapper {
    margin: 0 auto;
    width: 80%;
    height: 50px;
    margin-bottom: 20px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      height: 100px;
    }
  }

  .company-name {
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;
