import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { Section } from '../../styles/components/containers';
import { H2 } from '../../styles/components/header';
import { FlexCol, NoScrollBar } from '../../styles/styles';

export const CareersContainer = styled(MainContainer)`
  .content-container {
    ${ FlexCol }
  }
`;

export const Header = styled(Section)`
  position: relative;
  background: ${({ theme }) => theme.colors.primary};

  h1 {
    color: #fff;
    text-align: center;
  }

  .boomerang {
    position: absolute;
    bottom: -190px;
    left: -30px;
    display: none;
    width: auto;
    height: 380px;
    fill: ${({ theme }) => theme.colors.green};
    z-index: 2;
  }

  @media (min-width: 1261px) {
    .boomerang {
      display: block;
    }
  }
`;

export const JobPostings = styled.div`
  ${ NoScrollBar }
  position: relative;
  padding-top: 20px;
  overflow: auto;

  .job-posting {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray1}`};
  }
`;

export const NoJobs = styled(H2)`
  padding-top: 100px;
  color: ${({ theme }) => theme.colors.lightGray1};
  text-align: center;
`;

export const OpenPositionsContainer = styled(Section)`
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  .square {
    position: absolute;
    bottom: -150px;
    right: 10vw;
    display: none;
    fill: ${({ theme }) => theme.colors.lightYellow2};
  }

  .open-positions-wrapper {
    flex-grow: 1;
    position: relative;
    width: calc(100% - 30px);
    max-width: 920px;
    margin: 0 auto;
    z-index: 1;
  }

  @media (min-width: 1261px) {
    .square {
      display: block;
    }
  }
`;
