import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const MapPinIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }map-pin-icon` }
    id={ id }
    width={ 22 }
    height={ 24 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M19.25 10.1665C19.25 16.5832 11 22.0832 11 22.0832C11 22.0832 2.75 16.5832 2.75 10.1665C2.75 7.97847 3.61919 5.88005 5.16637 4.33287C6.71354 2.7857 8.81196 1.9165 11 1.9165C13.188 1.9165 15.2865 2.7857 16.8336 4.33287C18.3808 5.88005 19.25 7.97847 19.25 10.1665Z' stroke={ stroke } strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11 12.9165C12.5188 12.9165 13.75 11.6853 13.75 10.1665C13.75 8.64772 12.5188 7.4165 11 7.4165C9.48122 7.4165 8.25 8.64772 8.25 10.1665C8.25 11.6853 9.48122 12.9165 11 12.9165Z' stroke={ stroke } strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
