import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const LightBulb: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}lightbulb-graphic` }
    id={ id }
    height={ 94 }
    width={ 89 }
  >
    <path d='M72.226 33.7947C72.2237 30.1461 71.4622 26.5392 69.9912 23.2101C68.5202 19.881 66.3729 16.9049 63.6901 14.4766C61.0072 12.0483 57.8492 10.2227 54.423 9.11918C50.9967 8.01571 47.3794 7.65931 43.808 8.07333C38.2104 8.73738 32.9852 11.2582 28.9432 15.2445C24.9013 19.2309 22.2686 24.4599 21.4538 30.1202C20.8827 34.1412 21.2521 38.2421 22.5319 42.0913C23.8117 45.9405 25.966 49.4299 28.8207 52.2774C31.3594 54.7247 33.3895 57.6632 34.7916 60.9195C36.1936 64.1759 36.9391 67.6842 36.9841 71.2377H56.8948V70.8702C56.9967 63.7469 59.8655 56.9526 64.8772 51.965C69.59 47.1278 72.2311 40.5978 72.226 33.7947Z' fill='#F0E05D' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M29.4541 33.7945C29.4541 31.4952 29.9003 29.2185 30.7672 27.0942C31.6341 24.9699 32.9047 23.0397 34.5065 21.4139C37.7415 18.1303 42.129 16.2856 46.704 16.2856' fill='transparent' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M24.187 56.6682L14.3945 66.6261' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M79.0322 1L69.2397 10.9395' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M69.2397 56.6682L79.0322 66.6261' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M14.3945 1L24.187 10.9395' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M14.8651 33.813H1' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M92.4261 33.813H78.561' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M36.9121 76.7676H56.5151' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M38.7217 82.2795H54.7045' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M40.5317 87.791H52.8945' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
  </SvgIcon>
);
