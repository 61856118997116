import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const EyeIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }eye-icon` }
    id={ id }
    width={ 20 }
    height={ 15 }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M4.80149 10.1647C3.75192 9.16526 3.08946 8.07601 2.78167 7.50002C3.08946 6.92403 3.75192 5.83478 4.80149 4.83529C6.02143 3.67356 7.72475 2.66669 9.99999 2.66669C12.2752 2.66669 13.9786 3.67356 15.1985 4.83529C16.2481 5.83478 16.9105 6.92403 17.2183 7.50002C16.9105 8.07601 16.2481 9.16526 15.1985 10.1647C13.9786 11.3265 12.2752 12.3334 9.99999 12.3334C7.72475 12.3334 6.02143 11.3265 4.80149 10.1647ZM9.99999 0.666687C7.07402 0.666687 4.90115 1.97861 3.42225 3.38695C1.95377 4.78536 1.13068 6.31169 0.857562 6.86984C0.661717 7.27006 0.661717 7.72998 0.857562 8.1302C1.13068 8.68835 1.95377 10.2147 3.42225 11.6131C4.90115 13.0214 7.07402 14.3334 9.99999 14.3334C12.926 14.3334 15.0988 13.0214 16.5777 11.6131C18.0462 10.2147 18.8693 8.68835 19.1424 8.1302C19.3383 7.72998 19.3383 7.27006 19.1424 6.86984C18.8693 6.31169 18.0462 4.78536 16.5777 3.38695C15.0988 1.97861 12.926 0.666687 9.99999 0.666687ZM8.49999 7.50002C8.49999 6.67159 9.17157 6.00002 9.99999 6.00002C10.8284 6.00002 11.5 6.67159 11.5 7.50002C11.5 8.32844 10.8284 9.00002 9.99999 9.00002C9.17157 9.00002 8.49999 8.32844 8.49999 7.50002ZM9.99999 4.00002C8.067 4.00002 6.49999 5.56702 6.49999 7.50002C6.49999 9.43301 8.067 11 9.99999 11C11.933 11 13.5 9.43301 13.5 7.50002C13.5 5.56702 11.933 4.00002 9.99999 4.00002Z' />
  </SvgIcon>
);
