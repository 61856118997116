import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Image, OurImpactDataSourcesContainer, SubcategoryNavigation } from './styles';
import { H2M } from '../../styles/components/header';
import { PMarketing } from '../../styles/components/paragraph';
import { SubcategorySection } from '../UtilizingUNSDGs/SubcategorySection';
import { Button } from '../Button';
import { ISubcategoryGroup, Subcategories, UNSDGModel, UNSDGsModel } from '../../models/unsdgs';
import { removeSpecialCharacters } from '../../lib/misc';
import { useAnalytics } from '../../contexts/analytics-store';
import Skeleton from 'react-loading-skeleton';
import { ButtonKind } from '../Button/styles';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
}

interface IImage {
  imageUrl: string;
  altText: string;
  imageWidth: string;
}

const images: IImage[] = [
  {
    imageUrl: 'https://cdn.karmawallet.io/uploads/b5eBHewcV1-our-impact-climate-neutral.png',
    altText: 'Climate Netural Certified logo',
    imageWidth: '186px',
  },
  {
    imageUrl: 'https://cdn.karmawallet.io/uploads/MlFMCt0O0K-our-impact-ppc.png',
    altText: 'Plastic Pollution Coalition logo',
    imageWidth: '314px',
  },
  {
    imageUrl: 'https://cdn.karmawallet.io/uploads/Bd61IeaEKi-our-impact-repurpose.png',
    altText: 'Repurpose Global logo',
    imageWidth: '218px',
  },
];

const OurImpactDataSourcesBase: React.FC<IProps> = ({
  className = '',
}) => {
  const unsdgsModel = useRef(new UNSDGsModel()).current;
  const analytics = useAnalytics();
  const [activeSubcategory, setActiveSubcategory] = useState(Subcategories.Sustainability);
  const [activeUNSDG, setActiveUNSDG] = useState<UNSDGModel>(null);
  const [unsdgsBySubcategory, setUnsdgsBySubcategory] = useState<ISubcategoryGroup[]>([]);

  useEffect(() => {
    unsdgsModel.load()
      .catch(() => {
        analytics.fireEvent('OurImpact_UNSDGError');
      });
  }, []);

  useEffect(() => {
    if (unsdgsModel.unsdgs.length > 0) {
      analytics.fireEvent('OurImpact_UNSDGLoaded');
      setUnsdgsBySubcategory(unsdgsModel?.groupedUNSDGs);
      setActiveUNSDG(unsdgsModel?.groupedUNSDGs.find(subcat => subcat.subcategory === Subcategories.Sustainability)?.unsdgs[0]);
    }
  }, [unsdgsModel.unsdgs]);

  const onTabClick = useCallback((subcategory: Subcategories) => () => {
    setActiveSubcategory(subcategory);
    setActiveUNSDG(unsdgsBySubcategory.find(subcat => subcat.subcategory === subcategory)?.unsdgs[0]);
    const subcategoryName = removeSpecialCharacters(subcategory).split(' ').join('');
    analytics.fireEvent('OurImpact_SubCategoryClick', subcategoryName);
  }, [unsdgsBySubcategory]);

  const onUNSDGClick = useCallback((unsdg: UNSDGModel) => () => {
    setActiveUNSDG(unsdg);
    analytics.fireEvent('OurImpact_UNSDGClick', String(unsdg.goalNum));
  }, []);

  const renderSubcategoryNav = useCallback(() => {
    let content: JSX.Element[] = [];

    if (!unsdgsBySubcategory.length && unsdgsModel.busy) {
      for (let i = 0; i < 4; i++) {
        content.push(<Skeleton key={ `${i}-skeleton` } className='subcat-skeleton unsdg-picker-button' />);
      }
    }

    if (unsdgsBySubcategory.length) {
      content = unsdgsBySubcategory.map(({ subcategory }) => (
        <Button
          className={ `${subcategory === activeSubcategory ? 'active' : ''} unsdg-picker-button` }
          key={ `subcategory-picker-${subcategory}` }
          onClick={ onTabClick(subcategory) }
          kind={ ButtonKind.Blank }
        >
          <h5>{ subcategory }</h5>
        </Button>
      ));
    }

    return (
      <SubcategoryNavigation>
        { content }
      </SubcategoryNavigation>
    );
  }, [unsdgsBySubcategory, activeSubcategory]);

  const renderSubcategorySection = useCallback(() => {
    if (!activeSubcategory || !unsdgsBySubcategory.length) return null;
    const subcategory = unsdgsBySubcategory.find((category) => category.subcategory === activeSubcategory);

    return (
      <SubcategorySection
        subcategory={ subcategory }
        onUNSDGClick={ onUNSDGClick }
        activeUNSDG={ activeUNSDG }
      />
    ); 
  }, [unsdgsBySubcategory, activeSubcategory, activeUNSDG, onUNSDGClick]);

  const renderCertificationLogos = useMemo(() => {
    const content = images.map(({ imageUrl, altText, imageWidth }) => (
      <div key={ imageUrl } className='cert-logo'>
        <Image
          src={ imageUrl }
          alt={ altText }
          width={ imageWidth }
        />
      </div>
    ));

    return (
      <div className='cert-logo-container'>{ content }</div>
    );
  }, [images]);

  return (
    <OurImpactDataSourcesContainer className={ className }>
      <div className='section-header-content'>
        <H2M>Data Sources</H2M>
        <PMarketing>We work with 30+ data sources to rate thousands of companies, mapping their data to the United Nations Sustainable Development Goals. Some of these data sources are also mapped as Company Values – indicating a company we rate has gone above and beyond with that data source.</PMarketing>
      </div>
      { renderCertificationLogos }
      { renderSubcategoryNav() }
      { renderSubcategorySection() }
    </OurImpactDataSourcesContainer>
  );
};

export const OurImpactDataSources = observer(OurImpactDataSourcesBase);
