import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { MainContainer } from '../../components/MainContainer';
import { Section } from '../../styles/components/containers';
import { BasicContainer, FlexCol, FlexHorizontalCenter, PrimaryFont } from '../../styles/styles';

export const OffsetEmissionsContainer = styled(MainContainer)``;

export const OffsetEmissionsInnerWrapper = styled(Section)`
  margin: 0 auto;
  max-width: 920px;

  .header {
    margin: 30px;
    text-align: center;
  }
`;

export const OverviewSection = styled.div`
  .top-section {
    ${FlexHorizontalCenter}
    justify-content: space-between;
    margin-bottom: 20px;

    .faq-cta {
      ${FlexHorizontalCenter}
      color: ${({ theme }) => theme.colors.darkGreen1}; 

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }

      .faq-cta-text {
        margin: 0 8px 0 10px;
        color: ${({ theme }) => theme.colors.darkGreen1}; 
        
        @media (${({ theme }) => theme.breakpoints.mdMax}) {
          margin: 0 4px;
        }
      }
    }
  }

  .chevron-icon {
    width: 10px;
    height: 10px;
  }

  .text, .rare-link {
    ${PrimaryFont}
    font-weight: normal;
  }

  .text {
    margin-bottom: 20px;
  }

  .rare-link {
    color: ${({ theme }) => theme.colors.darkGreen1}; 
  }
`;

export const DonateNowBlock = styled(Button)`
  ${BasicContainer}
  ${FlexCol}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
  max-width: 874px;
  width: 100%;
  padding: 10px;
  margin: 0 auto 27px;

  .donate-cta-text {
    margin-bottom: 0;

    span {
      font-weight: 700;
      font-size: 24px;
    }
  }

  .sub-text {
    text-align: center;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkGreen1};
    cursor: pointer;
  }
`;

export const RareProjectsContainer = styled.div``;

export const DonateNowSkeleton = styled(Skeleton)`
  max-width: 874px;
  height: 100px;
  border-radius: 20px;
  display: flex;
  margin: 0 auto;
`;
