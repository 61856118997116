import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyModel } from '../../models/companies';
import { useUserSession } from '../../contexts/user';
import { useSignInModal } from '../../contexts/sign-in';
import { buildWildfireCashbackUrl } from '../../lib/urlBuilders';
import { observer } from 'mobx-react';
import { CashbackRedirectContainer, LogInContent } from './styles';
import { AnimatedEllipsis } from '../../components/AnimatedEllipsis';
import { Button } from '../../components/Button';
import { ButtonKind } from '../../components/Button/styles';

import { SignInMode } from '../../models/sign-in';
import { Link, useSearchParams } from 'react-router-dom';

interface IProps {
  className?: string;
  companyId: string;
}

const CashbackRedirectBase: React.FC<IProps> = ({
  className = '',
  companyId,
}) => {
  const signInModal = useSignInModal();
  const userSession = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [company, setCompany] = useState<CompanyModel>();
  const [error, setError] = useState(false);
  const [windowOpened, setWindowOpend] = useState(false);

  const userLoggedIn = useMemo(() => {
    if (!!userSession.authenticating || !userSession.initialized) return false;
    if (userSession.isLoggedIn) return true;
    if (!userSession.isLoggedIn) return false;
  }, [userSession.authenticating, userSession.initialized, userSession.isLoggedIn]);

  const shoppingUrl = useMemo(() => {
    if (!userLoggedIn || !company?.merchant) return null;
    const builtUrl = buildWildfireCashbackUrl(company.merchant.integrations.wildfire, userSession._id);
    return builtUrl;
  }, [userSession._id, userSession, company, userLoggedIn]);

  const loadCompany = useCallback(async() => {
    try {
      const companyData = await CompanyModel.loadCompany(companyId);
      setCompany(companyData);
    } catch {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (!companyId) return () => null;
    loadCompany();
  }, [companyId]);

  useEffect(() => {
    if (!userLoggedIn|| !company?.merchant || !shoppingUrl) return;
    if (!windowOpened) {
      window.location.href = shoppingUrl;
      setWindowOpend(true);
    }
  }, [userLoggedIn, shoppingUrl, company, windowOpened]);

  const onLogInClick = useCallback((signInMode: SignInMode) => () => {
    searchParams.set('redirect', encodeURIComponent(`/redirect/cashback/${companyId}`));
    setSearchParams(searchParams);
    signInModal.open(signInMode);
  }, [signInModal]);

  const content = useMemo(() => {
    if (!!error) return <div>Whoops something went wrong! Please reload or try again later.</div>;
    if (!!userSession.authenticating || (!!userSession.initialized && !!userSession.isLoggedIn) || !company?.merchant) {
      return <AnimatedEllipsis text={ 'Cashback offer activated! Redirecting you to the shopping site' } />;
    }

    return (
      <LogInContent>
        <div>One quick thing before you shop!</div>
        <div>Log in to your Karma Wallet account and we'll automatically redirect you to our brand partner.</div>
        <Button
          className='sign-in-button'
          onClick={ onLogInClick(SignInMode.SIGN_IN) }
          kind={ ButtonKind.Primary }
        >
          Sign In
        </Button>
        <span className='create-account'>
          Don't have a Karma Wallet account?
          <Button
            className='create-account-button'
            onClick={ onLogInClick(SignInMode.CREATE_ACCOUNT) }
            kind={ ButtonKind.Blank }
          >
            Click here
          </Button>
          to join!
        </span>
        <div className='explanation-text'>
          <div className='p'>To receive cashback and ensure it is attributed to your account, you must be logged in.</div> 
          <div className='p'>For more information about cashback read our <Link to='/cashback-info' target='_blank'>Cashback Info Page</Link></div>
        </div>
      </LogInContent>
    ); 
  }, [userLoggedIn, userSession.initialized, error, company]);

  return (
    <CashbackRedirectContainer className={ className }>
      { content }
    </CashbackRedirectContainer>
  );
};

export const CashbackRedirect = observer(CashbackRedirectBase);
