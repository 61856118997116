import React, { useCallback, useState } from 'react';
import { IRadioButtonProps, RadioButton } from '../RadioButton';

import { RadioGroupContainer } from './styles';

export enum RadioGroupStack {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

interface IProps {
  className?: string;
  name: string;
  radioButtons: IRadioButtonProps[];
  stack?: RadioGroupStack;
  onSelectionChange?: (selected: IRadioButtonProps) => void;
}

export const RadioGroup: React.FC<IProps> = ({
  className = '',
  name,
  radioButtons,
  stack = RadioGroupStack.Vertical,
  onSelectionChange,
}) => {
  const [checked, setChecked] = useState(radioButtons.find(r => r.checked) ?? radioButtons[0]);

  const onChange = useCallback((button: IRadioButtonProps) => () => {
    setChecked(button);
    if (onSelectionChange) {
      onSelectionChange(button);
    }
  }, [onSelectionChange]);

  const renderButtons = () => radioButtons.map(button => (
    <RadioButton
      key={ button.id }
      className='radio-button'
      checked={ checked.id === button.id }
      disabled={ !!button.disabled }
      id={ button.id }
      label={ button.label }
      labelHidden={ button.labelHidden }
      name={ name }
      onChange={ onChange(button) }
    />
  ));

  return (
    <RadioGroupContainer className={ `${stack} ${className}` }>
      { renderButtons() }
    </RadioGroupContainer>
  );
};
