import React, { useCallback } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { FacebookIcon } from '../svgs/icons/FacebookIcon';
import { InstagramIcon } from '../svgs/icons/InstagramIcon';
import { LinkedInIcon } from '../svgs/icons/LinkedInIcon';
import { TikTokIcon } from '../svgs/icons/TikTokIcon';
import { SocialLinksContainer } from './styles';
import { XSocialIcon } from '../svgs/icons/XSocialIcon';

interface IProps {
  className?: string;
}

const socialLinks = [
  {
    id: 'linkedin',
    icon: <LinkedInIcon className='social-icon' />,
    url: 'https://www.linkedin.com/company/karmawallet/',
    text: 'LinkedIn',
  },
  {
    id: 'instagram',
    icon: <InstagramIcon className='social-icon' />,
    url: 'https://www.instagram.com/karmawallet/',
    text: 'Instagram',
  },
  {
    id: 'facebook',
    icon: <FacebookIcon className='social-icon' />,
    url: 'https://www.facebook.com/karmawallet1',
    text: 'Facebook',
  },
  {
    id: 'x',
    icon: <XSocialIcon className='social-icon' />,
    url: 'https://x.com/KarmaWallet',
    text: 'X',
  },
  {
    id: 'tiktok',
    icon: <TikTokIcon className='social-icon' />,
    url: 'https://www.tiktok.com/@karma.wallet',
    text: 'TikTok',
  },
];

export const SocialLinks: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  
  const onSocialClick = useCallback((itemName: string) => () => {
    analytics.fireEvent(`Footer_${itemName}`);
  }, []);

  const renderSocialLinks = () => socialLinks.map(({ id, icon, url, text }) => (
    <a
      key={ id }
      href={ url }
      target='_blank'
      onClick={ onSocialClick(text) }
      rel='noreferrer'
      className='social-link'
      aria-label={ `Link to Karma Wallet's ${text} page.` }
    >
      <span className='social-icon-wrapper'>{ icon }</span>
    </a>
  ));

  return (
    <SocialLinksContainer className={ className }>
      { renderSocialLinks() }
    </SocialLinksContainer>
  );
};
