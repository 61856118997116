import React, { useCallback } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { H1, H4 } from '../../styles/components/header';
import { PrivacyPolicyContainer, PrivacyPolicySectionWrapper } from './styles';
import { Link } from 'react-router-dom';

interface IProps {
  className?: string;
}

export const PrivacyPolicy: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();

  const onLinkClick = useCallback((linkName: string) => () => {
    analytics.fireEvent(`PrivacyPolicy_${linkName}Click`);
  }, []);

  return (
    <PrivacyPolicyContainer className={ className } title={ 'Privacy Policy' }>
      <PrivacyPolicySectionWrapper>
        <H1 className='center'>Privacy Policy</H1>
        <p className='center'>Date of last update January, 2023</p>
        <p>This privacy policy (“Privacy Policy”) explains how ImpactKarma, PBC. (“IK” or “we”) collects and processes personal information we collect from users of the websites located at <a onClick={ onLinkClick('ImpactKarma') }href='https://www.karmawallet.com'>https://www.impactkarma.com</a> and <a onClick={ onLinkClick('KarmaWallet') } href='https://www.karmawallet.io'>https://www.karmawallet.io</a> (the “Websites”) or Karma Wallet App (the “App”).</p>

        <p>To the extent that IK has links to other websites, please note that we do not own, operate, or control them, and those websites are not subject to this Privacy Policy. The operators of linked websites may have their own controlling privacy policies that you should review.</p>

        <H4 className='section-title-text'>Personal information we collect from you</H4>
        <p>When you use or access the Websites or use the App , we may collect or receive personal information about you. The types of personal information we collect depends on which services you use. This information may include the following:</p>
        <ul>
          <li>
              Information that you share with us in connection with registering for an account associated with the Websites or the App, including your name, email address, and other information you may submit when registering for an account.
          </li>
          <li>
              Any information you choose to provide when you email us or communicate with us through other means.
          </li>
          <li>
              Any other information you choose to provide when you contact us or otherwise engage with us through the Websites or the App.
          </li>
        </ul>
        <p>In some circumstances, when you use the Websites, you may also provide information about other individuals, such as when you refer friends from your contacts to IK.</p>
        <p>We also may collect certain other information automatically when you engage with the Websites. This data allows us to make sure the Websites or the App are working correctly, to make your experience better, and to detect and prevent fraud. The information we collect may include the following:</p>
        <ul>
          <li>
            <em>Technical Information.</em> IK automatically collects information to ensure that our products work correctly on different devices and browsers. That includes data such as the type of device you're using, device unique identifiers, operating system, browser type, time spent on the Websites or the App, pages visited, referring URL, other website traffic data, IP address, and error logs
          </li>
          <li>
            <em>Search engine and other referrals.</em> When a third party refers you to the Websites or the App, such as clicking on a link to our page from a Google search, or clicking on an online ad for our services, we may receive some information about your device and your interaction with the referring site or content.
          </li>
          <li>
            <em>Location Information.</em> We may receive your approximate location or data that indicates your approximate location, such as your IP address. We use IP addresses for purposes such as calculating usage levels, diagnosing server problems, and administering IK Products. We may also use your approximate location to understand from what regions of the world our website visitors come.
          </li>
          <li>
            <em>Email Analytics.</em> To help us gauge the effectiveness of our communications, we use common digital tools that allow us to see if you opened an email we sent or visited a link in those emails.
          </li>
        </ul>

        <H4 className='section-title-text'>Aggregate and Anonymized Data</H4>
        <p>We may also aggregate, anonymize, or de-identify personal information by removing any details that identify you personally. This lets us look at our users generally and does not identify you or any specific person. We use this general data for research, development, marketing, analytics, and other business purposes, and we may disclose it to third parties, including our clients who advertise or market their products or services through the Websites. Aggregated, anonymized, or de-identified information is not personal information subject to this Privacy Policy and will be used to the extent permissible under applicable law.</p>

        <H4 className='section-title-text'>How we use your data</H4>
        <p>We may use personal information that we collect about you for purposes that include the following:</p>
        <ul>
          <li>
              Providing the Websites and supporting the features and services available to you.
          </li>
          <li>
              Personalizing your digital experience with us, such as enabling you to register for an account, enhancing features, and designing relevant alerts, products, or services.
          </li>
          <li>
              To enable our clients to advertise or market their products or services to you. We may display information on our Websites regarding our clients' products or services based on the information that we collect from or receive regarding you, as well as criteria provided by our clients. We do not disclose information that identifies you to our clients in connection with this activity, although our clients may receive aggregated, anonymized, or de-identified information.
          </li>
          <li>
              Operating, evaluating, and improving our Websites and other products and services.
          </li>
          <li>
              Understanding how you and our clients use the Websites and to determine what features and services may interest you.
          </li>
          <li>
              To communicate with you and others, including responding to requests and providing promotional content.
          </li>
          <li>
              Trouble-shooting, de-bugging, and otherwise addressing issues that may arise with the functionality of the Websites.
          </li>
          <li>
              Creating aggregated, anonymized, or de-identified information. 
          </li>
          <li>
              Protecting the legal rights, property, safety, and security of records or information, our organization and Service users, and others, including detecting and preventing fraud, misuse of the Websites, and other unauthorized activity. 
          </li>
          <li>
              Complying with industry standards, applicable law, judicial process, any properly authorized civil, criminal, or regulatory investigation, or any subpoena or summons issued by a competent authority.
          </li>
          <li>
              Enforcing our policies, terms of use, or rights arising from contracts.
          </li>
          <li>
              For such purposes as you may authorize or direct, or that are described when information is collected from you.
          </li>
        </ul>

        <H4 className='section-title-text'>Parties with which we may share your data </H4>
        <p>We may disclose personal information we collect about you:</p>
        <ul>
          <li>
              To our affiliates.
          </li>
          <li>
              To our contractors, service providers, business partners, and other third parties we use to support our organization and the Websites.
          </li>
          <li>
              To government agencies or others as necessary to comply with any court order, law, or legal process, including to respond to any government or regulatory request, and consistent with other purposes authorized by law.
          </li>
          <li>
              To other organizations, such as a buyer or other successor in interest, in the event of an actual or potential merger, divestiture, restructuring, reorganization, dissolution, or other sale, exchange, or transfer of some or all of our assets, including as part of bankruptcy, liquidation, or similar proceeding.
          </li>
          <li>
              We will also disclose information for any purpose or to any organization that you authorize or direct, or that are described when we collect information from you.
          </li>
        </ul>

        <H4 className='section-title-text'>Integration with Plaid</H4>
        <p>In addition to personal information that we collect directly from you, you may choose to link your account with a third party payment processing service. Currently, IK's services integrate with services provided by Plaid Technologies, Inc. (“Plaid”). If you do so, we may receive information related to your purchases made through your financial institutions, such as date of purchase, transaction amount, and merchant name. We may use and disclose this information as described in the “How we use your data” and “How we share your data” sections, above. However, we will not disclose this information in a form that identifies you to third parties for marketing or advertising purposes. Such third party payment processing services providers' collection, use, and disclosure of information that such third party collects about you is not governed by this Privacy Policy. Refer to such third party's privacy policy for information on Plaid's privacy practices: <a onClick={ onLinkClick('PlaidPrivacy') } href='https://plaid.com/legal/#consumers' target='_blank' rel='noreferrer' >https://plaid.com/legal/#consumers</a></p>

        <H4 className='section-title-text'>How we protect your data</H4>
        <p>The security of your information is important to us. We maintain safeguards intended to protect the personal information collected through the Websites. We have put in place physical, electronic, and procedural safeguards. We can't guarantee that user information will not be accessed, viewed, disclosed, altered, or destroyed despite our safeguards. You provide us with information at your own risk.</p>

        <H4 className='section-title-text'>How we use cookies and similar technologies</H4>
        <p>We may use cookies, pixel tags, web beacons, links, and other markers (“Cookies”) to collect some of the data discussed in this Privacy Policy. Cookies help us operate our products, analyze engagement and usage, and provide a relevant and personalized shopping experience. See below to learn how you can manage your cookie preferences.</p>
        <p>Some cookies can be temporary (“session cookies” like those used for navigating your browser) and disappear once you close it. Others last longer (“persistent cookies,” like when you ask a site to remember your login) and are saved to your computer until you delete them.</p>
        <p>Our cookies serve several important functions:</p>
        <ul>
          <li>
              Operations: These are cookies that we need to make the Websites work, and include remembering entries into a form, navigation, and standard technology checks like load balancing or javascript checks. 
          </li>
          <li>
              Analytics: These types of cookies help IK monitor the traffic and activity on our Websites. We use this data to help us detect fraud, understand trends, broad demographic data, and the general operation of our site. For example, Google Analytics uses cookies to help us analyze how visitors use the Websites. The information generated by the cookies about your use of the Websites includes your IP address. For more information on Google Analytics, including how Google Analytics collects, uses, and discloses information, refer to the following page: <a onClick={ onLinkClick('GooglePolicies') } href='https://policies.google.com/technologies/partner-sites' target='_blank' rel='noreferrer' >https://policies.google.com/technologies/partner-sites</a>
          </li>
          <li>
              Attribution: We use third-party pixels on our landing page to tell our advertising partners that someone found IK through one of their ads. In addition, third-party affiliates may use cookies on our behalf, so that IK can get a commission from a merchant when you make a purchase.
          </li>
        </ul>
        <p>If you want to disable cookies, your browser or mobile device may have an option to do that. If you chose to disable cookies we cannot guarantee that our Websites will work as expected. For more information, including instructions on disabling cookies, please visit: <a onClick={ onLinkClick('AboutCookies') } href='http://www.allaboutcookies.org/' target='_blank' rel='noreferrer' >http://www.allaboutcookies.org/</a></p>

        <H4 className='section-title-text'>Your Choices</H4>
        <p>You may choose not to provide the personal information we request. However, not providing information we request may restrict your ability to use certain features of the Websites. For example, you may be able to restrict the collection of personal information or functionality through your device's operating system or by disabling cookies, but doing so may prevent you from using the functionality of the Websites.</p>
        <p>Some Internet browsers have a “do-not-track” feature that let you tell websites that you do not want to have your online activities tracked. At this time the Websites do not respond to browser “do not track” signals. We may allow third parties to use the Websites to collect personal information about your online activities over time and across different websites, applications, and other online products or services.</p>
        <p>If you would like to delete your registration information, you can always ask us through our <Link onClick={ onLinkClick('ServiceIK') } to='/contact-us'>Contact Us</Link> page. We may retain data, if necessary and permitted or required by law, or if such data is in an aggregated and anonymized format that does not identify you and cannot be attributed to you.</p>

        <H4 className='section-title-text'>Non-US Residents</H4>
        <p>We do not operate or provide IK products to residents of the EU. We are located in the United States and Websites are operated from the United States. If you are located outside of the United States, please do not access the Websites, register for IK's products, or send any personal information to us.</p>

        <H4 className='section-title-text'>Changes to this policy</H4>
        <p>We may update this Privacy Policy to reflect changes in our privacy practices at any time and without prior notice to you. When we do so, we will update the Effective Date of the Privacy Policy, above. We encourage you to periodically review this Privacy Policy for the latest information on our privacy practices.</p>

        <H4 className='section-title-text'>How to contact us</H4>
        <p>If you have any questions about our privacy policies and practice, please contact us through our <Link onClick={ onLinkClick('Help') } to='/contact-us'>Contact Us</Link> page.</p>
      </PrivacyPolicySectionWrapper>
    </PrivacyPolicyContainer>
  );
};
