import styled from 'styled-components';

export const JobPostingContainer = styled.button`
  display: block;
  width: 100%;
  padding-left: 10px;
  background: none;
  border: none;
  outline: none;
  transition: .3s ease-in-out;

  &:hover,
  &:focus-visible {
    background: ${({ theme }) => theme.colors.light};
    cursor: pointer;

    h4 {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  h4 {
    margin-bottom: 0.5rem;
    text-align: left;
  }
`;
