import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { BottomRow, BottomRowItem, DonationItemSkeletonContainer, DonationWrapper, TopRow, TopRowItem } from './styles';

interface IProps {
  className?: string;
}

export const DonationItemSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <DonationItemSkeletonContainer className={ className }>
    <DonationWrapper>
      <TopRow>
        <TopRowItem>
          <Skeleton className='donation-project-name' />
        </TopRowItem>
        <TopRowItem>
          <Skeleton className='donation-amount' />
        </TopRowItem>
      </TopRow>
      <BottomRow>
        <BottomRowItem>
          <Skeleton className='donation-date' />
        </BottomRowItem>
        <BottomRowItem>
          <Skeleton className='donation-tonnes' />
        </BottomRowItem>
      </BottomRow>
    </DonationWrapper>
  </DonationItemSkeletonContainer>
);
