import styled, { css } from 'styled-components';
import { BasicContainer, FlexHorizontalCenter } from '../../styles/styles';
import { Button } from '../Button';

export const MoreMenuTrigger = styled(Button)`
  svg {
    fill: ${({ theme }) => theme.colors.darkGray1};
  }
`;

export const MoreMenuContainer = styled.div`
  padding: 3px; // used so outline doesn't get cutoff
`;

const MoreMenuStyles = css`
  ${FlexHorizontalCenter}
  justify-content: flex-start;
  width: 100%;
  padding: 5px 20px;
  border-radius: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.lightGray2};
  }
`;

export const MoreMenuOption = styled(Button)`
  ${MoreMenuStyles} 
`;

export const MoreMenuOptionOverride = styled.span`
  ${MoreMenuStyles} 
`;

export const OptionsContainer = styled.div`
  ${ BasicContainer }
  min-width: 100px;
  padding: 15px 0;
  background: #fff;
  z-index: 999;
`;
