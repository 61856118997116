import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const GarbageTruck: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}garbage-truck-graphic` }
    id={ id }
    height={ 59 }
    width={ 95 }
  >
    <path d='M26 42H88V48C88 49.1046 87.1046 50 86 50H26V42Z' fill='#fff' stroke='#323232' strokeWidth='2' />
    <path d='M7.72835 50.7756H7.45179C5.88293 50.7756 4.37832 50.1523 3.26896 49.043C2.15961 47.9336 1.53638 46.429 1.53638 44.8602V27.8668C1.53715 26.8501 1.76828 25.8467 2.21242 24.9321L7.37497 14.3766L9.11118 10.8581C9.53009 9.99416 10.1857 9.26696 11.0017 8.76105C11.8177 8.25513 12.7605 7.99129 13.7206 8.00022H25.7973V50.7756H19.6514' fill='#fff' />
    <path d='M7.72835 50.7756H7.45179C5.88293 50.7756 4.37832 50.1523 3.26896 49.043C2.15961 47.9336 1.53638 46.429 1.53638 44.8602V27.8668C1.53715 26.8501 1.76828 25.8467 2.21242 24.9321L7.37497 14.3766L9.11118 10.8581C9.53009 9.99416 10.1857 9.26696 11.0017 8.76105C11.8177 8.25513 12.7605 7.99129 13.7206 8.00022H25.7973V50.7756H19.6514' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='#fff' />
    <path d='M1.53638 35.5998L16.9165 26.2427V14.1968H7.39033' stroke='#323232' fill='#fff' strokeWidth='2' strokeLinejoin='round' />
    <path d='M1.53638 41.6997H7.29814' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.6437 56.5112C16.9107 56.5112 19.5591 53.8628 19.5591 50.5958C19.5591 47.3288 16.9107 44.6804 13.6437 44.6804C10.3767 44.6804 7.72827 47.3288 7.72827 50.5958C7.72827 53.8628 10.3767 56.5112 13.6437 56.5112Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.6433 53.5108C15.2532 53.5108 16.5583 52.2056 16.5583 50.5956C16.5583 48.9856 15.2532 47.6804 13.6433 47.6804C12.0334 47.6804 10.7283 48.9856 10.7283 50.5956C10.7283 52.2056 12.0334 53.5108 13.6433 53.5108Z' fill='#fff' />
    <path d='M55.9154 56.5112C59.1824 56.5112 61.8308 53.8628 61.8308 50.5958C61.8308 47.3288 59.1824 44.6804 55.9154 44.6804C52.6484 44.6804 50 47.3288 50 50.5958C50 53.8628 52.6484 56.5112 55.9154 56.5112Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M55.915 53.5108C57.5249 53.5108 58.83 52.2056 58.83 50.5956C58.83 48.9856 57.5249 47.6804 55.915 47.6804C54.3051 47.6804 53 48.9856 53 50.5956C53 52.2056 54.3051 53.5108 55.915 53.5108Z' fill='#fff' />
    <path d='M74.9154 56.5112C78.1824 56.5112 80.8308 53.8628 80.8308 50.5958C80.8308 47.3288 78.1824 44.6804 74.9154 44.6804C71.6484 44.6804 69 47.3288 69 50.5958C69 53.8628 71.6484 56.5112 74.9154 56.5112Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M74.915 53.5108C76.5249 53.5108 77.83 52.2056 77.83 50.5956C77.83 48.9856 76.5249 47.6804 74.915 47.6804C73.3051 47.6804 72 48.9856 72 50.5956C72 52.2056 73.3051 53.5108 74.915 53.5108Z' fill='#fff' />
    <path d='M30 2V42H93L83.8408 8.228H43.9983L40.3194 2H30Z' fill='#287E8A' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M45.1567 15.4875L49.9198 33.8945' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M57.3411 15.4875L62.1041 33.8945' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.6956 15.4875L73.474 33.8945' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
