import { WebServiceHelper } from '../../lib/webServiceHelperRecoil'; 

export const loadCompanySentiment = async (companyId: string) => {
  try {
    const res = await WebServiceHelper.sendRequest({
      method: 'GET',
      path: `/sentiment/${companyId}`, 
    });
    return res;
  } catch (err: any) {
    throw new Error('Could not load sentiment data');
  }
};
