import React from 'react';
import { PublicationDate, SourceArticleItemsContainer, SourceColumns, SourceName, SourceSentiment, SourcesHeader, SourcesModalContainer, SourcesModalInnerContainer, SourceTitle } from './styles';
import { SentimentSourceArticleItem } from './SentimentSourceArticleItem';
import { useAppData } from '../../../../contexts/app';
import { ISentimentSourceArticle } from '../../../../models/sentiment/types';

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  articles: ISentimentSourceArticle[];
}

export const SourcesModal: React.FC<IProps> = ({
  className,
  isOpen,
  articles,
  onClose,
}) => {
  const appData = useAppData();

  const onCloseModal = () => {
    onClose();
  };

  const articlesLists = () => {
    if (!articles) {
      return null;
    }

    const articlesList = articles.map((article) => (
      <SentimentSourceArticleItem
        key={ article._id }
        article={ article }
      />
    ));

    return (
      <SourceArticleItemsContainer>
        { articlesList }
      </SourceArticleItemsContainer>
    );
  };

  return (
    <SourcesModalContainer
      className={ className }
      isOpen={ isOpen }
      onClose={ onCloseModal }
      windowHeight={ appData.windowVisualViewportHeight }
    >
      <SourcesHeader>Sources</SourcesHeader>
      <SourcesModalInnerContainer>
        <div className='sticky-container'>
          <SourceColumns>
            <SourceName>Source Name</SourceName>
            <SourceTitle>Title</SourceTitle>
            <SourceSentiment>Sentiment</SourceSentiment>
            <PublicationDate className='source-date'>Date</PublicationDate>
          </SourceColumns>
          { articlesLists() }
        </div>
      </SourcesModalInnerContainer>
    </SourcesModalContainer>
  );
};
