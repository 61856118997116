import { BaseModel } from './base';
import { observable, makeObservable, computed, runInAction } from 'mobx';

type PrivateFields = '_success' | '_errorMessage';

export enum SubscriptionCode {
  AccountUpdates = 'accountUpdates',
  MonthlyNewsletters = 'monthlyNewsletters',
  GeneralUpdates = 'generalUpdates',
  GroupAdmins = 'groupAdmins',
  GroupMembers = 'groupMembers',
  DebitCardHolders = 'debitCardHolders',
  DoneGood = 'doneGood',
}

export enum SubscriptionListName {
  EmployerBenefit = 'employerBenefit',
  NonProfit = 'nonProfit',
  SocialMediaCommunity = 'socialMediaCommunity',
  Other = 'other',
  None = 'none'
}

export interface INewsletterUnsubscribeRQ {
  email: string;
  resubscribeList: SubscriptionCode[];
}

export class SubscriptionModel extends BaseModel {
  private _success = false;
  private _errorMessage: string | null = null;

  constructor(basePath: string) {
    super({ basePath });
    makeObservable<SubscriptionModel, PrivateFields>(this, {
      _success: observable,
      _errorMessage: observable,
      success: computed,
      errorMessage: computed,
    });
  }

  get success() { return this._success; }
  get errorMessage() { return this._errorMessage; }

  public unsubscribe = async (req: INewsletterUnsubscribeRQ) => {
    if(!req) throw new Error('Invalid request');

    const result = await this.webServiceHelper.sendRequest<any>({
      path: '/subscription/newsletter-unsubscribe',
      method: 'PUT',
      data: req,
    });

    if (!result.success) {
      runInAction(() => {
        this._success = false;
        this._errorMessage = result.error || 'An error occurred while updating subscriptions.';
      });
      throw new Error(result.error);
    }

    runInAction(() => {
      this._success = true;
      this._errorMessage = null;
    });
  };
}
