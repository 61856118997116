import React from 'react';
import { QuoteSectionContainer, QuoteSectionInnerContainer } from './styles';
import { QuotationMark } from '../../../components/svgs/graphics/QuotationMark';

interface IProps {
  className?: string;
}

export const QuoteSection: React.FC<IProps> = ({
  className,
}) => (
  <QuoteSectionContainer className={ className }>
    <QuoteSectionInnerContainer>
      <div>
        <blockquote>
          <QuotationMark className='left-quote' />
          <div>
            We're all here for the same reason: to create positive impact and be a part of a company that puts people first. This mission drives our team every day - from the daily decisions we make to the big picture projects.
            <QuotationMark className='right-quote' />
          </div>
          <footer>Juliet Payne: Marketing Manager</footer>
        </blockquote>
      </div>
    </QuoteSectionInnerContainer>
  </QuoteSectionContainer>
);
