import React, { createContext } from 'react';
import { ToasterModel } from '../models/toaster';

export const ToasterContext = createContext<ToasterModel>(null);

export const useToaster = () => React.useContext(ToasterContext);

export const ToasterStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ToasterContext.Provider value={ new ToasterModel() }>
    { children }
  </ToasterContext.Provider>
);
