import { BaseModel } from './base';
import { observable, makeObservable, action, computed } from 'mobx';

type PrivateFields = '_success' | '_setSuccess';

export enum InterestCategory {
  EmployerBenefit = 'employerBenefit',
  NonProfit = 'nonProfit',
  SocialMediaCommunity = 'socialMediaCommunity',
  Other = 'other',
  None = 'none'
}

export interface IOffsetInfo {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  interestCategory: InterestCategory;
}

export class GroupOffsetInfoModel extends BaseModel {
  private _success = false;
  constructor(basePath: string) {
    super({ basePath });
    makeObservable<GroupOffsetInfoModel, PrivateFields>(this, {
      _success: observable,
      success: computed,
      _setSuccess: action.bound,
    });
  }

  get success() { return this._success; }

  private _setSuccess = (status: boolean) => {
    this._success = status;
  };

  public requestGroupOffsetInfo = async (userInfo: IOffsetInfo) => {
    if (userInfo.interestCategory === InterestCategory.None) {
      throw new Error('Invalid interest category');
    }
    const result = await this.webServiceHelper.sendRequest<IOffsetInfo>({
      path: '/visitor/submit-interest-form',
      method: 'POST',
      data: userInfo,
    });

    if(!result.success) throw new Error(result.error);

    this._setSuccess(true);
  };
}
