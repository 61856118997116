import React, { useCallback, useEffect, useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { useAnalytics } from '../../../contexts/analytics-store';
import { Button } from '../../Button';
import { ButtonKind } from '../../Button/styles';
import { IModalProps } from '../Modal';
import { CopyIcon } from '../../svgs/icons/CopyIcon';
import { FacebookIcon } from '../../svgs/icons/FacebookIcon';
import { OptionsContainer, SocialShareModalContainer } from './styles';
import { XSocialIcon } from '../../svgs/icons/XSocialIcon';

enum ShareOption {
  Facebook = 'Facebook',
  X = 'X',
  Copy = 'Copy',
}

export enum SocialModalComponentName {
  Company = 'company', 
  Compare = 'compare'
}

interface IProps extends IModalProps {
  shareUrl: string;
  text?: string;
  // name for analytic event
  componentName: string;
}

export const SocialShareModal: React.FC<IProps> = ({
  shareUrl,
  text,
  componentName,
  ...restArgs
}) => {
  const analytics = useAnalytics();
  const [linkCopiedTimeout, setLinkCopiedTimeout] = useState<number>(null);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    if (!linkCopied) return;
    window.clearTimeout(linkCopiedTimeout);
    
    setLinkCopiedTimeout(window.setTimeout(() => {
      setLinkCopied(false);
    }, 3000));
  }, [linkCopied]);

  const onCopyLinkClick = useCallback(() => {
    if (linkCopied) return;
    analytics.fireEvent(`${componentName}_${ShareOption.Copy}`);
    window.navigator.clipboard.writeText(shareUrl);
    setLinkCopied(true);
  }, [shareUrl]);

  const onSocialClick = useCallback((option: ShareOption) => () => {
    analytics.fireEvent(`${componentName}_ShareClick_${option}`);
  }, []);

  return (
    <SocialShareModalContainer
      { ...restArgs }
      title={ componentName === SocialModalComponentName.Company ? 'Share This Company Overview' : 'Share this Comparison' }
    >
      { !!text ? <p>{ text }</p> : null }
      <OptionsContainer>
        <FacebookShareButton
          placeholder={ 'Facebook' }
          className=''
          url={ shareUrl }
          onClick={ onSocialClick(ShareOption.Facebook) }
        >
          <FacebookIcon />
          <span>Facebook</span>
        </FacebookShareButton>
        <TwitterShareButton
          placeholder={ 'X' }
          url={ shareUrl }
          onClick={ onSocialClick(ShareOption.X) }
        >
          <XSocialIcon />
          <span>X</span>
        </TwitterShareButton>
        <Button
          className={ `with-stroke ${linkCopied ? 'copied' : ''}` }
          kind={ ButtonKind.Blank }
          onClick={ onCopyLinkClick }
        >
          <CopyIcon />
          <span>{ linkCopied ? 'Link Copied!' : 'Copy Link' }</span>
        </Button>
      </OptionsContainer>
    </SocialShareModalContainer>
  );
};
