import styled from 'styled-components';
import { _H4, _H5 } from '../../styles/components/header';
import { FlexCenter, FlexHorizontalCenter } from '../../styles/styles';

export const LegacyUNSDGScoresContainer = styled.div`
  .unsdg-category {
    margin-bottom: 30px;
  }

  .unsdg-category-name {
    ${_H4}
    ${FlexCenter}
    margin-bottom: 10px;
    text-align: center;
  }

  .category-graphic {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .unsdg-subcategory-name {
    ${_H5}
    text-align: center;
  }

  .unsdgs-container {
    ${FlexHorizontalCenter}
    justify-content: center;
    margin-bottom: 15px;
  }

  .unsdg {
    width: 25px;
    height: 18px;

    &:not(:last-child) {
      margin-right: 2px;
    }
    
    &.active {
      background: ${({ theme }) => theme.colors.green };
    }

    &.inactive {
      background: ${({ theme }) => theme.colors.lightGray1 };
    }
  }
`;
