import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../styles/components/containers';
import { FlexCol } from '../../styles/styles';

export const OurImpactHeroContainer = styled(Section)``;

export const OurImpactHeroInnerContainer = styled(MaxBoundaryContainer)`
  ${FlexCol}
  align-items: center;
  text-align: center;

  .header, p, div, h1 {
    color: ${({ theme }) => theme.colors.offWhite};
  }

  img {
    width: 100%;
    max-width: 400px;
    margin: 50px 0 50px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin: 100px 0 100px;
      max-width: 750px;
    }
  }
`;
