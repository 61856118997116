import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const LeafB: FC<IProps> = ({
  className = '',
  id = '',
  fill = 'white',
  stroke = 'black',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}leafb-graphic` }
    id={ id }
    height={ 186 } 
    width={ 179 }
    fill={ fill }
    stroke={ stroke }
  >
    <path d='M125.981 37.6935C125.981 37.6935 128.763 52.6987 125.479 61.936C125.479 61.936 130.182 65.3478 134.514 66.4401C134.514 66.4401 127.828 68.7308 126.59 72.6526C124.746 78.4906 120.89 86.6301 110.424 93.0478C110.424 93.0478 112.988 95.1113 120.411 94.8257C125.654 94.6254 136.024 101.811 144.038 108.893C144.038 108.893 139.178 110.446 135.074 112.298C128.977 115.051 121.921 116.767 114.884 116.104L119.12 121.569C119.12 121.569 113.526 121.27 109.764 121.274C106.221 121.278 101.221 119.861 101.221 119.861C101.221 119.861 102.893 124.15 103.508 128.013C104.317 133.095 106.791 136.205 106.791 136.205C106.791 136.205 79.1205 125.357 73.6724 109.784C73.6724 109.784 67.5962 113.919 54.7915 101.895C46.0618 93.6987 40.5242 91.8844 40.5242 91.8844C40.5242 91.8844 50.485 88.5182 60.6358 90.0721C60.6358 90.0721 50.6176 79.3863 49.6709 68.4457L53.7396 70.3727C56.7769 59.5901 56.1598 51.9659 56.1598 51.9659C56.1598 51.9659 66.8349 61.2658 70.8972 63.6835C76.1469 66.8098 78.738 73.1304 78.738 73.1304C78.738 73.1304 83.8053 57.6677 92.2167 50.7858C96.4224 47.3448 93.6091 40.8604 93.6091 40.8604L100.874 45.4491C100.874 45.4491 118.689 45.3877 125.981 37.6935Z' fill={ fill } stroke={ stroke } strokeWidth='1.46256' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M121.05 46.6938C109.468 65.3337 89.3071 88.5521 76.4418 105.458' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.0311 48.8929C97.1824 56.3554 98.2202 61.9698 102.764 71.7668' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M88.4502 63.4741C87.6015 70.9366 87.4928 76.0063 92.0368 85.8032' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M124.896 68.2141C114.818 74.0546 109.848 76.9618 100.377 75.8102' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M112.12 85.5735C102.042 91.4139 98.5416 91.3668 89.0701 90.2152' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M76.4473 105.455C75.7496 97.5663 74.6726 85.7581 60.0616 60.4559' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M132.422 106.789C105.803 103.184 94.829 103.202 76.4412 105.45' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.1202 78.0277C71.4906 75.9859 72.8341 74.2794 73.1455 69.4783' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M71.1178 82.7794C63.9257 82.1575 60.4154 80.1282 54.6193 74.3677' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.816 97.8662C107.139 99.3782 104.878 100.806 103.322 103.796' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.834 117.089C105.506 114.942 101.67 111.734 96.2188 103.921' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M75.9772 100.707C68.9438 95.5746 62.4348 97.7607 52.3517 93.8512' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.377 104.993C87.3108 112.192 93.9447 116.699 99.6492 129.668' stroke={ stroke } strokeWidth='0.933551' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M120.639 60.2193C120.73 60.7885 121.614 60.6465 121.523 60.0773C121.431 59.5081 120.547 59.6501 120.639 60.2193Z' fill={ fill } />
    <path d='M108.21 50.4116C108.127 51.0996 108.109 51.7898 108.138 52.4814C108.143 52.5877 108.235 52.7124 108.318 52.7736C108.406 52.8374 108.542 52.8753 108.651 52.8524C108.911 52.798 109.034 52.5915 109.022 52.3394C109.021 52.2961 109.019 52.2529 109.018 52.2096C109.017 52.1916 109.017 52.1734 109.016 52.1536C109.018 52.2113 109.017 52.2152 109.017 52.1625C109.016 52.0883 109.015 52.0159 109.014 51.9417C109.014 51.7822 109.018 51.622 109.025 51.4631C109.037 51.1438 109.064 50.8258 109.101 50.5078C109.129 50.2749 108.945 50.0289 108.705 50.013C108.465 49.9972 108.24 50.1601 108.21 50.4098L108.21 50.4116Z' fill={ fill } />
    <path d='M108.317 54.847C108.409 55.4162 109.293 55.2742 109.201 54.705C109.11 54.1358 108.226 54.2777 108.317 54.847Z' fill={ fill } />
    <path d='M93.9556 59.9102C93.6863 60.7622 93.7107 61.6596 94.0196 62.4985C94.1004 62.7195 94.4091 62.824 94.6137 62.7205C94.8434 62.6038 94.9226 62.3626 94.8356 62.1263C94.8171 62.0785 94.8018 62.0284 94.785 61.9803C94.7436 61.8582 94.8068 62.0711 94.7761 61.9473C94.753 61.8603 94.7318 61.773 94.7155 61.6832C94.6993 61.5933 94.6868 61.5046 94.6762 61.4157C94.6708 61.3712 94.6673 61.3264 94.6637 61.2816C94.6621 61.2601 94.6576 61.1194 94.6587 61.228C94.6551 61.0473 94.6659 60.866 94.6916 60.6878C94.7045 60.5987 94.7212 60.5108 94.7394 60.4208C94.7588 60.327 94.7187 60.484 94.7496 60.3829C94.7635 60.3335 94.7773 60.2842 94.7929 60.2345C94.864 60.0109 94.7764 59.7367 94.5367 59.6555C94.3199 59.5815 94.0335 59.6728 93.9577 59.9117L93.9556 59.9102Z' fill={ fill } />
    <path d='M94.3048 64.222C94.3962 64.7912 95.2801 64.6492 95.1887 64.08C95.0973 63.5108 94.2134 63.6527 94.3048 64.222Z' fill={ fill } />
    <path d='M108.711 80.9073C108.802 81.4765 109.686 81.3345 109.595 80.7653C109.504 80.1961 108.62 80.338 108.711 80.9073Z' fill={ fill } />
    <path d='M113.521 81.4372C114.223 81.1558 114.886 80.7917 115.511 80.3686C115.705 80.2358 115.723 79.9065 115.573 79.7385C115.399 79.5452 115.15 79.5361 114.943 79.6765C114.871 79.7261 114.797 79.7742 114.723 79.8224C114.702 79.8366 114.68 79.8492 114.659 79.8635C114.704 79.8364 114.705 79.8343 114.663 79.8629C114.631 79.8824 114.598 79.9023 114.567 79.9218C114.416 80.0131 114.263 80.0993 114.108 80.1805C113.797 80.3447 113.478 80.492 113.151 80.6243C112.933 80.712 112.823 81.0089 112.929 81.2185C113.043 81.4432 113.29 81.5341 113.523 81.4404L113.521 81.4372Z' fill={ fill } />
    <path d='M110.057 69.7576C109.929 70.0176 109.8 70.2776 109.671 70.5359C109.647 70.5923 109.639 70.6499 109.644 70.7088C109.641 70.7692 109.653 70.8272 109.68 70.8845C109.725 70.9825 109.822 71.0811 109.925 71.1152C110.037 71.1536 110.165 71.1602 110.274 71.1046L110.373 71.0434C110.434 70.9937 110.476 70.9324 110.506 70.8587C110.635 70.5987 110.764 70.3386 110.893 70.0804C110.916 70.024 110.925 69.9664 110.919 69.9074C110.922 69.8471 110.911 69.7891 110.884 69.7318C110.839 69.6338 110.742 69.5352 110.638 69.5011C110.527 69.4627 110.399 69.4561 110.29 69.5117L110.191 69.5729C110.13 69.6226 110.087 69.6838 110.057 69.7576Z' fill={ fill } />
    <path d='M81.6851 79.7065C81.5242 81.3807 81.768 83.0679 82.3923 84.6287C82.6052 85.1639 83.4213 84.7917 83.2066 84.2567C82.6424 82.8441 82.4297 81.3169 82.5749 79.8047C82.6295 79.2302 81.7399 79.1337 81.6851 79.7065Z' fill={ fill } />
    <path d='M85.6769 85.1207C85.7683 85.6899 86.6522 85.5479 86.5608 84.9787C86.4693 84.4094 85.5854 84.5514 85.6769 85.1207Z' fill={ fill } />
    <path d='M69.8331 69.3309C69.9245 69.9001 70.8084 69.7581 70.717 69.1889C70.6256 68.6197 69.7417 68.7616 69.8331 69.3309Z' fill={ fill } />
    <path d='M60.7262 69.3672L60.8866 70.3661C60.9048 70.4792 60.9733 70.5897 61.066 70.6582C61.1586 70.7268 61.2908 70.7599 61.3996 70.737C61.6365 70.6881 61.8103 70.4716 61.7705 70.2241L61.6101 69.2253C61.5919 69.1121 61.5234 69.0016 61.4308 68.9331C61.3382 68.8646 61.2059 68.8314 61.0972 68.8543C60.8602 68.9033 60.6865 69.1198 60.7262 69.3672Z' fill={ fill } />
    <path d='M61.3273 72.4383C61.4187 73.0075 62.3026 72.8655 62.2112 72.2963C62.1197 71.7271 61.2358 71.8691 61.3273 72.4383Z' fill={ fill } />
    <path d='M68.2311 91.4588C67.888 91.4341 67.5495 91.3815 67.2147 91.3065C67.1656 91.2944 67.1694 91.2956 67.2279 91.3098C67.2053 91.3044 67.1844 91.2987 67.1618 91.2932C67.1277 91.2842 67.0954 91.2749 67.063 91.2656C66.9758 91.2415 66.8879 91.2139 66.8001 91.1863C66.6373 91.1327 66.4755 91.0734 66.3181 91.008C66.1005 90.9179 65.8151 91.0381 65.739 91.2643C65.6629 91.4905 65.7642 91.748 65.9953 91.8433C66.675 92.124 67.4028 92.2972 68.1349 92.3501C68.3679 92.3671 68.6132 92.2007 68.6297 91.9533C68.6446 91.7188 68.4841 91.4762 68.2329 91.4585L68.2311 91.4588Z' fill={ fill } />
    <path d='M102.072 96.002C102.164 96.5712 103.048 96.4293 102.956 95.86C102.865 95.2908 101.981 95.4328 102.072 96.002Z' fill={ fill } />
    <path d='M89.3597 99.3567L88.9975 100.523C88.9618 100.639 88.9555 100.758 89.0081 100.871C89.0529 100.969 89.1503 101.068 89.2538 101.102C89.3651 101.14 89.4931 101.147 89.602 101.091C89.7027 101.041 89.7998 100.956 89.8328 100.846L90.1949 99.6795C90.2306 99.5631 90.2369 99.4443 90.1843 99.3312C90.1396 99.2332 90.0421 99.1346 89.9387 99.1005C89.8274 99.0621 89.6994 99.0555 89.5904 99.1111C89.4898 99.1617 89.3927 99.2462 89.3597 99.3567Z' fill={ fill } />
    <path d='M95.2866 112.153C95.5227 112.833 95.9496 113.425 96.4896 113.897C96.6668 114.051 96.9898 113.985 97.1154 113.796C97.2601 113.579 97.2047 113.336 97.0149 113.17C96.9982 113.157 96.9314 113.113 96.9282 113.094C96.9771 113.138 96.9836 113.145 96.9493 113.112C96.9362 113.098 96.9215 113.086 96.9084 113.071C96.8542 113.017 96.8016 112.96 96.7507 112.903C96.6486 112.787 96.5546 112.664 96.467 112.537C96.4553 112.52 96.4454 112.504 96.4358 112.489C96.4093 112.448 96.4142 112.456 96.4507 112.514C96.4524 112.514 96.3962 112.423 96.3912 112.415C96.3533 112.348 96.3169 112.28 96.2823 112.211C96.2128 112.071 96.1553 111.928 96.1029 111.783C96.0256 111.561 95.7116 111.457 95.5088 111.561C95.2758 111.68 95.2049 111.916 95.2868 112.155L95.2866 112.153Z' fill={ fill } />
    <path d='M122.237 109.348C122.276 110.103 122.851 110.824 123.573 111.041C123.689 111.077 123.807 111.084 123.921 111.031C124.019 110.986 124.118 110.888 124.152 110.785C124.225 110.57 124.136 110.278 123.896 110.206C123.812 110.181 123.733 110.149 123.655 110.109C123.578 110.068 123.752 110.166 123.681 110.121C123.663 110.109 123.645 110.098 123.626 110.086C123.59 110.063 123.555 110.038 123.522 110.01C123.505 109.997 123.488 109.983 123.472 109.97C123.444 109.947 123.401 109.888 123.491 109.99C123.463 109.958 123.431 109.929 123.402 109.896C123.344 109.83 123.293 109.761 123.247 109.686L123.308 109.785C123.239 109.673 123.186 109.557 123.148 109.43L123.182 109.545C123.15 109.434 123.13 109.319 123.124 109.204C123.117 109.096 123.027 108.973 122.945 108.912C122.856 108.848 122.72 108.81 122.611 108.833C122.502 108.856 122.384 108.913 122.319 109.012C122.25 109.118 122.232 109.22 122.24 109.346L122.237 109.348Z' fill={ fill } />
    <path d='M124.817 98.5933C124.909 99.1625 125.793 99.0206 125.701 98.4513C125.61 97.8821 124.726 98.0241 124.817 98.5933Z' fill={ fill } />
    <path d='M86.8969 119.304C86.9535 119.329 87.0102 119.354 87.0636 119.382C87.0853 119.393 87.107 119.404 87.129 119.417C87.0478 119.374 87.1255 119.417 87.1414 119.426C87.2417 119.486 87.3408 119.55 87.4354 119.62C87.5301 119.69 87.6186 119.766 87.707 119.843C87.7577 119.887 87.6336 119.769 87.7178 119.854C87.7351 119.871 87.7524 119.888 87.7699 119.907C87.8132 119.951 87.855 119.997 87.8951 120.043C87.9685 120.127 88.0368 120.214 88.102 120.303C88.1373 120.354 88.1726 120.404 88.2064 120.457C88.1849 120.424 88.1793 120.412 88.2041 120.454C88.2193 120.48 88.2344 120.507 88.2496 120.533C88.3061 120.637 88.359 120.741 88.4037 120.85C88.4272 120.906 88.451 120.964 88.4713 121.022C88.4806 121.046 88.4878 121.068 88.4953 121.092C88.4996 121.108 88.5351 121.227 88.5049 121.118C88.5698 121.342 88.7617 121.543 89.0178 121.489C89.232 121.446 89.4583 121.217 89.3888 120.976C89.0645 119.86 88.2878 118.942 87.22 118.47C87.0054 118.376 86.7158 118.504 86.6409 118.726C86.5595 118.964 86.667 119.205 86.8972 119.305L86.8969 119.304Z' fill={ fill } />
    <path d='M76.8136 108.969C76.905 109.538 77.7889 109.396 77.6975 108.827C77.606 108.258 76.7221 108.4 76.8136 108.969Z' fill={ fill } />
  </SvgIcon>
);
