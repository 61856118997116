import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAnalytics } from '../../contexts/analytics-store';
import { removeSpecialCharacters } from '../../lib/misc';
import { ISubcategoryGroup, Subcategories, UNSDGModel, UNSDGsModel } from '../../models/unsdgs';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { SectionHeader } from '../SectionHeader';
import { SubcategoryNav, UtilizingUNSDGsContainer } from './styles';
import { SubcategorySection } from './SubcategorySection';

interface IProps {
  className?: string;
}

const UtilizingUNSDGsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const unsdgsModel = useRef(new UNSDGsModel()).current;
  const analytics = useAnalytics();
  const [activeSubcategory, setActiveSubcategory] = useState(Subcategories.Sustainability);
  const [activeUNSDG, setActiveUNSDG] = useState<UNSDGModel>(null);
  const [unsdgsBySubcategory, setUnsdgsBySubcategory] = useState<ISubcategoryGroup[]>([]);

  useEffect(() => {
    unsdgsModel.load()
      .catch(() => {
        analytics.fireEvent('HowItWorks_UNSDGError');
      });
  }, []);

  useEffect(() => {
    if (unsdgsModel.unsdgs.length > 0) {
      analytics.fireEvent('HowItWorks_UNSDGLoaded');
      setUnsdgsBySubcategory(unsdgsModel?.groupedUNSDGs);
      setActiveUNSDG(unsdgsModel?.groupedUNSDGs.find(subcat => subcat.subcategory === Subcategories.Sustainability)?.unsdgs[0]);
    }
  }, [unsdgsModel.unsdgs]);

  const onTabClick = useCallback((subcategory: Subcategories) => () => {
    setActiveSubcategory(subcategory);
    setActiveUNSDG(unsdgsBySubcategory.find(subcat => subcat.subcategory === subcategory)?.unsdgs[0]);
    const subcategoryName = removeSpecialCharacters(subcategory).split(' ').join('');
    analytics.fireEvent(`HowItWorks_SubCategoryClick_${subcategoryName}`);
  }, [unsdgsBySubcategory]);

  const onUNSDGLinkClick = () => {
    analytics.fireEvent('HowItWorks_UNSDGClick_Link');
  };

  const onUNSDGClick = useCallback((unsdg: UNSDGModel) => () => {
    setActiveUNSDG(unsdg);
    analytics.fireEvent(`HowItWorks_UNSDGClick_${unsdg.goalNum}`);
  }, []);

  const renderSectionHeader = useCallback(() => (
    <SectionHeader
      headerText='Utilizing UN SDGs'
      subText={ <span>We map a company’s data sources to a standard that aligns with the <a onClick={ onUNSDGLinkClick } target='_blank' href='https://sdgs.un.org/goals' rel='noreferrer'>United Nations Sustainable Development Goals</a> - or UN SDGs. The UN SDGs are grouped into People and Planet categories, each containing two subcategories: Sustainability, Climate Action, Community Welfare, and Diversity & Inclusion. Tap through the subcategories below to learn about each UN SDG.</span> }
    />
  ), []);

  const renderSubcategoryNav = useCallback(() => {
    let content: JSX.Element[] = [];

    if (!unsdgsBySubcategory.length && unsdgsModel.busy) {
      for (let i = 0; i < 4; i++) {
        content.push(<Skeleton key={ `${i}-skeleton` } className='subcat-skeleton unsdg-picker-button' />);
      }
    }

    if (unsdgsBySubcategory.length) {
      content = unsdgsBySubcategory.map(({ subcategory }) => (
        <Button
          className={ `${subcategory === activeSubcategory ? 'active' : ''} unsdg-picker-button` }
          key={ `subcategory-picker-${subcategory}` }
          onClick={ onTabClick(subcategory) }
          kind={ ButtonKind.Blank }
        >
          { subcategory }
        </Button>
      ));
    }

    return (
      <SubcategoryNav>
        { content }
      </SubcategoryNav>
    );
  }, [unsdgsBySubcategory, activeSubcategory]);

  const renderSubcategorySection = useCallback(() => {
    if (!activeSubcategory || !unsdgsBySubcategory.length) return null;
    const subcategory = unsdgsBySubcategory.find((category) => category.subcategory === activeSubcategory);

    return (
      <SubcategorySection
        subcategory={ subcategory }
        onUNSDGClick={ onUNSDGClick }
        activeUNSDG={ activeUNSDG }
      />
    ); 
  }, [unsdgsBySubcategory, activeSubcategory, activeUNSDG, onUNSDGClick]);

  return (
    <UtilizingUNSDGsContainer className={ className }>
      { renderSectionHeader() }
      { renderSubcategoryNav() }
      { renderSubcategorySection() }
    </UtilizingUNSDGsContainer>
  );
};

export const UtilizingUNSDGs = observer(UtilizingUNSDGsBase);
