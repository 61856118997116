import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import { Label } from '../../styles/components/label';
import { TextAreaContainer } from './styles';

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  // use to provide additional context
  // use in place of ariaDescribedBy when there isn't an element on the page with the needed description text
  // ariaDescription?: string;
  // use to provide additional context
  // use when an element currently exist with the description text, pass thru that element's id
  ariaDescribedBy?: string;
  labelClassName?: string;
  id: string;
  label?: string;
  errorText?: string;
  labelHidden?: boolean;
  placeholder?: string;
  value: string;
  size?: number;
  // adding to make required
  onChange(e: ChangeEvent<HTMLTextAreaElement>): void;
}

export const TextArea: React.FC<IProps> = ({
  className = '',
  ariaDescribedBy,
  // ariaDescription,
  labelClassName = '',
  id,
  errorText,
  label,
  labelHidden,
  placeholder,
  value,
  size,
  onChange,
}) => (
  <TextAreaContainer className={ className } sizeOfArea={ size }>
    <Label
      className={ `textarea-label ${labelClassName} ${labelHidden ? 'sr-only' : ''}` }
      htmlFor={ id }
    >
      { !labelHidden && label }
    </Label>

    <textarea
      className={ `textarea-field ${errorText ? 'error' : ''}` }
      id={ id }
      // aria-description={ ariaDescription }
      aria-describedby={ ariaDescribedBy }
      placeholder={ placeholder }
      value={ value }
      onChange={ onChange }
    />
    { errorText && <p className='error-text'>{ errorText }</p> }
  </TextAreaContainer>
);
