import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../../styles/styles';
import { TextLGDemiBold, TextMD, TextSMMedium } from '../../../styles/components/text';
import { ButtonLink } from '../../../components/Button';

interface IProps {
  backgroundImage?: string;
}

export const LeftRailContainer = styled.div<IProps>`
  ${FlexCol}
  align-items: center;
  background: ${({ backgroundImage, theme }) => !!backgroundImage ? `url(${backgroundImage})` : theme.colors.grey1};
  background-position: center;
  background-size: cover;
  justify-content: space-between;
  padding: 5% 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 100px 0 60px;
    width: 50%;

    &.apply-info {
      padding: 60px 0;
    }
  }

  &.success {
    padding-bottom: 0;

    .content-wrapper {
      justify-content: center;
      height: 100%;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        justify-content: space-between;
      }
    }

    .karma-app {
      width: 20%;
      margin-left: 20px;
      
      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        width: 400px;
        margin-left: 0;
      }
    }
  }

  .text-wrapper {
    margin-bottom: 0px;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 10px;
    }

    .inverted-text {
      color: transparent;
      font-size: 62px;
      line-height: 62px;
      margin-bottom: -15px;
      text-align: center;
      -webkit-text-stroke-color: ${({ theme }) => theme.colors.white};

      @media (${({ theme }) => theme.breakpoints.lgMax}) {
        font-size: 50px;
        line-height: 50px;
      }

      @media (${({ theme }) => theme.breakpoints.xsMax}) {
        font-size: 44px;
        line-height: 44px;
        margin-bottom: -10px;
      }
    }

    .regular-text  {
      color: ${({ theme }) => theme.colors.white};
      display: inline;
      font-size: 62px;
      line-height: 62px;
      position: relative;
      z-index: 2;

      @media (${({ theme }) => theme.breakpoints.lgMax}) {
        font-size: 50px;
        line-height: 50px;
      }

      @media (${({ theme }) => theme.breakpoints.xsMax}) {
        font-size: 45px;
        line-height: 45px;
      }
    }

    .success-text {
      @media (${({ theme }) => theme.breakpoints.mdMax}) {
        font-size: 40px;
        line-height: 40px;
      }

      @media (${({ theme }) => theme.breakpoints.xsMax}) {
        font-size: 30px;
        line-height: 30px;
      }
    }

    .underlined-text-wrapper {
      position: relative;

      &.block {
        display: block;
      }

      #form-text-underline {
        bottom: 0px;
        left: -4px;
        position: absolute;
        width: 100%;

        @media (${({ theme }) => theme.breakpoints.smMax}) {
          bottom: -4px;
        }
      }
    }

    .underlined-word-wrapper {
      position: relative;
    }
  }

  .top-line-wrapper {
    display: inline;

    .inverted-text {
      display: inline;
    }

    .regular-text {
      padding: 0 10px;
    }
  }
`;

export const ContentWrapper = styled.div`
  ${FlexRow}
  align-items: center;
  justify-content: center;

  @media (${({ theme }) => theme.breakpoints.xsMax}) {
    ${FlexCol}
  } 

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexCol}
    justify-content: flex-start;
  }
`;

export const ApplyInfoContainer = styled.div`
  ${FlexCol}
  align-items: center;
  max-width: 469px;
  width: 100%;

  .membership-card {
    margin-top: 8px;
  }

  .dg-membership-card {
    margin: 8px auto 24px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-top: 70px;

    &.no-group-logo {
      margin-top: 150px;
    }

    .membership-card {
      margin-top: 32px;
    }
  }

  @media (min-width: 2000px) {
    align-self: end;
    margin-right: 120px;
  }
`;

export const SupportedByContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 7px;
  margin-top: 14px;

  .supported {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey5};
    margin: unset;
  }

  .logos-wrapper {
    ${FlexHorizontalCenter}
    gap: 39px;
  }

  svg { 
    width: auto;
  }

  .b-corp-logo {
    height: 59px;
  }

  .one-percent-logo {
    height: 55px;
  }

  .visa-logo {
    height: 20px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexHorizontalCenter}
    margin-top: 86px;

    &.donegood-logos {
      margin-top: 63px;
    }

    .b-corp-logo {
      height: 82px;
    }

    .one-percent-logo {
      height: 77px;
    }

    .visa-logo {
      height: 28px;
    }
  }
`;

export const HeaderContainer = styled.div`
  ${FlexCol}
  align-items: start;
  margin: 10px;
  position: relative;

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 40px;
    line-height: 40px;
    max-width: 300px;

    @media (${({ theme }) => theme.breakpoints.xxsMin}) {
      font-size: 44px;
      line-height: 44px;
      max-width: 340px;
    }

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      font-size: 55px;
      line-height: 60px;
      max-width: 453px;
    }
  }

  .donegood-header {
    color: ${({ theme }) => theme.colors.orange1};
  }

  .generic-tag {
    margin-bottom: 24px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      margin-bottom: 28px;
    }
  }

  .group-logo {
    margin-bottom: 8px;
    max-width: 66px;
    max-height: 66px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      margin-bottom: 20px;
      max-height: 100px;
      max-width: 100px;
    }
  }

  .karma-card {
    bottom: 110px;
    height: 26px;
    position: absolute;
    right: -10px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      bottom: 120px;
      height: 34px;
      right: 26px;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      bottom: 150px;
    }
  }
`;

export const DoneGoodSubheader = styled.p`
  ${TextLGDemiBold}
  font-size: 20px;
  line-height: 26px;
  margin: 8px auto;

  .highlight {
    color: ${({ theme }) => theme.colors.orange1};
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 0;
  }
`;

export const DoneGoodAndCardContainer = styled.div`
  ${FlexHorizontalCenter}
  align-self: center;
  gap: 22px;
  margin-bottom: 20px;

  .donegood-logo {
    height: 36px;
    width: auto;
  }

  .karma-card-dg {
    height: 40px;
  }

  @media (${({ theme }) => theme.breakpoints.xxsMin}) {
    align-self: start;
    gap: 56px;
    margin-bottom: 16px;

    .donegood-logo {
      height: 44px;
    }

    .karma-card-dg {
      height: 48px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    gap: 64px;
    margin-top: -40px;
  }
`;

export const LearnMoreButton = styled(ButtonLink)`
  ${TextMD}
  margin: auto;
  text-align: center;
  text-decoration: underline;
`;
