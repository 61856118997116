import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const CoinB: FC<IProps> = ({
  className = '',
  id = '',
  fill = 'white',
  stroke = 'black',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}coinb-graphic` }
    id={ id }
    height={ 52 } 
    width={ 66 }
    fill={ fill }
    stroke={ stroke }
  >
    <g transform='matrix(1,0,0,1,-11.7893,-21.5216)'>
      <path d='M39.101,62.526C55.923,68.582 72.585,65.089 76.316,54.725C80.047,44.361 69.435,31.05 52.613,24.994C35.791,18.938 19.129,22.431 15.398,32.795C11.667,43.159 22.279,56.47 39.101,62.526Z' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M15.135,33.612C13.626,37.506 11.189,44.887 12.843,48.357C14.682,52.214 17.677,57.249 22.739,61.566C27.639,65.744 34.765,68.727 39.697,70.156C45.102,71.722 53.211,73.13 59.508,71.787C64.895,70.639 69.154,68.64 71.507,65.636C73.131,63.563 74.931,58.703 76.008,55.497C71.742,65.26 55.498,68.428 39.101,62.526C22.718,56.628 12.225,43.849 15.135,33.612ZM76.905,52.42C76.867,52.663 76.822,52.906 76.769,53.147C76.907,52.703 76.983,52.447 76.983,52.447L76.905,52.42Z' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M41.043,57.39C54.339,62.177 67.33,59.913 70.059,52.333C72.787,44.753 64.221,34.728 50.924,29.942C37.628,25.155 24.637,27.419 21.909,34.999C19.18,42.579 27.747,52.603 41.043,57.39Z' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M44.126,32.529L43.989,34.382C43.989,34.382 39.026,35.081 38.554,37.657C38.35,38.768 38.451,40.926 40.73,42.68C43.265,44.63 48.188,45.68 49.067,46.592C49.759,47.309 48.994,48.08 48.381,48.562C47.769,49.043 46.637,49.424 45.682,49.184C45.081,49.033 43.625,48.619 42.366,48.08C40.512,47.287 38.827,46.32 38.827,46.32L36.028,49.074C36.028,49.074 38.42,50.466 39.525,50.971C40.526,51.427 42.661,52.101 42.661,52.101L42.567,54.22L47.864,54.64L47.967,52.578C47.967,52.578 52.403,52.083 54.284,49.434C55.147,48.219 55.121,46.138 54.033,45.001C52.541,43.442 47.896,41.803 45.713,40.96C44.764,40.594 43.133,39.985 44.193,38.517C45.22,37.094 47.372,37.842 48.411,38.137C49.638,38.486 52.63,39.647 52.63,39.647L54.855,36.861C54.855,36.861 53.08,35.874 51.969,35.553C51.052,35.288 49.072,34.769 49.072,34.769L49.165,33.025L44.126,32.529Z' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M15.138,52.216L16.274,43.989' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M19.041,57.721L20.354,50.844' fill='red' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M23.582,62.113L25.05,54.912' fill='red' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M28.883,65.48L30.406,59.124' fill='none' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M34.751,68.114L36.026,61.515' fill='none' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M41.274,70.234L42.361,63.85' fill='none' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M49.432,71.774L50.07,65.345' fill='none' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M57.494,71.999L57.887,65.672' fill='none' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M64.804,70.089L65.032,64.465' fill='none' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M70.009,67.062L70.204,62.016' fill='red' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M62.809,49.334C62.856,49.219 62.848,49.287 62.624,49.242' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M72.435,53.426C72.479,53.217 72.45,52.717 72.615,52.426' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M73.05,51.221C73.038,51.165 73.044,51.192 73.03,51.14' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M26.415,38.114C25.86,37.89 26.141,37.081 26.258,36.486' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M27.048,35.136C27.002,35.151 27.032,35.182 27.042,35.22' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M17,37.39C16.974,37.369 17.156,37.432 17.158,37.432C17.296,37.465 17.366,37.475 17.523,37.53C17.806,37.627 18.096,37.667 18.379,37.761' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M17.493,36.338C17.903,36.525 18.222,36.677 18.682,36.702' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M22.727,48.56C22.734,48.527 22.749,48.554 22.773,48.548' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M25.177,51.477C25.128,51.309 25.275,51.142 25.342,50.995' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M26.257,51.994C26.252,52.012 26.317,51.881 26.327,51.856C26.363,51.766 26.383,51.673 26.407,51.579' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M58.82,37.103C58.768,37.099 58.753,37.145 58.736,37.188' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M41.763,37.884C41.759,37.868 41.761,37.875 41.773,37.904' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M38.218,32.009C38.118,31.878 38.098,31.669 38.159,31.522' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M39.209,31.501C39.203,31.479 39.213,31.506 39.217,31.515' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M30.962,44.551C31.221,44.889 32.066,44.938 32.431,44.882' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M33.792,44.595C33.788,44.596 33.783,44.596 33.779,44.597' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M45.996,25.742C45.926,25.752 46.095,25.706 46.156,25.731C46.355,25.813 46.565,25.859 46.79,25.903C47.363,26.013 47.749,26.251 48.275,26.466' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M49.567,26.912C49.598,26.916 49.586,26.908 49.599,26.942' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M71.582,42.219C71.393,42.158 71.338,41.44 71.187,41.268' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M70.272,40.159C70.2,40.116 70.2,40.107 70.171,40.039' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M50.516,63.128C50.681,62.918 50.644,62.399 50.624,62.131' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M51.442,62.922C51.387,62.677 51.459,62.363 51.49,62.112' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M46.841,66.392C46.803,66.441 46.743,66.413 46.792,66.392' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M37.276,66.424C37.458,66.651 37.701,66.699 37.977,66.812' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M38.508,65.546C38.559,65.578 38.577,65.605 38.636,65.629' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M58.428,54.692C58.624,54.705 58.73,54.751 58.932,54.574' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M62.331,68.035C62.275,67.98 62.308,67.993 62.234,67.993' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M21.743,55.703C21.711,55.696 21.737,55.703 21.681,55.648' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M24.816,28.34C24.701,28.322 24.719,28.358 24.718,28.271' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M65.85,60.472C65.819,60.375 65.946,60.354 66.046,60.353' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
      <path d='M52.473,68.983C52.304,68.908 53.127,69.27 53.206,69.269' fillRule='nonzero' stroke={ stroke } strokeWidth='1px' />
    </g>
  </SvgIcon>
);
