import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(isBetween);

export const inDateRange = (endDate: Date, startDate: Date) => {
  const currentDate = dayjs().utc();
  return currentDate.isBetween(dayjs(startDate), dayjs(endDate));
};

export const isDaylightSavingsTime = () => {
  const d = new Date();
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();    
};

export const isOlderThan30Days = (date: Date) => {
  const currentDate = dayjs().utc();
  return currentDate.diff(dayjs(date), 'day') > 30;
};
