import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useSignInModal } from '../../contexts/sign-in';
import { SignInMode } from '../../models/sign-in';
import { ActionSection } from '../ActionSection';
import { ButtonKind } from '../Button/styles';
import { ConditionalAccess } from '../ConditionalAccess';
import { TrackYourImpactContainer } from './styles';

interface IProps {
  className?: string;
}

const TrackYourImpactCTASectionBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const signInModal = useSignInModal();

  const onTrackImpactClick = useCallback(() => {
    signInModal.open(SignInMode.CREATE_ACCOUNT);
    analytics.fireEvent('HowItWorks_TrackYourImpact_Click');
  }, [signInModal]);

  const ctas = useMemo(() => (
    [
      {
        id: 'track-impact',
        text: 'Create Account',
        kind: ButtonKind.Primary,
        onClick: onTrackImpactClick,
      },
    ]
  ), [signInModal]);

  return (
    <ConditionalAccess loggedIn={ false }>
      <TrackYourImpactContainer className={ className }>
        <ActionSection
          ctas={ ctas }
          className='track-impact-action-section'
          header='Are the brands you love sustainable?'
          isMarketing={ false }
        />
      </TrackYourImpactContainer>
    </ConditionalAccess>
  );
};

export const TrackYourImpactCTASection = observer(TrackYourImpactCTASectionBase);
