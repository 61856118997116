import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../../styles/styles';
import { ButtonLink } from '../../../components/Button';
import { TextLG, TextLGDemiBold } from '../../../styles/components/text';

export const JoinTheMovementSectionContainer = styled(Section)`
  padding: 65px 15px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 90px 15px;
  }
`;

export const JoinTheMovementInnerSectionContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 32px;
  }
`;

export const QuoteContainer = styled.blockquote`
  ${FlexColCenter}
  margin-bottom: 64px;

  svg {
    display: none;
  }

  .quote-content {
    ${FlexCol}
    align-items: center;
    max-width: 600px;
    position: relative;
  }

  .quote-text {
    ${TextLG}
    color: ${({ theme }) => theme.colors.primary};
    font-style: italic;
    margin-bottom: 28px;
    text-align: center;
  }

  .quotation-right {
    bottom: 100px;
    position: absolute;
    right: 0px;
    scale: -1;
    top: unset;
  }

  .CEO-section {
    ${FlexHorizontalCenter}
    gap: 16px;

    img {
      max-width: 62px;
    }

    .CEO-info {
      ${FlexCol}
      align-items: flex-start;
      justify-content: space-between;
    }

    .CEO-name {
      ${TextLGDemiBold}
      margin-bottom: 0;
    }

    .CEO-company {
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    gap: 28px;
    margin-bottom: 86px;

    svg {
      display: inline;
    }

    .quote-content {
      align-items: flex-start;
      max-width: 794px;
    }

    .quote-text {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 40px;
      text-align: left;
    }
  }
`;

export const ArticlesContainer = styled.div`
  ${FlexCol}
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;
  margin-bottom: 64px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 74px;
  }
`;

export const ArticleContainer = styled.div`
  ${FlexCol}
  align-items: flex-start;
  justify-content: flex-start;
  height: 377px;
  max-width: 338px;

  img {
    margin-bottom: 22px;
    max-width: 338px;
  }
`;

export const ArticleDataContainer = styled(ButtonLink)`
  ${FlexCol}
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;

  .article-type {
    color: ${({ theme }) => theme.colors.white};
    font-size: 13px;
    font-weight: 500;
    text-transform: unset;
  }
`;
