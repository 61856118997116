import styled from 'styled-components';
import { MaxBoundaryContainer } from '../../styles/components/containers';
import { FlexCenter, FlexHorizontalCenter } from '../../styles/styles';

export const GroupOffsetStatementsContainer = styled(MaxBoundaryContainer)`
  ${FlexCenter}
  flex-direction: column-reverse;
  padding-top: 0px;

  .divider-line {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
    border: ${({ theme }) => `1px solid ${theme.colors.lightGray1}`};

    .divider-line {
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.lightGray1};
      margin: 20px 0;
    }
  }

  .checkbox {
    background-color: #fff;
  }

  .cell {
    width: 21%;
    padding: 10px;
    text-align: center;
  }

  .button-wrapper {
    text-align: center;
    margin: 0 auto;
  }
`;

export const GroupOffsetStatementsSkeletonContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const StatementsListContainer = styled.div`
  width: 100%;

  .no-statements {
    ${FlexCenter}
    min-height: 60px;
    color: ${({ theme }) => theme.colors.lightGray1};
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 70%;

    .mobile-header {
      display: none;
    }
  }
`;

export const HeaderRowContainer = styled.div`
  .statement-mobile-header {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    background-color: ${({ theme }) => theme.colors.light};
    border: 1px solid ${({ theme }) => theme.colors.lightGray1};
    border-left: none;
    border-right: none;
    padding: 10px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      display: none;
    }
  }

  .table-header {
    display: none;
    
    .statement-checkbox {
      width: 5%;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${ FlexHorizontalCenter }
      width: 100%;
      justify-content: space-between;
      padding: 10px;
      background-color: ${({ theme }) => theme.colors.light};
      border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray1};
  
      .cell {
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
  
        &:first-of-type {
          text-align: left;
          width: 16%;
        }
      }
    }
  }
`;
