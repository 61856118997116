import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { FlexColCenter } from '../../styles/styles';

export const OurImpactContainer = styled(MainContainer)`
  .section-header-content {
    ${FlexColCenter}
    max-width: 730px;
    margin: 0 auto;
    text-align: center;

    .subtext {
      margin-top: 30px;
      font-weight: 500;
      font-size: 20px;
    }
  }
`;
