import React, { AllHTMLAttributes, useRef } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { RadioButtonContainer } from './styles';

export interface IRadioButtonProps extends AllHTMLAttributes<HTMLInputElement> {
  checkedFillColor?: string;
  className?: string;
  id: string;
  label: string;
  labelHidden?: boolean;
}

export const RadioButton: React.FC<IRadioButtonProps> = ({
  className = '',
  checked,
  disabled,
  id,
  label,
  labelHidden,
  ...restProps
}) => {
  const innerFrom = useRef({
    width: '0px',
    height: '0px',
  }).current;
  const innerTo = useRef({
    width: '15px',
    height: '15px',
  }).current;
  const innerSpring = useSpring({
    config: config.wobbly,
    from: innerFrom,
    to: checked ? innerTo : innerFrom,
  });

  return (
    <RadioButtonContainer
      className={ `${disabled ? 'disabled' : ''} ${className}` }
      htmlFor={ id }
    >
      <input
        { ...restProps }
        className='radio-input'
        disabled={ disabled }
        id={ id }
        type='radio'
      />
      <div className={ `outer ${(checked && !disabled) ? 'checked' : ''}` }>
        { (checked || disabled) && <animated.div className='inner' style={ disabled ? null : innerSpring } /> }
      </div>
      <label
        className={ `${labelHidden ? 'sr-only' : ''} label` }
        htmlFor={ id }
      >
        { label }
      </label>
    </RadioButtonContainer>
  );
};
