import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow, LineClamp } from '../../../styles/styles';
import { TextLGDemiBold, TextSMMedium } from '../../../styles/components/text';

export const DontTakeItFromUsSectionContainer = styled(Section)`
  background-color: ${({ theme }) => theme.colors.blue + '55'};
`;

export const DontTakeItFromUsSectionInnerContainer = styled(MaxBoundaryContainer)`
  ${FlexColCenter}
  align-items: center;

  h2 {
    font-size: 40px;
    line-height: 46px;
    max-width: 600px;
    margin-bottom: 17px;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      width: unset;
    }
  }

  p {
    font-weight: 500;
    text-align: center;
    width: 90%;
  }

  img {
    max-width: 338px;
  }

  .consultation-text {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.primary};
    font-style: italic;
    margin-top: 25px;
    max-width: 383px;
    width: 80%;
  }
`;

export const MainBodyContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 60px;
  margin: 25px 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    align-items: center;
    gap: 40px;
    margin: 25px 0;
}
`;

export const QuoteContainer = styled.div``;

export const QuoteTextContainer = styled.blockquote`
  ${FlexColCenter}

  div {
    ${FlexCol}
    color: ${({ theme }) => theme.colors.primary};
    font-size: 22px;
    font-style: italic;
    font-weight: 500;
    line-height: 34px;
    max-width: 500px;
    padding-right: 40px;
    padding-left: 40px;
    position: relative;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 24px;
      padding-right: 0;
      text-align: left;
    }
    
    .quotation-left {
      left: 0px;
      position: absolute;
      top: 0px;
    }
    
    .quotation-right {
      position: absolute;
      right: 10px;
      scale: -1;
      top: 0px;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        bottom: 0px;
        right: 10px;
        top: unset;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    align-items: flex-start;
  }
`;

export const QuoteSourceContainer = styled.footer`
  ${FlexRow}
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding-left: unset;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding-left: 40px;
  }

  cite {
    ${FlexCol}
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-left: 20px;

    span {
      font-size: 17px;
      font-weight: 400;
    }
  }

  img {
    max-width: 62px;
  }
`;

export const ArticleContainer = styled.article`
  ${FlexCol}
  align-items: flex-start;
  max-width: 338px;

  a {
    text-decoration: none;
  }

  .case-study-tag {
    color: ${({ theme }) => theme.colors.white};
    font-size: 13px;
    font-weight: 400;
    text-transform: unset;
  }

  img {
    margin-bottom: 25px;
  }

  h2 {
    ${TextLGDemiBold}
    ${LineClamp}
    line-clamp: 3;
    text-align: left;
    -webkit-line-clamp: 3;
  }
`;
