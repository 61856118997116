import { observer } from 'mobx-react';
import React from 'react';
import { GroupHero } from '../../../components/GroupHero';
import { GroupOffsetStatements } from '../../../components/GroupOffsetStatements';
import { useGroup } from '../../../contexts/group';
import { Section } from '../../../styles/components/containers';
import { StatementsContainer } from './styles';

interface IProps {
  className?: string;
}

export const StatementsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const { group } = useGroup();

  return (
    <StatementsContainer
      className={ className }
      title={ !!group?.name ? `${group?.name} Statements` : null }
    >
      <GroupHero group={ group } />
      <Section className='statements-section'>
        <GroupOffsetStatements group={ group } />
      </Section>
    </StatementsContainer>
  );
};

export const Statements = observer(StatementsBase);
