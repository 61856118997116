import React, { useCallback, useMemo } from 'react';
import { CompanyModel, ICompanySector, ImpactCategory } from '../../models/companies';
import { H2, H3, H4, H6 } from '../../styles/components/header';
import { RatingBar } from '../RatingBar';
import { AverageComparisonItem, CategorySection, CompanyImpactCategoriesContainer, CompanyImpactCategoriesInnerWraper, ComparisonWrapper, ContentWrapper, HeaderSection, HeaderWrapper } from './styles';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { Earth } from '../svgs/graphics/Earth';
import { PeopleGraphic } from '../svgs/graphics/PeopleGraphic';
import { CompanyRatingTag } from '../CompanyRatingTag';
import { CompanyComparisonItem } from './CompanyComparisonItem';

interface IProps extends IThemeProps {
  className?: string;
  companies: CompanyModel[];
  noVS?: boolean;
  description?: JSX.Element;
}

const CompanyImpactCategoriesBase: React.FC<IProps> = ({
  className = '',
  companies,
  noVS,
  theme,
  description,
}) => {
  const sector = useMemo(() => {
    if (!companies.length || !companies[0]) return;
    if (companies.length === 2) {
      // return the shared sectors
      const sharedSectors = [];
      for (let i = 0; i < companies[0].sectors?.length; i++) {
        for (let j = 0; j < companies[1].sectors?.length; j++) {
          if (companies[0].sectors[i]?.sector?._id === companies[1].sectors[j].sector?._id) {
            sharedSectors.push(companies[0].sectors[i]);
          }
        }
      }

      return sharedSectors.find((s: ICompanySector) => s.primary)?.sector || sharedSectors[0]?.sector;
    }

    return companies[0].sectors?.find((s: ICompanySector) => s.primary).sector;
  }, [companies]);

  const renderComparisonWrapper = useCallback((type: ImpactCategory) => {
    if (!!noVS) return null;

    return (
      <ComparisonWrapper>
        <CompanyComparisonItem className='company-badge' company={ companies[0] } type={ type } />
        <H3 className='vs-text'>vs</H3>
        { !!companies[1] ? 
          <CompanyComparisonItem className='company-badge' company={ companies[1] } type={ type } />
          : 
          <AverageComparisonItem>
            <span className='company-name'>Avg. in { sector?.name }</span>
            <CompanyRatingTag rating={ type === ImpactCategory.Planet ? sector?.averageScores.avgPlanetScore : sector?.averageScores.avgPeopleScore } className='rating' />
          </AverageComparisonItem>
        }
      </ComparisonWrapper>
    );
  }, [noVS, companies, sector]);

  const renderImpactSection = useCallback((type: ImpactCategory) => {
    let categoryText: string, subcategoryText: string, icon: any;

    const points = [];

    if (!noVS) {
      points.push( {
        text: `Avg. in ${sector?.name}`,
        value: type === ImpactCategory.Planet ? sector?.averageScores.avgPlanetScore : sector?.averageScores.avgPeopleScore,
      });
    }

    if (type === ImpactCategory.Planet) {
      categoryText = 'Planet';
      subcategoryText = 'Sustainability and Climate Action';
      icon = <Earth />;
    } else {
      categoryText = 'People';
      subcategoryText = 'Community Welfare and Diversity & Inclusion';
      icon = <PeopleGraphic />;
    }

    companies.forEach((c) => {
      let score = 0;

      if (c.categoryScores.length > 0) {
        score = type === ImpactCategory.Planet && c.categoryScores.length > 0 ? c.categoryScores[1]?.score : c.categoryScores[0]?.score;
      } 

      points.push({
        text: c.companyName,
        value: score,
      });
    });

    return (
      <CategorySection className='impact-category-section'>
        <HeaderWrapper className='impact-category-header'>   
          <div className='category-content-wrapper'>
            <div className='category-content'>
              { icon }
              <H4 className='category-header'>{ categoryText }</H4>
            </div>
            <H6 className='sub-category'>{ subcategoryText }</H6>
          </div>
          { renderComparisonWrapper(type) }
        </HeaderWrapper>
        <RatingBar
          className='rating-bar'
          height={ 20 }
          max={ 8 }
          min={ -8 }
          points={ points }
        />
      </CategorySection>
    );
  }, [companies, sector]);

  if (!companies[0]) return null;

  return (
    <CompanyImpactCategoriesContainer className={ className } backgroundColor={ theme.colors.light }>
      <CompanyImpactCategoriesInnerWraper >
        <HeaderSection>
          { 
            !noVS
              ? (
                <H2>
                  { companies[0].companyName }{ !!companies[1] ? '/' + companies[1].companyName : null } vs. Sector Average
                </H2>
              )
              : null
          }
        </HeaderSection>
        <ContentWrapper className='impact-categories-content-wrapper'>
          { renderImpactSection(ImpactCategory.Planet) }
          { renderImpactSection(ImpactCategory.People) }
        </ContentWrapper>
        { description }
      </CompanyImpactCategoriesInnerWraper>
    </CompanyImpactCategoriesContainer>
  );
};

export const CompanyImpactCategories = withTheme(CompanyImpactCategoriesBase);
