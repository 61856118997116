import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexRow } from '../../../styles/styles';

export const TeamSectionContainer = styled(Section)`
  min-height: calc(100vh - 75px);
  width: 100%;
`;

export const TeamSectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexCol}
  align-items: center;

  h2 {
    font-size: 32px;
    line-height: 2.5rem;
    margin-top: 60px;
  }
`;

export const HeaderContainer = styled.div`
  text-align: center;
`;

export const TeamCardsContainer = styled.div`
  ${FlexRow}
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 35px;

  &.advisors {
    max-width: 1100px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 20px;
  }
`;
