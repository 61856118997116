import styled from 'styled-components';
import { _H4 } from '../../../../styles/components/header';

export const AbandonedPageContainer = styled.div`
  text-align: center;

  .status-text {
    margin-bottom: 10px;
  }
`;

export const ResultHeader = styled.p`
  ${_H4}
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 40px;
  text-align: center;
`;
