import React, { useCallback } from 'react';
import { ICta } from '../../CTAs';
import { IModalProps } from '../Modal';
import { CustomModalBody, CustomModalHeader, CustomModalCtas, CustomModalContainer } from './styles';

interface IProps extends IModalProps {
  title?: string;
  ctas?: ICta[];
  children: React.ReactNode;
}

export const CustomModal: React.FC<IProps> = ({
  title,
  children,
  ctas,
  ...restProps
}) => {
  const getHeight = useCallback(() => {
    let minusPx = 0;
    if (!!title) minusPx += 35;
    if (!!ctas) minusPx += 55;

    return `calc(100% - ${minusPx}px)`;
  }, [title, ctas]);

  return (
    <CustomModalContainer { ...restProps }>
      { !!title ? <CustomModalHeader className='modal-header'>{ title }</CustomModalHeader> : null }
      <CustomModalBody style={ { 'height': getHeight() } } className='custom-modal-body'>
        { children }
      </CustomModalBody>
      { !!ctas && <CustomModalCtas className='ctas-container' ctas={ ctas } /> }
    </CustomModalContainer>
  );
};
