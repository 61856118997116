import React from 'react';
import { CompanyModel } from '../../../models/companies';
import { CategoryContainer, CategoryInnerContainer, CompanyNameText, CompanyRatingBreakdownContainer, CompanyRatingBreakdownInnerWrapper, RatingBreakdownContainer, ScoreNumber, TotalScoreContainer } from './styles';
import { IThemeProps } from '../../../styles/themes';
import { withTheme } from 'styled-components';
import { H2, H4, H5 } from '../../../styles/components/header';
import { Subcategories } from '../../../models/unsdgs';
import { SubcategoryItem } from './SubcategoryItem';
import { CircleBarChart } from '../../../components/CircleBarChart';

interface CompanyRatingBreakdownProps extends IThemeProps {
  className?: string;
  company: CompanyModel;
}

const CompanyRatingBreakdownBase: React.FC<CompanyRatingBreakdownProps> = ({
  className,
  company,
  theme,
}) => {
  const renderPlanetCategoryScores = () => (
    <CategoryContainer>
      <H5>Planet</H5>
      <CategoryInnerContainer>
        {
          company.subcategoryScores.map((s) => {
            if (s.subcategory.name === Subcategories.ClimateAction || s.subcategory.name === Subcategories.Sustainability) {
              return <SubcategoryItem subcategoryName={ s.subcategory.name } score={ s.score } key={ s.subcategory.name } />;
            }
          })
        }
      </CategoryInnerContainer>
    </CategoryContainer>
  );

  const renderPeopleCategoryScores = () => (
    <CategoryContainer>
      <H5>People</H5>
      <CategoryInnerContainer>
        {
          company.subcategoryScores.map((s) => {
            if (s.subcategory.name === Subcategories.CommunityWelfare || s.subcategory.name === Subcategories.DiversityInclusion) {
              return <SubcategoryItem subcategoryName={ s.subcategory.name } score={ s.score } key={ s.subcategory.name } />;
            }
          })
        }
      </CategoryInnerContainer>
    </CategoryContainer>
  );
  
  return (
    <CompanyRatingBreakdownContainer backgroundColor={ theme.colors.blue1 } className={ className }>
      <CompanyRatingBreakdownInnerWrapper>
        <RatingBreakdownContainer>
          <CompanyNameText>{ company.companyName }</CompanyNameText>
          <H2>Rating Breakdown</H2>
          { renderPlanetCategoryScores() }
          { renderPeopleCategoryScores() }
          <TotalScoreContainer>
            <H4>Total Rating</H4>
            <ScoreNumber>{ company.combinedScore }</ScoreNumber>
          </TotalScoreContainer>
        </RatingBreakdownContainer>
        <CircleBarChart company={ company } />
      </CompanyRatingBreakdownInnerWrapper>
    </CompanyRatingBreakdownContainer>
  );
};

export const CompanyRatingBreakdown = withTheme(CompanyRatingBreakdownBase);
