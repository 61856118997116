import React from 'react';
import { OurImpactCardContainer, TextContainer, IconContainer } from './styles';
import { H3, H4, H5 } from '../../styles/components/header';

interface IStatProps {
  className?: string;
  icon: JSX.Element;
  score: number;
  text: string;
  subtext?: string;
  textColor: string;
  bgColor: string;
}

export const OurImpactCard: React.FC<IStatProps> = ({
  className = '',
  icon,
  score,
  text,
  subtext,
  textColor,
  bgColor,
}) => (
  <OurImpactCardContainer className={ className } color={ bgColor }>
    <IconContainer>
      { icon }
    </IconContainer>
    <TextContainer color={ textColor }>
      <H3 className='score'>{ score }</H3>
      <H4 className='text'>{ text }</H4>
      { subtext ? <H5 className='subtext'>{ subtext }</H5> : null }
    </TextContainer>
  </OurImpactCardContainer>
)
;
