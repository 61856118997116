import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { FlexColCenter, PrimaryFont } from '../../styles/styles';
import { Button } from '../../components/Button';

export const IndustryReportContainer = styled(MainContainer)`
  .overall-rating {
    max-width: 950px;
    width: 90%;
  }

  .article-img {
    max-width: 100%;
    margin: 10px 0 30px;
  }

  .intro-title {
    font-size: 34px;
  }
  
  .intro-paragraph {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin: 20px 0 50px;
  }

  .section-title {
    ${PrimaryFont}
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.dark};
    margin: 30px 0 15px;
  }

  li {
    margin-bottom: 10px;
  }

  .the-good-list {
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.primary}44;
    width: 100%;
    max-width: 863px;
    padding: 30px;
    align-self: center;
    margin: 20px 0 35px;
  
    h5 {
      font-size: 18px;
      font-weight: 600px;
      margin-bottom: 16px;
    }
  
    ul {
      ${FlexColCenter}
      gap: 15px;
      margin-bottom: 0;
      font-weight: 500;
      padding-left: 20px;
    }
  }
`;

export const HeaderButtonContainer = styled(Button)`
  width: 100%;
`;
