import React, { useMemo } from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { EmojiHappyIcon } from '../svgs/icons/EmojiHappyIcon';
import { EmojiSadIcon } from '../svgs/icons/EmojiSadIcon';
import { BarContainer, BarPointWrapper, BarWrapper, Point, RatingBarContainer } from './styles';

interface ILinePointsProps {
  text: string;
  value: number;
}

interface IProps extends IThemeProps {
  backgroundColor?: string;
  className?: string;
  emojiColor?: string;
  height: number;
  max: number;
  min: number;
  points: ILinePointsProps[];
}

const RatingBarBase: React.FC<IProps> = ({
  backgroundColor,
  className = '',
  emojiColor,
  height,
  max, 
  min,
  points,
  theme,
}) => {
  const range = useMemo(() => max - min, [max, min]);
  const gradientBackground = useMemo(() => `linear-gradient(to right, ${theme.colors.negative} 0, ${theme.colors.negative} 49%, ${theme.colors.neutral} 49%, ${theme.colors.neutral} 74%, ${theme.colors.positive} 74%, ${theme.colors.positive} 100%)`, []);

  const barPoints = useMemo(() => (
    points.sort((a, b) => a.value - b.value).map(({ text, value }) => {
      const percent = (value - min) / range;
      const location = `${percent * 100}`;
      const alignment = parseInt(location) > 50 ? 'right' : 'left';

      return (
        <BarPointWrapper
          key={ text }
          location={ `${location}%` }
          alignment={ alignment }
        >
          <span className='point-text'>{ text }</span>
          <Point className='point' />
        </BarPointWrapper>
      );
    })
  ), [max, min, range, points]);

  return (
    <RatingBarContainer
      className={ className }
    >
      { 
        !!emojiColor && (
          <EmojiSadIcon
            className='emoji-icon'
            fill={ emojiColor }
          />
        )
      }
      <BarWrapper
        backgroundColor={ backgroundColor ? backgroundColor : gradientBackground }
        height={ height }
      >
        { barPoints }
        <BarContainer />
      </BarWrapper>
      { 
        !!emojiColor && (
          <EmojiHappyIcon
            className='emoji-icon'
            fill={ emojiColor }
          />
        )
      }
    </RatingBarContainer>
  );
};

export const RatingBar = withTheme(RatingBarBase);
