import styled from 'styled-components';
import { MaxBoundaryContainerLarge } from '../../styles/components/containers';
import { FlexCenter, FlexCol } from '../../styles/styles';

export const GroupFooterContainer = styled.div``;

export const GroupFooterInner = styled(MaxBoundaryContainerLarge)`
  display: flex;
  flex-direction: column-reverse;

  .logo {
    outline: none;

    &:focus-visible {
      outline: ${({ theme }) => `1px dashed ${theme.colors.darkGray1}`};
      outline-offset: 1px;
    }

    .mobile-logo {
      height: 30px;
      width: auto;
    }

    .desktop-logo {
      display: none;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      .mobile-logo {
        display: none;
      }

      .desktop-logo {
        display: block;
        max-height: 40px;
      }
    }
  }

  nav a {
    padding: 0 15px 8px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  nav {
    ${FlexCol}
    width: 100%;
  }

  .logo-wrapper {
    ${FlexCenter}
    justify-content: start;
    padding: 15px;

    .logo {
      padding-right: 10px;
    }
  }
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    height: 80px;

    nav {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 15px 0;
      width: auto;
    }
  }
`;
