import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const RecycleCan: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}recycle-can-graphic` }
    id={ id }
    height={ 95 }
    width={ 73 }
  >
    <path d='M70.0701 22.42H2.54012C2.33958 22.4265 2.13978 22.3926 1.95256 22.3205C1.76533 22.2483 1.59448 22.1394 1.45012 22C1.30108 21.8509 1.18423 21.6728 1.10683 21.4767C1.02942 21.2806 0.993104 21.0707 1.00012 20.86V15C0.998025 14.5921 1.07644 14.1879 1.23088 13.8103C1.38532 13.4328 1.61275 13.0895 1.90012 12.8C2.18299 12.5087 2.52206 12.2778 2.89678 12.1214C3.2715 11.965 3.67408 11.8863 4.08012 11.89H68.5301C68.9362 11.8863 69.3387 11.965 69.7135 12.1214C70.0882 12.2778 70.4273 12.5087 70.7101 12.8C70.9975 13.0895 71.2249 13.4328 71.3794 13.8103C71.5338 14.1879 71.6122 14.5921 71.6101 15V20.84C71.6117 21.044 71.5728 21.2463 71.4955 21.4352C71.4183 21.624 71.3043 21.7956 71.1601 21.94C71.0208 22.0902 70.8523 22.2103 70.6648 22.2928C70.4773 22.3754 70.275 22.4187 70.0701 22.42Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M42.8399 1H28.7699C27.9591 1.00151 27.1566 1.16306 26.4085 1.47538C25.6603 1.7877 24.9811 2.24464 24.4099 2.82C23.2528 3.9917 22.6058 5.57328 22.6099 7.22V11.91H27.2299V8.77C27.23 7.94706 27.5532 7.15706 28.1299 6.57C28.4155 6.28232 28.7551 6.05385 29.1292 5.89769C29.5033 5.74153 29.9045 5.66076 30.3099 5.66H41.3099C41.7153 5.66076 42.1165 5.74153 42.4906 5.89769C42.8647 6.05385 43.2043 6.28232 43.4899 6.57C44.0666 7.15706 44.3898 7.94706 44.3899 8.77V11.91H48.9999V7.22C49.0002 5.57207 48.3497 3.99069 47.1899 2.82C46.6205 2.24494 45.9431 1.78811 45.1966 1.47577C44.45 1.16342 43.6491 1.00174 42.8399 1Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M60.88 93.5099H10.74C9.94176 93.5133 9.17383 93.2047 8.59996 92.6499C8.0279 92.092 7.68846 91.3381 7.64996 90.5399L4.70996 22.4199H66.91L64 90.5399C63.9482 91.3451 63.5905 92.1001 63 92.6499C62.4316 93.2008 61.6714 93.5092 60.88 93.5099Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M36.9999 39.5301L34.8999 36.1501H31.9999C31.3323 36.1441 30.6745 36.3114 30.0908 36.6357C29.5071 36.9599 29.0176 37.43 28.6699 38.0001L26.6699 41.1901L33.6699 45.6201L36.9999 39.5301Z' fill='#456C51' stroke='#323232' strokeWidth='2' strokeLinejoin='round' />
    <path d='M53.9999 51.4399L46.4099 55.8599L49.5699 61.5599H54.6299L55.9999 59.4199C56.3619 58.8372 56.5612 58.1681 56.577 57.4822C56.5929 56.7964 56.4247 56.1188 56.0899 55.5199L53.9999 51.4399Z' fill='#456C51' stroke='#323232' strokeWidth='2' strokeLinejoin='round' />
    <path d='M24.1899 62.1899L22.6399 65.7399L24.5799 69.0699C24.9359 69.6586 25.4377 70.1454 26.0367 70.4835C26.6358 70.8216 27.312 70.9995 27.9999 70.9999H30.5299V62.1899H24.1899Z' fill='#456C51' stroke='#323232' strokeWidth='2' strokeLinejoin='round' />
    <path d='M48.1199 42.2299L45.6799 38.0499C45.376 37.4756 44.9211 36.9952 44.3642 36.6604C43.8074 36.3255 43.1697 36.1491 42.5199 36.1499H34.9399L40.6199 46.9099L37.4599 48.7999L46.8699 49.8799L51.3799 40.5699L48.1199 42.2299Z' fill='#699E72' stroke='#323232' strokeWidth='2' strokeLinejoin='round' />
    <path d='M41.9999 61.5599V58.3999L35.6699 65.3499L41.9999 73.5699V71.0499H47.0599C47.7124 71.0645 48.3557 70.8938 48.915 70.5575C49.4744 70.2211 49.9269 69.7331 50.2199 69.1499L54.6499 61.5599H41.9999Z' fill='#699E72' stroke='#323232' strokeWidth='2' strokeLinejoin='round' />
    <path d='M31.7599 56.5L27.9999 48.29L17.8799 48.93L21.5099 51.15L19.1599 55.86C18.8349 56.4406 18.6643 57.0947 18.6643 57.76C18.6643 58.4253 18.8349 59.0795 19.1599 59.66L22.6699 65.74L23.9999 63.45L28.7099 55.29L31.7599 56.5Z' fill='#699E72' stroke='#323232' strokeWidth='2' strokeLinejoin='round' />
  </SvgIcon>
);
