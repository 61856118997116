import styled from 'styled-components';
import { PencilBanner } from '../PencilBanner';

export const PayMembershipBannerContainer = styled(PencilBanner)`
  font-size: 14px;

  .pay-membership-cta {
    text-decoration: underline;
    margin-left: 10px;
  }
`;
