import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexCol, FlexRow, PrimaryFont, SecondaryFont } from '../../styles/styles';
import { _H4 } from '../../styles/components/header';

interface IProps {
  width: string;
}

export const OurImpactDataSourcesContainer = styled(MaxBoundarySection)`
  padding: 120px 15px;

  p {
    color: ${({ theme }) => theme.colors.darkGray};
  }

  .cert-logo-container {
    ${FlexCol}
    align-items: center;
    gap: 50px;
    justify-content: space-between;
    margin: 60px 0 60px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${FlexRow}
      margin: 100px 0 100px;
    }
  }
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
  max-width: ${({ width }: IProps) => width};
`;

export const SubcategoryNavigation = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    justify-content: space-between;
    padding: 0px 15px;
    max-width: 900px;
    margin: 60px auto 0;
  }

  .unsdg-picker-button {
    ${SecondaryFont}
    font-weight: normal;
    font-size: 14px;
    padding: 0 10px 20px;

    &.active {
      text-decoration: underline;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${PrimaryFont}
      ${_H4}
    } 
  }

  .subcat-skeleton {
    height: 20px;
    width: 200px;
  }
`;
