import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexColCenter } from '../../styles/styles';

export const HowItWorksContainer = styled(MainContainer)``;

export const TopSection = styled(MaxBoundarySection)`
  ${FlexColCenter}
  align-items: center;

  .how-it-works-graphic {
    width: 350px;
    height: 200px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 500px;
      height: 300px;
    }
  }
`;

export const GreenwashingSection = styled(MaxBoundarySection)`
  .greenwashing-section {
    padding: 0 10px;
  }

  
`;
