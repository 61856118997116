import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CompanyModel } from './companies';

type PrivateFields = '_busy'

export enum UserEventMedium {
  BROWSE = 'browse',
  SEARCH = 'search',
  MAP = 'map',
}

export enum UserEventChannel {
  WEB = 'web',
  MOBILE = 'mobile',
}

export enum UserEventType {
  CLICK = 'click',
  VIEW = 'view',
}

export enum UserEventName {
  OnLoadKarmaCollectiveOffers = 'OnLoadKarmaCollectiveOffers',
  EarnCashbackOffers = 'EarnCashbackOffers',
  BrowseCompanies = 'BrowseCompanies',
  KarmaCollectiveBrowseSection = 'KarmaCollectiveBrowseSection',
  CashbackOfferDetails = 'CashbackOfferDetails',
  CompanyPage = 'CompanyPage',
  KarmaCollectiveWidget = 'KarmaCollectiveWidget',
  AllBrandsWidget = 'AllBrandsWidget',
}

export type AttributionReportingData = {
  merchantRateId: string[];
};

export interface IUserEvent {
  _id: string;
  channel: UserEventChannel;
  date: Date;
  user: string;
  type: UserEventType
  name: UserEventName;
  medium: UserEventMedium;
  data?: AttributionReportingData | any;
}

export type CompanyEvents = { company: CompanyModel, event: Partial<IUserEvent> }[];

export const createViewEventsFromCompaniesAndEventData = (companies: CompanyModel[], name: UserEventName, medium: UserEventMedium): Partial<IUserEvent> => ({
  type: UserEventType.VIEW,
  name,
  medium,
  data: {
    merchantIds: companies?.map((company) => company?.merchant?._id || company?.merchant),
  },
});

export const getMediumFromSearchParams = (searchParams: URLSearchParams) => {
  if (searchParams?.entries?.toString()?.length > 0) {
    return UserEventMedium.SEARCH;
  }
  return UserEventMedium.BROWSE;
};

export class UserEventModel extends BaseModel {
  private _busy = false;
  private userEvents: IUserEvent[] = [];

  constructor(userEventsData?: IUserEvent[]) {
    super();
    makeObservable<UserEventModel, PrivateFields>(this, {
      _busy: observable,
    });
    if (!!userEventsData) {
      this.userEvents = userEventsData;
    }
  }
  get busy() { return this._busy; }
  get events() { return this.userEvents; }

  private setBusy = (busy: boolean) => {
    runInAction(() => {
      this._busy = busy;
    });
  };

  private setEvents = (event: IUserEvent[]) => {
    runInAction(() => {
      this.userEvents = event;
    });
  };

  public createAndSetEvent = async (event: Partial<IUserEvent>) => {
    if (this._busy) return;
    this.setBusy(true);

    const reqData = {
      channel: UserEventChannel.WEB,
      type: event.type,
      name: event.name,
      medium: event.medium,
      data: event.data || {},
    } as IUserEvent;

    this.setEvents([...this.userEvents, reqData]);

    try {
      const result = await this.webServiceHelper.sendRequest<string>(
        {
          path: '/user-event',
          method: 'POST',
          data: reqData,
        },
      );

      if (!result.success) {
        throw new Error(result.error);
      }

    } catch (e) {
      throw new Error('Error creating user event');
    } finally {
      this.setBusy(false);
    }
  };

}
