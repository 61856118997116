import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';
import { TextLGDemiBold, TextLGMedium, TextSM } from '../../../styles/components/text';

export const ByTheNumbersSectionContainer = styled(Section)`
  ${FlexColCenter}
  background-color: ${({ theme }) => theme.colors.dark};
  background-image: url('https://cdn.karmawallet.io/uploads/e1IomduG2t-bythenumbersbg.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: auto 50%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    background-size: 100% auto;
  }
`;

export const ByTheNumbersInnerContainer = styled(MaxBoundaryContainerLarge)`
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 40px;
    margin-bottom: 20px;
  }
`;

export const ByTheNumbersCardsContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
    align-items: flex-start;
  }
`;

export const ByTheNumbersCard = styled.div`
  max-width: 250px;
  width: 90%;

  .numbers-card-text {
    text-align: center;

    .number-text {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 34px;
      font-weight: 600;
    }

    .title-text {
      ${TextLGDemiBold}
      color: ${({ theme }) => theme.colors.white};
      line-height: 0.5rem;
    }

    .description {
      color: ${({ theme }) => theme.colors.white};
      font-size: 15px;
      line-height: 26px;
      margin: 20px 0 0;
    }

    .source-link {
      ${TextSM}
      color: ${({ theme }) => theme.colors.grey3};
      font-weight: 400;
      font-style: italic;
      text-decoration: none;
    }
  }
`;

export const KarmaWalletEmployeeBenefitContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  text-align: center;

  p {
    color: ${({ theme }) => theme.colors.white};
    ${TextLGMedium}
  }
`;

export const BenefitTitleContainer = styled.div`
  ${TextLGDemiBold}
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 999px;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 17px;
  margin-bottom: 45px;
  max-width: 575px;
  padding: 6px 25px;
  text-align: center;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    font-size: 18px;
  }
`;

export const BenefitCardsContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 20px;
  margin: 10px 0 45px;
  width: 100%;

  .benefit-card {
    background-color: ${({ theme }) => theme.colors.darkGreen4};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 20px;
    max-width: 355px;
    padding: 27px 20px;
    width: 100%;

    .benefit-title {
      color: ${({ theme }) => theme.colors.white};
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 3px;
      text-transform: uppercase;
    }

    .benefit-description {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 23px;
      font-weight: 600;
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
  }
`;
