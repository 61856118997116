import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getLocalStorageWithExpirationDayjs, setLocalStorageWithExpirationDayjs } from '../lib/localStorage';

export const useShareASaleParam = () => {
  const [searchParams] = useSearchParams();
  const affiliateParam = searchParams.get('sscid');
  const xTypeParam = searchParams.get('xType');
  const existingAffiliate = getLocalStorageWithExpirationDayjs('affiliateParam');
  const existingXType = getLocalStorageWithExpirationDayjs('xType');

  useEffect(() => {
    if (!!affiliateParam && location.pathname !== '/account') {
      // no existing affiliateParam, so set one
      if (!existingAffiliate) {
        setLocalStorageWithExpirationDayjs('affiliateParam', affiliateParam, 90);
        setLocalStorageWithExpirationDayjs('xTypeParam', xTypeParam, 90);
        setLocalStorageWithExpirationDayjs('sscidCreatedOn', new Date(), 90);
      } 
      if (!!existingAffiliate) {
        if (existingAffiliate !== affiliateParam || xTypeParam !== existingXType) {
          setLocalStorageWithExpirationDayjs('affiliateParam', affiliateParam, 90);
          setLocalStorageWithExpirationDayjs('xTypeParam', xTypeParam, 90);
          setLocalStorageWithExpirationDayjs('sscidCreatedOn', new Date(), 90);
        }
      }
    }
  }, [affiliateParam, existingAffiliate, xTypeParam, existingXType]);
};
