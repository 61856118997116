import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useGroup } from '../../contexts/group';
import { GroupModel } from '../../models/group';
import { GroupBody, MainGroupContainerContainer } from './styles';

interface IProps {
  className?: string;
  title: string;
  children: React.ReactNode;
}

export const MainGroupContainerBase: React.FC<IProps> = ({
  className = '',
  children,
  title,
}) => {
  const location = useLocation();
  const analytics = useAnalytics();
  const errorMessages = useErrorMessages();
  const { group, setGroup } = useGroup();
  const { groupId } = useParams();

  useEffect(() => {
    if (groupId && !group) {
      GroupModel.load(groupId, 'karma')
        .then(setGroup)
        .catch(err => {
          errorMessages.push({ 
            title: 'Error Loading Group',
            message: err.message,
          });
        });
    }
  }, [groupId]);

  useEffect(() => {
    if (!!title) {
      const pageTitle = `${title || 'Groups'} - `;
      document.title = `${pageTitle}Karma Wallet`;
    
      analytics.firePageViewEvent(location.pathname, location.search);
    }
  }, [title]);

  return (
    <MainGroupContainerContainer className={ className }>
      <GroupBody>
        { children }
      </GroupBody>
    </MainGroupContainerContainer>
  );
};

export const MainGroupContainer = observer(MainGroupContainerBase);
