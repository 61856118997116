import React, { useEffect, useMemo, useRef } from 'react';
import { ApplyInfoContainer, ContentWrapper, DoneGoodAndCardContainer, DoneGoodSubheader, HeaderContainer, LearnMoreButton, LeftRailContainer, SupportedByContainer } from './styles';
import { Underline } from '../../../components/svgs/shapes/Undeline';
import { useAppData } from '../../../contexts/app';
import { CurrentPage } from '../../../models/karmaCard';
import { H1MInverted, H1M, H1 } from '../../../styles/components/header';
import { observer } from 'mobx-react';
import { MembershipCard } from '../../../components/MembershipCard';
import KarmaCardImage from '../../../../public/assets/images/empty-kw-card.png';
import { theme } from '../../../styles/themes';
import { GenericTag } from '../../../components/GenericTag';
import { GroupModel } from '../../../models/group';
import { BCorpLogo } from '../../../components/svgs/logos/BCorp/BCorpLogo';
import { OnePercentForThePlanetLogo } from '../../../components/svgs/logos/OnePercentForThePlanetLogo';
import { VisaLogo } from '../../../components/svgs/logos/VisaLogo';
import { useDoneGoodVersion } from '../../../contexts/doneGoodVersion';
import { DoneGoodLogo } from '../../../components/svgs/logos/DoneGoodLogo';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps {
  page: CurrentPage;
  group?: GroupModel;
}

export const LeftRailBase: React.FC<IProps> = ({
  page,
  group,
}) => {
  const ref = useRef(null);
  const app = useAppData();
  const analytics = useAnalytics();
  const { isDoneGood } = useDoneGoodVersion();

  const logosColor = isDoneGood ? theme.colors.orange1 : theme.colors.primary;

  const backgroundImg = useMemo(() => {
    if (app.isLargeDesktop) return 'https://cdn.karmawallet.io/uploads/hFZT0xooKL-rainforest.webp';
    return 'https://cdn.karmawallet.io/uploads/IULhYSvcKm-rainforest-sm.webp';
  }, [app]);

  useEffect(() => {
    if (app.isDesktop) return;

    if (!!ref.current && page === CurrentPage.Success) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [ref.current, page]);

  const onLearnMoreClick = () => {
    analytics.fireEvent('DG_Apply_Learn_More_Click');
  };

  const doneGoodContent = (
    <>
      <DoneGoodAndCardContainer>
        <DoneGoodLogo
          checkMarkColor={ theme.colors.orange1 }
          fill={ theme.colors.black }
          className='donegood-logo'
        />
        <img alt='Karma Wallet Card' className='karma-card-dg' src={ KarmaCardImage } />
      </DoneGoodAndCardContainer>
      <GenericTag
        className='generic-tag'
        color={ theme.colors.orange1 }
        tagText='Become a Changemaker'
        textColor={ theme.colors.white }
      />
      <H1 className='donegood-header'>DoneGood + Karma Wallet</H1>
      <DoneGoodSubheader>Hey DoneGood Shopper, you get <span className='highlight'>HALF</span> off your membership fee.</DoneGoodSubheader>
      <MembershipCard
        kind='orange'
        type='donegood'
        className='dg-membership-card'
      />
      <LearnMoreButton
        href='/donegood-app'
        onClick={ onLearnMoreClick }
      >
        Learn more about the Karma Wallet Membership here
      </LearnMoreButton>
    </>
  );

  const defaultContent = (
    <>
      <GenericTag
        className='generic-tag'
        color={ theme.colors.secondary }
        tagText='Prepaid Reloadable Debit Card'
      />
      <H1>Become a Karma Wallet Member</H1>
      <img alt='Karma Wallet Card' className='karma-card' src={ KarmaCardImage } />
      <MembershipCard kind='light' className='membership-card' />
    </>
  );

  if (page !== CurrentPage.Success) {
    return (
      <LeftRailContainer className='apply-info'>
        <ApplyInfoContainer className={ `${!group?.logo ? 'no-group-logo' : ''}` }>
          <HeaderContainer>
            {
              !!group?.logo &&
              <img
                className='group-logo'
                src={ group?.logo }
                alt={ `${group.company} name` }
              />
            } 
            { isDoneGood ? doneGoodContent : defaultContent }
          </HeaderContainer>
          <SupportedByContainer className={ isDoneGood ? 'donegood-logos' : '' }>
            <p className='supported'>Supported by:</p>
            <div className='logos-wrapper'>
              <BCorpLogo fill={ logosColor } />
              <VisaLogo fill={ logosColor } />
              <OnePercentForThePlanetLogo fill={ logosColor } />
            </div>
          </SupportedByContainer>
        </ApplyInfoContainer>
      </LeftRailContainer>
    );  
  }

  return (
    <LeftRailContainer className='success' ref={ ref } backgroundImage={ backgroundImg }>
      <ContentWrapper className='content-wrapper'>
        <div className='text-wrapper' role='heading' arial-level={ 1 }>
          <span className='top-line-wrapper'>
            <H1MInverted className='inverted-text success-text' role='presentation'>Your sustainability journey</H1MInverted>
          </span>
          <span className='underlined-text-wrapper block'>
            <H1M className='regular-text success-text' role='presentation'>
              starts
              <span className='underlined-word-wrapper'>
                <H1M className='regular-text underlined-word success-text' role='presentation'> here</H1M>
                <Underline id='form-text-underline' />
              </span>
            </H1M>
          </span>
        </div>
        <img src='https://cdn.karmawallet.io/uploads/_HWw2GX_OY-karmaapp.png' alt='Picture of Karma Wallet mobile app in an iphone.' className='karma-app' />
      </ContentWrapper>
    </LeftRailContainer>
  );  
};

export const LeftRail = observer(LeftRailBase);
