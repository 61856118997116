import { observer } from 'mobx-react';
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { config, useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../contexts/analytics-store';
import { usePromos } from '../../contexts/promos';
import { useSignInModal } from '../../contexts/sign-in';
import { useUserSession } from '../../contexts/user';
import { SignInMode } from '../../models/sign-in';
import { IThemeProps } from '../../styles/themes';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { AsymHamburgerMenuIcon } from '../svgs/icons/HamburgerMenuIcon';
import { XIcon } from '../svgs/icons/XIcon';
import { UserAccountMenu, UserAccountMenuOptions } from '../UserAccountMenu';
import { AltHeaderItemsContainer, AppHeaderContainer, DesktopNavContainer, MobileHeaderNavContainer, MobileNav, MobileNavItemsContainer, MobileUserAccountMenu, MobileUserAccountMenuOverlay, NavWrapper } from './styles';
import { useBanners } from '../../contexts/banners';
import { MarketingBanners } from '../MarketingBanner';
import { useAppData } from '../../contexts/app';
import { useHeader } from '../../contexts/header';
import { useKarmaCardLegal } from '../../contexts/karma-card-legal';
import { AccountHubUserMenu } from '../../pages/Account/AccountHubUserMenu';
import { DesktopNavigationMenu } from './DesktopNavigationMenu';
import MobileNavigationMenu from './MobileNavigationMenu';
import { SubMenuSelected } from '../../models/header';

interface IProps extends IThemeProps {
  className?: string;
}

const AppHeaderBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const location = useLocation();
  const signInModal = useSignInModal();
  const promosContext = usePromos();
  const bannersStore = useBanners();
  const karmaCardLegalStore = useKarmaCardLegal();
  const headerModel = useHeader();
  const userSession = useUserSession();
  const analytics = useAnalytics();
  const appData = useAppData();
  const mobileNavEngaged = useRef(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const mobileUserAccountMenuEngaged = useRef(false);
  const [mobileUserAccountMenuIsOpen, setMobileUserAccountMenuIsOpen] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  const baseFrom = {
    height: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
  };
  
  const baseTo = {
    paddingTop: '0px',
    paddingBottom: '0px',
  };
  
  const mobileNavFrom = { ...baseFrom };
  
  const mobileUserAccountMenuFrom = { ...baseFrom };

  const windowHeight = window.innerHeight;

  const mobileUserAccountMenuTo = {
    ...baseTo,
    height: (windowHeight - 75) + 'px',
  };

  useLayoutEffect(() => {
    if (!!ref.current) headerModel.setHeaderHeight(ref.current?.clientHeight);
  }, [ref.current, bannersStore.showBanner, bannersStore.activeBanner, appData, headerModel.initialized, bannersStore.busy]);

  const loadPromos = useCallback(async() => {
    try {
      await promosContext.loadPromos(true);
    } catch (err: any) {
      analytics.fireEvent('LoadPromos_Failed');
    }
  }, []);

  const loadBanners = useCallback((refresh?: boolean) => async () => {
    try {
      await bannersStore.banners[refresh ? 'refresh' : 'loadMore']();
    } catch (err: any) {
      analytics.fireEvent('LoadBanners_Failed');
    }
  }, []);

  const loadKarmaCardLegal = useCallback(async () => {
    try {
      await karmaCardLegalStore.getLegalText();
    } catch (err: any) {
      analytics.fireEvent('LoadKarmaCardLegal_Failed');
    }
  }, []);

  useEffect(() => {
    loadBanners(true)();
    loadPromos();
    loadKarmaCardLegal();
  }, []);

  useEffect(() => {
    if (userSession.authenticating || !userSession.initialized) return;
    const groupCode = searchParams.get('groupCode');
    // only open these modals if they are not logged in
    const signin = searchParams.get('signin');
    // to request an account (before verification email sent)
    const createaccount = searchParams.get('createaccount');
    // create password after receiving password reset email
    const createpassword = searchParams.get('createpassword');
    // to request a password reset
    const resetpassword = searchParams.get('resetpassword');
    // finish account creation process after requesting an account
    const verifyaccount = searchParams.get('verifyaccount');
    // enter new email after requesting an email change and verifying the old email
    const verifyEmailChange = searchParams.get('verifyEmailChange');
    // complete email change after confirming control of old and new email
    const affirmEmailChange = searchParams.get('affirmEmailChange');

    if (!userSession.isLoggedIn) {
      if (signin === 'true') signInModal.open(SignInMode.SIGN_IN);
      if (createaccount === 'true') {
        analytics.fireEvent('CreateAccount_Auto_Open');
        signInModal.open(SignInMode.CREATE_ACCOUNT);
      }
      if (resetpassword === 'true') {
        signInModal.open(SignInMode.RESET_PASSWORD);
      } 
      if (!!createpassword) {
        signInModal.open(SignInMode.CREATE_PASSWORD);
      } 
    }
  
    if (!!verifyaccount) {
      signInModal.open(SignInMode.ACCOUNT_COMPLETION);
    } 

    if (!!affirmEmailChange) {
      signInModal.open(SignInMode.CHANGE_EMAIL_COMPLETION);
    } 

    if (!!verifyEmailChange) {
      signInModal.open(SignInMode.CHANGE_EMAIL);
    } 

    if (userSession?.initialized && !!groupCode) {
      if (userSession?.isLoggedIn && window.location.pathname !== '/apply') navigate(`/account?groupCode=${groupCode}`);
      if (!userSession?.isLoggedIn && !!signInModal && window.location.pathname !== '/apply') {
        signInModal.open(SignInMode.CREATE_ACCOUNT);
      } 
    }
  }, [location.search, userSession.isLoggedIn, userSession.initialized, userSession.authenticating, userSession]);

  const mobileNavSpring = useSpring({
    config: config.gentle,
    from: mobileNavIsOpen || !mobileNavEngaged.current ? mobileNavFrom : { ...baseTo, height: `${window.innerHeight - headerModel.headerHeight}px` },
    to: mobileNavIsOpen ? { ...baseTo, height: `${window.innerHeight - headerModel.headerHeight}px` } : mobileNavFrom,
  });

  const mobileUserAccountMenuOverlaySpring = useSpring({
    config: config.gentle,
    from: mobileUserAccountMenuIsOpen || !mobileUserAccountMenuEngaged.current ? mobileNavFrom : { ...baseTo, height: `${window.innerHeight - 75}px` },
    to: mobileUserAccountMenuIsOpen ? { ...baseTo, height: `${window.innerHeight - 75}px` } : mobileNavFrom,
  });

  const mobileUserAccountMenuSpring = useSpring({
    config: config.gentle,
    from: mobileUserAccountMenuIsOpen || !mobileUserAccountMenuEngaged.current ? mobileUserAccountMenuFrom : mobileUserAccountMenuTo,
    to: mobileUserAccountMenuIsOpen ? mobileUserAccountMenuTo : mobileUserAccountMenuFrom,
  });

  useEffect(() => {
    // prevent scrolling when this modal is open
    document.body.classList[mobileNavIsOpen || mobileUserAccountMenuIsOpen ? 'add' : 'remove']('no-scroll');
  }, [mobileNavIsOpen, mobileUserAccountMenuIsOpen]);

  const onAltButtonClick = useCallback((mode: SignInMode) => () => {
    setMobileNavIsOpen(false);
    signInModal.open(mode);
    analytics.fireEvent(`AppNavAltBtnClick_${mode}`);
  }, []);

  const onHamburgerClick = useCallback(() => {
    mobileNavEngaged.current = true;
    const isOpen = !mobileNavIsOpen;
    headerModel.setSubMenuSelected(SubMenuSelected.None);
    setMobileNavIsOpen(isOpen);
    setMobileUserAccountMenuIsOpen(false);
    analytics.fireEvent(`MobileNavHamburgerClicked_${isOpen ? 'open' : 'close'}`);
  }, [mobileNavIsOpen, headerModel.subMenuSelected]);

  const onMobileAvatarClick = useCallback(() => {
    mobileUserAccountMenuEngaged.current = true;
    const isOpen = !mobileUserAccountMenuIsOpen;
    headerModel.setSubMenuSelected(SubMenuSelected.None);
    setMobileUserAccountMenuIsOpen(isOpen);
    setMobileNavIsOpen(false);
    analytics.fireEvent(`MobileUserAccountMenuClicked_${isOpen ? 'open' : 'close'}`);
  }, [mobileUserAccountMenuIsOpen]);

  const onMobileUserAccountMenuOverlayClick = useCallback(() => {
    setMobileUserAccountMenuIsOpen(false);
    analytics.fireEvent('MobileUserAccountMenuOverlayClicked');
  }, []);

  const onUserAccountMenuOptionClick = useCallback(() => {
    setMobileUserAccountMenuIsOpen(false);
  }, []);

  const renderUserMenu = (altHeaderItemsClassName = '') => {
    if ((altHeaderItemsClassName === 'mobile-nav-alt-buttons' && userSession.isLoggedIn) || !!userSession.authenticating) return null;

    let content: JSX.Element;

    if (userSession.isLoggedIn) {
      content = <UserAccountMenu key='user-account-menu' setMobileNavIsOpen={ setMobileNavIsOpen } />;
    } else {
      content = (
        <Fragment key='alt-buttons'>
          <Button
            id='signin-button'
            kind={ appData.isDesktop ? ButtonKind.Blank : ButtonKind.PrimaryGhost }
            onClick={ onAltButtonClick(SignInMode.SIGN_IN) }
          >
            Sign In
          </Button>
          {
            appData.isDesktop && ( 
              <Button
                id='create-account-button'
                kind={ ButtonKind.Primary }
                onClick={ onAltButtonClick(SignInMode.CREATE_ACCOUNT) }
              >
                Karma Score
              </Button>
            )
          }
        </Fragment>
      );
    }

    return (
      <AltHeaderItemsContainer
        className={ `${altHeaderItemsClassName} ${userSession.isLoggedIn ? 'small' : ''}` }
      >
        { content }
      </AltHeaderItemsContainer>
    );
  };

  const renderMobileNav = () => {
    const content: JSX.Element[] = [];

    if (userSession.isLoggedIn) {
      content.push((
        <AccountHubUserMenu
          user={ userSession }
          isOpen={ mobileUserAccountMenuIsOpen }
          key='mobile-avatar'
          className={ `mobile-nav-item mobile-avatar ${mobileUserAccountMenuIsOpen ? 'is-open' : ''}` }
          onClick={ onMobileAvatarClick }
        />
      ));
    }

    if (!userSession.isLoggedIn) {
      content.push((
        <Button
          id='membership-button'
          kind={ ButtonKind.Primary }
          key={ 'membership-button' }
          onClick={ onAltButtonClick(SignInMode.CREATE_ACCOUNT) }
        >
          Karma Score
        </Button>
      ));

      content.push((
        <Button
          key='nav-item-hamburger'
          className={ `mobile-nav-item ${mobileNavIsOpen ? 'is-open' : ''}` }
          kind={ ButtonKind.Blank }
          onClick={ onHamburgerClick }
        >
          {
            mobileNavIsOpen
              ? <XIcon stroke={ theme.colors.primary } />
              : <AsymHamburgerMenuIcon fill={ theme.colors.primary } />
          }
        </Button>
      ));
    }

    const mobileNav = (
      <MobileNav
        className={ `mobile-nav ${mobileNavIsOpen ? 'is-open' : ''}` }
        style={ mobileNavSpring }
        offset={ headerModel.headerHeight }
      >
        <div className='mobile-nav-body'>
          <MobileNavigationMenu setMobileNavOpen={ setMobileNavIsOpen } setMobileUserAccountMenuIsOpen={ setMobileUserAccountMenuIsOpen } />
          { renderUserMenu('mobile-nav-alt-buttons') }
        </div>
      </MobileNav>
    );

    const mobileUserAccountMenu = (
      <>
        <MobileUserAccountMenuOverlay
          onClick={ onMobileUserAccountMenuOverlayClick }
          style={ mobileUserAccountMenuOverlaySpring }
        />
        <MobileUserAccountMenu
          className={ mobileNavIsOpen ? 'is-open' : '' }
          style={ mobileUserAccountMenuSpring }
          offset={ headerModel.headerHeight }
        >
          <UserAccountMenuOptions
            className='user-account-menu-options'
            onClick={ onUserAccountMenuOptionClick }
            setMobileNavIsOpen={ setMobileNavIsOpen }
            setMobileUserAccountMenuIsOpen={ setMobileUserAccountMenuIsOpen }
          />
        </MobileUserAccountMenu>
      </>
    );

    return (
      <MobileNavItemsContainer>
        { content }
        { mobileNav }
        { mobileUserAccountMenu }
      </MobileNavItemsContainer>
    );
  };

  // returning null here triggered the layout effect to run the first time and the ref was still null
  // we need to watch the two variables used to determine if null is returned in the layout effect dependency to make this work
  if (!headerModel.initialized || bannersStore.busy) return null;

  return (
    <AppHeaderContainer className={ className } id='app-header' ref={ ref }>
      <MarketingBanners />
      <NavWrapper
        className={ `${className} ${!!bannersStore.activeBanner && bannersStore.showBanner ? 'has-marketing-banners' : ''}` }
        app='karma'
        navClassName='global-app-header'
      >
        <DesktopNavContainer>
          <DesktopNavigationMenu setMobileNavOpen={ setMobileNavIsOpen } />
          { renderUserMenu() }
        </DesktopNavContainer>
        <MobileHeaderNavContainer>
          { renderMobileNav() }
        </MobileHeaderNavContainer>
      </NavWrapper>
    </AppHeaderContainer>
  );
};

const AppHeaderAsObserver = observer(AppHeaderBase);
export const AppHeader = withTheme(AppHeaderAsObserver);
