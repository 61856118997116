import React, { useEffect, useMemo } from 'react';
import Persona from 'persona';
import { useAnalytics } from '../../../contexts/analytics-store';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { PersonaModalContainer } from './styles';
import { Field, InquiryAttributes } from 'persona/dist/lib/interfaces';
import { PersonaTemplateIdEnumValues, PersonaEnvIdEnum, PersonaTemplateIdEnum } from '../../../constants';

export interface IPersonaModalProps {
  isOpen: boolean;
  accountId?: string;
  fields?: any;
  inquiryId?: string;
  onCancel?(): void;
  onComplete?(data: { inquiryId: string; status: string; fields: Record<string, Field> | InquiryAttributes }): void;
  referenceId?: string;
  sessionToken?: string;
  templateId: PersonaTemplateIdEnumValues;
  className: string;
}

const GenericErrorMessageTitle = 'Error loading Persona modal';
const GenericErrorMessage = 'Please try again or contact support@karmawallet.io.';

export const PersonaModal: React.FC<IPersonaModalProps> = ({
  accountId,
  fields,
  isOpen,
  inquiryId,
  onCancel,
  onComplete,
  sessionToken,
  templateId,
  className,
}) => {
  const analytics = useAnalytics();
  const errorMessages = useErrorMessages();

  const environmentId = useMemo(() => {
    if (window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')) {
      return PersonaEnvIdEnum.staging;
    }
    return PersonaEnvIdEnum.production;
  }, []);

  const onReady = async (): Promise<void> => {
    try {
      const isReady = !!client && !client.isOpen;
      if (!isReady) return null;
      await client.open();
    } catch (err: any) {
      analytics.fireEvent(`Persona_Open_Details_Error${templateName ? '_' + templateName : ''}`);
      errorMessages.push({
        title: GenericErrorMessageTitle,
        message: GenericErrorMessage,
      });
    }
  };

  const templateName = useMemo(() => {
    let currentTemplate = '';
    Object.entries(PersonaTemplateIdEnum).forEach(([key, value]) => {
      if (value === templateId) {
        currentTemplate = key;
      }
    });
    return currentTemplate;
  }, [templateId]);

  const cleanUpPersonaWidgetOverlay = () => {
    const overlays = document.getElementsByClassName('persona-widget__overlay');
    const numOverlays = overlays?.length;
    for (let i = numOverlays - 1; i >= 0; i--) {
      overlays[i].remove();
    }
  };

  useEffect(() => cleanUpPersonaWidgetOverlay, []);

  useEffect(() => {
    if (!isOpen) {
      cleanUpPersonaWidgetOverlay();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const hasTemplateIdOrSessionToken = !!templateId || (!!sessionToken && !!inquiryId);
  if (!fields?.applicationData.email || !hasTemplateIdOrSessionToken) return null;

  const client: any = new Persona.Client({
    environmentId,
    accountId,
    fields,
    inquiryId: !!inquiryId && inquiryId,
    sessionToken: !!sessionToken && sessionToken,
    templateId: !!templateId && templateId,
    onComplete,
    onCancel,
    onReady,
  });

  return <PersonaModalContainer className={ className } />;
};
