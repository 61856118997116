import React from 'react';
import { PayMembershipBannerContainer } from './styles';
import { ButtonLink } from '../../Button';
import { ButtonKind } from '../../Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';

export const PayMembershipBanner: React.FC = () => {
  const analytics = useAnalytics();

  const onPayMembershipClick = () => {
    analytics.fireEvent('PayMembershipBanner_Click');
  };

  return (
    <PayMembershipBannerContainer key='pay-membership-banner'>
      <span>
        To finish joining the Karma Wallet community, please pay your membership fee.
        <ButtonLink
          className='pay-membership-cta'
          kind={ ButtonKind.Blank }
          onClick={ onPayMembershipClick }
          href='/apply'
        >
          Pay Now
        </ButtonLink>
      </span>
     
    </PayMembershipBannerContainer>
  );
}; 
