import styled from 'styled-components';

export const CarouselContainer = styled.div`
  .slick-next,
  .slick-prev {
    &:before {
      display: none;
    }

    .next,
    .previous {
      display: flex !important; // important required here to override inline style applied by react-slick
    }
  }

  .slick-next {
    left: 100%;
    right: unset;
  }

  .slick-prev {
    left: unset;
    right: 100%;
  }

  .slick-dots {
    position: static;
    padding-top 30px;

    li {
      margin: 0 5px;

      button {
        width: 15px;
        height: 15px;
        padding: 0px;
        border: none;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.lightGray1};
        outline: none;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;
