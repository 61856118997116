import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { GroupLogoSkeleton } from '../GroupLogo/skeleton';
import { GroupHeroSkeletonContainer, GroupHeroInner } from './styles';

interface IProps {
  className?: string;
}

export const GroupHeroSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <GroupHeroSkeletonContainer className={ className }>
    <GroupHeroInner>
      <GroupLogoSkeleton className='group-logo group-logo-skeleton' />
      <Skeleton className='group-name group-name-skeleton' />
    </GroupHeroInner>
  </GroupHeroSkeletonContainer>
);
