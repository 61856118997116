import styled from 'styled-components';

export const SectionHeaderContainer = styled.div`
  text-align: center;
  max-width: 850px;
  margin: 0 auto 30px;

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    max-width: 780px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 40px;
  }
`;

export const SubheaderText = styled.p`
  margin-top: 20px;

  &.subheader-text {
    font-weight: 400;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    font-size: 20px;

    div, a {
      font-size: 20px;
    }
  }
`;
