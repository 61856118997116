import { observer } from 'mobx-react';
import React, { useEffect, useCallback, useState } from 'react';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { IThemeProps } from '../../styles/themes';
import { CircleChart } from '../CircleChart';
import { GroupMemberDataContainer, MembersChartChildrenContainer, OffsetChildrenContainer, CircleWrapperContainer } from './styles';
import { H2, H3 } from '../../styles/components/header';
import { GroupMemberDataSkeleton } from './skeleton';
import { GroupModel } from '../../models/group';
import { formatter } from '../../lib/misc';

const enum OffsetType {
  Member = 'member',
  Group = 'group'
}
interface IProps extends IThemeProps {
  className?: string;
  group: GroupModel;
}

interface IOffsetData {
  dollarAmount: number;
  tonnes: number;
  type: OffsetType;
}

const GroupMemberDataBase: React.FC<IProps> = ({
  className = '',
  group,
  theme,
}) => {
  const errorMessages = useErrorMessages();
  const [members, setMembers] = useState(0);
  const [membersWithDonations, setMembersWithDonations] = useState(0);

  useEffect(() => {
    if (!!group) {
      group.loadMembersOffsetData()
        .catch(err => {
          errorMessages.push({
            title: 'Error Loading Member Offsets Data',
            message: err.message,
          });
        });
    }
  }, [group]);

  useEffect(() => {
    if (!!group?.membersOffsetData) {
      setMembers(group.membersOffsetData.members);
      setMembersWithDonations(group.membersOffsetData.membersWithDonations);
    }
  }, [group?.membersOffsetData]);

  const renderMembersChartChildren = useCallback(() => (
    <MembersChartChildrenContainer className='circle-child-wrapper'>
      <div className='header'>{ members }</div>
      <div className='sub-text'>{ membersWithDonations } of { members } donated</div>
    </MembersChartChildrenContainer>
  ), [members, membersWithDonations]);

  const renderOffsetChild = (offsetData: IOffsetData) => {
    const { dollarAmount, tonnes, type } = offsetData;

    if (offsetData.dollarAmount > 0) {
      return (
        <OffsetChildrenContainer className='no-data circle-child-wrapper'>
          <H2 className='amount'>{ formatter.format(dollarAmount) }</H2>
          <div>is equal to</div>
          <H2 className='tonnes'>{ tonnes.toFixed(2) }</H2>
          <div>Tonnes of Carbon</div>
        </OffsetChildrenContainer>
      );
    }

    return (
      <OffsetChildrenContainer className='no-data circle-child-wrapper'>
        <H3>{ type === OffsetType.Member ? '$0' : 'No Member' }</H3>
        <div>{ type === OffsetType.Member ? 'Offset Donations' : 'Donations Matched' }</div>
      </OffsetChildrenContainer>
    );
  };

  const renderCircleLabel = (offsetData: IOffsetData) => {
    const { dollarAmount, type } = offsetData;

    if (type === 'group') {
      return (
        <div className='circle-label-text'>
          <H3>Group</H3>
          <div>Donations Matched</div>
        </div>
      );
    } else {
      return dollarAmount === 0
        ? (
          <div className='circle-label-text'>
            <H3>0 Members</H3>
          </div>
        )
        : (
          <div className='circle-label-text'>
            <H3>Member</H3>
            <div>Offset Donations</div>
          </div>
        );
    }
  };

  const renderDataCharts = () => {
    const membersChart = (
      <CircleWrapperContainer key='member-count-chart'>
        <CircleChart
          colors={ [theme.colors.primary, theme.colors.lightGray1] }
          data={ [
            { value: membersWithDonations },
            { value: (members - membersWithDonations) },
          ] }
          size={ 280 }
        >
          { renderMembersChartChildren() }
        </CircleChart>
        <div className='circle-label-text'>
          <H3>{ members } Joined</H3>
          <div>
            Group Created on
            <br />
            { group.createdOn.format('MMMM DD, YYYY') }
          </div>
        </div>
      </CircleWrapperContainer>
    );

    const memberDonationsChart = (
      <CircleWrapperContainer key='member-donations-chart'>
        <CircleChart
          animate={ false }
          colors={ [theme.colors.darkGray3, theme.colors.darkGray3] }
          data={ [
            { value: 0 },
            { value: 1 },
          ] }
          fill={ theme.colors.lightGreen2 }
          size={ 280 }
        >
          { 
            renderOffsetChild({
              dollarAmount: group.membersOffsetData?.memberDonations?.dollars,
              tonnes: group.membersOffsetData?.memberDonations?.tonnes,
              type: OffsetType.Member,
            }) 
          }
        </CircleChart>
        { 
          renderCircleLabel({
            dollarAmount: group.membersOffsetData?.memberDonations?.dollars,
            type: OffsetType.Member, 
            tonnes: group.membersOffsetData?.memberDonations?.tonnes,
          }) 
        }
      </CircleWrapperContainer>
    );

    const groupDonationsChart = (
      <CircleWrapperContainer key='group-donations-chart'>
        <CircleChart
          animate={ false }
          colors={ [theme.colors.darkGray3, theme.colors.darkGray3] }
          data={ [
            { value: 0 },
            { value: 1 },
          ] }
          fill={ theme.colors.lightGreen2 }
          size={ 280 }
        >
          { 
            renderOffsetChild({
              dollarAmount: group.membersOffsetData?.groupDonations?.dollars,
              tonnes: group.membersOffsetData?.groupDonations?.tonnes,
              type: OffsetType.Group,
            }) 
          }
        </CircleChart>
        { 
          renderCircleLabel({
            dollarAmount: group.membersOffsetData?.groupDonations?.dollars,
            type: OffsetType.Group, 
            tonnes: group.membersOffsetData?.groupDonations?.tonnes,
          }) 
        }
      </CircleWrapperContainer>
    );

    return [membersChart, memberDonationsChart, groupDonationsChart];
  };

  if (!group) return null;
  if (group.loadingMembersOffsetData) return <GroupMemberDataSkeleton />;

  return (
    <GroupMemberDataContainer className={ className }>
      { renderDataCharts() }
    </GroupMemberDataContainer>
  );
};

const GroupMemberDataAsObserver = observer(GroupMemberDataBase);
export const GroupMemberData = withTheme(GroupMemberDataAsObserver);
