import styled from 'styled-components';

export const DeclinedPageContainer = styled.div`
  text-align: center;

  .status-text {
    margin-bottom: 40px;
  }
`;

export const AcceptedDocumentsContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary}1A;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  padding: 16px 8px;

  ul {
    text-align: left;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const InfoContainer = styled.div`
  p {
    font-size: 12px;
    font-style: italic;
  }
`;
