import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Smartphone: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}smartphone-graphic` }
    id={ id }
    height={ 88 }
    width={ 51 }
  >
    <rect x='1' y='1' width='48.16' height='86' rx='10' fill='#fff' stroke='#323232' strokeWidth='2' />
    <path d='M20.7798 7.02002H29.3798' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
    <circle cx='25.0798' cy='77.54' r='4.3' fill='#fff' stroke='#323232' strokeWidth='2' />
    <rect x='1' y='13.04' width='48.16' height='55.04' fill='#64949B' stroke='#323232' strokeWidth='2' />
    <line x1='23.1468' y1='28.3864' x2='18.7784' y2='36.8804' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
    <line x1='17.0644' y1='40.1009' x2='17.3718' y2='39.4861' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
    <path d='M23.9791 35.3823L16.5763 49.9506' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
  </SvgIcon>
);
