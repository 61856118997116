import React, { useCallback, useMemo, useState } from 'react';
import { CompanyModel } from '../../models/companies';
import { ButtonKind } from '../Button/styles';
import { CompanyLogo } from '../CompanyLogo';
import { CompanyCardContainer, GraphicContainer, OwnedBy, BottomSection, TopSection, BottomWrapper, TextContainer, CompareCheckbox, CompareSelectButton } from './styles';
import { PillTag } from '../PillTag';
import { DollarIcon } from '../svgs/icons/DollarIcon';
import { theme } from '../../styles/themes';
import { PillTagKind, PillTagSize } from '../PillTag/styles';
import { CompanyRatingTag } from '../CompanyRatingTag';
import { CompareCompaniesModel } from '../../models/compare-companies';

export interface ICompareClickProps {
  companyId: string;
  companyName: string;
  companyLogo: string;
}

interface IProps {
  className?: string;
  company: CompanyModel;
  label?: string;
  onClick?(): void;
  onCompareClick?(props: ICompareClickProps): void;
  compareCompaniesModel?: CompareCompaniesModel
  compareVisible?: boolean;
}

enum ContentClass {
  Rating = 'rating',
  Score = 'score',
}

enum Header {
  Rating = 'RATING',
  Score = 'SCORE',
  UnSdgs = 'UN SDGS',
}

interface CompanyStats {
  header: Header;
  content: any;
  contentClass: ContentClass;
}

export const CompanyBadge: React.FC<IProps> = ({
  className = '',
  company,
  label,
  onClick,
  onCompareClick,
  compareCompaniesModel,
  compareVisible = false,
}) => {
  const [checked, setChecked] = useState(false);
  const hasBcorp = false; //needs to be updated once Bcorp certification is added to the company document

  const onCheck = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    if (compareCompaniesModel?.selectedCompanies?.length === 3 && !checked && !compareCompaniesModel?.selectedCompanies?.find((selectedCompany) => selectedCompany?._id === company?._id)) {
      return;
    } 
    setChecked(!checked);

    const compareClickProps = {
      companyId: company._id,
      companyName: company.companyName,
      companyLogo: company.logo,
    };

    onCompareClick(compareClickProps);
  }, [checked, compareCompaniesModel?.selectedCompanies?.length, company._id, company.companyName, company.logo]);

  const renderTopSection = useMemo(() => {
    const cashback = !!company.hasCashback ? 
      <PillTag kind={ PillTagKind.CashbackGreen } size={ PillTagSize.Small }>
        <DollarIcon fill={ theme.colors.dark } />
        <div>Cashback up to { company.maxRate }</div>
      </PillTag>
      : <div className='top-space' />;

    const compareCheckboxChecked = !!compareCompaniesModel?.selectedCompanies?.find((selectedCompany) => selectedCompany._id === company._id);
    const showCompareCheckbox = !!compareVisible && !(compareCompaniesModel?.selectedCompanies?.length === 3 && !compareCheckboxChecked);

    return (
      <TopSection className={ !!company.hasCashback && hasBcorp ? 'two-children' : 'one-child' }>
        { cashback }
        {
          !!showCompareCheckbox ? 
            <CompareSelectButton
              onClick={ onCheck }
            >
              <CompareCheckbox
                checked={ compareCheckboxChecked }
                label='Compare'
                checkColor={ theme.colors.dark }
                readOnly
              />
            </CompareSelectButton> : null
        }
      </TopSection>
    );
  }, [company, hasBcorp, checked, compareCompaniesModel?.selectedCompanies, compareVisible, company.hasCashback]);

  const renderGraphicSection = useMemo(() => (
    <GraphicContainer>
      <CompanyLogo className='company-logo' company={ company } />
      <TextContainer>
        <div className='company-name'>{ company.companyName }</div>
        {
          !!company.parentCompany && !company.parentCompany.hidden.status && (
            <OwnedBy>
              { `Owned by ${company.parentCompany.companyName}` }
            </OwnedBy>
          )
        }
      </TextContainer>
    </GraphicContainer>
  ), [company]);

  const companyStatsList: CompanyStats[] = [
    {
      header: Header.Rating,
      content: <CompanyRatingTag rating={ company.rating } className='rating' />,
      contentClass: ContentClass.Rating,
    },
    {
      header: Header.Score,
      content: company.combinedScore,
      contentClass: ContentClass.Score,
    },
    {
      header: Header.UnSdgs,
      content: company.evaluatedUnsdgsCount,
      contentClass: ContentClass.Score,
    },
  ];

  const renderCompanyStats = useMemo(() => (
    <BottomSection color={ company.rating }>
      { 
        companyStatsList.map((item, index) => (
          <div className='bottom-item-container' key={ index }>
            <div className='header'>{ item.header }</div>
            {
              item.header === Header.Rating ?
                item.content :
                <div className={ item.contentClass }>
                  { item.content }
                  { item.header === Header.UnSdgs ? <div className='unsdg'>/16</div> : null }
                </div>
            }
          </div>
        ))
      }
    </BottomSection>
  ), [company]);
  
  return (
    <CompanyCardContainer
      className={ className }
      href={ `/company/${company._id}/${company.slug}` }
      kind={ ButtonKind.Blank }
      label={ label }
      onClick={ onClick }
    >
      { renderTopSection }
      <BottomWrapper className='bottom-wrapper'>
        { renderGraphicSection }
        { renderCompanyStats }
      </BottomWrapper>
    </CompanyCardContainer>
  );
};
