import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { action, observable, makeObservable } from 'mobx';
import { AppType } from '../constants';

declare const __KARMA_ENV__: string;
declare const __REACT_APP_API_URL__: string;
declare const __REACT_APP_API_PORT__: string;
declare const __REACT_APP_AUTH__: string;

type PrivateFields = '_apiBaseUrl' | '_authKey' | '_client' | '_headers';

export interface IRequestConfig {
  data?: any;
  method: 'get' | 'GET' | 'post' | 'POST' | 'delete' | 'DELETE' | 'put' | 'PUT' | 'patch' | 'PATCH';
  queryParams?: string | { [key: string]: any };
  path: string;
  headers?: { [key: string]: any };
  preventAutoQueryGeneration?: boolean;
}

export interface IPaginatedResponse<T> {
  docs: T[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number;
  nextPage: number;
}

interface IResponse<T> {
  success: boolean;
  value?: T;
  error?: string;
}

export class WebServiceHelper {
  private _app: AppType;
  private _apiBaseUrl: string = null;
  private _basePath: string = null;
  private _authKey = '';
  private _client: AxiosInstance = null;
  private _headers: { [key: string]: any } = {};

  constructor(basePath = '', app?: AppType) {
    makeObservable<WebServiceHelper, PrivateFields>(this, {
      _apiBaseUrl: observable,
      _authKey: observable,
      _client: observable.ref,
      _headers: observable,
      initClient: action,
      sendRequest: action,
    });
    
    this._app = app;
    this._basePath = basePath;
    this._apiBaseUrl = `${__KARMA_ENV__}` === 'local'
      ? `${__REACT_APP_API_URL__}:${__REACT_APP_API_PORT__}`
      : `${__REACT_APP_API_URL__}`;

    this.initClient();
  }

  private _constructQuery = (params: string | { [key: string]: any }, preventAutoQueryGeneration?: boolean) => {
    if (preventAutoQueryGeneration) {
      if (typeof params !== 'string') throw new Error('When using parameter: `preventAutoQueryGeneration`, parameter `queryParams` must be of type string.');
      return params;
    } else {
      if (typeof params === 'string') throw new Error('Parameter `queryParams` must be an object of type { [key: string]: any }');
    }

    const query: string[] = [];

    Object.entries(params).forEach(([key, value]) => {
      if (!!value) {
        query.push(`${key}=${value}`);
      }
    });

    return query.length > 0
      ? `?${query.join('&')}`
      : '';
  };

  initClient = () => {
    const headers: AxiosRequestHeaders = {
      Authorization: `${__REACT_APP_AUTH__}`,
      // 'Access-Control-Allow-Origin': '*',
    };

    this._client = axios.create({
      headers,
      baseURL: this._apiBaseUrl,      
      withCredentials: true,
    });
    
    this._client.interceptors.request.use((config: any) => {
      const updatedConfig = { ...config };
      return updatedConfig;
    }, (err: Error) => Promise.reject(err));

    this._client.interceptors.response.use(response => response, err => Promise.reject(err));
  };

  sendRequest = async <T>({ data, method, queryParams, path, headers, preventAutoQueryGeneration }: IRequestConfig, ignoreBasePath = false): Promise<IResponse<T>> => {
    if (!this._client) {
      return {
        success: false,
        error: 'web service client not found',
      };
    }

    const query = queryParams ? this._constructQuery(queryParams, preventAutoQueryGeneration) : '';
    const url = `${this._apiBaseUrl}${ignoreBasePath ? '' : this._basePath}${path}${query}`;

    try {
      const response = await this._client[(method.toLowerCase() as 'get' | 'post' | 'delete' | 'put' | 'patch')](url, data, headers || {});

      return {
        success: response?.status >= 200 && response?.status < 300,
        value: response?.data,
      };
    } catch (error: any) {
      if (!!error.response?.data?.message) {
        return {
          success: false,
          error: error.response.data.message,
        };
      } else {
        return {
          success: false,
          error: error.response?.data?.metadata?.message || 'error',
        };
      }
    }
  };

  sendFormDataRequest = async <T>({ data, queryParams, path }: IRequestConfig, ignoreBasePath = false): Promise<IResponse<T>> => {
    if (!this._client) {
      return {
        success: false,
        error: 'web service client not found',
      };
    }

    const query = queryParams ? this._constructQuery(queryParams) : '';
    const url = `${this._apiBaseUrl}${ignoreBasePath ? '' : this._basePath}${path}${query}`;

    try {
      const response = await this._client.post(url, data, { headers: this._headers });

      return {
        success: response?.status >= 200 && response?.status < 300,
        value: response?.data,
      };
    } catch (error: any) {
      if (!!error.response?.data?.message) {
        return {
          success: false,
          error: error.response.data.message,
        };
      } else {
        return {
          success: false,
          error: error.response?.data?.metadata?.message || 'error',
        };
      }
    }
  };
}
