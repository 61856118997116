import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';

export const AppLandingPageContainer = styled(MainContainer)``;

export const DisclaimerContainer = styled.div`
  margin: 28px auto;
  max-width: 717px;
  text-align: left;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 88px;
  }
`;
