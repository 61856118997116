import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CardModel, ICard } from './cards';
import { CollectionModel, ICollectionConfig } from './collection';
import { CompanyModel, ICompany } from './companies';
import { ISector } from './sectors';
import { IValue } from './values';

dayjs.extend(utc);

type PrivateTransactionFields = '_busy' |
'_card' |
'_company' |
'_init' |
'_transaction';

type PrivateTransactionsFields = '_busy' |
'_config' |
'_options' |
'_mostRecentTransactions' |
'_loadingMostRecentTransactions' |
'_transactions';

interface ITransactionsOptions {
  url?: string;
}

export enum MatchTypes {
  Offset = 'offset',
}

export interface IUserOrGroup {
  user: string;
  group: string;
}

export interface ITransactionMatch {
  status: boolean;
  amount: number;
  matcher: IUserOrGroup;
  date: Date;
}

export interface IPlaidTransactionLocation {
  address: string;
  city: string;
  country: string;
  lat: number;
  lon: number;
  postal_code: string;
  region: string;
  store_number: string;
}

export interface IPlaidTransactionMeta {
  reference_number: string;
  ppd_id: string;
  payee: string;
  by_order_of: string;
  payer: string;
  payment_method: string;
  payment_processor: string;
  reason: string;
}

export interface IPlaidTransactionFinanceCategory {
  primary: string;
  detailed: string;
}

export interface IPlaidTransactionIntegration {
  account_id?: string;
  account_owner?: string;
  authorized_date?: string;
  authorized_datetime?: string;
  category?: string[];
  category_id?: string;
  check_number?: string;
  iso_currency_code?: string;
  location?: IPlaidTransactionLocation;
  merchant_name?: string;
  name?: string;
  payment_channet?: string;
  payment_meta?: IPlaidTransactionMeta;
  pending?: boolean;
  pending_transaction_id?: string;
  personal_finance_category?: IPlaidTransactionFinanceCategory;
  transaction_code?: string;
  transaction_id?: string;
  transaction_type?: string;
  unofficial_currency_code?: string;
}

export interface IRareTransactionIntegration {
  certificate_url?: string;
  certificateUrl?: string; // added during some rare updates...has to be included now or will break FE
  currency?: string;
  fee_amt?: number,
  offsetsPurchased?: number,
  processed_ts?: string;
  processed?: boolean;
  projectName?: string;
  refunded_ts?: string;
  refunded?: boolean;
  statement_descriptor?: string;
  subtotal_amt?: number,
  tonnes_amt?: number,
  transaction_id?: string;
}

export interface ITransactionIntegrations {
  plaid?: IPlaidTransactionIntegration;
  rare?: IRareTransactionIntegration;
}

export interface ITransaction {
  _id: string;
  amount: number;
  association?: IUserOrGroup;
  card: ICard;
  company: ICompany;
  createdOn: Date;
  date: Date;
  reversed: boolean;
  integrations?: ITransactionIntegrations;
  lastModified: Date;
  matched?: ITransactionMatch;
  matchType: MatchTypes;
  onBehalfOf?: IUserOrGroup;
  sector: ISector;
  user: string;
  values: IValue[];
}

export class TransactionModel extends BaseModel {
  private _busy = false;
  private _card: CardModel = null;
  private _company: CompanyModel = null;
  private _transaction: ITransaction = null;

  constructor (transactionInfo: ITransaction) {
    super();
    makeObservable<TransactionModel, PrivateTransactionFields>(this, {
      _busy: observable,
      _card: observable,
      _company: observable,
      _transaction: observable,
      _id: computed,
      amount: computed,
      association: computed,
      busy: computed,
      card: computed,
      company: computed,
      date: computed,
      reversed: computed,
      integrations: computed,
      matched: computed,
      matchType: computed,
      onBehalfOf: computed,
      sector: computed,
      values: computed,
      _init: action.bound,
    });

    this._transaction = transactionInfo;
    this._init();
  }

  get _id() { return this._transaction._id; }
  get amount() { return this._transaction.amount; }
  get association() { return this._transaction.association; }
  get busy() { return this._busy; }
  get card() { return this._card; }
  get company() { return this._company; }
  get date() { return dayjs(this._transaction.date).local(); }
  get reversed() { return this._transaction.reversed || false; }
  get integrations() { return this._transaction.integrations ?? {}; }
  get matched() { return this._transaction.matched; }
  get matchType() { return this._transaction.matchType; }
  get onBehalfOf() { return this._transaction.onBehalfOf; }
  get sector() { return this._transaction.sector; }
  get values() { return this._transaction.values; }

  private _init = () => {
    this._company = new CompanyModel(this._transaction.company);
    this._card = new CardModel(this._transaction.card);
  };
}

export class TransactionsModel extends BaseModel {
  private _busy = false;
  private _config: ICollectionConfig = {};
  private _options: ITransactionsOptions = {};
  private _transactions: CollectionModel<ITransaction, TransactionModel> = null;
  private _mostRecentTransactions: TransactionModel[] = [];
  private _loadingMostRecentTransactions = false;

  constructor (config?: ICollectionConfig, options?: ITransactionsOptions) {
    super();
    makeObservable<TransactionsModel, PrivateTransactionsFields>(this, {
      _busy: observable,
      _config: observable,
      _options: observable,
      _mostRecentTransactions: observable,
      _loadingMostRecentTransactions: observable,
      _transactions: observable,
      busy: computed,
      mostRecentTransactions: computed,
      loadingMostRecentTransactions: computed,
      loadRecentTransactions: action.bound,
    });

    this._config = config ?? {};
    this._options = options ?? {};
    this._init();
  }

  get busy() { return this._busy; }
  get loadingMostRecentTransactions() { return this._loadingMostRecentTransactions; }
  get mostRecentTransactions() { return this._mostRecentTransactions; }
  get transactions() { return this._transactions; }

  public loadRecentTransactions = async () => {
    if (this._loadingMostRecentTransactions) return;
    this._loadingMostRecentTransactions = true;

    const result = await this.webServiceHelper.sendRequest<TransactionModel[]>({
      path: '/transaction/most-recent',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._mostRecentTransactions = result.value;
        this._loadingMostRecentTransactions = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingMostRecentTransactions = false;
      });

      throw new Error(result.error);
    }
  };

  private _init = () => {
    const url = this._options.url ?? '/transaction';
    this._transactions = new CollectionModel<ITransaction, TransactionModel>(
      url,
      (transactionInfo: ITransaction) => new TransactionModel(transactionInfo),
      this._config,
    );
  };
}
