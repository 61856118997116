import React from 'react';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { H1 } from '../../../styles/components/header';
import { NotFoundContainer, NotFoundContentContainer } from './styles';

interface IProps {
  className?: string;
}

export const NotFound: React.FC<IProps> = ({
  className = '',
}) => (
  <NotFoundContainer className={ className } title={ 'Not Found' }>
    <NotFoundContentContainer>
      <H1>Well this is awkward...</H1>
      <p>{ 'We either couldn\'t find the page you were looking for, or you may not have permission to view the group that was entered.' }</p>
      <ButtonLink
        kind={ ButtonKind.Primary }
        href='/account'
      >
          Take me back to Karma Wallet
      </ButtonLink>
    </NotFoundContentContainer>
  </NotFoundContainer>
);
