import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const XIcon: FC<IProps> = ({ className = '' , stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}x-icon` }
    id={ id }
    width={ 18 }
    height={ 18 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M1.5 1.5L9 9M16.5 16.5L9 9M9 9L16.5 1.5M9 9L1.5 16.5' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
