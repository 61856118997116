import styled from 'styled-components';
import { MaxBoundaryContainer } from '../../styles/components/containers';
import { BasicContainer, FlexCenter, FlexCol, FlexHorizontalCenter, PrimaryFont, TruncateText } from '../../styles/styles';

export const BanOption = styled.div`
  color: ${({ theme }) => theme.colors.red};
`;

export const GroupMembersListBottomRow = styled.div`
  ${ FlexCol }

  .members-list-ctas {
    padding: 0;
    justify-content: center;
  }

  @media (min-width: 575px) {
    ${ FlexHorizontalCenter }
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;

    .members-list-ctas {
      justify-content: flex-end;
    }
  }
`;

export const GroupMembersListContainer = styled(MaxBoundaryContainer)``;

export const GroupMembersListCount = styled.p`
  align-self: flex-start;
  margin: 0;
  padding: 5px 22px;

  @media (min-width: 575px) {
    padding: 15px 22px;
  }
`;

export const GroupMembersListInner = styled.div`
  ${ BasicContainer }
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  position: relative;
  max-height: 500px;
  min-height: 50px;
  padding: 0;
  overflow: auto;

  .loading-spinner {
    position: fixed;
    top: 55%;
    left: 50%;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ::-webkit-scrollbar {
      background: transparent;
      color: transparent;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.lightGray};
      border-radius: 50px;
    }
  }
`;

export const Row = styled.div`
  ${ FlexHorizontalCenter }
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  max-width: 100%;
  padding: 10px 20px;

  & .dt-header-item {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    align-items: center;

    & .m-header-item {
      display: none;
    }

    & .dt-header-item {
      display: block;
      font-weight: 600;
    }
  }
`;

export const ListHeader = styled(Row)`
  background: ${({ theme }) => theme.colors.darkGray2};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: sticky;
  top: 0px;
  z-index: 2;

  & * {
    font-size: 16px;
    color: #fff;
  }

  .checkbox {
    background: #fff;
  }
`;

export const MemberCheckboxContainer = styled.div`
  min-width: 26px;
  max-width: 26px;
`;

export const MemberDataContainer = styled.div`
  ${ FlexHorizontalCenter }
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: calc(100% - 26px);
  padding: 3px 0 0 10px;

  & > * {
    ${ PrimaryFont }
    padding-left: 10px;
  }

  .member-name {
    ${ TruncateText }
    min-width: 100%;
    max-width: 100%;
    font-weight: 600;
  }

  .member-email {
    ${ TruncateText }
    min-width: 100%;
    max-width: 100%;
    padding-bottom: 15px;
    flex-grow: 1;
  }

  .member-role {
    min-width: 50%;
    max-width: 50%;
  }

  .member-status {
    min-width: 50%;
    max-width: 50%;
    text-align: right;
  }

  & > :last-child {
    ${ FlexCenter }
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: 50px;
    max-width: 50px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .member-name {
      ${ TruncateText }
      min-width: 200px;
      max-width: 200px;
    }

    .member-email {
      ${ TruncateText }
      min-width: unset;
      max-width: unset;
      padding-bottom: 0;
      flex-grow: 1;
    }

    .member-role,
    .member-status {
      font-weight: 600;
      min-width: 120px;
      max-width: 120px;
    }

    .member-status {
      text-align: left;
    }

    & > :last-child {
      position: static;
    }
  }
`;

export const MemberItem = styled(Row)`
  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray1}`};
  }

  &.removed > * > *:not(:last-child),
  &.banned > * > *:not(:last-child) {
    opacity: 0.4;
  }

  & > * {
    font-size: 16px;
  }

  &.verified .member-status,
  &.approved .member-status {
    color: ${({ theme }) => theme.colors.green};
  }

  &.removed .member-status,
  &.banned .member-status {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const NoMembers = styled.div`
  ${ FlexCenter }
  height: 75px;
  color: ${({ theme }) => theme.colors.lightGray1};
`;
