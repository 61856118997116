import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { PartnershipSection } from '../../components/PartnershipSection';
import { H1, H3, H5 } from '../../styles/components/header';
import rareLogo from '../../../public/assets/images/rare-logo.png';
import { DonateNowBlock, DonateNowSkeleton, OffsetEmissionsContainer, OffsetEmissionsInnerWrapper, OverviewSection, RareProjectsContainer } from './styles';
import { InfoIcon } from '../../components/svgs/icons/InfoIcon';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { ChevronDirection, ChevronIcon } from '../../components/svgs/icons/ChevronIcon';
import { DonationSection } from '../../components/DonationSection';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { OffsetsModel } from '../../models/offsets';
import { ProjectCardError } from '../../components/RareProjectCard/error';
import { RareProjectCard } from '../../components/RareProjectCard';
import { RareProjectCardSkeletonContainer } from '../../components/RareProjectCard/styles';

interface IProps extends IThemeProps {
  className?: string;
}

const previewInfo = {
  description: 'Donate to a Rare.org project bundle to offset your carbon emissions.',
  title: 'Offset Your Emissions',
};

const OffsetEmissionsBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const errorMessages = useErrorMessages();
  const analytics = useAnalytics();
  const offsetsModel = useRef(new OffsetsModel()).current;
  const donationPicker = useRef<null | HTMLDivElement>(null);
  
  useEffect(() => {
    offsetsModel.loadRareProjects()
      .catch((err) => {
        analytics.fireEvent('OffsetEmissions_ProjectsLoad_Error');
        errorMessages.push({
          title: 'Error Loading Rare Projects',
          message: err.message,
        });
      });
  }, []);

  const onAnchorClick = useCallback(() => {
    analytics.fireEvent('OffsetEmissions_Anchor_Click');
    if (!!donationPicker.current) donationPicker.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const onFAQClick = useCallback(() => {
    analytics.fireEvent('OffsetEmissions_FAQs_Click');
  }, []);

  const onRareClick = useCallback(() => {
    analytics.fireEvent('OffsetEmissions_Rare_Click');
  }, []);

  const overviewSection = useMemo(() => (
    <OverviewSection>
      <div className='top-section'>
        <H3 className='overview-header'>Climate Impact Projects</H3>
        <a
          className='faq-cta'
          target='_blank'
          rel='noreferrer'
          onClick={ onFAQClick }
          href='https://cdn.karmawallet.io/rare/Rare_CarbonOffsetDonations_FAQ.pdf'
        >
          <InfoIcon fill={ theme.colors.darkGray1 } />
          <H5 className='faq-cta-text'>FAQs</H5>
          <ChevronIcon direction={ ChevronDirection.Right } stroke={ theme.colors.darkGreen1 } />
        </a>
      </div>
      <H5 className='text'>In partnership with non-profit,&nbsp;
        <a
          className='rare-link'
          onClick={ onRareClick }
          target='_blank'
          rel='noreferrer'
          href='https://rare.org/'
        >
            Rare.org
        </a>
          , we're providing a way to offset your carbon emissions. Your donation will be spread across high-quality climate impact projects that prevent millions of tonnes of CO2 from entering the atmosphere every year:</H5>
    </OverviewSection>
  ), []);

  const renderDonateNowBlock = useCallback(() => {
    if (offsetsModel.loadingRareProjects) return <DonateNowSkeleton />;
    if (!offsetsModel.loadingRareProjects && offsetsModel.bundleCost === 0) return null;

    return (
      <DonateNowBlock
        label='Go to Donation Section'
        onClick={ onAnchorClick }
      >
        <H5 className='donate-cta-text'>
          <span>Donate now </span>
          to the Climate Impact Project Bundle
        </H5>
        <p className='sub-text'>
          Each Bundle Costs an Average of ${ offsetsModel.bundleCost } Per Tonne
        </p>
      </DonateNowBlock>
    );
  }, [offsetsModel.bundleCost, offsetsModel.loadingRareProjects]);

  const renderRareProjects = useCallback(() => {
    let content: JSX.Element[] = [];

    if (offsetsModel.loadingRareProjects || !offsetsModel.rareProjects.length) {
      for (let i = 0; i < 3; i++) {
        content.push(<RareProjectCardSkeletonContainer key={ `rare-project-card-skel-${i}` } />);
      }
    } 

    if (!offsetsModel.loadingRareProjects && !offsetsModel.rareProjects.length) {
      for (let i = 0; i < 3; i++) {
        content.push(<ProjectCardError key={ `rare-project-card-error-${i}` } />);
      }
    }

    content = [
      ...content,
      ...offsetsModel.rareProjects.map(project => <RareProjectCard key={ project.name } project={ project } />),
    ];

    return (
      <RareProjectsContainer className={ className }>
        { content }
      </RareProjectsContainer>
    );
  }, [offsetsModel.rareProjects, offsetsModel.loadingRareProjects]);

  return (
    <OffsetEmissionsContainer className={ className } title={ previewInfo.title }>
      <OffsetEmissionsInnerWrapper> 
        <H1 className='header'>Offset Emissions</H1>
        <PartnershipSection partnerLogo={ rareLogo } />
        { overviewSection }
        { renderDonateNowBlock() }
        { renderRareProjects() }
        <div ref={ donationPicker }>
          <DonationSection />
        </div>
      </OffsetEmissionsInnerWrapper>
    </OffsetEmissionsContainer>
  );
};

const OffsetEmissionsObserver = observer(OffsetEmissionsBase);
export const OffsetEmissions = withTheme(OffsetEmissionsObserver);
