import React, { Ref } from 'react';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';

import { _BlankArrowButton, ArrowButtonContainer } from './styles';
import { ArrowButtonKind } from '../ArrowButton';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  kind: ArrowButtonKind;
  ref?: Ref<HTMLButtonElement>;
  onClick(): void;
}

export const BlankArrowButton: React.FC<IProps> = ({
  className = '',
  kind,
  onClick,
  ref,
  ...restProps
}) => (
  <ArrowButtonContainer className={ className }>
    <_BlankArrowButton
      { ...restProps }
      className={ `blank-arrow-button ${kind}` }
      onClick={ onClick }
      ref={ ref }
    >
      <ChevronIcon direction={ kind === ArrowButtonKind.Next ? ChevronDirection.Right : ChevronDirection.Left } />
    </_BlankArrowButton>
  </ArrowButtonContainer>
);
