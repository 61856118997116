import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const HomeEnergy: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}home-energy-graphic` }
    id={ id }
    height={ 98 }
    width={ 93 }
  >
    <path d='M91.2881 42.563L46.2717 2L1.25537 42.563C1.1395 42.6976 1.058 42.8738 1.0215 43.0685C0.984994 43.2632 0.995146 43.4674 1.05067 43.6547C1.1062 43.8419 1.20451 44.0034 1.33281 44.1181C1.4611 44.2329 1.61345 44.2957 1.77 44.2982H10.02V96.9203H82.539V44.2982H90.7968C90.9514 44.2899 91.1006 44.223 91.2254 44.1061C91.3501 43.9891 91.4449 43.8274 91.4975 43.6413C91.5502 43.4553 91.5584 43.2534 91.5211 43.0614C91.4838 42.8694 91.4027 42.6959 91.2881 42.563Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M56.0783 42.5752C55.6006 42.2981 54.9887 42.4607 54.7115 42.9384C54.4344 43.4161 54.597 44.028 55.0747 44.3052L56.0783 42.5752ZM46.4969 77.3481V76.3481H46.4963L46.4969 77.3481ZM43.2296 76.0367C42.6874 75.932 42.1629 76.2867 42.0582 76.8289C41.9535 77.3712 42.3082 77.8957 42.8505 78.0004L43.2296 76.0367ZM55.0747 44.3052C60.1966 47.2765 63.6393 52.824 63.6393 59.1764H65.6393C65.6393 52.0833 61.7926 45.8902 56.0783 42.5752L55.0747 44.3052ZM63.6393 59.1764C63.6393 68.6608 55.962 76.3481 46.4969 76.3481V78.3481C57.0697 78.3481 65.6393 69.7623 65.6393 59.1764H63.6393ZM46.4963 76.3481C45.3994 76.3488 44.3056 76.2444 43.2296 76.0367L42.8505 78.0004C44.0517 78.2323 45.2729 78.3489 46.4976 78.3481L46.4963 76.3481Z' fill='#323232' />
    <path d='M36.914 75.7785C37.3917 76.0557 38.0037 75.8931 38.2808 75.4153C38.5579 74.9376 38.3953 74.3257 37.9176 74.0486L36.914 75.7785ZM46.4954 41.0059V42.0059L46.496 42.0059L46.4954 41.0059ZM49.3977 42.251C49.9421 42.344 50.4588 41.978 50.5517 41.4336C50.6447 40.8892 50.2788 40.3725 49.7344 40.2795L49.3977 42.251ZM37.9176 74.0486C32.7957 71.0772 29.353 65.5297 29.353 59.1776H27.353C27.353 66.2705 31.1998 72.4635 36.914 75.7785L37.9176 74.0486ZM29.353 59.1776C29.353 49.6932 37.0303 42.0059 46.4954 42.0059V40.0059C35.9227 40.0059 27.353 48.5917 27.353 59.1776H29.353ZM46.496 42.0059C47.4691 42.0053 48.4397 42.0874 49.3977 42.251L49.7344 40.2795C48.6649 40.0969 47.5812 40.0052 46.4948 40.0059L46.496 42.0059Z' fill='#323232' />
    <path d='M53.8202 48.1499C57.375 50.5236 59.717 54.5759 59.717 59.1764C59.717 66.4898 53.7977 72.4187 46.4961 72.4187' fill='#fff' />
    <path d='M54.3755 47.3183C53.9162 47.0116 53.2952 47.1353 52.9885 47.5946C52.6819 48.0539 52.8056 48.6748 53.2649 48.9815L54.3755 47.3183ZM46.4961 71.4187C45.9438 71.4187 45.4961 71.8664 45.4961 72.4187C45.4961 72.971 45.9438 73.4187 46.4961 73.4187V71.4187ZM53.2649 48.9815C56.5533 51.1774 58.717 54.9236 58.717 59.1764H60.717C60.717 54.2283 58.1967 49.8699 54.3755 47.3183L53.2649 48.9815ZM58.717 59.1764C58.717 65.9391 53.2439 71.4187 46.4961 71.4187V73.4187C54.3515 73.4187 60.717 67.0406 60.717 59.1764H58.717Z' fill='#323232' />
    <path d='M39.1717 70.2024C37.3565 68.9948 35.868 67.3559 34.8391 65.4319C33.8102 63.508 33.2728 61.3588 33.2749 59.1762C33.2749 51.8624 39.1939 45.9338 46.4958 45.9338' fill='#fff' />
    <path d='M38.6178 71.035C39.0777 71.3409 39.6984 71.2161 40.0043 70.7563C40.3102 70.2964 40.1854 69.6757 39.7256 69.3698L38.6178 71.035ZM33.2749 59.1762L34.2749 59.1771V59.1762H33.2749ZM46.4958 46.9338C47.0481 46.9338 47.4958 46.4861 47.4958 45.9338C47.4958 45.3816 47.0481 44.9338 46.4958 44.9338V46.9338ZM39.7256 69.3698C38.048 68.2537 36.6721 66.7389 35.7209 64.9604L33.9573 65.9035C35.064 67.9729 36.6651 69.7359 38.6178 71.035L39.7256 69.3698ZM35.7209 64.9604C34.7698 63.1818 34.2729 61.195 34.2749 59.1771L32.2749 59.1752C32.2726 61.5226 32.8506 63.8341 33.9573 65.9035L35.7209 64.9604ZM34.2749 59.1762C34.2749 52.4132 39.7477 46.9338 46.4958 46.9338V44.9338C38.6401 44.9338 32.2749 51.3117 32.2749 59.1762H34.2749Z' fill='#323232' />
    <path d='M54.8608 56.7708H47.8185L52.4045 42.3398L37.6665 61.5811H44.709L40.1228 76.0121L54.8608 56.7708Z' fill='#F0E05D' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinejoin='round' />
  </SvgIcon>
);
