import styled from 'styled-components';
import { FlexCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';

export const SocialLinksContainer = styled.div`
  ${FlexRow}
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.darkGray1};
  padding: 15px;
  border-radius: 16px;
  width: 325px;
  justify-self: center;

  a {
    ${ FlexCenter }
    outline: none;
  }

  .social-icon {
    width: auto;
    min-height: 24px;
    max-height: 24px;
    fill: ${({ theme }) => theme.colors.white};
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    a {
      ${ FlexHorizontalCenter }
      text-decoration: none;

      span {
        &:first-child {
          ${ FlexCenter }
          min-width: 18px;
          text-align: center;
        }
      }
    }
  }
`;
