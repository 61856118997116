import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const EmojiHappyIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }emoji-happy-icon` }
    id={ id }
    width={ 25 }
    height={ 25 }
    fill='none'
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M2.40869 12.2661C2.40869 6.60513 6.99777 2.01605 12.6587 2.01605C18.3196 2.01605 22.9087 6.60513 22.9087 12.2661C22.9087 17.927 18.3196 22.5161 12.6587 22.5161C6.99777 22.5161 2.40869 17.927 2.40869 12.2661ZM12.6587 0.0160522C5.8932 0.0160522 0.408691 5.50056 0.408691 12.2661C0.408691 19.0315 5.8932 24.5161 12.6587 24.5161C19.4242 24.5161 24.9087 19.0315 24.9087 12.2661C24.9087 5.50056 19.4242 0.0160522 12.6587 0.0160522ZM9.66549 13.8043C9.30449 13.3864 8.67302 13.3402 8.25505 13.7012C7.83708 14.0622 7.79089 14.6937 8.15189 15.1116C9.11479 16.2265 10.584 17.016 12.6587 17.016C14.7334 17.016 16.2026 16.2265 17.1655 15.1116C17.5265 14.6937 17.4803 14.0622 17.0623 13.7012C16.6443 13.3402 16.0128 13.3864 15.6519 13.8043C15.0796 14.4669 14.1687 15.016 12.6587 15.016C11.1486 15.016 10.2378 14.4669 9.66549 13.8043ZM7.90869 9.76606C7.90869 9.21378 8.35641 8.76606 8.90869 8.76606H8.91985C9.47213 8.76606 9.91985 9.21378 9.91985 9.76606C9.91985 10.3183 9.47213 10.7661 8.91985 10.7661H8.90869C8.35641 10.7661 7.90869 10.3183 7.90869 9.76606ZM16.4087 8.76606C15.8564 8.76606 15.4087 9.21378 15.4087 9.76606C15.4087 10.3183 15.8564 10.7661 16.4087 10.7661H16.4198C16.9721 10.7661 17.4198 10.3183 17.4198 9.76606C17.4198 9.21378 16.9721 8.76606 16.4198 8.76606H16.4087Z' fill={ fill } />
  </SvgIcon>
);
