import { observer } from 'mobx-react';
import React from 'react';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { FilterPillPickerContainer } from './styles';
import { XIcon } from '../svgs/icons/XIcon';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';

export interface IPillItem {
  id: string;
  label: string;
  onClick: () => void;
  selected: boolean;
  icon: string;
}

interface IProps extends IThemeProps {
  className?: string;
  items: IPillItem[];
}

const FilterPillPickerBase: React.FC<IProps> = ({
  className = '',
  items,
  theme,
}) => (
  <FilterPillPickerContainer className={ className }>
    {
      items.map(({
        label,
        selected,
        onClick,
        icon,
      }) => (
        <Button
          key={ label }
          className={ selected ? 'selected pill-item' : 'pill-item' }
          kind={ ButtonKind.Blank }
          onClick={ onClick }
        >
          <img
            src={ icon }
            alt={ label }
            className='value-icon'
          />
          { label }
          <div className='close-icon-container'>
            { selected ? <XIcon stroke={ theme.colors.white } /> : null }
          </div>
        </Button>
      ))
    }
  </FilterPillPickerContainer>
);

const FilterPillPickerObserver = observer(FilterPillPickerBase);
export const FilterPillPicker = withTheme(FilterPillPickerObserver);
