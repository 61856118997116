import { computed, observable, makeObservable, action, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CompanyModel, ICompanyRef } from './companies';

type PrivateFields = '_article' | '_company' | '_busy' | '_allArticles' | '_errorLoadingAllArticles';

export enum IArticleType {
  GoodAndBad = 'the-good-and-the-bad',
  CompanySpotlight = 'company-spotlight',
  Feature = 'feature',
  General = 'general',
  SourceSpotlight = 'source-spotlight',
  IndustryReport = 'industry-report',
}

export enum ArticleHeaderTypes {
  LogoAndTitle = 'logo-and-title',
  CompanyAndRating = 'company-and-rating',
  TitleOnly = 'title-only',
}

export interface IArticle {
  enabled: boolean;
  company?: ICompanyRef;
  publishedOn?: Date;
  createdOn: Date;
  lastModified: Date;
  introParagraph: string;
  title: string;
  description: string;
  type: IArticleType;
  featured: boolean;
  body?: string;
  headerBackground?: string;
  headerLogo?: string;
  headerTitle?: string;
  listViewImage?: string;
  headerType: ArticleHeaderTypes;
  deleted: boolean;
}

export interface IArticleResponse extends IArticle {
  _id: string;
}

export interface IArticleUpdate {
  enabled: boolean;
  company?: string;
  publishedOn?: Date;
  createdOn?: Date;
  lastModified?: Date;
  introParagraph: string;
  title: string;
  description: string;
  type: IArticleType;
  featured: boolean;
  body: string;
  headerBackground: string;
  headerLogo?: string;
  headerTitle?: string;
  listViewImage?: string;
  headerType: ArticleHeaderTypes;
}

type ArticlesModelPrivateFields = '_busy' | '_loadingTemplates' | '_articles' | '_templates' | '_headers';

export interface IArticleTemplate {
  html: string;
  type: IArticleType;
}

export class ArticleModel extends BaseModel {
  private _allArticles: any[] = [];
  private _article: IArticleResponse = null;
  private _company: CompanyModel = null;
  private _busy = false;
  private _errorLoadingAllArticles = false;

  constructor (data: IArticleResponse) {
    super();
    makeObservable<ArticleModel, PrivateFields>(this, {
      _allArticles: observable,
      _company: observable,
      _article: observable,
      _busy: observable,
      _errorLoadingAllArticles: observable,
      _id: computed,
      allArticles: computed,
      article: computed,
      busy: computed,
      company: computed,
      enabled: computed,
      createdOn: computed,
      lastModified: computed,
      errorLoadingAllArticles: computed,
      description: computed,
      bannerImageUrl: computed,
      publishedOn: computed,
      introParagraph: computed,
      type: computed,
      featured: computed,
      loadArticle: action.bound,
      title: computed,
      body: computed,
      headerBackground: computed,
      headerLogo: computed,
      headerTitle: computed,
      listViewImage: computed,
      headerType: computed,
    });

    this._article = data;
  }

  get _id() { return this._article._id; }
  get allArticles() { return this._allArticles; }
  get bannerImageUrl() { return this._article.headerBackground; }
  get enabled() { return this._article.enabled; }
  get errorLoadingAllArticles() { return this._errorLoadingAllArticles; }
  get publishedOn() { return this._article.publishedOn; }
  get introParagraph() { return this._article.introParagraph; }
  get company() { return this._article.company; }
  get featured() { return this._article.featured; }
  get article() { return this._article; }
  get busy() { return this._busy; }
  get description() { return this._article.description; }
  get type() { return this._article.type; }
  get title() { return this._article.title; }
  get body() { return this._article.body; }
  get headerBackground() { return this._article.headerBackground; }
  get headerLogo() { return this._article.headerLogo; }
  get headerTitle() { return this._article.headerTitle; }
  get headerType() { return this._article.headerType; }
  get listViewImage() { return this._article.listViewImage; }
  get createdOn() { return this._article.createdOn; }
  get lastModified() { return this._article.lastModified; }

  public loadAllArticles = async () => {
    runInAction(() => this._busy = true);

    const result = await this.webServiceHelper.sendRequest<IArticleResponse[]>({
      path: '/article',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._allArticles = result.value.map((article: IArticleResponse) => new ArticleModel(article));
        this._busy = false;
      });
    }
    
    runInAction(() => {
      this._busy = false;
    });
  };

  public updateArticle = async (data: IArticleUpdate) => {
    runInAction(() => this._busy = true );

    const result = await this.webServiceHelper.sendRequest<IArticleResponse>(
      {
        path: `/admin/article/${this._id}`,
        method: 'PUT',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  public loadArticle = async (articleId: string) => {
    if (this._busy) return;
    runInAction(() => this._busy = true);
 
    const result = await this.webServiceHelper.sendRequest<IArticleResponse>({
      path: `/article/${articleId}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._article = result.value;
        if (!!result.value.company) {
          this._company = new CompanyModel(result.value.company, false, result.value.company.evaluatedUnsdgs);
        } 
      });
    } else {
      throw new Error(result.error);
    }

    runInAction(() => this._busy = false);
  };
}

export class ArticlesModel extends BaseModel {
  private _busy = false;
  private _loadingTemplates = false;
  private _articles: ArticleModel[] = [];
  private _templates: IArticleTemplate[] = [];
  private _headers: ArticleHeaderTypes[] = [];

  constructor() {
    super();
    makeObservable<ArticlesModel, ArticlesModelPrivateFields>(this, {
      _busy: observable,
      _loadingTemplates: observable,
      _articles: observable,
      _templates: observable,
      _headers: observable,
      templates: computed,
      headers: computed,
      articles: computed,
      loadArticles: action.bound,
      loadTemplates: action.bound,
    });
  }

  get busy () { return this._busy; }
  get loadingTemplates () { return this._loadingTemplates; }
  get articles () { return this._articles; }
  get templates () { return this._templates; }
  get headers () { return this._headers; }

  public loadArticles = async () => {
    runInAction(() => this._busy = true);

    const result = await this.webServiceHelper.sendRequest<IArticleResponse[]>({
      path: '/admin/article',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._articles = result.value.map((article: IArticleResponse) => new ArticleModel(article));
        this._busy = false;
      });
    }
    
    runInAction(() => {
      this._busy = false;
    });
  };

  public loadTemplates = async () => {
    runInAction(() => this._loadingTemplates = true);

    const result = await this.webServiceHelper.sendRequest<IArticleTemplate[]>({
      path: '/admin/article/templates',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._templates = result.value;
        this._loadingTemplates = false;
      });
    }
    
    runInAction(() => {
      this._loadingTemplates = false;
    });
  };

  public loadHeaderTemplates = async () => {
    runInAction(() => this._loadingTemplates = true);

    const result = await this.webServiceHelper.sendRequest<ArticleHeaderTypes[]>({
      path: '/admin/article/header-types',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._headers = result.value;
        this._loadingTemplates = false;
      });
    }
    
    runInAction(() => {
      this._loadingTemplates = false;
    });
  };

  public createArticle = async (data: IArticleUpdate) => {
    runInAction(() => this._busy = true);

    const result = await this.webServiceHelper.sendRequest<IArticleResponse>(
      {
        path: '/admin/article',
        method: 'POST',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._articles.push(new ArticleModel(result.value));
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  public updateArticle = async (data: IArticleResponse) => {
    runInAction(() => {
      this._articles = this._articles.map((article) => {
        if (article._id === data._id) {
          return new ArticleModel(data);
        } else {
          return article;
        }
      });
    });
  };

  public addArticle = async (data: IArticleResponse) => {
    runInAction(() => this._articles.push(new ArticleModel(data)));
  };
}
