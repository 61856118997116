import styled from 'styled-components';
import { FlexCenter, FlexRow } from '../../styles/styles';

export const FilterPillPickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  .pill-item {
    ${FlexRow}
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: solid 1px ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.05);
    font-weight: 500;
    font-size: 14px;
    height: 44px;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 10px 24px;
    text-align: left;
    width: 100%;

    .value-icon {
      margin-right: 15px;
      max-height: 22px;
    }

    .close-icon-container {
      ${FlexCenter}
      height: 24px;
      margin-left: auto;
      width: 24px;

      svg {
        height: 12px;
        width: 12px;
      }
    }

    &.selected {
      background: ${({ theme }) => theme.colors.darkGray1};
      color: ${({ theme }) => theme.colors.white};
    }

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      font-size: 16px;
      padding: 10px 24px;
      margin-right: 10px;
      width: unset;
    }
  }
`;
