import React, { useEffect, useState } from 'react';
import { CenterContainer, TooltipBox, TooltipWrapper } from './styles';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { XIcon } from '../svgs/icons/XIcon';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { InfoIcon } from '../svgs/icons/InfoIcon';

interface IProps extends IThemeProps {
  className?: string;
  withBorder?: boolean;
  text: string | JSX.Element;
  position?: 'bottom' | 'left' | 'right' ;
  children?: React.ReactNode;
}

const TooltipBase: React.FC<IProps> = ({
  className = '',
  withBorder,
  text,
  position,
  theme,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const onCloseToolTip = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowTooltip(false);
  };

  const onOpenToolTip = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setShowTooltip(prev => !prev);
  };

  useEffect(() => {
    document.addEventListener('click', onCloseToolTip as any);

    return () => {
      document.removeEventListener('click', onCloseToolTip as any);
    };
  }, []);

  const renderTooltip = () => (
    <CenterContainer position={ position } withBorder={ withBorder }>
      <TooltipBox position={ position } withBorder={ withBorder }>
        <Button 
          className='close-button'
          kind={ ButtonKind.Blank }
          onClick={ onCloseToolTip as any }
        >
          <XIcon stroke={ theme.colors.white } />
        </Button>
        { text }
      </TooltipBox>
    </CenterContainer>
  );

  return (
    <TooltipWrapper
      className={ className }
      onClick={ onOpenToolTip }
    >
      { !!children ? children : <InfoIcon /> }
      { !!showTooltip ? renderTooltip() : null }
    </TooltipWrapper>
  );
};

export const Tooltip = withTheme(TooltipBase);
