import React from 'react';
import { HeroLogosContainer, HeroSectionContainer, HeroSectionInnerContainer, HeroTextContainer } from './styles';
import { H2M, H2MInverted } from '../../../styles/components/header';
import { ButtonKind } from '../../../components/Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';
import { theme } from '../../../styles/themes';
import { useAppData } from '../../../contexts/app';
import { ApplyButton } from '../styles';
import { GenericTag } from '../../../components/GenericTag';

interface IProps {
  className?: string;
}

const karmaCollectiveDesktopLogos: string[] = [
  'https://cdn.karmawallet.io/uploads/OzpRZnKBPC-karma-collective-top.png',
  'https://cdn.karmawallet.io/uploads/X0Mw8R3hUF-karma-collective-middle.png',
  'https://cdn.karmawallet.io/uploads/mLZ2TCPiMC-karma-collective-bottom.png',
];

export const HeroSection: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const appData = useAppData();

  const onApplyNowClick = () => {
    analytics.fireEvent('KarmaCollectivePage_ApplyNow_Click');
  };

  return (
    <HeroSectionContainer backgroundColor={ theme.colors.dark } className={ className }>
      <HeroSectionInnerContainer>
        <HeroTextContainer>
          <GenericTag tagText='BRAND COMMUNITY' color={ theme.colors.secondary } />
          <span role='heading' aria-level={ 1 }>
            <H2MInverted className='inverted-text' role='presentation'>Join</H2MInverted>
            <H2M className='hero-text' role='presentation'>The</H2M>
            <br />
            <H2M className='hero-text karma' role='presentation'>Karma Collective</H2M>
          </span>
          <p>Empowering brands, bridging values, earning trust.</p>
          <ApplyButton
            className='apply-button'
            onClick={ onApplyNowClick }
            href='https://docs.google.com/forms/d/e/1FAIpQLSd6ViYM9iVkK_T_HWNDnNUDtQwFjmT4k2p8613IwOO7KQOEeg/viewform'
            kind={ ButtonKind.Primary }
            target='_blank'
            rel='noreferrer'
          >
            Apply Now
          </ApplyButton>
        </HeroTextContainer>
        <HeroLogosContainer>
          {
            !appData.isDesktop ?
              <img
                alt='Karma Collective Companies Logos'
                className='mobile-logos'
                src='https://cdn.karmawallet.io/uploads/aZxgxKjXjf-karma-collective-logos-mobile.png'
              />
              :
              karmaCollectiveDesktopLogos.map(logos => (
                <img key={ logos } src={ logos } alt='Karma Collective Companies Logos' className='logo' />
              )) 
          }
        </HeroLogosContainer>
      </HeroSectionInnerContainer>
    </HeroSectionContainer>
  );
};
