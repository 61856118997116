import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const EnvelopeIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }envelope-icon` }
    id={ id }
    width={ 18 }
    height={ 15 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M4.83333 5.00001L7.75061 7.33383C8.48105 7.91818 9.51895 7.91818 10.2494 7.33383L13.1667 5.00001M16.5 11.3333L16.5 3.66667C16.5 2.56211 15.6046 1.66667 14.5 1.66667L3.5 1.66668C2.39543 1.66668 1.5 2.56211 1.5 3.66668L1.5 11.3333C1.5 12.4379 2.39543 13.3333 3.5 13.3333L14.5 13.3333C15.6046 13.3333 16.5 12.4379 16.5 11.3333Z' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
