import styled from 'styled-components';
import { FlexCenter, FlexCol } from '../../styles/styles';
import { SectorCarousel } from '../SectorCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TextXSBold } from '../../styles/components/text';
import { SectorCarouselLocation } from '../SectorCarousel/styles';

interface IProps {
  location?: SectorCarouselLocation,
}

export const SectorCarouselContainer = styled.div<IProps>`
  width: 100%;
  max-width: 800px;
  min-width: 300px;
  margin: 0 auto;

  h2 {
    margin-bottom: 30px;
    text-align: center;
  }

  @media (min-width: 500px) {
    max-width: ${({ location }) => location === 'accountHub' ? '95%' : '100%'};
  }

  @media (min-width: 700px) {
    max-width: ${({ location }) => location === 'accountHub' ? '650px' : '100%'};
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: ${({ location }) => location === 'accountHub' ? '650px' : '550px'};
  }

  @media (min-width: 1100px) {
    max-width: 650px;
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    max-width: ${({ location }) => location === 'accountHub' ? '650px' : '750px'};
  }

  @media (min-width: 1750px) {
    max-width: ${({ location }) => location === 'accountHub' ? '1215px' : '750px'};
  }
`;

export const SectorList = styled.div`
  ${ FlexCol }

  & > * {
    margin-bottom: 50px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: none;
  }
`;

export const SectorSlider = styled(SectorCarousel)`
  display: block;
  gap: 3px;

  .sector-card {
    &:focus-visible {
      outline: unset;
      outline-offset: unset;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: block;
  }
`;

export const Slide = styled.div`
  ${ FlexCol }
  align-items: center;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  padding: 10px 5px;
  border-radius: 8px;
  cursor: pointer;
  min-height: 100px;

  .img-container {
    ${ FlexCenter }
    position: relative;
    margin: 0 auto 5px;
    overflow: hidden;
    width: 44px;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0 auto;
    line-height: 1.25;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
    -webkit-box-orient: vertical;
  }

  a {
    text-decoration: none;
  }

  &.selected {
    background: ${({ theme }) => theme.colors.primary + '44'};
  }

  @media(hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.primary + '44' };
    }
  }
`;

export const AccountHubSlide = styled.div`
  border-radius: 100px;
  padding: 12px 20px;

  p {
    ${TextXSBold}
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1.25;
    margin: 0 auto;
    text-transform: uppercase;
  }

  &.selected {
    background: ${({ theme }) => theme.colors.primary};
    p {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media(hover: hover) {
    &:not(.selected):hover {
      background: ${({ theme }) => theme.colors.primary + '44' };
      cursor: pointer;
    }
  }
`;
