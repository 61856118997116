import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const Light: FC<IProps> = ({
  className = '',
  id = '',
  fill = 'white',
  stroke = 'black',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}food-graphic` }
    id={ id }
    height={ 531 } 
    width={ 424 }
    fill={ fill }
    stroke={ stroke }
  >
    <path d='M193.148 50.4261C105.686 60.5367 42.9837 139.633 53.0943 227.094C57.6537 266.532 76.762 302.848 106.682 328.936C126.436 346.018 140.794 368.478 148.008 393.574H275.119C281.987 368.716 296.098 346.46 315.657 329.651C382.408 272.222 389.964 171.549 332.535 104.798C297.99 64.6459 245.758 44.2689 193.144 50.4261H193.148Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M287.17 385.184H136.378C132.316 385.184 128.597 387.929 127.83 391.917C126.8 397.282 130.887 401.975 136.066 401.975H136.365C144.652 401.975 151.589 408.288 152.351 416.541C152.676 420.07 152.845 423.612 152.858 427.159V477.525C152.858 486.796 160.375 494.313 169.645 494.313H253.591C262.861 494.313 270.378 486.796 270.378 477.525V427.159C270.387 423.621 270.551 420.092 270.868 416.572C271.612 408.306 278.558 401.975 286.854 401.975C290.915 401.975 294.635 399.23 295.401 395.242C296.432 389.877 292.344 385.184 287.166 385.184H287.17Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M186.432 494.312H236.799V502.708C236.799 516.616 225.524 527.892 211.616 527.892C197.708 527.892 186.432 516.616 186.432 502.708V494.312Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M114.654 125.445C97.36 148.459 85.7383 176.033 82.5601 199.856' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M144.379 95.4805C140.123 98.6154 136.027 102.066 132.108 105.782' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M34.2244 212.062H2.64136' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M420.504 212.062H388.925' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M211.572 34.7119V3.12891' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M86.167 86.6583L63.8372 64.3242' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M359.312 359.799L336.978 337.469' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M336.978 86.6583L359.312 64.3242' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M63.8372 359.799L86.167 337.469' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M238.869 118.827C237.215 118.043 235.253 118.333 233.898 119.558C103.942 237.256 138.668 303.869 159.647 327.035C186.865 357.085 228.031 356.552 262.091 325.705C305.105 286.748 277.042 237.659 261.961 211.289C246.386 184.647 239.232 153.917 241.441 123.131C241.532 121.308 240.519 119.61 238.869 118.827Z' fill={ stroke } />
    <path d='M238.869 118.827C237.215 118.043 235.253 118.333 233.898 119.558C103.942 237.256 138.668 303.869 159.647 327.035C186.865 357.085 224.987 356.37 262.091 325.705C306.824 288.736 277.042 237.659 261.961 211.289C246.386 184.647 239.232 153.917 241.441 123.131C241.532 121.308 240.519 119.61 238.869 118.827Z' fill={ fill === 'none' ? 'white' : fill } stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M211.023 384.955C198.691 316.368 199.765 249.807 219.799 186.117' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M175.707 229.031C180.643 276.782 203.237 284.819 203.237 284.819' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M242.415 263.852C236.396 301.358 203.337 316.162 203.337 316.162' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M198.981 187.27C200.375 187.27 200.375 185.105 198.981 185.105C197.587 185.105 197.587 187.27 198.981 187.27Z' fill={ stroke } />
    <path d='M207.338 175.913C209.07 174.722 210.693 173.367 212.153 171.856C212.547 171.449 212.59 170.726 212.153 170.323C211.715 169.92 211.04 169.886 210.62 170.323C209.931 171.037 209.208 171.717 208.455 172.358C208.273 172.51 208.091 172.661 207.905 172.813C207.805 172.891 207.71 172.969 207.61 173.047C207.567 173.081 207.524 173.116 207.481 173.15C207.377 173.233 207.632 173.038 207.468 173.159C207.065 173.462 206.662 173.757 206.246 174.042C205.779 174.363 205.528 175.008 205.857 175.523C206.156 175.987 206.84 176.255 207.338 175.913Z' fill={ stroke } />
    <path d='M218.054 168.935C219.448 168.935 219.448 166.77 218.054 166.77C216.66 166.77 216.66 168.935 218.054 168.935Z' fill={ stroke } />
    <path d='M253.405 217.27C256.241 219.097 257.717 222.509 257.328 225.8C257.259 226.381 257.873 226.883 258.41 226.883C259.051 226.883 259.424 226.385 259.493 225.8C259.973 221.739 257.912 217.599 254.496 215.4C253.323 214.642 252.236 216.517 253.405 217.27Z' fill={ stroke } />
    <path d='M258.765 234.583C260.159 234.583 260.159 232.418 258.765 232.418C257.371 232.418 257.371 234.583 258.765 234.583Z' fill={ stroke } />
    <path d='M233.093 259.157C234.487 259.157 234.487 256.992 233.093 256.992C231.699 256.992 231.699 259.157 233.093 259.157Z' fill={ stroke } />
    <path d='M266.849 106.868C268.243 106.868 268.243 104.703 266.849 104.703C265.455 104.703 265.455 106.868 266.849 106.868Z' fill={ stroke } />
    <path d='M188.901 250.085C190.373 251.051 191.849 252.021 193.322 252.986C193.798 253.298 194.538 253.095 194.803 252.597C195.088 252.055 194.919 251.449 194.413 251.116C192.941 250.15 191.464 249.18 189.992 248.215C189.516 247.903 188.775 248.106 188.511 248.604C188.225 249.146 188.394 249.752 188.901 250.085Z' fill={ stroke } />
    <path d='M158.136 268.214C159.127 271.566 161.314 274.471 164.25 276.368C164.739 276.684 165.445 276.467 165.731 275.978C166.038 275.454 165.835 274.818 165.341 274.497C162.903 272.921 161.05 270.427 160.223 267.638C160.058 267.08 159.461 266.725 158.889 266.881C158.318 267.036 157.967 267.651 158.132 268.214H158.136Z' fill={ stroke } />
    <path d='M169.641 279.7C171.035 279.7 171.035 277.535 169.641 277.535C168.247 277.535 168.247 279.7 169.641 279.7Z' fill={ stroke } />
    <path d='M186.285 306.584C185.852 309.667 186.792 312.784 188.961 315.04C189.368 315.46 190.083 315.447 190.494 315.04C190.905 314.633 190.901 313.932 190.494 313.507C189.706 312.689 189.065 311.62 188.723 310.559C188.628 310.264 188.545 309.961 188.48 309.658C188.446 309.507 188.42 309.351 188.394 309.199C188.364 309.009 188.407 309.333 188.385 309.139C188.377 309.061 188.368 308.983 188.359 308.905C188.329 308.593 188.316 308.285 188.325 307.974C188.325 307.818 188.333 307.662 188.346 307.506C188.351 307.428 188.359 307.35 188.364 307.272C188.364 307.246 188.394 307.004 188.372 307.155C188.454 306.575 188.229 305.995 187.614 305.822C187.108 305.683 186.363 305.995 186.281 306.579L186.285 306.584Z' fill={ stroke } />
    <path d='M181.691 309.473C181.024 311.928 181.734 314.643 183.514 316.457C183.912 316.864 184.653 316.886 185.047 316.457C185.441 316.029 185.471 315.357 185.047 314.924C184.964 314.838 184.878 314.751 184.8 314.66C184.761 314.617 184.722 314.569 184.683 314.522C184.497 314.305 184.752 314.626 184.657 314.491C184.523 314.292 184.384 314.097 184.267 313.885C184.202 313.768 184.137 313.647 184.081 313.526C184.055 313.47 184.033 313.418 184.003 313.361C184.046 313.448 184.046 313.474 184.003 313.37C183.912 313.132 183.83 312.889 183.765 312.638C183.735 312.521 183.709 312.404 183.683 312.287C183.67 312.227 183.661 312.171 183.648 312.11C183.648 312.084 183.613 311.889 183.635 312.032C183.657 312.175 183.631 311.976 183.626 311.95C183.622 311.889 183.613 311.828 183.613 311.768C183.605 311.634 183.601 311.499 183.601 311.361C183.601 311.118 183.618 310.88 183.639 310.642C183.622 310.794 183.626 310.733 183.648 310.616C183.657 310.555 183.67 310.499 183.683 310.439C183.709 310.304 183.743 310.174 183.778 310.045C183.925 309.499 183.592 308.845 183.02 308.711C182.449 308.577 181.847 308.884 181.687 309.469L181.691 309.473Z' fill={ stroke } />
    <path d='M232.356 322.243C233.751 322.243 233.751 320.078 232.356 320.078C230.962 320.078 230.962 322.243 232.356 322.243Z' fill={ stroke } />
    <path d='M258.406 294.778C260.432 297.532 261.424 300.866 261.207 304.278C261.172 304.863 261.731 305.361 262.29 305.361C262.904 305.361 263.333 304.863 263.372 304.278C263.606 300.533 262.497 296.709 260.272 293.687C259.925 293.215 259.332 292.981 258.791 293.297C258.328 293.57 258.055 294.306 258.401 294.778H258.406Z' fill={ stroke } />
    <path d='M218.054 358.677C219.448 358.677 219.448 356.512 218.054 356.512C216.66 356.512 216.66 358.677 218.054 358.677Z' fill={ stroke } />
    <path d='M252.162 258.427C253.556 258.427 253.556 256.262 252.162 256.262C250.768 256.262 250.768 258.427 252.162 258.427Z' fill={ stroke } />
    <path d='M179.11 212.224C179.18 211.085 179.34 210.089 179.656 208.933C179.972 207.777 180.344 206.816 180.86 205.811C181.115 205.309 181.007 204.612 180.47 204.33C179.976 204.07 179.262 204.179 178.989 204.72C177.794 207.062 177.101 209.6 176.945 212.224C176.911 212.791 177.469 213.332 178.028 213.306C178.643 213.28 179.076 212.83 179.11 212.224Z' fill={ stroke } />
    <path d='M295.444 130.423C296.839 130.423 296.839 128.258 295.444 128.258C294.05 128.258 294.05 130.423 295.444 130.423Z' fill={ stroke } />
    <path d='M315.025 159.237C318.328 162.086 320.45 166.031 321.039 170.352C321.117 170.932 321.862 171.248 322.373 171.11C322.996 170.937 323.208 170.361 323.13 169.776C322.498 165.134 320.104 160.765 316.557 157.704C316.116 157.323 315.462 157.271 315.025 157.704C314.635 158.094 314.579 158.851 315.025 159.237Z' fill={ stroke } />
    <path d='M322.953 177.368C324.347 177.368 324.347 175.203 322.953 175.203C321.559 175.203 321.559 177.368 322.953 177.368Z' fill={ stroke } />
    <path d='M266.559 183.117C269.43 183.606 272.309 183.614 275.18 183.147C275.756 183.052 276.081 182.337 275.938 181.813C275.769 181.198 275.184 180.964 274.604 181.055C272.145 181.458 269.59 181.445 267.131 181.025C266.555 180.926 265.966 181.177 265.797 181.783C265.654 182.302 265.975 183.017 266.555 183.117H266.559Z' fill={ stroke } />
    <path d='M270.945 189.434C272.66 189.793 274.37 190.153 276.085 190.512C276.639 190.629 277.28 190.352 277.419 189.754C277.544 189.213 277.254 188.546 276.661 188.421C274.946 188.061 273.236 187.702 271.521 187.343C270.967 187.226 270.326 187.503 270.188 188.1C270.062 188.642 270.352 189.308 270.945 189.434Z' fill={ stroke } />
    <path d='M109.489 222.481C110.883 222.481 110.883 220.316 109.489 220.316C108.094 220.316 108.094 222.481 109.489 222.481Z' fill={ stroke } />
    <path d='M127.094 162.329C128.488 162.329 128.488 160.164 127.094 160.164C125.7 160.164 125.7 162.329 127.094 162.329Z' fill={ stroke } />
    <path d='M184.311 74.3056C185.705 74.3056 185.705 72.1406 184.311 72.1406C182.916 72.1406 182.916 74.3056 184.311 74.3056Z' fill={ stroke } />
    <path d='M190.914 79.4345C192.308 79.4345 192.308 77.2695 190.914 77.2695C189.52 77.2695 189.52 79.4345 190.914 79.4345Z' fill={ stroke } />
    <path d='M219.081 71.8091C220.103 72.5539 221.28 73.749 222.012 74.7795C222.896 76.0222 223.515 77.2736 223.943 78.6505C224.117 79.2091 224.697 79.5641 225.277 79.4083C225.857 79.2524 226.208 78.6375 226.035 78.0746C225.013 74.7882 222.952 71.9607 220.176 69.9386C219.704 69.5922 218.968 69.8606 218.695 70.3283C218.375 70.8782 218.609 71.4627 219.085 71.8091H219.081Z' fill={ stroke } />
    <path d='M227.468 72.2057C227.68 72.314 227.888 72.4309 228.091 72.5608C228.195 72.6301 228.299 72.6994 228.399 72.773C228.442 72.8033 228.598 72.8682 228.451 72.8119C228.303 72.7556 228.459 72.8206 228.503 72.8552C228.879 73.167 229.17 73.5307 229.503 73.8815C229.434 73.8078 229.382 73.7212 229.477 73.8511C229.512 73.8988 229.542 73.9421 229.577 73.9897C229.641 74.085 229.706 74.1802 229.763 74.2755C229.888 74.479 230.005 74.6868 230.109 74.9033C230.135 74.9553 230.157 75.0072 230.183 75.0592C230.196 75.0895 230.209 75.1198 230.226 75.1545C230.261 75.2281 230.243 75.1978 230.183 75.0549C230.17 75.1198 230.278 75.3103 230.304 75.3753C230.386 75.6134 230.451 75.8516 230.507 76.0984C230.633 76.6526 231.3 77.0294 231.841 76.8562C232.421 76.67 232.729 76.1157 232.599 75.5225C232.105 73.3272 230.572 71.357 228.563 70.3438C228.057 70.0884 227.368 70.1923 227.082 70.7335C226.823 71.2272 226.931 71.9416 227.472 72.2144L227.468 72.2057Z' fill={ stroke } />
    <path d='M159.37 142.157C160.764 142.157 160.764 139.992 159.37 139.992C157.976 139.992 157.976 142.157 159.37 142.157Z' fill={ stroke } />
    <path d='M70.068 172.071C71.7957 172.903 73.597 173.565 75.4546 174.028C76.0045 174.167 76.654 173.851 76.7882 173.271C76.9224 172.691 76.6193 172.084 76.0305 171.937C75.1645 171.721 74.3071 171.465 73.4628 171.162C73.0428 171.01 72.6271 170.846 72.2114 170.677C72.4279 170.768 72.1378 170.647 72.1161 170.638C71.9992 170.586 71.8823 170.534 71.7654 170.482C71.5619 170.391 71.3584 170.296 71.1592 170.196C70.6483 169.95 69.9641 170.041 69.6783 170.586C69.4229 171.071 69.5225 171.807 70.068 172.067V172.071Z' fill={ stroke } />
    <path d='M69.4142 177.044C70.5963 177.615 71.7784 178.191 72.9605 178.763C73.2246 178.893 73.5017 178.953 73.7962 178.871C74.0473 178.802 74.3201 178.607 74.4413 178.373C74.6968 177.888 74.5972 177.156 74.0516 176.892C72.8696 176.321 71.6875 175.745 70.5054 175.173C70.2412 175.043 69.9641 174.983 69.6697 175.065C69.4185 175.134 69.1458 175.329 69.0245 175.563C68.7691 176.048 68.8686 176.78 69.4142 177.044Z' fill={ stroke } />
    <path d='M310.483 279.938C311.877 279.938 311.877 277.773 310.483 277.773C309.088 277.773 309.088 279.938 310.483 279.938Z' fill={ stroke } />
    <path d='M337.108 234.313C337.554 234.378 337.983 234.495 338.403 234.659C338.541 234.711 338.359 234.646 338.32 234.625C338.377 234.655 338.437 234.677 338.493 234.703C338.589 234.75 338.684 234.798 338.779 234.85C338.974 234.958 339.165 235.075 339.351 235.205C339.394 235.235 339.437 235.266 339.481 235.3C339.637 235.413 339.338 235.183 339.437 235.27C339.52 235.339 339.602 235.404 339.68 235.478C339.844 235.629 340.005 235.785 340.152 235.954C340.221 236.032 340.282 236.132 340.355 236.201C340.174 236.036 340.264 236.084 340.321 236.162C340.351 236.205 340.381 236.248 340.412 236.292C340.546 236.487 340.663 236.69 340.771 236.898C341.031 237.4 341.78 237.591 342.252 237.288C342.767 236.954 342.919 236.344 342.642 235.807C341.68 233.945 339.758 232.533 337.675 232.23C337.376 232.187 337.112 232.178 336.839 232.338C336.614 232.468 336.402 232.728 336.341 232.984C336.225 233.495 336.497 234.226 337.099 234.317L337.108 234.313Z' fill={ stroke } />
    <path d='M334.934 245.22C336.328 245.22 336.328 243.055 334.934 243.055C333.54 243.055 333.54 245.22 334.934 245.22Z' fill={ stroke } />
    <path d='M103.612 275.597C106.977 276.021 110.319 276.597 113.632 277.307C114.203 277.428 114.801 277.138 114.966 276.549C115.113 276.008 114.784 275.341 114.208 275.216C110.701 274.467 107.167 273.878 103.612 273.432C103.032 273.358 102.53 273.982 102.53 274.514C102.53 275.159 103.028 275.523 103.612 275.597Z' fill={ stroke } />
    <path d='M106.743 288.27C108.549 288.703 110.359 289.131 112.164 289.564C112.714 289.694 113.364 289.391 113.498 288.806C113.632 288.222 113.329 287.616 112.74 287.473C110.935 287.04 109.125 286.611 107.319 286.178C106.769 286.048 106.12 286.351 105.986 286.936C105.851 287.52 106.154 288.127 106.743 288.27Z' fill={ stroke } />
    <path d='M174.529 414.427C175.924 414.427 175.924 412.262 174.529 412.262C173.135 412.262 173.135 414.427 174.529 414.427Z' fill={ stroke } />
    <path d='M183.332 412.47C184.726 412.47 184.726 410.305 183.332 410.305C181.938 410.305 181.938 412.47 183.332 412.47Z' fill={ stroke } />
    <path d='M228.126 399.966C228.343 400.048 227.897 399.853 228.104 399.957C228.143 399.975 228.182 399.992 228.221 400.014C228.325 400.066 228.429 400.122 228.529 400.187C228.62 400.243 228.706 400.304 228.793 400.364C228.901 400.442 228.607 400.208 228.75 400.334C228.797 400.377 228.845 400.416 228.893 400.46C228.97 400.533 229.048 400.607 229.122 400.685C229.178 400.745 229.261 400.888 229.334 400.927C229.274 400.897 229.191 400.737 229.3 400.888C229.33 400.932 229.36 400.975 229.39 401.018C229.455 401.118 229.516 401.217 229.577 401.317C229.629 401.412 229.676 401.507 229.724 401.603C229.797 401.759 229.733 401.633 229.707 401.551C229.724 401.603 229.746 401.65 229.763 401.702C229.841 401.914 229.901 402.127 229.945 402.347C229.953 402.399 229.962 402.451 229.971 402.503C229.997 402.638 229.945 402.244 229.962 402.438C229.971 402.547 229.979 402.651 229.979 402.759C229.979 402.876 229.979 402.993 229.971 403.11C229.971 403.161 229.958 403.218 229.953 403.27L229.975 403.11C229.966 403.153 229.962 403.196 229.953 403.235C229.854 403.789 230.105 404.43 230.711 404.569C231.239 404.69 231.937 404.409 232.045 403.811C232.27 402.573 232.066 401.33 231.452 400.23C230.837 399.13 229.871 398.334 228.711 397.888C228.182 397.684 227.498 398.113 227.377 398.645C227.234 399.264 227.568 399.758 228.135 399.979L228.126 399.966Z' fill={ stroke } />
    <path d='M235.21 397.989C235.31 398.049 235.409 398.114 235.504 398.184C235.552 398.218 235.6 398.253 235.647 398.287C235.665 398.3 235.686 398.313 235.704 398.331C235.769 398.378 235.743 398.357 235.626 398.27C235.708 398.292 235.834 398.439 235.898 398.495C235.989 398.573 236.076 398.651 236.158 398.729C236.318 398.881 236.474 399.041 236.626 399.21C236.704 399.296 236.782 399.387 236.855 399.478C236.868 399.496 236.886 399.513 236.899 399.534C236.951 399.599 236.929 399.569 236.838 399.457C236.877 399.47 236.92 399.569 236.942 399.599C237.21 399.972 237.449 400.366 237.648 400.777C237.682 400.851 237.821 401.201 237.713 400.903C237.752 401.011 237.799 401.119 237.838 401.232C237.916 401.453 237.985 401.678 238.046 401.903C238.189 402.449 238.825 402.838 239.38 402.661C239.934 402.483 240.289 401.916 240.137 401.327C239.575 399.179 238.18 397.309 236.305 396.127C235.825 395.824 235.093 396.01 234.825 396.516C234.543 397.049 234.699 397.673 235.214 397.997L235.21 397.989Z' fill={ stroke } />
    <path d='M229.542 439.091C230.936 439.091 230.936 436.926 229.542 436.926C228.148 436.926 228.148 439.091 229.542 439.091Z' fill={ stroke } />
    <path d='M206.273 507.657C206.684 507.869 207.082 508.098 207.472 508.349C207.663 508.471 207.849 508.596 208.031 508.726C208.121 508.791 208.212 508.856 208.303 508.925C208.355 508.964 208.403 508.999 208.455 509.038C208.572 509.125 208.247 508.873 208.442 509.029C209.165 509.61 209.832 510.242 210.442 510.939C210.815 511.367 211.594 511.35 211.975 510.939C212.395 510.484 212.374 509.865 211.975 509.406C210.689 507.93 209.109 506.691 207.368 505.786C206.866 505.526 206.169 505.639 205.887 506.176C205.623 506.674 205.74 507.38 206.277 507.657H206.273Z' fill={ stroke } />
    <path d='M216.695 504.542C217.206 506.292 218.345 507.798 219.873 508.786C220.349 509.093 221.09 508.898 221.354 508.396C221.64 507.859 221.475 507.244 220.964 506.915C220.83 506.829 220.705 506.738 220.575 506.642C220.466 506.564 220.761 506.794 220.661 506.707C220.631 506.681 220.605 506.66 220.575 506.638C220.518 506.59 220.462 506.543 220.406 506.491C220.168 506.279 219.947 506.053 219.743 505.811C219.713 505.772 219.553 505.573 219.67 505.724C219.787 505.876 219.635 505.672 219.605 505.633C219.514 505.504 219.427 505.374 219.345 505.235C219.18 504.962 219.037 504.681 218.912 504.391L219.02 504.651C218.929 504.425 218.847 504.2 218.778 503.966C218.617 503.425 218.007 503.027 217.444 503.209C216.881 503.391 216.517 503.958 216.686 504.542H216.695Z' fill={ stroke } />
    <path d='M242.017 486.313C243.411 486.313 243.411 484.148 242.017 484.148C240.623 484.148 240.623 486.313 242.017 486.313Z' fill={ stroke } />
    <path d='M254.448 425.498C254.803 425.554 255.154 425.641 255.496 425.749C255.669 425.805 255.834 425.87 256.003 425.935C256.132 425.987 255.795 425.84 255.92 425.9C255.959 425.918 255.998 425.935 256.037 425.952C256.124 425.991 256.21 426.035 256.293 426.078C256.604 426.238 256.903 426.42 257.189 426.624C257.232 426.654 257.276 426.684 257.319 426.719C257.436 426.805 257.284 426.693 257.25 426.667C257.31 426.723 257.384 426.775 257.445 426.827C257.592 426.953 257.73 427.083 257.865 427.217C258.267 427.615 258.999 427.65 259.397 427.217C259.796 426.784 259.826 426.113 259.397 425.684C258.22 424.511 256.665 423.683 255.02 423.411C254.725 423.363 254.452 423.363 254.184 423.519C253.959 423.649 253.747 423.909 253.686 424.164C253.569 424.684 253.846 425.398 254.444 425.498H254.448Z' fill={ stroke } />
    <path d='M183.553 444.265C184.363 444.759 185.177 445.257 185.986 445.75C186.225 445.893 186.554 445.932 186.822 445.859C187.073 445.789 187.346 445.595 187.467 445.361C187.744 444.832 187.597 444.196 187.078 443.88C186.268 443.386 185.454 442.888 184.644 442.395C184.406 442.252 184.077 442.213 183.808 442.286C183.557 442.356 183.284 442.551 183.163 442.784C182.886 443.313 183.033 443.949 183.553 444.265Z' fill={ stroke } />
    <path d='M151.056 370.903C152.451 370.903 152.451 368.738 151.056 368.738C149.662 368.738 149.662 370.903 151.056 370.903Z' fill={ stroke } />
    <path d='M295.813 320.759C298.277 321.079 300.857 320.486 302.957 319.156C303.434 318.853 303.672 318.178 303.347 317.676C303.022 317.173 302.377 316.965 301.866 317.286C300.879 317.909 300.1 318.234 298.952 318.485C298.355 318.615 298.125 318.65 297.476 318.671C296.917 318.693 296.363 318.663 295.813 318.589C295.263 318.516 294.704 319.139 294.73 319.672C294.761 320.321 295.207 320.676 295.813 320.754V320.759Z' fill={ stroke } />
    <path d='M276.838 405.484C258.631 421.982 218.842 431.763 152.853 431.763' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M270.382 438.008C249.373 450.374 212.118 457.51 155.75 457.51' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M270.382 462.613C249.373 474.98 212.118 482.116 155.75 482.116' stroke={ stroke } strokeWidth='5.196' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
