import styled from 'styled-components';
import { FlexCenter } from '../../styles/styles';

export enum PillTagSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export enum PillTagKind {
  CashbackGreen = 'cashback-green',
  CashbackLightGreen = 'cashback-light-green',
  ForMembers = 'for-members',
  Grey = 'grey',
  Green = 'green',
  Orange = 'orange',
}

interface IProps {
  kind?: PillTagKind;
  size?: PillTagSize;
}

export const PillTagContainer = styled.div<IProps>`
  ${FlexCenter}
  border-radius: 31px;
  font-weight: 700;
  gap: 3px;

  div {
    color: ${({ theme }) => theme.colors.dark};
    line-height: normal;
    text-align: center;
  }

  ${({ kind, theme }) => {
    switch (kind) {
      case PillTagKind.CashbackGreen:
        return `
          background: ${theme.colors.green1};
          border: 1px solid ${theme.colors.secondary};
        `;
      case PillTagKind.CashbackLightGreen:
        return `
          background: ${theme.colors.green3}66;
          border: 1px solid ${theme.colors.secondary};
        `;
      case PillTagKind.ForMembers:
        return `
          background: ${theme.colors.white};
          border: 1px solid ${theme.colors.primary};
        `;
      case PillTagKind.Grey:
        return `
          background: ${theme.colors.grey2};
        `;
      case PillTagKind.Green:
        return `
          background: ${theme.colors.secondary}1A;
          border: 1px solid ${theme.colors.secondary};
        `;
      case PillTagKind.Orange:
        return `
          background: ${theme.colors.orange1}1A;
          border: 1px solid ${theme.colors.orange1};
        `;
      default:
        return `
          background: ${theme.colors.white};
          border: 1px solid ${theme.colors.lightGray}; 
        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case PillTagSize.Large:
        return `
          font-size: 20px;
          min-width: 116px;
          padding: 5px 19px;
        `;
      case PillTagSize.Medium:
        return `
          font-size: 14px;
          font-weight: 500;
          gap: 8px;
          padding: 8px 16px;
        `;
      case PillTagSize.Small:
        return `
          font-size: 9px;
          padding: 6px 13px;
        `;
      default:
        return `
          font-size: 9px;
          padding: 6px 13px;
        `;
    }
  }}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${({ size }) => {
    switch (size) {
      case PillTagSize.Large:
        return;
      case PillTagSize.Medium:
        return;
      case PillTagSize.Small:
        return`
          font-size: 11px;
          padding: 5px 8px;
        `;
      default :
        return `
          font-size: 12px;
          padding: 6px 16px;
        `;
    }
  }}
  }

  @media (${({ theme }) => theme.breakpoints.xxlMin}) {
    ${({ size }) => {
    switch (size) {
      case PillTagSize.Large:
        return;
      case PillTagSize.Medium:
        return;
      case PillTagSize.Small:
        return`
          font-size: 12px;
          padding: 6px 16px;
        `;
      default :
        return `
          font-size: 12px;
          padding: 6px 16px;
        `;
    }
  }}
  }
`;
