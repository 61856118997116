import styled from 'styled-components';
import { FlexCol, FlexRow, TruncateText } from '../../styles/styles';
import { Checkbox } from '../Checkbox';
import { ButtonLink } from '../Button';

interface ICompareCheckboxProps {
  checked: boolean;
  show?: boolean;
}

export const CompanyCardContainer = styled(ButtonLink)`
  ${FlexCol}
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  min-width: 280px;
  min-height: 224px;
  padding: 24px;

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    height: 224px;
    padding: 16px 24px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-height: 350px;
  }
`;

export const TopSection = styled.div`
  ${FlexRow}
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  .top-space {
    height: 32px;
  }

  svg {
    height: 11px;
    margin-top: -2px;
      
    @media (${({ theme }) => theme.breakpoints.xxlMin}) {
      height: 13px;
    }
  }

  .b-corp {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.light};
    border-radius: 3px;
    display: flex;
    height: 61px;
    justify-content: center;
    padding: 8px 11px;
    width: 43px;

    img {
      filter: brightness(0%);
      height: 38px;
      width: 27px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    align-items: center;
    height: 48px;

    svg {
      height: 10px;
      width: 6px;
    }

    .b-corp {
      height: 48px;
      width: 33px;
      padding: 6px 9px;

      img {
        height: 30px;
        width: 21px;
      }
    }
  }
`;

export const CompareSelectButton = styled.button`
  align-self: center;
  border: none;
  justify-self: flex-end;
`;

export const CompareCheckbox = styled(Checkbox)<ICompareCheckboxProps>`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  display: ${({ show }) => show ? 'none' : 'flex'};
  flex-direction: row-reverse;
  gap: 3px;
  width: 100%;

  .label {
    color: ${({ theme }) => theme.colors.grey3};
    font-weight: 500;
    font-size: 12px;
    font-weight: 500;
    padding-left: 0;
  }

  .checkbox.square {
    background: ${({ checked, theme }) => checked ? theme.colors.secondary : theme.colors.white};
    border: 1px solid ${({ checked, theme }) => checked ? theme.colors.secondary : theme.colors.black};
    border-radius: 50%;
    height: 16px;
    min-width: 16px;
    max-width: 16px;
    max-height: 16px;
    min-height: 16px;
    width: 16px;

    div svg {
      height: 10px;
      margin-top: 5px;
      max-width: 10px;
      max-height: 10px;
      width: 10px;
    }

    input {
      cursor: pointer;
    }
  }
`;

export const BottomWrapper = styled.div`
  ${FlexCol}
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    gap: 12px;
  }
`;

export const GraphicContainer = styled.div`
  ${FlexCol}
  align-items: center;
  align-self: stretch;
  margin-top: 20px;

  img {
    height: 110px;
    width: 110px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    ${FlexRow}
    gap: 10px;
    width: 100%;

    img {
      height: 64px;
      width: 64px;
    }
  }
`;

export const TextContainer = styled.div`
  ${FlexCol}
  align-items: center;
  width: 100%;

 .company-name {
    ${TruncateText}
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
    font-size: 18px;
    margin-top: 17px;
    max-width: 100%;
  }
  
  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    align-items: start;
    gap: 8px;
    width: 75%;

    .company-name {
      margin-top: unset;
    }
  }
`;

export const OwnedBy = styled.div`
  ${TruncateText}
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  font-size: 12px;
  margin: 5px 0 15px;
  max-width: 100%;
  padding: 4px 12px;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    margin-top: unset;
    max-width: 100%;
  } 
`;

export const BottomSection = styled.div`
  ${FlexRow}
  padding: 12px 16px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.light};

  .bottom-item-container {
    ${FlexCol}
    font-size: 10px;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    gap: 7px;

    .header {
      color: ${({ theme }) => theme.colors.midGray};
      font-weight: 600;
      font-size: 10px;
      line-height: 10px; 
    }
    
    .rating {
      height: 15px;
    }

    .score {
      ${FlexRow}
      font-size: 20px;
      font-weight: 600;
      align-items: end;
      margin-top: -5px;
      color: ${({ theme }) => theme.colors.black};
    }

    .unsdg {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.midGray}
    }
  }
`;
