import React from 'react';
import { H1 } from '../../../styles/components/header';
import { ButtonKind } from '../../../components/Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';
import { ROIBottomSectionContainer, ROIContainer, ROITopSectionContainer, WhatIsKarmaCollectiveInnerSectionContainer, WhatIsKarmaCollectiveSectionContainer, WhatIsKarmaCollectiveTextContainer } from './styles';
import { ArrowDirection, ThickArrowIcon } from '../../../components/svgs/icons/ThickArrow';
import { ApplyButton } from '../styles';
import { GenericTag } from '../../../components/GenericTag';
import { theme } from '../../../styles/themes';

interface IProps {
  className?: string;
}

export const WhatIsKarmaCollectiveSection: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();

  const onContactUsClick = () => {
    analytics.fireEvent('KarmaCollectivePage_ContactUs_Click');
  };

  return (
    <WhatIsKarmaCollectiveSectionContainer className={ className }>
      <WhatIsKarmaCollectiveInnerSectionContainer>
        <WhatIsKarmaCollectiveTextContainer>
          <GenericTag tagText='FIND LOYAL CUSTOMERS' color={ theme.colors.secondary } />
          <H1>What is the Karma Collective?</H1>
          <p className='bold'>The Karma Collective is a community of like-minded ethical companies that rewards consumers for shopping sustainably.</p>
          <p>The Karma Collective is about building real and authentic relationships with customers to drive acquisition and loyalty for brands. It introduces a new way of reaching your target audience through values-driven marketing.</p>
          <p>Acquire and retain your ideal customers through authentic engagement and shared values with no upfront costs - only paying for verified leads.</p>
          <ApplyButton
            onClick={ onContactUsClick }
            kind={ ButtonKind.Primary }
            href='/contact-us'
          >
            Contact Us to Learn More
          </ApplyButton>
        </WhatIsKarmaCollectiveTextContainer>
        <ROIContainer>
          <ROITopSectionContainer>
            <div className='left-content'>
              <div className='benefit-box'>
                <ThickArrowIcon />
                2.8X LTV
              </div>
              <div className='horizontal-line' />
              <div className='benefit-box'>
                <ThickArrowIcon direction={ ArrowDirection.Down } />
                3X CAC
              </div>
            </div>
              =
            <div className='benefit-box roi-box'>
              <ThickArrowIcon />
              8.4X ROI
            </div>
          </ROITopSectionContainer>
          <ROIBottomSectionContainer>
            <p className='abbreviations'>LTV = lifetime value</p>
            <p className='abbreviations'>CAC = customer acquisition cost</p>
          </ROIBottomSectionContainer>
        </ROIContainer>
      </WhatIsKarmaCollectiveInnerSectionContainer>
    </WhatIsKarmaCollectiveSectionContainer>
  );
};
