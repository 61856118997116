import React, { createContext } from 'react';
import { AccountHubMobileNavModel } from '../models/accountHubMobileNav';

export const AccountHubMobileNavContext = createContext<AccountHubMobileNavModel>(null);

export const useAccountHubMobileNav = () => React.useContext(AccountHubMobileNavContext);

export const AccountHubMobileNavStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <AccountHubMobileNavContext.Provider value={ new AccountHubMobileNavModel() }>
    { children }
  </AccountHubMobileNavContext.Provider>
);
