import React, { useCallback } from 'react';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../contexts/analytics-store';
import { H2 } from '../../styles/components/header';
import { IThemeProps } from '../../styles/themes';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { Clipboard } from '../svgs/graphics/Clipboard';
import { CheckIcon } from '../svgs/icons/CheckIcon';
import { BackedByResearchSectionContainer, ContentWrapperContainer, ListItemsContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
}

const listItems = [
  'Rankings organizations',
  'Sustainability reports',
  'Academic research',
  'Certification databases',
];

export const BackedByResearchBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();

  const handleButtonClick = useCallback(() => {
    analytics.fireEvent('ViewSourcesClick');
  }, []);

  const renderListItems = () => (
    <ListItemsContainer>
      { 
        listItems.map((item) => (
          <li
            key={ item }
            className='list-item'
          >
            <CheckIcon stroke={ theme.colors.primary } />
            <div className='item-text'>{ item }</div>
          </li>
        ))
      }
    </ListItemsContainer>
  );

  return (
    <BackedByResearchSectionContainer className={ className }>
      <H2 className='header'>Backed by Research</H2>
      <ContentWrapperContainer>
        <div className='content-wrapper'>
          <p className='subtext'>We gather private and publicly available data from the following sources:</p>
          { renderListItems() }
        </div>
        <ButtonLink
          href='/sources'
          className='view-sources-button'
          onClick={ handleButtonClick }
          kind={ ButtonKind.Primary }
        >
          View Our Sources
        </ButtonLink>
        <Clipboard type='bw' />
      </ContentWrapperContainer>
    </BackedByResearchSectionContainer>
  );
};

export const BackedByResearch = withTheme(BackedByResearchBase);
