import { selector } from 'recoil';
import { selectedSentimentSubcategoryAtom } from './atom';
import { sentimentCategories } from '../../constants/sentiment';

export const selectedSentimentCategoryIcon = selector({
  key: 'selectedSentimentCategory',
  get: ({ get }) => {
    const selectedCategory = get(selectedSentimentSubcategoryAtom);
    let icon = 'https://cdn.karmawallet.io/uploads/summary-icon.svg';
    
    const sentimentCategoriesArrays = Object.entries(sentimentCategories);

    sentimentCategoriesArrays.forEach((category) => {
      category[1].forEach((subcategory: any) => {
        if (subcategory.title === selectedCategory) icon = subcategory.icon;
      });
    });
    
    return icon;
  } });
