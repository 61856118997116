import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppType } from '../../constants/index';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import { buildBaseKarmaUrl } from '../../lib/urlBuilders';
import { TransactionsReadyModal } from '../modals/TransactionsReadyModal';
import { MainContainerContainer } from './styles';
import { useBanners } from '../../contexts/banners';
import { useHeader } from '../../contexts/header';
import { Footer } from '../Footer';

interface IProps {
  className?: string;
  title?: string;
  appType?: AppType;
  transparent?: boolean;
  children: React.ReactNode;
}

const MainContainerBase: React.FC<IProps> = ({
  children,
  className = '',
  title,
  transparent = false,
  appType = 'karma',
}) => {
  const location = useLocation();
  const analytics = useAnalytics();
  const user = useUserSession();
  const bannersStore = useBanners();
  const headerModel = useHeader();

  useEffect(() => {
    if (transparent) {
      headerModel.setMakeSolid(false);
    } else {
      headerModel.setMakeSolid(true);
    }
    headerModel.setTransparent(transparent);
  }, [transparent]);

  useEffect(() => {
    if (user.authenticating || !user.initialized) return;
    if (!bannersStore.banners) return;

    if (user.isLoggedIn) {
      const bannerToShow = bannersStore.loggedInBanner || bannersStore.bothBanner;
      if (!bannerToShow) return;
      bannersStore.setActiveBanner(bannerToShow);
    }

    if (!user.isLoggedIn) {
      const bannerToShow = bannersStore.loggedOutBanner || bannersStore.bothBanner;
      if (!bannerToShow) return;
      bannersStore.setActiveBanner(bannerToShow);
    }
  }, [user.authenticating, user.initialized, user.isLoggedIn, bannersStore.banners, bannersStore.loggedInBanner, bannersStore.loggedOutBanner, bannersStore.bothBanner]);
  
  useEffect(() => {
    if (window.location.search.includes('oauth_state_id') && !window.location.href.includes('account')) { 
      window.location.href = `${buildBaseKarmaUrl('karma')}/account${window.location.search}`;
    }
  }, [window.location]);

  useEffect(() => {
    const pageTitle = !!title ? `${title} - ` : '';
    document.title = `${pageTitle}Karma Wallet`;

    analytics.firePageViewEvent(location.pathname, location.search);
  }, [title, location]);

  if (!headerModel.initialized) return null;

  return (
    <MainContainerContainer className={ className } offset={ headerModel.headerHeight }>
      <div 
        className={ 
          `content-container
            ${bannersStore.activeBanner && bannersStore.showBanner ? 'has-marketing-banners' : '' } 
            ${!!transparent ? 'transparent' : ''}` 
        }
      >
        { children }
      </div>
      { location.pathname.includes('/account') ? null :<Footer appType={ appType === 'developer' ? 'developer' : 'karma' } /> }
      <TransactionsReadyModal
        isOpen={ user.transactionsReady }
        onClose={ user.transactionsReadyModalClosed }
      />
    </MainContainerContainer>
  );
};

export const MainContainer = observer(MainContainerBase);
