import styled from 'styled-components';
import { FlexCol } from '../../styles/styles';

export const GroupTotalEquivalencyContainer = styled.div`
  position: relative;
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-height: 600px;
  }

  .header {
    margin-bottom: 20px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 35px;
    }
  }
`;

export const GroupTotalEquivalencyInner = styled.div`
  margin: 0 auto;
  max-width: 921px;
  padding: 25px;
  background: ${({ theme }) => theme.colors.light};

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 50px;
  }
`;

export const GroupTotalContentWrapper = styled.div`
  ${FlexCol}
  align-items: center;
  margin: 0 auto;
  max-width: 575px;
  text-align: center;

  .group-text {
    margin-bottom: 30px;
  }

  .purchase-offsets-cta {
    margin-top: 30px;
  }
`;

export const EquivalencySection = styled.div`
  ${FlexCol}
  align-items: center;
  margin: 20px 0 0;

  .equivalency-graphic-wrapper {
    width: 130px;
    margin: 40px 0;

    .equivalency-graphic {
      height: auto;
      width: 100%;
    }

    .smartphone-graphic {
      height: 60%;
    }
  }

  .equivalency-text-wrapper {
    h1 {
      margin-bottom: 5px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin: 35px 0 0;
  }
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.disabled};
  text-align: center;
  margin: 0 auto;
`;
