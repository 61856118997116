import React, { useCallback } from 'react';
import { H5 } from '../../styles/components/header';
import { PasswordRule, PasswordRulesContainer, PasswordRulesWrapper } from './styles';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { passwordRules } from '../../utils/input-validation';
import { CheckIcon } from '../svgs/icons/CheckIcon';
import { XIcon } from '../svgs/icons/XIcon';

interface IProps extends IThemeProps {
  className?: string;
  darkTheme?: boolean;
  password: string;
}

const PasswordRulesBase: React.FC<IProps> = ({
  className = '',
  darkTheme,
  password,
  theme,
}) => {
  const renderRules = useCallback(() => passwordRules.map(rule => {
    const isValid = rule.validate(password);
  
    const icon = isValid
      ? <CheckIcon stroke={ theme.colors.darkGreen1 } />
      : <XIcon stroke={ theme.colors.red } />;
  
    return (
      <PasswordRule
        key={ rule.id }
        className={ isValid ? 'valid' : 'invalid' }
        darkTheme={ darkTheme }
      >
        <span>{ icon }</span>
        <span>{ rule.text }</span>
      </PasswordRule>
    );
  }), [password]);

  if (!password) return null; 

  return (
    <PasswordRulesContainer className={ className } darkTheme={ darkTheme }>
      <H5>Password Rules</H5>
      <PasswordRulesWrapper>
        { renderRules() }
      </PasswordRulesWrapper>
    </PasswordRulesContainer>
  );
};

export const PasswordRules = withTheme(PasswordRulesBase);
