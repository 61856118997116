import { observer } from 'mobx-react';
import React from 'react';
import { JobPostingModel } from '../../models/job-postings';
import { H4 } from '../../styles/components/header';
import { JobPostingDetails } from '../JobPostingDetails';
import { JobPostingContainer } from './styles';

interface IProps {
  className?: string;
  job: JobPostingModel;
  onClick(): void;
}

const JobPostingBase: React.FC<IProps> = ({
  className = '',
  job,
  onClick,
}) => (
  <JobPostingContainer
    className={ className }
    onClick={ onClick }
  >
    <H4>{ job.title }</H4>
    <JobPostingDetails job={ job } />
  </JobPostingContainer>
);

export const JobPosting = observer(JobPostingBase);
