import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const Circle: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }circle` }
    id={ id }
    width={ 285 }
    height={ 285 }
    fill={ fill }
  >
    <path d='M142.233 0.00292969C271.28 0.00292969 284.466 63.5745 284.466 142.003C284.466 220.431 256.214 284.003 142.233 284.003C24.49 284.003 0 220.431 0 142.003C0 63.5745 28.2613 0.00292969 142.233 0.00292969Z' />
  </SvgIcon>
);
