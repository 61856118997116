import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { useSignInModal } from '../../../../contexts/sign-in';
import { useUserSession } from '../../../../contexts/user';
import { SignInMode } from '../../../../models/sign-in';
import { H1 } from '../../../../styles/components/header';
import { IThemeProps } from '../../../../styles/themes';
import { isValidEmail, passwordRules } from '../../../../utils/input-validation';
import { CTAs } from '../../../CTAs';
import { EyeToggle } from '../../../EyeToggle';
import { EnvelopeIcon } from '../../../svgs/icons/EnvelopeIcon';
import { LockIcon } from '../../../svgs/icons/LockIcon';
import { TextFieldKind } from '../../../TextField';
import { FormContainer, FormField } from '../styles';
import { CtaType } from '../../../CTAs/index';
import { Button } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';

interface IProps extends IThemeProps {
  className?: string;
}

const SignInBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const signInModal = useSignInModal();
  const userSession = useUserSession();
  const analytics = useAnalytics();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [inputWithArrowFocused, setInputWithArrowFocused] = useState(false);
  const [genError, setGenError] = useState('');

  const reset = () => { 
    setEmail('');
    setEmailError('');
    setPassword('');
    setPasswordError('');
    setShowPassword(false);
    setInputWithArrowFocused(false);
  };

  useEffect(() => () => reset(), []);

  const disableSignIn = () => !email ||
      !password ||
      !!emailError ||
      !!passwordError;

  const onEmailBlur = useCallback((withArrow: boolean) => () => {
    if (!!email && !isValidEmail(email)) setEmailError('This isn\'t a valid e-mail address');
    if (withArrow) onInputWithArrowBlur();
  }, [email, emailError]);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setGenError('');
    setEmail(e.target.value);
  }, [email]);

  const onInputWithArrowBlur = useCallback(() => {
    setInputWithArrowFocused(false);
  }, [inputWithArrowFocused]);

  const onInputWithArrowFocus = useCallback(() => {
    setInputWithArrowFocused(true);
  }, [inputWithArrowFocused]);

  const onModeClick = useCallback((mode: SignInMode) => () => {
    signInModal.setMode(mode);
    analytics.fireEvent(`SignInModalModeChange_${mode}`);
  }, [signInModal.mode]);

  const onPasswordChange = useCallback((validate: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    setGenError('');
    if (validate && !!e.target.value) {
      const isValid = !passwordRules.find(r => !r.validate(e.target.value));
      setPasswordError(isValid ? '' : 'invalid');
    } else {
      setPasswordError('');
    }

    setPassword(e.target.value);
  }, [password]);

  const onResetPasswordClick = useCallback(() => {
    signInModal.setMode(SignInMode.RESET_PASSWORD);
    analytics.fireEvent(`SignInModalModeChange_${SignInMode.RESET_PASSWORD}`);
  }, [signInModal.mode]);

  const onSignInClick = useCallback(async(Event: React.SyntheticEvent) => {
    Event.preventDefault();
    try {
      if (!email || !password) return;

      analytics.fireEvent('SignInClick');

      await userSession.login(email, password);

      const redirectTo = searchParams.get('redirectTo');
      signInModal.close();

      if (!!redirectTo) {
        if (redirectTo.split('')[0] === '/') {
          navigate(redirectTo);
        } else {
          window.location.href = redirectTo;
        }
      } else if (window.location.pathname === '/apply') {
        return;
      } else {
        navigate('/account/dashboard');
      }
    } catch (err: any) {
      setGenError(err.message);
      analytics.fireEvent('SignInError');
    }
  }, [email, password]);

  const onShowPasswordClick = useCallback(() => {
    setShowPassword(!showPassword);
    analytics.fireEvent(`ShowPasswordToggleClick_${!showPassword}`);
  }, [showPassword]);

  return (
    <FormContainer className={ className }>
      <H1 className='sign-in-header'>Sign In</H1>
      <p>Sign in to your Karma Wallet account.</p>
      <form aria-label='Sign in form'>
        <FormField
          autoComplete='email'
          id='user-email-input'
          className={ `input-with-arrow ${signInModal.mode === SignInMode.SIGN_IN ? 'arrow-left' : 'arrow-right' } ${ inputWithArrowFocused ? 'focused' : '' } ${ !!emailError ? 'error' : '' }` }
          fieldKind={ TextFieldKind.Pill }
          label='Email'
          labelHidden
          leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
          placeholder='Email Address'
          value={ email }
          onChange={ onEmailChange }
          onBlur={ onEmailBlur(true) }
          onFocus={ onInputWithArrowFocus }
          errorText={ emailError }
        />
        <FormField
          autoComplete='current-password'
          id='user-password-input'
          className={ !!passwordError ? 'error' : '' }
          fieldKind={ TextFieldKind.Pill }
          label='Password'
          labelHidden
          leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
          rightAccessory={ <EyeToggle show={ showPassword } onClick={ onShowPasswordClick } /> }
          placeholder='Password'
          type={ showPassword ? 'text' : 'password' }
          value={ password }
          errorText={ genError }
          onChange={ onPasswordChange(false) }
        />
        <CTAs
          className='ctas-container sign-in'
          ctas={ [
            {
              id: 'sign-in-cta',
              disabled: disableSignIn(),
              onClick: onSignInClick,
              text: 'Sign In',
              ctaType: CtaType.Submit,
            },
          ] }
        />
      </form> 
      <Button
        className='forgot-password-cta'
        kind={ ButtonKind.Blank }
        onClick={ onResetPasswordClick }
      >
        Forgot Password?
      </Button>
      <p className='new-to-karma'>New to Karma Wallet?
        <Button
          className='create-account-cta'
          kind={ ButtonKind.Blank }
          onClick={ onModeClick(SignInMode.CREATE_ACCOUNT) }
        >
          Create an account
        </Button>
      </p>
    </FormContainer>
  );
};

const SignInAsObserver = observer(SignInBase);
export const SignIn = withTheme(SignInAsObserver);
