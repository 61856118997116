import React from 'react';
import { PencilBannerContainer } from './styles';
import { useLocation } from 'react-router-dom';

interface IProps {
  className?: string;
  background?: string;
  children?: React.ReactNode;
}

export const PencilBanner: React.FC<IProps> = ({
  children,
  className = '',
  background,
}) => {
  const location = useLocation();

  return (
    <PencilBannerContainer
      className={ className }
      background={ background }
      location={ location.pathname }
    >
      { children }
    </PencilBannerContainer>
  );
};
