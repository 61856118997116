import { useEffect } from 'react';
import { useSocket } from '../contexts/socket-client';
import { ISocketEvent, SocketClient } from '../models/socket-client';

const useSocketOn = (eventName: string, handler: (socketEventData: ISocketEvent) => void, dependencies?: null): SocketClient => {
  const socket = useSocket();

  useEffect(() => {
    if (!eventName) return;
    socket.on(eventName, handler);
    return () => {
      socket.off(eventName, handler);
    };
  }, dependencies ? [socket.isConnected, ...dependencies] : [socket.isConnected]);
  return socket;
};

export default useSocketOn;
