import React, { useCallback, useRef } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { IRareProject } from '../../models/offsets';
import { H4, H5 } from '../../styles/components/header';
import { DataBullets, KeyStats, ProjectDetails, ProjectOverview, RareProjectCardContainer, VerifiedBy, WhyConsider } from './styles';

interface IProps {
  className?: string;
  project: IRareProject;
}

export const RareProjectCard: React.FC<IProps> = ({
  className = '',
  project,
}) => {
  const analytics = useAnalytics();
  const { name, description_short, tagline, images, co2_impact, verifiers } = project;
  const image = useRef((images || []).find((img) => img.orientation === 'landscape')).current;
  const descriptionArray = useRef((description_short || '').split('\n')).current;
  const shortDescription = useRef(descriptionArray.find(x => !!x) ?? '').current;
  const benefits = useRef(descriptionArray.slice(1)).current;

  const onVerifierClick = useCallback(() => {
    analytics.fireEvent('OffsetEmissions_Verifier_Click');
  }, []);

  const renderProjectOverview = useCallback(() => (
    <ProjectOverview>
      <img src={ image.sizes.m } alt={ image.name } />
      <div className='content'>
        <H4 className='project-name'>{ name }</H4>
        <p>{ tagline }</p>
      </div>
    </ProjectOverview>
  ), []);

  const renderVerifierLinks = useCallback(() => verifiers.map((item) => (
    <a
      className='verifier-link'
      href={ item.website_url }
      key={ item.name }
      onClick={ onVerifierClick }
      rel='noreferrer'
      target='_blank'
    >
      { item.name }
    </a>
  )), [project]);

  const renderProjectDetails = useCallback(() => (
    <ProjectDetails>
      <DataBullets>
        <KeyStats>
          <H5 className='heading'>Key Statistics</H5>
          <p className='stat'>
            <span className='bold'>CO<sub>2</sub> Impact: </span> 
            { co2_impact }
          </p>
        </KeyStats>
        <VerifiedBy>
          <H5 className='heading'>Verified By</H5>
          { renderVerifierLinks() }
        </VerifiedBy>
      </DataBullets>
      <WhyConsider>
        <H5 className='heading'>Why Consider It</H5>
        <p className='benefits short-description'>{ shortDescription }</p>
        <ul>
          { 
            benefits.map((benefit, i) => (
              <li
                key={ `${name}-benefit-${i}` }
                className='benefit'
              >
                { benefit }
              </li>
            ))
          }
        </ul>
      </WhyConsider>
    </ProjectDetails>
  ), []);

  return (
    <RareProjectCardContainer className={ className }>
      { renderProjectOverview() }
      { renderProjectDetails() }
    </RareProjectCardContainer>
  );
};
