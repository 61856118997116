import React, { useCallback } from 'react';
import { CompanyRating } from '../../models/companies';
import { Ratings } from '../../models/impact';
import { EmojiHappyIcon } from '../svgs/icons/EmojiHappyIcon';
import { EmojiNeutralIcon } from '../svgs/icons/EmojiNeutralIcon';
import { EmojiSadIcon } from '../svgs/icons/EmojiSadIcon';
import { CompanyRatingIndicatorContainer } from './styles';

interface IProps {
  className?: string;
  rating: string;
  backgroundColor?: string;
}

export const CompanyRatingIndicator: React.FC<IProps> = ({
  className = '',
  rating,
  backgroundColor,
}) => {
  const renderIndicator = useCallback(() => {
    switch (rating) {
      case Ratings.Positive: return <EmojiHappyIcon className={ CompanyRating.Positive } />;
      case Ratings.Negative: return <EmojiSadIcon className={ CompanyRating.Negative } />;
      default: return <EmojiNeutralIcon className={ CompanyRating.Neutral } />;
    }
  }, [rating]);

  return (
    <CompanyRatingIndicatorContainer
      className={ `${className} ${rating}` }
      backgroundColor={ backgroundColor || 'transparent' }
    >
      { renderIndicator() }
    </CompanyRatingIndicatorContainer>
  );
};
