import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { FlexCol, FlexRow } from '../../styles/styles';

export const GetStartedSectionContainer = styled(Section)``;

export const InnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexCol}
  align-items: center;
  justify-content: space-evenly;

  p {
    margin-bottom: unset;
  }

  .get-started-steps-desktop {
    display: none;
    height: auto;
    max-width: 541px;
    width: 50%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: inline;
    }
  }

  .get-started-steps-mobile { 
    height: auto;
    margin-bottom: 16px;
    max-width: 376px;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: none;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
  }
`;

export const HeaderContainer = styled.div`
  H6 {
    background: ${({ theme }) => theme.colors.green};
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: normal;
    margin-bottom: 16px;
    padding: 4px 8px;
  }

  H1 {
    font-size: 40px;
    line-height: normal;
    margin-bottom: 26px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      margin-bottom: 52px;
    }
  }
`;

export const StepsContainer = styled.div`
  ${FlexCol}
  gap: 36px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 52px;
  }
`;

export const StepContainer = styled.div`
  ${FlexRow}
  align-items: start;
  gap: 8px;

  .step-number {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 40px;
    color: ${({ theme }) => theme.colors.white};
    padding: 6px 12px;
  }
`;

export const StepTextContainer = styled.div`
  ${FlexCol}
  gap: 2px;
  max-width: 404px;

  H4 {
    color: ${({ theme }) => theme.colors.black};
    font-size: 24px;
    line-height: 32px;
  }

  p {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
  }

  .description-1 {
    max-width: 323px;
  }

  .description-2 {
    max-width: 268px;
  }

  .description-4 {
    max-width: 323px;
  }
`;

export const AppButtonsWrapper = styled.div`
  ${FlexCol}
  gap: 3px;
  margin: auto;
  max-width: 352px;

  .disclaimer-text {
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    margin: unset;
  }
`;

export const AppButtonsContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 12px;

  img {
    height: 52px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    gap: 17px;
  }
`;
