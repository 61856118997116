import styled from 'styled-components';
import { FlexCenter } from '../../styles/styles';

export const ContactUsContainer = styled.div`
  ${FlexCenter}
  padding: 9px;
  background: ${({ theme }) => theme.colors.primary};
  max-width: 1523px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    flex-direction: column;
    padding: 12px;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.white};
    margin-right: 15px;

    @media (${({ theme }) => theme.breakpoints.smMax}) {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  a {
    background: ${({ theme }) => theme.colors.green3};
    color: ${({ theme }) => theme.colors.dark};
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    padding: 8px 22px;
  }
`;
