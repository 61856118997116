import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Source } from './Source';
import { SourcesContainer, SourcesInner, SourcesWrapper } from './styles';
import { Section } from '../../styles/components/containers';
import { UNSDGsModel } from '../../models/unsdgs';
import { SourcesModel } from '../../models/sources';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { SectionHeader } from '../../components/SectionHeader';

interface IProps {
  className?: string;
}

const SourcesBase: React.FC<IProps> = ({
  className = '',
}) => {
  const unsdgModel = useRef(new UNSDGsModel()).current;
  const sourcesModel = useRef(new SourcesModel()).current;
  const [errorLoadingUNSDGs, setErrorLoadingUNSDGs] = useState(false);
  const [errorLoadingSources, setErrorLoadingSources] = useState(false);

  useEffect(() => {
    if (!unsdgModel.unsdgs.length) {
      unsdgModel.load()
        .catch(() => {
          setErrorLoadingUNSDGs(true);
        });
    }

    if (!sourcesModel.sources.length) {
      sourcesModel.loadSources()
        .catch(() => {
          setErrorLoadingSources(true);
        });
    }
  }, []);

  const sources = useMemo(() => {
    let children;

    if (errorLoadingSources) children = <div className='error-text'>Error loading data sources.</div>;

    if (sourcesModel.sources.length > 0 && !sourcesModel.busy) {
      children = sourcesModel.sources.map(({ _id, logoUrl, url, name, description }) => (
        <Source
          key={ _id }
          img={ logoUrl }
          link={ url }
          name={ name }
          description={ description } 
        />
      ));
    }

    return (
      <SourcesWrapper itemsPerRow={ 2 }>
        { children }
      </SourcesWrapper>
    );
  }, [sourcesModel.sources, sourcesModel.busy, errorLoadingSources]);

  const unsdgs = useMemo(() => {
    let children;

    if (errorLoadingUNSDGs) {
      children = <div className='error-text'>Error loading UN SDGs.</div>;
    }

    if (unsdgModel.unsdgs.length > 0 && !unsdgModel.busy) {
      children = unsdgModel.unsdgs.map( ({ _id, img, sourceUrl, title, description }) => (
        <Source
          key={ _id }
          img={ img }
          link={ sourceUrl }
          name={ title }
          description={ description } 
          className='unsdg'
        />
      ));
    }

    return (
      <SourcesWrapper itemsPerRow={ 3 }>
        { children }
      </SourcesWrapper>
    );
  }, [unsdgModel.unsdgs, unsdgModel.busy, errorLoadingUNSDGs]);

  if (unsdgModel.busy || sourcesModel.busy) return <LoadingSpinner />;

  return (
    <SourcesContainer className={ className } title='Sources'>
      <SourcesInner>
        <Section>
          <SectionHeader headerText='Data Sources' />
          { sources }
        </Section>
        <Section>
          <SectionHeader headerText='UN Sustainable Development Goals' />
          { unsdgs }
        </Section>
      </SourcesInner>
    </SourcesContainer>
  );
};

export const Sources = observer(SourcesBase);
