import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { KWLogoDark } from '../svgs/graphics/LogoWithText';
import { PlusIcon } from '../svgs/icons/PlusIcon';
import { PartnershipSectionContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  partnerLogo: string;
}

export const PartnershipSectionBase: React.FC<IProps> = ({
  className = '',
  partnerLogo,
  theme,
}) => (
  <PartnershipSectionContainer className={ className }>
    <div className='karma-logo-wrapper'>
      <KWLogoDark className='kw-logo' />
    </div>
    <PlusIcon stroke={ theme.colors.darkGray1 } />
    <img className='partner-logo' src={ partnerLogo } />
  </PartnershipSectionContainer>
);

export const PartnershipSection = withTheme(PartnershipSectionBase);
