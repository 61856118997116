import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const Recycling: FC<IProps> = ({
  className = '',
  id = '',
  fill = 'white',
  stroke = 'black',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}recycling-graphic` }
    id={ id }
    height={ 138 } 
    width={ 131 }
    fill={ fill }
    stroke={ stroke }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M16.167 100.621C16.167 100.621 20.6561 102.278 22.8134 102.478C26.9088 102.859 29.02 102.566 29.02 102.566C29.02 102.566 34.6779 107.908 37.8869 110.077C41.1955 112.313 48.8719 115.986 48.8719 115.986L48.6826 117.673L37.6218 119.296L24.5448 117.667L16.8388 114.069L16.167 100.621Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M1.7915 57.9246L2.28684 61.6412L9.76225 67.9679L10.4048 63.4151L1.7915 57.9246Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M25.8608 78.7344L26.3985 82.0044L34.5674 89.1343L34.4074 86.008L25.8608 78.7344Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M34.4219 86.0393L25.909 78.6356C25.909 78.6356 20.8482 83.0178 17.3882 91.1633C16.3054 93.7129 14.3952 98.4666 13.8965 103.098C13.3649 108.035 14.9865 114.009 14.9865 114.009C14.9865 114.009 12.2364 111.44 6.36214 102.456C4.4998 99.6084 1.64317 95.7946 1.16495 91.9023C0.622212 87.4856 1.45717 80.6185 3.1058 75.9537C4.91163 70.844 8.92271 64.0109 8.92271 64.0109L1.84963 57.879C1.84963 57.879 10.9651 54.6556 19.0579 51.7263C24.3193 49.8222 28.5804 47.4529 31.0935 48.8609C33.7077 50.3255 32.8684 54.5168 33.1197 61.7735C33.4825 72.2513 34.4219 86.0393 34.4219 86.0393Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M25.8953 78.6558L26.4221 81.8923C26.4221 81.8923 20.9321 89.1567 19.1384 94.4491C16.7533 101.486 15.9459 109.233 18.0437 112.41C19.1071 114.02 21.9103 115.656 25.5373 116.537C28.2162 117.189 31.3934 117.292 34.398 117.382C41.868 117.605 48.9453 115.996 48.9453 115.996L48.988 118.7C48.4701 119.284 46.8301 119.558 44.3002 119.961C40.9084 120.5 36.0138 121.049 31.0099 120.77C26.1341 120.498 23.4931 119.958 18.9915 117.336C17.6362 116.546 14.3861 114.292 13.9882 111.999C13.4075 108.651 13.3551 102.991 14.7513 98.1175C16.6359 91.5395 20.0573 85.1415 22.2587 82.4119C24.0811 80.152 25.8953 78.6558 25.8953 78.6558Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M29.7703 69.0797C29.687 69.0002 29.6754 68.9442 29.7912 68.9606' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.3449 56.2937C16.3425 56.2942 16.3435 56.3025 16.3457 56.3014C16.5148 56.2202 16.653 56.134 16.8379 56.072C17.2034 55.9497 17.5847 55.7159 17.9057 55.5107' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M19.0881 54.9282C19.0953 54.8953 19.085 54.8695 19.1212 54.875' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.6155 67.1594C11.8816 67.7569 12.302 68.1893 12.8397 68.5607' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.1877 67.946C11.2631 68.2248 11.6308 68.5836 11.8455 68.7641' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.6379 67.389C13.597 67.378 13.6059 67.3687 13.6011 67.4027' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M29.7031 55.784C29.7085 55.7753 29.7057 55.7819 29.6821 55.7786' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M22.9871 60.6668C23.0616 60.3881 23.6232 59.8866 23.8615 59.7078' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M25.4287 58.4751C25.4067 58.4872 25.4114 58.5042 25.408 58.5283' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.2646 66.0755C27.2621 66.0557 27.27 66.0508 27.2388 66.0464' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.44322 59.8813C8.40114 59.956 8.84852 60.4295 8.90388 60.4882' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.0649 61.4768C10.1134 61.4823 10.0936 61.5031 10.1377 61.5092' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.20785 61.9396C5.17109 61.9325 5.16626 61.955 5.20137 61.9599' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.32056 63.2241C6.36582 63.3465 6.40313 63.4765 6.41663 63.6049' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M29.313 82.8005C29.3173 82.8005 29.3217 82.7999 29.3261 82.7993' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M26.1301 76.4289C26.1737 76.3021 26.9401 75.7387 27.0828 75.5889' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.251 76.6222C27.3233 76.4905 27.3253 76.4 27.5055 76.3396' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.355 51.8784C27.3764 52.1988 27.6821 52.2471 27.9589 52.1966' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.4966 51.4932C28.5575 51.5025 28.5431 51.5019 28.5826 51.5288' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14.7874 75.3293C14.5843 75.7891 15.4398 76.3312 15.7778 76.3789' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.7395 75.2958C16.7058 75.2864 16.7145 75.2793 16.71 75.3111' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M22.2362 71.4248C22.1991 71.4084 22.2225 71.4182 22.1648 71.3979' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.1366 91.4748C11.0715 91.3727 11.1344 91.5659 11.1505 91.5993' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21.0311 108.245C20.8917 108.294 20.8848 108.323 20.9455 108.448' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.5316 111.149C28.518 111.134 29.412 111.251 29.7016 111.352' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M31.9763 112.069C31.976 112.065 31.9758 112.061 31.9756 112.057' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M23.3523 113.414C23.3848 113.504 23.4102 113.544 23.4495 113.625' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.512 102.976C10.336 102.515 10.4235 101.881 10.5176 101.399' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.4136 110.71C15.3939 110.665 15.397 110.691 15.4722 110.655' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.1672 105.189C28.1232 105.346 28.5906 105.782 28.6803 105.872' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M40.7925 118.564C40.7551 118.585 40.764 118.59 40.7295 118.59' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M24.375 117.589C24.4372 117.723 24.6408 117.731 24.7647 117.8C25.1375 118.007 25.4638 118.224 25.9074 118.287' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M26.9319 118.463C26.9545 118.453 26.975 118.455 26.9996 118.455' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M46.7994 117.57C46.7983 117.575 46.7972 117.58 46.7961 117.585' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M39.5369 113.172C39.5301 113.368 40.0674 113.592 40.1915 113.716' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.9541 113.547C39.0865 113.64 39.2028 113.787 39.3177 113.902' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.72363 97.7253C5.73241 97.6935 5.72961 97.711 5.72961 97.6726' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M31.6914 79.0286C31.8354 79.0434 32.1032 78.8805 32.238 78.813' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.052 99.7207C11.0383 99.7047 11.049 99.713 11.0125 99.7305' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.30008 89.6941C4.31901 89.7094 4.3003 89.6831 4.29272 89.6677' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.91164 87.4519C4.87038 87.3959 4.85732 87.3904 4.88064 87.3235' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.70483 78.38C5.76047 77.5427 6.10876 76.5655 6.60081 75.866' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.85743 82.9378C9.72438 82.9076 10.5221 83.5622 10.6774 83.6818' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.39205 83.8035C9.26465 83.8512 10.0515 84.3702 10.2447 84.4372' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M18.0494 85.5905C18.0419 85.5213 17.9924 85.518 18.061 85.4851' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M19.1543 89.6398C19.1122 89.586 19.2536 89.6096 19.2594 89.6069' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M19.9916 88.6766C19.9722 88.6596 19.9779 88.67 20.0158 88.6514' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M31.7742 85.4513C31.7749 85.4475 31.7757 85.4437 31.7764 85.4399' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.8026 100.162C15.701 99.9881 16.0675 99.3605 16.1263 99.1909' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.3701 97.9921C16.3876 97.9619 16.3768 97.9827 16.3962 97.9268' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M58.7517 23.2444C58.7517 23.2444 55.0722 26.3035 53.8201 28.0717C51.4426 31.428 50.6403 33.4027 50.6403 33.4027C50.6403 33.4027 43.1857 35.6319 39.7029 37.3265C36.1113 39.0734 29.0924 43.885 29.0924 43.885L27.726 42.8775L31.8513 32.4873L39.8001 21.9766L46.7692 17.102L58.7517 23.2444Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M102.917 32.1467L99.4504 30.7174L90.2336 34.0279L93.8552 36.8608L102.917 32.1467Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M72.8623 42.5906L69.7615 41.4212L59.5024 44.9307L62.2898 46.3553L72.8623 42.5906Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M62.2542 46.3473L72.9224 42.6768C72.9224 42.6768 71.6577 36.1028 66.3335 29.0337C64.6668 26.8211 61.5052 22.79 57.7437 20.0425C53.734 17.1137 47.7497 15.531 47.7497 15.531C47.7497 15.531 51.349 14.4335 62.0664 13.8382C65.4641 13.6495 70.1952 13.0824 73.8051 14.6144C77.9015 16.3528 83.4311 20.5094 86.6466 24.2695C90.1688 28.3883 94.0809 35.2785 94.0809 35.2785L102.928 32.219C102.928 32.219 101.162 41.725 99.6521 50.1982C98.6703 55.7067 98.5917 60.5815 96.1158 62.054C93.5403 63.5857 90.3302 60.7632 83.9201 57.3525C74.6646 52.4277 62.2542 46.3473 62.2542 46.3473Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M72.9128 42.6517L69.8464 41.4896C69.8464 41.4896 66.3004 33.1029 62.6139 28.9033C57.712 23.3191 51.4064 18.7464 47.6069 18.9749C45.6806 19.0908 42.8625 20.7006 40.2854 23.4008C38.3815 25.3949 36.7036 28.0949 35.1238 30.6522C31.1954 37.0098 29.0505 43.9434 29.0505 43.9434L26.687 42.6283C26.4399 41.8876 27.0228 40.3305 27.939 37.9381C29.1678 34.731 31.14 30.2179 33.8838 26.024C36.5574 21.9375 38.3449 19.92 42.867 17.3329C44.2289 16.5542 47.8059 14.8664 49.9905 15.6683C53.1803 16.8393 58.1082 19.624 61.6305 23.2698C66.3848 28.1909 70.215 34.3529 71.4782 37.6242C72.5242 40.3323 72.9128 42.6517 72.9128 42.6517Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.2685 50.7969C79.3791 50.7645 79.4333 50.7825 79.3612 50.8746' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.0554 45.5663C97.0561 45.5639 97.0485 45.5607 97.0483 45.5631C97.0341 45.7502 97.0396 45.9129 97.0009 46.104C96.9241 46.4818 96.9358 46.9289 96.953 47.3094' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M96.8627 48.6229C96.8876 48.6456 96.9151 48.6495 96.8922 48.6782' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.01 36.0349C89.3595 35.9667 88.7749 36.1145 88.1843 36.3945' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M89.542 35.2703C89.2629 35.1962 88.7683 35.3352 88.5047 35.4309' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M88.7983 37.675C88.8283 37.6451 88.8319 37.6575 88.8049 37.6363' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.819 57.3887C90.8239 57.3977 90.8196 57.392 90.8342 57.3733' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M89.9446 49.1298C90.1487 49.3338 90.3022 50.0709 90.3379 50.3667' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.6251 52.3432C90.6257 52.3181 90.6086 52.3136 90.5894 52.2986' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.1216 50.1306C83.1399 50.1383 83.1402 50.1477 83.1597 50.1228' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.8997 36.9309C97.8561 36.8571 97.2224 37.0078 97.1439 37.0264' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.7036 37.5347C95.6746 37.5739 95.6665 37.5463 95.6392 37.5815' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.7324 33.0984C97.757 33.0701 97.7399 33.0547 97.7181 33.0826' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M96.0649 33.4197C95.9363 33.3977 95.8051 33.365 95.6871 33.3125' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.614 43.5397C67.6118 43.5435 67.6101 43.5476 67.6084 43.5516' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M74.7252 43.9745C74.8131 44.0757 74.9179 45.0211 74.9763 45.2196' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.9985 44.8465C74.0763 44.975 74.1538 45.022 74.1159 45.2082' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.3708 57.3042C95.0826 57.1626 94.888 57.4031 94.7933 57.6681' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.136 58.488C95.0975 58.5361 95.1051 58.5239 95.0621 58.5447' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M81.3498 34.6983C81.0532 34.2925 80.156 34.7624 79.9457 35.0312' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.3985 36.4018C80.4235 36.3773 80.4253 36.3883 80.4 36.3685' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M81.0047 43.1004C81.0375 43.0765 81.0173 43.0918 81.0637 43.052' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.1898 23.4622C69.3107 23.4568 69.112 23.4148 69.0749 23.412' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.7179 23.6436C49.7452 23.4986 49.724 23.4783 49.5848 23.4681' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M43.4539 28.6919C43.4736 28.6875 42.9248 29.403 42.6931 29.6036' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M40.9343 31.2129C40.9377 31.2145 40.9417 31.2165 40.9451 31.2183' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M44.0847 23.0752C43.9906 23.0583 43.9432 23.0603 43.8532 23.0538' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.5417 17.1719C60.0293 17.2502 60.5339 17.6425 60.9045 17.9652' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M50.3934 17.5509C50.4417 17.556 50.4173 17.5455 50.4111 17.6287' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M48.8008 31.356C48.6869 31.2394 48.075 31.4258 47.9527 31.4589' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M30.9021 35.6019C30.9027 35.5591 30.894 35.5644 30.9113 35.5345' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M39.9548 21.8694C39.8073 21.8561 39.6989 22.0285 39.5771 22.1012C39.2115 22.3207 38.8602 22.4946 38.5838 22.8472' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M37.9192 23.6467C37.9169 23.6715 37.9048 23.6881 37.8925 23.7094' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.762 41.3048C28.7583 41.3014 28.7546 41.298 28.7508 41.2946' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.198 37.2078C36.0317 37.104 35.5697 37.4577 35.4003 37.5031' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.167 36.52C36.02 36.588 35.8345 36.6153 35.6773 36.6571' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M66.4864 15.6502C66.5095 15.6737 66.4957 15.6625 66.529 15.6817' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.6919 47.485C69.6071 47.6023 69.6143 47.9157 69.6054 48.0661' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M62.0903 19.2658C62.1109 19.2619 62.0984 19.2671 62.1015 19.2267' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M74.15 18.4344C74.1273 18.4431 74.1594 18.44 74.1765 18.4412' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M75.785 20.0842C75.8541 20.0764 75.8654 20.0679 75.9117 20.1215' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.2474 25.3084C83.9447 25.7752 84.6168 26.5655 84.9766 27.3414' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.2253 26.6269C77.318 26.5268 76.3522 26.8904 76.171 26.965' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M76.7082 25.7874C76.7305 25.6532 75.8876 26.0751 75.733 26.209' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M70.8324 32.3909C70.896 32.419 70.9236 32.3778 70.9179 32.4537' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M66.771 31.3263C66.8386 31.3167 66.7475 31.4274 66.747 31.4337' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.1851 32.5286C67.2096 32.5202 67.1977 32.5199 67.1949 32.5621' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.0893 44.3466C64.0922 44.3492 64.0951 44.3517 64.098 44.3543' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.3364 23.1633C59.5374 23.162 59.8977 23.7933 60.0151 23.929' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M60.932 24.7381C60.9494 24.7684 60.9367 24.7485 60.9755 24.7933' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M105.905 98.668C105.905 98.668 105.095 93.9518 104.19 91.9834C102.472 88.2463 101.163 86.5641 101.163 86.5641C101.163 86.5641 102.96 78.9936 103.233 75.1302C103.516 71.1463 102.859 62.662 102.859 62.662L104.415 61.9823L111.35 70.7501L116.478 82.8893L117.215 91.362L105.905 98.668Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M76.1147 132.466L79.0858 130.179L80.8272 120.541L76.563 122.261L76.1147 132.466Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M82.1001 101.216L84.6632 99.1151L86.7534 88.4757L84.126 90.1774L82.1001 101.216Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M84.1472 90.1498L81.9918 101.224C81.9918 101.224 88.3173 103.416 97.1015 102.339C99.851 102.002 104.923 101.28 109.183 99.396C113.724 97.3879 118.087 92.9967 118.087 92.9967C118.087 92.9967 117.238 96.6625 112.395 106.242C110.859 109.279 108.985 113.659 105.853 116.02C102.3 118.698 95.935 121.408 91.0709 122.313C85.7428 123.304 77.8196 123.247 77.8196 123.247L76.0458 132.438C76.0458 132.438 68.6965 126.156 62.1132 120.612C57.8336 117.007 53.6512 114.502 53.6139 111.621C53.5752 108.625 57.6246 107.256 63.7834 103.41C72.6761 97.8573 84.1472 90.1498 84.1472 90.1498Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M82.0153 101.226L84.5549 99.1515C84.5549 99.1515 93.591 100.274 99.0713 99.1811C106.358 97.728 113.471 94.5536 115.173 91.1488C116.036 89.4227 116.051 86.1772 115.001 82.5953C114.226 79.9494 112.726 77.1463 111.302 74.4996C107.76 67.9187 102.828 62.5942 102.828 62.5942L105.148 61.205C105.913 61.3614 106.971 62.6447 108.584 64.6343C110.747 67.3021 113.67 71.2666 115.93 75.7397C118.132 80.0984 118.985 82.6552 118.965 87.865C118.958 89.4338 118.632 93.3754 116.845 94.8664C114.236 97.0433 109.36 99.9187 104.442 101.146C97.8027 102.803 90.5512 103.039 87.0866 102.497C84.2182 102.049 82.0153 101.226 82.0153 101.226Z' fill='#001C20' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M71.7847 102.658C71.7574 102.77 71.7147 102.808 71.6711 102.699' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.4232 120.681C67.4249 120.683 67.4315 120.678 67.4295 120.677C67.2746 120.571 67.1309 120.494 66.9848 120.365C66.696 120.11 66.303 119.896 65.9648 119.721' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.8722 118.983C64.8401 118.994 64.8229 119.015 64.8095 118.981' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.198 119.344C79.5824 118.814 79.7466 118.234 79.7994 117.583' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.0938 119.317C80.2974 119.113 80.4244 118.615 80.4733 118.339' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M78.3875 117.476C78.3985 117.517 78.3859 117.514 78.4178 117.501' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M60.3014 109.368C60.2911 109.368 60.2982 109.367 60.3071 109.389' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M67.8916 112.74C67.6129 112.815 66.8978 112.579 66.6238 112.462' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.7697 111.724C64.7911 111.737 64.8035 111.725 64.8261 111.716' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M70.4376 106.329C70.4217 106.341 70.4135 106.337 70.4253 106.366' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M74.481 125.729C74.5667 125.728 74.7531 125.104 74.7762 125.026' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M75.0552 123.526C75.0357 123.481 75.0637 123.488 75.0468 123.447' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.8837 127.503C77.8959 127.538 77.9178 127.531 77.9045 127.498' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M78.4385 125.897C78.5218 125.797 78.6158 125.699 78.7202 125.623' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.8965 96.1967C83.8944 96.1929 83.8916 96.1894 83.889 96.1859' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.9682 102.137C79.8366 102.162 78.9655 101.78 78.7644 101.732' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.574 101.072C79.4238 101.075 79.3445 101.119 79.2021 100.993' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M58.0974 113.351C58.3642 113.172 58.2532 112.883 58.071 112.669' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M57.1909 112.557C57.1685 112.499 57.1753 112.512 57.1788 112.464' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.6863 112.512C85.186 112.458 85.2277 111.446 85.1001 111.13' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.6857 110.836C83.6944 110.87 83.6839 110.866 83.7138 110.854' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.5826 108.012C77.5869 108.052 77.5837 108.027 77.595 108.087' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M100.497 107.597C100.441 107.705 100.577 107.554 100.598 107.523' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.075 90.6442C110.187 90.7404 110.215 90.7322 110.293 90.6168' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.838 82.6972C108.832 82.7165 108.486 81.8835 108.429 81.5825' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.913 79.2527C107.91 79.2548 107.906 79.2572 107.903 79.2593' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M113.387 86.0515C113.448 85.9784 113.47 85.9364 113.521 85.8617' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.77 102.39C110.458 102.773 109.866 103.014 109.401 103.173' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M115.016 94.2762C114.988 94.3155 115.009 94.2996 114.94 94.2526' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M103.855 85.9945C104.013 85.9542 104.158 85.3311 104.19 85.2086' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M109.129 68.3727C109.166 68.3946 109.166 68.3845 109.183 68.4144' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M116.494 83.0771C116.579 82.9561 116.484 82.7759 116.482 82.6341C116.474 82.2078 116.499 81.8166 116.332 81.4009' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M115.973 80.424C115.952 80.4096 115.944 80.3908 115.932 80.3695' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.263 63.6671C105.267 63.6656 105.272 63.664 105.277 63.6625' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.089 72.153C105.262 72.061 105.186 71.484 105.232 71.3145' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.703 72.4731C105.717 72.3119 105.786 72.1376 105.829 71.9805' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.614 109.164C108.582 109.173 108.599 109.166 108.565 109.185' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.4402 96.0218C79.381 95.8897 79.1059 95.7392 78.9801 95.6563' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.681 103.547C107.674 103.567 107.676 103.554 107.71 103.577' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.373 114.412C102.377 114.388 102.364 114.418 102.354 114.432' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M100.126 114.999C100.098 115.062 100.1 115.076 100.031 115.09' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.8715 118.853C91.1186 119.223 90.0982 119.41 89.2464 119.334' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.7407 112.977C93.7811 113.107 93.9491 112.089 93.9751 111.895' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.7233 112.949C94.8283 113.036 94.8844 112.095 94.8458 111.894' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.9417 104.559C91.8855 104.6 91.9074 104.644 91.8446 104.601' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.8984 101.571C94.8729 101.634 94.8226 101.5 94.8174 101.497' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.6468 101.328C93.6418 101.353 93.648 101.343 93.6128 101.32' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.96 92.7414C84.9564 92.7426 84.9527 92.7439 84.9491 92.7451' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.684 99.2145C105.585 99.3892 104.858 99.3856 104.682 99.4194' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M103.521 99.8099C103.487 99.8098 103.51 99.8088 103.452 99.82' stroke='#5CC87F' strokeWidth='1' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
