import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppType } from '../constants';
import { EquivalencyGraphics } from '../constants/equivalencies';
import { WebServiceHelper } from '../lib/webServiceHelper';
import { BaseModel } from './base';
import { CollectionModel } from './collection';
import { ICompanyRef } from './companies';
import { IOffsetDonation } from './offsets';
import { IStatementRef, StatementModel } from './statements';
import { IUserGroup, UserGroupRole, UserGroupStatus } from './user-groups';
import { IUser, UserModel } from './users';

dayjs.extend(utc);

type PrivateGroupMemberFields = '_busy' |
'_member';

type PrivateGroupFields = '_busy' |
'_equivalency' |
'_group' |
'_init' |
'_loadingEquivalency' |
'_loadingMembers' |
'_loadingMembersOffsetData' |
'_membersOffsetData' |
'_members' |
'_offsetStatements' |
'_owner';

type PrivateGroupsFields = '_busy' |
'_groups' |
'_loadingSummary' |
'_summary';

export enum GroupCodeAvailability {
  Available = 'available',
  Unavailable = 'unavailable',
}

export enum GroupPrivacyStatus {
  Protected = 'protected',
  Public = 'public',
  Private = 'private'
}

export enum GroupStatus {
  Open = 'open',
  Locked = 'locked',
}

export interface IEquivalencyObject {
  text: string;
  icon: EquivalencyGraphics;
  textNoQuantity: string;
  quantity: number;
}

export interface ITotalDonationsObject {
  dollars: number;
  tonnes: number;
}
export interface IGroupEquivalency {
  useAverageAmericanEmissions: boolean;
  equivalency: IEquivalencyObject;
  totalDonations: ITotalDonationsObject;
  averageAmericanEmissions: {
    monthly: number;
    annually: number;
  };
}

export interface IGroupIntegrations {
  integrations: {
    rare: {
      type: {
        groupId: string,
      },
    },
  },
}

export interface IGroupMatching {
  enabled: boolean,
  matchPercentage: number;
  maxDollarAmount: number;
  lastModified?: Date;
}

export interface IGroupSettings {
  privacyStatus: GroupPrivacyStatus;
  allowInvite: boolean;
  allowDomainRestriction: boolean;
  allowSubgroups: boolean;
  approvalRequired: boolean;
  matching: IGroupMatching;
}

export interface IGroupCodeStatus {
  available: boolean,
  isValid: boolean,
}

export interface IGroupOwner {
  _id: string;
  name: string;
}

export interface IGroup {
  _id: string;
  code: string;
  company?: string | ICompanyRef,
  createdOn: Date;
  domains: string[];
  integrations?: IGroupIntegrations;
  invites?: string[];
  lastModified: Date;
  logo?: string;
  name: string;
  owner: string | IGroupOwner;
  settings: IGroupSettings;
  status: GroupStatus;
  totalMembers: number;
  defaultGroupDonation: IDonationAllocation;
}

export interface IGroupMember {
  _id: string;
  name: string;
  email: string;
  role: UserGroupRole;
  status: UserGroupStatus;
  joinedOn: Date;
}

export interface IGroupMembersOffsetData {
  userGroup: IUserGroup;
  members: number;
  membersWithDonations: number;
  groupDonations: IOffsetDonation;
  memberDonations: IOffsetDonation;
  totalDonations: IOffsetDonation;
}

export interface IGroupsSummary {
  total: number;
  locked: number;
  private: number;
  protected: number;
  public: number;
}

export const defaultGroupSettings: IGroupSettings = {
  privacyStatus: GroupPrivacyStatus.Private,
  allowInvite: false,
  allowDomainRestriction: false,
  allowSubgroups: false,
  approvalRequired: false,
  matching: {
    enabled: false,
    matchPercentage: null,
    maxDollarAmount: null,
  },
};

export interface IDonationAllocation {
  amount: string;
  donationRecipient: {
    _id: string;
    name: string;
  };
}

export const MAX_CODE_LENGTH = 16;

export class GroupMemberModel extends BaseModel {
  private _busy = false;
  private _member: IGroupMember = null;

  constructor (memberInfo: IGroupMember) {
    super();
    makeObservable<GroupMemberModel, PrivateGroupMemberFields>(this, {
      _busy: observable,
      _member: observable,
      busy: computed,
      _id: computed,
      name: computed,
      email: computed,
      role: computed,
      status: computed,
      joinedOn: computed,
    });
    this._member = memberInfo;
  }

  get busy() { return this._busy; }
  get _id() { return this._member._id; }
  get name() { return this._member.name; }
  get email() { return this._member.email; }
  get role() { return this._member.role; }
  get status() { return this._member.status; }
  get joinedOn() { return dayjs(this._member.joinedOn).local(); }
  get isActiveMember() {
    return this.status !== UserGroupStatus.Removed
    && this.status !== UserGroupStatus.Banned
    && this.status !== UserGroupStatus.Left;
  }

  static hasPermissionToModify = (modifierRole: UserGroupRole, midifyeeRole: UserGroupRole) => {
    if (midifyeeRole === UserGroupRole.Owner) return false;
    if (midifyeeRole === UserGroupRole.SuperAdmin && modifierRole !== UserGroupRole.Owner) return false;
    if (midifyeeRole === UserGroupRole.Admin && modifierRole !== UserGroupRole.Owner && modifierRole !== UserGroupRole.SuperAdmin) return false;

    return true;
  };
}

class BaseGroupModel extends BaseModel {
  public checkingCode = false;

  constructor(app: AppType) {
    super({}, null, app);
    makeObservable<BaseGroupModel>(this, {
      checkingCode: observable,
      checkCode: action.bound,
    });
  }

  public checkCode = async (code: string) => {
    if (this.checkingCode) return;
    this.checkingCode = true;

    const result = await this.webServiceHelper.sendRequest<IGroupCodeStatus>({
      path: `/group/check-code?code=${ code }`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this.checkingCode = false;
      });

      return {
        ...result.value,
        available: result.value.available ? GroupCodeAvailability.Available : GroupCodeAvailability.Unavailable,
      };
    } else {
      runInAction(() => {
        this.checkingCode = false;
      });

      throw new Error(result.error);
    }
  };
}

export class GroupModel extends BaseGroupModel {
  private _busy = false;
  private _equivalency: IGroupEquivalency = null;
  private _group: IGroup = null;
  private _loadingEquivalency = false;
  private _loadingMembers = false;
  private _loadingMembersOffsetData = false;
  private _membersOffsetData: IGroupMembersOffsetData = null;
  private _members: CollectionModel<IGroupMember, GroupMemberModel> = null;
  private _offsetStatements: CollectionModel<IStatementRef, StatementModel> = null;
  private _owner: UserModel = null;
 
  constructor (groupInfo: IGroup, app?: AppType) {
    super(app);
    makeObservable<GroupModel, PrivateGroupFields>(this, {
      _busy: observable,
      _equivalency: observable,
      _group: observable,
      _loadingEquivalency: observable,
      _loadingMembers: observable,
      _loadingMembersOffsetData: observable,
      _members: observable,
      _membersOffsetData: observable,
      _offsetStatements: observable,
      _owner: observable,
      _id: computed,
      busy: computed,
      code: computed,
      company: computed,
      createdOn: computed,
      defaultGroupDonation: computed,
      domains: computed,
      equivalency: computed,
      integrations: computed,
      invites: computed,
      loadingEquivalency: computed,
      lastModified: computed,
      loadingMembers: computed,
      loadingMembersOffsetData: computed,
      logo: computed,
      members: computed,
      membersOffsetData: computed,
      name: computed,
      offsetStatements: computed,
      owner: computed,
      settings: computed,
      status: computed,
      delete: action.bound,
      _init: action.bound,
      loadEquivalency: action.bound,
      loadMembersOffsetData: action.bound,
      reset: action.bound,
      update: action.bound,
    });

    this._init(groupInfo);
  }

  get _id() { return this._group._id; }
  get busy() { return this._busy; }
  get code() { return this._group.code; }
  get company() { return this._group.company; }
  get createdOn() { return dayjs(this._group.createdOn).local(); }
  get defaultGroupDonation() { return this._group?.defaultGroupDonation || null; }
  get domains() { return this._group.domains || []; }
  get equivalency() { return this._equivalency || ({} as IGroupEquivalency); }
  get integrations() { return this._group.integrations || {}; }
  get invites() { return this._group.invites || []; }
  get lastModified() { return dayjs(this._group.lastModified).local(); }
  get loadingEquivalency() { return this._loadingEquivalency; }
  get loadingMembers() { return this._loadingMembers; }
  get loadingMembersOffsetData() { return this._loadingMembersOffsetData; }
  get logo() { return this._group.logo; }
  get members() { return this._members; }
  get membersOffsetData() { return this._membersOffsetData || ({} as IGroupMembersOffsetData); }
  get name() { return this._group.name; }
  get offsetStatements() { return this._offsetStatements; }
  get owner() { return this._owner; }
  get settings() { return this._group.settings; }
  get status() { return this._group.status; }
  get totalMembers() { return this._group.totalMembers; }

  public toJs() { this._group; }

  public delete = async () => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IGroup>({
      path: `/group/${this._id}`,
      method: 'DELETE',
    });

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public loadEquivalency = async () => {
    if (this._loadingEquivalency || !!this._equivalency) return;
    this._loadingEquivalency = true;

    const result = await this.webServiceHelper.sendRequest<IGroupEquivalency>({
      path: `/group/${this._id}/offset-equivalency`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._equivalency = result.value;
        this._loadingEquivalency = false;
      });
    } else {
      runInAction(() => {
        this._loadingEquivalency = false;
      });

      throw new Error(result.error);
    }
  };

  public loadMembersOffsetData = async () => {
    if (this._loadingMembersOffsetData || !!this._membersOffsetData) return;
    this._loadingMembersOffsetData = true;

    const result = await this.webServiceHelper.sendRequest<IGroupMembersOffsetData>({
      path: `/group/${this._id}/offset-data`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._membersOffsetData = result.value;
        this._loadingMembersOffsetData = false;
      });
    } else {
      runInAction(() => {
        this._loadingMembersOffsetData = false;
      });

      throw new Error(result.error);
    }
  };

  public reset = () => {
    this._busy = false;
    this._equivalency = null;
    this._group = null;
    this._loadingEquivalency = false;
    this._loadingMembers = false;
    this._loadingMembersOffsetData = false;
    this._membersOffsetData = null;
    this._members = null;
    this._offsetStatements = null;
    this._owner = null;
  };

  public updateMemberStatus = async (memberId: string, status: UserGroupStatus) => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IGroup>({
      path: `/group/${this._id}/user/${ memberId }`,
      method: 'PUT',
      data: { status },
    });

    if (result.success) {
      runInAction(async () => {
        this._busy = false;
        await this.members.refresh();
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public updateMembersStatus = async (status: UserGroupStatus, memberIds: string[] = []) => {
    if (this._busy) return true;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IGroup>({
      path: `/group/${this._id}/members`,
      method: 'PUT',
      data: { status, memberIds },
    });

    if (result.success) {
      runInAction(async () => {
        this._busy = false;
        await this.members.refresh();
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public update = async (data: Partial<IGroup>) => {
    if (this._busy) return;
    this._busy = true;

    if (data.settings?.matching) {
      data.settings.matching = {
        ...this.settings.matching,
        ...data.settings.matching,
      };
    }

    const result = await this.webServiceHelper.sendRequest<IGroup>({
      path: `/group/${this._id}`,
      method: 'PUT',
      data,
    });

    if (result.success) {
      runInAction(() => {
        this._group = result.value;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  private _init = (groupInfo: IGroup) => { 
    this._group = groupInfo;
    this._owner = new UserModel('/user', this._group.owner as IUser);
    this._members = new CollectionModel<IGroupMember, GroupMemberModel>(
      `/group/${this._id}/members`,
      (memberInfo: IGroupMember) => new GroupMemberModel(memberInfo),
      {},
      this._app,
    );
    this._offsetStatements = new CollectionModel<IStatementRef, StatementModel>(
      `/group/${this._id}/offset-statements`,
      (statementInfo: IStatementRef) => new StatementModel(statementInfo),
      { limit: 50 },
      this._app,
    );
  };

  static getGroupByCode = async (code: string) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IGroup>({
      path: `/group?code=${ code }`,
      method: 'GET',
    });

    if (result.success) {
      return new GroupModel(result.value);
    } else {
      throw new Error(result.error);
    }
  };

  static load = async (id: string, app?: AppType) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IGroup>({
      path: `/group/${id}`,
      method: 'GET',
    });

    if (result.success) {
      return new GroupModel(result.value, app);
    } else {
      throw new Error(result.error);
    }
  };
}

export class GroupsModel extends BaseGroupModel {
  private _busy = false;
  private _groups: CollectionModel<IGroup, GroupModel> = null;
  private _loadingSummary = false;
  private _summary: IGroupsSummary = null;

  constructor (app?: AppType) {
    super(app);
    makeObservable<GroupsModel, PrivateGroupsFields>(this, {
      _busy: observable,
      _groups: observable,
      _loadingSummary: observable,
      _summary: observable,
      busy: computed,
      groups: computed,
      summary: computed,
      createGroup: action.bound,
      loadSummary: action.bound,
    });
    this._init();
  }

  get busy() { return this._busy; }
  get groups() { return this._groups; }
  get loadingSummary() { return this._loadingSummary; }
  get summary() { return this._summary; }

  public createGroup = async (data: Partial<IGroup>) => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IGroup>({
      path: '/group',
      method: 'POST',
      data,
    });

    if (result.success) {
      runInAction(() => {
        this._groups.refresh();
        this.loadSummary();
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public loadSummary = async () => {
    if (this._loadingSummary) return;
    this._loadingSummary = true;

    const result = await this.webServiceHelper.sendRequest<IGroupsSummary>({
      path: '/admin/groups/summary',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._summary = result.value;
        this._loadingSummary = false;
      });
    } else {
      runInAction(() => {
        this._loadingSummary = false;
      });

      throw new Error(result.error);
    }
  };

  private _init = () => {
    this._groups = new CollectionModel<IGroup, GroupModel>(
      '/groups',
      (groupInfo: IGroup) => new GroupModel(groupInfo, this._app),
    );
  };
}
