import React, { useCallback } from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { ButtonKind } from '../../Button/styles';
import { CtaType } from '../../CTAs';
import { CoinIcon } from '../../svgs/icons/CoinIcon';
import { CashbackInfoModalContainer } from './styles';
import { FAQSections } from '../../../pages/FAQs';

interface IProps extends IThemeProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onCTAClick: () => void;
  hasKarmaCard: boolean;
}

const CashbackInfoModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  onCTAClick,
  theme,
  hasKarmaCard,
}) => {
  const CTAs = [
    { 
      text: 'Learn More',
      url: `/faqs?scrollTo=${FAQSections.KWCardCashback}`,
      kind: ButtonKind.Primary,
      ctaType: CtaType.Link,
      openNewTab: true,
      onClick: onCTAClick,
      id: 'cashback-learn-more-cta',
    },
    { 
      text: 'Apply for Karma Wallet Card',
      url: '/app',
      kind: ButtonKind.Primary,
      ctaType: CtaType.Link,
      openNewTab: false,
      onClick: onCTAClick,
      id: 'cashback-apply-cta',
    },
  ];

  const CTA = useCallback(() => {
    const CTAcontent = [];

    if (!hasKarmaCard) {
      CTAcontent.push(CTAs[1]);
    } else {
      CTAcontent.push(CTAs[0]);
    }

    return CTAcontent;
  }, [hasKarmaCard]);

  return (
    <CashbackInfoModalContainer
      className={ className }
      isOpen={ isOpen }
      onClose={ onClose }
      graphic={ <CoinIcon stroke={ theme.colors.darkGray1 } /> }
      title='How Cashback Works'
      body='Earn cashback on qualifying purchases at select sustainable companies. Cashback is automatically credited to your Karma Wallet Card on a monthly basis.'
      ctas={ CTA() }
    />
  );
};

export const CashbackInfoModal = withTheme(CashbackInfoModalBase);
