import React, { useCallback, useEffect, useMemo } from 'react';
import { BannerContentWrapper, BannerItemContainer } from './styles';
import { IBanner, IBannerColors } from '../../../models/banners';
import { observer } from 'mobx-react';
import { XIcon } from '../../svgs/icons/XIcon';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { Button, ButtonLink } from '../../Button';
import { ButtonKind } from '../../Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';
import { useBanners } from '../../../contexts/banners';

interface IProps extends IThemeProps {
  className?: string;
  banner: IBanner;
}

const BannerItemBase: React.FC<IProps> = ({
  className = '',
  banner,
  theme,
}) => {
  const analytics = useAnalytics();
  const bannersModel = useBanners();
  const { text, link, linkText, color } = banner;

  useEffect(() => {
    analytics.fireEvent('MarketingBanner_View', `${ banner.name }`);
  }, []);

  const strokeColor = useMemo(() => {
    if (color === IBannerColors.Dark) return theme.colors.white;
    return theme.colors.darkGray1;
  }, [banner]);

  const buttonKind = useMemo(() => {
    if (color === IBannerColors.Dark) return ButtonKind.Secondary;
    return ButtonKind.Primary;
  }, [banner]);

  const onCtaClick = useCallback(() => {
    analytics.fireEvent('MarketingBanner_CTA_Click', `${ banner.name }`);
  }, []);

  const onCloseClick = useCallback(() => {
    bannersModel.setBannerSectionShow(false);
    analytics.fireEvent('MarketingBanner_Close', `${ banner.name }`);
    localStorage.setItem(`marketing-banner-${bannersModel.activeBanner._id}`, 'closed');
  }, []);

  const content = useMemo(() => (
    <BannerContentWrapper>
      <span className='banner-span'>
        { 
          link ? (
            <ButtonLink
              onClick={ onCtaClick }
              href={ link }
              kind={ ButtonKind.Blank }
              label={ linkText }
              target='_blank'
            >
              <div className='text-wrapper' dangerouslySetInnerHTML={ { __html: text } } />
            </ButtonLink>
          ) 
            : <div className='text-wrapper' dangerouslySetInnerHTML={ { __html: text } } />
        }
        {
          link && linkText ? (
            <div className='button-wrapper'>
              <ButtonLink
                className='desktop-cta'
                onClick={ onCtaClick }
                href={ link }
                kind={ ButtonKind.Primary }
                target='_blank'
                rel='noreferrer'
              >
                { linkText }
              </ButtonLink>
              <a
                className='mobile-cta'
                onClick={ onCtaClick }
                href={ link }
                target='_blank'
                rel='noreferrer'
              >
                { linkText }
              </a>
            </div>
          )
            : null
        }
      </span>
    </BannerContentWrapper>
  ), [banner, buttonKind]);

  if (!banner || !bannersModel.showBanner) return null;

  return (
    <BannerItemContainer className={ className }>
      { content }
      <Button
        onClick={ onCloseClick } 
        kind={ ButtonKind.Blank }
        label='Close Banner'
      >
        <XIcon
          stroke={ strokeColor }
        />
      </Button>
    </BannerItemContainer>
  );
};

const BannerItemObserver = observer(BannerItemBase);
export const BannerItem = withTheme(BannerItemObserver);
