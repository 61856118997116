import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const UnsdgNeutralIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }neutral-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    fill={ fill }
  >
    <path d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z' fill={ fill } />
    <path d='M15.3114 9H5V10.7091H15.3114V9Z' fill='white' />
  </SvgIcon>
);
