import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CompanyCashbackContainer, CompanyCashbackContainerInner, CurrentOffersContainer, HeaderWrapperContainer, CashbackTagWrapper, CashbackCardTop, CashbackCardsContainer, CashbackCard, ProtipContainer } from './styles';
import { ButtonKind } from '../Button/styles';
import { Button, ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { CompanyModel, MerchantRateType } from '../../models/companies';
import { observer } from 'mobx-react';
import { useUserSession } from '../../contexts/user';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { CashbackInfoModal } from '../modals/CashbackInfoModal';
import { H2 } from '../../styles/components/header';
import { buildWildfireCashbackUrl } from '../../lib/urlBuilders';
import { useSignInModal } from '../../contexts/sign-in';
import { SignInMode } from '../../models/sign-in';
import { TooltipInfoIcon } from '../svgs/icons/TooltipInfoIcon';
import { useNavigate } from 'react-router';
import { CompanyCashbackModal } from '../modals/CompanyCashbackModal';
import { UserEventMedium, UserEventModel, UserEventName, UserEventType } from '../../models/userEvents';

interface IProps extends IThemeProps {
  className?: string;
  company: CompanyModel;
}

export enum IntegrationType {
  Wildfire = 'wildfire',
  Kard = 'kard',
}

const CompanyCashbackOffersBase: React.FC<IProps> = ({
  className,
  company,
  theme,
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const signInModal = useSignInModal();
  const navigate = useNavigate();
  const [startShoppingClicked, setStartShoppingClicked] = useState(false);
  const [linkCardClicked, setLinkCardClicked] = useState(false);
  const [cashbackInfoModalOpen, setCashbackInfoModalOpen] = useState(false);
  const [wildfireCashbackDetailsModalOpen, setWildfireCashbackDetailsModalOpen] = useState(false);
  const [kardCashbackDetailsModalOpen, setKardCashbackDetailsModalOpen] = useState(false);
  const userEventModel = useRef(new UserEventModel()).current;
  const shoppingUrl = useMemo(() => {
    if (!user._id || !company.merchant) return '';
    let builtUrl;
    if (!!company.merchant.integrations.wildfire) {
      builtUrl = buildWildfireCashbackUrl(company.merchant.integrations.wildfire, user._id);
    } else {
      builtUrl = company.url;
    }
    return builtUrl;
  }, [user._id, user, company.merchant, company]);

  useEffect(() => {
    user.loadCards()
      .catch(() => {
        analytics.fireEvent('CompanyCashbackOffers_LoadCards_Error');
      });
  }, [user._id]);

  useEffect(() => {
    if (!!linkCardClicked && !!user._id) navigate('/account');
  }, [user._id, linkCardClicked]);

  useEffect(() => {
    if (!!startShoppingClicked && !!user._id) window.open(`${shoppingUrl}`, '_blank');
  }, [user._id, startShoppingClicked]);

  const createCompanyClickEvent = async () => {
    if (!user?.isLoggedIn) return;
    await userEventModel.createAndSetEvent({
      type: UserEventType.CLICK,
      name: UserEventName.BrowseCompanies,
      medium: UserEventMedium.BROWSE,
      data: {
        merchantIds: [company?.merchant._id],
      },
    });
  };
  // Info Icon Modal
  const onCashbackInfoClick = useCallback(() => {
    setCashbackInfoModalOpen(true);
    analytics.fireEvent('Company_CashbackInfo_Click');
    createCompanyClickEvent();
  }, []);

  const onCashbackInfoModalCTAClick = useCallback(() => {
    analytics.fireEvent('Company_CashbackInfoModal_CTA_Click');
    setCashbackInfoModalOpen(false);

    createCompanyClickEvent();
  }, []);

  const onCloseCashbackInfoModal = useCallback(() => {
    analytics.fireEvent('Company_ViewCashbackInfo_Close');
    setCashbackInfoModalOpen(false);
  }, []);

  //offers modals
  const onWildfireCashbackDetailsClick = useCallback(() => {
    analytics.fireEvent('Company_WildfireCashbackDetails_Click');
    setWildfireCashbackDetailsModalOpen(!wildfireCashbackDetailsModalOpen);
    createCompanyClickEvent();
  }, []);

  const onCloseWildfireCashbackDetails = useCallback(() => {
    analytics.fireEvent('Company_WildfireCashbackDetails_Close');
    setWildfireCashbackDetailsModalOpen(false);
  }, []);

  const onKardCashbackDetailsClick = useCallback(() => {
    analytics.fireEvent('Company_KardCashbackDetails_Click');
    setKardCashbackDetailsModalOpen(!kardCashbackDetailsModalOpen);
    createCompanyClickEvent();
  }, []);

  const onCloseKardCashbackDetails = useCallback(() => {
    analytics.fireEvent('Company_KardCashbackDetails_Close');
    setKardCashbackDetailsModalOpen(false);
  }, []);

  const onStartShoppingClick = useCallback(() => {
    createCompanyClickEvent();
    setLinkCardClicked(false);
    if (!user.isLoggedIn) {
      signInModal.open(SignInMode.SIGN_IN);
      setStartShoppingClicked(true);
      analytics.fireEvent('Company_StartShopping_Login', `${company._id}`);
    } else {
      window.open(`${shoppingUrl}`, '_blank');
      setWildfireCashbackDetailsModalOpen(false);
      setKardCashbackDetailsModalOpen(false);
    }
    analytics.fireEvent('Company_StartShopping_Click', `${company._id}`);
  }, [user.hasPayPal, company, shoppingUrl]);

  const finalAmount = (amountType: MerchantRateType, amount: number) => (
    amountType === MerchantRateType.Percent ? `${amount}%` : `$${amount}`
  );

  const doubleOffers: boolean = !!company.merchant?.integrations?.kard?.maxRate?.amount && !!company.merchant?.integrations?.wildfire?.maxRate?.amount;

  const cashbackInfoModal = useMemo(() => (
    <CashbackInfoModal
      isOpen={ cashbackInfoModalOpen }
      onClose={ onCloseCashbackInfoModal }
      onCTAClick={ onCashbackInfoModalCTAClick }
      hasKarmaCard={ user.hasKarmaWalletCard }
    />
  ), [cashbackInfoModalOpen, onCloseCashbackInfoModal, company, user.hasKarmaWalletCard]);

  const wildfireCashbackDetailsModal = useMemo(() => (
    <CompanyCashbackModal
      isOpen={ wildfireCashbackDetailsModalOpen }
      company={ company }
      onClose={ onCloseWildfireCashbackDetails }
      onClick={ onStartShoppingClick }
      isLoggedIn={ user.isLoggedIn }
      integration={ IntegrationType.Wildfire }
    />
  ), [wildfireCashbackDetailsModalOpen, user.isLoggedIn, CompanyCashbackOffersObserver, company]);

  const kardCashbackDetailsModal = useMemo(() => (
    <CompanyCashbackModal
      isOpen={ kardCashbackDetailsModalOpen }
      company={ company }
      onClose={ onCloseKardCashbackDetails }
      onClick={ onStartShoppingClick }
      hasKarmaCard={ user.hasKarmaWalletCard }
      isLoggedIn={ user.isLoggedIn }
      integration={ IntegrationType.Kard }
    />
  ), [kardCashbackDetailsModalOpen, user.isLoggedIn, CompanyCashbackOffersObserver, company]);

  const header = useMemo(() => (
    <HeaderWrapperContainer>
      <H2 className='cashback-title' id='cashback-offers'>Cashback Offers</H2>
      <Button
        kind={ ButtonKind.Blank }
        onClick={ onCashbackInfoClick }
        label='View Info about Cashback Offers'
      >
        <TooltipInfoIcon fill={ theme.colors.darkGray1 } />
      </Button>
    </HeaderWrapperContainer>
  ), []);

  const cashbackCard = useCallback((amount: string, integration: IntegrationType) => {
    const description = 'when you use your Karma Wallet Card';
    const cashbackModal = integration === IntegrationType.Wildfire ? onWildfireCashbackDetailsClick : onKardCashbackDetailsClick;
    const newCustomerOnlyWild = integration === IntegrationType.Wildfire && !!company.merchant?.integrations?.wildfire?.PaysNewCustomersOnly ? <div className='new-customers-only'>*New customers only</div> : null;

    return (
      <CashbackCard>
        <CashbackCardTop>
          <div className='up-to'>Up to</div>
          <Button
            kind={ ButtonKind.Blank }
            onClick={ cashbackModal }
            label='View Info about Cashback Offers'
            className='cashback-offers'
          >
            <TooltipInfoIcon fill={ theme.colors.darkGray1 } />
          </Button>
          <CashbackTagWrapper>
            <div className='max-rate-tag large-tag'>{ amount }</div>
          </CashbackTagWrapper>
          <div className='description'>{ description }</div>
        </CashbackCardTop>
        {
          user.isLoggedIn && user.hasKarmaWalletCard && !doubleOffers ?
            <Button
              kind={ ButtonKind.GhostMarketing }
              onClick={ onStartShoppingClick }
              className='start-shopping-cashback tesing'
            >
              Start Shopping
            </Button>
            : null
        }
        { newCustomerOnlyWild }
      </CashbackCard>
    );
  }, [user.hasKarmaWalletCard, user.isLoggedIn]);

  const wildCashbackCard = useMemo(() => {
    if (!company.merchant?.integrations?.wildfire?.maxRate?.amount) return;
    const { type, amount } = company.merchant.integrations.wildfire.maxRate;

    return cashbackCard(finalAmount(type, amount), IntegrationType.Wildfire);
  }, [company, user.isLoggedIn, user.hasKarmaWalletCard]);

  const kardCashbackCard = useMemo(() => {
    if (!company.merchant?.integrations?.kard?.maxRate?.amount) return;
    const { type, amount } = company.merchant.integrations.kard.maxRate;

    return cashbackCard(finalAmount(type, amount), IntegrationType.Kard);
  }, [company, user.isLoggedIn, user.hasKarmaWalletCard]);

  const cashbackContent = useMemo(() => (
    <CurrentOffersContainer>
      { header }
      <CashbackCardsContainer>
        { wildCashbackCard }
        { kardCashbackCard }
      </CashbackCardsContainer>
      {
        !user.isLoggedIn || (user.isLoggedIn && !user.hasKarmaWalletCard) ? (
          <div className='apply-wrapper'>
            <div className='apply-text'>Ready to start earning up to 20% cashback on select sustainable purchases?</div>
            <ButtonLink
              href='/app'
              kind={ ButtonKind.Primary }
            >
              Get the Karma Wallet Card
            </ButtonLink>
          </div>
        )
          : null
      }
      {
        user.isLoggedIn && doubleOffers && user.hasKarmaWalletCard ?
          <>
            <ProtipContainer>
              <div className='pro-tip'>Pro-Tip:</div>
              <div>Shop through Karma Wallet by clicking "Start Shopping" and use your Karma Wallet card to maximize your cashback.</div>
            </ProtipContainer>
            <Button
              kind={ ButtonKind.Primary }
              onClick={ onStartShoppingClick }
              className='start-shopping-double'
            >
              Start Shopping
            </Button>
          </>
          : null
      }
    </CurrentOffersContainer>
  ), [user.isLoggedIn, user.hasKarmaWalletCard]);

  return (
    <CompanyCashbackContainer className={ className }>
      { wildfireCashbackDetailsModal }
      { kardCashbackDetailsModal }
      { cashbackInfoModal }
      <CompanyCashbackContainerInner>
        { cashbackContent }
      </CompanyCashbackContainerInner>
    </CompanyCashbackContainer>
  );
};

const CompanyCashbackOffersObserver = observer(CompanyCashbackOffersBase);
export const CompanyCashbackOffers = withTheme(CompanyCashbackOffersObserver);
