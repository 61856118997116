import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CompanyModel, ICompanySector } from './companies';
import { SectorModel } from './sectors';
import { ValueModel, ValuesModel } from './values';
import { getUniqueSectors } from '../lib/misc';

type PrivateFields = '_initialLoad' |
'_loadedCompanies' |
'_loadingCompanies' |
'_sectors' |
'_selectedCompanies';

export enum CompanyType {
  Company1 = 'company1',
  Company2 = 'company2',
}

export enum PickWinner {
  Company1 = 'company1',
  Company2 = 'company2',
  Company3 = 'company3',
  None = 'none',
  Even = 'even'
}

export interface ICompareCompany {
  company?: CompanyModel;
  values?: ValueModel[];
  errorLoading: boolean;
}

export interface ISectorInfo {
  name?: string;
  _id?: string;
  primary?: boolean;
}

export interface ISelectedCompanySector {
  _id: string;
  sector: string;
  primary: boolean;
}

export interface ISelectedCompany {
  _id: string;
  companyName: string;
  sectors?: ISectorInfo[] | null;
  companyLogo?: string;
}

export class CompareCompaniesModel extends BaseModel {
  private _initialLoad = false;
  private _loadedCompanies: ICompareCompany[] = [];
  private _loadingCompanies = false;
  private _sectors: ISectorInfo[] = [];
  private _selectedCompanies: ISelectedCompany[] = [];

  constructor () {
    super();
    makeObservable<CompareCompaniesModel, PrivateFields>(this, {
      _initialLoad: observable,
      _loadedCompanies: observable,
      _loadingCompanies: observable,
      _sectors: observable,
      _selectedCompanies: observable,
      initialLoad: computed,
      loadedCompanies: computed,
      loadingCompanies: computed,
      selectedCompanies: computed,
      loadCompany: action.bound,
      setSectors: action.bound,
      removeLoadedCompany: action.bound,
      removeSelectedCompany: action.bound,
      resetLoadedCompanies: action.bound,
      updateSelectedCompanies: action.bound,
    });
  }

  get initialLoad () { return this._initialLoad; }
  // companies that have been loaded to display on the comparison page, this will be the full company object
  get loadedCompanies () { return this._loadedCompanies; }
  get loadingCompanies () { return this._loadingCompanies; }
  get sectors () { return this._sectors; }
  // currently selected in the company picker, not loaded yet, need the sector names and ids to load company results
  get selectedCompanies() { return this._selectedCompanies; }

  get sharedSectors() {
    if (!this._loadedCompanies.length || (this._loadedCompanies.length > 0 && this._loadedCompanies[0].errorLoading)) return [];

    if (this._loadedCompanies.length === 1 && this._loadedCompanies[0].company.sectors) {
      return getUniqueSectors(this._loadedCompanies[0].company.sectors).map((s: ICompanySector) => s.sector);
    }

    if (this._loadedCompanies.length === 2) {
      return this._loadedCompanies[0]?.company?.sectors?.filter(s => this._loadedCompanies[1]?.company?.sectors?.some(s2 => s2.sector._id === s.sector._id));
    }
  }

  get ourPick () {
    if (this._loadedCompanies.length === 2) {
      if (this._loadedCompanies[0]?.company?.combinedScore > this._loadedCompanies[1]?.company?.combinedScore) {
        return PickWinner.Company1;
      }
  
      if (this._loadedCompanies[0]?.company?.combinedScore < this._loadedCompanies[1]?.company?.combinedScore) {
        return PickWinner.Company2;
      }
  
      if (this._loadedCompanies[0]?.company?.combinedScore === this._loadedCompanies[1]?.company?.combinedScore) {
        return PickWinner.Even;
      }
    }

    if (this._loadedCompanies.length === 3) {
      if (this._loadedCompanies[0]?.company?.combinedScore > this._loadedCompanies[1]?.company?.combinedScore && this._loadedCompanies[0]?.company?.combinedScore > this._loadedCompanies[2]?.company?.combinedScore ) {
        return PickWinner.Company1;
      }
  
      if (this._loadedCompanies[1]?.company?.combinedScore > this._loadedCompanies[0]?.company?.combinedScore && this._loadedCompanies[1]?.company?.combinedScore > this._loadedCompanies[2]?.company?.combinedScore ) {
        return PickWinner.Company2;
      }

      if (this._loadedCompanies[2]?.company?.combinedScore > this._loadedCompanies[0]?.company?.combinedScore && this._loadedCompanies[2]?.company?.combinedScore > this._loadedCompanies[1]?.company?.combinedScore ) {
        return PickWinner.Company3;
      }
  
      if (this._loadedCompanies[0]?.company?.combinedScore === this._loadedCompanies[1]?.company?.combinedScore && this._loadedCompanies[0]?.company?.combinedScore === this._loadedCompanies[2]?.company?.combinedScore) {
        return PickWinner.Even;
      }
    }
  }

  loadCompany = async (companyId: string) => {
    try {
      const company = await CompanyModel.loadCompany(companyId);
      const values = await ValuesModel.loadCompanyValues(companyId);

      runInAction(() => {
        this._loadedCompanies = [...this._loadedCompanies.filter(lc => lc.company._id !== company._id), { company: company, values: values, errorLoading: false }];
        // update the name of the company in the selected companies (only needed first time
        this._selectedCompanies = [...this.selectedCompanies.filter(sc => sc._id !== company._id), { _id: company._id, companyName: company.companyName }];
      });
    } catch (err) {
      runInAction(() => {
        this._loadedCompanies.push({ errorLoading: true });
        this._selectedCompanies = [...this._selectedCompanies.filter(sc => sc._id !== companyId)];
      });
    }
  };

  public loadCompanies = async () => {
    const companiesToLoad = this._selectedCompanies;

    runInAction(() => {
      this._loadingCompanies = true;
    });

    try {
      for (let i = 0; i < companiesToLoad.length; i++) {
        await this.loadCompany(companiesToLoad[i]._id);
      }
    } finally {
      runInAction(() => {
        this._loadingCompanies = false;
        this._initialLoad = true;
      });
    }
  };

  // selected companies are the ids of the companies that are selected in the search results
  public updateSelectedCompanies = (companyId: string, companyName?: string, sectors?: ISelectedCompanySector[], companyLogo?: string) => {
    const sectorInfo: ISectorInfo[] = [];

    runInAction(() => {
      if (this._selectedCompanies.find(sc => sc._id === companyId)) {
        this._selectedCompanies = [...this._selectedCompanies.filter(sc => sc._id !== companyId)];
      } else {
        this._selectedCompanies = [...this._selectedCompanies, { _id: companyId, companyName: companyName, companyLogo: companyLogo, sectors: sectorInfo || null }];
      } 
    });
  };

  public removeSelectedCompany = (companyId: string) => {
    runInAction(() => {
      this._selectedCompanies = [...this._selectedCompanies.filter(sc => sc._id !== companyId)];
    });
  };

  public removeLoadedCompany = (companyId: string) => {
    runInAction(() => {
      this._loadedCompanies = [...this._loadedCompanies.filter(sc => sc.company?._id !== companyId)];
    });
  };

  public resetLoadedCompanies = () => { 
    runInAction(() => {
      this._loadedCompanies = [];
    });
  };

  public removeSelectedCompanies = () => { 
    runInAction(() => {
      this._selectedCompanies = [];
    });
  };

  public setSectors = async (sectors: SectorModel[]) => {
    const sectorInfo: ISectorInfo[] = [];

    for (const sector of sectors) {
      sectorInfo.push({
        name: sector.name,
        _id: sector._id,
      });
    }

    runInAction(() => {
      this._sectors = sectorInfo;
    });
  };

  public setInitialLoad = () => {
    runInAction(() => { 
      this._initialLoad = true;
    });
  };

  public resetInitialLoad = () => {
    runInAction(() => {
      this._initialLoad = false;
    });
  };
}
