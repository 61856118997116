import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Star: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}star-graphic` }
    id={ id }
    height={ 113 }
    width={ 111 }
    fill='none'
  >
    <path d='M51.2563 14.5871C52.4537 10.9019 57.6673 10.9019 58.8647 14.5871L66.5243 38.1608C67.0598 39.8089 68.5956 40.9248 70.3285 40.9248H95.1154C98.9903 40.9248 100.601 45.8832 97.4666 48.1609L77.4136 62.7302C76.0116 63.7488 75.425 65.5543 75.9605 67.2024L83.62 90.7761C84.8174 94.4613 80.5995 97.5258 77.4647 95.2482L57.4117 80.6789C56.0097 79.6603 54.1113 79.6603 52.7094 80.6789L32.6564 95.2482C29.5215 97.5258 25.3036 94.4613 26.501 90.7761L34.1606 67.2024C34.696 65.5543 34.1094 63.7488 32.7075 62.7302L12.6545 48.1609C9.5196 45.8832 11.1307 40.9248 15.0056 40.9248H39.7925C41.5254 40.9248 43.0612 39.8089 43.5967 38.1609L51.2563 14.5871Z' fill='#323232' stroke='#323232' strokeWidth='2' />
    <path d='M51.2563 11.7082C52.4537 8.02296 57.6673 8.02296 58.8647 11.7082L66.5243 35.2819C67.0598 36.93 68.5956 38.0459 70.3285 38.0459H95.1154C98.9903 38.0459 100.601 43.0043 97.4666 45.2819L77.4136 59.8513C76.0116 60.8699 75.425 62.6754 75.9605 64.3235L83.62 87.8972C84.8174 91.5824 80.5995 94.6469 77.4647 92.3693L57.4117 77.8C56.0097 76.7814 54.1113 76.7814 52.7094 77.8L32.6564 92.3693C29.5215 94.6469 25.3036 91.5824 26.501 87.8972L34.1606 64.3235C34.696 62.6754 34.1094 60.8699 32.7075 59.8513L12.6545 45.2819C9.5196 43.0043 11.1307 38.0459 15.0056 38.0459H39.7925C41.5254 38.0459 43.0612 36.93 43.5967 35.2819L51.2563 11.7082Z' fill='#FFDC00' stroke='#323232' strokeWidth='2' />
    <line x1='76.7799' y1='18.6341' x2='97.4519' y2='5.55022' stroke='black' strokeWidth='2' strokeLinecap='round' />
    <line x1='72.4834' y1='9.61655' x2='79.1637' y2='1.86138' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
    <line x1='79.5657' y1='25.289' x2='93.5956' y2='23.2709' stroke='black' strokeWidth='2' strokeLinecap='round' />
    <line x1='1' y1='-1' x2='25.4647' y2='-1' transform='matrix(-0.844974 -0.534808 -0.534808 0.844974 33.6519 20.0137)' stroke='black' strokeWidth='2' strokeLinecap='round' />
    <line x1='1' y1='-1' x2='11.2357' y2='-1' transform='matrix(-0.652649 -0.757661 -0.757661 0.652649 37.5332 11.0266)' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
    <line x1='1' y1='-1' x2='15.1743' y2='-1' transform='matrix(-0.989813 -0.142376 -0.142376 0.989813 31.4033 26.4209)' stroke='black' strokeWidth='2' strokeLinecap='round' />
  </SvgIcon>
);
