import React, { FC } from 'react';
import { ArrowSvgIcon } from './styles';

export enum ArrowDirection {
  Up = 'up',
  Right = 'right',
  Down = 'down',
  Left = 'left',
}

interface IProps {
  className?: string;
  stroke?: string;
  direction?: ArrowDirection;
  id?: string;
}

export const ArrowIcon: FC<IProps> = ({ className = '', direction, stroke = '', id = '' }) => (
  <ArrowSvgIcon
    className={ `${direction} ${className ? className + ' ' : ''}arrow-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M15.8332 10L10.8332 5M15.8332 10L10.8332 15M15.8332 10H4.1665' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </ArrowSvgIcon>
);
