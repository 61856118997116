import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const GirlWithMagnifierGraphic: FC<IProps> = ({ className = '', id = '', fill = '#fff', stroke = '#000' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }girl-with-magnifier-graphic` }
    id={ id }
    width={ 241 }
    height={ 336 }
    fill={ fill }
    stroke={ stroke }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M32.6815 137.812C32.6815 137.812 36.3446 161.075 41.1212 179.018C44.8909 193.179 52.7903 203.979 50.8805 214.026C48.8037 224.949 43.4624 237.954 38.7352 250.429C31.4621 269.627 24.5318 286.755 24.5318 286.755L40.6425 293.751L67.2402 263.704L69.3554 300.525L89.3648 302.371C89.3648 302.371 102.414 225.741 104.246 197.631C105.634 176.327 100.356 133.706 100.356 133.706L32.6815 137.812Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M163.038 96.4381C163.038 96.4381 174.912 90.2491 178.776 88.9237C181.093 88.1293 183.914 87.6606 186.217 88.4879C188.85 89.4343 195.175 92.0715 194.578 94.6015C193.98 97.1315 186.619 102.09 182.631 103.669C178.985 105.113 170.653 104.077 170.653 104.077L165.287 105.654L163.038 96.4381Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M84.4212 36.8208C84.4212 36.8208 92.8469 40.1247 93.8628 46.9339C94.1997 49.191 90.8722 54.1272 90.8722 54.1272L76.421 50.8903L77.1899 40.5309L84.4212 36.8208Z' fill={ stroke } stroke={ fill } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M91.2559 134.106C91.2559 134.106 83.608 135.95 79.6952 136.455C75.5572 136.99 66.4249 137.311 66.4249 137.311L57.4628 53.4305L71.4846 52.1567L85.7759 58.2561L91.2559 134.106Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M88.1558 10.6245C88.1558 10.6245 90.8852 25.0872 89.1031 31.8385C88.0377 35.8766 86.3904 39.5437 80.3649 41.5879C75.9387 43.0893 80.7461 55.8753 80.7461 55.8753C80.7461 55.8753 73.1021 60.958 69.5885 60.1398C66.0755 59.3222 59.6662 50.9674 59.6662 50.9674L57.8975 28.6816L56.9497 20.5271L62.3462 11.3803L88.1558 10.6245Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M81.041 41.3287C77.1712 42.712 73.8225 42.3341 70.9307 40.8765Z' fill={ fill } />
    <path d='M81.041 41.3287C77.1712 42.712 73.8225 42.3341 70.9307 40.8765' stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M58.1359 50.3684C54.6018 51.3244 39.8529 59.6486 36.2128 58.3055C32.5728 56.9631 30.9382 46.6945 32.8474 42.8828C33.7016 41.1767 36.6685 38.1287 41.7826 40.3517C47.5572 42.8613 41.0716 32.2165 47.3595 30.322C57.7156 27.2014 48.2997 22.4609 49.4908 17.7124C50.105 15.2647 52.629 5.64583 59.2661 6.94798C63.2296 7.7253 62.8625 2.25966 68.1942 1.25482C72.347 0.471821 77.5376 2.61144 80.67 3.15499C82.9758 3.55557 86.175 3.14364 88.3884 3.84267C90.7822 4.59842 94.025 7.11706 94.6854 10.4419C95.2232 13.1518 93.914 15.3504 93.3762 16.2333C92.2505 18.0812 75.8664 20.3349 72.8739 17.7068C69.7088 14.9266 66.1643 18.6906 65.7305 22.2146C65.28 25.8686 65.1144 27.2145 65.0406 27.8131C64.8192 29.6105 60.1344 30.7062 59.852 32.4968C59.3578 35.6379 65.9564 41.4014 65.2826 45.0287C65.0701 46.1708 58.9452 50.1494 58.1359 50.3684Z' fill={ stroke } stroke={ fill } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M83.3699 70.8087L88.5335 104.792L90.2509 140.261C90.2509 140.261 105.966 139.03 105.288 134.276C103.924 124.713 100.945 105.766 100.945 105.766C100.945 105.766 109.797 115.609 115.881 116.884C132.18 120.298 166.781 107.283 166.781 107.283L163.615 93.8316C163.615 93.8316 136.011 99.8022 123.823 96.319C117.646 94.5533 117.974 87.2221 114.14 77.6939C111.016 69.9287 105.221 63.2341 101.817 59.4916C96.2661 53.3877 80.346 52.1411 80.346 52.1411L83.3699 70.8087Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M99.5237 87.6934C100.617 93.6214 99.8908 99.3838 101.029 106.456' stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M34.2871 301.658L30.7658 309.556L17.3505 303.301L21.287 295.721L34.2871 301.658Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M84.8288 312.864L85.0098 318.384L69.5665 322.244L70.37 310.126L84.8288 312.864Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M69.668 100.287L68.4704 140.99C68.4704 140.99 54.9332 143.349 48.5458 143.87C40.7972 144.502 30.0239 143.703 30.0239 143.703C30.0239 143.703 29.4451 128.879 30.5861 118.776C32.3824 102.863 29.1878 88.8954 29.1878 88.8954L28.5255 68.6187L29.1313 61.0719L48.325 51.7622L60.0884 63.0543L65.1481 87.0015L69.668 100.287Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M59.5995 138.843C63.0592 138.141 70.7231 141.886 72.8043 142.888C73.4897 143.218 72.821 144.11 72.0785 144.322C71.3283 144.537 66.2006 143.217 67.6362 144.808C68.9768 146.293 80.5144 147.253 83.0564 149.174C84.8424 150.524 83.822 153.789 81.9673 155.065C80.1082 156.344 74.9195 156.106 71.291 155.745C68.3197 155.449 64.9261 154.658 61.3804 153.081C56.6057 150.957 53.3 148.849 52.0447 147.098C49.9019 144.109 56.1397 139.545 59.5995 138.843Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M39.9873 80.5419C36.0502 84.6419 33.2617 88.8513 30.1948 93.8426C26.6343 99.6379 22.9846 104.232 29.0473 109.811C37.6885 117.763 61.7969 137.309 61.7969 137.309L51.1379 149.429C51.1379 149.429 18.1412 133.666 7.40076 120.511C3.6516 115.92 0.78358 110.442 0.72518 104.779C0.661646 98.5678 5.5602 88.4939 12.1402 79.3289C20.707 67.3979 26.2223 63.0279 32.0963 58.3497C36.3717 54.9443 47.3842 51.2603 47.3842 51.2603' fill={ fill } />
    <path d='M39.9873 80.5419C36.0502 84.6419 33.2617 88.8513 30.1948 93.8426C26.6343 99.6379 22.9846 104.232 29.0473 109.811C37.6885 117.763 61.7969 137.309 61.7969 137.309L51.1379 149.429C51.1379 149.429 18.1412 133.666 7.40076 120.511C3.6516 115.92 0.78358 110.442 0.72518 104.779C0.661646 98.5678 5.5602 88.4939 12.1402 79.3289C20.707 67.3979 26.2223 63.0279 32.0963 58.3497C36.3717 54.9443 47.3842 51.2603 47.3842 51.2603' stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M79.0553 50.1528L88.4834 102.961C88.4834 102.961 92.7318 92.8668 94.1289 87.6401C95.5876 82.1818 97.2363 70.2122 97.2363 70.2122L90.9574 64.4833L95.8026 63.807L89.8612 51.7585L79.0553 50.1528Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M60.7907 47.5703C60.7907 47.5703 67.0543 64.6231 68.5368 73.3518C70.059 82.3199 69.9185 103.282 69.9185 103.282C69.9185 103.282 64.031 96.0478 62.4118 91.8055C57.6949 79.4467 56.7239 73.8755 56.7239 73.8755L62.1615 68.0734L54.9039 68.2873L46.5815 51.3366L60.7907 47.5703Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M88.7464 159.089C88.7464 159.089 93.2683 208.082 89.6487 225.55C86.7108 239.729 67.0287 263.895 67.0287 263.895' fill={ fill } />
    <path d='M88.7464 159.089C88.7464 159.089 93.2683 208.082 89.6487 225.55C86.7108 239.729 67.0287 263.895 67.0287 263.895' stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M43.5996 294.081C43.5996 294.081 42.008 298.09 40.9203 299.684C39.8325 301.277 36.951 304.093 36.951 304.093L18.9439 296.928C18.9439 296.928 18.9169 292.975 19.5831 290.978C20.2402 289.008 22.9407 284.947 22.9407 284.947L43.5996 294.081Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M91.6841 302.12C91.6841 302.12 92.195 306.216 91.7066 308.195C91.2111 310.199 88.7147 314.143 88.7147 314.143C88.7147 314.143 80.703 314.507 76.9365 314.17C73.2663 313.842 66.1158 312.124 66.1158 312.124C66.1158 312.124 65.8052 308.453 65.8315 306.576C65.8578 304.721 66.4001 300.332 66.4001 300.332C66.4001 300.332 74.5588 300.653 78.6275 300.941C82.842 301.239 91.6841 302.12 91.6841 302.12Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M68.5479 318.496C68.5479 318.496 66.4776 324.184 66.2665 326.797C66.0541 329.429 67.2715 334.29 67.2715 334.29L115.467 334.007C115.467 334.007 117.623 327.727 115.693 326.579C111.7 324.203 84.7626 316.458 84.7626 316.458L68.5479 318.496Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M16.5424 301.807C16.5424 301.807 12.3901 306.257 10.658 308.548C8.9682 310.784 6.14914 315.554 6.14914 315.554L44.2773 333.731C44.2773 333.731 50.1808 329.532 49.0978 327.235C47.0973 322.991 32.2738 308.269 32.2738 308.269L16.5424 301.807Z' fill={ fill } stroke={ stroke } strokeWidth='1.33362' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M200.133 82.5773C200.133 82.5773 175.144 97.5039 169.94 100.68C169.533 100.929 168.789 101.221 168.911 101.636C169.066 102.16 169.12 103.284 169.759 104.304C170.206 105.017 170.991 105.634 171.514 106.018C171.889 106.293 172.501 106.645 172.919 106.392C178.204 103.185 203.224 86.7735 203.224 86.7735L200.133 82.5773Z' fill={ fill } stroke={ stroke } strokeWidth='1.24216' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M172.74 105.645C172.861 102.48 171.98 101.707 169.068 101.033Z' fill={ fill } />
    <path d='M172.74 105.645C172.861 102.48 171.98 101.707 169.068 101.033' stroke={ stroke } strokeWidth='1.24258' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M205.593 90.9493C205.593 90.9493 197.333 90.8712 196.173 89.2568C194.308 86.6604 197.249 80.6118 197.249 80.6118L205.593 90.9493Z' fill={ fill } stroke={ stroke } strokeWidth='1.24237' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M218.435 56.6861C206.367 56.746 196.49 65.4775 196.393 76.173C196.296 86.8686 206.015 95.5035 218.083 95.4436C230.151 95.3837 240.028 86.6516 240.125 75.9561C240.222 65.2606 230.503 56.6262 218.435 56.6861ZM218.397 60.8219C208.905 60.869 201.137 67.7372 201.06 76.1499C200.984 84.5625 208.628 91.3549 218.12 91.3078C227.613 91.2607 235.381 84.3919 235.458 75.9793C235.534 67.5666 227.89 60.7748 218.397 60.8219Z' fill={ fill } stroke={ stroke } strokeWidth='1.33363' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M185.939 98.8926C185.939 98.8926 181.514 96.9457 179.477 97.2436C177.44 97.541 174.478 99.6563 173.719 100.678C173.332 101.199 173.806 101.629 174.198 102.387C175.092 104.119 179.259 104.153 179.259 104.153' fill={ fill } />
    <path d='M185.939 98.8926C185.939 98.8926 181.514 96.9457 179.477 97.2436C177.44 97.541 174.478 99.6563 173.719 100.678C173.332 101.199 173.806 101.629 174.198 102.387C175.092 104.119 179.259 104.153 179.259 104.153' stroke={ stroke } strokeWidth='1.33363' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M182.181 90.9711C184.631 91.9367 189.529 93.8668 190.621 94.8955C191.335 95.5672 189.787 97.0633 188.734 97.1417C187.127 97.262 183.514 95.6728 180.975 95.6162C178.449 95.5601 175.148 96.3872 173.497 96.8011' fill={ fill } />
    <path d='M182.181 90.9711C184.631 91.9367 189.529 93.8668 190.621 94.8955C191.335 95.5672 189.787 97.0633 188.734 97.1417C187.127 97.262 183.514 95.6728 180.975 95.6162C178.449 95.5601 175.148 96.3872 173.497 96.8011' stroke={ stroke } strokeWidth='1.33363' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M205.774 89.885C205.773 89.8873 205.159 90.1452 205.132 90.1553' stroke={ stroke } strokeWidth='0.564932' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M198.354 84.5589C198.367 84.5638 198.398 84.5827 198.38 84.5582' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M194.759 90.2825C194.762 90.2863 194.756 90.2723 194.757 90.2672' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M195.055 90.077C195.077 90.0604 195.091 90.0688 195.097 90.0398' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M189.374 90.2484C189.381 90.2486 189.858 90.0145 189.891 89.9936' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M190.27 89.9259C190.269 89.9202 190.268 89.9273 190.268 89.9299' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M170.159 103.08C170.158 103.161 170.393 103.039 170.43 103.028' stroke={ fill } strokeWidth='0.481388' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M169.926 102.831C169.787 102.835 169.974 102.796 170.014 102.769' stroke={ fill } strokeWidth='0.481388' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M217.115 56.4834C217.166 56.5052 217.281 56.433 217.335 56.417C217.607 56.3362 217.884 56.3283 218.163 56.3036' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M209.228 60.2074C209.262 60.1987 209.249 60.2054 209.258 60.1636' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M209.649 59.9389C209.636 59.9453 209.644 59.9332 209.646 59.924' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M235.42 82.6846C235.392 82.612 235.426 82.5318 235.441 82.455' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M235.785 82.2812C235.799 82.2403 235.763 82.2172 235.772 82.1747' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M202.912 66.5936C202.905 66.6043 202.91 66.5999 202.891 66.5973' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M203.131 66.0722C203.132 66.0717 203.134 66.0711 203.135 66.0706' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M197.779 88.4441C197.648 88.4358 197.607 88.0777 197.627 87.9831' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M197.758 87.0845C197.765 86.9877 197.684 86.8272 197.677 86.7061' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M239.103 76.1467C239.251 76.0441 239.371 75.8519 239.453 75.6885' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M187.133 94.7672C187.134 94.8025 187.313 94.8132 187.339 94.8148C187.542 94.8275 187.774 94.8295 187.976 94.7802' stroke={ stroke } strokeWidth='0.564524' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M176.018 101.673C176.004 101.677 176.013 101.676 175.993 101.673' stroke={ fill } strokeWidth='0.481388' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M181.235 94.8786C181.237 94.8781 181.239 94.8775 181.241 94.8769' stroke={ fill } strokeWidth='0.481388' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.9945 32.7915C66.0498 32.791 66.0404 32.777 66.0404 32.815' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.5176 35.3255C65.2217 35.2376 64.9471 34.9312 64.6525 34.8013' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.0046 35.8495C65.0457 35.7673 64.6748 35.5814 64.5984 35.5366' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.8326 47.8331C73.9046 47.8585 73.8094 47.7973 73.8019 47.7939' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M75.7601 54.7006C75.8031 54.6349 75.4444 54.5391 75.3565 54.4614' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M75.1387 55.0946C75.0793 55.1019 75.0116 55.1126 74.9688 55.0742' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.375 19.291C85.3089 19.3943 85.0355 19.4056 84.8924 19.4056' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.1327 35.3096C85.1513 35.3508 85.1551 35.357 85.1187 35.3892' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M70.4219 26.6205C70.1979 26.5587 70.0131 26.5672 69.784 26.4663' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.7246 55.5647C56.6977 55.3318 56.2773 55.6316 56.0167 55.6316' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.738 56.1992C56.781 56.2196 56.188 56.3641 56.1065 56.3641' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M63.5546 70.6748C63.7548 71.4379 63.9127 72.2533 64.0879 73.0272' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M63.7675 74.979C63.8389 74.9978 63.8251 74.9762 63.8251 75.0314' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M45.9171 66.3145C45.9485 66.2667 45.9254 66.278 45.9844 66.278' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.7598 79.5644C49.6147 79.6853 48.1579 78.5176 48.079 78.4648' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.6055 80.4555C49.5541 80.076 48.3881 79.5063 48.0755 79.3525' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M18.0098 82.1219C17.9841 81.9574 17.4085 82.9123 17.1973 83.2856' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.998 84.6006C15.9871 84.7179 15.9422 84.859 15.8209 84.859' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21.0156 110.337C20.5317 109.706 20.0093 109.081 19.3804 108.525' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M17.7994 107.21C17.8489 107.2 17.8307 107.213 17.8307 107.154' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14.804 115.622C14.8851 115.647 14.8385 115.646 14.9023 115.534' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M53.2031 109.043C52.7058 108.921 52.3136 108.642 51.9517 108.322' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M52.2667 109.664C52.3172 109.627 52.3204 109.692 52.2392 109.692' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.8063 114.854C65.8498 114.823 65.9704 114.822 65.8511 114.822' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.3782 116.079C65.4012 116.105 65.3897 116.086 65.3897 116.142' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M74.5022 106.544C74.5265 106.492 74.5967 106.419 74.5258 106.544' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.5747 86.6494C64.629 86.7165 64.6297 86.839 64.7031 86.839' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.078 89.6016C65.248 89.6996 65.2211 89.6809 65.2211 89.8362' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M88.7555 77.8959C88.8037 77.7467 88.425 76.9597 88.425 76.7339' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.9805 75.8261C87.9626 75.7527 87.9664 75.7883 87.9664 75.7183' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.707 82.2825C102.846 82.2604 102.215 81.765 102.096 81.6606' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M101.535 82.9433C101.645 83.0348 101.387 82.8439 101.351 82.8281' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M94.4394 101.334C94.4722 101.21 94.2918 101.359 94.2283 101.415' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.2605 64.1748C77.5435 64.2327 76.9486 64.2412 76.8209 64.2412' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.2551 65.4282C77.524 65.368 76.8283 65.3584 76.7077 65.3584' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.6878 92.7217C73.8758 92.7744 73.664 94.2916 73.664 94.5084' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.8254 96.1177C73.8477 96.1476 73.8375 96.1273 73.8375 96.1843' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.5967 122.455C85.6288 122.702 84.8337 122.971 84.6315 123.15' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.1221 123.911C85.1902 123.915 84.9129 124.023 84.8654 124.065C84.6106 124.29 84.1877 124.298 84.0035 124.624' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M148.106 101.26C148.169 101.322 148.189 101.264 148.207 101.326' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M122.002 99.3006C121.383 99.54 120.455 99.4935 120.075 98.9658' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M119.48 98.1938C119.5 98.234 119.502 98.2156 119.435 98.2307' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M111.989 108.532C112.027 108.54 112.009 108.542 111.994 108.486' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M132.781 111.83C132.959 111.748 133.14 111.848 132.92 111.897' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M128.779 112.611C128.372 112.61 127.862 112.967 127.428 113.063' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M126.188 113.097C125.983 113.233 125.925 113.395 125.559 113.476' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M109.053 96.4215C109.041 96.0726 108.075 95.8468 107.753 95.7271' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.873 97.1515C109.034 96.8327 107.756 96.6778 107.577 96.6426' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M145.922 107.656C145.88 107.557 145.834 107.607 145.721 107.632' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M161.957 105.123C161.766 104.911 161.433 104.735 161.159 104.59' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M161.281 106.015C161.119 105.924 161.003 105.802 160.808 105.764' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M106.251 72.0705C106.339 72.0246 105.505 71.9423 105.144 71.6995C104.635 71.3573 104.608 70.6708 104.093 70.3984' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M103.43 69.6713C103.471 69.6591 103.451 69.658 103.467 69.7149' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.473 78.0625C108.482 78.0678 108.492 78.0737 108.502 78.0789' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.992 122.75C98.234 123.246 97.5319 123.481 97.6037 123.736' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M96.3477 125.05C96.2893 125.071 96.3214 125.061 96.2496 125.078' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.127 114.027C93.0653 113.873 93.0044 113.741 92.7952 113.788' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.7457 114.977C92.7523 114.984 92.759 114.992 92.7656 115' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.4805 133.587C92.4466 133.591 92.4648 133.584 92.4798 133.639' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.5487 134.714C73.5672 134.619 73.4593 134.684 73.3845 134.701' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M63.6426 133.544C63.5887 133.422 62.8397 133.197 62.6472 133.137' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M63.5195 134.407C63.4002 134.235 63.2269 134.199 62.9997 134.154' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M40.5594 134C40.5995 133.98 40.5825 133.996 40.5957 133.944' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.1289 123.408C37.8979 123.165 37.3222 123.056 37.0155 122.834' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M35.6558 121.433C35.6588 121.432 35.6617 121.431 35.6641 121.43' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M63.6007 146.944C63.6876 146.915 63.823 146.942 63.7394 146.925' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M74.4628 152.873C74.4705 152.877 73.8929 152.778 73.7415 152.748' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M71.8437 152.081C71.8412 152.054 71.8875 152.047 71.8393 152.038' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M48.7415 156.32C48.7607 156.32 48.7845 156.356 48.7678 156.365C48.6022 156.458 47.852 156.328 47.6544 156.332' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M49.0322 157.205C49.1875 157.288 48.6176 157.389 48.3391 157.414' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M26.2707 131.193C26.324 131.057 26.1642 131.065 26.0499 131.042' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21.123 128.361C21.1243 128.352 19.9557 127.51 19.7946 127.479' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M19.1733 126.998C19.1829 126.941 19.0488 126.856 19.0092 126.801' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M31.8711 141.379C31.9256 141.377 31.8962 141.375 31.959 141.388' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.8242 153.007C92.8152 152.933 91.8141 153.586 91.5214 153.65' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M91.9294 152.383C92.0109 152.257 91.5726 152.543 91.4705 152.599' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.881 161.03C83.938 161.013 83.9079 161.015 83.9727 161.028' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.4855 175.246C84.6831 175.387 83.7789 175.631 83.7096 175.669' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.5762 176.45C84.4254 176.443 84.1385 176.511 84.0127 176.581' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.4877 163.066C64.5529 163.052 64.5397 163.041 64.5253 163.1' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M33.3106 63.96C33.3745 64.0294 33.3496 63.9888 33.3809 64.0842' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M32.9076 71.128C32.9314 71.0696 32.1927 70.665 32.0188 70.5811' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M32.1797 72.1107C32.0103 71.8322 31.8748 71.8883 31.6374 71.6631' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.4173 56.9374C85.5546 56.9119 85.6696 56.9639 85.4971 57.0085' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M92.7333 71.8918C92.8482 71.793 92.7856 71.8218 92.9121 71.7891' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M47.4691 178.477C47.4825 178.267 47.8201 178.419 47.6372 178.467' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M54.1946 189.475C54.3043 189.329 55.0686 191.798 55.2227 192.262' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.8288 195.511C56.8243 195.417 56.8511 195.53 56.8633 195.566' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M78.7578 210.703C78.7289 210.538 78.4427 210.297 78.2502 210.347' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M78.2676 212.268C78.2252 212.557 77.7452 212.429 77.5488 212.334' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.8404 213.618C94.1022 213.576 94.0393 213.615 94.1035 213.809' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.3809 188.781C77.187 188.527 76.6826 188.368 76.2847 188.472' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M76.5742 190.86C76.5248 191.056 76.1404 190.904 76.1077 190.805' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.5413 189.046C98.4845 188.921 98.5439 188.944 98.625 188.923' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.3497 173.827C98.487 174.091 96.7511 173.883 96.4681 173.839' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M97.9786 176.56C97.9569 176.737 97.9109 176.712 98.0859 176.667' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.0879 240.712C55.0912 240.027 53.732 239.962 52.8246 239.101' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M53.7852 242.029C53.5105 241.995 53.1505 241.676 52.8097 241.511' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M54.0564 256.455C54.2033 256.449 54.1407 256.436 54.248 256.476' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M78.0039 305.188C78.1534 305.244 77.5405 305.151 77.5065 305.149C76.9719 305.112 76.4431 305.032 75.9175 304.996' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.9071 305.955C73.8847 305.907 73.9328 305.942 73.9648 305.954' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.1701 284.141C79.3428 283.626 78.7729 282.701 78.9539 282.322' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M78.7406 280.061C78.7004 280.003 78.7438 280.085 78.7598 280.119' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.7686 251.94C85.1582 251.08 84.9182 250.352 84.9605 249.445C84.965 249.342 85.1428 248.705 85.0773 248.68' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.507 247.085C85.5556 246.982 85.5466 246.961 85.6699 246.917' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M85.2386 269.657C85.5396 269.689 85.5608 269.509 85.2957 269.41' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.5806 271.567C84.7269 271.622 84.647 271.657 84.5365 271.697' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M76.5709 228.075C76.621 228.101 76.0838 227.167 75.8733 227.088' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M75.4611 229.153C75.8013 229.222 75.13 228.557 75.0453 228.469' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.1983 271.889C38.2149 271.952 38.3554 271.988 38.2104 271.934' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.7918 274.105C36.8326 274.146 36.9118 274.315 36.7624 274.259' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M53.4881 208.528C53.5564 208.429 53.5111 208.436 53.6133 208.475' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M60.4713 222.113C60.6055 221.78 59.483 220.258 59.3008 219.861' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.0977 222.115C59.0206 221.777 58.7543 221.547 58.5477 221.269' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.6096 224.929C93.637 224.888 93.6332 224.911 93.554 224.881' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M70.4885 250.363C70.6425 250.392 71.0346 250.596 70.7516 250.303' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.1959 252.423C69.3185 252.488 69.3121 252.445 69.2674 252.539' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M57.041 268.504C57.0057 268.966 56.2273 269.467 55.9288 269.817' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M17.3145 310.822C17.3504 310.683 17.3241 310.709 17.4043 310.588' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M34.0762 321.571C33.7245 320.976 32.466 320.827 31.8409 320.503' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M33.6625 323.036C33.7385 323.061 33.6982 323.059 33.7832 323.039' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M76.7407 326.004C76.7062 325.887 76.7624 326.077 76.7637 326.082' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.5646 328.917C80.6878 328.931 80.6291 328.953 80.6719 329.097' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.562 327.269C105.273 327.476 104.686 327.065 104.373 327.006' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M104.348 328.175C104.407 328.289 104.452 328.237 104.484 328.346' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M79.0388 320.553C79.1832 320.48 79.1909 320.473 79.2305 320.607' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M86.0319 308.403C86.0536 308.383 86.0759 308.364 86.0977 308.345' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M26.0259 302.959C26.0859 302.928 26.0374 302.914 26.0125 302.832' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.2207 292.628C28.0327 292.204 26.5759 291.745 26.1273 291.748' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.6504 293.608C27.6241 293.307 27.1395 293.259 26.9008 293.186' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M41.666 281.295C41.2906 281.81 40.7676 282.407 40.1714 282.868' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.8305 284.156C38.8406 284.157 38.8513 284.16 38.8613 284.162' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.3489 93.7969C36.6236 93.7425 36.8476 93.8242 36.4933 93.9059' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.709 109.594C38.562 109.23 38.5825 108.97 38.08 109.085' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.63 111.588C38.7073 111.659 38.6511 111.553 38.637 111.503' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.363 60.9081C98.3119 60.9685 98.3291 60.9369 98.4824 60.9019' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M172.974 93.5336C172.966 93.5095 172.962 93.5166 172.992 93.5166' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M169.402 96.5327C169.456 96.5288 168.943 96.8638 168.831 96.9034' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M178.103 101.04C178.107 101.039 178.11 101.038 178.113 101.038' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M198.752 78.0347C198.898 78.0651 198.866 78.0962 198.891 78.2169' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M201.403 85.4183C201.509 85.3667 202.488 86.8555 202.693 87.0972' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M221.948 59.3982C222.043 59.3722 222.023 59.3728 222.102 59.3604' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M223.021 93.6201C222.938 93.6701 222.606 93.2439 222.548 93.1753' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M222.019 94.0796C222.137 94.032 221.582 93.7362 221.533 93.6914' stroke={ stroke } strokeWidth='0.555677' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
