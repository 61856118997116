import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { BasicContainer, FlexColCenter, FlexRow, SecondaryFont } from '../../styles/styles';
import { TextField } from '../../components/TextField';
import { TextArea } from '../../components/TextArea';
import { _H5 } from '../../styles/components/header';

export const ContactUsPageContainer = styled(MainContainer)`
  background-color: ${props => props.theme.colors.dark};
`;

export const ContactUsPageBoundaryContainer = styled(Section)``;

export const ContactUsTextField = styled(TextField)`
  border-radius: 999px;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ContactUsPageInnerWrapper = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  position: relative;

  h1 {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 48px;
    line-height: 0.5em;
    margin-bottom: 25px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 71px;
    }

    span {
      font-family: inherit;
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: ${({ theme }) => theme.colors.offWhite};
    }
  }

  p {
    ${_H5}
    max-width: 260px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.offWhite};
  }

  .girl-with-magnifier-graphic {
    position: absolute;
    transform: scaleX(-1);    
    width: 175px;
    right: 0px;
    top: 80px;
    z-index: 0;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      position: static;
      transform: scaleX(1);
      width: unset;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
  }
`;

export const FormContainer = styled.div`
  ${BasicContainer}
  background-color: ${({ theme }) => theme.colors.grey1};
  z-index: 1;
  flex: 1;
  padding: 30px;
  margin-top: 60px;

  form {
    fieldset {
      display: flex;
      flex-direction: column;
      border: none;
    }

    select {
      height: 48px;
    }

    .contact-us-field {
      input {
        height: 48px;
      }
    }

    legend {
      ${SecondaryFont}
      font-size: 16px;
      color: ${({ theme }) => theme.colors.black};
      margin-bottom: 10px;
    }

    .name-container {
      display: flex;
      align-items: flex-start;
      column-gap: 10px;
      flex-wrap: wrap;

      .name-field {
        width: unset;
        flex-basis: 250px;
        flex-grow: 1;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    margin-top: 0px;
    max-width: 685px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  max-width: 350px;
`;

export const MessageField = styled(TextArea)`
  textarea {
    font-size: 16px;
    font-weight: 400;
    border-color: ${({ theme }) => theme.colors.primary};

    &::placeholder {
      color: ${({ theme }) => theme.colors.midGray};
    }
  }

  .textarea-label {
    margin-bottom: 0;
  }
`;

export const SelectField = styled.select`
  border-radius: 999px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 12px 20px;
  margin-bottom: 20px;
`;
