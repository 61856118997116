import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexRow, SecondaryFont } from '../../../styles/styles';
import { H5 } from '../../../styles/components/header';

export const CompanyRatingBreakdownContainer = styled(Section)``;

export const CompanyRatingBreakdownInnerWrapper = styled(MaxBoundaryContainerLarge)`
  ${FlexCol}
  align-items: center;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    align-items: center;
    gap: 60px;
    justify-content: space-around;
  }
`;

export const RatingBreakdownContainer = styled.div`
  ${FlexCol}
  margin-bottom: 20px;
  max-width: 556px;
  width: 100%;

  h2 {
    font-size: 36px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    h2 {
      font-size: 50px;
      margin-top: 5px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    h2 {
      font-size: 55px;
      margin-top: 10px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    h2 {
      font-size: 70px;
      margin-top: 15px;
    }
  }
`;

export const TotalScoreContainer = styled.div`
  ${FlexRow}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 8px;
  justify-content: space-between; 
  margin: 48px auto 28px;
  padding: 15px 24px 15px 50px;
  width: 100%;

  h4 {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const CategoryContainer = styled.div`
  margin-top: 20px;
`;

export const SummaryContainer = styled.div`
  margin-bottom: calc(100vw/2);
  max-width: 556px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 0;
  }
`;

export const CategoryInnerContainer = styled.div`
  ${FlexCol}
  background-color: #E6F0F1;
  border: 1px solid #ACBBBD;
  border-radius: 16px;
  gap: 10px;
  padding: 24px;
  margin-top: 10px;
`;

export const CompanyNameText = styled(H5)``;

export const SummaryText = styled.p``;

export const ScoreNumber = styled.h4`
  ${SecondaryFont}
  color: ${({ theme }) => theme.colors.white};
  font-size: 40px;
  padding-top: 5px;
  text-align: center;
  width: 85px;
`;
