import { animated } from 'react-spring';
import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

interface INavMenuItemProps {
  expanded: boolean;
}

export const MobileNavItemsContainer = styled(animated.div)`
  ${FlexCol}
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
`;

export const MobileNavMenuItemContainer = styled.div<INavMenuItemProps>`
  ${FlexRow}
  align-items: space-between;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  gap: 20px;
  height: 100%;
  justify-content: ${({ expanded }) => (expanded ? 'flex-start' : 'space-between')};
  margin: 0;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors.grey1};
  }
`;

export const SubMenuContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  background: ${({ theme }) => theme.colors.dark};
  justify-content: center;
  padding: 40px 0;
  width: 100%;
`;

export const SubMenuLinkListContainer = styled.ul`
  ${FlexCol}
  align-items: center;
  gap: 10px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
`;
