import styled from 'styled-components';
import { FlexCenter } from '../../styles/styles';

const size = 42;

export const _ArrowButton = styled.button`
  ${ FlexCenter }
  min-width: ${size}px;
  max-width: ${size}px;
  min-height: ${size}px;
  max-height: ${size}px;
  margin: 0 0 2px 0;
  border: none;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.lightBlue2};
  outline: none;

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:active:not(:disabled) {
    margin: 2px 0 0 0;
    box-shadow: none;
  }

  &:focus:(:active):not(:disabled) {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.lightGray2};
    box-shadow: none;

    .chevron-icon {
      stroke: ${({ theme }) => theme.colors.disabled};
    }
  }

  &.next {
    padding-left: 2px;
  }

  &.previous {
    padding-right: 2px;
  }

  .chevron-icon {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const ArrowButtonContainer = styled.div`
  ${ FlexCenter }
  min-width: ${size}px;
  max-width: ${size}px;
  min-height: ${size + 2}px;
  max-height: ${size + 2}px;
`;
