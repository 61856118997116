import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

export const SubMenuContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  background: ${({ theme }) => theme.colors.dark};
  justify-content: center;
  padding: 40px 0;
  width: 100%;
`;

export const SubMenuLinkListContainer = styled.ul`
  ${FlexCol}
  align-items: center;
  gap: 10px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
`;

export const SubmenuLinkItem = styled.li`
  ${FlexColCenter}
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  justify-content: center;
  padding: 10px;
  text-align: center;
  width: 100%;

  .submenu-link {
    ${FlexColCenter}
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: 600;
    line-height: 10px;
    text-decoration: none;

    div {
      ${FlexRow}
      align-items: center;
      color: ${({ theme }) => theme.colors.white};
      height: 100%;
      justify-content: center;

      svg, img {
        height: 15px;
        margin-left: 5px;
        margin-bottom: 2px;
        width: auto;
      }
    }

    &:hover,
    &:focus {
      div {
        color: ${({ theme }) => theme.colors.tertiary};
      }
    }
    
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 14px;
    }
  }

  .disabled {
    color: ${({ theme }) => theme.colors.grey4};

    div {
      color: ${({ theme }) => theme.colors.grey4};
    }

    &:hover,
    &:focus {
      div {
        color: ${({ theme }) => theme.colors.grey4};
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: unset;
  }
`;

export const LinkItemSubtext = styled.span`
  color: ${({ theme }) => theme.colors.grey4};
  font-size: 15px;
  font-weight: 500;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    font-size: 12px;
  }
`;
