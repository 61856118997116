import { observer } from 'mobx-react';
import React, { useEffect, useCallback, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { GroupModel } from '../../models/group';
import { StatementModel } from '../../models/statements';
import { Checkbox } from '../Checkbox';
import { GroupStatementRow } from '../GroupStatementRow';
import { StatementItemSkeleton } from '../GroupStatementRow/skeleton';
import { GroupStatementSummary } from '../GroupStatementSummary';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { GroupOffsetStatementsSkeleton } from './skeleton';
import { GroupOffsetStatementsContainer, HeaderRowContainer, StatementsListContainer } from './styles';

interface IProps {
  className?: string;
  group: GroupModel;
}

const GroupOffsetStatementsBase: React.FC<IProps> = ({
  className = '',
  group,
}) => {
  const errorMessages = useErrorMessages();
  const [allSelected, setAllSelected] = useState(false);
  const [selectedStatements, setSelectedStatements] = useState<string[]>([]);

  const loadMore = () => {
    group?.offsetStatements?.loadMore()
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Offset Statements',
          message: err.message,
        });
      });
  };

  useEffect(() => {
    if (group?.offsetStatements) loadMore();
  }, [group?.offsetStatements]);

  const onStatementClick = useCallback((statement: StatementModel) => () => {
    let _selected = [...selectedStatements];

    if (_selected.includes(statement._id)) {
      _selected = _selected.filter(s => s !== statement._id);
    } else {
      _selected.push(statement._id);
    }

    setSelectedStatements(_selected);
    if (allSelected) setAllSelected(false);
  }, [selectedStatements]);

  const addAllUnpaidStatements = () => group.offsetStatements.results.filter(s => !s.offsets.matched && s.offsets.toBeMatched.dollars > 0).map(ss => ss._id);

  const handleSelectAllClick = useCallback (() => {
    setSelectedStatements(allSelected ? [] : addAllUnpaidStatements());
    setAllSelected(!allSelected);
  }, [allSelected, group?.offsetStatements?.results]);

  const renderHeaderRow = () => (
    <HeaderRowContainer>
      <div className='statement-mobile-header'>Statements</div>
      <div className='table-header'>
        <Checkbox
          className='statement-checkbox'
          label='Select All Statements'
          labelHidden
          id='select-all-statements'
          checked={ allSelected }
          onChange={ handleSelectAllClick }
        />
        <div className='cell'>Month</div>
        <div className='cell'>Member Offsets<br />(Tonnes)</div>
        <div className='cell'>Offsets to Match<br />(Tonnes)</div>
        <div className='cell'>Match Dollar<br />Amount</div>
        <div className='cell'>Status</div>
      </div>
    </HeaderRowContainer>
  );

  const renderStatements = () => {
    let statements: JSX.Element[] = [];

    if (group.offsetStatements.results.length > 0) {
      statements = group.offsetStatements.results.map(statement => (
        <GroupStatementRow
          key={ statement._id }
          className='statement-item'
          statement={ statement }
          onClick={ onStatementClick(statement) }
          groupId={ group._id }
          selected={ (allSelected && selectedStatements.includes(statement._id)) || selectedStatements.includes(statement._id) }
        />
      ));
    } else {
      statements.push(<div key='no-statements' className='no-statements'>No statements found</div>);
    }

    if (group.offsetStatements.busy) {
      if (group.offsetStatements.firstPageLoaded) {
        statements.push((
          <div key='loading-statements-spinner'>
            <LoadingSpinner />
          </div>
        ));
      } else {
        const skeletons: JSX.Element[] = [];

        for (let i = 0; i < 5; i++) {
          skeletons.push(<StatementItemSkeleton key={ `statement-item-skele-${i}` } />);
        }

        statements = [...statements, ...skeletons];
      }
    }

    if (!group.offsetStatements.allResultsFetched && !group.offsetStatements.busy) {
      statements.push(<Waypoint key='waypoint' onEnter={ loadMore } topOffset={ 200 } />);
    }

    return statements;
  };

  if (!group) return null;

  if (group.offsetStatements?.busy) return <GroupOffsetStatementsSkeleton className={ className } />;

  return (
    <GroupOffsetStatementsContainer
      className={ className }
    >
      <StatementsListContainer>
        { renderHeaderRow() }
        { renderStatements() }
      </StatementsListContainer>
      <GroupStatementSummary
        selectedStatements={ group.offsetStatements.results.filter(s => selectedStatements.find(ss => ss === s._id )) }
        groupId={ group._id }
      />
    </GroupOffsetStatementsContainer>
  );
};

export const GroupOffsetStatements = observer(GroupOffsetStatementsBase);
