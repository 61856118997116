import { observer } from 'mobx-react';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../contexts/analytics-store';
import { CompareCompaniesModel, PickWinner } from '../../models/compare-companies';
import { IThemeProps } from '../../styles/themes';
import { CompareCompaniesCardContainer, CompareCompaniesContainer, ComparePageHeader, OverallRatingsContainer } from './styles';
import { ComparisonData } from '../../components/ComparisonData';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { CompareCompaniesCard } from '../../components/CompareCompaniesCard';
import { H4 } from '../../styles/components/header';
import { Button } from '../../components/Button';
import { SocialModalComponentName, SocialShareModal } from '../../components/modals/SocialShareModal';
import { ButtonKind } from '../../components/Button/styles';
import { SocialIcon } from '../../components/svgs/icons/SocialIcon';

export const company1Param = 'company1';
export const company2Param = 'company2';
export const company3Param = 'company3';

interface IProps extends IThemeProps {
  className?: string;
}

export enum RemovalMethod {
  CompanyCard = 'CompanyCard',
  CompanyPicker = 'CompanyPicker',
  PillPicker = 'PillPicker',
}

const CompareCompaniesBase: React.FC<IProps> = ({
  className = '',
  theme, // eslint-disable-line
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const CompareModel = useRef(new CompareCompaniesModel()).current;
  const [searchParams, setSearchParams] = useSearchParams();
  const [showShareModal, setShowShareModal] = useState(false);
  const loaded = CompareModel.initialLoad;

  const loadCompanies = useCallback(async () => {
    try {
      await CompareModel.loadCompanies();
    } catch (err: any) {
      analytics.fireEvent('CompareCompanies_Error_Loading_Companies');
    }
  }, []);

  const initialLoad = async () => {
    await loadCompanies();
    CompareModel.setInitialLoad();
  };

  const onShareClick = useCallback(() => {
    setShowShareModal(true);
  }, []);
    
  const onShareClose = useCallback(() => {
    setShowShareModal(false);
  }, []);

  const updateCompaniesFromParams = (params: URLSearchParams) => {
    const company1 = params.get('company1');
    const company2 = params.get('company2');
    const company3 = params.get('company3');
    if (!company1 || !company2) throw new Error('Missing company params');
    if (!!company1) CompareModel.updateSelectedCompanies(company1, 'company1');
    if (!!company2) CompareModel.updateSelectedCompanies(company2, 'company2');
    if (!!company3) CompareModel.updateSelectedCompanies(company3, 'company3');
  };

  useEffect(() => {
    if (!window.location.search) {
      navigate('/browse-and-compare-companies');
    }
    updateCompaniesFromParams(searchParams);
    initialLoad();
  }, []);

  useEffect(() => {
    const handlePop = () => {
      CompareModel.resetLoadedCompanies();
      CompareModel.removeSelectedCompanies();
      CompareModel.resetInitialLoad();
      const currentSearchParams = new URLSearchParams(window.location.search);
      updateCompaniesFromParams(currentSearchParams);
      initialLoad();
    };
    
    window.addEventListener('popstate', handlePop);
    
    return () => {
      window.removeEventListener('popstate', handlePop);
    };
  }, []);

  const removeParams = (paramName:string) => {
    searchParams.delete(paramName);
    setSearchParams(searchParams);
  };

  const onRemoveCompany = useCallback((id: string) => () => {
    if (id === CompareModel.selectedCompanies[0]?._id) {   
      searchParams.set(company1Param, CompareModel.selectedCompanies[1]._id);
      searchParams.set(company2Param, CompareModel.selectedCompanies[2]._id);
    }

    if (id === CompareModel.selectedCompanies[1]?._id) {   
      searchParams.set(company1Param, CompareModel.selectedCompanies[0]._id);
      searchParams.set(company2Param, CompareModel.selectedCompanies[2]._id);
    }

    if (id === CompareModel.selectedCompanies[2]?._id) {   
      searchParams.set(company1Param, CompareModel.selectedCompanies[0]._id);
      searchParams.set(company2Param, CompareModel.selectedCompanies[1]._id);
    }

    removeParams(company3Param); 
    CompareModel.removeSelectedCompany(id);
    CompareModel.removeLoadedCompany(id);
    analytics.fireEvent('Compare_RemoveCompany');
  }, []);

  const company1 = CompareModel.loadedCompanies[0];
  const company2 = CompareModel.loadedCompanies.length > 1 ? CompareModel.loadedCompanies[1] : null;
  const company3 = CompareModel.loadedCompanies.length > 2 ? CompareModel.loadedCompanies[2] : null;

  const renderCompanyCards = () => {
    if (!CompareModel.initialLoad) return null;
    
    const removablecards = CompareModel.loadedCompanies.length === 3 ? true : false; 

    return (
      <CompareCompaniesCardContainer>
        { company1 && <CompareCompaniesCard comparedCompany={ company1 } ourPick={ CompareModel.ourPick === PickWinner.Company1 } isRemovable={ removablecards } onClose={ onRemoveCompany(company1.company._id) } /> }
        { company2 && <CompareCompaniesCard comparedCompany={ company2 } ourPick={ CompareModel.ourPick === PickWinner.Company2 } isRemovable={ removablecards } onClose={ onRemoveCompany(company2.company._id) } /> }
        { company3 && <CompareCompaniesCard comparedCompany={ company3 } ourPick={ CompareModel.ourPick === PickWinner.Company3 } isRemovable={ removablecards } onClose={ onRemoveCompany(company3.company._id) } /> }
      </CompareCompaniesCardContainer>
    );
  };

  const comparisonData = () => {
    if (CompareModel.loadedCompanies.length === 0) return null;

    if (CompareModel.loadedCompanies.filter((c: any) => !!c.errorLoading).length > 0) {
      return <div className='error-comparison'>Error Loading one or more companies, please select a new one</div>;
    } 

    return (
      <ComparisonData
        company1={ company1 }
        company2={ company2 }
        company3={ company3 }
        CompareModel={ CompareModel }
      />
    );
  };

  const renderHeader = () => (
    <ComparePageHeader>
      <H4 className='title'>Compare Companies</H4>
      <p>Compare companies on their social and environmental impact.</p>
    </ComparePageHeader>
  );

  const renderOverallRatings = () => (
    <OverallRatingsContainer>
      <H4>Overall Ratings</H4>
      <Button
        className='social-share-button'
        kind={ ButtonKind.Blank }
        onClick={ onShareClick }
        label='Share Comparison'
      >
        Share Findings
        <SocialIcon stroke={ theme.colors.primary } />
      </Button>
    </OverallRatingsContainer>
  );

  const renderSocialShareModal = useMemo(() => (
    <SocialShareModal
      className='social-share-modal'
      isOpen={ showShareModal }
      onClose={ onShareClose }
      shareUrl={ window.location.href }
      componentName={ SocialModalComponentName.Compare }
    />
  ), [showShareModal, CompareModel.loadedCompanies]);

  return (
    <CompareCompaniesContainer
      className={ className }
      title='Compare Companies'
    >
      {
        !!loaded ? (
          <>
            { renderHeader() }
            { renderOverallRatings() }
            { renderSocialShareModal }
            { renderCompanyCards() }
            { comparisonData() }
          </>
        ) : <LoadingSpinner />
      }
    </CompareCompaniesContainer>
  );
};

export const CompareCompaniesObserver = observer(CompareCompaniesBase);
export const CompareCompanies = withTheme(CompareCompaniesObserver);
