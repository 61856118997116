import styled from 'styled-components';
import { NoScrollBar } from '../../styles/styles';

export const TextAreaContainer = styled.div< {sizeOfArea?: number} >`
  height: ${props => !!props.sizeOfArea ? `${String(props.sizeOfArea)}`+'px' : '200px'};
  min-height: 200px;

  .textarea-label {
    display: block;
    margin-bottom: 10px;
  }

  .error-text {
    margin: 5px 0 0;
    padding-left: 10px;
    font-weight: 500;
    text-align: left;
    color: ${({ theme }) => theme.colors.danger};
    margin-bottom: 0;
  }

  .error {
    border-color: ${({ theme }) => theme.colors.danger};
  }

  textarea {
    ${ NoScrollBar }
    display: block;
    min-width: 100%;
    height: calc(100% - 30px);
    padding: 15px;
    border: ${({ theme }) => `1px solid ${theme.colors.darkGray1}`};
    border-radius: 20px;
    resize: none;
    outline: none;
  }
`;
