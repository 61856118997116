import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../svgs/graphics/Logo';
import { KWLogoDark } from '../svgs/graphics/LogoWithText';
import { GroupFooterContainer, GroupFooterInner } from './styles';

interface IProps {
  className?: string;
}

export const GroupFooter: React.FC<IProps> = ({
  className = '',
}) => (
  <GroupFooterContainer className={ className }>
    <GroupFooterInner>
      <div className='logo-wrapper'>
        <Link
          to='/'
          className='logo'
        >
          <KWLogoDark className='desktop-logo' />
          <Logo className='mobile-logo' />
        </Link>
        <div>
            ©{ dayjs().year() } ImpactKarma Inc. All rights reserved.
        </div>
      </div>
      <nav>
        <a
          href='/privacy-policy'
          target='_blank'
          rel='noreferrer'
        >
          Privacy Policy
        </a>
        <a
          href='https://cdn.karmawallet.io/documents/pathward_privacy_policy.pdf'
          target='_blank'
          rel='noreferrer'
        >
          Pathward Privacy Policy
        </a>
        <a
          href='https://cdn.karmawallet.io/documents/karma_wallet_pathward_cardholder_agreement.pdf'
          target='_blank'
          rel='noreferrer'
        >
          Cardholder Agreement
        </a>
        <a
          href='/terms-and-conditions'
          target='_blank'
          rel='noreferrer'
        >
          Terms & Conditions
        </a>
        <a
          href='/faqs'
          target='_blank'
          rel='noreferrer'
        >
          FAQs
        </a>
      </nav>
    </GroupFooterInner>
  </GroupFooterContainer>
);
