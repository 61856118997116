import styled, { css } from 'styled-components';
import { PrimaryFont } from '../../styles/styles';

interface PositionProp {
  position: string;
  withBorder: boolean;
}

export const TooltipWrapper = styled.div`
  display: inline-flex;
  position: relative;
  z-index: 5;
`;

export const CenterContainer = styled.div<PositionProp>`
  align-items: center;
  bottom: calc(100% + 5px);
  display: flex;
  justify-content: center;
  left: 50%;
  margin-left: -150px;
  position: absolute;
  width: 300px;

  ${({ position }) => {
    switch (position) {
      case 'bottom':
        return css`
          bottom: unset;
          top: calc(100% + 5px);
        `;
      case 'left':
        return css`
          left: unset;
          margin-right: 0;
          right: calc(100% + 5px);
          top: 50%;
          width: 100%;
          width: max-content;
        `;
      case 'right':
        return css`
          left: calc(100% + 5px);
          margin-left: 0;
          top: 50%;
          width: 100%;
          width: max-content;
        `;
    }
  }}
`;

export const TooltipBox = styled.span<PositionProp>`
  ${PrimaryFont}
  background-color: ${({ theme }) => theme.colors.dark};
  border: ${({ theme, withBorder }) => withBorder ? `1px solid ${theme.colors.secondary}` : 'none'};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-weight: 400;
  max-width: 300px;
  position: relative;
  padding: 12px 25px;
  text-align: center;

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 7px;
    height: 7px;
  }

  &:after {
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme, withBorder }) => withBorder ? theme.colors.secondary :theme.colors.dark} transparent transparent transparent;
    content: "";
    height: 1px;
    left: calc(50% - 4.5px);
    position: absolute;
    top: 100%;
    width: 1px;
  }

  ${({ position, withBorder }) => {
    switch (position) {
      case 'bottom':
        return css`
          &:after {
            border-color: transparent transparent ${({ theme }) => withBorder ? theme.colors.secondary :theme.colors.dark} transparent;
            top: unset;
            bottom: 100%;
            left: calc(50% - 5px);
          }
        `;
      case 'left':
        return css`
          &:after {
            border-color: transparent transparent transparent ${({ theme }) => withBorder ? theme.colors.secondary :theme.colors.dark};
            left: 100%;
            top: calc(50% - 5px);
          }
        `;
      case 'right':
        return css`
          &:after {
            border-color: transparent ${({ theme }) => withBorder ? theme.colors.secondary :theme.colors.dark} transparent
              transparent;
            left: unset;
            right: 100%;
            top: calc(50% - 5px);
          }
        `;
    }
  }}
`;
