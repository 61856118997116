import React from 'react';
import { PillTagContainer, PillTagKind, PillTagSize } from './styles';

interface Iprops {
  className?: string;
  kind?: PillTagKind;
  size?: PillTagSize;
  children: React.ReactNode;
}

export const PillTag: React.FC<Iprops> = ({
  className = '',
  children,
  kind,
  size,
}) => (
  <PillTagContainer className={ className } size={ size } kind={ kind }>
    { children }
  </PillTagContainer>
);
