import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Celebrate: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}celebrate-graphic` }
    id={ id }
    height={ 78 }
    width={ 74 }
    fill='none'
  >
    <g clipPath='url(#clip0_4743_69531)'>
      <path d='M53.3764 59.726L1.38781 77.4256C1.24832 77.4732 1.09801 77.4792 0.955158 77.4429C0.81231 77.4067 0.683089 77.3297 0.583192 77.2213C0.483294 77.1129 0.417035 76.9779 0.392474 76.8326C0.367913 76.6873 0.386091 76.5379 0.44483 76.4028L21.8175 27.0264' fill='#fff' />
      <path d='M53.3764 59.726L1.38781 77.4256C1.24832 77.4732 1.09801 77.4792 0.955158 77.4429C0.81231 77.4067 0.683089 77.3297 0.583192 77.2213C0.483294 77.1129 0.417035 76.9779 0.392474 76.8326C0.367913 76.6873 0.386091 76.5379 0.44483 76.4028L21.8175 27.0264' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M54.5147 58.9639C57.3493 56.1293 52.4128 46.5969 43.4887 37.6727C34.5645 28.7486 25.0322 23.8121 22.1975 26.6467C19.3629 29.4814 24.2994 39.0137 33.2235 47.9379C42.1477 56.862 51.6801 61.7985 54.5147 58.9639Z' fill='#BABAC2' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M33.2241 47.9395C33.2241 47.9395 33.4599 62.217 41.8971 63.6353' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M16.8745 38.4336C16.8745 38.4336 13.4524 61.2473 28.2813 68.0914' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M8.12934 58.9658C8.12934 58.9658 4.32706 69.9924 11.5514 73.7947' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M30.9429 17.0264C33.1523 20.4144 34.4437 24.3183 34.6903 28.3556C34.9369 32.3929 34.1304 36.425 32.3497 40.0568' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M36.0454 44.5169C37.2431 42.1747 39.5207 40.6044 41.7488 39.1937C44.1138 37.6728 46.5359 36.2128 49.1671 35.2242C51.7983 34.2356 54.669 33.7489 57.4332 34.2356' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M36.8364 47.6078C41.8653 44.9915 47.5425 43.8825 53.1862 44.4139' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M32.8291 43.7373L47.8633 22.2734' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M57.5199 24.5886C59.8298 24.5886 61.7024 22.7161 61.7024 20.4061C61.7024 18.0962 59.8298 16.2236 57.5199 16.2236C55.21 16.2236 53.3374 18.0962 53.3374 20.4061C53.3374 22.7161 55.21 24.5886 57.5199 24.5886Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M40.2588 12.7675C41.7287 12.7675 42.9204 11.5759 42.9204 10.1059C42.9204 8.63597 41.7287 7.44434 40.2588 7.44434C38.7888 7.44434 37.5972 8.63597 37.5972 10.1059C37.5972 11.5759 38.7888 12.7675 40.2588 12.7675Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M52.8059 9.34623C53.8559 9.34623 54.7071 8.49506 54.7071 7.44509C54.7071 6.39511 53.8559 5.54395 52.8059 5.54395C51.756 5.54395 50.9048 6.39511 50.9048 7.44509C50.9048 8.49506 51.756 9.34623 52.8059 9.34623Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M60.0306 50.7907C61.7105 50.7907 63.0724 49.4288 63.0724 47.7489C63.0724 46.0689 61.7105 44.707 60.0306 44.707C58.3506 44.707 56.9888 46.0689 56.9888 47.7489C56.9888 49.4288 58.3506 50.7907 60.0306 50.7907Z' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M73.1178 33.962L69.7794 34.3688C69.6878 34.3804 69.6005 34.4141 69.5249 34.4671C69.4494 34.5201 69.3879 34.5908 69.3459 34.673L67.787 37.654C67.7378 37.7484 67.6631 37.8272 67.5715 37.8813C67.4799 37.9355 67.3749 37.963 67.2685 37.9606C67.162 37.9582 67.0584 37.9261 66.9693 37.8679C66.8802 37.8097 66.8091 37.7277 66.7642 37.6312L65.3459 34.5893C65.3074 34.5051 65.2487 34.4315 65.1751 34.3752C65.1016 34.3188 65.0153 34.2814 64.9239 34.2661L61.6083 33.7072C61.5037 33.6896 61.406 33.6431 61.3264 33.573C61.2467 33.5029 61.1882 33.412 61.1574 33.3105C61.1266 33.2089 61.1247 33.1008 61.152 32.9983C61.1792 32.8957 61.2345 32.8028 61.3117 32.73L63.7756 30.4487C63.8427 30.3851 63.8937 30.3063 63.9241 30.219C63.9546 30.1317 63.9636 30.0383 63.9505 29.9468L63.4562 26.6198C63.4408 26.5146 63.4551 26.4073 63.4974 26.3098C63.5398 26.2124 63.6085 26.1287 63.6959 26.0682C63.7832 26.0077 63.8858 25.9729 63.9919 25.9675C64.098 25.9622 64.2035 25.9866 64.2965 26.038L67.2395 27.6692C67.3209 27.7121 67.4116 27.7346 67.5037 27.7346C67.5958 27.7346 67.6865 27.7121 67.768 27.6692L70.7794 26.1711C70.8747 26.1237 70.9813 26.104 71.0872 26.114C71.1932 26.1241 71.2942 26.1636 71.3788 26.2281C71.4635 26.2926 71.5284 26.3795 71.5663 26.4789C71.6041 26.5784 71.6134 26.6864 71.593 26.7909L70.9505 30.095C70.9338 30.1854 70.9389 30.2783 70.9654 30.3663C70.9918 30.4542 71.0389 30.5346 71.1026 30.6008L73.4562 33.0038C73.5286 33.08 73.5785 33.1748 73.6005 33.2776C73.6224 33.3805 73.6155 33.4874 73.5804 33.5865C73.5454 33.6857 73.4837 33.7732 73.402 33.8395C73.3204 33.9057 73.222 33.9481 73.1178 33.962V33.962Z' fill='#FFDC00' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M24.9732 16.7801L21.3268 14.879C21.2454 14.836 21.1547 14.8136 21.0626 14.8136C20.9705 14.8136 20.8798 14.836 20.7983 14.879L17.1519 16.7801C17.0578 16.8289 16.952 16.8506 16.8463 16.8426C16.7406 16.8346 16.6393 16.7973 16.5536 16.735C16.4679 16.6726 16.4013 16.5875 16.3612 16.4894C16.3212 16.3913 16.3093 16.2839 16.3268 16.1794L17.0226 12.1223C17.0373 12.0347 17.0315 11.9449 17.0058 11.8598C16.9801 11.7747 16.9351 11.6967 16.8744 11.6319L13.9238 8.75734C13.8474 8.68311 13.7934 8.58899 13.7677 8.48563C13.7421 8.38226 13.7459 8.27377 13.7788 8.17247C13.8116 8.07117 13.8722 7.9811 13.9536 7.91243C14.035 7.84377 14.134 7.79926 14.2394 7.78396L18.3154 7.1908C18.4072 7.1782 18.4944 7.14315 18.5693 7.08876C18.6443 7.03437 18.7046 6.96232 18.7451 6.87901L20.5664 3.18699C20.6135 3.09158 20.6863 3.01125 20.7767 2.95508C20.8671 2.89891 20.9714 2.86914 21.0778 2.86914C21.1842 2.86914 21.2885 2.89891 21.3788 2.95508C21.4692 3.01125 21.5421 3.09158 21.5892 3.18699L23.4105 6.87901C23.4509 6.96232 23.5113 7.03437 23.5862 7.08876C23.6612 7.14315 23.7484 7.1782 23.8401 7.1908L27.9162 7.78396C28.0216 7.79926 28.1206 7.84377 28.202 7.91243C28.2834 7.9811 28.3439 8.07117 28.3768 8.17247C28.4096 8.27377 28.4134 8.38226 28.3878 8.48563C28.3622 8.58899 28.3081 8.68311 28.2318 8.75734L25.285 11.6319C25.2178 11.696 25.1674 11.7756 25.1382 11.8638C25.109 11.952 25.102 12.046 25.1177 12.1376L25.8135 16.1946C25.8297 16.3 25.8161 16.4079 25.7741 16.5059C25.7321 16.604 25.6634 16.6883 25.5759 16.7493C25.4884 16.8103 25.3855 16.8455 25.279 16.851C25.1724 16.8564 25.0665 16.8319 24.9732 16.7801V16.7801Z' fill='#FFDC00' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    </g>
    <defs>
      <clipPath id='clip0_4743_69531'>
        <rect width='74' height='77.8479' fill='#fff' />
      </clipPath>
    </defs>
  </SvgIcon>
);
