import React, { FC } from 'react';
import { SvgIcon } from '../../../SvgIcon';
import { theme } from '../../../../../styles/themes';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const BCorpLogo: FC<IProps> = ({
  className = '',
  id = '',
  fill = theme.colors.primary,
}) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }b-corp-logo` }
    id={ id }
    width={ 49 }
    height={ 83 }
    fill={ fill }
  >
    <g clipPath='url(#clip0_7558_9873)'>
      <path d='M0.324219 38.0171C0.324219 24.5932 11.1951 13.7223 24.6103 13.7302C37.9613 13.7382 48.8996 24.6107 48.8957 37.8703C48.8917 51.4387 38.0835 62.3112 24.5984 62.312C11.1792 62.312 0.323425 51.4482 0.324219 38.0171ZM45.68 38.0481C45.6974 26.459 36.2661 16.9967 24.6532 16.9721C13.0911 16.9475 3.73675 26.2137 3.54073 37.668C3.34153 49.3293 12.6435 59.0185 24.435 59.0971C36.1852 59.1756 45.6625 49.7325 45.68 38.0473V38.0481Z' fill={ fill } />
      <path d='M48.7737 67.4507V70.5855H0.359375V67.4507H48.7737Z' fill={ fill } />
      <path d='M8.28613 3.66483H6.45288C6.10845 2.52282 5.09581 1.95142 3.92126 2.22045C3.25701 2.37283 2.78401 2.77598 2.50784 3.38706C2.01738 4.47511 1.98802 5.59093 2.43562 6.69563C2.82528 7.65511 3.68318 8.16461 4.6617 8.07652C5.6188 7.99002 6.16639 7.4702 6.4386 6.3893C6.45764 6.31232 6.4751 6.23534 6.50367 6.11154H8.33295C8.37263 7.95748 6.83461 9.56772 4.9593 9.68279C2.12928 9.8558 0.233345 8.09081 0.192077 5.25126C0.178586 4.28067 0.367466 3.36246 0.853156 2.51488C2.16103 0.232453 5.63387 -0.205621 7.4195 1.68953C7.93376 2.23553 8.20914 2.88947 8.28613 3.66483Z' fill={ fill } />
      <path d='M47.0261 3.73868V0.747559H48.7609V9.48761H47.1197V8.73209C46.6039 9.42174 45.9602 9.65903 45.2166 9.66856C44.0278 9.68284 43.0937 9.07414 42.6525 7.96626C42.2033 6.83615 42.1874 5.69256 42.6755 4.57277C43.2445 3.26569 44.646 2.64032 45.9626 3.11649C46.3348 3.25061 46.6586 3.51965 47.0261 3.73948V3.73868ZM44.0405 6.36475C44.1286 6.69648 44.1881 7.13534 44.3611 7.52342C44.6206 8.10593 45.1047 8.39084 45.7658 8.33449C46.3301 8.28688 46.7554 7.94166 46.9491 7.32343C47.1435 6.70521 47.1554 6.07349 46.9927 5.44812C46.8015 4.71403 46.3007 4.30532 45.6206 4.29818C44.9301 4.29104 44.423 4.67753 44.2135 5.40368C44.1341 5.67986 44.1087 5.97191 44.0397 6.36475H44.0405Z' fill={ fill } />
      <path d='M41.8396 6.75933H37.3215C37.2215 7.29819 37.4549 7.8561 37.8723 8.1164C38.5643 8.54813 39.5524 8.37036 40.0579 7.73388C40.1293 7.64421 40.2492 7.54183 40.3515 7.53707C40.7856 7.51564 41.2213 7.52754 41.692 7.52754C41.5435 8.14497 41.2451 8.63225 40.7944 9.01715C39.7071 9.94727 37.1247 10.0274 36.0311 8.33861C35.0312 6.79504 35.2494 3.74043 37.8898 3.07221C39.8595 2.57382 41.5832 3.79122 41.7919 5.82207C41.8229 6.11968 41.8237 6.42125 41.8396 6.76012V6.75933ZM40.023 5.61891C39.8976 4.67768 39.3675 4.22374 38.5231 4.30151C37.7826 4.36976 37.2049 4.99275 37.3072 5.61891H40.023Z' fill={ fill } />
      <path d='M15.3039 6.75524H10.7319C10.6851 7.47663 10.9954 8.02026 11.547 8.23453C12.2231 8.49642 13.0263 8.33453 13.4286 7.80122C13.5961 7.57901 13.7627 7.50996 14.0199 7.52266C14.3746 7.54012 14.7317 7.52742 15.142 7.52742C14.9325 8.26072 14.5818 8.83609 13.9548 9.21702C12.5041 10.0987 10.3724 9.68208 9.48042 8.34723C8.49793 6.87746 8.83204 4.63868 10.1859 3.61254C12.1255 2.14356 14.7849 3.18319 15.196 5.57831C15.2595 5.94893 15.2658 6.32986 15.3031 6.75524H15.3039ZM10.7081 5.61958H13.4485C13.408 4.82041 12.9477 4.3371 12.2065 4.2998C11.3811 4.25854 10.8391 4.72359 10.7081 5.61958Z' fill={ fill } />
      <path d='M21.7544 4.32183H20.7227V3.15125H21.7369V1.26562H23.5265V3.13061H24.7899V4.31151H23.5273C23.5273 4.7853 23.5273 5.21702 23.5273 5.64954C23.5273 6.24475 23.5217 6.83996 23.5297 7.43437C23.5376 8.05101 23.6757 8.18196 24.2812 8.18037C24.4391 8.18037 24.5979 8.18037 24.7828 8.18037V9.49856C24.0669 9.58982 23.3463 9.63506 22.6424 9.42078C22.1091 9.25809 21.7996 8.87081 21.7877 8.30655C21.7639 7.15661 21.7639 6.00666 21.7551 4.85672C21.7536 4.70117 21.7551 4.54483 21.7551 4.32262L21.7544 4.32183Z' fill={ fill } />
      <path d='M32.2061 0.784811V2.04348C30.9402 2.14109 30.8426 2.22522 30.9593 3.1347H32.1076V4.32194H30.9236V9.48597H29.161V4.32988H28.1562V3.13866H29.1594C29.1395 2.31807 29.2054 1.56096 29.9173 1.08162C30.6164 0.61101 31.3989 0.695926 32.2061 0.784811Z' fill={ fill } />
      <path d='M5.9762 76.8612C5.76669 76.8612 5.58098 76.8477 5.39845 76.8644C4.98657 76.9017 4.71356 76.8326 4.46834 76.3945C4.00725 75.5692 2.71604 75.5898 2.12877 76.362C1.55816 77.112 1.53673 78.5563 2.09226 79.3238C2.46526 79.8396 2.9946 79.9975 3.59774 79.9118C4.17153 79.8301 4.49691 79.4531 4.65722 78.9159C4.68737 78.8151 4.71515 78.7127 4.75007 78.5897H6.03413C5.97699 80.2126 4.58262 81.303 2.92 81.0761C0.556623 80.7523 -0.117154 78.3397 0.564559 76.5049C1.20262 74.7883 3.30808 74.0859 4.88101 75.0335C5.5286 75.4232 5.94604 76.1017 5.9762 76.862V76.8612Z' fill={ fill } />
      <path d='M15.4258 82.5V76.5193H16.5575C16.5781 76.6288 16.6011 76.7518 16.6337 76.9248C17.0233 76.5185 17.4765 76.3296 18.0177 76.3574C18.7518 76.3947 19.3185 76.7137 19.6415 77.3748C20.0581 78.2256 20.0708 79.1049 19.6962 79.9779C19.2327 81.058 17.7622 81.5072 16.6622 80.4945V82.5H15.4258ZM18.7494 78.7573C18.6844 78.4779 18.6478 78.1875 18.5463 77.9216C18.3804 77.4867 18.0264 77.2772 17.5908 77.2994C17.1733 77.3209 16.867 77.555 16.7225 77.9899C16.5599 78.4795 16.5559 78.9771 16.7281 79.4636C16.9138 79.989 17.3908 80.2263 17.9312 80.0874C18.4296 79.9588 18.6978 79.5017 18.7486 78.7573H18.7494Z' fill={ fill } />
      <path d='M16.0664 9.49156V3.16012H17.6989V4.09975C18.3322 3.31249 19.0663 2.85299 20.117 3.01806V4.64973C19.9472 4.63544 19.8043 4.61957 19.6615 4.61084C18.5861 4.54576 17.8838 5.14256 17.829 6.23298C17.7822 7.15675 17.7997 8.08369 17.7893 9.00904C17.7878 9.1638 17.7893 9.31934 17.7893 9.49156H16.0672H16.0664Z' fill={ fill } />
      <path d='M29.2391 77.8651C29.2693 77.2128 29.5732 76.7977 30.1232 76.5771C30.9152 76.2596 31.7271 76.2715 32.527 76.543C33.0262 76.7128 33.2929 77.089 33.2976 77.6278C33.3032 78.2357 33.3024 78.8444 33.3032 79.4523C33.3032 79.938 33.3032 80.4237 33.3032 80.9293H32.2064C32.1651 80.8221 32.1231 80.7134 32.0731 80.5832C31.5191 81.0261 30.8843 81.1356 30.2041 81.03C29.5716 80.9316 29.1447 80.4951 29.0978 79.9126C29.0375 79.1563 29.3161 78.6857 30.0137 78.4992C30.4954 78.3706 31.0009 78.3325 31.4938 78.2413C31.962 78.1548 32.1382 77.9357 32.0144 77.5826C31.9747 77.4691 31.8771 77.3501 31.7739 77.2905C31.3319 77.0358 30.739 77.2334 30.4994 77.6834C30.4581 77.7604 30.3605 77.8532 30.2843 77.8572C29.9446 77.8762 29.6034 77.8651 29.2383 77.8651H29.2391ZM32.016 78.8476C31.5803 78.9389 31.1652 79.0095 30.7605 79.1174C30.4573 79.1984 30.3097 79.4238 30.3422 79.7452C30.3748 80.069 30.6033 80.1801 30.8843 80.2221C31.7041 80.3436 32.1183 79.8531 32.0167 78.8484L32.016 78.8476Z' fill={ fill } />
      <path d='M8.90118 76.3599C10.2979 76.3646 11.1995 77.2908 11.2003 78.7224C11.2011 80.1383 10.2813 81.07 8.88372 81.0684C7.46077 81.0668 6.56478 80.1438 6.57034 78.6859C6.57589 77.2678 7.49013 76.3559 8.90118 76.3607V76.3599ZM7.76076 78.6931C7.8425 79.0082 7.88853 79.324 8.00836 79.6089C8.16074 79.97 8.49009 80.1319 8.87022 80.1319C9.26306 80.1319 9.60035 79.9732 9.76859 79.5954C10.0345 78.9994 10.04 78.3923 9.74875 77.8019C9.57416 77.4479 9.25751 77.2995 8.8734 77.3003C8.48612 77.3003 8.16708 77.4558 8.01154 77.8169C7.89488 78.0891 7.84409 78.3907 7.76076 78.6923V78.6931Z' fill={ fill } />
      <path d='M25.1422 78.6945C25.147 80.1619 24.2597 81.0729 22.8312 81.0682C21.4281 81.0634 20.5242 80.1397 20.5274 78.7127C20.5305 77.2811 21.4329 76.3605 22.8328 76.3589C24.2494 76.3573 25.1382 77.2557 25.1422 78.6937V78.6945ZM21.7281 78.7096C21.8051 79.0159 21.8495 79.3024 21.9511 79.5675C22.0995 79.9547 22.4336 80.1254 22.8264 80.1285C23.2169 80.1325 23.5574 79.9778 23.7264 79.5984C23.9907 79.0032 23.9986 78.3977 23.7113 77.8064C23.5375 77.4493 23.2256 77.2993 22.8399 77.3001C22.455 77.3001 22.1289 77.4453 21.9773 77.8112C21.859 78.0961 21.809 78.4096 21.7289 78.7096H21.7281Z' fill={ fill } />
      <path d='M43.7869 78.7245C43.7837 80.1522 42.883 81.0633 41.4719 81.0665C40.0704 81.0696 39.1594 80.1459 39.1602 78.7205C39.161 77.2682 40.0673 76.3508 41.4934 76.358C42.8893 76.3651 43.7893 77.2928 43.7861 78.7237L43.7869 78.7245ZM40.3633 78.6928C40.4268 78.9864 40.4569 79.2515 40.5426 79.4967C40.6894 79.9157 41.0228 80.1276 41.4537 80.1308C41.8783 80.134 42.2259 79.9506 42.3894 79.5316C42.6028 78.9816 42.606 78.4229 42.379 77.8769C42.2148 77.4833 41.8918 77.3016 41.4704 77.3C41.0656 77.2992 40.7402 77.465 40.5871 77.8452C40.476 78.1206 40.4323 78.4229 40.3633 78.6928Z' fill={ fill } />
      <path d='M48.7294 80.9351H47.5088C47.5088 80.12 47.512 79.3185 47.5064 78.5169C47.5048 78.3201 47.4937 78.1201 47.458 77.9273C47.3922 77.5741 47.1763 77.3638 46.8073 77.3463C46.4247 77.3281 46.0724 77.4186 45.9398 77.8193C45.8502 78.0892 45.816 78.3868 45.8089 78.6733C45.7914 79.4121 45.8033 80.1518 45.8033 80.92H44.5859V76.5162H45.72C45.7367 76.6535 45.7533 76.7916 45.7764 76.9797C45.8787 76.8916 45.9605 76.8202 46.0446 76.7503C46.5993 76.2908 47.2303 76.2607 47.8778 76.4718C48.4738 76.6662 48.708 77.1614 48.723 77.7368C48.75 78.8002 48.731 79.8645 48.731 80.9343L48.7294 80.9351Z' fill={ fill } />
      <path d='M33.1797 3.16089H34.9026V9.48677H33.1797V3.16089Z' fill={ fill } />
      <path d='M25.6875 9.48463V3.16113H27.4088V9.48463H25.6875Z' fill={ fill } />
      <path d='M36.6267 77.3137H35.7958C35.7958 78.1192 35.7887 78.8921 35.7998 79.6659C35.8029 79.9032 35.9648 80.0111 36.2005 80.0072C36.3434 80.0048 36.487 80.0072 36.6513 80.0072V81.0254C36.1474 80.9833 35.6617 80.9849 35.1942 80.892C34.7649 80.8071 34.5633 80.4778 34.5586 80.0056C34.5506 79.2659 34.5562 78.5255 34.5562 77.7858C34.5562 77.6438 34.5562 77.5009 34.5562 77.3375C34.2863 77.3192 34.0562 77.3041 33.8086 77.2875V76.5153C34.0387 76.5018 34.2681 76.4891 34.5348 76.4732V75.1772H35.7672V76.47H36.6259V77.3152L36.6267 77.3137Z' fill={ fill } />
      <path d='M13.1103 80.9279H11.8945V76.5146H13.0453V77.0495C13.4349 76.8273 13.8008 76.5511 14.2127 76.4011C14.6722 76.2337 14.7682 76.344 14.769 76.8289C14.769 77.0273 14.765 77.2249 14.7603 77.4233C14.7603 77.4471 14.742 77.4709 14.7206 77.5241C14.6301 77.5241 14.5293 77.5257 14.4277 77.5241C13.6071 77.513 13.1754 77.9058 13.1405 78.7399C13.1151 79.3335 13.1334 79.9295 13.1294 80.5239C13.1294 80.6525 13.1183 80.7811 13.1111 80.9295L13.1103 80.9279Z' fill={ fill } />
      <path d='M25.9492 76.5114H27.1007V77.0551C27.4682 76.8376 27.8174 76.5582 28.2158 76.413C28.7284 76.2257 28.8189 76.3273 28.8197 76.8559C28.8197 77.0654 28.8197 77.2749 28.8197 77.5241C28.6888 77.5241 28.5888 77.5257 28.4896 77.5241C27.6499 77.5106 27.2119 77.9145 27.1857 78.7629C27.1666 79.397 27.1809 80.0319 27.1785 80.6668C27.1785 80.7438 27.169 80.8215 27.1626 80.9231H25.9492V76.5114Z' fill={ fill } />
      <path d='M37.3164 76.5112H38.5036V80.9277H37.3164V76.5112Z' fill={ fill } />
      <path d='M33.1758 2.15378V0.73877H34.9003V2.15378H33.1758Z' fill={ fill } />
      <path d='M25.7031 0.731689H27.4078V2.14908H25.7031V0.731689Z' fill={ fill } />
      <path d='M47.2908 62.5508C48.0947 62.5453 48.7534 63.1905 48.7645 63.9944C48.7756 64.7896 48.1217 65.4507 47.3154 65.4594C46.4861 65.4689 45.8464 64.8547 45.8361 64.0396C45.825 63.196 46.4503 62.5564 47.2908 62.5508ZM48.4709 63.9936C48.4661 63.3222 47.9558 62.7929 47.309 62.7889C46.6297 62.7849 46.121 63.3055 46.1218 64.0039C46.1226 64.7055 46.6313 65.2372 47.2995 65.234C47.9622 65.2308 48.4764 64.6872 48.4709 63.9936Z' fill={ fill } />
      <path d='M38.4958 75.7828H37.3125V74.8066H38.4958V75.7828Z' fill={ fill } />
      <path d='M31.03 37.1688C31.6926 37.4291 32.3434 37.6203 32.9338 37.9283C34.9695 38.9909 35.9908 40.7583 36.2123 42.9875C36.4519 45.4009 35.844 47.5826 34.1616 49.388C32.7838 50.8657 31.0316 51.6006 29.0491 51.8157C28.4991 51.8752 27.9412 51.8847 27.3865 51.8847C23.2208 51.8903 19.0552 51.8879 14.8903 51.8879C14.7593 51.8879 14.6276 51.8879 14.4609 51.8879V24.1908C14.5839 24.1821 14.7228 24.1646 14.8609 24.1646C18.6298 24.1631 22.3986 24.1504 26.1675 24.1702C27.5809 24.1773 28.9944 24.2488 30.3768 24.5972C32.8307 25.2146 34.7933 27.5026 35.0456 30.0271C35.2464 32.0325 34.8607 33.8665 33.5068 35.4244C32.8275 36.2061 31.9767 36.7545 31.0308 37.168L31.03 37.1688ZM18.1608 48.7428C18.2425 48.7539 18.2933 48.7666 18.3441 48.7666C21.3709 48.7674 24.3978 48.7761 27.4246 48.7627C28.5944 48.7571 29.7078 48.5047 30.6768 47.804C32.0228 46.8302 32.5474 45.4398 32.5608 43.8478C32.5751 42.1765 31.976 40.8003 30.4149 39.9829C29.3364 39.4179 28.1674 39.2179 26.9698 39.2099C24.1549 39.1917 21.3392 39.2044 18.5234 39.2036C18.4084 39.2036 18.2933 39.2036 18.16 39.2036V48.742L18.1608 48.7428ZM18.1576 36.0704C18.3465 36.0704 18.4909 36.0704 18.6353 36.0704C20.9622 36.0704 23.2883 36.0863 25.6152 36.0609C26.3643 36.053 27.1246 35.9958 27.8595 35.8561C29.5737 35.5292 30.9212 34.7046 31.2625 32.8436C31.3887 32.1547 31.3847 31.4198 31.3117 30.7199C31.1403 29.089 30.2086 28.024 28.6198 27.6296C27.8952 27.4502 27.1373 27.3264 26.3929 27.3153C23.7494 27.274 21.1058 27.2915 18.4623 27.2891C18.3623 27.2891 18.2623 27.3089 18.1584 27.32V36.0704H18.1576Z' fill={ fill } />
      <path d='M46.7344 63.1588C46.9582 63.1588 47.1542 63.1596 47.3502 63.1588C47.6121 63.1572 47.8748 63.1778 47.9629 63.4786C48.0542 63.7921 47.9256 64.0198 47.5812 64.1381C47.7169 64.3635 47.8423 64.5722 47.9772 64.7968C47.647 64.9515 47.6224 64.6452 47.4986 64.5039C47.3748 64.3635 47.2534 64.2214 47.1328 64.0825C46.8828 64.342 47.2264 64.765 46.7344 64.8809V63.1596V63.1588ZM47.0367 63.9675C47.2883 63.8833 47.4796 63.831 47.6566 63.7484C47.6843 63.7357 47.6716 63.5215 47.6383 63.5095C47.455 63.4437 47.259 63.4119 47.0367 63.3611V63.9667V63.9675Z' fill={ fill } />
    </g>
    <defs>
      <clipPath id='clip0_7558_9873'>
        <rect width='48.7056' height='82' fill='white' transform='translate(0.191406 0.5)' />
      </clipPath>
    </defs>
  </SvgIcon>
);
