import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexRow } from '../../../styles/styles';

export const JourneySectionContainer = styled(Section)`
  ${FlexRow}
  background-color: ${props => props.theme.colors.dark};
  min-height: 765px;
  overflow: hidden;
  padding: 80px 15px;
  position: relative;
  width: 100%;

  .wave-a {
    height: 856px;
    position: absolute;
    right: -75%;
    top: 50px;
    transform: rotate(-80deg);

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      right: -40%;
    }

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      right: -20%;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      right: 0%;
      top: -100px;
    }
  }
`;

export const JourneySectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexCol}
  align-items: center;
  max-width: 1064px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    align-items: flex-start;
    background-image: url('https://cdn.karmawallet.io/uploads/kw_timeline.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .kw-timeline {
    height: auto;
    width: 100%;
  }
`;

export const TextContent = styled.div`
  max-width: 480px;
  margin-bottom: 40px;
  width: 100%;

  h2 {
    color: ${({ theme }) => theme.colors.white};  
    font-size: 40px;
  }

  p {
    color: ${({ theme }) => theme.colors.white};  
    line-height: 1.75rem;

    & * {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
