import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const UnsdgNoDataIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }no-data-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    fill={ fill }
  >
    <path d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z' fill={ fill } />
    <path d='M6.93433 9.31321C6.93738 8.91972 7.01011 8.52988 7.14917 8.16177C7.27769 7.82462 7.47193 7.51634 7.7206 7.25489C7.96973 6.99543 8.27076 6.79139 8.60404 6.65605C8.97166 6.50826 9.36495 6.43506 9.76113 6.44065C10.2308 6.42745 10.6986 6.505 11.1388 6.66917C11.4698 6.7965 11.7722 6.98823 12.0286 7.2332C12.2399 7.43774 12.4046 7.68547 12.5114 7.95948C12.6026 8.19142 12.6517 8.43772 12.6566 8.68688C12.6664 8.99939 12.6172 9.31097 12.5114 9.6052C12.4244 9.82822 12.3027 10.0361 12.1508 10.2212C12.0122 10.3876 11.8517 10.5345 11.6737 10.6578C11.4992 10.7778 11.3347 10.898 11.18 11.0184C11.0253 11.1377 10.8864 11.2763 10.7668 11.4309C10.6398 11.6005 10.5609 11.8013 10.5383 12.0121V12.4538H8.96802V11.9309C8.98085 11.6425 9.04587 11.3589 9.15998 11.0938C9.25425 10.8854 9.37757 10.6915 9.52626 10.5177C9.65897 10.3654 9.80703 10.2272 9.96802 10.1052C10.1227 9.98863 10.2661 9.87245 10.3983 9.75664C10.5249 9.64667 10.633 9.51701 10.7183 9.37264C10.804 9.21239 10.8423 9.03099 10.8286 8.84976C10.8405 8.70701 10.8235 8.56333 10.7786 8.42733C10.7336 8.29132 10.6616 8.1658 10.5669 8.05833C10.4687 7.96972 10.3538 7.90149 10.229 7.85764C10.1043 7.81379 9.97205 7.79519 9.84002 7.80289C9.6525 7.79781 9.46666 7.83965 9.29942 7.92461C9.15208 8.00321 9.0248 8.11469 8.92742 8.25036C8.8266 8.39458 8.75374 8.5564 8.71258 8.72748C8.66473 8.91958 8.64134 9.11692 8.64289 9.31488L6.93433 9.31321Z' fill='white' />
    <path d='M9.73662 13.1504H9.70176C9.20723 13.1504 8.80634 13.5513 8.80634 14.0458C8.80634 14.5404 9.20723 14.9412 9.70176 14.9412H9.73662C10.2312 14.9412 10.632 14.5404 10.632 14.0458C10.632 13.5513 10.2312 13.1504 9.73662 13.1504Z' fill='white' />
  </SvgIcon>
);
