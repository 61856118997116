import styled from 'styled-components';
import { BasicContainer, FlexCenter, FlexHorizontalCenter, TruncateText } from '../../styles/styles';

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Trigger = styled.button`
  ${ FlexHorizontalCenter }
  justify-content: space-between;
  background: none;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }

  .selected-dropdown-option {
    font-size: 16px;
    text-align: left;
    font-weight: 500;
    text-overflow: ellipsis;
  }

  .chevron-container {
    width: 10%;
  }
`;

export const FormTrigger = styled(Trigger)``;

export const Option = styled.button`
  ${TruncateText}
  min-width: 100%;
  max-width: 100%;
  padding: 4px 15px;
  border: none;  
  background: none;
  text-align: left;

  &:hover:not(:disabled) {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.lightGray2};
  }

  &:focus-visible {
    background: ${({ theme }) => theme.colors.lightGray2};
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.lightGray1};
  }
`;

export const OptionsContainer = styled.div`
  ${ BasicContainer }
  position: absolute;
  top: calc(100% - 2px);
  width: 100%;
  left: 0;
  padding: 16px 0;
  min-width: 200px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #fff;
  z-index: 10;
  overflow: hidden;
`;

export const RegularTrigger = styled(Trigger)`
  ${ BasicContainer }
  padding: 10px 14px;

  &.is-open {
    border-bottom: 2px solid #fff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      width: 100%;
      height: 4px;
      background: #fff;
      z-index: 11;
    }
  }

  .chevron-container {
    ${ FlexCenter }
    min-width: 10px;
    margin-left: 15px;
    stroke: ${({ theme }) => theme.colors.darkGray1};
    
    svg {
      width: 100%;
      height: 10px;
    }
  }
`;
