import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { ButtonKind } from '../Button/styles';
import { EyeIcon } from '../svgs/icons/EyeIcon';
import { EyeWithSlashIcon } from '../svgs/icons/EyeWithSlashIcon';
import { EyeToggleContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  show: boolean;
  onClick(): void;
}

export const EyeToggleBase: React.FC<IProps> = ({
  className = '',
  theme,
  show,
  onClick,
}) => (
  <EyeToggleContainer
    className={ className }
    kind={ ButtonKind.Blank }
    onClick={ onClick }
  >
    {
      show
        ? <EyeIcon fill={ theme.colors.disabled } />
        : <EyeWithSlashIcon fill={ theme.colors.disabled } />
    }
  </EyeToggleContainer>
);

export const EyeToggle = withTheme(EyeToggleBase);
