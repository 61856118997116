import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexColCenter } from '../../../styles/styles';
import { TextLGDemiBold } from '../../../styles/components/text';

export const HeroSectionContainer = styled(Section)`
  ${FlexColCenter}
  background-blend-mode: darken;
  background-color: ${({ theme }) => theme.colors.dark + 'DD'};
  background-image: url('https://cdn.karmawallet.io/uploads/vVLYtoDrZ7-pexels-bertellifotografia-3321797.jpg');
  background-size: cover;
  background-position: bottom 30% center;
  background-repeat: no-repeat;
  min-height: calc(100vh - 75px);
`;

export const HeroSectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  background-color: transparent;
  height: 100%;

  .generic-tag {
    align-self: unset;
    margin: unset;;
  }
  
  h1 {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 48px;
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 71px;
     }
  }

  .section-sub-text {
    ${TextLGDemiBold}
    color: ${({ theme }) => theme.colors.white};
    max-width: 500px;
    margin-bottom: 20px;
    text-align: center;
    width: 90%;
  }
`;

export const AdoptionStatementContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark};
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  border-radius: 999px;
  margin-top: 80px;
  max-width: 620px;
  padding: 13px 65px;

  @media (${({ theme }) => theme.breakpoints.xxsMin}){
    margin-top: 135px;
  }

  p {
    color: ${({ theme }) => theme.colors.tertiary};
    font-size: 21px;
    font-weight: 500;
    line-height: 1.8rem;
    margin: unset;
    text-align: center;
    width: 70%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      width: 100%;
    }
  }
`;
