import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkInlineLinks from 'remark-inline-links';
import { ButtonKind } from '../../components/Button/styles';
import { CTAs, CtaType, ICta } from '../../components/CTAs';
import { JobPostingDetails } from '../../components/JobPostingDetails';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { Markdown } from '../../components/Markdown';
import { JobPostingModel } from '../../models/job-postings';
import { H1, H3 } from '../../styles/components/header';
import { ActionsContainer, CareerContainer, CareerInner, Header, JobDescription } from './styles';
import { Circle } from '../../components/svgs/shapes/Circle';
import { Triangle } from '../../components/svgs/shapes/Triangle';
import { Boomerang } from '../../components/svgs/shapes/Boomerang';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useAnalytics } from '../../contexts/analytics-store';

interface IProps {
  className?: string;
}

const CareerBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const errorMessages = useErrorMessages();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [job, setJob] = useState<JobPostingModel | null>(null);
  // const preview = useMemo<ILinkPreviewProps>(() => ({
  //   img: 'https://s3.amazonaws.com/assets.karmawallet/kw_logo.png',
  //   title: job?.title || '',
  //   description: !!job?.description ? `${job?.description?.slice(0, 300)}...` : '',
  //   url: window.location.href,
  // }), [job]);

  useEffect(() => {
    if (!jobId) navigate('/careers');
    
    JobPostingModel.load(jobId as string)
      .then(_job => setJob(_job))
      .catch(err => {
        analytics.fireEvent('Career_LoadingError');
        errorMessages.push({
          title: 'Error Loading Job Posting',
          message: err.message,
        });
      });
  }, [jobId]);

  const onApplyNowClick = useCallback(() => {
    analytics.fireEvent('Career_Apply_Click', `${job?._id}`);
  }, [job]);

  const onViewOtherOpeningsClick = useCallback(() => {
    analytics.fireEvent('Career_View_Others', `${job?._id}`);
  }, [job]);

  const renderActions = (prefix: string) => {
    const ctas: ICta[] = [
      {
        id: `${prefix}-view-other-openings`,
        text: 'View Other Openings',
        kind: ButtonKind.PrimaryGhost,
        ctaType: CtaType.Link,
        url: '/careers',
        onClick: onViewOtherOpeningsClick,
      },
    ];

    if (!!job?.applicationUrl) {
      ctas.push({
        id: `${prefix}-apply-now`,
        text: 'Apply Now',
        kind: ButtonKind.Primary,
        ctaType: CtaType.Link,
        url: job.applicationUrl,
        openNewTab: true,
        onClick: onApplyNowClick,
      });
    }

    return (
      <ActionsContainer>
        { !!job?.instructions && <p className='job-instructions'>{ job?.instructions }</p> }
        <CTAs
          className='job-ctas'
          ctas={ ctas }
        />
      </ActionsContainer>
    );
  };

  const renderJob = () => {
    if (!job || job.busy) return <LoadingSpinner />;

    return (
      <CareerInner>
        <Header>
          <div className='header-inner'>
            <H1>{ job.title }</H1>
            <JobPostingDetails className='job-details' job={ job } />
            { renderActions('top') }
          </div>
        </Header>
        <JobDescription>
          <H3>About the role</H3>
          <Markdown
            className='job-description'
            content={ job.description }
            rehypePlugins={ [rehypeRaw] }
            remarkPlugins={ [remarkGfm, remarkInlineLinks] }
          />
        </JobDescription>
        { renderActions('bottom') }
        <Circle className='circle' />
        <Triangle className='triangle' />
        <Boomerang className='boomerang' />
      </CareerInner>
    );
  };

  return (
    <CareerContainer
      className={ className }
      title={ job?.title }
    >
      { renderJob() }
    </CareerContainer>
  );
};

export const Career = observer(CareerBase);
