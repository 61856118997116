import dayjs from 'dayjs';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { getLocalStorageItemWithExpiry, setLocalStorageItemWithExpiry } from '../lib/localStorage';
import { BaseModel } from './base';

type PrivateFields = '_busy' |
'_linkToken' |
'_onError' |
'_onSuccess' |
'_preventPlaidExec';

interface IPlaidCreateLinkTokenResponse {
  expiration: string;
  link_token: string;
  request_id: string;
  userId: string;
}

export interface IPlaid {
  error: ErrorEvent;
  ready: boolean;
  exit(): void;
  open(): void;
}
export class PlaidModel extends BaseModel {
  private _busy = false;
  private _linkToken: string = null;
  private _preventPlaidExec = false;
  private _onError: () => void = null;
  private _onSuccess: () => void = null;

  constructor () {
    super();
    makeObservable<PlaidModel, PrivateFields>(this, {
      _busy: observable,
      _linkToken: observable,
      _onError: observable,
      _onSuccess: observable,
      _preventPlaidExec: observable,
      busy: computed,
      linkToken: computed,
      createLinkToken: action.bound,
      resetLinkToken: action.bound,
    });
  }

  get busy() { return this._busy; }
  get linkToken() { return this._linkToken; }
  get linkTokenFromLocalStorage() { return getLocalStorageItemWithExpiry('oauthConfig'); }
  get onError() { return this._onError; }
  get onSuccess() { return this._onSuccess; }
  get preventPlaidExec() { return this._preventPlaidExec; }
  
  public createLinkToken = async (cardId: string, onSuccess: () => void, onError?: () => void) => {
    if (this._busy) return;
    this._preventPlaidExec = false;
    this._busy = true;
    this._onSuccess = onSuccess;
    this._onError = onError;

    const result = await this.webServiceHelper.sendRequest<IPlaidCreateLinkTokenResponse>({
      path: '/integrations/plaid/create-link-token',
      method: 'POST',
      data: { cardId },
    });

    if (result.success) {
      runInAction(() => {
        this._linkToken = result.value.link_token;
        setLocalStorageItemWithExpiry('oauthConfig', this._linkToken, dayjs(result.value.expiration));
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public exchangeLinkToken = async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<void>({
      path: '/integrations/plaid/exchange-public-token',
      method: 'POST',
      data: { publicToken, metadata },
    });

    if (result.success) {
      runInAction(() => {
        this.resetLinkToken();
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public resetLinkToken = () => {
    this._preventPlaidExec = true;
    this._linkToken = null;
    window.localStorage.removeItem('oauthConfig');
  };
}
