import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { ButtonLink } from '../../components/Button';

export const KarmaCollectivePageContainer = styled(MainContainer)`
  p {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    line-height: 22px;
  }

  h2 {
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 40px;
    }
  }
`;

export const ApplyButton = styled(ButtonLink)`
  font-size: 16px;
  padding: 9px 28px;
`;
