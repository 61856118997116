import React, { useMemo } from 'react';
import { AboutImageContainer, HeroSectionContainer, HeroSectionInnerContainer } from './styles';
import { H1M } from '../../../styles/components/header';

interface IProps {
  className?: string;
}

export const HeroSection: React.FC<IProps> = ({
  className,
}) => {
  const videoURL = 'https://s3.amazonaws.com/cdn.karmawallet.io/videos/about-us.mp4';
  const videoPlaceholder = 'https://cdn.karmawallet.io/videos/about-us-placeholder.png';

  const videoElement = useMemo(() => (
    <div className='about-us-video-container'>
      <video
        autoPlay={ false }
        poster={ videoPlaceholder }
        controls
        id='homepage-video'
        muted={ false }
        playsInline
      >
        <source src={ videoURL } type='video/mp4' />
      </video>
    </div> 
  ), []);

  return (
    <HeroSectionContainer className={ className }>
      <HeroSectionInnerContainer>
        <H1M>Karma Wallet offers financial products that enable you to create a better future every single day.</H1M>
        { videoElement }
        <AboutImageContainer>
          <div className='image image-1' />
          <div className='image image-2' />
          <div className='image image-3' />
          <div className='image image-4' />
          <div className='image image-5' />
        </AboutImageContainer>
      </HeroSectionInnerContainer>
    </HeroSectionContainer>
  );
};
