import React from 'react';
import { LoveKarmaWalletSectionContainer, LoveKarmaWalletSectionInnerContainer } from './styles';
import { H2M } from '../../../styles/components/header';
import { GreenCreditCardGraphic } from '../../../components/svgs/graphics/GreenCreditCard';
import { PersonPlantingTreeGraphic } from '../../../components/svgs/graphics/PersonPlantingTree';
import { IThemeProps } from '../../../styles/themes';
import { withTheme } from 'styled-components';

interface IProps extends IThemeProps {
  className?: string;
}

const LoveKarmaWalletSectionBase: React.FC<IProps> = ({
  className,
  theme,
}) => (
  <LoveKarmaWalletSectionContainer className={ className }>
    <LoveKarmaWalletSectionInnerContainer>
      <H2M>Employees Love Karma Wallet</H2M>
      <div className='adoption-rate-text'>Our clients see ~<span>70% adoption</span> by their employees.</div>
      <div className='tagline-container'>
        <p>Your team wants sustainability initiatives. <span>We make it simple.</span></p>
      </div>
    </LoveKarmaWalletSectionInnerContainer>
    <GreenCreditCardGraphic className='karma-card-graphic' />
    <PersonPlantingTreeGraphic
      stroke={ theme.colors.dark }
      fill={ theme.colors.secondary }
      className='planting-tree-graphic'
    />
  </LoveKarmaWalletSectionContainer>
);

export const LoveKarmaWalletSection = withTheme(LoveKarmaWalletSectionBase);
