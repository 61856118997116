import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const Bottle: FC<IProps> = ({
  className = '',
  id = '',
  fill = 'white',
  stroke = 'black',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}bottle-graphic` }
    id={ id }
    height={ 97 } 
    width={ 48 }
    fill={ fill }
    stroke={ stroke }
  >
    <g transform='matrix(1,0,0,1,-31.5131,-12.2789)'>
      <g>
        <path d='M70.05,18.904C71.021,19.197 69.548,21.025 69.975,21.945C70.686,23.478 73.178,26.238 74.973,29.28C76.648,32.121 77.632,35.25 78.305,36.695C78.756,37.662 78.819,38.941 78.344,39.898C77.506,41.59 75.663,44.719 73.632,47.749C72.376,49.622 70.726,51.227 70.166,53.127C68.292,59.486 66.537,71.717 67.102,78.053C67.534,82.898 72.733,87.271 73.557,91.141C74.269,94.481 73.09,98.592 72.048,101.273C71.129,103.638 69.586,106.118 67.303,107.227C64.932,108.38 59.606,108.181 57.825,108.186C57.319,108.187 57.092,107.229 56.622,107.258C56.152,107.286 55.655,108.293 55.007,108.359C53.499,108.512 48.995,108.438 47.578,108.179C47.006,108.074 46.902,106.887 46.502,106.807C46.101,106.727 45.708,107.693 45.176,107.699C43.961,107.711 40.536,108.391 39.216,106.881C38.022,105.516 35.948,103.471 34.531,100.681C33.065,97.791 32.391,94.229 32.072,92.1C31.535,88.517 34.85,84.275 35.453,81.772C35.82,80.249 35.344,78.162 35.691,77.08C35.953,76.262 37.154,76.05 37.532,75.279C38.185,73.949 38.957,70.424 39.604,69.096C39.976,68.334 40.982,68.038 41.415,67.311C41.849,66.585 41.758,65.513 42.206,64.734C42.661,63.945 43.814,63.484 44.144,62.576C44.806,60.753 46.456,56.788 46.179,53.797C45.856,50.31 42.617,44.864 42.206,41.655C41.899,39.249 42.61,36.696 43.718,34.539C44.654,32.719 46.877,29.855 49.426,27.629C52.417,25.016 55.986,23.345 57.25,21.88C58.047,20.957 56.581,18.535 57.769,18.258C59.902,17.762 68.016,18.289 70.05,18.904Z' fill={ fill } stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M66.269,31.032C66.335,32.096 66.466,34.223 67.407,35.395C68.619,36.904 73.606,39.216 73.543,40.088C73.48,40.959 68.878,39.688 67.03,40.625C65.183,41.561 64.124,45.401 62.458,45.708C60.793,46.016 58.659,43.811 57.037,42.469C55.058,40.83 52.075,37.526 50.584,35.874' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M49.648,42.144C52.308,44.536 57.626,49.322 60.746,49.601C63.866,49.879 66.459,44.92 68.367,43.815C69.499,43.16 71.297,43.035 72.196,42.972' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M66.059,57.036C66.059,57.036 53.931,70.824 50.44,74.205C48.963,75.635 46.397,76.759 45.114,77.32' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M61.557,70.179C63.816,76.952 65.987,85.478 70.135,90.361' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M41.489,67.332C42.748,68.615 44.233,69.53 45.804,69.859' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M43.477,63.856C44.844,65.073 46.126,65.879 47.328,66.296' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M45.238,59.978C46.388,61.32 48.265,62.423 50.095,62.053' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M47.913,52.305C49.658,53.861 51.824,54.795 54.423,55.09' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M57.811,74.35C57.811,74.35 57.412,90.67 54.987,94.556C52.846,97.987 46.456,97.774 43.261,97.668' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M58.751,22.309C61.905,23.707 65.475,23.93 68.642,22.927' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M35.999,88.948C41.838,90.463 48.212,90.766 54.07,89.292' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M46.342,102.925C48.991,103.461 52.515,104.699 54.289,104.532C55.535,104.414 55.737,101.944 56.988,101.924C58.689,101.897 61.993,103.553 64.495,104.368' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M57.705,13.55L57.494,17.977L69.881,18.85L70.194,14.377L57.705,13.55Z' fill={ fill } stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M63.567,17.65C60.831,17.49 56.718,16.811 56.718,16.811L56.391,19.057C56.391,19.057 60.722,19.924 63.815,20.053C66.847,20.18 71.21,19.706 71.21,19.706L71.058,17.47C71.058,17.47 66.956,17.847 63.567,17.65Z' fill={ fill } stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M63.853,14.994C67.309,15.23 70.143,14.945 70.183,14.356C70.223,13.767 67.454,13.098 63.999,12.862C60.543,12.626 57.709,12.911 57.668,13.5C57.628,14.089 60.397,14.758 63.853,14.994Z' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M62.59,54.137C62.47,54.186 62.491,54.185 62.504,54.321' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M64.901,52.45C64.9,52.45 64.9,52.45 64.9,52.451' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M60.088,21.305C60.012,21.301 60.184,21.471 60.192,21.482' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M58.74,15.043C58.736,15.072 58.736,15.071 58.751,15.097' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M59.497,15.453C59.64,15.466 59.888,15.684 60.02,15.749C60.487,15.982 60.999,16.201 61.509,16.314' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M63.593,16.446C63.602,16.446 63.591,16.442 63.584,16.436' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M65.952,16.541C66.495,16.663 67,16.68 67.554,16.621' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M66.935,16.015C66.973,16.055 67.084,16.073 67.128,16.086C67.374,16.155 67.629,16.195 67.884,16.214' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M63.805,19.206C63.816,19.21 63.803,19.202 63.798,19.198' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M59.285,18.674C59.345,18.58 59.397,18.495 59.469,18.407' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M65.345,21.903C65.349,21.805 65.408,21.685 65.47,21.609' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M66.071,22.213C66.093,22.064 66.133,21.931 66.197,21.795' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M58.86,28.192C58.933,28.143 59.136,28.243 59.192,28.268C59.504,28.405 59.793,28.58 60.111,28.706' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M70.932,32.682C70.922,32.416 71.712,32.131 71.848,31.921' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M71.657,33.011C71.593,33.041 71.845,32.77 71.908,32.72' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M64.165,38.581C64.12,38.558 64.146,38.596 64.16,38.614' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M49.481,37.029C49.437,37.355 50.051,37.866 50.238,38.1' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M48.445,37.605C48.533,37.798 48.676,37.953 48.825,38.112' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M50.892,31.028C50.858,30.896 51.206,30.574 51.285,30.49' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M51.397,31.477C51.403,31.483 51.583,31.33 51.599,31.317' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M59.881,41.362C59.875,41.329 59.854,41.31 59.857,41.279' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M69.552,46.828C69.554,46.852 69.722,46.893 69.757,46.929C69.868,47.04 69.973,47.152 70.06,47.284' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M70.238,46.415C70.291,46.662 70.527,46.835 70.74,46.933' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M76.388,39.762C76.348,39.75 76.305,39.76 76.346,39.728' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M50.147,48.814C50.161,48.572 50.377,48.394 50.555,48.251' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M50.659,49.175C50.653,49.27 50.883,49.021 50.963,48.958' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M48.669,57.748C48.654,57.691 48.654,57.683 48.701,57.646' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M49.871,58.284C49.874,58.289 49.876,58.295 49.88,58.3' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M55.382,63.054C55.15,63.043 55.984,63.739 56.116,63.801' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M54.956,63.935C55.133,63.996 55.333,64.194 55.467,64.32' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M62.916,66.943C62.803,66.858 63.442,66.313 63.528,66.269' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M63.455,67.723C63.428,67.567 63.675,67.495 63.782,67.41' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M58.777,71.598C58.68,71.485 58.626,71.511 58.609,71.666' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M50.67,79.073C50.408,79.116 51.207,79.831 51.351,79.897' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M50.206,79.958C50.08,79.996 50.733,80.436 50.831,80.5' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M38.521,81.916C38.528,82.584 39.767,83.056 40.212,83.344' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M40.157,79.582C39.899,79.54 39.929,78.735 39.95,78.547' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M40.923,78.875C40.923,78.872 40.924,78.869 40.924,78.866' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M48.199,72.641C48.198,72.646 48.197,72.65 48.197,72.655' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M48.011,67.551C48.009,67.552 48.008,67.553 48.006,67.554' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M45.157,42.36C45.165,42.332 45.154,42.32 45.202,42.326' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M64.942,90.79C64.915,90.721 65.106,91.15 65.811,91.689' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M67.169,92.863C67.165,92.855 67.159,92.847 67.155,92.838' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M60.661,98.887C60.899,98.562 61.085,98.322 61.442,98.038' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M61.741,98.971C61.852,98.804 61.877,98.629 62.055,98.487' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M52.352,101.03C52.378,100.997 52.344,101.006 52.321,101.004' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M40.105,101.639C40.064,101.289 40.509,100.828 40.688,100.552' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M40.769,101.885C40.919,101.792 41.12,101.435 41.223,101.277' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M40.243,94.767C40.25,94.741 40.25,94.756 40.2,94.751' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M53.154,92.649C53.178,92.638 53.164,92.641 53.159,92.691' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M53.336,85.421C53.379,85.641 53.535,85.831 53.673,86.003' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M54.763,84.995C54.8,84.984 54.754,84.985 54.738,84.983' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M61.828,78.488C61.839,78.45 61.843,78.466 61.788,78.46' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M68.973,102.177C69.059,101.965 69.23,101.729 69.403,101.592' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M61.541,105.736C61.511,105.748 61.525,105.748 61.531,105.695' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
        <path d='M59.791,85.402C59.709,85.4 59.722,85.423 59.73,85.354' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth=' 1px;' />
      </g>
    </g>
  </SvgIcon>
);
