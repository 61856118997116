import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection } from '../../styles/components/containers';
import { _H4 } from '../../styles/components/header';
import { FlexCenter, FlexCol, FlexHorizontalCenter, NoScrollBar, PrimaryFont, TruncateText } from '../../styles/styles';

const leftRailWidth = 250;

export const LeftCol = styled.div`
  padding-right: 15px;
  padding-left: 15px;

  .query-text {
    ${PrimaryFont}
    ${TruncateText}
    font-size: 20px;
    font-weight: bold;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-width: ${leftRailWidth}px;
    max-width: ${leftRailWidth}px;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  position: relative;
  height: 60px;
`;

export const NoResults = styled.div`
  ${FlexCenter}
  color: ${({ theme }) => theme.colors.darkGray3};
`;

export const SearchResultsContainer = styled(MainContainer)``;

export const SearchResultsInner = styled(MaxBoundarySection)`
  ${FlexCol}
  max-height: calc(100vh - 75px);
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexHorizontalCenter};
    flex-direction: row;
    align-items: stretch;
  }
`;

export const SearchResultsList = styled.div`
  ${NoScrollBar}
  max-height: 100%;
  overflow: auto;

  .search-result {
    padding-top: 20px;
    padding-bottom: 20px;
    
    &:not(:last-child) {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray1}`};
    }

    & > div:first-child {
      min-width: calc(100% - 45px);
      max-width: calc(100% - 45px);

        .company-name {
          ${PrimaryFont}
          font-size: 14px;

          @media (${({ theme }) => theme.breakpoints.lgMin}) {
            ${_H4}
          }
        }

        .company-sectors {
          padding-bottom: 5px;
          font-size: 16px;
        }
      }
    }
  }

  .waypoint-support {
    border: 1px solid transparent;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-width: calc(100% - ${leftRailWidth}px);
    max-width: calc(100% - ${leftRailWidth}px);
  }
`;
