import styled from 'styled-components';
import { MaxBoundaryContainer, TextSection } from '../../../styles/components/containers';
import { FlexCol } from '../../../styles/styles';

export const MasterArticleContainer = styled.div``;

export const BottomSectionWrapper = styled(TextSection)``;

export const BottomSectionContainer = styled(MaxBoundaryContainer)`
  ${FlexCol}
  margin: 80px auto;
  max-width: 950px;

  .see-report-cta {
    align-self: center;
    max-width: 200px;
    margin-top: -25px;
    z-index: 2;
  }

  img {
    height: auto;
    margin-bottom: 60px;
    width: 115px;
  }

  h2 {
    margin-bottom: 40px;
    width: 215px;
  }

  p {
    width: 230px;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 30px;
  }

  .company-rating {
    ${FlexCol}
    align-self: center;
  }
`;

export const CompanyDataSquare = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.dark};
  border-radius: 9px;
  display: none;
  height: 256px;
  justify-content: center;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 229px;

  p {
    color: ${({ theme }) => theme.colors.midGray};
    font-weight: 600;
    font-size: 18px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 50px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexCol}
  }
`;

export const MakeImpactCTAContainer = styled.div`
  ${FlexCol}
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding: 60px 0 40px;
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 40px;
    width: 100%;
  }

  p {
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 30px;
    width: 230px;
  }

  @media (min-width: 500px) {
    h2 {
      font-size: 40;
      width: 100%;
      margin-bottom: 10px;
    }

    p {
      width: 100%;
      max-width: 371px;
    }
  }
`;
