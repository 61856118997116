import React, { useMemo } from 'react';
import {
  DataSourcesContainer,
  DiscoverTheTruthSectionContainer,
  InnerWrapperContainer,
} from './styles';
import { H1M, H1MInverted, H3 } from '../../../styles/components/header';
import { useAnalytics } from '../../../contexts/analytics-store';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { useAppData } from '../../../contexts/app';
import { UpdateHeader } from '../../../components/UpdateHeader';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';

interface IProps extends IThemeProps {
  className?: string;
}

const DiscoverTheTruthSectionBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();
  const app = useAppData();

  const onExploreCompanyRatingsClick = () => {
    analytics.fireEvent('HP_BrowseCompanies_Click');
  };

  const dataSources = useMemo(() => {
    const desktopImg = 'https://cdn.karmawallet.io/uploads/5NnbYKid9h-topsourcesdesktop.webp';
    const mobileImg = 'https://cdn.karmawallet.io/uploads/fryajY1jNv-topsourcesmobile.webp';

    return (
      <DataSourcesContainer>
        <p className='top-data-sources-text'>Our Top Data Sources</p>
        <picture>
          <source 
            srcSet={ mobileImg }
            media={ `(${theme.breakpoints.smMax})` } />
          <img
            loading='lazy'
            src={ desktopImg }
            className='data-sources'
            alt='Data Sources'
          />
        </picture>
      </DataSourcesContainer>
    );
  }, []);

  const backgroundImg = useMemo(() => {
    if (app.isLargeDesktop) return 'https://cdn.karmawallet.io/uploads/hFZT0xooKL-rainforest.webp';
    return 'https://cdn.karmawallet.io/uploads/IULhYSvcKm-rainforest-sm.webp';
  }, [app]);

  return (
    <DiscoverTheTruthSectionContainer className={ className } backgroundImage={ backgroundImg }>
      <UpdateHeader />
      <InnerWrapperContainer>
        <H3 role='presentation'>#LiveYourValues</H3>
        <span role='heading' aria-level={ 2 }>
          <H1M className='live-your' role='presentation'>A</H1M>
          <H1MInverted className='values' role='presentation'> new kind</H1MInverted>
          <H1M className='live-your' role='presentation'> of financial company.</H1M>
        </span>
        <p>Discover the impact of your everyday purchases on our society and environment with ratings for 18,000+ brands.</p>
        <ButtonLink
          className='get-started-button'
          kind={ ButtonKind.Tertiary }
          onClick={ onExploreCompanyRatingsClick }
          href='/browse-and-compare-companies'
        >
          Explore Company Ratings
        </ButtonLink>
        { dataSources }
      </InnerWrapperContainer>
    </DiscoverTheTruthSectionContainer>
  );
}; 

export const DiscoverTheTruthSection = withTheme(DiscoverTheTruthSectionBase);
