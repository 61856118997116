import styled from 'styled-components';
import { BasicContainer } from '../../../styles/styles';
import { Button } from '../../Button';

export const SubcategorySectionContainer = styled.div`
  padding: 20px 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 10px 15px 0px;
  }
`;

export const UNSDGsPickerContainer = styled.div`
  ${BasicContainer}
  min-height: 360px;
  position: relative;
  padding: 10px;
  max-width: 360px;
  margin: 0 auto;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 100%;
    max-width: 100%;
    padding: 35px;
    min-height: 309.5px;
    border: solid 3px ${({ theme }) => theme.colors.darkGray1};
  }

  .category-graphic {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -20px;
    right: calc(50% - 16px);

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 42px;
      height: 42px;
    }
  }
`;

export const DescriptionSection = styled.div`

`;

export const UNSDGsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const UNSDGWrapper = styled(Button)`
  width: 50%;
  overflow: hidden;
  border-radius: 0;
  object-fit: contain;
  padding: 8px;

  &.active {
    img {
      border: solid 2px ${({ theme }) => theme.colors.darkGray1};

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        border: solid 3px ${({ theme }) => theme.colors.darkGray1};
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 25%;
  }

  img {
    width: 100%;
  }
`;

export const DescriptionContainer = styled.div`
  margin-top: 30px;
  text-align: center;

  h3 {
    padding-bottom: 20px;
  }

  .unsdg-description-text {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.darkGray2};
    margin-bottom: 0;

    @media (${({ theme }) => theme.breakpoints.lgMax}) {
      font-weight: 400;
      line-height: 22px;
    }
  }
`;
