import React from 'react';
import { OurImpactCertificationsContainer } from './styles';
import { IBlockContent, ImpactContentBlockType, OurImpactContentBlock } from '../OurImpactContentBlock';
import { H2M } from '../../styles/components/header';
import { PMarketing } from '../../styles/components/paragraph';
import { theme } from '../../styles/themes';

interface IProps {
  className?: string;
}

const content: IBlockContent[] = [
  {
    title: 'Certified B Corp',
    body: 'As a Certified B Corp, we join a network of companies using business as a force for good. We passed strict third-party standards for social and environmental impact, and were awarded a score through B Corp of 100.3. We join over 6,500 companies across 89 companies that have 1 goal – transform the global economy to benefit all people, communities, and the planet.',
    impactContentType: ImpactContentBlockType.Certifications,
    ctaLink: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/impact-karma-inc/',
    ctaText: 'See our profile on B Corp',
    bodyAlignment: 'left',
    graphicSection: <img src='https://cdn.karmawallet.io/uploads/S2uUBFKb5H-bcorp.svg' alt='B corp logo' />,
    customGraphic: true,
    customGraphicScore: 100.3,
    customGraphicText: 'Overall B Impact Score',
    customGraphicBg: theme.colors.primary,
    customGraphicTextColor: theme.colors.white,
  },
  {
    title: '1% for the Planet Member',
    body: 'We’re a proud member of 1% for the Planet, joining a global network of brands that are putting people and the planet over profit. Through this membership, we commit to donate 1% of our profits towards 1% approved non-profits.',
    impactContentType: ImpactContentBlockType.Certifications,
    ctaLink: 'https://onepercentfortheplanet.org/',
    ctaText: 'Check out 1% for the Planet',
    bodyAlignment: 'right',
    graphicSection: <img src='https://cdn.karmawallet.io/uploads/5nCPILuVJ1-group-2102-2.png' alt='one percent for the planet logo' />,
    customGraphic: false,
  },
];

export const OurImpactCertifications: React.FC<IProps> = ({
  className = '',
}) => (
  <OurImpactCertificationsContainer className={ className }>
    <div className='section-header-content header'>
      <H2M className='title'>Certifications</H2M>
      <PMarketing>We don&apos;t just talk the talk. We walk the walk. Our certifications hold us to rigorous third-party standards for social and environmental performance, while connecting us with like-minded companies to create collective impact.</PMarketing>
    </div>
    {
      content.map(c => <OurImpactContentBlock key={ c.title } content={ c } /> )
    }
  </OurImpactCertificationsContainer>
); 
