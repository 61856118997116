import styled from 'styled-components';
import { FlexColCenter } from '../../../styles/styles';
import { InfoModal } from '../InfoModal';

export const RelinkCardModalContainer = styled(InfoModal)`
  .info-modal-ctas {
    @media (${props => props.theme.breakpoints.lgMin}) {
      ${FlexColCenter}

      button {
        margin: 0 0 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
