import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexCol } from '../../styles/styles';

export const PageDownContainer = styled.div``;

export const PageDownWrapper = styled(MaxBoundarySection)`
 ${FlexCol}
  align-items: center;
  text-align: center;

  svg {
    margin-bottom: 20px;
  }

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    width: 90%;
    max-width: 800px;
    margin: 40px 0;
    
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
  }

  a {
    margin: 60px 0 0;
  }

  .redirect-text {
    color: ${({ theme }) => theme.colors.darkGray3};
    margin-top: 20px;
  }
`;
