import React, { FC } from 'react';
import { ChevronSvgIcon } from './styles';

export enum ChevronDirection {
  Up = 'up',
  Right = 'right',
  Down = 'down',
  Left = 'left',
}

interface IProps {
  className?: string;
  stroke?: string;
  direction?: ChevronDirection;
  id?: string;
}

export const ChevronIcon: FC<IProps> = ({ className = '', direction, stroke = '', id = '' }) => (
  <ChevronSvgIcon
    className={ `${direction} ${className ? className + ' ' : ''}chevron-icon` }
    id={ id }
    width={ 10 }
    height={ 18 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M8.75 1.5L1.25 9L8.75 16.5' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </ChevronSvgIcon>
);
