import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const LockIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }lock-icon` }
    id={ id }
    width={ 23 }
    height={ 24 }
    stroke={ 'none' }
    fill={ fill }
  >
    <path d='M19.6875 10.3128H17.6955V3.74976C17.6955 1.67876 16.0165 -0.000244141 13.9455 -0.000244141H6.67949C4.60849 -0.000244141 2.92949 1.67876 2.92949 3.74976V10.3128H0.937488C0.418488 10.3128 0.000488281 10.7318 0.000488281 11.2498V22.4998C0.000488281 23.0188 0.418488 23.4378 0.937488 23.4378H19.6875C20.2065 23.4378 20.6255 23.0188 20.6255 22.4998V11.2498C20.6255 10.7318 20.2065 10.3128 19.6875 10.3128ZM11.1325 17.2558V18.8088C11.1325 18.9378 11.0275 19.0428 10.8985 19.0428H9.72649C9.59749 19.0428 9.49249 18.9378 9.49249 18.8088V17.2558C9.12449 16.9918 8.90549 16.5658 8.90549 16.1138C8.90549 15.3418 9.54049 14.7068 10.3125 14.7068C11.0845 14.7068 11.7195 15.3418 11.7195 16.1138C11.7195 16.5658 11.5005 16.9918 11.1325 17.2558ZM15.5855 10.3128H5.03949V3.74976C5.03949 2.84476 5.77449 2.10976 6.67949 2.10976H13.9455C14.8505 2.10976 15.5855 2.84476 15.5855 3.74976V10.3128Z' />
  </SvgIcon>
);
