import styled from 'styled-components';
import { _H4Marketing } from '../../../../styles/components/header';
import { FlexColCenter, FlexRow } from '../../../../styles/styles';
import { TextSMDemiBold } from '../../../../styles/components/text';

interface IProps {
  orangeColors?: boolean;
}

export const ApplyHeaderContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  margin: 25px 0 20px;

  .header-container {
    ${FlexColCenter}
    align-items: center;
    margin-bottom: 20px;

    &.page-one {
      margin-bottom: 0;
    }

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      ${FlexRow}
      align-items: center;
      justify-content: center;
    }
  }

  &.page-one {
    margin: 0px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    align-items: flex-start;
  }
`;

export const HeaderText = styled.div`
  ${_H4Marketing}
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

export const SubheaderText = styled.p<IProps>`
  ${TextSMDemiBold}
  background-color: ${({ theme, orangeColors }) => orangeColors ? theme.colors.orange2 : `${theme.colors.primary}33`};
  border-radius: 6px;
  margin-top: 10px;
  padding: 10px 16px;
  text-align: center;
  width: 100%;

  p {
    margin-bottom: 0;
  }

  &.page-one {
    margin-bottom: 21px;
    margin-top: 18px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    text-align: center;
  }
`;
