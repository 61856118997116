import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';

dayjs.extend(utc);

type PrivateFields = '_busy' |
'_card';

export enum CardStatus {
  Linked = 'linked',
  Unlinked = 'unlinked',
  Error = 'error',
}

export interface ICardIntegrations {
  marqeta?: any;
  plaid?: any;
  rare?: any;
}

export interface ICard {
  _id: string;
  userId: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
  status: CardStatus;
  initialTransactionsProcessing: boolean;
  lastTransactionSync: Date;
  institution: string;
  institutionId?: string;
  createdOn: Date;
  lastModified: Date;
  isEnrolledInAutomaticRewards: boolean;
  integrations: ICardIntegrations;
}

export class CardModel extends BaseModel {
  private _busy = false;
  private _card: ICard = null;

  constructor (cardInfo: ICard) {
    super();
    makeObservable<CardModel, PrivateFields>(this, {
      _busy: observable,
      _card: observable,
      _id: computed,
      busy: computed,
      createdOn: computed,
      institution: computed,
      institutionId: computed,
      lastModified: computed,
      lastTransactionSync: computed,
      isEnrolledInAutomaticRewards: computed,
      mask: computed,
      name: computed,
      status: computed,
      subtype: computed,
      type: computed,
    });

    this._card = cardInfo;
  }

  get _id() { return this._card._id; }
  get busy() { return this._busy; }
  get createdOn() { return dayjs(this._card.createdOn).local(); }
  get institution() { return this._card.institution; }
  get institutionId() { return this._card.institutionId || ''; }
  get lastModified() { return dayjs(this._card.lastModified).local(); }
  get lastTransactionSync() { return dayjs(this._card.lastTransactionSync).local(); }
  get mask() { return this._card.mask; }
  get name() { return this._card.name; }
  get processing() { return this._card.initialTransactionsProcessing; }
  get status() { return this._card.status; }
  get subtype() { return this._card.subtype; }
  get type() { return this._card.type; }
  get isEnrolledInAutomaticRewards() { return this._card.isEnrolledInAutomaticRewards; }
}
