import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { Button } from '../../../components/Button';
import { FlexCol, FlexRow } from '../../../styles/styles';

export const AccountHubNavMainContainer = styled.div`
  grid-area: sideNav;
  height: 75px;
  min-width: 126px;
  left: 0;
  padding: 10px 0 10px 10px;
  top: 0;
  z-index: 101;

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    height: 100vh;
    padding: 20px;
  }
`;

const NavLinksBase = css`
  background-color: ${({ theme }) => theme.colors.dark};
  border-radius: 20px;
  overflow: hidden;
`;

export const MobileNavLinksListItem = styled.li`
  ${FlexRow}
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  padding: 10px 0px;
  padding-left: 10px;

  div {
    ${FlexRow}
  }

  .account-nav-link-text {
    ${FlexRow}
    color: ${({ theme }) => theme.colors.grey3};
    text-decoration: none;
  }
  

  svg {
    fill: ${({ theme }) => theme.colors.grey3};
    min-width: 20px;
    min-height: 20px;
  }

  span {
    color: ${({ theme }) => theme.colors.grey3};
    margin-top: 2px;
    overflow: hidden;
    padding-left: 10px;
    transition: width .3s ease-in-out;
    white-space: nowrap;

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      position: relative;
      width: auto;
    }
  }

  &.active {
    .account-nav-link-text {
        span {
          color: ${({ theme }) => theme.colors.white};
        }
    }

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &:hover {
    .account-nav-link-text {
      span {
        color: ${({ theme }) => theme.colors.grey2};
      }
    }

    svg {
      fill: ${({ theme }) => theme.colors.grey2};
    }
  }
`;

export const MobileNavMenuHeader = styled(Button)`
  ${FlexRow}
  align-items: center;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 20px;
  position: absolute;
  top: 0;
`;

export const MobileNavLinksListContainer = styled(animated.nav)`
  ${NavLinksBase}
  ${FlexRow}
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    display: none;
  }
`;

export const MobileNavLinksList = styled.ul`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 45px;
  justify-self: center;
  justify-content: space-between;
  width: fit-content;
  padding: 0;
`;

export const NavLinksListContainer = styled.nav`
  ${NavLinksBase}
  align-items: center;
  display: none;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 30px 20px;
  max-height: calc(100vh - 40px);
  min-width: 75px;
  transition: min-width .8s ease-in-out;
  width: fit-content;
  position: fixed;

  .KW-logo {
    min-height: 53px;
    margin-right: 10px;
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    display: flex;
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    min-width: 322px;
    width: fit-content;
  }
}
`;

export const NavLinksList = styled.ul`
  height: 100%;
  width: fit-content;
  gap: 6px;
  padding: 0;
  margin-top: 70px;
`;

export const NavLinksListItem = styled.li`
  ${FlexRow}
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 10px 0px;
  padding-left: 10px;

  div {
    ${FlexRow}
  }

  .account-nav-link-text {
    ${FlexRow}
    color: ${({ theme }) => theme.colors.grey3};
    text-decoration: none;
  }
  
  svg {
    fill: ${({ theme }) => theme.colors.grey3};
    min-width: 20px;
    min-height: 20px;
  }

  span {
    color: ${({ theme }) => theme.colors.grey3};
    margin-top: 2px;
    overflow: hidden;
    padding-left: 10px;
    transition: width .3s ease-in-out;
    width: 0px;
    white-space: nowrap;

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      position: relative;
      width: auto;
    }
  }

  &.active {
    .account-nav-link-text {
        span {
          color: ${({ theme }) => theme.colors.white};
        }
    }

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  &:hover {
    .account-nav-link-text {
      span {
        color: ${({ theme }) => theme.colors.grey2};
      }
    }

    svg {
      fill: ${({ theme }) => theme.colors.grey2};
    }
  }
`;

export const NavAltLinksList = styled.ul`
  gap: 6px;
  padding: 0;
  width: fit-content;
`;

export const NavLinksListAltItem = styled.li`
  ${FlexCol}
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  padding: 10px 0px 10px 5px;

  .account-nav-alt-link {
    ${FlexCol}
    text-decoration: none;

    .alt-link-text {
      color: ${({ theme }) => theme.colors.white};
    }

    .alt-link-icon {
      margin-left: 10px;

      svg {
        max-height: 15px;
        max-width: 15px;
      }
    }
    
    .sub-text {
      color: ${({ theme }) => theme.colors.grey3};
      font-size: 12px;
    }
  }

  &:hover {
    .alt-link-text {
        color: ${({ theme }) => theme.colors.tertiary};
    }
  }
`;
