import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Trees: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}trees-graphic` }
    id={ id }
    height={ 104 }
    width={ 104 }
  >
    <path d='M77.1409 29.6286H75.4679V20.1556C75.4753 17.6601 74.9912 15.1876 74.0431 12.8793C73.095 10.5709 71.7015 8.47191 69.9421 6.70211C68.1828 4.9323 66.0923 3.52636 63.7896 2.56454C61.4869 1.60271 59.0173 1.10385 56.5218 1.09644C51.4829 1.1144 46.6572 3.13178 43.1048 6.70542C39.5524 10.2791 37.5637 15.1166 37.5757 20.1556V29.5947H35.9027C30.8638 29.6126 26.0381 31.63 22.4857 35.2036C18.9332 38.7773 16.9446 43.6149 16.9566 48.6538C16.9446 53.6927 18.9332 58.5303 22.4857 62.104C26.0381 65.6776 30.8638 67.695 35.9027 67.713H77.1409C79.6364 67.7055 82.1061 67.2067 84.4087 66.2449C86.7114 65.283 88.8021 63.8771 90.5614 62.1073C92.3207 60.3375 93.7141 58.2385 94.6623 55.9301C95.6104 53.6218 96.0945 51.1493 96.087 48.6538C96.093 43.6199 94.1015 38.7892 90.5494 35.2223C86.9973 31.6553 82.1749 29.6436 77.1409 29.6286Z' fill='#95D28B' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M56.5217 28.0122V102.87' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M56.5217 28.7695L65.0452 20.1895' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M56.5218 28.7695L47.9983 20.1895' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M71.4097 48.4617L79.9445 57.0417' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M71.4097 48.4618L79.9445 39.8931' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M41.6339 48.4617L33.0991 57.0417' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M41.6339 48.4618L33.0991 39.8931' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M41.0801 48.4617H71.9636' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M88.3774 102.87V85.7773' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M88.3774 85.4834C96.3812 85.4834 102.87 78.995 102.87 70.9912C102.87 62.9874 96.3812 56.499 88.3774 56.499C80.3736 56.499 73.8853 62.9874 73.8853 70.9912C73.8853 78.995 80.3736 85.4834 88.3774 85.4834Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M76.0784 63.3608C76.0784 66.3791 81.8888 68.8322 85.5401 68.8322' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M75.0156 76.5869C75.9991 79.4921 81.1087 81.6965 84.6696 80.8939' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M102.485 74.0435C102.485 74.9817 97.8277 75.7391 92.4016 75.7391' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M25.2199 65C26.4514 62.7065 26.8747 60.0651 26.4214 57.5016C25.9682 54.9381 24.6649 52.6018 22.7217 50.8696C23.1683 49.6304 23.31 48.302 23.135 46.9965C22.96 45.6911 22.4732 44.4469 21.716 43.3691C20.9588 42.2914 19.9533 41.4117 18.7845 40.8045C17.6157 40.1972 16.3179 39.8801 15.0008 39.8801C13.6836 39.8801 12.3858 40.1972 11.217 40.8045C10.0482 41.4117 9.04268 42.2914 8.28548 43.3691C7.52828 44.4469 7.04167 45.6911 6.86664 46.9965C6.69162 48.302 6.83336 49.6304 7.27993 50.8696C5.3367 52.6018 4.03344 54.9381 3.58021 57.5016C3.12699 60.0651 3.55025 62.7065 4.78172 65C2.96498 66.9831 1.76439 69.452 1.32652 72.1055C0.888656 74.7591 1.23234 77.4828 2.31565 79.9444C3.39897 82.406 5.17517 84.4992 7.42758 85.9689C9.67999 87.4385 12.3113 88.2211 15.0008 88.2211C17.6902 88.2211 20.3216 87.4385 22.5741 85.9689C24.8265 84.4992 26.6025 82.406 27.6859 79.9444C28.7692 77.4828 29.1128 74.7591 28.675 72.1055C28.2371 69.452 27.0367 66.9831 25.2199 65Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M15.001 102.87V57.2339' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M15.001 57.234L19.2627 52.9609' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M15.001 67.3965L8.12793 60.5234' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M15.001 78.8253L22.4845 71.3418' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
  </SvgIcon>
);
