import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { EquivalencyGraphic } from '../EquivalencyGraphic';
import { H1, H3, H4 } from '../../styles/components/header';
import {
  GroupTotalEquivalencyContainer,
  GroupTotalEquivalencyInner,
  GroupTotalContentWrapper,
  EquivalencySection,
  ErrorText,
} from './styles';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { GroupModel } from '../../models/group';
import { useAnalytics } from '../../contexts/analytics-store';

interface IProps {
  className?: string;
  group: GroupModel;
}

const GroupTotalEquivalencyBase: React.FC<IProps> = ({
  className = '',
  group,
}) => {
  const analytics = useAnalytics();
  const [useAmericanAverage, setUseAmericanAverage] = useState(false);
  // offsets group + member total or average american emissions
  const [tonnesValue, setTonnesValue] = useState(0);
  const [dollarValue, setDollarValue] = useState(0);
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    if (!!group) {
      group.loadEquivalency()
        .catch(() => {
          analytics.fireEvent('GroupTotal_Equivalency_Error');
          setErrorLoading(true);
        });
    }
  }, [group]);

  useEffect(() => {
    if (!!group?.equivalency?.useAverageAmericanEmissions) {
      setUseAmericanAverage(true);
      setTonnesValue(parseFloat(group.equivalency.averageAmericanEmissions.annually.toFixed(2)));
    }
  }, [group?.equivalency?.useAverageAmericanEmissions]);

  useEffect(() => {
    if (!!group?.equivalency?.totalDonations?.tonnes) {
      setTonnesValue(parseFloat(group.equivalency.totalDonations.tonnes.toFixed(2)));
      setDollarValue(group.equivalency.totalDonations.dollars);
    }
  }, [group?.equivalency?.totalDonations]);

  if (!group) return null;

  const renderHeaderText = () => {
    if (!!useAmericanAverage) return 'Your Donation Matters';

    return `Total $${dollarValue.toLocaleString('en-US')} Donated`;
  };

  const renderGroupText = () => {
    if (!!useAmericanAverage) return null;

    return `${group.name}'s offsets to-date: ${tonnesValue} Tonnes of carbon`;
  };

  const renderAmericanText = () => {
    if (!useAmericanAverage) return null;

    return (
      <H4 className='american-text'>
        The average American is responsible for { tonnesValue } tonnes of CO<sub>2</sub> emissions over two years.
      </H4>
    );
  };

  const renderEquivalencySection = () => {
    if (!group.equivalency?.equivalency) return null;
    
    const { quantity, textNoQuantity, icon } = group.equivalency.equivalency;

    return (
      <EquivalencySection>
        <H3>{ tonnesValue } Tonnes of CO<sub>2</sub> is like...</H3>
        <EquivalencyGraphic
          className='equivalency-graphic-wrapper'
          icon={ icon }
        />
        <div className='equivalency-text-wrapper'>
          <H1>{ quantity.toLocaleString('en-US') }</H1>
          <H4>{ textNoQuantity }</H4>
        </div>
      </EquivalencySection>
    );
  };

  const renderCTA = () => {
    if (!useAmericanAverage) return null;

    return (
      <ButtonLink
        className='purchase-offsets-cta'
        href='/offset-emissions'
        kind={ ButtonKind.PrimaryGhost }
        target='_blank'
      >
        Purchase Offsets
      </ButtonLink>
    );
  };

  const render = () => {
    if (errorLoading) return <ErrorText>Error loading group equivalency data</ErrorText>;
    if (group.loadingEquivalency || group.equivalency?.useAverageAmericanEmissions === undefined) return <LoadingSpinner />; 

    return (
      <GroupTotalEquivalencyInner>
        <GroupTotalContentWrapper>
          <H4 className='group-text'>{ renderGroupText() }</H4>
          <H1 className='header'>{ renderHeaderText() }</H1>
          { renderAmericanText() }
          { renderEquivalencySection() }
          { renderCTA() }
        </GroupTotalContentWrapper>
      </GroupTotalEquivalencyInner> 
    );
  };
   
  return (
    <GroupTotalEquivalencyContainer className={ className }>
      { render() }    
    </GroupTotalEquivalencyContainer>
  ); 
};

export const GroupTotalEquivalency = observer(GroupTotalEquivalencyBase);
