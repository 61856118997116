import { emailRegex, passwordRegex, zipCodeRegex, numbersRegex } from '../constants/regex';

interface IPasswordRule {
  id: string;
  text: string;
  validate(pw: string): boolean;
}

export const isValidEmail = (email: string) => emailRegex.test(email);
export const isValidPassword = (password: string) => passwordRegex.test(password);
export const isValidZipcode = (zipcode: string | number) => zipCodeRegex.test(`${zipcode}`);
export const isDigitsOnly = (item: string | number) => numbersRegex.test(`${item}`);

export const passwordRules: IPasswordRule[] = [
  {
    id: 'min-chars',
    text: '8 character minimum',
    validate: (pw: string) => pw.length >= 8,
  },
  {
    id: 'uppercase-char',
    text: 'One uppercase character',
    validate: (pw: string) => /[A-Z]/.test(pw),
  },
  {
    id: 'one-special-char',
    text: 'One special character: @$-!%*?&',
    validate: (pw: string) => /[@$!%*?&-]/gu.test(pw),
  },
  {
    id: 'lowercase-char',
    text: 'One lowercase character',
    validate: (pw: string) => /[a-z]/.test(pw),
  },
  {
    id: 'one-number',
    text: 'One number',
    validate: (pw: string) => /[0-9]/.test(pw),
  },
  {
    id: 'invalid-character',
    text: 'No invalid characters',
    validate: (pw: string) => !/[^a-zA-Z0-9@$!%*?&-]/gu.test(pw),
  },
];

/**
 * 
 * @param emailDomains - Array of domains to check against
 * @param emailsToCheck - Array of emails to check against the domains
 * @returns the matching email 
 */
export const checkIfEmailMatchesDomains = (emailDomains: string[], emailsToCheck: string[]) => {
  for (const domain of emailDomains) {
    return emailsToCheck.find(email => email.split('@')?.[1] === domain);
  }
};

export const checkZipcodeAndFormat = (zipcode: string) => {
  let zipErrorText = 'Use 5 digits or full 9 digit format';
  let isValid = false;
  let value = '';
 
  if (zipcode.length === 0) {
    isValid = true;
    zipErrorText = '';
  } else if (zipcode.length <= 5 && isDigitsOnly(zipcode)) {
    value = zipcode;
    isValid = true;
    zipErrorText = '';
  } else if (zipcode.length > 5 && zipcode.length < 11) {
    value = zipcode.replace(/(\d{5})(\d{1,4})/, '$1-$2');
    if (isValidZipcode(zipcode)) {
      isValid = true;
      zipErrorText = '';
    } 
  } else {
    value = zipcode;
  }

  return { 
    isValid, 
    value, 
    zipErrorText,
  };
};

export const formatCardInput = (card: string) => {
  let value;
  if (card.length === 0) {
    return value;
  } else if (card.length <= 6 && isDigitsOnly(card)) {
    value = card;
  } else if (card.length > 6 && card.length < 10) {
    value = card.replace(/(\d{6})(\d{1,4})/, '$1-$2');
  } else {
    value = card;
  }
  return value;
};

export const ensureCorrectCardFormat = (card: string) => {
  const CARDNUMBERREGEX = /^\d{6}-\d{4}$/;
  return CARDNUMBERREGEX.test(card);
};
