import styled from 'styled-components';

export const ApplyDisclaimerContainer = styled.div`
  text-align: center;
  width: 85%;
  max-width: 800px;
  padding: 20px 0 0;
  margin: 0 auto;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 20px;
  }

  @media (${({ theme }) => theme.breakpoints.xsMax}) {
    max-width: 90%;
  }

  p {
    font-size: 10px;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.lightGray1};
    margin-bottom: 0;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 12px;
    }
  }
`;
