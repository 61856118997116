import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const DefaultCompanyLogo: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }default-company-logo-svg` }
    id={ id }
    width={ 87 }
    height={ 133 }
    fill='none'
  >
    <path d='M0.244629 0.168701V132.861H32.6446V104.978H54.3889V132.861H86.7889V0.168701H0.244629ZM20.4369 97.2822H8.89848V85.7437H20.4369V97.2822ZM20.4369 78.0533H8.89848V66.5149H20.4369V78.0533ZM20.4369 58.8245H8.89848V47.286H20.4369V58.8245ZM20.4369 39.5899H8.89848V28.0514H20.4369V39.5899ZM20.4369 20.361H8.89848V8.82255H20.4369V20.361ZM39.6658 97.2822H28.1273V85.7437H39.6658V97.2822ZM39.6658 78.0533H28.1273V66.5149H39.6658V78.0533ZM39.6658 58.8245H28.1273V47.286H39.6658V58.8245ZM39.6658 39.5899H28.1273V28.0514H39.6658V39.5899ZM39.6658 20.361H28.1273V8.82255H39.6658V20.361ZM58.9004 97.2822H47.3619V85.7437H58.9004V97.2822ZM58.9004 78.0533H47.3619V66.5149H58.9004V78.0533ZM58.9004 58.8245H47.3619V47.286H58.9004V58.8245ZM58.9004 39.5899H47.3619V28.0514H58.9004V39.5899ZM58.9004 20.361H47.3619V8.82255H58.9004V20.361ZM78.1292 97.2822H66.5908V85.7437H78.1292V97.2822ZM78.1292 78.0533H66.5908V66.5149H78.1292V78.0533ZM78.1292 58.8245H66.5908V47.286H78.1292V58.8245ZM78.1292 39.5899H66.5908V28.0514H78.1292V39.5899ZM78.1292 20.361H66.5908V8.82255H78.1292V20.361Z' fill='#BABAC2' />
  </SvgIcon>
);
