import styled from 'styled-components';
import { MaxBoundaryContainer } from '../../../styles/components/containers';

export const BannerItemContainer = styled.div`
  height: 110px;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  
  &.dark {
    background-color: ${ props => props.theme.colors.primary };

    span, div, p, b {
      color: white;
    }
  }

  &.light {
    background-color: ${ props => props.theme.colors.tertiary };
    color: ${ props => props.theme.colors.darkGray1 };
  }

  &.warning {
    background-color: ${ props => props.theme.colors.red };
    color: ${ props => props.theme.colors.white };
  }

  .x-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 10px;
    right: 10px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    height: 75px;
    padding: 10px 20px;

    .x-icon {
      right: 20px;
      width: 15px;
      height: 15px;
    }
  }
`;

export const BannerContentWrapper = styled(MaxBoundaryContainer)`
  text-align: center;

  p {
    display: inline;
  }

  .desktop-cta {
    margin-left: 10px;
    border: none;
    box-shadow: none;
    display: none;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      display: inline;
    }
  }

  .mobile-cta {
    margin-left: 5px;
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      display: inline;
    }
  }

  .button-wrapper, .text-wrapper {
    display: inline;
  }

  .banner-span {
    display: inline;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
