import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppType } from '../constants';
import { BaseModel } from './base';
import { CollectionModel, ICollectionConfig } from './collection';

type PrivateSectorFields = '_busy' |
'_sector';

type PrivateFields = '_busy' |
'_checkingName' |
'_creatingSector' |
'_filterOptions' |
'_init' |
'_loadingFilterOptions' |
'_options' |
'_sectors';

export enum SectorNameAvailability {
  Available = 'available',
  Unavailable = 'unavailable',
}

export interface IBaseSector {
  name: string;
  tier: number;
  carbonMultiplier: number;
  parentSector?: string;
  icon?: string;
}

export interface IAverageScores {
  numCompanies: number;
  avgScore: number;
  avgPlanetScore: number;
  avgPeopleScore: number;
  avgSustainabilityScore: number;
  avgCommunityWelfareScore: number;
  avgDiversityInclusionScore: number;
}

export interface ISector extends Omit<IBaseSector, 'parentSector'> {
  _id: string;
  parentSectors: ISector[];
  averageScores: IAverageScores;
  icon: string;
}

export interface ISectorNameStatus {
  available: boolean,
  isValid: boolean,
}

export interface ISecterTiersFilter {
  tier: number;
  count: number;
}

export interface ISecterCarbonOffsetsRangeFilter {
  min: number;
  max: number;
}

export interface ISectorFilterOptions {
  tiers: ISecterTiersFilter[];
  carbonMultiplierRange: ISecterCarbonOffsetsRangeFilter;
}

export interface ISectorsModelOptions {
  url?: string;
}

export enum SectorConfigType {
  BrowseBy = 'browse-by',
}

export class SectorModel extends BaseModel {
  private _busy = false;
  private _sector: ISector = null;

  constructor(sectorInfo: ISector, app?: AppType) {
    super({}, null, app);
    makeObservable<SectorModel, PrivateSectorFields>(this, {
      _busy: observable,
      _sector: observable,
      _id: computed,
      busy: computed,
      carbonMultiplier: computed,
      name: computed,
      icon: computed,
      parentSectors: computed,
      tier: computed,
      update: action.bound,
    });

    this._sector = sectorInfo;
  }

  get busy() { return this._busy; }
  get _id() { return this._sector._id; }
  get name() { return this._sector.name; }
  get icon() { return this._sector.icon; }
  get tier() { return this._sector.tier; }
  get carbonMultiplier() { return this._sector.carbonMultiplier; }
  get parentSectors() { return this._sector.parentSectors; }

  public toJs = () => ({ ...this._sector });

  public update = async (updatedData: Partial<ISector>) => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<ISector>({
      path: `/admin/sectors/${this._id}`,
      method: 'PUT',
      data: updatedData,
    });

    if (result.success) {
      runInAction(() => {
        this._sector = result.value;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}

export class SectorsModel extends BaseModel {
  private _busy = false;
  private _checkingName = false;
  private _config: ICollectionConfig = {};
  private _creatingSector = false;
  private _filterOptions: ISectorFilterOptions = null;
  private _loadingFilterOptions = false;
  private _options: ISectorsModelOptions = null;
  private _sectors: CollectionModel<ISector, SectorModel> = null;
 
  constructor (config: ICollectionConfig = {}, options: ISectorsModelOptions = {}) {
    super();
    makeObservable<SectorsModel, PrivateFields>(this, {
      _busy: observable,
      _checkingName: observable,
      _creatingSector: observable,
      _filterOptions: observable,
      _loadingFilterOptions: observable,
      _options: observable,
      _sectors: observable,
      busy: computed,
      checkingName: computed,
      creatingSector: computed, 
      filterOptions: computed,
      loadingFilterOptions: computed,
      options: computed,
      sectors: computed,
      _init: action.bound,
      checkName: action.bound,
      createSector: action.bound,
      loadFilterOptions: action.bound,
    });
    this._config = config ?? {};
    this._options = options;
    this._init();
  }

  get busy() { return this._busy; }
  get checkingName() { return this._checkingName; }
  get creatingSector() { return this._creatingSector; }
  get filterOptions() { return this._filterOptions; }
  get loadingFilterOptions() { return this._loadingFilterOptions; }
  get options() { return this._options; }
  get sectors() { return this._sectors; }

  public checkName = async (name: string) => {
    if (this._checkingName) return;
    this._checkingName = true;

    const result = await this.webServiceHelper.sendRequest<ISectorNameStatus>({
      path: `/admin/sectors/check-name?name=${name}`,
      method: 'GET',
    });

    runInAction(() => {
      this._checkingName = false;
    });

    if (result.success) {
      return {
        ...result.value,
        available: result.value.available ? SectorNameAvailability.Available : SectorNameAvailability.Unavailable,
      };
    } else {
      throw new Error(result.error);
    }
  };

  public createSector = async (data: IBaseSector) => {
    if (this._creatingSector) return;
    this._creatingSector = true;

    const result = await this.webServiceHelper.sendRequest<ISector>({
      path: '/admin/sectors',
      method: 'POST',
      data,
    });

    runInAction(() => {
      this._creatingSector = false;
    });

    if (result.success) {
      return new SectorModel(result.value);
    } else {
      throw new Error(result.error);
    }
  };

  public loadFilterOptions = async () => {
    if (this._loadingFilterOptions) return;
    this._loadingFilterOptions = true;

    const result = await this.webServiceHelper.sendRequest<ISectorFilterOptions>({
      path: '/sectors/filter',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._filterOptions = result.value;
        this._loadingFilterOptions = false;
      });
    } else {
      runInAction(() => {
        this._loadingFilterOptions = false;
      });

      throw new Error(result.error);
    }
  };

  private _init = () => {
    const url = this._options.url ?? '/sectors';
    this._sectors = new CollectionModel<ISector, SectorModel>(
      url,
      (sectorInfo: ISector) => new SectorModel(sectorInfo),
      this._config,
    );
  };
}
