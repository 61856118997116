import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { CollectionModel, ICollectionConfig } from './collection';

type PrivateFields = '_busy' |
'_banners' |
'_isAdmin' |
'_showBanner' |
'_activeBanner' |
'_init';

type PrivateBannerFields = '_busy' |
'_banner';

export enum IBannerColors {
  Dark = 'dark',
  Light = 'light',
  Warning = 'warning',
}

export enum ILoggedInState {
  LoggedIn = 'loggedIn',
  LoggedOut = 'loggedOut',
  Both = 'both',
}

export interface IBanner {
  color: IBannerColors;
  enabled: boolean;
  endDate?: Date;
  _id: string;
  link?: string;
  linkText?: string;
  loggedInState: ILoggedInState;
  name: string;
  startDate?: Date;
  text: string;
}

export interface IBannerUpdate {
  color: IBannerColors;
  enabled: boolean;
  endDate?: string;
  _id?: string;
  link?: string;
  linkText?: string;
  loggedInState: ILoggedInState;
  name: string;
  startDate?: string;
  text: string;
}

export class BannerModel extends BaseModel {

  private _busy = false;
  private _banner: IBanner = null;

  constructor (bannerInfo: IBanner) {
    super();
    makeObservable<BannerModel, PrivateBannerFields>(this, {
      _busy: observable,
      _banner: observable,
      busy: computed,
      banner: computed,
      _id: computed,
      color: computed,
      enabled: computed,
      endDate: computed,
      link: computed,
      linkText: computed,
      loggedInState: computed,
      name: computed,
      startDate: computed,
      text: computed,
    });

    this._banner = bannerInfo;
  }

  get busy() { return this._busy; }
  get banner() { return this._banner; }
  get _id() { return this._banner._id; }
  get color() { return this._banner.color; }
  get enabled() { return this._banner.enabled; }
  get endDate() { return this._banner.endDate; }
  get link() { return this._banner.link; }
  get linkText() { return this._banner.linkText; }
  get loggedInState() { return this._banner.loggedInState; }
  get name() { return this._banner.name; }
  get startDate() { return this._banner.startDate; }
  get text() { return this._banner.text; }
}

export class BannersModel extends BaseModel {
  private _busy = true;
  private _activeBanner: IBanner = null;
  private _showBanner = false;
  private _banners: CollectionModel<IBanner, BannerModel> = null;
  private _isAdmin = false;
  private _config: ICollectionConfig = {};

  constructor(config: ICollectionConfig = {}, isAdmin = false) {
    super({ basePath: '/banner' });
    makeObservable<BannersModel, PrivateFields>(this, {
      _busy: observable,
      _banners: observable,
      _isAdmin: observable,
      _showBanner: observable,
      _activeBanner: observable,
      activeBanner: computed,
      banners: computed,
      busy: computed,
      showBanner: computed,
      loggedInBanner: computed,
      loggedOutBanner: computed,
      bothBanner: computed,
      _init: action.bound,
      createBanner: action.bound,
      updateBanner: action.bound,
    });

    this._config = config || {};
    this._isAdmin = isAdmin;
    this._init(isAdmin);
  }

  get activeBanner () { return this._activeBanner; }
  get banners () { return this._banners; }
  get busy () { return this._busy; }
  get showBanner() { return this._showBanner; }
  get loggedInBanner() { return this._banners.results.filter((banner) => banner.loggedInState === ILoggedInState.LoggedIn)[0]; }
  get loggedOutBanner() { return this._banners.results.filter((banner) => banner.loggedInState === ILoggedInState.LoggedOut)[0]; }
  get bothBanner() { return this._banners.results.filter((banner) => banner.loggedInState === ILoggedInState.Both)[0]; }

  public createBanner = async (data: IBannerUpdate) => {
    if (this._busy || !this._isAdmin) return;

    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IBanner>(
      {
        path: '/admin/banner',
        method: 'POST',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._banners.push(new BannerModel(result.value));
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  public updateBanner = async (data: IBannerUpdate) => {
    if (this._busy || !this._isAdmin) return;

    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IBanner>(
      {
        path: `/admin/banner/${data._id}`,
        method: 'PUT',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  setBannerSectionShow(show: boolean) {
    runInAction(() => this._showBanner = show );
  }

  setActiveBanner(banner: IBanner) {
    if (localStorage.getItem(`marketing-banner-${banner._id}`)) {
      runInAction(() => {
        this._activeBanner = null;
        this._showBanner = false;
      });
    } else {
      runInAction(() => {
        this._activeBanner = banner;
        this._showBanner = true;
      });
    }
  }

  private _init = (isAdmin: boolean) => {
    const url = isAdmin ? '/admin/banner' : '/banner';

    this._banners = new CollectionModel<IBanner, BannerModel>(
      url,
      (bannerInfo: IBanner) => new BannerModel(bannerInfo),
      this._config,
    );

    runInAction(() => this._busy = false);
  };
}
