import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';

type PrivateFields = '_busy' |
'_bundleCost' |
'_loadingRareProjects' |
'_rareProjects';

export interface IOffsetDonation {
  dollars: number;
  tonnes: number;
}

interface ISourceBase {
  logo_url: string;
  name: string;
  website_url: string;
}
interface ISource extends ISourceBase {
  source_id: string;
}

interface IVerifier extends ISourceBase {
  verifier_id: string;
}

interface ISdgn {
  logo_url: string;
  name: string;
  number: number;
  sdgn_id: string;
}

interface IDrawdown {
  name: string;
  number: number;
}

interface ISizes {
  s: string;
  m: string;
  xl: string;
  l: string;
  bw: string;
}

interface IRareImage {
  caption: string;
  description: string;
  image_id: string;
  image_type: string;
  name: string;
  orientation: string;
  sizes: ISizes;
  source: string;
}

interface IBenefits {
  benefit_id: string;
  description_long: string;
  description_short: string;
  images: IRareImage[];
  type: string;
}

export interface IRareProject {
  benefits: IBenefits[];
  co2_impact: string;
  cost_per_tonne: number;
  description_long: string;
  description_short: string;
  drawdowns: IDrawdown[];
  ent_ts: string | null;
  geographic_size: string;
  images: IRareImage[];
  location_city: string;
  location_country: string;
  location_lat: number;
  location_lon: number;
  location_zip: string;
  name: string;
  project_id: string;
  project_type: string;
  sdgns: ISdgn[];
  source: ISource[];
  start_ts: string | null;
  tagline: string;
  total_tonnes_available: number;
  verifiers: IVerifier[];
  vintage_ts: string;
}
export interface IRareProjectsData {
  bundle_cost_per_tonne: number;
  projects: IRareProject[];
}

export class OffsetsModel extends BaseModel {
  private _busy = false;
  private _loadingRareProjects = false;
  private _rareProjects: IRareProject[] = [];
  private _bundleCost: IRareProjectsData['bundle_cost_per_tonne'] = 0;

  constructor () {
    super();
    makeObservable<OffsetsModel, PrivateFields>(this, {
      _bundleCost: observable,
      _busy: observable, 
      _loadingRareProjects: observable,
      _rareProjects: observable,
      bundleCost: computed,
      busy: computed, 
      loadingRareProjects: computed,
      rareProjects: computed,
      loadRareProjects: action.bound,
    });
  }

  get busy() { return this._busy; }
  get bundleCost() { return this._bundleCost; }
  get rareProjects() { return this._rareProjects; }
  get loadingRareProjects() { return this._loadingRareProjects; }

  public loadRareProjects = async () => {
    if (this._loadingRareProjects) return;
    this._loadingRareProjects = true;

    const result = await this.webServiceHelper.sendRequest<IRareProjectsData>({
      path: '/integrations/rare/project',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._rareProjects = result.value.projects;
        this._bundleCost = result.value.bundle_cost_per_tonne;
        this._loadingRareProjects = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingRareProjects = false;
      });

      throw new Error(result.error);
    }
  };
}
