import React, { useEffect, useState } from 'react';
import { SentimentAnalysisContainer, SentimentAnalysisInnerWrapper, SentimentContainer, SentimentFooter, SentimentHeader, SentimentTextContainer, SourcesLinkContainer } from './styles';
import { loadCompanySentiment } from '../../../models/sentiment/api';
import { H2 } from '../../../styles/components/header';
import { SentimentCategories } from './SentimentCategoriesMenu';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { ISentiment, SentimentSubcategory } from '../../../models/sentiment/types';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { SourcesModal } from './SourcesModal';
import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedSentimentSubcategoryAtom } from '../../../models/sentiment/atom';
import { selectedSentimentCategoryIcon } from '../../../models/sentiment/selectors';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps {
  className?: string;
  companyId: string;
}

export const SentimentAnalysis: React.FC<IProps> = ({
  className,
  companyId,
}) => {
  const analytics = useAnalytics();
  const [sourcesModalIsOpen, setSourcesModalIsOpen] = useState(false);
  const [sentimentData, setSentimentData] = useState<ISentiment | null>(null);
  const [sentimentLoading, setSentimentLoading] = useState(true);
  const [sentimentLoadingError, setSentimentLoadingError] = useState(false);
  const [selectedSentimentSubcategory] = useRecoilState(selectedSentimentSubcategoryAtom);
  const icon = useRecoilValue(selectedSentimentCategoryIcon);

  const onSourcesModalClose = () => {
    setSourcesModalIsOpen(false);
  };

  useEffect(() => {
    const fetchSentimentData = async () => {
      const res = await loadCompanySentiment(companyId);
      if (res.success) {
        setSentimentData(res.data);
        setSentimentLoading(false);
      } else {
        setSentimentLoadingError(true);
      }
    };
    fetchSentimentData();
  }, [companyId]);

  const onViewAllSourcesClick = () => {
    setSourcesModalIsOpen(true);
    analytics.fireEvent('CompanyPage_ViewAllSources_Click');
  };

  if (sentimentLoadingError) return null;

  const sentimentFooter = selectedSentimentSubcategory === SentimentSubcategory?.Summary ? (
    <SentimentFooter>
      <SourcesLinkContainer>
        <span className='sources-text'>Sources:</span>
        <Button
          className='view-all-sources-button'
          kind={ ButtonKind.Blank }
          onClick={ onViewAllSourcesClick }
        >
          View All Sources
        </Button>
      </SourcesLinkContainer>
      <p>Time Frame: { dayjs(sentimentData?.timePeriod.startDate).format('MMMM YYYY') } - { dayjs(sentimentData?.timePeriod.endDate).format('MMMM YYYY') }</p>
    </SentimentFooter>
  ) : null; 

  const sentimentText = sentimentLoading
    ? <SentimentTextContainer><LoadingSpinner /></SentimentTextContainer>
    : (
      <SentimentTextContainer>
        <SentimentHeader>
          <img
            src={ icon }
            alt={ selectedSentimentSubcategory }
            aria-hidden={ true }
          />
          <h3>{ selectedSentimentSubcategory }</h3>
        </SentimentHeader>
        { sentimentData[selectedSentimentSubcategory as SentimentSubcategory]?.summary || sentimentData.overall.summary }
        { sentimentFooter }
        <SourcesModal onClose={ onSourcesModalClose } isOpen={ sourcesModalIsOpen } articles={ sentimentData.articles } />
      </SentimentTextContainer>
    );

  return (
    <SentimentAnalysisContainer className={ className }>
      <SentimentAnalysisInnerWrapper>
        <H2>Public Sentiment</H2>
        <SentimentContainer>
          <SentimentCategories />
          { sentimentText }
        </SentimentContainer>
      </SentimentAnalysisInnerWrapper>
    </SentimentAnalysisContainer>
  );
};
