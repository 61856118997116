import React, { useCallback, useMemo, useState } from 'react';
import { AccordionContainer, CompareCompaniesCardContainer, CompareCompanyLogo, GraphicSectionContainer, GraphicSectionContainerWrapper, SubcategoriesContainer, SubcategoryItem, TopSectionContainer } from './styles';
import { ICompareCompany } from '../../models/compare-companies';
import { useAnalytics } from '../../contexts/analytics-store';
import { AccordionSection } from '../AccordionSection';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { Button, ButtonLink } from '../Button';
import { InfoIcon } from '../svgs/icons/InfoIcon';
import { ButtonKind } from '../Button/styles';
import { CloseButton } from '../CloseButton';
import { Subcategories } from '../../models/unsdgs';
import { InfoModal } from '../modals/InfoModal';
import { Star } from '../svgs/graphics/Star';

interface IProps extends IThemeProps {
  className?: string;
  comparedCompany: ICompareCompany;
  onClose?(): void;
  ourPick: boolean;
  isRemovable: boolean;
}

const subcategoriesOrder = [Subcategories.ClimateAction, Subcategories.Sustainability, Subcategories.CommunityWelfare, Subcategories.DiversityInclusion];

export const CompareCompaniesCardBase: React.FC<IProps> = ({
  className = '',
  comparedCompany,
  onClose,
  ourPick,
  isRemovable,
  theme,
}) => {
  const analytics = useAnalytics();
  const company = comparedCompany.company;
  const [cardExpanded, setCardExpanded] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const onCompanyUrlClick = useCallback(() => {
    analytics.fireEvent('Compare_CompanyClick');
  }, []);

  const onInfoClick = useCallback(() => {
    setShowInfoModal(true);
    analytics.fireEvent('Company_OurPick_Click');
  }, []);

  const onInfoClose = useCallback(() => {
    setShowInfoModal(false);
    analytics.fireEvent('Company_OurPick_Close');
  }, []);

  const sortedSubcategories = useMemo(() => {
    const sortedScores = company.subcategoryScores.slice().sort((a, b) => {
      const aIndex = subcategoriesOrder.indexOf(a.subcategory.name);
      const bIndex = subcategoriesOrder.indexOf(b.subcategory.name);
      return aIndex - bIndex;
    });

    return sortedScores.map(s =>
      <SubcategoryItem key={ s.subcategory.name } color={ s.subcategory.name }>
        <div className='name'>{ s.subcategory.name }</div>
        <div className='score' >{ s.score }</div>
      </SubcategoryItem>,
    );
  }, [company.subcategoryScores]);

  const graphicSection = useMemo(() => (
    <GraphicSectionContainer>
      <ButtonLink
        href={ `/company/${company?._id}/${company?.slug}` }
        label={ `View ${company?.companyName} report card` }
        onClick={ onCompanyUrlClick }
      >
        <CompareCompanyLogo company={ company } />
      </ButtonLink>
      <ButtonLink
        className='companyName'
        href={ `/company/${company?._id}/${company?.slug}` }
        label={ `View ${company?.companyName} report card` }
        onClick={ onCompanyUrlClick }
      >
        { company.companyName }
      </ButtonLink> 
    </GraphicSectionContainer>
  ), [company]);

  const renderTopSection = useMemo(() => (
    <TopSectionContainer>
      <GraphicSectionContainerWrapper className='mobile'>
        { graphicSection }
      </GraphicSectionContainerWrapper>
      { 
        ourPick
          ? (
            <div className='our-pick'>
              <div className='our-pick-text'>
                Our Pick
              </div>
              <Button
                label='Info About Our Pick'
                onClick={ onInfoClick }
                kind={ ButtonKind.Blank }
              >
                <InfoIcon fill={ theme.colors.white } />
              </Button>
            </div>
          )
          : null
      }
      {
        isRemovable
          ? (
            <CloseButton 
              className='icon'
              label='Remove company from comparison'
              onClick={ onClose }
            />
          )
          : null
      }
    
    </TopSectionContainer>
  ), [company, isRemovable]);

  const subcategoriesAccordion = useMemo(() => {
    const accordionHeader = (
      <SubcategoryItem className='header' color='total' >
        <div className='name'>Total Score</div>
        <div className='score'>{ company.combinedScore }</div>
      </SubcategoryItem> 
    );

    const accordionChildren = (
      <SubcategoriesContainer>
        { sortedSubcategories }
      </SubcategoriesContainer>
    );

    const onAccordionClick = () => {
      analytics.fireEvent('CompareCompanies_Expand_CompareCard');
      setCardExpanded(!cardExpanded);
    };

    return (
      <AccordionContainer>
        <AccordionSection
          header= { accordionHeader }
          chevronColor={ theme.colors.grey3 }
          children={ accordionChildren }
          onClick={ onAccordionClick }
          expanded={ cardExpanded }
          className={ `accordion ${cardExpanded && 'expanded'}` }
        />
      </AccordionContainer>
    );
  }, [sortedSubcategories, cardExpanded, company]);

  const subCategories = useMemo(() => (
    <SubcategoriesContainer className='desktop'>
      { sortedSubcategories }
      <SubcategoryItem className='total' color='total'>
        <div className='name'>Total</div>
        <div className='score' >{ company.combinedScore }</div>
      </SubcategoryItem>
    </SubcategoriesContainer>
  ), [sortedSubcategories, company]);

  return (
    <CompareCompaniesCardContainer className={ `${className} ${ourPick && 'our-pick'}` }>
      <InfoModal
        graphic={ <Star /> }
        title='Our Pick'
        onClose={ onInfoClose }
        isOpen={ showInfoModal }
        body={ `${company?.companyName} performs as well or better across all indices. It is the more impactful choice.` }
      />
      { renderTopSection }
      <GraphicSectionContainerWrapper className='desktop'>
        { graphicSection }
      </GraphicSectionContainerWrapper>
      { subCategories }
      { subcategoriesAccordion }
    </CompareCompaniesCardContainer>
  );
};

export const CompareCompaniesCard = withTheme(CompareCompaniesCardBase);
