import React from 'react';
import { AppLandingPageContainer } from './styles';
import { PageDown } from '../../components/PageDown';

interface IProps {
  className?: string;
  isDoneGood?: boolean;
}

// const disclaimerText = [
//   {
//     text: '**This optional offer is not a Pathward product or service nor does Pathward endorse this offer.',
//   },
//   {
//     text: '***Standard text message and data rates, fees, and charges may apply.',
//   },
//   {
//     text: '****Visa\'s Zero Liability policy does not apply to certain commercial card and anonymous prepaid card transactions or transactions not processed by Visa. Cardholders must use care in protecting their card and notify their issuing financial institution immediately of any unauthorized use. Contact your issuer for more detail.',
//   },
//   {
//     text: 'The Karma Wallet Visa® Prepaid Card is issued by Pathward®, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. The Karma Wallet Visa Prepaid Card is powered by Marqeta. This card can be used everywhere Visa debit cards are accepted.',
//     paddingTop: true,
//   },
//   { 
//     text: 'Karma Wallet is a paid membership that costs $40 per year. Cardholders can cancel their membership at any time, with at least 30 days\' notice. Our cardholders earn up to $300 in cashback per year on average with a $500 monthly average spend.',
//     paddingTop: true,
//   },
// ];

export const AppLanding: React.FC<IProps> = ({
  className = '',
  // isDoneGood = false,
}) => 
// Remove once we fix marqeta issue and need to bring pages back
  (
    <AppLandingPageContainer title='Karma Wallet Mobile App' className={ className }>
      <PageDown />
    </AppLandingPageContainer>
  )

// return (
//   <DoneGoodVersionStore isDoneGood={ isDoneGood }>
//     <AppLandingPageContainer title={ `Karma Wallet Mobile App${isDoneGood ? ' DoneGood' : ''}` } className={ className }>
//       <AppLandingPageHero />
//       <TopBrandsOffersSection />
//       <AppLandingBenefitsSection />
//       <YouShopWeDonateSection />
//       <VideosAndArticlesSection />
//       <WhatIsTheKarmaWalletCardSection />
//       <SafetyAndSecuritySection />
//       <NotReadySection />
//       <DisclaimerContainer>
//         <DisclaimerSection textItems={ disclaimerText } />
//       </DisclaimerContainer>
//     </AppLandingPageContainer>
//   </DoneGoodVersionStore>
// );
;
