import React, { useCallback, useMemo } from 'react';
import { useAnalytics } from '../../../contexts/analytics-store';
import { ButtonKind } from '../../Button/styles';
import { CtaType } from '../../CTAs';
import { Celebrate } from '../../svgs/graphics/Celebrate';
import { InfoModal } from '../InfoModal';

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

export const TransactionsReadyModal: React.FC<IProps> = ({ onClose, ...restProps }) => {
  const analytics = useAnalytics();

  const onNotNowClick = useCallback(() => {
    analytics.fireEvent('TransactionsReady_NotNow_Click');
    onClose();
  }, [onClose]);

  const onViewTransactionsClick = useCallback(() => {
    analytics.fireEvent('TransactionsReady_ViewTransactions_Click');
    onClose();
  }, []);

  const ctas = useMemo(() => ([
    {
      className: 'transactions-ready-cta',
      id: 'transactions-ready-view-transactions',
      text: 'View Transactions',
      kind: ButtonKind.Primary,
      ctaType: CtaType.Link,
      url: '/account/transactions',
      onClick: onViewTransactionsClick,
    },
    {
      className: 'transactions-ready-cta',
      id: 'transactions-ready-not-now',
      text: 'Not Now',
      kind: ButtonKind.PrimaryGhost,
      onClick: onNotNowClick,
    },
  ]), []);

  return (
    <InfoModal
      { ...restProps }
      onClose={ onClose }
      graphic={ <Celebrate /> }
      title='Transactions Added!'
      body={ 'Your card is linked and we\'ve analyzed all matched transactions.' }
      ctas={ ctas }
    />
  );
};
