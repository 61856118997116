import React from 'react';
import { ErrorPage } from '../../pages/ErrorPage';
import { ErrorBoundary } from '../ErrorBoundary';

interface IErrorPage {
  Component: React.FC;
  componentProps?: any;
}

export const WrappedWithErrorPage: React.FC<IErrorPage> = ({ Component, componentProps }) => (
  <ErrorBoundary Fallback={ <ErrorPage /> }>
    { componentProps ? <Component { ...componentProps } /> : <Component /> }
  </ErrorBoundary>
);
