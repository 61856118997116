import styled from 'styled-components';
import { FlexCol, FlexRow, NoScrollBar } from '../../styles/styles';
import { animated } from 'react-spring';

export const FilterCompaniesModalContainer = styled(animated.div)`
  ${NoScrollBar}
  ${FlexCol}
  align-items: center;
  background: white;
  border-radius: 8px 8px  0px 0px;
  box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  left: 50%;
  margin: 0 auto;
  max-width: 944px;
  min-height: 104px;
  padding: 8px 20px;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    flex-direction: row;
    height: 104px;
  }
`;

export const CardsContainer = styled.div`
  ${FlexCol}
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    padding-right: 10px;
  }
`;

export const PlaceholderCard = styled.div`
  ${FlexRow}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey2 + '22'};
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  height: 35px;
  justify-content: center;
  padding: 8px 16px;
  width: 100%;

  p {
    color: ${({ theme }) => theme.colors.grey3 + 'AA'};
    font-size: 12px;
    line-height: 1rem;
    margin: unset;
    text-align: center;
    width: 90%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      width: 70%;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    width: 32%;
    height: 76px;
  }
`;

export const CompareCompanyCard = styled.div`
  ${FlexRow}
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 4px;
  gap: 10px 10px;
  height: 35px;
  justify-content: space-between;
  position: relative;
  padding: 8px 10px;
  width: 100%;

  .remove-button {
    height: 11px;
    position: absolute;
    right: 7px;
    top: 7px;
    width: 11px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    width: 32%;
    height: 76px;
`;

export const CompanyInfoContainer = styled.div`
  ${FlexRow}
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 100%;

  .company-name {
    font-size: 12px;
    font-weight: 600;
    word-break: break-all;
  }

  .logo-container {
    ${FlexRow}
    align-items: center;
    max-width: 24px;
    max-height: 24px;

    img {
      max-height: 24px;
      max-width: 100%;
      width: auto;

      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        max-height: 50px;
      }
    }

    svg {
      margin: 0 auto;
      max-height: 24px;
      max-width: 100%;
      width: auto;

      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        max-height: 50px;
      }
    }

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      height: 60px;
      min-width: 60px;
      max-height: 50px;
      min-height: 50px;
      width: 60px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    .company-name {
      display: flex;
      font-size: 14px;
    }
`;

export const CompareModalControlsContainer = styled.div`
  ${FlexRow}
  align-items: center;
  height: 74px;
  justify-content: space-around;
  padding: 10px 0;
  width: 100%;

  button {
    padding: 3px 35px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexCol}
    padding: unset;
    width: unset;
  }
`;
