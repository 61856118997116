import styled from 'styled-components';
import { Section } from '../../styles/components/containers';
import { FlexColCenter, FlexRow } from '../../styles/styles';
import { TextLGBold } from '../../styles/components/text';

export const BackgroundContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  .wave-a {
    height: 800px;
    left: 10%;
    position: absolute;
    top: 250px;
    transform: rotate(-80deg);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 45%;
      top: -50px;
    }
  }

  .hand-with-mobile-app {
    position: absolute;
    right: 90%;
    top: 5%;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      right: 80%;
      top: 5%;
    }
  }

  .leaf-a {
    left: 90%;
    position: absolute;
    top: 60px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      left: 80%;
      top: 60px;
    }
  }

  .light {
    bottom: 5%;
    left: -45%;
    position: absolute;
    transform: scale(0.25);

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      bottom: 5%;
      left: 5%;
    }
  }

  .phone {
    bottom: 20%;
    position: absolute;
    right: -20%;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      bottom: 20%;
      right: 1%;
    }

    @media (${({ theme }) => theme.breakpoints.xxlMin}) {
      bottom: 20%;
      right: 10%;
    }
  }
`;

export const CommunityImpactContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  position: relative;
`;

export const CommunityImpactInnerWrapper = styled(Section)`
  ${FlexColCenter}
  align-items: center;
  background: transparent;
  position: relative;
  z-index: 1;

  .values-text {
    color: ${({ theme }) => theme.colors.white};
    ${TextLGBold}
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  h2, p {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }

  p {
    margin-bottom: 10px;
  }

  .learn-more {
    margin: 30px auto 0;
  }

  .last-update-text {
    color: ${({ theme }) => theme.colors.grey4};
    margin-top: 20px;
  }
`;

export const CommunityImpactHeaderWrapper = styled.div`
  ${FlexColCenter}
  align-items: center;

  h2 {
    margin: 10px 0;
  }

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
  }
`;

export const ImpactValuesContainer = styled.div`
  ${FlexColCenter}
  gap: 30px;
  margin: 60px 0;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
`;

export const ImpactValueCard = styled.div`
  background-color: ${({ theme }) => theme.colors.darkGreen3};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.green};
  min-width: 260px;
  padding: 15px 20px;
  text-align: center;

  .point-value {
    font-size: 32px;
    font-weight: 600;
  }
`;

export const TopValuesWrapper = styled.div`
  ${FlexColCenter}
  align-items: center;
  margin-top: 10px;

  h2 {
    font-size: 70px;
    margin: 10px 0;
  }

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 600;
  }
`;

export const TopValuesContainer = styled.div`
  ${FlexColCenter}
  gap: 50px;
  margin-top: 60px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    gap: 25px;
  }
`;
