import styled from 'styled-components';
import { FlexCenter, FlexCol, BoxShadow } from '../../styles/styles';

export const GroupStatementSummaryContainer = styled.div`
  align-self: stretch;
  padding: 0;
  width: 100%;
  background: #fff;
  border-left: 1px solid ${({ theme }) => theme.colors.lightGray1};

  .header {
    text-align: center;
    text-transform: uppercase;
  }

  .months {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray1};
  }

  .empty-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .statement-button-wrapper {
    margin: 20px auto;
    text-align: center;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 30%;
    padding: 15px;

    .months {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    padding: 10px;

    &.sticky {
      ${BoxShadow}
      position: fixed;
      top: 75px;
    }
  }
`;

export const AmountSectionContainer = styled.div`
  ${FlexCenter}
  margin-top: 10px;
`;

export const UnitWrapperContainer = styled.div`
  ${FlexCol}
  align-items: center;
  padding: 0 10px 10px;

  .label {
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 5px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 15px;
    }
  }

  &:first-of-type {
    border-right: solid 1px ${({ theme }) => theme.colors.lightGray1};
  }
`;
