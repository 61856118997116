import { SentimentSubcategory } from '../models/sentiment/types';

export interface ISentimentSubcategory {
  id: string;
  title: SentimentSubcategory;
  icon: string;
}

export interface ISentimentCategories {
  summary: ISentimentSubcategory[];
  planet: ISentimentSubcategory[];
  people: ISentimentSubcategory[];
}

export const sentimentCategories: ISentimentCategories = {
  summary: [
    {
      id: '01',
      title: SentimentSubcategory.Summary,
      icon: 'https://cdn.karmawallet.io/uploads/summary-icon.svg',
    },
  ],
  planet: [
    {
      id: '02',
      title: SentimentSubcategory.ClimateImpact,
      icon: 'https://cdn.karmawallet.io/uploads/climate-impact-icon.svg',
    },
    {
      id: '03',
      title: SentimentSubcategory.Biodiversity,
      icon: 'https://cdn.karmawallet.io/uploads/biodiversity-and-environmental-conservation-icon.svg',
    },
    {
      id: '04',
      title: SentimentSubcategory.WasteManagement,
      icon: 'https://cdn.karmawallet.io/uploads/waste-management-icon.svg',
    },
  ],
  people: [
    {
      id: '05',
      title: SentimentSubcategory.Governance,
      icon: 'https://cdn.karmawallet.io/uploads/governance-icon.svg',
    },
    {
      id: '06',
      title: SentimentSubcategory.LaborPractices,
      icon: 'https://cdn.karmawallet.io/uploads/labor-practice-icon.svg',
    },
    {
      id: '07',
      title: SentimentSubcategory.Diversity,
      icon: 'https://cdn.karmawallet.io/uploads/diversity-equity-and-inclusion-icon.svg',
    },
    {
      id: '08',
      title: SentimentSubcategory.Community,
      icon: 'https://cdn.karmawallet.io/uploads/community-development-and-philanthropy-icon.svg',
    },
  ],
};
