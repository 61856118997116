export const formatCurrency = (amount: number) => (Math.round(amount * 100) / 100).toFixed(2);

export const slugify = (str: string) => (
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
);
