import React, { useCallback } from 'react';
import { Ratings } from '../../models/impact';
import { CompanyRatingTagContainer } from './styles';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';

interface IProps extends IThemeProps {
  className?: string;
  rating: any;
}

export const CompanyRatingTagBase: React.FC<IProps> = ({
  className = '',
  rating,
  theme,
}) => {
  const renderIndicator = useCallback(() => {
    if (rating >= 8 || rating === Ratings.Positive ) return <p>Positive</p>;
    if (rating < 0 || rating === Ratings.Negative ) return <p>Negative</p>;
    return <p>Neutral</p>;
  }, [rating]);

  const backgroundColor = useCallback(() => {
    if (rating >= 8 || rating === Ratings.Positive ) return theme.colors.secondary;
    if (rating < 0 || rating === Ratings.Negative ) return theme.colors.negative2;
    return theme.colors.tertiary;
  }, [rating]);

  return (
    <CompanyRatingTagContainer
      className={ `${className} ${rating}` }
      backgroundColor={ backgroundColor() }
    >
      { renderIndicator() }
    </CompanyRatingTagContainer>
  );
};

export const CompanyRatingTag = withTheme(CompanyRatingTagBase);
