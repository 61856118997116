import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { JobPosting } from '../../components/JobPosting';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { Boomerang } from '../../components/svgs/shapes/Boomerang';
import { Square } from '../../components/svgs/shapes/Square';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { JobPostingModel, JobPostingsModel } from '../../models/job-postings';
import { H1, H2 } from '../../styles/components/header';
import { CareersContainer, Header, JobPostings, NoJobs, OpenPositionsContainer } from './styles';

interface IProps {
  className?: string;
}

// const preview = {
//   img: 'https://s3.amazonaws.com/assets.karmawallet/kw_logo.png',
//   title: 'We\'re hiring!',
//   description: 'We\'re looking for talented people to join our team.',
// };

const CareersBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const errorMessages = useErrorMessages();
  const jobsModel = useRef(new JobPostingsModel()).current;
  
  const loadMore = () => {
    jobsModel.jobs.loadMore({ published: true })
      .catch(err => {
        analytics.fireEvent('Careers_LoadingError');
        errorMessages.push({
          title: 'Error Loading Careers',
          message: err.message,
        });
      });
  };

  useEffect(() => {
    if (!jobsModel.jobs.firstPageLoaded) {
      loadMore();
    }
  }, []);

  const onJobPostingClick = useCallback((job: JobPostingModel) => () => {
    analytics.fireEvent('Careers_Job_Click', `${job._id}`);
    navigate(`/careers/${job._id}`);
  }, []);

  const renderOpenPositions = () => {
    let jobs: JSX.Element[] = [];

    if (!!jobsModel.jobs.results.length) {
      jobs = jobsModel.jobs.results.map(job => (
        <JobPosting
          key={ job._id }
          className='job-posting'
          job={ job }
          onClick={ onJobPostingClick(job) }
        />
      ));
    } else {
      if (!jobsModel.busy) {
        jobs.push(<NoJobs key='no-jobs'>{ 'We don\'t currently have any open positions' }</NoJobs>);
      }
    }

    if (jobsModel.busy) jobs.push(<LoadingSpinner />);
    
    return (
      <div className='open-positions-wrapper'>
        { !!jobsModel.jobs.results.length && !jobsModel.busy && <H2>Open roles</H2> }
        <JobPostings>
          { jobs }
        </JobPostings>
      </div>
    );
  };

  return (
    <CareersContainer
      className={ className }
      title='Careers'
    >
      <Header>
        <H1>We help people shop with intent.<br />Join us.</H1>
        <Boomerang className='boomerang' />
      </Header>
      <OpenPositionsContainer>
        <Square className='square' />
        { renderOpenPositions() }
      </OpenPositionsContainer>
    </CareersContainer>
  );
};

export const Careers = observer(CareersBase);
