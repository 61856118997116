import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Planet: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }planet-graphic` }
    id={ id }
    width={ 151 }
    height={ 150 }
    fill='none'
  >
    <path d='M150.516 75C150.516 116.406 116.954 150 75.5165 150C34.079 150 0.516602 116.438 0.516602 75C0.516602 33.5938 34.079 0 75.5165 0C116.954 0 150.516 33.5938 150.516 75Z' fill='#A3F2EB' />
    <path d='M70.4535 63.5C69.4847 69.6562 59.5785 69.1875 57.2035 74.8438C55.2973 80.0625 59.1098 86.2187 62.891 89.0312C73.2972 95.6562 82.2972 90.4688 82.2972 107C82.2972 115.5 74.7347 132.562 85.6097 136.812C94.6097 140.125 109.735 126.406 114.016 121.187C117.328 116.469 118.266 113.625 123.016 109.844C128.703 105.125 130.578 104.656 124.422 100.375C119.703 97.0625 116.391 98.4687 114.485 91.8437C113.547 88.5312 116.86 83.3438 115.922 79.5312C114.485 70.5312 103.141 69.125 95.1097 65.3437C86.5785 61.5625 81.391 61.5625 72.391 61.5625L70.4535 63.5Z' fill='#35AE3E' />
    <path d='M125.986 19.5312C120.798 22.2187 112.705 23.4063 108.298 24.9063C95.9859 29.6562 102.611 29.1562 101.673 39.5625C104.986 40.5 107.83 39.0938 109.705 36.25C113.017 45.7188 101.205 43.8125 96.9234 43.8125C94.5484 43.8125 92.6734 42.875 90.2984 43.8125C86.5172 45.7188 91.2359 52.3437 86.0484 47.5937C83.6734 46.1875 85.5797 41.9062 82.2672 39.5625C80.8609 38.625 73.2672 38.1562 71.861 39.0938C66.1735 42.875 73.7672 49.0312 78.0172 52.3437C81.3297 55.1875 79.8922 56.125 85.1109 57.0938C87.9547 57.5625 90.7984 55.1875 93.6422 56.1562C99.3297 57.5625 101.673 63.25 107.83 65.1562C115.392 67.5312 119.173 67.0625 122.486 74.625C124.392 79.3437 124.861 84.5625 127.705 89.75C132.423 96.8437 138.58 101.562 143.33 92.5937C145.423 88.0312 144.205 83.0937 149.986 83.875C150.33 80.9375 150.517 78 150.517 75C150.517 53.0312 141.048 33.25 125.986 19.5312Z' fill='#35AE3E' />
    <path d='M32.4848 109.844C31.0786 108.437 30.1098 102.281 28.7036 100.375C28.2348 98.9687 25.8598 98.9687 24.9223 98C23.9536 96.5937 25.3911 93.75 23.4848 92.8125C20.6411 90.9062 17.7973 91.875 14.9848 90.9062C10.2348 89.5 12.6098 91.375 8.8286 87.125C5.98485 84.2812 3.98486 78.6875 0.547363 75.875C0.828613 100.531 12.9848 122.281 31.5786 135.75C33.9223 133.031 36.7348 130.562 39.5785 128.281C43.8285 124.5 50.9535 124.031 47.6098 116.937C44.766 109.375 36.2661 113.156 32.4848 109.844Z' fill='#35AE3E' />
    <path d='M32.5795 33.2812C27.9858 29.8437 24.1108 26.2812 19.4546 25.2188C9.76708 36.125 3.23584 49.8437 1.20459 65C5.48583 64.9687 3.86084 72.9687 9.86083 70.1875C11.2983 69.7187 14.5796 62.625 16.0171 60.7188C18.8608 57.875 36.8295 51.25 28.7983 47C29.7358 45.5625 30.7045 45.0937 32.5795 44.625C33.517 47.4687 34.9545 49.375 37.7983 49.8438C39.6733 41.3438 39.6733 38.0312 32.5795 33.2812Z' fill='#35AE3E' />
    <path d='M28.7666 24.8437C29.7041 24.8437 36.7978 18.2187 36.7978 17.75C36.7978 15.375 35.5166 13.7187 33.6728 12.7188C29.8916 15.3125 26.3291 18.2187 23.0479 21.4375C24.8603 23.0312 26.1103 24.8437 28.7666 24.8437Z' fill='#35AE3E' />
    <path d='M73.9539 0.03125C64.0789 0.25 54.6414 2.34375 46.0789 6C46.7351 6.4375 47.3914 6.84375 48.0476 7.1875C44.8601 12.5 43.0789 13.5625 46.6101 19.9688C49.1101 25.2812 52.9851 24.5625 57.6101 28.8437C61.1726 24.9375 58.3289 23.5312 59.3914 19.9688C60.1101 16.4062 63.2976 14.6563 66.4851 13.2188C71.4539 11.0938 80.3288 13.5625 79.2663 6.125C78.9226 3.53125 76.8913 1.5 73.9539 0.03125Z' fill='#35AE3E' />
    <path d='M69.4232 16.9373C73.3294 16.2186 73.6732 20.1248 77.5794 19.0623C80.0794 25.4373 64.4545 24.0311 69.4232 17.2811V16.9373Z' fill='#35AE3E' />
  </SvgIcon>
);
