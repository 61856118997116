import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const ShoppingBasketWithCoins: FC<IProps> = ({ className = '', id = '', fill = 'none' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }shopping-basket-with-coins` }
    id={ id }
    width={ 200 }
    height={ 200 }
    fill={ fill }
  >
    <g clipPath='url(#clip0_3947_13889)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M162.691 124.901C162.691 124.901 156.584 131.047 156.885 134.432C157.125 137.133 157.792 140.789 159.847 144.476C161.836 148.045 165.413 151.396 168.03 153.336C170.897 155.463 175.408 158.095 179.45 158.69C182.907 159.199 185.881 158.953 187.945 157.687C190.161 156.329 194.12 151.056 194.12 151.056L162.691 124.901Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M169.364 148.668C178.027 155.991 188.707 157.601 193.219 152.264C197.73 146.926 194.365 136.663 185.702 129.34C177.039 122.017 166.359 120.407 161.847 125.745C157.336 131.082 160.701 141.345 169.364 148.668Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M171.657 146.045C178.505 151.833 186.73 153.361 190.03 149.457C193.33 145.554 190.454 137.698 183.606 131.909C176.759 126.121 168.533 124.593 165.234 128.496C161.934 132.4 164.81 140.256 171.657 146.045Z' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M178.992 131.942L178.501 133.013C178.501 133.013 175.394 132.331 174.544 133.758C174.177 134.374 173.759 135.679 174.726 137.226C175.802 138.947 178.497 140.66 178.818 141.397C179.071 141.977 178.445 142.266 177.975 142.417C177.504 142.568 176.747 142.544 176.232 142.189C175.908 141.967 175.134 141.399 174.504 140.8C173.577 139.918 172.789 138.97 172.789 138.97L170.515 139.988C170.515 139.988 171.63 141.345 172.176 141.89C172.67 142.383 173.79 143.256 173.79 143.256L173.265 144.495L176.322 145.917L176.839 144.713C176.839 144.713 179.587 145.401 181.291 144.242C182.073 143.71 182.519 142.467 182.123 141.55C181.581 140.293 179.181 138.291 178.07 137.306C177.587 136.878 176.751 136.156 177.707 135.517C178.632 134.899 179.746 135.819 180.299 136.225C180.951 136.704 182.474 138.056 182.474 138.056L184.413 136.891C184.413 136.891 183.576 135.912 182.986 135.475C182.5 135.115 181.437 134.368 181.437 134.368L181.879 133.351L178.992 131.942Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.478464' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M157.395 137.235L159.891 132.594' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M158.499 141.372L160.802 137.573' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M160.227 144.989L162.694 141.032' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M162.634 148.163L164.946 144.72' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M165.541 151.028L167.76 147.386' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M168.953 153.732L171.012 150.176' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M173.463 156.453L175.265 152.771' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M178.207 158.369L179.84 154.694' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M182.976 158.852L184.356 155.558' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M186.738 158.203L187.971 155.246' stroke='#001C20' strokeWidth='0.885159' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M186.378 146.07C186.432 146.011 186.412 146.05 186.288 145.973' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M191.196 150.632C191.269 150.517 191.362 150.214 191.524 150.077' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M192.05 149.458C192.055 149.421 192.053 149.439 192.056 149.404' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M167.22 131.344C166.939 131.088 167.285 130.669 167.487 130.341' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M168.256 129.713C168.225 129.712 168.236 129.738 168.234 129.763' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M161.78 128.831C161.769 128.813 161.864 128.89 161.865 128.891C161.94 128.941 161.979 128.963 162.06 129.03C162.207 129.15 162.37 129.238 162.518 129.357' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M162.307 128.314C162.509 128.516 162.665 128.677 162.933 128.794' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M162.716 136.74C162.728 136.721 162.731 136.742 162.748 136.743' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M163.527 139.016C163.536 138.906 163.659 138.839 163.732 138.766' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M164.055 139.563C164.047 139.573 164.115 139.509 164.127 139.496C164.168 139.451 164.2 139.4 164.236 139.349' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M186.713 137.913C186.683 137.898 186.663 137.923 186.644 137.945' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M176.396 134.604C176.397 134.593 176.397 134.598 176.397 134.618' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M175.589 130.326C175.558 130.226 175.593 130.097 175.661 130.023' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M176.29 130.243C176.292 130.227 176.292 130.248 176.292 130.254' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M168.499 136.179C168.578 136.437 169.07 136.653 169.299 136.7' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M170.173 136.831C170.17 136.83 170.167 136.83 170.164 136.829' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M181.6 128.321C181.556 128.311 181.667 128.322 181.698 128.35C181.798 128.442 181.912 128.516 182.037 128.592C182.353 128.785 182.53 129.011 182.795 129.256' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M183.465 129.807C183.483 129.815 183.477 129.808 183.477 129.831' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M193.17 143.779C193.071 143.701 193.197 143.262 193.146 143.126' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M192.846 142.265C192.813 142.223 192.815 142.218 192.812 142.171' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M176.015 151.552C176.16 151.463 176.253 151.147 176.3 150.983' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M176.613 151.633C176.635 151.475 176.747 151.304 176.821 151.162' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M173.109 152.68C173.076 152.701 173.046 152.671 173.08 152.669' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M167.414 150.581C167.472 150.756 167.606 150.838 167.745 150.967' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M168.342 150.332C168.366 150.362 168.37 150.382 168.4 150.41' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M182.589 148.285C182.702 148.336 182.755 148.386 182.915 148.326' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M181.955 157.084C181.933 157.038 181.95 157.053 181.905 157.037' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M160.551 140.771C160.534 140.759 160.548 140.77 160.526 140.724' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M168.432 125.18C168.367 125.143 168.37 125.169 168.389 125.116' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M185.721 153.365C185.724 153.299 185.805 153.315 185.865 153.337' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M175.884 155.465C175.801 155.383 176.21 155.78 176.257 155.797' stroke='#001C20' strokeWidth='0.334925' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M174.031 137.901C167.538 144.158 144.469 163.743 137.508 169.584C135.918 170.918 134.293 172.494 132.268 172.95C129.814 173.501 125.86 173.628 122.784 172.897C109.455 169.732 65.1671 158.304 52.2973 153.958C49.1991 152.911 47.1202 149.698 45.5655 146.821C41.113 138.583 25.8438 104.109 25.8438 104.109C25.8438 104.109 89.8942 116.79 104.562 119.208C107.624 119.713 110.899 119.478 113.853 118.626C116.807 117.773 119.692 115.952 122.287 114.094C131.711 107.344 170.397 78.1229 170.397 78.1229C170.397 78.1229 175.863 122.078 176.469 132.041C176.597 134.153 175.554 136.433 174.031 137.901Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M88.7969 114.494C89.4558 121.762 99.6918 152.147 103.947 160.134C105.612 163.259 114.644 165.938 114.325 162.411C113.666 155.144 104.249 124.513 99.9943 116.527C98.2108 113.178 88.4547 110.715 88.7969 114.494Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M137.868 100.793C141.169 107.302 148.315 136.605 148.7 146.193C148.842 149.732 140.918 158.439 139.316 155.28C136.016 148.771 128.314 117.854 127.68 108.828C127.413 105.043 136.152 97.4094 137.868 100.793Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M160.365 84.4045C163.195 91.1315 167.651 121.626 167.355 131.217C167.245 134.757 159.508 141.695 158.135 138.43C155.305 131.703 151.602 100.209 151.61 91.16C151.613 87.3669 158.893 80.9081 160.365 84.4045Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M93.5537 112.958C93.5537 112.958 105.943 147.966 108.888 154.738C109.519 156.191 113.099 156.722 113.099 156.722' fill='#C0E0CE' />
      <path d='M93.5537 112.958C93.5537 112.958 105.943 147.966 108.888 154.738C109.519 156.191 113.099 156.722 113.099 156.722' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M69.6235 110.842C70.2823 118.11 80.0448 146.064 84.2999 154.05C85.9648 157.176 94.9974 159.855 94.6779 156.328C94.0191 149.06 81.7765 119.351 77.5215 111.365C75.738 108.017 69.2812 107.064 69.6235 110.842Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M74.4131 108.854C74.4131 108.854 86.9836 141.927 89.928 148.699C90.5599 150.152 92.9192 150.411 92.9192 150.411' fill='#C0E0CE' />
      <path d='M74.4131 108.854C74.4131 108.854 86.9836 141.927 89.928 148.699C90.5599 150.152 92.9192 150.411 92.9192 150.411' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M109.015 155.447L104.199 160.319' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M90.3452 149.418L85.1572 154.725' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M51.523 106.635C52.2278 113.899 62.613 141.422 66.9177 149.381C68.6031 152.496 77.2472 155.6 76.9054 152.075C76.2007 144.811 62.9297 115.232 58.6244 107.273C56.8197 103.936 51.157 102.859 51.523 106.635Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M33.668 103.728C34.6306 110.962 46.6209 137.099 51.2065 144.9C53.0008 147.953 60.4372 150.626 59.9703 147.115C59.0078 139.881 44.9098 111.83 40.3242 104.028C38.4021 100.758 33.1679 99.9674 33.668 103.728Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M55.5273 104.752C55.5273 104.752 69.8308 136.944 72.8186 143.697C73.4589 145.145 75.005 145.323 75.005 145.323' fill='#C0E0CE' />
      <path d='M55.5273 104.752C55.5273 104.752 69.8308 136.944 72.8186 143.697C73.4589 145.145 75.005 145.323 75.005 145.323' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M73.5295 144.752L67.7811 150.03' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M52.001 145.675L58.2221 141.733' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M133.232 101.467C133.232 101.467 142.278 137.168 143.13 145.634C143.402 148.331 138.345 152.261 138.345 152.261' fill='#C0E0CE' />
      <path d='M133.232 101.467C133.232 101.467 142.278 137.168 143.13 145.634C143.402 148.331 138.345 152.261 138.345 152.261' stroke='#001C20' strokeWidth='0.993719' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M143.445 145.572L148.508 146.982' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M154.737 85.8835C154.737 85.8835 160.944 122.815 161.44 131.176C161.561 133.217 157.714 136.049 157.714 136.049' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M161.46 131.205L167.175 131.633' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M154.194 117.841C155.349 120.097 157.345 120.711 159.708 120.875L154.194 117.841Z' fill='#C0E0CE' />
      <path d='M154.194 117.841C155.349 120.097 157.345 120.711 159.708 120.875' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M16.491 92.2364C17.1388 95.0345 18.212 102.981 22.5073 104.353C37.8323 109.252 93.186 118.806 108.441 121.628C110.279 121.967 112.245 121.774 114.036 121.282C115.826 120.792 117.635 119.819 119.185 118.681C129.042 111.443 163.957 85.3267 173.178 77.8525C174.274 76.9643 174.521 75.2475 174.512 73.8368C174.493 71.1884 173.885 64.374 173.07 61.9607C172.608 60.5958 171.052 59.5048 169.62 59.3571C154.167 57.7638 96.1971 53.2145 80.3549 52.4015C78.3073 52.2964 76.3463 53.4647 74.5649 54.4797C64.2755 60.3408 28.2992 81.2754 18.6203 87.5681C17.1863 88.5004 16.1051 90.57 16.491 92.2364Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M16.7881 91.5555C16.7881 91.5555 86.5782 103.738 101.679 106.157C103.559 106.459 105.557 106.587 107.39 106.076C109.513 105.486 112.28 104.119 114.414 102.617C125.174 95.0423 171.948 60.6311 171.948 60.6311' fill='#C0E0CE' />
      <path d='M16.7881 91.5555C16.7881 91.5555 86.5782 103.738 101.679 106.157C103.559 106.459 105.557 106.587 107.39 106.076C109.513 105.486 112.28 104.119 114.414 102.617C125.174 95.0423 171.948 60.6311 171.948 60.6311' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M26.8291 86.5747C35.2236 81.1205 68.1034 61.6077 77.2662 56.4262C78.6109 55.6657 80.2624 55.3904 81.8045 55.4857C95.6843 56.3431 147.34 60.1012 160.546 61.5708C161.465 61.6731 161.772 63.7424 161.036 64.3027C152.772 70.5911 120.662 93.101 110.964 99.3007C108.601 100.812 105.628 101.838 102.843 101.502C88.8328 99.8112 39.5009 91.681 26.899 89.1527C25.9168 88.9561 26.1083 87.0433 26.8291 86.5747Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M33.8001 69.7192L64.2585 95.5559L42.9611 92.0187C42.9611 92.0187 26.6772 78.0239 25.1074 76.0629C22.5762 72.9012 22.1741 66.5544 25.2449 63.9139C33.1024 57.1573 62.785 40.2427 72.2526 35.5234C75.1764 34.0662 79.2269 33.9586 82.052 35.5979C89.6171 39.9869 111.643 56.4726 117.641 61.8577C119.145 63.2079 118.926 66.6467 118.041 67.908C117.157 69.1693 114.055 70.3819 112.335 69.4256C105.926 65.8634 85.7795 50.5886 79.5897 46.5345C78.3018 45.6906 76.5715 44.4052 75.1977 45.1008C67.566 48.965 33.8001 69.7192 33.8001 69.7192Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M34.9498 70.7145L29.1348 65.9288L73.0626 39.4512' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M144.961 72.5599L122.618 70.7028C122.618 70.7028 120.355 67.9764 120.412 66.5933C120.47 65.2103 121.249 62.33 122.963 62.4045C129.088 62.6709 150.379 63.78 155.94 64.6609C156.946 64.8202 157.135 67.064 156.332 67.6902C149.23 73.2294 121.462 92.3327 113.327 97.8977C111.507 99.1423 109.587 100.508 107.529 101.079C105.47 101.651 103.146 101.595 100.977 101.328C94.6272 100.547 75.2513 97.6854 69.4332 96.3946C68.6384 96.2178 68.2787 94.156 69.0921 94.1943C74.7238 94.4591 93.783 97.018 100.282 97.5739C102.875 97.7961 105.819 98.8015 108.09 97.5306C115.537 93.3615 144.961 72.5599 144.961 72.5599Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M53.0225 85.7309C53.0225 85.7309 73.8458 71.929 78.7981 68.9324C79.9696 68.2234 81.3654 67.6745 82.7329 67.7512C93.7003 68.366 144.6 72.6221 144.6 72.6221' fill='#C0E0CE' />
      <path d='M53.0225 85.7309C53.0225 85.7309 73.8458 71.929 78.7981 68.9324C79.9696 68.2234 81.3654 67.6745 82.7329 67.7512C93.7003 68.366 144.6 72.6221 144.6 72.6221' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M68.6331 95.5497C68.6331 95.5497 66.1556 89.8243 65.6253 87.7674C65.2456 86.2949 65.1052 84.7265 65.8775 83.4165C66.5929 82.2024 69.998 78.913 72.5655 78.5367C73.7051 78.3691 74.7956 80.1029 75.3409 81.2851C76.6147 84.0463 80.2094 95.101 80.2094 95.101' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M70.6279 79.6011L76.4393 94.6304' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M103.372 97.6448C103.372 97.6448 93.0075 96.4258 92.9514 96.1765C92.3734 93.6262 89.2608 79.7756 88.6055 76.2731C88.4727 75.5638 87.953 73.6532 88.8831 73.3344C90.0915 72.9198 94.3856 73.3202 95.856 73.7853C96.8038 74.0856 97.4073 75.1769 97.7062 76.1254C98.9587 80.1014 103.372 97.6448 103.372 97.6448Z' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M93.1699 73.4966L99.332 97.3717' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M109.33 96.6302C109.33 96.6302 106.047 82.0284 105.413 78.4344C105.252 77.5249 104.641 74.7374 105.526 74.4719C106.887 74.0636 112.932 74.185 114.61 74.6678C115.647 74.966 116.105 76.4599 116.447 77.4823C117.272 79.9481 118.586 90.1877 118.586 90.1877' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M110.856 74.5077L114.355 92.8605' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M124.445 86.5374C124.445 86.5374 123.398 80.5464 123.264 78.7544C123.19 77.7583 122.68 76.0408 123.645 75.783C125.289 75.3435 131.698 74.9246 133.13 76.1196C134.161 76.978 134.141 79.6802 134.141 79.6802' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M128.959 75.7336L129.829 82.6668' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M112.506 66.9554C114.054 66.9554 115.309 65.7454 115.309 64.2529C115.309 62.7604 114.054 61.5504 112.506 61.5504C110.958 61.5504 109.703 62.7604 109.703 64.2529C109.703 65.7454 110.958 66.9554 112.506 66.9554Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M125.626 69.5538C127.174 69.5538 128.429 68.3438 128.429 66.8513C128.429 65.3587 127.174 64.1488 125.626 64.1488C124.078 64.1488 122.823 65.3587 122.823 66.8513C122.823 68.3438 124.078 69.5538 125.626 69.5538Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.994' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M131.246 98.9576C131.284 98.6473 131.69 98.7808 131.976 98.6026C132.556 98.2398 133.298 97.5795 133.676 97.0115' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M147.223 89.1823C147.565 89.2398 147.885 89.1979 148.224 89.222' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M147.376 90.3169C147.402 90.2729 147.813 90.373 147.865 90.3808' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M156.995 82.3802C156.975 82.3666 156.987 82.3702 156.959 82.3752' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M159.127 84.923C159.122 84.8094 159.618 84.5453 159.708 84.4828C160.238 84.1157 160.783 83.7479 161.251 83.3035' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M105.435 111.029C105.106 110.887 105.737 111.361 105.834 111.403C106.289 111.598 106.857 111.899 107.364 111.881' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M105.348 112.24C105.13 112.462 106.537 112.971 106.718 113.039' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M113.204 137.509C113.152 137.558 113.182 137.366 113.15 137.301C113.059 137.109 113.025 136.862 112.971 136.646C112.822 136.053 112.589 135.571 112.513 134.947' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M112.162 133.598C112.099 133.592 112.091 133.564 112.112 133.504' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M124.991 125.947C124.835 125.635 125.785 125.677 125.959 125.739' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M125.804 126.854C125.704 126.848 125.886 126.864 125.936 126.882' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M131.154 150.255C131.245 150.104 131.1 149.822 131.051 149.651C130.858 148.974 130.58 148.351 130.494 147.65' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M130.089 146.487C130.069 146.503 130.091 146.502 130.104 146.507' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M125.873 170.13C126.401 170.454 127.343 170.403 127.911 170.302C128.067 170.274 128.984 169.954 128.746 169.949' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M126.664 169.541C127.062 169.606 127.429 169.465 127.765 169.306' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M118.356 159.897C118.25 159.851 118.258 159.613 118.146 159.495' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M117.201 157.048C117.19 157.019 117.187 157.025 117.22 157.038' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M122.371 149.252C121.963 149.309 123.596 149.066 123.792 149.042' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M122.831 149.976C122.771 150.008 122.957 150.033 123.024 150.038C123.241 150.055 123.471 150.034 123.684 150.008' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M106.535 141.224C106.519 141.167 106.556 141.12 106.523 141.053' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M116.798 126.961C116.758 126.95 117.093 126.667 117.137 126.611' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M122.569 132.188C122.513 132.188 122.497 132.209 122.514 132.162' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M119.017 108.999C119.105 108.73 119.076 108.203 118.951 107.938' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M120.216 108.662C120.224 108.696 120.225 108.679 120.213 108.714' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M45.0077 105.157C44.9144 105.148 45.7042 105.399 45.7269 105.403C46.2932 105.511 46.7626 105.631 47.265 105.888' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M48.5693 106.305C48.6376 106.303 48.638 106.383 48.71 106.359' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M67.8564 105.21C68.0667 104.963 68.2278 104.588 68.317 104.273' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M68.7266 105.404C68.8035 105.219 68.933 105.11 69.0211 104.938' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.1699 107.826C76.1964 107.775 76.1807 107.791 76.2093 107.882' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M83.9917 113.408C83.8511 113.392 84.4262 113.477 84.5661 113.478' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M86.0268 113.941C86.0213 113.944 86.015 113.947 86.0088 113.949' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M83.3896 100.738C83.3925 100.689 83.6516 100.767 83.8199 100.792' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M52.6329 123.602C52.5398 123.615 53.1332 123.564 53.3402 123.497' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.4321 124.482C53.3227 124.554 53.5196 124.581 53.5596 124.587' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M39.4522 125.597C39.2294 125.284 39.0866 124.908 38.9619 124.521' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M38.1081 122.991C38.0529 122.982 38.0125 122.97 38.0344 123.037' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M50.0006 148.809C49.8964 148.875 50.691 148.643 50.9021 148.575' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M50.6778 149.615C50.5499 149.595 50.8762 149.476 50.9638 149.447' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M44.7781 86.6121C44.7137 86.6973 45.3377 86.2124 45.4074 86.1613' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M45.5736 87.5024C45.4927 87.506 45.6657 87.4605 45.7719 87.4264' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22.6003 98.1423C22.3047 98.263 23.3003 99.169 23.5161 99.279' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M23.6653 98.0056C23.5205 98.2357 24.1411 98.5537 24.2868 98.6283' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22.8649 89.6061C22.8191 89.5349 22.8529 89.5536 22.9167 89.5327' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M24.2156 90.0443C24.2101 90.1103 24.2059 90.0794 24.2244 90.1368' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M82.4708 87.8325C82.4147 87.9709 83.1609 87.6337 83.3079 87.5868' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M83.3154 88.785C83.3641 88.7757 83.4121 88.7571 83.4587 88.7421' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M81.9684 74.6266C82.0629 74.2389 81.7129 73.5076 81.5879 73.1193' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M81.2302 71.7478C81.2498 71.7794 81.2121 71.7481 81.1846 71.7341' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M61.2722 96.9417C61.2172 96.9957 61.2305 96.9432 61.2139 96.8914' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M62.0537 96.6064C62.0559 96.6101 62.0581 96.6145 62.0603 96.6181' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M59.0557 105.527C59.1358 105.277 59.2772 105.055 59.3491 104.802' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M60.354 105.18C60.3767 105.196 60.3693 105.186 60.3242 105.201' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M38.9082 99.2208C38.9028 99.2222 39.4331 99.497 39.6536 99.426' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M41.8705 99.8989C41.8674 99.9057 41.8643 99.9125 41.8613 99.9193' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M48.2744 94.6774C48.4259 94.9486 49.5617 94.7633 49.8811 94.9273' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M51.4531 95.3979C51.4753 95.4023 51.4604 95.3965 51.4744 95.4403' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M33.2529 102.848C33.3293 102.805 33.3101 102.809 33.3834 102.785' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M50.2952 114.403C50.2147 114.432 50.242 114.436 50.2644 114.506' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M59.8557 120.625C59.7374 121.026 60.2386 121.624 60.4545 121.918' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M60.6621 123.353C60.6735 123.419 60.685 123.471 60.705 123.533' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.3095 125.267C76.0049 125.424 76.756 125.312 76.8696 125.275' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.874 126.102C76.9629 126.178 77.0652 126.092 77.1832 126.11' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.0451 127.626C88.9493 128.128 89.3767 128.546 89.5222 128.997' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M150.958 128.076C150.713 128.069 151.109 127.926 151.195 127.926' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M151.072 129.568C151.034 129.545 151.049 129.579 151.049 129.603' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M157.026 146.059C156.958 146.084 157.778 145.524 158.052 145.341' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M159.458 144.331C159.421 144.366 159.445 144.355 159.383 144.355' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M171.514 127.309C171.329 127.197 172.102 126.65 172.213 126.594' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M172.095 128.128C172.02 128.117 172.263 127.95 172.311 127.926' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M168.995 104.63C168.922 104.557 168.707 104.377 168.707 104.244' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M166.692 94.121C166.556 94.1437 167.194 93.1632 167.278 92.9956' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M167.371 94.41C167.375 94.4554 167.492 94.1153 167.625 93.9825' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M169.461 77.1284C169.447 77.0596 169.413 77.0116 169.497 77.0116' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M147.841 111.837C147.836 111.753 147.844 111.847 147.888 111.892' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M133.306 159.702C133.191 159.959 134.314 160.043 134.47 160.043' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M136.029 160.482C136.065 160.49 136.018 160.487 135.999 160.487' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M164.064 71.0403C164.571 71.0466 164.911 70.7457 165.321 70.4725' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M166.959 69.3911C166.996 69.376 166.981 69.39 166.981 69.334' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M148.517 67.2634C148.257 67.2115 148.917 67.1522 149.052 67.1522' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M149.98 67.4144C150.069 67.4444 150.047 67.4494 150.047 67.5326' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M135.634 67.6024C135.606 67.2743 136.809 66.4902 137.099 66.3455' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M136.708 67.8238C136.779 67.7571 136.82 67.7187 136.909 67.674' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M99.6523 53.8367C99.7397 53.9763 100.121 53.4235 100.291 53.2542' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M100.413 54.6432C100.254 54.5686 100.429 54.4021 100.48 54.2504' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M88.8047 45.8057C89.203 45.8165 89.5814 46.4238 89.9499 46.4238' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M90.855 47.4611C90.8127 47.5063 90.8292 47.5149 90.7568 47.5149' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M120.087 74.2623C119.99 74.2666 119.881 74.29 119.881 74.4095' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M120.528 82.741C120.506 82.7531 120.497 82.0779 120.497 81.9508' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M88.4782 84.838C88.4804 84.7456 88.4316 84.8022 88.4316 84.7499' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M103.762 85.574C103.567 85.5122 103.422 84.1867 103.422 83.9438' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M103.335 81.9735C103.332 81.9662 103.329 81.9581 103.326 81.9508' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M151.157 61.9502C151.096 62.057 151.043 61.9967 151.043 62.1247' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M170.043 64.6577C170.004 64.6979 170.029 64.6863 169.968 64.6863' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M165.095 62.9263C164.962 62.9365 165.756 62.3062 165.985 62.0769' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M167.375 61.1902C167.35 61.1655 167.361 61.1831 167.361 61.1287' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M129.384 89.6088C129.338 89.4249 130.044 89.0068 130.173 88.8768' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M131.343 87.9528C131.368 87.9887 131.359 87.9664 131.359 88.0233' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M56.1376 75.0046C56.1027 75.0089 56.403 74.186 56.4605 74.0447' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M57.4867 75.111C57.423 75.164 57.4519 75.164 57.4894 75.2263' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M33.0077 87.1136C32.9383 87.1819 33.1546 87.393 33.208 87.4271' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M34.1377 88.7266C34.1847 88.7681 34.173 88.809 34.2342 88.7717' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M32.1387 75.8162C32.1064 75.748 32.485 76.1506 32.6315 76.2763C32.8921 76.4985 33.4644 76.8663 33.6375 77.1531' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M35.2976 78.4061C35.2569 78.4663 35.2813 78.4706 35.3158 78.5279' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M51.0795 49.4669C51.0323 49.4571 51.6962 48.9361 51.861 48.8367' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.0605 48.5677C53.0676 48.5711 53.0746 48.5745 53.0817 48.5779' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.6611 54.2608C53.6966 54.0729 54.041 53.7985 54.1754 53.6411' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.928 53.9099C54.8733 53.8574 55.1496 53.5588 55.2008 53.4988' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M79.3688 63.2183C79.2162 63.2647 79.5286 63.1023 79.5747 63.0744C79.8104 62.9324 80.0277 62.7431 80.2045 62.5359' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M80.2544 63.7975C80.2738 63.7449 80.2249 63.7967 80.1846 63.8211' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M73.7304 68.4144C73.7153 68.3577 73.701 68.3817 73.6172 68.3103' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M62.3769 85.9113C62.357 86.0256 62.2807 86.2485 62.3388 86.3451' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M70.0215 98.7156C70.2372 98.6453 70.6803 98.7767 70.9369 98.7561' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M48.4619 127.7C48.4631 127.692 48.4644 127.682 48.4656 127.673' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M101.198 73.0198C101.148 73.0879 101.071 73.0613 101.15 73.014' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M110.49 84.2966C110.355 84.3527 110.312 83.9012 110.273 83.8373' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M94.6496 89.1545C94.6846 89.1691 94.6496 89.1757 94.6299 89.1866' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M94.5847 87.5648C94.5811 87.501 94.536 87.496 94.5976 87.4587' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M143.564 130.953C143.42 130.978 143.486 130.973 143.363 130.973' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M137.856 138.325C137.932 138.213 137.953 138.24 138.08 138.24' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M103.107 151.628C103.32 151.569 103.452 151.466 103.651 151.367' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M102.402 149.448C102.305 149.478 102.331 149.494 102.331 149.386' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M84.1277 146.63C84.0212 146.707 83.6719 145.526 83.6719 145.34' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M79.3193 157.641C79.5487 157.962 80.3361 158.137 80.6989 158.137' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M72.5284 149.732C72.5205 149.689 72.5306 149.716 72.4424 149.716' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.6005 141.909C53.2113 141.696 53.2394 141.283 53.0391 140.882' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M40.833 114.538C41.0264 114.552 41.0484 114.317 41.1516 114.213' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M32.9165 110.178C32.8527 109.903 33.0789 110.72 33.1426 110.911' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M133.669 103.443C133.724 103.503 133.656 103.482 133.622 103.482' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M138.479 88.7151C138.533 88.3232 139.232 88.1279 139.483 87.8766' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M139.214 89.1326C139.127 89.106 139.221 89.1139 139.237 89.1139' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M152.881 79.6766C152.874 79.6853 152.866 79.6947 152.858 79.7034' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M117.278 116.841C117.152 116.863 117.212 116.835 117.107 116.939' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M119.98 102.587C120.021 102.558 119.999 102.581 119.999 102.49' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M93.6183 109.885C93.3648 109.596 94.3496 110.168 94.733 110.168' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M107.549 103.375C107.794 103.461 108.771 103.459 108.771 103.151' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M113.912 95.9584C113.863 95.9799 113.888 95.977 113.888 95.8853' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M103.581 58.7393C103.497 59.2907 104.334 59.4958 104.643 59.8048' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M81.8105 39.5225C82.02 39.3878 82.1932 39.6752 82.2678 39.8244' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M69.3224 45.01C69.1084 45.0427 69.6192 44.71 69.7165 44.6127' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M165.268 87.421C165.517 87.5985 165.821 88.3156 165.821 88.6188' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M161.846 107.616C161.898 106.86 161.873 108.219 162.022 108.517' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M163.093 112.109C163.094 112.095 163.096 112.081 163.097 112.068' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M148.264 105.217C148.206 105.212 148.244 105.215 148.15 105.215' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M139.988 120.239C140.199 120.481 140.64 121.244 140.64 121.562' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M140.6 120.216C140.661 120.386 140.668 120.436 140.804 120.571' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M148.671 154.159C148.861 154.208 149.649 154.504 149.969 154.504' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M150.42 152.942C150.655 152.915 150.967 152.943 151.125 153.101' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M114.424 167.274C114.638 167.418 115.203 167.385 115.457 167.385' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M95.0508 162.67C95.1161 162.309 95.1949 162.199 95.89 162.43' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M125.801 141.125C125.633 140.809 126.318 140.113 126.53 139.901' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M126.771 141.101C126.775 141.097 126.779 141.092 126.783 141.087' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M168.198 139.715C168.103 139.405 168.58 139.112 168.803 138.964' stroke='#001C20' strokeWidth='0.3834' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M91.4141 176.667L89.4055 171.431C89.4055 171.431 85.4008 170.712 80.6909 171.161C76.334 171.577 71.4852 173.077 68.537 174.111C65.59 175.145 61.228 177.364 57.8754 179.426C54.239 181.664 52.3098 184.121 52.3098 184.121L53.9098 188.823C53.9098 188.823 64.6501 181.305 70.795 179.314C77.0458 177.288 91.4141 176.667 91.4141 176.667Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.644246' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M71.0183 177.981C60.6937 181.488 53.0755 186.543 54.0024 189.272C54.9294 192.001 64.0505 191.37 74.3751 187.862C84.6996 184.355 92.3179 179.3 91.3909 176.571C90.464 173.842 81.3428 174.474 71.0183 177.981Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M71.7934 180.3C63.6327 183.072 57.482 186.688 58.0555 188.376C58.629 190.064 65.7095 189.186 73.8702 186.413C82.0309 183.641 88.1816 180.025 87.6081 178.337C87.0346 176.649 79.9541 177.528 71.7934 180.3Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.644246' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M75.8937 184.781L75.7488 184.4C75.7488 184.4 78.2097 183.223 78.1528 182.635C78.1283 182.381 77.8266 181.993 76.4562 182.139C74.9324 182.302 72.2894 183.138 71.7334 183.15C71.2964 183.159 71.5986 182.852 71.8565 182.632C72.1144 182.412 72.6497 182.101 73.1668 181.946C73.4922 181.848 74.2854 181.62 74.9927 181.458C76.0342 181.218 77.0091 181.047 77.0091 181.047L78.1233 179.936C78.1233 179.936 76.7371 180.175 76.1125 180.311C75.547 180.435 74.376 180.756 74.376 180.756L74.1785 180.334L71.4173 181.369L71.6034 181.782C71.6034 181.782 69.3889 182.809 68.7327 183.708C68.4317 184.12 68.6859 184.51 69.3747 184.497C70.3196 184.479 72.8883 183.813 74.1037 183.514C74.6323 183.384 75.5382 183.156 75.1654 183.658C74.8042 184.144 73.6155 184.455 73.0493 184.617C72.3806 184.809 70.7136 185.218 70.7136 185.218L69.8973 186.215C69.8973 186.215 70.9205 186.029 71.5267 185.857C72.0269 185.714 73.101 185.396 73.101 185.396L73.2555 185.747L75.8937 184.781Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.347303' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M88.9365 174.934L87.627 171.469' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M86.1602 174.748L84.8506 171.283' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M83.0117 174.94L81.6432 171.301' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M79.7588 175.5L78.2787 171.633' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.3242 176.172L74.8312 172.242' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M72.5938 177.281L71.1919 173.337' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.9707 186.514L53.6775 182.953' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M57.0458 184.617L55.7984 181.207' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M59.624 182.843L58.3069 179.214' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M62.4092 181.304L61.1863 177.673' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M65.5049 179.675L64.2635 176.033' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M68.8609 178.403L67.5052 174.606' stroke='#001C20' strokeWidth='0.64251' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M65.2706 186.245C65.2772 186.264 65.2839 186.284 65.2907 186.303' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M62.8158 187.079C62.8173 187.087 62.5549 187.209 62.5282 187.226' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M61.488 187.406C61.4761 187.411 61.4839 187.408 61.466 187.415' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M56.1586 188.649C56.1498 188.718 56.0332 188.464 56.0272 188.437C55.9956 188.291 56.0542 188.147 56.116 188.019' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M56.588 187.374C56.6018 187.378 56.5938 187.378 56.6121 187.371' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M63.5237 179.628C63.5447 179.633 63.5545 179.657 63.5465 179.634' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M62.989 178.942C62.9995 178.93 62.9967 178.938 62.99 178.918' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M66.8042 176.956C66.8293 177.085 66.677 177.255 66.6071 177.361' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M66.2915 176.844C66.2471 176.862 66.2252 176.953 66.2052 176.995' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M82.5053 180.258C82.5074 180.289 82.5259 180.309 82.4922 180.293' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M81.76 181.391C81.774 181.391 81.7573 181.394 81.7517 181.396' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.0315 177.736C89.0892 177.603 88.8909 177.439 88.7907 177.391' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M88.1126 177.025C88.1088 177.001 88.0985 177.019 88.0939 177.006' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M86.9327 173.03C86.9436 173.003 86.8096 173.003 86.7916 173.003' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M86.6064 172.422C86.5985 172.411 86.6022 172.418 86.5966 172.402' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M80.1852 173.178C80.193 173.185 80.1868 173.174 80.1853 173.169' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M85.4448 179.554C85.3773 179.591 85.3281 179.773 85.2322 179.836' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.0114 177.564C76.0637 177.392 75.5641 177.605 75.4806 177.634' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M74.7275 177.808C74.7054 177.804 74.6775 177.82 74.6567 177.828' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M67.4578 183.202C67.4622 183.231 67.4488 183.217 67.4277 183.212' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M68.9814 180.045C68.7069 180.066 68.2154 180.325 67.9895 180.475' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M67.0479 180.905C67.0239 180.905 67.0293 180.9 67.0029 180.918' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M84.207 176.181C84.1027 176.118 83.9135 176.183 83.8049 176.208C83.4418 176.293 83.0656 176.336 82.7124 176.432' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M82.1656 176.545C82.1641 176.558 82.1652 176.55 82.1614 176.569' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M70.5584 174.754C70.4662 174.919 70.0631 174.995 69.8913 175.042' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M70.8259 175.26C70.7186 175.358 70.5576 175.432 70.4114 175.472' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M57.5752 182.654C57.5993 182.658 57.5952 182.663 57.5867 182.639' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M55.29 183.584C55.2461 183.596 55.091 183.467 55.0244 183.45' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M57.3999 185.388C57.416 185.405 57.4097 185.395 57.4176 185.418' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M66.2901 189.34C66.2946 189.338 66.2811 189.343 66.2765 189.344' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M73.3917 187.47C73.3859 187.46 73.3922 187.475 73.3945 187.481' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M79.5915 181.811C79.6597 181.761 79.5282 182.274 79.5229 182.295' stroke='#001C20' strokeWidth='0.243112' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M9.63965 139.237L15.0391 145.231C15.0391 145.231 20.7874 144.129 26.8792 141.095C32.5146 138.288 38.2508 133.771 41.6755 130.861C45.099 127.952 49.8107 122.722 53.2475 118.222C56.9751 113.34 58.2975 109.046 58.2975 109.046L53.7222 103.558C53.7222 103.558 43.1814 119.195 35.9587 125.041C28.6113 130.987 9.63965 139.237 9.63965 139.237Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.92673' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M36.3462 126.944C48.3986 116.906 56.0183 106.187 53.3653 103.001C50.7123 99.816 38.7913 105.371 26.7388 115.409C14.6864 125.447 7.06669 136.167 9.71968 139.352C12.3727 142.538 24.2938 136.982 36.3462 126.944Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M34.1101 124.231C43.6366 116.297 50.0287 108.268 48.3873 106.297C46.7459 104.326 37.6926 109.16 28.1661 117.095C18.6397 125.029 12.2476 133.058 13.889 135.029C15.5304 137 24.5837 132.166 34.1101 124.231Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.92673' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M26.2932 120.332L26.684 120.768C26.684 120.768 23.9874 123.618 24.3674 124.379C24.5312 124.707 25.1369 125.073 26.9013 124.169C28.8633 123.164 31.9802 120.677 32.7207 120.374C33.3027 120.136 33.0556 120.705 32.823 121.134C32.5904 121.562 32.0319 122.255 31.4177 122.731C31.0313 123.03 30.0837 123.745 29.218 124.329C27.9432 125.188 26.7224 125.92 26.7224 125.92L25.8002 127.988C25.8002 127.988 27.5381 126.952 28.3061 126.446C29.0015 125.987 30.4081 124.952 30.4081 124.952L30.8911 125.417L34.0646 122.603L33.6015 122.144C33.6015 122.144 36.0446 119.622 36.4619 118.076C36.6533 117.367 36.1107 116.975 35.1927 117.348C33.9332 117.86 30.828 120.08 29.3504 121.109C28.7079 121.556 27.6089 122.33 27.8505 121.464C28.0846 120.624 29.5202 119.593 30.1966 119.083C30.9955 118.48 33.0225 117.071 33.0225 117.071L33.6039 115.311C33.6039 115.311 32.3262 116.089 31.6014 116.633C31.0034 117.083 29.7252 118.064 29.7252 118.064L29.3367 117.673L26.2932 120.332Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.499585' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.8604 140.283L17.4073 144.261' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.6904 139.102L21.2374 143.079' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.8105 137.218L25.5263 141.398' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M25.8945 134.786L29.8781 139.215' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M30.1543 132.112L34.1873 136.618' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M34.5947 128.699L38.5127 133.271' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M53.4887 107.205L57.0631 111.319' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M51.682 110.824L55.1168 114.758' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M49.1361 114.538L52.7777 118.731' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M46.1885 118.042L49.7043 122.285' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M42.875 121.826L46.4218 126.077' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M39.0252 125.266L42.8047 129.664' stroke='#001C20' strokeWidth='0.924232' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M39.7886 112.884C39.7699 112.861 39.7508 112.838 39.7318 112.816' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M42.6523 110.499C42.6462 110.489 42.9355 110.191 42.9624 110.154' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M44.2674 109.373C44.2804 109.36 44.2715 109.368 44.2917 109.351' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M50.7818 104.954C50.7577 104.856 51.0457 105.137 51.0673 105.17C51.1851 105.35 51.1809 105.574 51.1641 105.778' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M50.8618 106.889C50.8415 106.89 50.8523 106.887 50.8316 106.905' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M45.5461 120.869C45.5157 120.872 45.4911 120.846 45.513 120.872' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M46.6182 121.515C46.6105 121.534 46.6106 121.524 46.6282 121.545' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M42.5238 126.148C42.4239 125.989 42.5402 125.682 42.5798 125.505' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M43.2664 126.035C43.3165 125.988 43.2993 125.855 43.3046 125.789' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19.7394 129.82C19.7206 129.78 19.6853 129.762 19.7388 129.766' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.1531 127.912C20.1356 127.919 20.1548 127.906 20.1608 127.901' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.2736 136.575C12.2643 136.782 12.6156 136.901 12.7748 136.914' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.8748 137.056C13.8916 137.085 13.8959 137.056 13.9085 137.07' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.5198 141.81C17.5191 141.853 17.6986 141.784 17.7224 141.774' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.2744 142.458C18.2895 142.467 18.2818 142.461 18.2964 142.478' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M26.5031 138.13C26.4884 138.124 26.5031 138.137 26.5076 138.142' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.1509 132.282C16.2226 132.198 16.1945 131.928 16.2906 131.794' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M29.8471 130.084C29.8657 130.341 30.4268 129.798 30.5237 129.716' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M31.4453 129.095C31.4766 129.088 31.5056 129.052 31.5297 129.032' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M38.4211 118.1C38.4011 118.065 38.4256 118.077 38.4554 118.072' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M38.0019 123.127C38.3598 122.957 38.886 122.356 39.1116 122.037' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M40.1582 120.973C40.1901 120.961 40.1853 120.97 40.2115 120.933' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19.5557 136.171C19.7279 136.201 19.9487 136.017 20.0813 135.927C20.5251 135.626 21.008 135.374 21.4325 135.062' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22.1097 134.627C22.1052 134.61 22.108 134.621 22.1032 134.594' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M38.6178 131.044C38.6561 130.774 39.1582 130.464 39.3647 130.313' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M37.9958 130.501C38.0894 130.315 38.2671 130.132 38.4427 130.002' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M51.9668 113.737C51.9349 113.743 51.9378 113.735 51.9598 113.761' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.5586 111.309C54.611 111.269 54.8861 111.363 54.9842 111.351' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M50.7981 109.973C50.7681 109.959 50.7813 109.968 50.7592 109.942' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M36.8213 109.256C36.8164 109.26 36.831 109.248 36.8359 109.244' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28.2511 115.431C28.2631 115.441 28.2483 115.426 28.2423 115.418' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22.8492 126.23C22.7832 126.332 22.695 125.575 22.6912 125.544' stroke='#001C20' strokeWidth='0.349709' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <g clipPath='url(#clip1_3947_13889)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M109.817 33.415L128.762 21.272L150.961 13.557L131.928 25.5101L109.817 33.415Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M113.346 37.9341L123.487 60.6136L145.978 55.8705L134.653 31.5495L113.346 37.9341Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M134.478 31.4807L146.016 55.8513L162.442 41.7043L152.135 19.1158L134.478 31.4807Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M120.099 36.7246L130.189 59.1381L136.081 57.9279L125.541 35.1216L120.099 36.7246Z' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M142.874 26.6154L153.283 49.5051L157.72 45.7133L147.458 23.3391L142.874 26.6154Z' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M150.993 13.5985L131.763 25.5222L135.075 32.311L153.292 19.0836L150.993 13.5985Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M109.766 33.4708L131.766 25.5264L135.084 32.3103L112.27 38.9779L109.766 33.4708Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M120.424 36.5874L117.856 30.8689L123.245 28.6545L126.106 34.9181L120.424 36.5874Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M139.292 21.0313L142.099 27.2143L146.72 23.8559L144.123 18.0642L139.292 21.0313Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M131.06 22.4785C131.06 22.4785 138.384 22.32 142.379 22.2659C142.691 22.2611 143.879 22.1106 144.062 22.1301C147.115 22.4593 152.998 24.8418 152.998 24.8418L151.005 18.7085C151.005 18.7085 147.099 17.498 145.08 17.3578C142.867 17.2072 137.729 17.7971 137.729 17.7971L131.06 22.4785Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M126.627 21.4677C126.627 21.4677 120.088 25.4696 117.695 28.3738C115.107 31.5139 111.102 40.3051 111.102 40.3051L115.2 44.65C115.2 44.65 118.601 35.1544 120.877 31.6953C122.923 28.5879 128.861 23.8971 128.861 23.8971L126.627 21.4677Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M115.162 18.5502C115.162 18.5502 118.754 21.1204 120.136 22.003C121.055 22.5894 122.057 23.3827 122.057 23.3827L123.736 21.465C121.216 19.0343 118.299 18.3169 115.162 18.5502Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M146.555 6.99167C146.979 7.64392 147.217 10.0215 146.511 11.1499C145.315 13.0562 142.161 16.3871 138.606 18.8698C135.32 21.1627 131.451 22.0529 131.451 22.0529L136.489 15.5622C136.489 15.5622 139.173 13.811 140.247 12.5466C141.199 11.4226 141.57 10.3111 142.219 8.9551C142.492 8.38753 143.169 7.51757 143.977 7.23998C145.18 6.82714 146.134 6.34035 146.555 6.99167Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M127.32 21.06C126.352 20.9505 121.461 22.1767 117.418 23.9948C114.081 25.4952 112.462 25.0396 110.754 24.6267C109.224 24.2564 108.316 22.9379 109.106 21.1956C109.449 20.4371 111.251 19.3528 113.22 19.1591C115.693 18.9169 118.695 19.4789 120.506 20.2883C121.484 20.7271 123.722 21.7492 123.722 21.7492C123.722 21.7492 124.759 21.4694 125.11 21.3946C126.313 21.1355 127.045 21.0857 126.498 20.4647C125.951 19.8463 122.968 18.4915 120.516 17.7709C118.929 17.3043 116.286 16.5218 113.897 16.4523C111.981 16.3978 110.195 17.1855 109.185 17.5134C107.617 18.0212 106.901 20.7945 106.926 22.1795C106.952 23.5646 108.107 25.5976 109.453 26.072C111.646 26.8463 117.214 27.3159 120.27 26.5048C123.246 25.7135 129.024 21.2573 127.32 21.06Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M144.785 7.35325C144.519 7.44445 144.238 7.57383 143.95 7.73391C142.922 8.30766 141.783 9.27636 140.719 10.3505C136.963 14.1346 133.274 20.6964 131.412 22.0261C130.472 22.6962 128.557 20.8195 128.845 19.702C129.454 17.3435 131.372 13.1346 133.676 9.8807C135.881 6.7611 138.456 4.56769 139.911 3.5291C140.598 3.03901 141.918 2.63079 143.28 3.21393C145.098 3.99441 146.618 7.0934 146.618 7.0934C146.618 7.0934 146.062 7.08788 145.453 7.18888C145.23 7.22646 144.999 7.28014 144.785 7.35325Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M129.65 20.3887C129.885 19.2234 130.335 18.3458 131.022 17.6391' stroke='#001C20' strokeWidth='0.583106' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path fillRule='evenodd' clipRule='evenodd' d='M126.647 21.4107C126.413 21.0074 126.57 20.8019 126.847 20.4415C127.124 20.0793 127.798 19.4111 128.313 19.6514C129.057 19.9952 131.096 21.2189 131.339 22.0329C131.578 22.8356 130.004 24.0431 129.629 24.3258C129.406 24.4922 129.124 24.0685 128.913 23.8866C128.394 23.4331 127.015 22.038 126.647 21.4107Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='0.816349' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M130.825 21.2588C131.106 20.2428 131.694 19.7125 132.259 19.2828' stroke='#001C20' strokeWidth='0.583106' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M112.627 24.993C113.711 25.2023 117.903 25.1197 118.727 24.5911' stroke='#001C20' strokeWidth='0.583106' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.373 22.7634C124.56 22.4394 125.736 22.2105 126.908 22.0223' stroke='#001C20' strokeWidth='0.583106' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M107.896 22.0091C108.371 21.1755 109.197 20.6144 110.332 20.0736' stroke='#001C20' strokeWidth='0.583106' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M145.27 22.2698C144.099 21.9396 141.245 21.2936 140.191 21.3318' stroke='#001C20' strokeWidth='0.583106' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M144.548 41.8121C144.488 41.7791 144.776 42.1394 144.844 42.1923C145.096 42.387 145.371 42.5141 145.669 42.6185' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M145.563 38.3547C145.545 38.3419 145.49 38.3267 145.522 38.3652' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M150.597 38.2131C150.533 38.2272 150.784 38.3555 150.84 38.3949C151.026 38.5272 151.306 38.715 151.54 38.7315' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M150.911 38.932C150.937 39.0039 150.981 39.0199 151.052 39.0398' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M153.243 42.2897C153.251 42.2913 153.318 41.8884 153.321 41.8424' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M153.291 41.3522C153.293 41.3511 153.295 41.3498 153.296 41.3487' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M149.07 44.9332C149.058 44.9519 149.061 44.7286 149.073 44.6816' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M149.045 44.0455C149.044 44.0473 149.043 44.0492 149.041 44.0511' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M143.579 47.9679C143.561 47.9725 143.52 47.8128 143.5 47.7668C143.458 47.6683 143.362 47.5467 143.356 47.4391' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M143.149 46.9553C143.142 46.938 143.129 46.9316 143.147 46.9216' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M141.476 38.7233C141.308 38.6287 141.513 38.1245 141.565 38.0049' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M141.747 37.5882C141.748 37.573 141.75 37.5804 141.742 37.566' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M142.46 34.5843C142.441 34.5505 142.585 34.2561 142.612 34.2043' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M142.761 34.6718C142.732 34.679 142.81 34.4138 142.818 34.3958C142.846 34.3357 142.882 34.2809 142.919 34.2255' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M143.431 32.9834C143.439 32.9653 143.42 32.9676 143.44 32.9561' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M147.317 31.0225C147.307 31.0111 147.686 31.1244 147.743 31.1317' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M147.838 31.5318C147.839 31.5305 147.841 31.5291 147.842 31.5278' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M135.128 53.4445C135.13 53.4674 135.123 53.4772 135.104 53.4599C134.993 53.3625 134.941 52.9446 134.931 52.7956' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M134.739 52.3548C134.713 52.3393 134.713 52.3485 134.731 52.3282' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.868 51.1181C138.859 51.1431 139.445 51.2757 139.523 51.2963' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M139.116 50.9322C139.151 51.015 139.519 51.0108 139.606 51.0055' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M141.384 53.0989C141.379 53.1267 141.378 53.1178 141.381 53.1539' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M139.281 45.297C139.148 45.1775 139.133 44.9031 139.067 44.7419' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.921 44.3653C138.904 44.3529 138.888 44.3398 138.872 44.3256' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M133.629 41.5891C133.573 41.5257 133.792 41.6348 133.873 41.6564C134.097 41.7153 134.32 41.7059 134.551 41.6913' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M134.076 41.9761C133.965 41.921 134.323 42.068 134.443 42.036' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M134.808 39.027C134.813 39.0449 134.801 39.0296 134.796 39.0251' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M134.191 35.1816C134.179 35.1346 134.515 35.285 134.575 35.3384' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M135.03 35.826C135.016 35.8313 135.024 35.8268 135.009 35.8435' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M132.978 36.0833C132.973 36.0683 132.969 36.0822 132.962 36.0901' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M129.911 40.9923C129.876 41.0411 129.85 40.4723 129.844 40.3787' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M129.897 39.8837C129.882 39.8797 129.898 39.8898 129.904 39.895' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M132.527 46.9232C132.653 47.0061 132.725 47.021 132.859 46.9666' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M133.465 47.0708C133.465 47.0512 133.457 47.0676 133.452 47.0733' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M136.536 47.4997C136.489 47.4122 136.519 47.016 136.545 46.9151' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M136.959 47.0978C136.904 47.0964 136.912 46.9542 136.909 46.917' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M136.482 42.9782C136.476 43.0048 136.458 43.0189 136.441 43.0389' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M136.914 39.9025C136.879 39.8351 136.878 39.709 136.873 39.6339' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M137.246 56.7386C137.216 56.773 137.398 56.7777 137.43 56.7802C137.494 56.7853 138.247 56.6565 138.252 56.6502' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.714 56.6219C138.724 56.6201 138.717 56.6221 138.731 56.6062' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M143.818 55.285C143.837 55.2811 143.829 55.2929 143.83 55.3095' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M146.362 54.2718C146.273 54.3268 146.081 53.7367 146.075 53.6479' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M145.911 53.2899C145.91 53.287 145.909 53.2844 145.908 53.2815' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M134.211 56.2922C134.101 56.1878 133.91 55.7714 133.901 55.6286' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M133.685 55.1392C133.67 55.1346 133.679 55.1385 133.662 55.1239' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M133.823 56.4036C133.745 56.3857 133.743 56.2837 133.718 56.2235' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M128.88 49.8647C128.867 49.8506 129.106 50.0224 129.403 50.0035' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M129.072 50.4442C129.038 50.419 129.209 50.4968 129.315 50.49' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M128.964 48.2205C128.937 48.2038 128.936 48.2141 128.934 48.1763' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.452 39.9657C123.276 39.8894 123.808 40.0458 123.865 40.0962' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.565 39.5725C123.691 39.6334 123.775 39.6732 123.927 39.6636' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M125.886 41.6742C125.892 41.6888 125.892 41.6802 125.876 41.6978' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.646 47.1931C123.629 47.2156 123.5 46.8543 123.471 46.7828' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.292 46.4162C123.293 46.4123 123.293 46.4085 123.293 46.4047' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M126.9 56.6296C126.878 56.5091 126.802 56.0928 126.716 56.0178' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M126.563 55.5601C126.551 55.5619 126.557 55.5596 126.573 55.5742' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M122.678 54.6056C122.823 54.6338 122.98 54.6748 123.13 54.7146' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M122.771 54.2847C122.728 54.213 122.939 54.2835 123.022 54.2781' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M121.052 48.5129C121.028 48.4913 121.02 48.4915 121.018 48.4581' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.163 50.8331C123.249 50.926 123.545 50.921 123.668 50.9132' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.932 51.3211C123.928 51.3053 123.923 51.3197 123.916 51.3271' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M120.095 42.0062C120.075 41.9853 120.072 42.0073 120.049 41.9868' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M124.593 43.7988C124.57 43.8142 124.558 43.8251 124.541 43.8451' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M128.505 44.9714C128.448 44.8652 128.381 44.7215 128.306 44.5925' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M130.462 56.075C130.596 56.1101 130.609 56.1744 130.794 56.1626' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M130.776 56.509C130.795 56.5372 130.822 56.5456 130.842 56.5235' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M130.816 52.7615C130.86 52.8174 131.04 52.7175 131.119 52.7124' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M139.998 48.9097C139.998 48.9364 140.003 48.9248 139.986 48.9442' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M146.492 49.1876C146.568 49.077 146.549 48.903 146.584 48.7706' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M147.137 49.0518C147.14 49.0503 147.143 49.0488 147.146 49.0473' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M150.666 48.6418C150.664 48.6689 150.648 48.5845 150.646 48.5655C150.639 48.4529 150.658 48.3414 150.651 48.2274' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M149.251 49.9435C149.233 49.8939 149.251 49.8678 149.248 49.8232' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.54 32.4992C138.603 32.411 138.646 32.3013 138.689 32.2101' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.916 31.7296C138.92 31.7166 138.919 31.7244 138.913 31.7066' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M139.14 32.7775C139.141 32.7842 139.14 32.7787 139.124 32.7845' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M145.447 26.4651C145.426 26.4318 145.669 26.4953 145.714 26.4943C145.788 26.4922 145.863 26.4801 145.932 26.5126' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M146.287 26.6627C146.285 26.6628 146.282 26.6628 146.279 26.6626' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M147.821 28.5645C147.825 28.582 147.822 28.5728 147.829 28.5917' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M150.498 33.2446C150.48 33.1834 150.48 33.1512 150.509 33.0897' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M137.79 26.3107C137.757 26.3349 137.861 26.1466 137.881 26.1019C137.945 25.9645 138.036 25.8087 138.141 25.6977' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.158 26.4289C138.147 26.4946 138.274 26.2503 138.342 26.1958' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M143.214 23.8027C143.218 23.8013 143.207 23.8053 143.203 23.8068' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M132.242 31.2308C132.179 31.2616 132.271 31.2707 132.312 31.2076C132.408 31.0604 132.439 30.8843 132.512 30.7273' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M132.781 31.334C132.733 31.3412 132.786 31.2849 132.795 31.2758' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M153.698 27.4424C153.674 27.3627 153.736 27.5208 153.747 27.5442C153.831 27.7276 153.955 27.8974 154.038 28.0765' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M154.262 28.5443C154.268 28.5749 154.253 28.59 154.266 28.6178' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M153.837 33.1574C153.812 33.1237 153.925 33.2314 153.998 33.2682C154.136 33.3366 154.314 33.3686 154.46 33.4229' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M153.964 33.7143C153.906 33.6856 154.136 33.8069 154.213 33.8354' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M155.792 34.5249C155.772 34.5441 155.779 34.543 155.786 34.5588' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M158.096 37.7154C158.067 37.7427 158.062 37.7158 158.089 37.7032' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M158.291 37.1328C158.278 37.1299 158.286 37.1331 158.276 37.1116' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M159.326 40.8819C159.383 40.635 159.599 40.4071 159.607 40.1527' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M159.699 40.7015C159.749 40.6182 159.751 40.4962 159.804 40.43' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M157.877 41.3737C157.854 41.4041 157.863 41.3873 157.849 41.4243' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M155.388 43.7009C155.364 43.7191 155.342 43.7149 155.356 43.7453' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M151.119 26.9491C151.13 26.9306 151.112 26.9435 151.105 26.947' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M156.219 31.1968C156.148 31.2064 156.202 31.0886 156.227 31.0226' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M153.286 30.5038C153.246 30.5086 153.243 30.5123 153.273 30.4987' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M129.573 35.9267C129.527 35.9359 129.547 35.9302 129.515 35.9409' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M127.595 30.207C127.525 30.3666 127.69 29.8055 127.772 29.723' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M128.236 28.9102C128.214 28.9076 128.226 28.9068 128.201 28.915' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M128.4 32.1768C128.381 32.1848 128.393 32.1827 128.363 32.1678' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M122.001 33.6789C121.979 33.5537 122.162 33.2993 122.22 33.1826' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M122.421 33.9678C122.384 33.8459 122.506 33.6928 122.522 33.5709' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.569 34.5079C123.545 34.5302 123.553 34.5336 123.543 34.5055' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M115.517 38.8173C115.59 38.5831 115.796 38.2473 115.928 37.9814' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M116.18 37.4289C116.199 37.4496 116.201 37.442 116.174 37.4514' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M119.725 29.4942C119.714 29.5136 119.713 29.4945 119.71 29.4857' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M120.238 28.9137C120.24 28.9164 120.242 28.919 120.244 28.9217' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M116.165 33.4758C116.171 33.4549 116.138 33.4491 116.163 33.4409' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M117.577 34.3382C117.497 34.2257 117.817 34.2679 117.827 34.2645' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M117.619 34.9091C117.603 34.8777 117.678 34.8688 117.707 34.8587' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M112.815 34.2155C112.789 34.1379 112.766 34.1184 112.826 34.0576' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M134.387 25.8167C134.391 25.8314 134.379 25.8188 134.368 25.8134' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M134.98 25.1053C134.969 25.0628 135.097 24.9271 135.131 24.8931' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M132.027 24.0254C132.102 23.9632 132.17 23.9389 132.271 23.9046' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M132.531 23.6995C132.533 23.7026 132.529 23.6934 132.528 23.6904' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M135.993 29.2045C135.98 29.1769 135.99 29.1789 135.968 29.1866' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M145.136 22.9548C145.135 22.9546 145.205 23.111 145.22 23.1549' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M145.382 23.5499C145.382 23.5606 145.386 23.5624 145.372 23.5671' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M144.686 20.2211C144.714 20.2259 144.861 19.8865 144.908 19.8252' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M145.006 20.2528C144.992 20.2156 145.064 20.0936 145.081 20.0579' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M143.097 19.6423C143.033 19.6261 143.057 19.6051 143.098 19.5734' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M147.788 21.3921C147.782 21.4069 147.93 21.4531 148.296 21.5417' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M148.671 21.6694C148.688 21.6687 148.677 21.6799 148.69 21.6885' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M149.969 19.7091C149.965 19.6998 149.969 19.7037 149.952 19.7074' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M148.784 19.2628C148.731 19.2728 148.726 19.2804 148.686 19.2551' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M149.341 16.4783C149.304 16.4306 149.303 16.2687 149.282 16.1775' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M149.58 16.4201C149.564 16.348 149.566 16.2336 149.583 16.1628' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M146.853 18.2761C146.849 18.2687 146.852 18.2727 146.842 18.2664' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.593 20.784C138.593 20.7768 138.626 20.7961 138.639 20.795C138.696 20.7902 138.744 20.7761 138.795 20.7454C138.915 20.6723 138.984 20.5148 139.056 20.4003' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M139.274 21.0606C139.271 21.0697 139.282 21.0035 139.311 20.9681C139.362 20.9053 139.405 20.8537 139.45 20.7832' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M137.156 12.2652C137.167 12.1096 137.707 11.5866 137.869 11.4243' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.292 11.0206C138.3 11.0021 138.281 10.9821 138.312 10.9777' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M133.189 13.1951C133.168 13.1599 133.311 13.3673 133.384 13.4218' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M133.409 12.9731C133.406 12.9321 133.472 13.03 133.511 13.043' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M135.094 14.0462C135.077 14.0456 135.05 14.0555 135.047 14.0374' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M139.055 16.135C139.018 16.1279 139.168 16.0505 139.216 16.0172C139.332 15.9365 139.44 15.8495 139.538 15.7457' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M139.897 15.4579C139.895 15.4578 139.893 15.4579 139.892 15.4578' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M144.623 10.2333C144.607 10.0877 144.765 10.404 144.773 10.4103' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M144.529 10.7236C144.522 10.716 144.563 10.8362 144.58 10.9564' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M141.085 7.1352C141.016 7.13721 141.43 6.87883 141.456 6.84364' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M141.89 6.47182C141.875 6.45859 141.855 6.46108 141.874 6.45832' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.5 6.66064C138.605 6.7352 138.684 6.73785 138.8 6.77635' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M138.318 7.03205C138.318 6.9786 138.421 7.02321 138.445 7.04146' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M142.382 3.96907C142.355 3.96888 142.36 3.97624 142.358 3.9572' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M133.23 18.1626C133.213 18.1334 133.213 18.1297 133.25 18.1244' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M135.286 18.869C135.261 18.8033 135.333 18.9033 135.344 18.916C135.402 18.981 135.406 19.1128 135.418 19.1981' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M135.548 18.7403C135.584 18.6783 135.641 18.935 135.647 18.9741' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M128.592 21.9108C128.672 21.9911 128.811 21.5892 128.822 21.5547' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M128.903 22.0077C128.883 22.0566 128.969 21.9248 129.006 21.8875' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M143.029 6.93494C143.013 6.91786 143.012 6.9231 143.03 6.89903' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M137.477 9.23755C137.473 9.21292 137.464 9.19408 137.479 9.17362' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M141.172 12.3923C141.258 12.3743 141.372 12.2286 141.43 12.1518' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M142.593 13.5757C142.612 13.6364 142.727 13.6215 142.774 13.6368' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M142.59 13.9648C142.629 13.9915 142.669 14.0197 142.716 14.0281' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M144.9 16.2762C144.882 16.2846 144.891 16.2823 144.874 16.2847' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.838 26.3676C123.832 26.3601 123.94 26.2652 123.947 26.2587C124.01 26.2014 124.049 26.1263 124.107 26.0664' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M124.567 25.6164C124.553 25.6244 124.556 25.6158 124.552 25.6069' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M120.696 23.9959C120.632 24.0101 120.828 23.9856 120.892 23.9718C121.008 23.9467 121.095 23.8702 121.206 23.8314' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M121.006 24.2778C121.036 24.2686 121.066 24.2578 121.095 24.2474' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M122.642 23.8071C122.635 23.8175 122.64 23.8124 122.623 23.8183' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M122.59 19.7505C122.513 19.8224 122.981 19.9048 123.021 19.9099' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M123.382 20.1493C123.387 20.172 123.378 20.1877 123.385 20.2075' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M112.591 18.1008C112.682 18.024 112.757 17.8111 112.787 17.6905' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M112.928 18.0835C112.916 18.144 113.013 17.9355 113.067 17.8936' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M109.691 18.9953C109.664 18.9952 109.673 18.9999 109.684 18.9759' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M108.367 23.7871C108.327 23.7745 108.555 24.1453 108.607 24.1955' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M110.884 25.6816C110.849 25.7492 110.924 25.5823 110.913 25.5499' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M111.27 25.7493C111.245 25.7493 111.281 25.6681 111.293 25.6439' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M115.347 30.5866C115.413 30.5934 115.488 30.5276 115.538 30.4945' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M118.821 18.295C118.835 18.2989 118.821 18.2858 118.816 18.2684' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M118.638 25.6683C118.701 25.7663 118.944 25.7147 119.026 25.6857' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M119.171 25.965C119.211 25.9594 119.255 25.9383 119.293 25.9249' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M124.794 22.8813C124.796 22.906 125.073 22.7663 125.095 22.7539' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M127.061 23.7135C127.045 23.7212 127.027 23.742 127.058 23.7311' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M127.354 23.8327C127.354 23.8349 127.353 23.8373 127.353 23.8396' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M112.669 39.7617C112.552 39.716 112.741 39.4685 112.863 39.4389' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M113.061 40.2135C113.08 40.1376 113.115 40.08 113.158 40.0085' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M117.484 43.8531C117.316 43.9491 117.966 44.2005 118.065 44.2087' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M155.053 45.4714C155.006 45.5097 154.986 45.4817 154.982 45.4254' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M140.835 27.051C140.754 26.7704 140.779 26.4681 140.642 26.1723' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M140.499 26.9979C140.461 26.8968 140.485 26.7779 140.441 26.6827' stroke='#001C20' strokeWidth='0.122126' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_3947_13889'>
        <rect width='200' height='200' fill='white' />
      </clipPath>
      <clipPath id='clip1_3947_13889'>
        <rect width='63.3244' height='63.3244' fill='white' transform='translate(93.3984 18.3176) rotate(-24.7556)' />
      </clipPath>
    </defs>
  </SvgIcon>
);
