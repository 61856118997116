import styled, { css } from 'styled-components';
import { FlexCenter, FlexCol, FlexColCenter, FlexHorizontalCenter } from '../../../styles/styles';
import { TextField } from '../../TextField';
import { NewModal } from '../NewModal';
import { TextLGDemiBold, TextMD, TextMDMedium, TextMDDemiBold, TextSM, TextSMMedium, TextSMDemiBold, TextXSBoldLight, TextXSDemiBold, TextXSLight } from '../../../styles/components/text';

const BaseMode = css`
  ${ TextXSDemiBold }

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    ${TextMDDemiBold}
  }
`;

export const FormContainer = styled.div`
  ${FlexColCenter}
  width: 100%;
  padding: 10px 20px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    padding: 40px 20px 20px;
  }

  & > h1,
  & > h3,
  & > h5,
  & > p {
    text-align: center;
  }

  & > h1 {
    margin: 15px 0;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & > h3 {
    color: ${({ theme }) => theme.colors.grey2};
    margin-bottom: 40px;
  }

  & > p {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 20px;

    &.legal {
      ${TextXSBoldLight}
      color: ${({ theme }) => theme.colors.grey3};
      line-height: 1.7em;
      margin: 0 auto 20px;
      max-width: 260px;
      text-align: center;
      
      &.white {
        color: ${({ theme }) => theme.colors.white};
      }

      a {
        color: ${({ theme }) => theme.colors.grey3};
        font-size: 12px;
        
        &.white {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }

  .get-updates-container {
    margin: 30px 0 20px;
  }

  .ctas-container {
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    display: flex;

    &.check-score, &.sign-in {
      margin-bottom: 16px;
    }


    button {
      flex-grow: 2;
    }
  }

  .promo-legal {
    font-size: 10px;
    line-height: 14px;
  }

  .bottom-action {
    display: block;
    margin: 10px auto;
  }

  .field-error {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.negative};
    text-align: left;
  }

  .field-success {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: 575px;
    margin: 0 auto;

    .field-error, .field-success {
      ${TextMDMedium}
    }
  }
`;

export const MembershipFormContainer = styled(FormContainer)`
  justify-content: flex-start;
  
  h1 {
    margin: 0 auto 8px;
  }

  .karma-score-header {
    max-width: 320px;
  }

  .membership-header {
    max-width: 300px;
  }

  .body-text {
    ${TextSMDemiBold}
    color: ${({ theme }) => theme.colors.white};
  }

  .yellow-text {
    ${TextMDDemiBold}
    border: 1px solid ${({ theme }) => theme.colors.gold};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.gold};
    font-size: 15px;
    margin: 0 0 20px;
    padding: 12px 21px;
  }

  .sign-up-text {
    ${TextSMMedium}
    margin-bottom: 12px;
  }

  .disclaimer-text {
    ${TextXSLight}
    color: ${({ theme }) => theme.colors.grey4};
    margin-bottom: 30px;
  }

  .have-account-cta {
    ${TextSMDemiBold}
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
    text-decoration: underline;
  }

  .become-a-member-cta {
    ${FlexCenter}
    ${TextSMMedium}
    margin-bottom: 24px;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      ${TextMDMedium}
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .yellow-text {
      ${TextLGDemiBold}
      margin: 12px 0 32px;
      padding: 12px 57px;
    }

    .karma-score-header {
      max-width: 400px;
    }

    .membership-header {
      max-width: 380px;
    }
  }
`;

export const FormField = styled(TextField)`
  &.error {
    input.pill-text-field,
    input.pill-text-field:focus:not(:disabled) { // required to over-specify focus state of default text input
      border: 2px solid ${({ theme }) => theme.colors.red};
      box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.red};
    }
  }

  &.email-field {
    margin-bottom: 6px;

    .input-container.small input {
      ${TextSM}
      
      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        ${TextMD}
      }
    }
  }

  input.pill-text-field:focus:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 1px inset ${({ theme }) => theme.colors.primary};
  }

  .toggle-password-visibility {
    svg {
      min-width: 28px;
      min-width: 28px;
      min-height: unset;
      height: auto;
      max-height: unset;
    }
  }
`;

export const ModesContainer = styled.div`
  ${ FlexHorizontalCenter }
  justify-content: space-between;
  padding: 5px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 999px;
  margin: 40px 0 22px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    margin: 40px 0 35px;
  }
`;

export const PasswordRule = styled.div`
  ${ FlexHorizontalCenter }

  &.valid {
    span {
      color: ${({ theme }) => theme.colors.darkGray1};
    }
  }

  &.invalid {
    span {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  span {
    &:first-child {
      ${ FlexCenter }
      margin-right: 10px;

      svg {
        width: 12px;
        height: auto;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    span:first-child svg {
      width: 15px;
    }
  }
`;

export const PasswordRulesContainer = styled.div`
  margin-top: 20px;
  padding-left: 15px;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const SelectedMode = styled.div`
  ${ BaseMode }
  background: ${({ theme }) => theme.colors.gold};
  border-radius: 999px;
  color: ${({ theme }) => theme.colors.darkGray1};
  padding: 8px;
  text-align: center;
  width: 50%;
`;

export const KarmaScoreHeader = styled.p`
  ${TextLGDemiBold}
  color: ${({ theme }) => theme.colors.tertiary} !important;
  font-size: 24px;
  margin: 10px auto 2px !important;

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    font-size: 20px;
    margin: 15px auto 5px !important;
  }
`;

export const SignInModalContainer = styled(NewModal)`
  background: ${({ theme }) => theme.colors.dark};
  overflow: hidden;
  padding: 0px;

  .modal-body {
    padding-top: 0px;

    .custom-modal-body {
      padding: 0;
    }
  }

  .x-icon {
    z-index: 2;
  }

  .modal-close-button {
    svg {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    &.create-account {
      stroke: ${({ theme }) => theme.colors.darkGray1};
    }

    .modal-close-button {
      right: 20px;
      top: 16px;
    }
  }
`;

export const SignInModalInner = styled.div`
  ${FlexCol}
  width: calc(100vw - 40px);
  height: auto;
  max-width: 960px;
  overflow: auto;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    flex-direction: row;
  }

  @media (${({ theme }) => theme.breakpoints.smMax}) {
    max-width: 500px;
  }

  &.create-account {
    @media (${({ theme }) => theme.breakpoints.xsMax}) {
      max-height: calc(98vh - 72px);
      overflow-x: hidden;
    }
  }

  .forgot-password-cta {
    ${TextSMDemiBold}
    color: ${({ theme }) => theme.colors.grey4};
    margin-bottom: 20px;
    text-align: center;
  }

  .create-account-cta, .new-to-karma {
    ${TextSMDemiBold}
    color: ${({ theme }) => theme.colors.grey3};
    display: inline-block;
  }

  .create-account-cta {
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 5px;
    text-decoration: underline;
  }
`;

export const UnselectedMode = styled.button`
  ${ BaseMode }
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.darkGray1};
  outline: none;
  width: 50%;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: ${({ theme }) => `1px solid ${theme.colors.darkGray1}`};
    outline-offset: 1px;
  }
`;

export const JoinGroupContainer = styled.div``;

export const BodyText = styled.p`
  margin: 0;
  line-height: 1.4em;
`;

export const DisclaimerText = styled(BodyText)`
  margin-top: 20px;
  font-size: 12px;
`;
