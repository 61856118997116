import React, { useCallback } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { CompanyModel } from '../../models/companies';
import { H2 } from '../../styles/components/header';
import { ButtonKind } from '../Button/styles';
import { CompanyLogo } from '../CompanyLogo';
import { ChildCompany, ChildrenCompaniesSectionContainer, CompaniesContainer } from './styles';

interface IProps {
  className?: string;
  company: CompanyModel;
}

export const ChildrenCompaniesSection: React.FC<IProps> = ({
  className = '',
  company,
}) => {
  const analytics = useAnalytics();

  const onClick = useCallback(() => {
    analytics.fireEvent('Company_ChildCompany_Click');
  }, []);

  const renderChildrenCompanies = useCallback(() => company.companiesOwned.filter(c => !c.hidden.status).map((c) => (
    <ChildCompany
      key={ `child-company-${c._id}` }
      kind={ ButtonKind.Blank }
      href={ `/company/${c._id}/${c.slug}` }
      onClick={ onClick }
    >
      <CompanyLogo company={ c } className='logo-wrapper' />
      <div className='company-name'>{ c.companyName }</div>
    </ChildCompany>
  )), [company]);

  return (
    <ChildrenCompaniesSectionContainer className={ className }>
      <H2>Companies owned by { company.companyName }</H2>
      <CompaniesContainer>
        { renderChildrenCompanies() }
      </CompaniesContainer>
    </ChildrenCompaniesSectionContainer>
  );
};
