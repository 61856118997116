import React from 'react';
import { H2, H5 } from '../../../styles/components/header';
import { GraphicContainer, HowItWorksInnerSectionContainer, HowItWorksSectionContainer, StepContainer, StepNumber, StepsContainer, StepsInnerContainer, TextContainer } from './styles';
import { Stats } from '../../../components/svgs/graphics/Stats';
import { ShoppingBasketWithCoins } from '../../../components/svgs/graphics/ShoppingBasketWithCoins';
import { PhoneWithLoupe } from '../../../components/svgs/graphics/PhoneWithLoupe';
import { HandWithBill } from '../../../components/svgs/graphics/HandWithBill';
import VerticalDottedLine from '../../../../public/assets/images/vertical-dotted-line.png';
import MobileVerticalDottedLine from '../../../../public/assets/images/mobile-vertical-dotted-line.png';
import { useAppData } from '../../../contexts/app';

interface IProps {
  className?: string;
}

interface IStep {
  step: string;
  header: string;
  primaryDescription: string;
  secondaryDescription: string;
  icon: JSX.Element;
}

const steps: IStep[] = [
  {
    step: 'Step 1',
    header: 'Join For Free',
    primaryDescription: 'Fill out our intake form and create a cashback campaign with our team.',
    secondaryDescription: 'We\'ll also send you an Impact Assessment to learn more about your company!',
    icon: <Stats />,
  },
  {
    step: 'Step 2',
    header: 'Acquire Your Ideal Customers',
    primaryDescription: 'Give automatic cashback when people buy your products. It costs 3X less to acquire customers with Karma Wallet.',
    secondaryDescription: 'Use values-based targeting to discover your ideal customers.',
    icon: <ShoppingBasketWithCoins />,
  },
  {
    step: 'Step 3',
    header: 'Insights & Analytics',
    primaryDescription: 'Get monthly consumer insights based on purchasing and in-app behavior.',
    secondaryDescription: 'Get quarterly reports on how your sustainability practices stack up against your competition.',
    icon: <PhoneWithLoupe />,
  },
  {
    step: 'Step 4',
    header: 'Increase Loyalty',
    primaryDescription: 'Through cashback rewards, targeted marketing, and values-aligned ads, the Karma Collective helps you find new customers - then bring them back again and again.',
    secondaryDescription: 'Our members have a 2.8X higher LTV for sustainable brands.',
    icon: <HandWithBill />,
  },
];

export const HowItWorksSection: React.FC<IProps> = ({
  className = '',
}) => {
  const appData = useAppData();

  const stepsContent = steps.map(({ step, header, primaryDescription, secondaryDescription, icon }, index) => (
    <StepContainer key={ step } className={ `step-${index}` }>
      <TextContainer>
        <StepNumber>{ step }</StepNumber>
        <H5>{ header }</H5>
        <p>{ primaryDescription }</p>
        <p>{ secondaryDescription }</p>
      </TextContainer>
      <GraphicContainer>{ icon }</GraphicContainer>
    </StepContainer>
  ));

  return (
    <HowItWorksSectionContainer className={ className }>
      <HowItWorksInnerSectionContainer>
        <H2>How It Works</H2>
        <StepsContainer>
          {
            appData.windowWidth > 767 ?
              <img aria-hidden='true' alt='Dotted Line' className='dotted-line' src={ VerticalDottedLine } />
              :
              <img aria-hidden='true' alt='Dotted Line' className='mobile-dotted-line' src={ MobileVerticalDottedLine } />
          }
          <StepsInnerContainer>
            { stepsContent }
          </StepsInnerContainer>
        </StepsContainer>
      </HowItWorksInnerSectionContainer>
    </HowItWorksSectionContainer>
  );
};
