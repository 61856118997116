import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';

type PrivateFields = '_transparent' | '_toSolidScrollPosition' | '_makeSolid' | '_initialized' |'_headerHeight' | '_subMenuSelected';

export interface IHeaderNavSettings {
  transparent: boolean;
  toSolidScrollPosition?: number;
}

export enum SubMenuSelected {
  None = 'none',
  ExploreData = 'explore-data',
  Marketplace = 'marketplace',
  Partnerships = 'partnerships',
}

export class HeaderModel extends BaseModel {
  private _transparent = false;
  private _toSolidScrollPosition = 0;
  private _makeSolid = false;
  private _initialized = false;
  private _headerHeight = 0;
  private _subMenuSelected = SubMenuSelected.None;

  constructor () {
    super();

    makeObservable<HeaderModel, PrivateFields>(this, {
      _transparent: observable,
      _toSolidScrollPosition: observable,
      _makeSolid: observable,
      _initialized: observable,
      _headerHeight: observable,
      _subMenuSelected: observable,
      makeSolid: computed,
      transparent: computed,
      toSolidScrollPosition: computed,
      setHeaderHeight: action.bound,
      setTransparent: action.bound,
      setMakeSolid: action.bound,
    });
  }

  get makeSolid () { return this._makeSolid; }
  get transparent () { return this._transparent; }
  get toSolidScrollPosition () { return this._toSolidScrollPosition; }
  get initialized () { return this._initialized; }
  get headerHeight () { return this._headerHeight; }
  get subMenuSelected () { return this._subMenuSelected; }

  setSubMenuSelected = (subMenuSelected: SubMenuSelected) => {
    runInAction(() => this._subMenuSelected = subMenuSelected);
  };

  setHeaderHeight = (height: number) => {
    runInAction(() => this._headerHeight = height);
  };

  setTransparent = (isTransparent: boolean) => {
    runInAction(() => {
      this._transparent = isTransparent;
      this._initialized = true;
    });
  };

  setMakeSolid = (makeSolid: boolean) => {
    runInAction(() => this._makeSolid = makeSolid);
  };
}
