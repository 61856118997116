import React, { useMemo } from 'react';
import { BenefitsSectionContainer, BenefitsSectionInnerContainer, DisclaimerWrapper, HeaderContainer, ImpactMetricsContainer } from './styles';
import { theme } from '../../../styles/themes';
import { BenefitCard } from '../../../components/BenefitCard';
import { BenefitCardVariant } from '../../../components/BenefitCard/styles';
import { MembershipBenefits } from '../../../components/MembershipBenefits';
import { useKarmaCardLegal } from '../../../contexts/karma-card-legal';
import { observer } from 'mobx-react';
import { H1M, H1MInverted } from '../../../styles/components/header';
import { Underline } from '../../../components/svgs/shapes/Undeline';
import { useDoneGoodVersion } from '../../../contexts/doneGoodVersion';

interface IProps {
  className?: string;
}

export const BenefitsSectionBase: React.FC<IProps> = ({
  className,
}) => {
  const karmaCardLegalContext = useKarmaCardLegal();
  const { isDoneGood } = useDoneGoodVersion();
  
  const benefitCardVariant = isDoneGood ? BenefitCardVariant.Orange : BenefitCardVariant.Blue;

  const legalDisclaimer = useMemo(() => {
    if (!karmaCardLegalContext?.issuerStatement) return null;

    return (
      <DisclaimerWrapper>
        <p>Data last updated 7/29/2024.</p>
        <p>{ karmaCardLegalContext.issuerStatement }</p>
      </DisclaimerWrapper>
    );
  }, [karmaCardLegalContext.issuerStatement]);

  const header = (
    <HeaderContainer>
      <div className='text-section'>
        <div className='text-wrapper' role='heading' arial-level={ 2 }>
          <H1MInverted className='inverted-text' role='presentation'>Good for your wallet.</H1MInverted>
          <span className='underlined-text-wrapper'>
            <H1M className='regular-text' role='presentation'>
              Good for the
              <span className='underlined-word-wrapper'>
                <H1M className='regular-text underlined-word' role='presentation'> planet.</H1M>
                <Underline stroke={ isDoneGood ? theme.colors.orange1 : theme.colors.tertiary } id='form-text-underline' />
              </span>
            </H1M>
          </span>
        </div>
        <p className='cashback-text'>Earn up to $300 cashback* a year and automatically donate to nonprofits for everyday spending with the Karma Wallet Card.</p>
      </div>
      <div>
        <img src='https://cdn.karmawallet.io/uploads/-Pv-Ny2TGh-holding-card-photo.png' alt='Person holding the Karma Wallet Card' />
      </div>
    </HeaderContainer>
  );
  
  const impactMetrics = (
    <ImpactMetricsContainer>
      <BenefitCard
        className='card'
        variant={ benefitCardVariant }
        firstLine='Dollars Towards Change'
        title='$265,449,728'
      />
      <BenefitCard
        className='card'
        variant={ benefitCardVariant }
        firstLine='Purchases'
        title='2,210,874'
      />
    </ImpactMetricsContainer>
  );

  return (
    <BenefitsSectionContainer className={ className } backgroundColor={ theme.colors.dark }>
      <BenefitsSectionInnerContainer>
        { header }
        <MembershipBenefits location='Apply' />
        { impactMetrics }
        { legalDisclaimer }
      </BenefitsSectionInnerContainer>
    </BenefitsSectionContainer>
  );
};

export const BenefitsSection = observer(BenefitsSectionBase);
