import styled from 'styled-components';

export const PaymentPageContainer = styled.div``;

export const ResultInfoSection = styled.div`
  text-align: center;
  max-width: 500px;

  .email-text {
    font-weight: 600;
  }

  .download-app-text {
    font-weight: 600;
  }
`;
