import { animated } from 'react-spring';
import styled from 'styled-components';
import { ToastType } from '../../models/toaster';
import { BasicContainer, FlexHorizontalCenter } from '../../styles/styles';

export interface IToasterProps {
  type?: ToastType;
}

export const ToasterContainer = styled(animated.div)<IToasterProps>`
  ${ BasicContainer }
  ${ FlexHorizontalCenter }
  align-items: stretch;
  position: fixed;
  top: 30px;
  left: 50%;
  max-width: 300px;
  padding: 10px 20px;
  z-index: 99999;

  ${({ theme, type }) => {
    switch (type) {
      case ToastType.Error:
        return `
          border: 1px solid ${theme.colors.danger};
          background: ${theme.colors.lightDanger};
          box-shadow: 0px 2px 0px ${theme.colors.darkDanger};
        `;
      case ToastType.Warning:
        return `
          border: 1px solid ${theme.colors.darkYellow1};
          background: ${theme.colors.lightYellow1};
          box-shadow: 0px 2px 0px ${theme.colors.darkYellow1};
        `;
      default:
        return `
          border: 1px solid ${theme.colors.darkGreen1};
          background: ${theme.colors.lightGreen1};
          box-shadow: 0px 2px 0px ${theme.colors.darkGreen1};
        `;
    }
  }}
`;
