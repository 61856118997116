import { observer } from 'mobx-react-lite';
import React, { createContext, useRef } from 'react';
import { SignInModal } from '../components/modals/SignInModal';
import { SignInModel } from '../models/sign-in';

export const SignInContext = createContext<SignInModel>(null);

export const useSignInModal = () => React.useContext(SignInContext);

const SignInStoreBase: React.FC<React.PropsWithChildren> = ({ children }) => {
  const signIn = useRef(new SignInModel()).current;
  return (
    <SignInContext.Provider value={ signIn }>
      { children }
      <SignInModal />
    </SignInContext.Provider>
  );
};

export const SignInStore = observer(SignInStoreBase);
