import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../styles/styles';
import { TextSMMedium, TextXSBoldLight } from '../../styles/components/text';

export const MembershipBenefitsContainer = styled.div`
  width: 100%;
`;

export const InnerWrapper = styled.div`
  ${FlexCol}
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px 24px;

  p {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: unset;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
  }
`;

export const BenefitSectionContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 12px;
  width: 100%;

  .header {
    font-size: 20px;
    font-weight: 700;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: calc(50% - 12px);
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    max-width: calc(25% - 24px);
  }
`;

export const ErrorMessageContainer = styled.div`
  ${FlexCol}
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;

export const BenefitCardsContainer = styled.div`
  ${FlexCol}
  gap: 10px;
  width: 100%;

  .tooltip-text {
    ${TextXSBoldLight}
    color: ${({ theme }) => theme.colors.white};
    max-width: 230px;

    a {
      ${TextXSBoldLight}
      color: ${({ theme }) => theme.colors.secondary};
      display: inline-block;
      text-decoration: none;
    }
  }

  .tooltip {
    display: block;

    :hover {
      cursor: pointer;
    }
  }

  .learn-more {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey3};
    text-decoration: underline;
  }

  .done-good-icon {
    height: 14px;
    position: relative;
    top: 2px;
    width: 14px;
  }

  .cashback-disclaimer {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey3};
    font-style: italic;
  }

  .karma-card {
    width: 48px;
    margin: 0;
  }

  .mobile-app {
    height: 80px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    gap: 24px;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  height: 60px;
  position: relative;
`;
