import styled from 'styled-components';
import { CompanyRating } from '../../models/companies';
import { FlexCenter } from '../../styles/styles';

export interface ICompanyRatingIndicatorProps {
  backgroundColor: string;
}

export const CompanyRatingIndicatorContainer = styled.div<ICompanyRatingIndicatorProps>`
  ${FlexCenter}
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
    
    &.${CompanyRating.Positive} {
      fill: ${({ theme }) => theme.colors.green};
    }

    &.${CompanyRating.Neutral} {
      fill: ${({ theme }) => theme.colors.darkYellow1};
    }

    &.${CompanyRating.Negative} {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;
