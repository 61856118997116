
import { WebServiceHelper } from '../lib/webServiceHelper';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { Categories } from './unsdgs';

type PrivateFields = '_values' |
'_busy';

type PrivateValueFields = '_busy' |
'_value';

export interface IValueCategory {
  name: Categories;
  index: number;
  createdOn: Date;
}

export interface IValue {
  category: IValueCategory;
  name: string;
  weight: number;
  _id: string;
  icon: string;
}

export class ValueModel extends BaseModel {
  private _value: IValue | null = null;
  private _busy = false;

  constructor (valueInfo: IValue) {
    super();
    makeObservable<ValueModel, PrivateValueFields>(this, {
      _value: observable,
      _busy: observable,
      value: computed,
      name: computed,
      weight: computed,
      category: computed,
      _id: computed,
      icon: computed,
    });

    this._value = valueInfo;
  }

  get value() { return this._value; }
  get name() { return this._value?.name; }
  get weight() { return this._value?.weight; }
  get category() { return this._value?.category; }
  get busy() { return this._busy; }
  get icon() { return this._value?.icon; }
  get _id() { return this._value?._id; }
}

export class ValuesModel extends BaseModel {
  private _values: IValue[] = [];
  private _busy = false;

  constructor () {
    super();
    makeObservable<ValuesModel, PrivateFields>(this, {
      _busy: observable,
      _values: observable,
      busy: computed,
      values: computed,
      load: action.bound,
    });
  }

  get values() { return this._values || []; }
  get busy() { return this._busy; }

  public load = async () => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IValue[]>({
      path: '/values',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._values = result.value.map(val => new ValueModel(val));
        this._busy = false;
      }); 
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
  
  static loadCompanyValues = async (id: string) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IValue[]>({
      path: `/values/company?companyId=${id}`,
      method: 'GET',
    });

    if (result.success) {
      return result.value.map((value) => new ValueModel(value));
    } else {
      throw new Error(result.error);
    }
  };

  public loadCompaniesValues = async ( companies: string[] ) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IValue[]>({
      path: '/values/companies',
      method: 'POST',
      data: { companies },
    });

    if (result.success) {
      runInAction(() => {
        this._values = result.value;
        this._busy = false;
      }); 
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}
