import { observer } from 'mobx-react';
import React, { useCallback, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { AppType } from '../../constants';
import { useAnalytics } from '../../contexts/analytics-store';
import { KWLogo, KWTealLogo } from '../svgs/graphics/LogoWithText';
import { DeveloperSiteLogoLink, GlobalHeaderContainer, GlobalHeaderInner, NavContainer } from './styles';

interface IProps {
  app?: AppType;
  className?: string;
  navClassName?: string;
  children?: React.ReactNode;
}

const homeLink = 'https://karmawallet.io';

const GlobalHeaderBase: React.FC<IProps> = ({
  app = 'karma',
  children,
  className = '',
  navClassName = '',
}) => {
  const analytics = useAnalytics();

  const onLogoClick = useCallback((appType: AppType, preventDefault?: boolean) => (e: MouseEvent<HTMLAnchorElement>) => {
    if (!!appType && appType === 'developer') {
      analytics.fireEvent('Developer_GlobalNavLogoClick');
    } else {
      analytics.fireEvent('GlobalNavLogoClick');
    }
    if (!!preventDefault) {
      e.preventDefault();
      window.location.href = homeLink;
    }
  }, []);

  const renderLogo = () => {
    switch (app) {
      case 'karma':
        return <Link
          to='/'
          className='logo'
          onClick={ onLogoClick(app) }
          aria-label='Link to Karma Wallet homepage.'
        >
          <KWTealLogo />
        </Link>;
      case 'developer':
        return <DeveloperSiteLogoLink
          to='/'
          className='logo'
          onClick={ onLogoClick(app) }
          aria-label='Link to Karma Wallet homepage.'
        >
          <div className='logo-container'><KWTealLogo /><span className='logo-text'>API</span></div>
        </DeveloperSiteLogoLink>;
      case 'admin':
        return <a
          href={ homeLink }
          target='_blank'
          rel='noreferrer'
          className='logo'
          onClick={ onLogoClick(app) }
          aria-label='Link to Karma Wallet homepage.'
        >
          <KWLogo />
        </a>;
      default:
        return <Link
          to='/'
          className='logo'
          onClick={ onLogoClick(app) }
          aria-label='Link to Karma Wallet homepage.'
        >
          <KWLogo />
        </Link>;
    }
  };

  return (
    <GlobalHeaderContainer className={ className }>
      <GlobalHeaderInner>
        { !location.pathname.includes('/account') ? renderLogo() : null }
        <NavContainer className={ navClassName }>
          { children }
        </NavContainer>
      </GlobalHeaderInner>
    </GlobalHeaderContainer>
  );
};

export const GlobalHeader = observer(GlobalHeaderBase);
