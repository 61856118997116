import JsCookies from 'js-cookie';

export enum SiteCookies {
  LegacyAuthKey = 'userInfo_authKey', // same name as original site...temporarily including so dont ruin exisitng sessions
  AuthKey = 'authKey',
}

export class Cookies {
  static get = (name: SiteCookies) => JsCookies.get(name) || '';

  static set = (name: SiteCookies, value: string, config: JsCookies.CookieAttributes = {}) => {
    const _config: JsCookies.CookieAttributes = {
      sameSite: 'strict',
      domain: window.location.hostname.includes('localhost') ? 'localhost' : 'karmawallet.io',
      ...config,
    };

    JsCookies.set(name, value, _config);
  };

  static remove = (name: SiteCookies, config: JsCookies.CookieAttributes = {}) => {
    const _config: JsCookies.CookieAttributes = {
      sameSite: 'strict',
      domain: window.location.hostname.includes('localhost') ? 'localhost' : 'karmawallet.io',
      ...config,
    };

    JsCookies.remove(name, _config);
  };
}
