import React from 'react';
import { OurImpactPartnershipsContainer, OurImpactWrapper } from './styles';
import { IBlockContent, ImpactContentBlockType, OurImpactContentBlock } from '../OurImpactContentBlock';
import { H2M } from '../../styles/components/header';
import { PMarketing } from '../../styles/components/paragraph';
import { IThemeProps, theme } from '../../styles/themes'; 
import { withTheme } from 'styled-components';

interface IProps extends IThemeProps {
  className?: string;
}

const content: IBlockContent[] = [
  {
    title: 'One Tree Planted',
    body: 'For every account with a linked card, we plant a tree through our partner One Tree Planted. Since 2014, One Tree Planted has planted over 100 million trees across the globe, creating new job opportunities and reforesting our planet. To date, Karma Wallet has planted 4,328 trees.',
    impactContentType: ImpactContentBlockType.Partnership,
    ctaLink: 'https://onetreeplanted.org/',
    ctaText: 'Check out One Tree Planted',
    bodyAlignment: 'left',
    graphicSection: <img src='https://cdn.karmawallet.io/uploads/44Wys1bC9l-onetree-logo.svg' alt='one tree logo' />,
    customGraphic: true,
    customGraphicScore: 4328,
    customGraphicText: 'Trees Planted',
    customGraphicBg: theme.colors.secondary,
    customGraphicTextColor: theme.colors.dark,
    customGraphicSubtext: 'so far thanks to our community',
  },
  {
    title: 'Rare.org',
    body: 'To help you offset your shopping carbon footprint, we partner with Rare. When you offset your footprint, your donation gets spread across climate impact projects that prevent millions of tonnes of CO2 from entering the atmosphere every year. To date, Karma Wallet has helped offset 143 tonnes of carbon.',
    impactContentType: ImpactContentBlockType.Partnership,
    ctaLink: 'https://rare.org/',
    ctaText: 'Check out Rare.org',
    bodyAlignment: 'right',
    graphicSection: <img src='https://cdn.karmawallet.io/uploads/lcxzJ5vgBh-rare.svg' alt='rare.org logo' />,
    customGraphic: true,
    customGraphicScore: 143,
    customGraphicText: 'Tonnes',
    customGraphicBg: theme.colors.tertiary,
    customGraphicTextColor: theme.colors.dark,
    customGraphicSubtext: 'of carbon was offset by our users',
  },
  {
    title: 'Plastic Pollution Coalition',
    body: 'Plastic Pollution Coalition is a global alliance of organizations, businesses, and individuals working together to create a world free of plastic pollution and its toxic impacts.\nPlastic Pollution Coalition Business Members are committed to reducing or eliminating plastics in their product offerings, packaging, and operations. These companies are helping to accelerate the transition away from plastics by selling reusable and plastic-free alternatives to their customers.',
    impactContentType: ImpactContentBlockType.Partnership,
    ctaLink: 'https://www.plasticpollutioncoalition.org/',
    ctaText: 'Check out PPC',
    bodyAlignment: 'left',
    graphicSection: <img src='https://cdn.karmawallet.io/uploads/ZzNW3GRQIA-group-2114-2.png' alt='proud member logo' />,
    customGraphic: false,
  },
];

const OurImpactPartnershipsBase: React.FC<IProps> = ({
  className = '',
}) => (
  <OurImpactWrapper backgroundColor={ theme.colors.light }>
    <OurImpactPartnershipsContainer className={ className } >
      <div className='section-header-content header'>
        <H2M className='title'>Partnerships</H2M>
        <PMarketing>Together, we create change. Our partners help us do amazing things like offset your carbon footprint, plant trees across the globe, fight deforestation, and prevent millions of tons of CO2 from entering the atmosphere.</PMarketing>
      </div>
      {
        content.map(c => <OurImpactContentBlock key={ c.title } content={ c } /> )
      }
    </OurImpactPartnershipsContainer>
  </OurImpactWrapper>
);

export const OurImpactPartnerships = withTheme(OurImpactPartnershipsBase);
