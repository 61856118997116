import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const PlusIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }plus-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    fill='none'
  >
    <path d='M10.0003 4.16669V10M10.0003 15.8334V10M10.0003 10H15.8337M10.0003 10H4.16699' stroke={ stroke } strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
