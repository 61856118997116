import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useAnalytics } from '../../../contexts/analytics-store';
import { usePromos } from '../../../contexts/promos';
import { SignInContext } from '../../../contexts/sign-in';
import { SignInMode } from '../../../models/sign-in';
import { CreateAccountPromoContainer } from '../../CreateAccountPromoContainer';
import { AccountCompletion } from './AccountCompletion';
import { CreateAccountEmailOnly } from './CreateAccountEmailOnly';
import { CreatePassword } from './CreatePassword';
import { ResetPassword } from './ResetPassword';
import { SignIn } from './SignIn';
import { SignInModalContainer, SignInModalInner } from './styles';
import { ChangeEmail } from './ChangeEmail';
import { ChangeEmailCompletion } from './ChangeEmailCompletion';

const SignInModalBase: React.FC = () => {
  const signInModel = useContext(SignInContext);
  const promos = usePromos();
  const analytics = useAnalytics();

  useEffect(() => {
    // prevent scrolling when this modal is open
    document.body.classList[signInModel.isOpen ? 'add' : 'remove']('no-scroll');
  }, [signInModel.isOpen]);

  const onSignInCloseClick = useCallback(() => {
    signInModel.close();
    analytics.fireEvent('SignInModalCloseClick');
  }, []);

  const renderMode = () => {
    if (signInModel.mode === SignInMode.SIGN_IN) return <SignIn />;
    if (signInModel.mode === SignInMode.CREATE_ACCOUNT) return <CreateAccountEmailOnly />;
    if (signInModel.mode === SignInMode.CREATE_PASSWORD) return <CreatePassword />;
    if (signInModel.mode === SignInMode.ACCOUNT_COMPLETION || signInModel.mode === SignInMode.ACCOUNT_COMPLETION_SUCCESS) return <AccountCompletion onClose={ onSignInCloseClick } />;
    if (signInModel.mode === SignInMode.CHANGE_EMAIL) return <ChangeEmail onClose={ onSignInCloseClick } />;
    if (signInModel.mode === SignInMode.CHANGE_EMAIL_COMPLETION) return <ChangeEmailCompletion onClose={ onSignInCloseClick } />;
    // if (signInModel.mode === SignInMode.MEMBERSHIP) return <Membership />;
    return <ResetPassword />;
  };

  const promoContent = useMemo(() => {
    if (signInModel.mode === SignInMode.SIGN_IN
     || signInModel.mode === SignInMode.CREATE_PASSWORD
     || signInModel.mode === SignInMode.RESET_PASSWORD
     || signInModel.mode === SignInMode.ACCOUNT_COMPLETION_SUCCESS
    ) return null;

    return <CreateAccountPromoContainer />;
  }, [signInModel.mode]);

  if (!promos?.checkedForPromos) return null;

  return (
    <SignInModalContainer
      isOpen={ signInModel.isOpen }
      onClose={ onSignInCloseClick }
      className={ signInModel?.mode }
      modalScrim='light'
    >
      <SignInModalInner
        className={ signInModel.mode === SignInMode.SIGN_IN ? 'sign-in' : 'create-account' }
      >
        { promoContent }
        { renderMode() }
      </SignInModalInner>
    </SignInModalContainer>
  );
};

export const SignInModal = observer(SignInModalBase);
