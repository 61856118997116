import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const Stats: FC<IProps> = ({ className = '', id = '', fill = 'none' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }stats-graphic` }
    id={ id }
    width={ 200 }
    height={ 200 }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M47.0886 41.12L12.4414 177.104L126.797 177.851L150.352 41.6416L47.0886 41.12Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M47.1046 41.0695C46.262 35.3631 48.1404 31.5455 52.8151 29.4526C57.6252 27.299 75.9649 28.148 75.9649 28.148C82.0087 26.8852 86.6031 24.3501 88.9022 19.7998L194.776 19.8987C198.215 26.8485 185.793 51.9 173.805 46.5239C171.279 45.3912 168.827 39.6573 168.827 39.6573L149.236 39.1149L150.362 41.6074L47.1046 41.0695Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M75.6973 28.1402L153.274 28.1768C161.831 26.9805 165.314 31.2938 168.871 39.7644' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M149.255 39.1367C147.414 34.9853 147.197 29.745 153.449 28.1724' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M150.461 41.6909L152.516 43.1165L129.112 179.974L126.812 177.716L150.461 41.6909Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M129.049 180.009L127.059 177.88L12.4229 177.203L13.4739 179.35L129.049 180.009Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M52.8474 50.9464L41.7354 95.6836L134.502 96.0758' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M49.1113 95.6351L52.7521 79.5876L59.2209 79.683L55.9064 95.6854L49.1113 95.6351Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M65.1953 95.7677L70.9041 63.4453L77.3728 63.5408L71.9904 95.8172L65.1953 95.7677Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M98.4736 95.7577L103.997 65.0756L110.466 65.171L105.269 95.8079L98.4736 95.7577Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M45.7432 80.5651C64.0416 69.4287 73.7075 62.2315 96.2752 79.1319C107.212 87.3575 128.978 69.6048 134.525 60.21' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M115.148 95.9923L122.84 52.5249L129.309 52.6204L121.944 96.0425L115.148 95.9923Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M82.6719 95.8102L89.7345 56.0533L96.2032 56.1489L89.4669 95.8604L82.6719 95.8102Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M40.8861 101.14L39.5859 107.126L46.1009 107.094L47.3602 100.979L40.8861 101.14Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M41.624 104.416L42.3105 105.444L45.4975 103.398' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M41.624 104.416L42.3105 105.444L45.4975 103.398' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M38.8456 110.96L37.4971 117.169L44.0046 117.185L45.2895 110.947L38.8456 110.96Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M39.5791 114.235L40.2655 115.263L43.4526 113.218' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M36.5029 121.159L35.1543 127.368L41.6618 127.384L42.9467 121.146L36.5029 121.159Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M37.2383 124.432L37.9247 125.46L41.1117 123.415' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M132.342 112.705L131.812 116.283L48.9824 116.422L49.6609 112.718L132.342 112.705Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M80.0257 122.801L79.496 126.378L47.1748 126.518L47.8533 122.814L80.0257 122.801Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M115.923 122.801L115.393 126.378L83.0723 126.518L83.7508 122.814L115.923 122.801Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd' d='M133.182 102.359L132.652 105.936L49.8223 106.076L50.5008 102.372L133.182 102.359Z' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M41.2037 162.99C48.785 163.877 55.6456 158.488 56.5274 150.953C57.4092 143.418 51.9782 136.59 44.397 135.703C36.8157 134.816 29.9551 140.205 29.0733 147.74C28.1915 155.275 33.6225 162.103 41.2037 162.99Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.9795 149.348L42.0385 149.525L42.8249 135.66' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M42.2197 149.619L52.2191 159.329' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M42.6748 149.201L53.6203 140.983' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M108.618 153.47C109.483 145.886 104.074 139.041 96.5369 138.181C88.9994 137.32 82.1874 142.771 81.3219 150.355C80.4565 157.938 85.8653 164.784 93.4028 165.644C100.94 166.504 107.752 161.053 108.618 153.47Z' fill='#C0E0CE' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.0103 165.732L95.1502 152.673L89.8908 139.228' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.2422 152.493L107.166 146.21' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.1426 152.544L100.555 139.569' stroke='#001C20' strokeWidth='1.01856' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.1697 152.395L81.4707 151.896' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.75 143.298L70.8922 143.485' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.4619 149.327L67.2567 149.457' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.5576 146.319L73.4166 146.723' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M112.456 146.223L118.522 146.409' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M112.167 152.251L123.075 152.434' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M112.263 149.244L126.122 149.648' stroke='#001C20' strokeWidth='1.01857' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M120.145 132.075C120.142 132.05 122.367 130.61 122.651 130.387' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M124.836 129.991C124.752 130.011 124.798 130.007 124.698 129.995' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M114.243 139.54C114.123 139.271 114.352 139.634 114.405 139.702' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M116.62 138.798C116.74 138.754 116.669 138.696 116.766 138.708' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.6836 162.872C69.0466 163.724 70.2662 164.726 70.6881 165.264' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M73.2331 167.663C73.2212 167.658 73.2092 167.653 73.1973 167.648' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M24.5611 165.455C24.388 165.512 24.954 166.416 25.0181 166.542C25.6396 167.761 26.818 168.418 27.9565 169.072' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M23.7072 168.19C23.6745 168.141 23.847 168.555 23.873 168.594C24.0484 168.858 24.052 168.835 24.2536 169.076C24.7468 169.662 25.2471 170.063 25.8625 170.518' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M28.6273 171.54C28.5991 171.538 28.5711 171.534 28.543 171.531' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M47.5992 169.487C47.4995 169.444 47.8162 169.507 47.9244 169.519C48.1304 169.543 48.3582 169.567 48.5636 169.536C49.3566 169.417 50.1604 169.403 50.9581 169.496' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M52.7142 169.719C52.7067 169.741 52.7059 169.731 52.7397 169.735' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.001 161.597C59.3043 161.435 59.4887 161.022 59.7325 160.791C60.2505 160.299 60.7985 159.792 61.3587 159.349' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M60.8102 162.103C60.7563 161.96 61.1977 161.692 61.2945 161.616' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M43.6632 158.031C43.6976 158.04 43.6508 158.017 43.6211 157.998' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M50.4805 148.681C50.5496 148.706 51.8372 147.916 52.0439 147.823' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M52.3561 149.698C52.2998 149.69 52.3126 149.691 52.2617 149.685' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.5597 143.128C36.5469 143.124 36.511 143.118 36.5338 143.12' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M38.1562 141.46C38.1727 141.336 38.1855 141.366 38.2886 141.344' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M78.952 137.268C78.9345 137.232 78.8071 137.066 78.9235 137.08' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.5088 134.71C83.7394 133.911 84.7871 133.669 85.4601 133.326' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.625 135.11C84.7982 134.937 85.0164 134.864 85.2158 134.708' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.0938 134.156C64.0961 134.073 64.1082 134.1 64.1772 134.108C64.6215 134.16 64.9341 134.208 65.3923 134.001C66.3542 133.568 67.178 132.702 67.9831 132.065' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.249 133.622C69.2617 133.567 69.2398 133.539 69.3043 133.547' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M86.8271 156.39C86.8308 156.143 87.5532 156.878 87.8654 157.039C88.7122 157.476 89.2106 157.756 89.7715 158.465' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.0985 160.803C90.0705 160.772 90.0889 160.784 90.0381 160.778' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M86.0498 173.124C86.781 173.227 87.5544 171.026 87.3325 170.414' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.8018 173.031C87.9895 172.946 88.0491 172.44 88.0724 172.242' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.909 157.798C102.749 157.819 103.863 158.044 104.204 158.084' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.867 168.122C102.628 169.371 104.228 169.699 105.031 169.793' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.7852 172.779C70.371 172.995 71.0832 172.862 71.6921 172.934' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M115.982 163.373C115.859 163.368 116.396 163.423 116.781 163.129C117.12 162.869 117.413 162.379 117.661 162.039C118.035 161.531 118.604 159.347 119.178 159.238' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M122.996 170.961C122.057 170.912 124.405 171.968 124.59 172.021' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M122.79 172.441C122.082 172.642 123.783 173.485 124.012 173.597' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M127.236 160.224C127.221 160.158 127.228 160.189 127.216 160.131' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M130.555 142.889C130.472 142.88 130.489 142.837 130.475 142.763C130.299 141.842 130.952 140.875 130.774 139.942' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M131.365 137.43C131.381 137.388 131.369 137.361 131.361 137.317' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M107.476 134.541C107.392 134.262 107.636 132.805 107.72 132.789' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M113.491 124.442C113.446 124.382 113.444 124.4 113.43 124.33' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M133.792 145.406C133.771 145.343 133.779 145.374 133.768 145.316' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M135.226 135.963C135.042 135.901 135.53 134.809 135.498 134.643' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M129.683 122.349C130.372 122.373 130.815 120.992 131.128 120.531' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M130.827 123.08C130.826 123.09 130.955 122.962 131.323 122.42' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.293 119.913C64.9766 120.16 65.7195 120.001 66.4301 120.173' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M68.6396 120.223C68.7341 120.218 68.7451 120.261 68.8128 120.236' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M29.7891 128.072C29.7901 128.161 29.8051 128.141 29.8653 128.169' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M32.996 116.052C32.9312 116.053 33.2858 113.296 33.5825 112.659' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M31.9245 114.408C31.8388 114.187 31.9453 113.194 32.1372 113.124' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.7497 96.0912C36.6024 95.9428 36.7492 96.3167 36.7623 96.3524C36.9549 96.8806 37.875 97.1905 37.974 97.4626' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.2197 97.726C36.4634 97.6576 36.8728 98.4848 37.2421 98.3502' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M58.6924 98.7822C58.6483 98.8004 59.3471 98.8848 59.9341 98.9525C60.539 99.0216 61.9641 99.0812 62.4603 98.9001' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M64.5305 99.2814C64.5259 99.2765 64.5213 99.2716 64.5166 99.2667' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.5593 99.7225C83.3971 99.3427 84.3058 98.191 84.4768 97.8243' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.7649 99.4408C84.7234 99.4684 85.1046 98.8704 85.2414 98.7256' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M83.6116 110.372C83.5117 110.405 83.5345 110.404 83.5029 110.472' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.4838 119.076C87.4545 119.087 87.4251 119.097 87.3965 119.108' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M133.153 73.7733C133.134 73.6882 134.44 73.2466 134.581 73.1563C135.464 72.5947 136.165 71.6125 136.902 70.8755' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M137.193 73.6682C137.162 73.5706 137.221 73.5566 137.304 73.5566' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.033 84.1787C109.858 84.0769 110.24 85.0154 110.288 85.0838C110.619 85.5567 111.098 85.7342 111.488 86.1249' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M110.092 86.642C110.05 87.0545 110.655 87.3964 110.848 87.6853' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M132.355 85.2946C132.35 85.2913 132.345 85.2872 132.34 85.2831' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M122.385 79.3815C122.498 79.5939 122.781 79.6834 122.976 79.8005' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M123.657 62.2855C123.646 61.6002 124.207 60.7808 124.617 60.2693' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M137.277 51.1267C137.245 51.0768 138.113 50.2873 138.183 50.2237C138.823 49.6424 139.205 49.0207 139.584 48.2626' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M141.084 46.9683C141.059 46.9305 141.056 46.9418 141.107 46.9418' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M142.345 55.2289C142.297 55.1774 142.306 55.2062 142.306 55.1469' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M142.376 57.9413C142.333 57.9504 142.357 57.9476 142.306 57.9476' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M138.777 85.3413C138.809 85.0772 138.721 86.0099 138.705 86.1242C138.633 86.6218 138.535 87.1071 138.464 87.6047' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M113.076 58.5568C113.637 58.0374 114.081 56.5311 114.53 56.3068' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M115.711 54.5845C115.746 54.5866 115.703 54.5857 115.69 54.5857' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M98.9248 48.4984C99.0987 48.5223 99.25 48.9558 99.5214 49.0237C100.084 49.1643 100.747 48.8373 101.283 49.1437C101.639 49.3472 101.811 49.7101 102.123 49.9443C102.198 50.0004 102.443 50.0452 102.443 50.1443' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M102.911 51.7809C102.936 51.7885 102.9 51.7846 102.883 51.7846' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M88.7695 70.374C88.9238 70.2638 89.1406 70.3309 89.3559 70.1151' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M126.73 44.5481C126.676 44.5376 126.679 44.5009 126.737 44.5009' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M128.828 46.6501C128.786 46.5328 129.243 46.9696 129.258 46.9816C129.609 47.2735 129.895 47.5542 130.338 47.702' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M65.4451 50.1392C65.3313 50.0904 65.492 49.9733 65.5488 49.9166C65.9559 49.5093 66.6139 49.5098 67.115 49.2092C67.6656 48.8788 67.8011 47.9063 68.2265 47.6936' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.609 46.2651C69.5798 46.2907 69.5926 46.2525 69.5926 46.2284' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M58.1797 61.2682C58.1779 61.2439 58.2198 61.9857 58.4764 62.3449C58.8022 62.8011 59.4589 62.8727 59.8405 63.2543' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M57.6504 63.2672C57.6783 63.1636 57.7944 63.5181 57.8695 63.6082C58.1337 63.9252 58.4217 64.1517 58.7788 64.366' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M42.4937 73.9279C42.4695 73.8772 42.4867 73.864 42.4121 73.864' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M44.0234 68.9374C44.049 68.5256 45.1855 64.4709 45.2406 64.4158' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M45.8906 62.2543C45.9057 62.2303 45.8967 62.2485 45.8967 62.1936' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M81.6914 53.0156C81.3065 53.5426 83.6878 55.5751 83.9904 56.1811' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.707 55.2278C81.1821 55.6486 81.6157 56.6464 81.6157 57.2929' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M70.7599 83.0909C70.7551 83.0662 70.9368 82.7751 70.9557 82.7562' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M61.1452 88.8297C60.7949 88.7875 61.7348 88.9949 62.114 89.3739' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M61.5182 87.3341C61.2767 87.409 61.9947 87.8899 62.0638 87.9591' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M80.3754 82.6484C80.2458 82.6601 80.2012 82.6162 80.2012 82.7568' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M87.8859 36.9255C87.7083 36.9502 88.3631 35.8225 88.4352 35.7208C88.7131 35.332 89.9616 33.7505 90.4054 33.7505' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M90.1881 36.0943C89.8825 36.3584 90.8603 34.8722 90.8603 34.8612' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M77.6903 35.1616C77.6577 35.1682 77.674 35.1704 77.674 35.1133' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M60.3008 34.2897C60.3846 34.319 60.3233 34.3387 60.346 34.4065C60.6608 35.351 61.456 35.1888 62.2152 35.5685' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M59.6925 35.901C59.6633 35.6189 60.2442 36.0488 60.4979 36.1757' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M93.0195 25.2525C93.2975 24.4635 95.1047 23.8085 95.1047 22.8875' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.0928 24.6891C95.183 24.1276 96.0386 23.6222 96.4198 23.2413' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M101.062 24.5296C101.622 24.2807 103.027 24.8185 103.442 24.4033' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M105.396 34.0565C105.343 34.0519 105.372 34.0533 105.31 34.0533' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M111.008 37.4084C111.053 37.0823 112.609 37.0847 112.987 37.0847' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M134.101 32.6821C134.288 33.545 135.711 34.5964 136.485 34.8867' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M133.272 34.0632C133.577 34.5496 134.14 35.2403 134.73 35.3811' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M126.906 35.06C126.866 35.042 126.756 34.9978 126.856 34.9745' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M145.996 32.2095C145.702 31.9376 146.044 31.3844 146.21 31.1171' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M151.727 34.8599C151.558 34.8995 151.746 33.6433 151.764 33.5206C151.836 32.9955 151.983 31.1399 152.667 30.9802' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M153.609 33.8354C153.113 33.6873 153.457 32.4224 153.641 32.1254' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M148.994 22.7374C149.071 22.7028 149.348 22.8207 149.431 22.8353C149.891 22.9168 150.872 22.8535 151.235 23.0793' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M153.021 23.6249C153.042 23.6044 153.015 23.6396 152.997 23.6674' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M132.771 24.3538C133.383 24.2639 134.036 24.5613 134.662 24.4149' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M170.403 31.5395C169.785 31.069 171.432 33.8427 171.495 33.9554C171.553 34.0601 171.933 34.7653 172.067 34.7271' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M172.982 35.8534C172.981 35.8593 172.98 35.8651 172.979 35.871' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M185.383 33.5795C185.827 33.2389 185.842 32.7308 186.099 32.267' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M186.713 31.167C186.718 31.144 186.716 31.16 186.705 31.1198' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M178.914 26.5204C179.088 27.0524 180.013 26.7493 180.364 26.8151' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M179.418 28.3827C179.36 28.3866 180.01 28.4966 180.171 28.586' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M179.978 40.5088C179.971 40.505 179.964 40.5011 179.956 40.4973' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M160.093 36.1742C160.226 36.1144 160.549 36.44 160.75 36.4486' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M167.015 24.6578C166.989 24.6417 166.824 24.5737 166.929 24.5436' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M189.741 23.1918C189.935 23.151 190.656 23.5315 190.967 23.5447' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M150.616 47.2084C150.577 47.1894 150.581 47.1856 150.607 47.2215' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M148.257 61.1328C148.121 61.0644 148.289 60.102 148.377 59.9343' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M146.152 72.3612C146.147 72.3815 146.152 72.3687 146.123 72.3898' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M141.742 99.2059C141.725 99.2304 141.736 99.2207 141.722 99.165' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M136.035 103C136.025 103.056 136.02 102.975 136.005 102.92' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M118.263 107.865C118.261 107.862 120.019 108.014 120.319 107.988' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M100.814 109.198C100.814 109.113 100.828 109.155 100.739 109.088' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M50.8105 131.044C50.8593 130.99 50.772 131.031 50.6992 131.041' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M36.3271 154.468C36.1692 154.5 36.5058 154.19 36.5386 154.112C36.6612 153.822 36.7451 153.452 36.9371 153.197' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M37.3542 154.935C37.2228 154.906 37.5124 154.432 37.5647 154.362' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M129.768 90.634C129.775 90.576 129.781 90.6076 129.678 90.6076' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M69.8127 54.3787C69.731 54.2602 69.7406 54.2254 69.7406 54.088' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M82.3906 45.9799C82.7362 45.6802 83.6209 45.3099 84.0509 45.1667' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M84.3477 46.683C84.4677 46.4896 84.5914 46.3841 84.7951 46.2822' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.0304 44.6356C55.9534 44.4178 56.0939 44.4234 56.2673 44.4234' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.9647 137.441C56.5971 137.441 57.3806 136.423 57.4758 136.233' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.9229 137.43C28.3581 137.457 28.9432 137.629 29.3193 137.441' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.3457 139.55C27.4181 139.342 28.1502 139.672 28.2971 139.672' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M95.2246 131.265C95.8212 131.634 96.7954 131.521 97.4327 131.308' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M96.9219 132.671C97.119 132.716 97.2362 132.795 97.4333 132.795' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M120.574 97.9704C120.573 97.9633 120.572 97.9554 120.57 97.9484' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M115.667 45.4516C115.519 45.444 115.508 45.3528 115.367 45.3528' stroke='#001C20' strokeWidth='0.392875' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
