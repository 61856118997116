import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
  ariaHidden?: boolean;
}

export const DashboardIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
  ariaHidden,
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}dashboard-icon` }
    id={ id }
    height={ 20 }
    width={ 20 }
    fill={ fill }
    aria-hidden={ ariaHidden }
  >
    <path d='M7.5 1.25V5H1.25V1.25H7.5ZM1.25 0C0.918479 0 0.600537 0.131696 0.366117 0.366117C0.131696 0.600537 0 0.918479 0 1.25L0 5C0 5.33152 0.131696 5.64946 0.366117 5.88388C0.600537 6.1183 0.918479 6.25 1.25 6.25H7.5C7.83152 6.25 8.14946 6.1183 8.38388 5.88388C8.6183 5.64946 8.75 5.33152 8.75 5V1.25C8.75 0.918479 8.6183 0.600537 8.38388 0.366117C8.14946 0.131696 7.83152 0 7.5 0L1.25 0ZM18.75 15V18.75H12.5V15H18.75ZM12.5 13.75C12.1685 13.75 11.8505 13.8817 11.6161 14.1161C11.3817 14.3505 11.25 14.6685 11.25 15V18.75C11.25 19.0815 11.3817 19.3995 11.6161 19.6339C11.8505 19.8683 12.1685 20 12.5 20H18.75C19.0815 20 19.3995 19.8683 19.6339 19.6339C19.8683 19.3995 20 19.0815 20 18.75V15C20 14.6685 19.8683 14.3505 19.6339 14.1161C19.3995 13.8817 19.0815 13.75 18.75 13.75H12.5ZM7.5 10V18.75H1.25V10H7.5ZM1.25 8.75C0.918479 8.75 0.600537 8.8817 0.366117 9.11612C0.131696 9.35054 0 9.66848 0 10L0 18.75C0 19.0815 0.131696 19.3995 0.366117 19.6339C0.600537 19.8683 0.918479 20 1.25 20H7.5C7.83152 20 8.14946 19.8683 8.38388 19.6339C8.6183 19.3995 8.75 19.0815 8.75 18.75V10C8.75 9.66848 8.6183 9.35054 8.38388 9.11612C8.14946 8.8817 7.83152 8.75 7.5 8.75H1.25ZM18.75 1.25V10H12.5V1.25H18.75ZM12.5 0C12.1685 0 11.8505 0.131696 11.6161 0.366117C11.3817 0.600537 11.25 0.918479 11.25 1.25V10C11.25 10.3315 11.3817 10.6495 11.6161 10.8839C11.8505 11.1183 12.1685 11.25 12.5 11.25H18.75C19.0815 11.25 19.3995 11.1183 19.6339 10.8839C19.8683 10.6495 20 10.3315 20 10V1.25C20 0.918479 19.8683 0.600537 19.6339 0.366117C19.3995 0.131696 19.0815 0 18.75 0L12.5 0Z' fill={ fill } />
  </SvgIcon>
);
