import React, { createContext } from 'react';
import { BadgesModel } from '../models/badges';

export const BadgesContext = createContext<BadgesModel>(null);

export const BadgesStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <BadgesContext.Provider value={ new BadgesModel() }>
    { children }
  </BadgesContext.Provider>
);
