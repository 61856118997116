import React, { createContext, useState } from 'react';
import { GroupModel } from '../models/group';

interface IGroupContext {
  group: GroupModel;
  setGroup: React.Dispatch<React.SetStateAction<GroupModel>>;
}

export const GroupContext = createContext<IGroupContext>(null);

export const useGroup = () => React.useContext(GroupContext);

export const GroupStore: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [group, setGroup] = useState<GroupModel>(null);

  return (
    <GroupContext.Provider value={ { group, setGroup } }>
      { children }
    </GroupContext.Provider>
  );
};
