import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';
import { IThemeProps } from '../../../../styles/themes';
import { withTheme } from 'styled-components';

interface IProps extends IThemeProps {
  stroke?: string;
  id?: string;
}

export const UnderlineBase: FC<IProps> = ({ 
  stroke = '',
  id = '',
  theme,
}) => (
  <SvgIcon
    id={ id }
    width={ 177 }
    height={ 15 }
    fill='none'
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M1.70213 13.6269C1.70133 13.627 1.70095 13.6271 1.69113 12.3927C1.68131 11.1582 1.68176 11.1582 1.68263 11.158L1.76142 11.144C1.81443 11.1346 1.89356 11.1206 1.99822 11.1022C2.20753 11.0655 2.51895 11.0112 2.92778 10.9411C3.74544 10.8008 4.95273 10.5969 6.51204 10.3424C9.63064 9.83356 14.1574 9.12246 19.7913 8.31331C31.0589 6.69504 46.7561 4.6844 64.4751 3.11492C99.9027 -0.0231373 143.45 -1.40139 175.83 5.66597C176.172 5.74065 176.397 6.35287 176.333 7.03338C176.269 7.7139 175.94 8.20503 175.598 8.13034C143.32 1.08527 99.8556 2.45427 64.4364 5.59157C46.7319 7.15978 31.0468 9.16885 19.788 10.7859C14.1587 11.5944 9.63618 12.3048 6.52133 12.8131C4.96391 13.0672 3.75842 13.2708 2.94249 13.4108C2.53452 13.4808 2.22394 13.5349 2.01545 13.5715C1.9112 13.5898 1.83248 13.6037 1.77987 13.613L1.70213 13.6269ZM1.06089 12.5057C1.05546 11.8239 1.33323 11.2206 1.68131 11.1582L1.69113 12.3927L1.70095 13.6271C1.35288 13.6895 1.06631 13.1874 1.06089 12.5057Z' fill={ !!stroke ? stroke : theme.colors.tertiary } />
    <path d='M1.69113 12.3927C1.70095 13.6271 1.70133 13.627 1.70213 13.6269L1.77987 13.613C1.83248 13.6037 1.9112 13.5898 2.01545 13.5715C2.22394 13.5349 2.53452 13.4808 2.94249 13.4108C3.75842 13.2708 4.96391 13.0672 6.52133 12.8131C9.63618 12.3048 14.1587 11.5944 19.788 10.7859C31.0468 9.16885 46.7319 7.15978 64.4364 5.59157C99.8556 2.45427 143.32 1.08526 175.598 8.13034C175.94 8.20503 176.269 7.7139 176.333 7.03338C176.397 6.35287 176.172 5.74065 175.83 5.66597C143.45 -1.40139 99.9027 -0.0231375 64.4751 3.11492C46.7561 4.6844 31.0589 6.69504 19.7913 8.31331C14.1574 9.12247 9.63064 9.83356 6.51204 10.3424C4.95273 10.5969 3.74544 10.8008 2.92778 10.9411C2.51895 11.0112 2.20753 11.0655 1.99822 11.1022C1.89356 11.1206 1.81443 11.1346 1.76142 11.144L1.68263 11.158C1.68176 11.1582 1.68131 11.1582 1.69113 12.3927ZM1.69113 12.3927L1.68131 11.1582C1.33323 11.2206 1.05546 11.8239 1.06089 12.5057C1.06631 13.1874 1.35288 13.6895 1.70095 13.6271L1.69113 12.3927Z' stroke={ !!stroke ? stroke : theme.colors.tertiary } />
  </SvgIcon>
);

export const Underline = withTheme(UnderlineBase);
