import React, { useEffect } from 'react';
import { DeclinedPageContainer } from './styles';
import { observer } from 'mobx-react';
import { ResponseText, ResultHeader } from '../../styles';
import { useAnalytics } from '../../../../contexts/analytics-store';

const DeclinedPageBase: React.FC = () => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.fireEvent('ApplyForCard_Declined');
  }, []);

  return (
    <DeclinedPageContainer>
      <ResultHeader>Applicaton is Declined</ResultHeader>
      <ResponseText className='response-text'>
        After reviewing your application, we are unable to approve your request at this time. If you have any questions, please email support@karmawallet.io.
      </ResponseText>
    </DeclinedPageContainer>
  );
};

export const DeclinedPage = observer(DeclinedPageBase);
