import styled from 'styled-components';

export const TopValueCardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkGreen3};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.green};
  min-width: 230px;
  min-height: 100px;
  padding: 10px;
  position: relative;

  .value-name {
    font-size: 14px;
    margin: 0;
    margin-top: 10px;
  }

  .value-dollar-amount {
    font-size: 30px;
    font-weight: 600;
    margin: 10px 0;
  }

  .value-purchases {
    font-size: 14px;
    margin: 0;
  }

  img {
    left: calc(50% - 14px);
    position: absolute;
    top: -18px;
    width: 28px;
    z-index: 2;
  }

  :after {
    background-color: #1f3d33;
    border-radius: 100px 100px 0 0;
    border: 1px solid ${({ theme }) => theme.colors.green};
    border-bottom: none;
    content: '';
    height: 32px;
    left: calc(50% - 32px);
    margin: 0 auto;
    position: absolute;
    top: -33px;
    transform-origin: center;
    width: 64px;
  }
`;
