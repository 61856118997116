import React from 'react';
import { JobPostingModel } from '../../models/job-postings';
import { H5 } from '../../styles/components/header';
import { BriefcaseIcon } from '../svgs/icons/BriefcaseIcon';
import { MapPinIcon } from '../svgs/icons/MapPinIcon';
import { Detail, JobPostingDetailsContainer } from './styles';

interface IProps {
  className?: string;
  job: JobPostingModel;
}

export const JobPostingDetails: React.FC<IProps> = ({
  className = '',
  job,
}) => {
  if (!job) return null;

  return (
    <JobPostingDetailsContainer className={ className }>
      <Detail>
        <BriefcaseIcon />
        <H5>{ job.department }</H5>
      </Detail>
      <Detail>
        <MapPinIcon />
        <H5>{ job.jobLocation }</H5>
      </Detail>
    </JobPostingDetailsContainer>
  );
};
