import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
  ariaHidden?: boolean;
}

export const TransactionsIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
  ariaHidden,
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}transactions-icon` }
    id={ id }
    height={ 15 }
    width={ 20 }
    fill={ fill }
    aria-hidden={ ariaHidden }
  >
    <path d='M13.75 4.375C13.75 4.20924 13.8158 4.05027 13.9331 3.93306C14.0503 3.81585 14.2092 3.75 14.375 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V5.625C17.5 5.79076 17.4342 5.94973 17.3169 6.06694C17.1997 6.18415 17.0408 6.25 16.875 6.25H14.375C14.2092 6.25 14.0503 6.18415 13.9331 6.06694C13.8158 5.94973 13.75 5.79076 13.75 5.625V4.375Z' fill={ fill } />
    <path d='M2.5 0C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5L0 12.5C0 13.163 0.263392 13.7989 0.732233 14.2678C1.20107 14.7366 1.83696 15 2.5 15H17.5C18.163 15 18.7989 14.7366 19.2678 14.2678C19.7366 13.7989 20 13.163 20 12.5V2.5C20 1.83696 19.7366 1.20107 19.2678 0.732233C18.7989 0.263392 18.163 0 17.5 0H2.5ZM18.75 2.5V8.75H1.25V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H17.5C17.8315 1.25 18.1495 1.3817 18.3839 1.61612C18.6183 1.85054 18.75 2.16848 18.75 2.5ZM17.5 13.75H2.5C2.16848 13.75 1.85054 13.6183 1.61612 13.3839C1.3817 13.1495 1.25 12.8315 1.25 12.5V11.25H18.75V12.5C18.75 12.8315 18.6183 13.1495 18.3839 13.3839C18.1495 13.6183 17.8315 13.75 17.5 13.75Z' fill={ fill } />
  </SvgIcon>
);
