import { atom } from 'recoil';
import { IMembershipPriceAtom } from './types';

export const defaultMembershipPrice: IMembershipPriceAtom = {
  price: '$40',
  error: false,
  loading: false,
};

export const membershipPriceAtom = atom({
  key: 'membershipPriceAtom',
  default: defaultMembershipPrice,
});
