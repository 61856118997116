import React, { useMemo } from 'react';
import {
  ApplyForCardModel,
  CurrentPage,
  TemplateIdForPage,
} from '../../../../models/karmaCard';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { observer } from 'mobx-react';
import { Button } from '../../../../components/Button';
import { CTAContainer } from '../../styles';
import { PageTwoContainer } from './styles';
import { PersonaTemplateIdEnumValues } from '../../../../constants';
import { getTemplateNameFromId } from '../../../../lib/misc';

interface IProps {
  applyModel: ApplyForCardModel;
  templateId: PersonaTemplateIdEnumValues;
}

export const PageTwoBase: React.FC<IProps> = ({ applyModel, templateId }) => {
  const analytics = useAnalytics();

  const hidePage = useMemo(() => {
    if (!templateId) return true;
    if (templateId === TemplateIdForPage[CurrentPage.PageTwo_CollectDocs]) {
      return applyModel.currentPage !== CurrentPage.PageTwo_CollectDocs;
    }
  }, [templateId, applyModel.currentPage]);

  const templateName = useMemo(
    () => getTemplateNameFromId(templateId),
    [templateId],
  );

  const isDisabled = !applyModel.email || !templateId;

  const onClick = () => {
    analytics.fireEvent(`PageTwo_Continue_Click${templateName ? '_' + templateName : ''}`);

    applyModel.setAwaitingApplicationDecision(true);
    applyModel.restartSocketConnection();
    applyModel.setOpenPersonaModal(true);
  };

  return (
    !hidePage && (
      <PageTwoContainer>
        <CTAContainer>
          <Button
            disabled={ isDisabled }
            onClick={ onClick }>
            Continue
          </Button>
        </CTAContainer>
      </PageTwoContainer>
    )
  );
};

export const PageTwo = observer(PageTwoBase);
