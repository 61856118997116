import React from 'react';
import { useShareASaleParam } from '../../hooks/useShareASaleParam';
import { useScrollToTopOnLocationChange } from '../../hooks/useScrollToTopOnLocationChange';
import { useSessionIdle } from '../../hooks/useSessionIdle';

export const Hooks: React.FC = () => {
  // Reads param on URL coming from ShareASale and setting to localStorage for use on Account Creation
  useShareASaleParam();
  useScrollToTopOnLocationChange();
  useSessionIdle();

  return null;
};
