import { observer } from 'mobx-react';
import React from 'react';
import { withTheme } from 'styled-components';
import { useBrowseQuery } from '../../contexts/browse-query';
import { Ratings } from '../../models/impact';
import { IThemeProps } from '../../styles/themes';
import { RatingFilterButtonContainer } from './styles';
import { Button } from '../Button';
import { CompanyRatingTag } from '../CompanyRatingTag';
import { ButtonKind } from '../Button/styles';

interface IProps extends IThemeProps {
  className?: string;
  rating: Ratings;
  selected: boolean;
  onRatingClick: any;
}

const RatingFilterButtonBase: React.FC<IProps> = ({
  className='',
  rating,
  selected,
  onRatingClick,
}) => {
  const requestQueryModel = useBrowseQuery();

  const displayLabel = () => {
    switch (rating as Ratings) {
      case Ratings.Negative:
        return 'Negative Rated Company';
      case Ratings.Positive:
        return 'Positive Rated Company';
      case Ratings.Neutral:
        return 'Neutral Rated Company';
      default:
        break;
    }
  };
  
  return (
    <RatingFilterButtonContainer
      className={ (requestQueryModel.cashbackOnly && rating === Ratings.Negative ? 'disabled': '') }
      selected={ selected }
    >
      <Button 
        className={ className }
        onClick={ onRatingClick(rating) }
        kind={ ButtonKind.Blank }
      >
        <CompanyRatingTag rating={ rating } />
        { displayLabel() }
      </Button>
    </RatingFilterButtonContainer>
  );
};

const RatingFilterButtonObserver = observer(RatingFilterButtonBase);
export const RatingFilterButton = withTheme(RatingFilterButtonObserver);
