/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Navigate } from 'react-router-dom';

export type Fallback = React.Component | React.FC | React.ReactElement | JSX.Element | any;

interface IProps {
  Fallback?: Fallback;
  redirect?: string;
  hideError?: boolean;
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
  error?: any;
  errorInfo?: any;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    /* eslint-disable-next-line no-console */
    console.log('error boundary', error, errorInfo);
    this.setState(prevState => ({
      ...prevState,
      hasError: error,
      errorInfo,
    }));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.Fallback) return this.props.Fallback;
      if (this.props.hideError) return null;
      if (this.props.redirect) return <Navigate to={ this.props.redirect } />;
      return (
        <div>
          <span>Something went wrong. Sorry about that.</span>
          <span>{ this.state.error && `error: ${ this.state.error.message.toLowerCase() }` }</span>
        </div>
      );
    }

    return this.props.children;
  }
}

// export const withPageErrorBoundary = (WrappedComponent: React.FunctionComponent, fallback) => {
//   class ErrorBoundaryWrapper extends ErrorBoundary {
//     constructor(props: IProps) {
//       super(props);
//     }

//     render() {
//       if (this.state.hasError) {
//         // Render fallback UI for errors
//         if (this.props.elementFallback) return this.props.elementFallback;
//         return this.props.fallback;
//       }

//       return <WrappedComponent />;
//     }
//   }

//   return ErrorBoundaryWrapper;
// };
