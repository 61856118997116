import styled from 'styled-components';
import { TextSection } from '../../../styles/components/containers';
import { MaxBoundaryContainer } from '../../../styles/components/containers';

export const IntroContainerWrapper = styled(TextSection)``;

export const IntroContainer = styled(MaxBoundaryContainer)`
  margin-top: 40px;
  max-width: 950px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-top: 60px;
  }

  .date-written {
    color: ${({ theme }) => theme.colors.midGray};
  }

  .intro-title {
    font-size: 34px;
  }

  .intro-paragraph {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin: 20px 0 50px;
  }
`;
