import { config, useSpring } from 'react-spring';
import React from 'react';
import { useToaster } from '../../contexts/toaster-store';

import { ToasterContainer } from './styles';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
}

const toasterFrom = { opacity: 0, transform: 'translate3d(-50%, -30px, 0)' };
const toasterTo = { opacity: 1, transform: 'translate3d(-50%, 0, 0)' };

const ToasterBase: React.FC<IProps> = ({
  className = '',
}) => {
  const toaster = useToaster();
  const toasterSpring = useSpring({
    config: config.gentle,
    from: toasterFrom,
    to: !!toaster.currentToast ? toasterTo : toasterFrom, 
  });

  if (!toaster.currentToast) return null;

  return (
    <ToasterContainer
      className={ `toaster ${className}` }
      style={ toasterSpring }
      type={ toaster.currentToast?.type }
    >
      { toaster.currentToast?.message }
    </ToasterContainer>
  );
};

export const Toaster = observer(ToasterBase);
