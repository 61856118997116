import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { Section } from '../../styles/components/containers';

export const CompanyContainer = styled(MainContainer)``;

export const CompanyInnerWrapper = styled.div``;

export const CompanyApplyForCardSectionContainer = styled(Section)`
  background: ${({ theme }) => theme.colors.lightGreen3};
  box-shadow: 0px 0px 25px 0px rgba(0, 28, 32, 0.05) inset;
  padding-top: 40px;
`;
