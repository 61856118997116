import styled from 'styled-components';
import { FlexHorizontalCenter } from '../../styles/styles';

export const Detail = styled.div`
  ${ FlexHorizontalCenter }

  svg {
    margin-right: 10px;
    stroke: ${({ theme }) => theme.colors.darkGray1};
  }
`;

export const JobPostingDetailsContainer = styled.div`
  ${ FlexHorizontalCenter }

  & > * {
    margin-right: 15px;
  }
`;
