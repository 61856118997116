import React, { useEffect } from 'react';
import { AmountContainer, MembershipCardContainer, OldAmountContainer } from './styles';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { membershipPriceAtom } from '../../models/membership/atom';
import { loadMembershipPrice } from '../../models/membership/api';
import { useAppData } from '../../contexts/app';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  kind?: 'light' | 'dark' | 'orange';
  type?: 'donegood' | 'karma';
}

export const MembershipCard: React.FC<IProps> = ({
  className,
  children,
  kind,
  type,
}) => {
  const [searchParams] = useSearchParams();
  const membershipPrice = useRecoilValue(membershipPriceAtom);
  const appData = useAppData();
  
  useEffect(() => {
    const membershipPromoCode = searchParams.get('membershipPromoCode');
    loadMembershipPrice(membershipPromoCode);
  }, []);

  const doneGoodPrice = (
    <div
      className='donegood-prices-container'
      aria-label='Previously $40 per year, now only $20 per year'
    >
      <OldAmountContainer>
        <p className='per-year old-price number' aria-hidden='true'>$40</p>
        <p className='per-year old-price' aria-hidden='true'>per year</p>
      </OldAmountContainer>
      <AmountContainer>
        <p className='per-year'>Only</p>
        <p className='per-year number'>$20</p>
        <p className='per-year'>per year!</p>
      </AmountContainer>
    </div>
  );

  const regularPrice = (
    <div className='regular-price-container'>
      <p className='amount'>
        { membershipPrice.price }
        <span className='period'> { appData.isDesktop ? 'per ' : '/' }year</span>
      </p>
    </div>
  );
  
  return (
    <MembershipCardContainer className={ className } kind={ kind } type={ type }>
      { type === 'donegood' ? doneGoodPrice : regularPrice }
      { children }
    </MembershipCardContainer>
  );
};
