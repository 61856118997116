import React from 'react';
import { ButtonAnchorContainer, ButtonContainer, ButtonKind, ButtonLinkContainer, ButtonSize } from './styles';

interface IProps {
  className?: string;
  disabled?: boolean;
  /**
   * if kind is set to one of the *WithIcon kinds,
   * you can add one of the following classes to the
   * svg so that it will inherit the existing theme
   * styles:
   * 
   * withStroke - if the svg's color is determined by its fill property
   * withFill - if the svg's color is determined by its fill property
   */
  kind?: ButtonKind;
  label?: string;
  makeFullOnMobile?: boolean;
  size?: ButtonSize;
}

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, IProps {
  onClick(): void;
  label?: string;
}

export interface IButtonLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement>, IProps {
  href: string;
}

const isExternalLink = (url: string) => url.slice(0, 8) === 'https://' || url.slice(0, 7) === 'http://';

export const ButtonLink: React.FC<IButtonLinkProps> = ({
  children,
  className = '',
  disabled,
  href, 
  label,
  makeFullOnMobile,
  kind = ButtonKind.Blank,
  size = ButtonSize.Regular,
  ...restProps
}) => isExternalLink(href)
  ? (
    <ButtonAnchorContainer
      className={ `${!!makeFullOnMobile ? 'mobile-full-width ' : ''}${ !!disabled ? 'disabled ' : '' }${className}` }
      href={ href }
      aria-label={ label }
      kind={ kind }
      size={ size }
      { ...restProps }
    >
      { children }
    </ButtonAnchorContainer>
  )
  : (
    <ButtonLinkContainer
      className={ `${!!makeFullOnMobile ? 'mobile-full-width ' : ''}${ !!disabled ? 'disabled ' : '' }${className}` }
      to={ href }
      kind={ kind }
      size={ size }
      aria-label={ label }
      { ...restProps }
    >
      { children }
    </ButtonLinkContainer>
  );

export const Button: React.FC<IButtonProps> = ({
  children,
  className = '',
  disabled,
  makeFullOnMobile,
  label,
  kind = ButtonKind.Primary,
  size = ButtonSize.Regular,
  onClick,
  ...restProps
}) => (
  <ButtonContainer
    className={ `${!!makeFullOnMobile ? 'mobile-full-width' : ''} ${className}` }
    disabled={ !!disabled }
    aria-label={ label }
    type='button'
    kind={ kind }
    onClick={ onClick }
    size={ size }
    { ...restProps }
  >
    { children }
  </ButtonContainer>
);
