import React from 'react';
import { PageDownContainer, PageDownWrapper } from './styles';
import { H1M, H5 } from '../../styles/components/header';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { Skyscape } from '../svgs/graphics/Skyscape';
import { useAnalytics } from '../../contexts/analytics-store';

interface IProps {
  className?: string;
}

export const PageDown: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();

  const onCtaClick = () => () => {
    analytics.fireEvent('OhNo_Page_GoToBlog_Click');
  };

  return (
    <PageDownContainer className={ className }>
      <PageDownWrapper>
        <Skyscape />
        <H1M className='center'>Oh no! This page is temporarily down.</H1M>
        <H5>This page is being worked on by the Karma Wallet team. While we work to make this experience better, feel free to checkout our blog articles to learn more about the world of sustainbility!</H5>
        <ButtonLink
          kind= { ButtonKind.Primary }
          href='https://karmawallet.io/blog/'
          onClick={ onCtaClick }
        >
          Go To Blog
        </ButtonLink>
      </PageDownWrapper>
    </PageDownContainer>
  );
};
