import React from 'react';
import { H1, H4, H6 } from '../../styles/components/header';
import { AppButtonsContainer, AppButtonsWrapper, GetStartedSectionContainer, HeaderContainer, InnerContainer, StepContainer, StepTextContainer, StepsContainer } from './styles';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import GetStartedSteps from '../../../public/assets/images/get-started-steps.png';
import { ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { ButtonKind } from '../Button/styles';
import AppStoreBadge from '../../../public/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GooglePlayBadge from '../../../public/assets/images/google-play-badge.svg';

interface IProps extends IThemeProps {
  className?: string;
}

interface IStep {
  description: string;
  header: string;
  stepNumber: number;
}

const steps: IStep[] = [
  {
    description: 'You can download on the Apple App Store or Google Play store.',
    header: 'Download the Karma Wallet app',
    stepNumber: 1,
  },
  {
    description: 'Simply add your 16-digit card number as well a 3-digit CVV.',
    header: 'Activate Your Card',
    stepNumber: 2,
  },
  {
    description: 'Choose 4 digits you can remember!',
    header: 'Set PIN',
    stepNumber: 3,
  },
  {
    description: 'Connect your bank account to transfer money with no fees.',
    header: 'Deposit Money',
    stepNumber: 4,
  },
];

const GetStartedSectionBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();

  const onAppleClick = () => {
    analytics.fireEvent('AppLandingPage_AppleStore_Click');
  };

  const onGoogleClick = () => {
    analytics.fireEvent('AppLandingPage_GoogleStore_Click');
  };

  const renderSteps = steps.map(step => (
    <StepContainer key={ step.stepNumber }>
      <H6 className='step-number'>{ step.stepNumber }</H6>
      <StepTextContainer>
        <H4>{ step.header }</H4>
        <p className={ `description-${step.stepNumber}` }>{ step.description }</p>
      </StepTextContainer>
    </StepContainer>
  ));

  const appButtons = (
    <AppButtonsWrapper>
      <AppButtonsContainer>
        <ButtonLink
          kind={ ButtonKind.Blank }
          target='_blank'
          onClick={ onAppleClick }
          href='https://apps.apple.com/app/karma-wallet/id6451440075'
        >
          <img src={ AppStoreBadge } alt='App Store Badge' />
        </ButtonLink>
        <ButtonLink
          kind={ ButtonKind.Blank }
          target='_blank'
          onClick={ onGoogleClick }
          href='https://play.google.com/store/apps/details?id=com.karmawalletnative.app'
        >
          <img className='google-button' src={ GooglePlayBadge } alt='Google Play Badge' />
        </ButtonLink>
      </AppButtonsContainer>
      <p className='disclaimer-text'>Standard text message and data rates, fees and charges may apply.</p>
    </AppButtonsWrapper>
  );

  return (
    <GetStartedSectionContainer backgroundColor={ theme.colors.white } className={ className }>
      <InnerContainer>
        <div className='content'>
          <HeaderContainer>
            <H6>NEXT STEPS</H6>
            <H1>Get Started</H1>
          </HeaderContainer>
          <img
            alt='Karma Wallet Activation Steps'
            className='get-started-steps-mobile'
            src={ GetStartedSteps }
          />
          <StepsContainer>
            { renderSteps }
            { appButtons }
          </StepsContainer>
        </div>
        <img
          alt='Karma Wallet Activation Steps'
          className='get-started-steps-desktop'
          src={ GetStartedSteps }
        />
      </InnerContainer>
    </GetStartedSectionContainer>
  );
};

export const GetStartedSection = withTheme(GetStartedSectionBase);
