import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';

export const OurImpactCertificationsContainer = styled(MaxBoundarySection)`
  .header {
    margin-top: 60px;
  }
  
  .title {
    margin-bottom: 15px;
  }
`;
