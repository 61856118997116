import React, { FC } from 'react';
import { SvgIcon } from '../../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const GaugeNeedle: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}gauge-graphic` }
    id={ id }
    height={ 110 }
    width={ 20 }
  >
    <path d='M8.91211 0.99613C8.96185 0.432361 9.43404 0 10 0V0C10.566 0 11.0381 0.432361 11.0879 0.99613L20 102L10 109.5L0 102L8.91211 0.99613Z' fill='#4C575E' />
  </SvgIcon>
);
