import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getRandomItemFromArray } from '../../lib/misc';
import { CompanyModel } from '../../models/companies';
import { CompanyImpactCategories } from '../CompanyImpactCategories';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { SectionHeader } from '../SectionHeader';
import { UNSDGsLegendWithText } from '../UNSDGsLegendWithText';
import { DeterminingCompanyImpactContainer, ContentContainer, ExampleDescription } from './styles';
import { CompanyTopSection } from '../CompanyTopSection';
import { CompanyRatingBreakdown } from '../../pages/Company/CompanyRatingBreakdown';

interface IProps {
  className?: string;
}

const companyIdsProd = ['621b992e5f87e75f5365a19d', '621b993a5f87e75f5365a815', '621b995c5f87e75f5365bc1d', '621b995f5f87e75f5365bded', '621b99605f87e75f5365be69', '621b99ca5f87e75f5365fc41', '621b99435f87e75f5365ad95', '621b99475f87e75f5365afad'];

export const DeterminingCompanyImpact: React.FC<IProps> = ({
  className = '',
}) => {
  const companyId = useMemo(() => getRandomItemFromArray(companyIdsProd), []);
  const [company, setCompany] = useState<CompanyModel>(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const valuesDescription = <ExampleDescription>*A company earns values when they have a data source directly correlated with a specific issue.</ExampleDescription>;
  const impactCategoriesDescription = <ExampleDescription>*We calculate sector average by taking the median rating of all companies in the sector.</ExampleDescription>;

  useEffect(() => {
    CompanyModel.loadCompany(companyId)
      .then(setCompany)
      .catch(() => {
        setErrorLoading(true);
      })
      .finally(() => {
        setDoneLoading(true);
      });
  }, [companyId]);

  const renderContent = useCallback(() => {
    if (!doneLoading) {
      return (
        <ContentContainer>
          <LoadingSpinner />
        </ContentContainer>
      );
    }

    if (errorLoading) {
      return (
        <ContentContainer className='error'>
          <div className='error-text'>Error loading determining impact example company</div>
        </ContentContainer>
      );
    }

    return (
      <ContentContainer>
        <CompanyTopSection
          className='section'
          company={ company }
          valuesDescription={ valuesDescription }
          isReadOnly
        />
        <CompanyRatingBreakdown
          className='section middle-section'
          company={ company }
        /> 
        <CompanyImpactCategories
          className='section'
          companies={ [company] }
          description={ impactCategoriesDescription }
        />
      </ContentContainer>
    );
  }, [company, doneLoading, errorLoading]);

  return (
    <DeterminingCompanyImpactContainer className={ className }>
      <SectionHeader 
        className='rating-a-company'
        headerText='Rating a Company'
        subText={ 'A company\'s data sources are mapped to 16 of the United Nations Sustainable Development Goals (UN SDGs). For each UN SDG, a company can earn a score of passed (1), partial (0.5), neutral (0), failed (-1), or no data (0). The sum of all UN SDGs determines a company\'s rating on a scale of -16 to 16.' }
      />
      <UNSDGsLegendWithText className='unsdg-legend' />
      { renderContent() }
    </DeterminingCompanyImpactContainer>
  );
};
