import { observer } from 'mobx-react';
import React from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { usePlaid } from '../../contexts/plaid';
import { Button, IButtonProps } from '../Button';

interface IProps extends IButtonProps {
  onPlaidSuccess(): void;
  onPlaidError?(): void;
}

const PlaidButtonBase: React.FC<IProps> = ({
  children,
  onClick,
  onPlaidError,
  onPlaidSuccess,
  ...restProps
}) => {
  const analytics = useAnalytics();
  const plaid = usePlaid();
  const errorMessages = useErrorMessages();

  const onButtonClick = async () => {
    try {
      await plaid.createLinkToken(null, onPlaidSuccess, onPlaidError);
      onClick();
    } catch(err: any) {
      analytics.fireEvent('Plaid_CreateLinkToken_Error');
      errorMessages.push({
        title: 'Create Link Token Error',
        message: err.message,
      });
    }
  };

  return (
    <Button
      { ...restProps }
      onClick={ onButtonClick }
    >
      { children }
    </Button>
  );
};

export const PlaidButton = observer(PlaidButtonBase);
