import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

export const HowItWorksSectionContainer = styled(Section)`
  padding: 65px 15px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 90px 15px;
  }
`;

export const HowItWorksInnerSectionContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 40px;
  }
`;

export const StepsContainer = styled.div`
  ${FlexRow}
  justify-content: center;
  gap: 10px;
  max-width: 345px;
  width: 100%;

  .dotted-line {
    display: inline;
    height: 1044px;
  }
  
  .mobile-dotted-line {
    height: 1444px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: unset;
  }
`;

export const StepsInnerContainer = styled.div`
  ${FlexCol}
  gap: 33px;
  position: relative;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 145px;
    width: 80%;
  }
`;

export const StepContainer = styled.div`
  ${FlexCol}
  gap: 40px;

  h5 {
    margin-bottom: 9px;
  }

  &.step-1 {
    gap: 24px;
    margin-top: 12px;
  }

  &.step-2 {
    gap: 24px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    justify-content: space-between;

    &.step-1 {
      gap: 40px;
      margin-top: unset;
    }

    &.step-2 {
      gap: 40px;
      margin-top: -28px;
    }
  }
`;

export const TextContainer = styled.div`
  ${FlexCol}
  align-items: start;
  max-width: 454px;
  text-align: left;
`;

export const StepNumber = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 12px;
  padding: 2px 8px;
`;

export const GraphicContainer = styled.div``;
