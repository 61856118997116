import React from 'react';
import { ICta } from '../../CTAs';
import { IModalProps } from '../Modal';
import { Body, ErrorModalContainer, ErrorModalCtas, Title } from './styles';

export interface IErrorModalProps extends IModalProps {
  title: string;
  body: string | JSX.Element;
  ctas: ICta[];
}

export const ErrorModal: React.FC<IErrorModalProps> = ({
  title,
  body,
  ctas,
  ...restProps
}) => (
  <ErrorModalContainer { ...restProps }>
    <Title>{ title }</Title>
    <Body>{ body }</Body>
    <ErrorModalCtas ctas={ ctas } />
  </ErrorModalContainer>
);
