import React, { useEffect, useState } from 'react';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';

import { DropdownContainer, Option, OptionsContainer, RegularTrigger } from './styles';

export enum DropdownType {
  Regular = 'regular',
  Form = 'form',
}

export interface IDropdownOption<T> {
  id: string;
  text: string;
  context: T;
  disabled?: boolean;
  isPlaceholder?: boolean;
}

interface IProps<T> {
  className?: string;
  options: IDropdownOption<T>[];
  selectedOption: IDropdownOption<T>;
  type?: DropdownType;
  onOptionClick(option: IDropdownOption<T>): void;
  onRenderOption?(option: IDropdownOption<T>): JSX.Element;
  onRenderTrigger?(selectedOption: IDropdownOption<T>): JSX.Element;
  id?: string;
}

export const Dropdown = <T extends (object | string)>({
  className = '',
  options,
  selectedOption,
  type = DropdownType.Regular,
  onOptionClick,
  onRenderOption,
  onRenderTrigger,
  id,
}: IProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window[isOpen ? 'addEventListener' : 'removeEventListener']('click', closeDropdown);

    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, [isOpen]);

  const onTriggerClick = () => {
    setIsOpen(!isOpen);
  };

  const onDropdownOptionClick = (option: IDropdownOption<T>) => () => {
    onOptionClick(option);
    setIsOpen(false);
  };

  const renderFormTrigger = () => <div>form dropdown...</div>;

  const renderOptions = () => {
    const _options = options.filter(option => !option.isPlaceholder).map(option => !!onRenderOption
      ? onRenderOption(option)
      : (
        <Option
          key={ option.id }
          className='dropdown-option'
          disabled={ option.disabled }
          onClick={ onDropdownOptionClick(option) }
        >
          { option.text }
        </Option>
      ),
    );

    return (
      <OptionsContainer className='dropdown-options-container'>
        { _options }
      </OptionsContainer>
    );
  };

  const renderRegularTrigger = () => (
    <RegularTrigger
      className={ `dropdown-trigger ${isOpen ? 'is-open' : ''}` }
      id='dropdown-trigger'
      onClick={ onTriggerClick }
    >
      <div className='selected-dropdown-option'>
        {
          !!onRenderTrigger
            ? onRenderTrigger(selectedOption)
            : selectedOption.text
        }
      </div>
      <div className='chevron-container'>
        <ChevronIcon
          direction={ isOpen ? ChevronDirection.Up : ChevronDirection.Down }
        />
      </div>
    </RegularTrigger>
  );

  const renderTrigger = () => {
    switch (type) {
      case DropdownType.Form: return renderFormTrigger();
      default: return renderRegularTrigger();
    }
  };

  return (
    <DropdownContainer
      id={ id }
      className={ className }
      onClick={ e => e.stopPropagation() }
    >
      { renderTrigger() }
      { isOpen && renderOptions() }
    </DropdownContainer>
  );
};
