import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const NewWindowIcon: FC<IProps> = ({ className = '', fill = 'black', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }new-window-icon` }
    id={ id }
    width={ 19 }
    height={ 19 }
    fill={ fill }
  >
    <path d='M2.79688 0.136719C1.70381 0.136719 0.796875 1.04366 0.796875 2.13672V16.1367C0.796875 17.2298 1.70381 18.1367 2.79688 18.1367H16.7969C17.8899 18.1367 18.7969 17.2298 18.7969 16.1367V9.13672H16.7969V16.1367H2.79688V2.13672H9.79688V0.136719H2.79688ZM11.7969 0.136719V2.13672H15.3828L6.08984 11.4297L7.50391 12.8438L16.7969 3.55078V7.13672H18.7969V0.136719H11.7969Z' fill={ fill } />
  </SvgIcon>
);
