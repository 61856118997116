import React, { useEffect } from 'react';
import { ResultInfoSection, PaymentPageContainer } from './styles';
import { observer } from 'mobx-react';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { ResultHeader } from '../../styles';
import { StripePaymentForm } from '../../../../components/StripePaymentForm';

interface IProps {
  clientSecret: string;
}

export const PaymentPageBase: React.FC<IProps> = ({
  clientSecret = '',
}) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.fireEvent('ApplyForCard_PaymentPage');
  }, []);

  const onComplete = () => {
    analytics.fireEvent('ApplyForCard_Stripe_Complete');
  };

  if (!clientSecret) return null;

  return (
    <PaymentPageContainer>
      <ResultInfoSection>
        <ResultHeader>You've been Approved!</ResultHeader>
      </ResultInfoSection>
      <StripePaymentForm 
        className='stripe-payment-form'
        clientSecret={ clientSecret }
        onComplete={ onComplete }
      />
    </PaymentPageContainer>
  ); 
};

export const PaymentPage = observer(PaymentPageBase);
