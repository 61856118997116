import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';

export const OurImpactReportsContainer = styled(MaxBoundarySection)`
  margin-bottom: 200px;
  
  .light {
    color: ${({ theme }) => theme.colors.darkGray3};
  }
`;
