import React from 'react';
import { AccountAvatarContainer } from './styles';
import { H2 } from '../../../styles/components/header';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
  userName: string;
}

export const AccountAvatarBase: React.FC<IProps> = ({
  className = '',
  userName,
}) => {
  const userNames = userName?.split(' ');
  const userFirstInitial = userNames?.[0][0];
  let userLastInitial = null;

  if (userNames?.length > 1) {
    userLastInitial = userNames?.[userNames.length - 1][0];
  }

  return (
    <AccountAvatarContainer className={ className }>
      <H2>{ !!userLastInitial ? userFirstInitial + userLastInitial : userFirstInitial }</H2>
    </AccountAvatarContainer>
  );
};

export const AccountAvatar = observer(AccountAvatarBase);
