import React from 'react';
import { observer } from 'mobx-react';
import { GreenwashingSection, HowItWorksContainer, TopSection } from './styles';
import { TrackYourImpactCTASection } from '../../components/TrackYourImpactCTASection';
import { TrustedSources } from '../../components/TrustedSources';
import { UtilizingUNSDGs } from '../../components/UtilizingUNSDGs';
import { HeaderSize, SectionHeader } from '../../components/SectionHeader';
import { DeterminingCompanyImpact } from '../../components/DeterminingCompanyImpact';
import { HowItWorksGraphic } from '../../components/svgs/graphics/HowItWorks';

interface IProps {
  className?: string;
}

const HowItWorksBase: React.FC<IProps> = ({
  className = '',
}) => (
  <HowItWorksContainer className={ className } title='Rating System'>
    <TopSection>
      <SectionHeader
        headerText={ 'Our Rating System' }
        subText={ 'We gather data from over thirty trusted data sources to evaluate companies for their social and environmental impact.' }
        headerSize={ HeaderSize.LARGE }
      />
      <HowItWorksGraphic className='how-it-works-graphic' />
    </TopSection>
    <TrustedSources />
    <UtilizingUNSDGs />
    <DeterminingCompanyImpact />
    <GreenwashingSection>
      <SectionHeader
        className='greenwashing-section'
        headerText={ 'Zero Greenwashing' }
        subText={ 'Greenwashing happens when companies want to seem more sustainable than they really are – but you deserve the truth. Karma Wallet checks company data against credible third party  sources so you can trust if a company is truly making social and environmental change. That\'s sustainability backed by science.' }
      />
    </GreenwashingSection>
    <TrackYourImpactCTASection />
  </HowItWorksContainer>
);

export const HowItWorks = observer(HowItWorksBase);
