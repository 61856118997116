import styled from 'styled-components';

interface INeedleProps {
  needleRotation: number;
}

export const IndustryReportGaugeContainer = styled.div<INeedleProps>`
position: relative;

.gauge {
  width: 100%;
}

.needle {
  width: 14px;
  height: auto;
  position: absolute;
  left: calc(50% - 7px);
  bottom: 19px;
  transform: rotate(${props => props.needleRotation}deg);
  transform-origin: 50% 89%;
}
`;
