import styled from 'styled-components';
import { FlexColCenter } from '../../../../styles/styles';
import { FormContainer } from '../styles';

export const AccountCompletionContainer = styled(FormContainer)`
  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexColCenter}
    min-height: 400px;
  }

  .cta {
    height: 45px;
    margin: 30px auto 0;
    min-width: 200px;
    position: relative;
    width: 100%;

    &:not(:disabled) {
      & > div {
        color: #fff;
      }
    }

    .spinner {
      height: 20px;
      width: 20px;
    }
  }

  .promo-legal {
    margin-top: 15px;
    text-align: center;
  }

  .redirect-link {
    font-weight: normal;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: underline;
  }
`;

export const Header = styled.div`
  margin: 20px 0 40px;
  text-align: center;

  & > H1 {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 15px;
  }

  .subtext {
    color: ${({ theme }) => theme.colors.grey2};
  };
`;

export const CreateAccountFormContainer = styled.div`
  margin-bottom: 20px;

  .create-account-header {
    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      margin-bottom: 10px;
    }
 }
`;

export const WelcomeContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  text-align: center;
`;

export const JoinGroupTextContainer = styled.div`
  ${FlexColCenter}
`;

export const GroupWelcomeContent = styled.div`
  .warning-text {
    color: ${({ theme }) => theme.colors.red};
    font-style: italic;

    strong {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  .success-text {
    color: ${({ theme }) => theme.colors.success};

    strong {
      color: ${({ theme }) => theme.colors.success};
    }
  }

  .join-group-ctas, .view-group-ctas {
    justify-content: center;
  }
`;

export const StandardWelcomeContent = styled.div`
  button {
    margin: 20px auto 0;
  }
`;
