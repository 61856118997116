import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useUserSession } from '../../contexts/user';
import { TransactionsModel } from '../../models/transactions';
import { H2 } from '../../styles/components/header';
import { DonationItem } from './DonationItem';
import { DonationItemSkeleton } from './DonationItem/skeleton';
import { DonationsContainer, DonationsList, DonationsWrapper, LoadingSpinnerContainer, NoDonations } from './styles';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';

interface IProps {
  className?: string;
}

const DonationsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const errorMessages = useErrorMessages();
  const user = useUserSession();
  const transactionsModel = useRef(new TransactionsModel({ limit: 10 })).current;

  useEffect(() => {
    user.checkIfHasTransactions()
      .catch(() => {
        analytics.fireEvent('Donations_HasTransactions_Error');
      })
      .finally(() => {
        if (!user.hasTransactions) navigate('/account');
      });
  }, []);

  const loadMore = (refresh?: boolean) => () => {
    transactionsModel.transactions[refresh ? 'refresh' : 'loadMore']({ onlyOffsets: true, includeNullCompanies: true })
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Donations',
          message: err.message,
        });
      });
  };
  
  useEffect(() => {
    if (!user.hasTransactions) return;
    loadMore(true)();
  }, [user.hasTransactions]);

  const renderDonations = () => {
    let donations: JSX.Element[] = [];

    if (transactionsModel.transactions.results.length > 0) {
      donations = transactionsModel.transactions.results.map(transaction => (
        <DonationItem
          key={ transaction._id }
          donation={ transaction }
        />
      ));
    } else if (transactionsModel.transactions.firstPageLoaded && !transactionsModel.transactions.busy) {
      donations.push(<NoDonations key='no-donations'>No donations found</NoDonations>);
    }

    if (transactionsModel.transactions.busy) {
      if (transactionsModel.transactions.firstPageLoaded) {
        donations.push((
          <LoadingSpinnerContainer key='loading-donations-spinner'>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        ));
      } else {
        const skeletons: JSX.Element[] = [];

        for (let i = 0; i < 5; i++) {
          skeletons.push(<DonationItemSkeleton key={ `donations-item-skele-${i}` } />);
        }

        donations = [...donations, ...skeletons];
      }
    }

    if (!transactionsModel.transactions.allResultsFetched && !transactionsModel.transactions.busy) {
      donations.push(<Waypoint key='waypoint' onEnter={ loadMore() } topOffset={ 200 } />);
    }

    return donations;
  };

  return (
    <DonationsContainer className={ className } title='Donations'>
      <DonationsWrapper>
        <H2>Past Donations</H2>
        <DonationsList>
          { renderDonations() }
        </DonationsList>
      </DonationsWrapper>
    </DonationsContainer>
  );
};

export const Donations = observer(DonationsBase);
