import React from 'react';
import { SvgIcon } from '../../SvgIcon';

export const IconSize = 20;

export const KeyIcon = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}key-icon icon` }
    id={ id }
    height={ IconSize }
    width={ IconSize }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M8.5 7.5C8.5 5.29086 10.2909 3.5 12.5 3.5C14.7091 3.5 16.5 5.29086 16.5 7.5C16.5 9.70914 14.7091 11.5 12.5 11.5C12.0942 11.5 11.7043 11.4399 11.3376 11.3288C10.9846 11.2218 10.6013 11.3178 10.3405 11.5787L10.1262 11.7929L8.75245 13.1667H8.33333C7.37465 13.1667 6.58783 13.9025 6.50687 14.8402C5.62412 14.9164 4.92027 15.6182 4.84081 16.5H3.5V14.5809L8.20711 9.87377L8.42133 9.65955C8.68218 9.3987 8.77824 9.01542 8.67123 8.66238C8.56011 8.29575 8.5 7.9058 8.5 7.5ZM12.5 1.5C9.18629 1.5 6.5 4.18629 6.5 7.5C6.5 7.89034 6.53741 8.27271 6.60901 8.64344L2.03697 13.2155C1.69315 13.5593 1.5 14.0256 1.5 14.5118V16.6667C1.5 17.6792 2.32081 18.5 3.33333 18.5H5C5.95868 18.5 6.7455 17.7642 6.82646 16.8265C7.70921 16.7502 8.41306 16.0485 8.49253 15.1667H8.82149C9.30772 15.1667 9.77403 14.9735 10.1179 14.6297L11.3566 13.391C11.7273 13.4626 12.1097 13.5 12.5 13.5C15.8137 13.5 18.5 10.8137 18.5 7.5C18.5 4.18629 15.8137 1.5 12.5 1.5ZM12.5 4.83333C11.9477 4.83333 11.5 5.28104 11.5 5.83333C11.5 6.38561 11.9477 6.83333 12.5 6.83333C12.8682 6.83333 13.1667 7.13181 13.1667 7.49999C13.1667 8.05228 13.6144 8.49999 14.1667 8.49999C14.719 8.49999 15.1667 8.05228 15.1667 7.49999C15.1667 6.02724 13.9728 4.83333 12.5 4.83333Z' />
  </SvgIcon>
);
