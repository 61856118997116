import React, { useMemo } from 'react';
import { IThemeProps } from '../../../styles/themes';
import { withTheme } from 'styled-components';
import { BetterFutureInnerContainer, BetterFutureSectionContainer, BetterFutureStepCard, BetterFutureStepCardsContainer, BetterFutureStepCardsWrapper, StepsWrapper } from './styles';
import { H2M } from '../../../styles/components/header';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
}

interface IHowItWorksSteps {
  title: string;
  description: string;
  stepNumber: number;
}

const HowItWorksForEmployees: IHowItWorksSteps[] = [
  {
    title: 'Direct Deposit',
    description: 'Employees allocate a portion of their paycheck to be deposited onto the Karma Wallet Card.',
    stepNumber: 1,
  },
  {
    title: 'Spend Anywhere',
    description: 'Employees can use the card anywhere Visa is accepted - making our card more accessible than typical gift card options.',
    stepNumber: 2,
  },
  {
    title: 'Sustainable Impact',
    description: 'We donate to nonprofits for your team\'s everyday spending. When they buy gas, we donate to reforestation. When they dine out, we donate a meal.',
    stepNumber: 3,
  },
];

const HowItWorksForEmployers: IHowItWorksSteps[] = [
  {
    title: 'Reloadable Card',
    description: 'You can reload the card at major employee milestones like annual bonuses or holiday gifting. Your employees can choose to reload the card with their own money, too.',
    stepNumber: 1,
  },
  {
    title: 'Simple Pricing',
    description: 'Typical prepaid gift cards, including Visa, Mastercard, and Amazon, cost up to $6 each. We charge a single fee once per year.',
    stepNumber: 2,
  },
  {
    title: 'ESG Goals',
    description: 'You\'ll see your teams donation and impact data each quarter. Empower your employees while supporting your company\'s ESG goals.',
    stepNumber: 3,
  },
];

const BetterFutureSectionBase: React.FC<IProps> = ({
  className,
  theme,
}) => {
  const analytics = useAnalytics();

  const onLearnMoreClick = () => {
    analytics.fireEvent('EmployerPage_LearnMore_Click');
  };
  
  const employeesSteps = useMemo(() => HowItWorksForEmployees.map((step) => (
    <BetterFutureStepCard color={ theme.colors.primary } key={ step.stepNumber }>
      <div className='step-number'>{ step.stepNumber }</div>
      <div className='step-title'>{ step.title }</div>
      <div className='step-description'>{ step.description }</div>
    </BetterFutureStepCard>
  )), [HowItWorksForEmployees]);

  const employersSteps = useMemo(() => HowItWorksForEmployers.map((step) => (
    <BetterFutureStepCard color={ theme.colors.secondary } key={ step.stepNumber }>
      <div className='step-number'>{ step.stepNumber }</div>
      <div className='step-title'>{ step.title }</div>
      <div className='step-description'>{ step.description }</div>
    </BetterFutureStepCard>
  )), [HowItWorksForEmployers]);

  const howItWorksSteps = useMemo(() => (
    <StepsWrapper>
      <span>Here's how it works:</span>
      <BetterFutureStepCardsWrapper>
        <span>For Employees</span>
        <BetterFutureStepCardsContainer key='employees-steps'>
          { employeesSteps }
        </BetterFutureStepCardsContainer>
      </BetterFutureStepCardsWrapper>
      <BetterFutureStepCardsWrapper>
        <span>For Employers</span>
        <BetterFutureStepCardsContainer key='employers-steps'>
          { employersSteps }
        </BetterFutureStepCardsContainer>
      </BetterFutureStepCardsWrapper>
    </StepsWrapper>
  ), [employeesSteps, employersSteps]);

  return (
    <BetterFutureSectionContainer className={ className }>
      <BetterFutureInnerContainer>
        <H2M>Give your employees the gift of a better future.</H2M>
        <p>A prepaid reloadable debit card that gives back to people and planet.</p>
        { howItWorksSteps }
        <ButtonLink
          onClick={ onLearnMoreClick }
          href='/app'
          kind={ ButtonKind.Primary }
        >
          Learn More
        </ButtonLink>
      </BetterFutureInnerContainer>
    </BetterFutureSectionContainer>
  );
};

export const BetterFutureSection = withTheme(BetterFutureSectionBase);
