import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../contexts/analytics-store';
import { usePromos } from '../../../contexts/promos';
import { useSignInModal } from '../../../contexts/sign-in';
import { useUserSession } from '../../../contexts/user';
import { SignInMode } from '../../../models/sign-in';
import { H1 } from '../../../styles/components/header';
import { IThemeProps } from '../../../styles/themes';
import { Button } from '../../Button';
import { ButtonKind } from '../../Button/styles';
import { PromoHomeModalContainer } from './styles';
import { useSearchParams } from 'react-router-dom';

interface IProps extends IThemeProps {
  className?: string;
}

const PromoCreateAccountHomeModalBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const promosContext = usePromos();
  const analytics = useAnalytics();
  const userModel = useUserSession();
  const signInModal = useSignInModal();
  const [searchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const hideModal = useMemo(() => !!searchParams.get('createaccount') || !!searchParams.get('verifyaccount'), [searchParams]);

  useEffect(() => {
    if (userModel.initialized
      && !userModel.isLoggedIn
      && promosContext?.activeCreateAccountPromo
      && !localStorage.getItem(`${promosContext?.activeCreateAccountPromo?.name}`)
      && !hideModal
    ) {
      localStorage.setItem(`${promosContext.activeCreateAccountPromo.name}`, 'true');
      setModalOpen(true);
    }
  }, [userModel.initialized, userModel.isLoggedIn, promosContext?.activeCreateAccountPromo]);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
    analytics.fireEvent('Promo_Modal_Close', `${promosContext?.activeCreateAccountPromo?.name}`);
  }, [promosContext.activeCreateAccountPromo]);

  const onCTAClick = useCallback(() => {
    setModalOpen(false);
    signInModal.open(SignInMode.CREATE_ACCOUNT);
    analytics.fireEvent('Promo_Modal_CTA_Click', `${promosContext?.activeCreateAccountPromo?.name}`);
  }, [promosContext?.activeCreateAccountPromo]);

  if (userModel.authenticating || (userModel.initialized && userModel.isLoggedIn) || !promosContext.activeCreateAccountPromo) return null;

  return (
    <PromoHomeModalContainer 
      className={ className }
      onClose={ onModalClose }
      isOpen={ modalOpen }
      backgroundImage={ promosContext?.activeCreateAccountPromo?.imageUrl }
      closeIconColor={ theme.colors.white }
      modalTheme='dark'
    >
      <H1 className='promo-amount'>${ promosContext?.activeCreateAccountPromo?.amount }</H1>
      <H1>{ promosContext?.activeCreateAccountPromo?.headerText }</H1>
      <div>{ promosContext?.activeCreateAccountPromo?.promoText }</div>
      <Button
        kind={ ButtonKind.Primary }
        onClick={ onCTAClick }
        className='promo-cta'
      >
        Get Started
      </Button>
    </PromoHomeModalContainer>
  );
};

const PromoCreateAccountHomeModalObserver = observer(PromoCreateAccountHomeModalBase);
export const PromoCreateAccountHomeModal = withTheme(PromoCreateAccountHomeModalObserver);
