import { observer } from 'mobx-react';
import React from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { ButtonKind } from '../../Button/styles';
import { ErrorModal } from '../ErrorModal';

const GlobalErrorModalBase: React.FC = () => {
  const errorMessages = useErrorMessages();

  const onClose = () => errorMessages.resolveCurrentMessage();

  return (
    <ErrorModal
      title={ errorMessages.currentErrorMessage?.title ?? 'Uh oh...' }
      body={ errorMessages.currentErrorMessage?.message }
      isOpen={ !!errorMessages.currentErrorMessage }
      ctas={ [
        {
          id: 'got-it-cta',
          text: 'Got it',
          kind: ButtonKind.SecondaryGhost,
          onClick: onClose,
        },
      ] }
      onClose={ onClose }
    />
  );
};

export const GlobalErrorModal = observer(GlobalErrorModalBase);
