export enum SentimentSubcategory {
  Summary = 'Summary',
  Biodiversity = 'Biodiversity & Environmental Conservation',
  ClimateImpact = 'Climate Impact',
  Community = 'Community Development & Philanthropy',
  Diversity = 'Diversity, Equity, & Inclusion',
  Governance = 'Governance',
  LaborPractices = 'Labor Practices',
  WasteManagement = 'Waste Management',
}

export interface ISubcategory {
  summary: string;
  value: string;
  category: string;
}

export interface ISentimentData {
  busy: boolean;
  data: ISentiment | null;
  error: boolean;
  errorMessage: string;
}

export interface ISentimentTimePeriod {
  startDate: string;
  endDate: string;
}

export interface ISentimentOverall {
  summary: string;
  value: string;
}

export interface ISentiment {
  createdOn: string;
  timePeriod: ISentimentTimePeriod;
  articles: ISentimentSourceArticle[];
  [SentimentSubcategory.Summary]: ISubcategory;
  [SentimentSubcategory.Biodiversity]: ISubcategory;
  [SentimentSubcategory.ClimateImpact]: ISubcategory;
  [SentimentSubcategory.Community]: ISubcategory;
  [SentimentSubcategory.Diversity]: ISubcategory;
  [SentimentSubcategory.Governance]: ISubcategory;
  [SentimentSubcategory.LaborPractices]: ISubcategory;
  [SentimentSubcategory.WasteManagement]: ISubcategory;
  overall: ISentimentOverall
}

export interface ISentimentSourceArticle {
  metadata: IArticleMetadata;
  sentiment: IArticleSentiment;
  date: string;
  _id: string;
}

export interface IArticleStatus {
  included: boolean;
}

export interface IArticleMetadata {
  title: string;
  source: string;
  url: string;
}

export interface IArticleSentiment {
  overall: IArticleOverallSentiment;
}

export interface IArticleOverallSentiment {
  summary: string;
  value: string;
}
