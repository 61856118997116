import styled from 'styled-components';
import { NoScrollBar } from '../../styles/styles';

export const MarkdownContainer = styled.div`
  ${ NoScrollBar }
  overflow: auto;
  
  & > * {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  li {
    line-height: 1.5em;
  }
`;
