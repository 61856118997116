import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';

type PrivateFields = '_busy';

interface IContactUsEmailRequest{
  id?: string;
  firstName: string;
  lastName?: string;
  email: string;
  phone?: string;
  topic: string;
  message: string;
}

export class ContactUsModel extends BaseModel {
  private _busy = false;

  constructor () {
    super();
    makeObservable<ContactUsModel, PrivateFields>(this, {
      _busy: observable,
    });
  }

  get busy() { return this._busy; }

  public submitContactUs = async ( data: IContactUsEmailRequest ) => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IContactUsEmailRequest>({
      path: '/contact-us',
      method: 'POST',
      data: data,
    });

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });

      return result;
    } else {
      runInAction(() => {
        this._busy = false;
      });
      
      throw new Error(result.error);
    }
  };
}
