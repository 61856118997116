import styled from 'styled-components';

interface IRatingFilterButtonProps {
  selected: boolean,
}

export const RatingFilterButtonContainer = styled.div<IRatingFilterButtonProps>`
  background: ${({ selected, theme }) => selected ? theme.colors.dark : 'transparent'};
  height: 100px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    min-width: 219px;
  }

  button {
    align-items: flex-start;
    border-radius: 0;
    color: ${({ selected, theme }) => selected ? theme.colors.white : theme.colors.dark};
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 30px;
    width: 100%;
  }

  &.disabled {
    display: none !important;
  }

  &:first-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }

  &:last-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    &:first-of-type {
      border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
      border-bottom: none;
    }

    &:last-of-type {
      border-left: 1px solid ${({ theme }) => theme.colors.lightGray};
      border-top: none;
    }
  }
`;
