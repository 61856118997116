import dayjs from 'dayjs';
import { z } from 'zod';

export const isValidEmailFormat = (email: string) => {
  // checks that there is only one @ symbol
  if (email.split('@').length !== 2) return false;
  // check that there are no spaces
  if (email.indexOf(' ') !== -1) return false;
  // check that it matches an email otherwise
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regex.test(email);
};

export const isValidDollarAmount = (amount: string) => {
  const regex = /^[0-9]+(\.[0-9]{2})?$/;
  return regex.test(amount);
};

// removes anything that is not a number as well as instances where starting with 0
export const removeInvalidCharacters = (str: string) => {
  if (str.length === 1 && str === '0') return '';
  return str.replace(/[^0-9.]/g, '');
};

export const checkIsValidDollarAmountAndFormat = (amount: string) => ({
  isValid: isValidDollarAmount(amount),
  formattedAmount: removeInvalidCharacters(amount),
});

export const validateEmail = (email: string) => {
  const emailSchema = z.string().email();
  const result = emailSchema.safeParse(email);
  if (result.success) return true;
  else return false;
};

export const validateSSN = (ssn: string) => {
  const ssnSchema = z.string().regex(/^\d{3}-?\d{2}-?\d{4}$/);
  const result = ssnSchema.safeParse(ssn);
  if (result.success) {
    return {
      isValid: true,
      message: '',
    };
  }
  
  if (result.success === false) {
    return {
      isValid: false,
      message: result.error.errors[0].message,
    };
  }
};

export const validateIsAName = (name: string) => {
  const nameSchema = z.string().regex(/^[a-z ,.'-]+$/i);
  const result = nameSchema.safeParse(name);
  if (result.success) return true;
  else return false;
};

export const validatePhoneNumber = (phone: string) => {
  const phoneSchema = z.string().regex(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i);
  const result = phoneSchema.safeParse(phone);
  if (result.success) {
    return {
      isValid: true,
      message: '',
    }; 
  } 

  if (result.success === false) {
    return {
      isValid: false,
      message: 'Enter a valid phone number',
    }; 
  }
};

export const validateDate = (date: string) => {
  const formatToDate = dayjs(date).toDate();
  const today = dayjs();
  const min = today.subtract(100, 'year').toDate();
  const max = today.subtract(18, 'year').toDate();

  const dateSchema = z.date()
    .min(min, { message: 'Birth year too far back' })
    .max(max, { message: 'You must be 18 or older' });
  
  const result = dateSchema.safeParse(formatToDate);

  if (result.success) {
    return {
      isValid: true,
      message: '',
    }; 
  } 

  if (result.success === false) {
    return {
      isValid: false,
      message: result.error.errors[0].message,
    }; 
  }
};

export const validateAddress = (address: string) => {
  const addressSchema = z.string().min(5);
  const result = addressSchema.safeParse(address);
  if (result.success) return true;
  else return false;
};

export const validateCity = (city: string) => {
  const citySchema = z.string().min(2);
  const result = citySchema.safeParse(city);
  if (result.success) return true;
  else return false;
};

export const validateZipcode = (zipcode: string) => {
  const zipcodeSchema = z.string().regex(/^\d{5}$/);
  const result = zipcodeSchema.safeParse(zipcode);
  if (result.success) {
    return {
      isValid: true,
      message: '',
    };
  } 

  if (result.success === false) {
    return {
      isValid: false,
      message: result.error.errors[0].message,
    };
  }
};
