import styled, { keyframes } from 'styled-components';
import { AbsoluteCenter } from '../../../styles/styles';

export enum SpinnerSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const smallSize = 20;
const mediumSize = 40;
const largeSize = 80;

interface IProps {
  size?: SpinnerSize;
  color?: string;
}

export const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const LoadingSpinnerContainer = styled.div<IProps>`
  ${AbsoluteCenter}
  animation: ${rotate} 1s linear infinite;
  transform-origin: center;

  .spinner {
    ${AbsoluteCenter}
    border-radius: 50%;

    ${({ size }) => {
    switch (size) {
      case SpinnerSize.Small: return `
            width: ${smallSize}px;
            height: ${smallSize}px;
          `;
      case SpinnerSize.Large: return `
            width: ${largeSize}px;
            height: ${largeSize}px;
          `;
      default: return `
            width: ${mediumSize}px;
            height: ${mediumSize}px;
          `;
    }
  }
}

    ${({ color, theme }) => {
    const _color = color || theme.colors.darkGray1;

    return `
        border-top: 3px solid ${_color};
        border-left: 2px solid ${_color};
        border-bottom: 1px solid ${_color};
        border-right: 1px solid transparent;
      `;
  }
}
  }
`;
