import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
  ariaHidden?: boolean;
}

export const ImpactReportIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
  ariaHidden,
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}impact-report-icon` }
    id={ id }
    height={ 21 }
    width={ 15 }
    fill={ fill }
    aria-hidden={ ariaHidden }
  >
    <path d='M3.125 15.0713C2.95924 15.0713 2.80027 15.0054 2.68306 14.8882C2.56585 14.771 2.5 14.612 2.5 14.4463V11.9463C2.5 11.7805 2.56585 11.6216 2.68306 11.5043C2.80027 11.3871 2.95924 11.3213 3.125 11.3213H4.375C4.54076 11.3213 4.69973 11.3871 4.81694 11.5043C4.93415 11.6216 5 11.7805 5 11.9463V14.4463C5 14.612 4.93415 14.771 4.81694 14.8882C4.69973 15.0054 4.54076 15.0713 4.375 15.0713H3.125ZM6.875 15.0713C6.70924 15.0713 6.55027 15.0054 6.43306 14.8882C6.31585 14.771 6.25 14.612 6.25 14.4463V9.44629C6.25 9.28053 6.31585 9.12156 6.43306 9.00435C6.55027 8.88714 6.70924 8.82129 6.875 8.82129H8.125C8.29076 8.82129 8.44973 8.88714 8.56694 9.00435C8.68415 9.12156 8.75 9.28053 8.75 9.44629V14.4463C8.75 14.612 8.68415 14.771 8.56694 14.8882C8.44973 15.0054 8.29076 15.0713 8.125 15.0713H6.875ZM10.625 15.0713C10.4592 15.0713 10.3003 15.0054 10.1831 14.8882C10.0658 14.771 10 14.612 10 14.4463V6.94629C10 6.78053 10.0658 6.62156 10.1831 6.50435C10.3003 6.38714 10.4592 6.32129 10.625 6.32129H11.875C12.0408 6.32129 12.1997 6.38714 12.3169 6.50435C12.4342 6.62156 12.5 6.78053 12.5 6.94629V14.4463C12.5 14.612 12.4342 14.771 12.3169 14.8882C12.1997 15.0054 12.0408 15.0713 11.875 15.0713H10.625Z' fill={ fill } />
    <path d='M2.5 0.0712891C1.83696 0.0712891 1.20107 0.334681 0.732233 0.803522C0.263392 1.27236 0 1.90825 0 2.57129V17.5713C0 18.2343 0.263392 18.8702 0.732233 19.3391C1.20107 19.8079 1.83696 20.0713 2.5 20.0713H12.5C13.163 20.0713 13.7989 19.8079 14.2678 19.3391C14.7366 18.8702 15 18.2343 15 17.5713V2.57129C15 1.90825 14.7366 1.27236 14.2678 0.803522C13.7989 0.334681 13.163 0.0712891 12.5 0.0712891L2.5 0.0712891ZM2.5 1.32129H12.5C12.8315 1.32129 13.1495 1.45299 13.3839 1.68741C13.6183 1.92183 13.75 2.23977 13.75 2.57129V17.5713C13.75 17.9028 13.6183 18.2208 13.3839 18.4552C13.1495 18.6896 12.8315 18.8213 12.5 18.8213H2.5C2.16848 18.8213 1.85054 18.6896 1.61612 18.4552C1.3817 18.2208 1.25 17.9028 1.25 17.5713V2.57129C1.25 2.23977 1.3817 1.92183 1.61612 1.68741C1.85054 1.45299 2.16848 1.32129 2.5 1.32129Z' fill={ fill } />
  </SvgIcon>
);
