import React from 'react';
import { Button, ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { PlaidButton } from '../PlaidButton';

import { CTAsContainer } from './styles';

export enum CtaType {
  Link = 'link',
  Button = 'button',
  Plaid = 'plaid',
  Submit = 'submit'
}

export interface ICta {
  className?: string;
  disabled?: boolean;
  id: string;
  text: string | React.ReactNode;
  kind?: ButtonKind;
  ctaType?: CtaType;
  url?: string;
  openNewTab?: boolean;
  onClick?: (e?: React.SyntheticEvent) => void | Promise<void>;
  onPlaidSuccess?(): (any | Promise<any>);
}

interface IProps {
  className?: string;
  ctas: ICta[];
}

export const CTAs: React.FC<IProps> = ({
  className = '',
  ctas,
}) => {
  const renderCtas = () => ctas.map(cta => {
    if (cta.ctaType === CtaType.Link) {
      return (
        <ButtonLink
          key={ cta.id }
          className={ cta.className }
          disabled={ cta.disabled }
          kind={ cta.kind }
          href={ cta.url }
          target={ cta.openNewTab ? '_blank' : null }
          onClick={ cta.onClick }
        >
          { cta.text }
        </ButtonLink>
      );
    }

    if (cta.ctaType === CtaType.Submit) {
      return (
        <Button
          key={ cta.id }
          className={ cta.className }
          disabled={ cta.disabled }
          kind={ cta.kind }
          onClick={ cta.onClick }
          type={ cta.ctaType }
        >
          { cta.text }
        </Button>
      );
    }

    if (cta.ctaType === CtaType.Plaid) {
      return (
        <PlaidButton
          key={ cta.id }
          className={ cta.className }
          kind={ cta.kind }
          onClick={ cta.onClick }
          onPlaidSuccess={ cta.onPlaidSuccess }
        >
          { cta.text }
        </PlaidButton>
      );
    }

    return (
      <Button
        key={ cta.id }
        className={ cta.className }
        disabled={ cta.disabled }
        kind={ cta.kind }
        onClick={ cta.onClick }
      >
        { cta.text }
      </Button>
    );
  });

  return (
    <CTAsContainer className={ className }>
      { renderCtas() }
    </CTAsContainer>
  );
};
