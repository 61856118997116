import React, { createContext } from 'react';
import { ErrorMessagesModel } from '../models/errorMessages';

export const ErrorMessagesContext = createContext<ErrorMessagesModel>(null);

export const useErrorMessages = () => React.useContext(ErrorMessagesContext);

export const ErrorMessagesStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ErrorMessagesContext.Provider value={ new ErrorMessagesModel() }>
    { children }
  </ErrorMessagesContext.Provider>
);
