import React from 'react';
import { GoodBadTextContainer } from './styles';
import { H1M } from '../../styles/components/header';

interface IProps {
  className?: string;
  outlineFill?: string;
}

export const GoodBadText: React.FC<IProps> = ({
  className = '',
  outlineFill,
}) => (
  <GoodBadTextContainer className={ className } outlineFill={ outlineFill }>
    <H1M>The Good</H1M>
    <div className='the-bad-wrapper'>
      <H1M>&</H1M><H1M className='the-bad-text'>The Bad</H1M>
    </div>
  </GoodBadTextContainer>
);
