import React, { useCallback, useRef } from 'react';
import ReactSlickSlider, { Settings as ReactSlickSettings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselContainer } from './styles';
import { ArrowButton, ArrowButtonKind } from '../ArrowButton';
import { BlankArrowButton } from '../BlankArrowButton';

interface ISliderProps extends ReactSlickSlider {
  innerSlider: {
    state: {
      currentSlide: number;
    }
  }
}

export enum CarouselArrowsKind {
  Default = 'default',
  Blank = 'blank',
}

interface IProps {
  className?: string;
  settings?: ReactSlickSettings;
  children: React.ReactNode;
  arrowsKind?: CarouselArrowsKind;
}

const NextArrow: React.FC<any> = (props: any) => (
  <ArrowButton
    { ...props }
    kind={ ArrowButtonKind.Next }
    disabled={ props.className.includes('slick-disabled') }
  />
);
const PrevArrow: React.FC<any> = (props: any) => (
  <ArrowButton
    { ...props }
    kind={ ArrowButtonKind.Previous }
    disabled={ props.className.includes('slick-disabled') }
  />
);

const BlankNextArrow: React.FC<any> = (props: any) => (
  <BlankArrowButton
    { ...props }
    kind={ ArrowButtonKind.Next }
    disabled={ props.className.includes('slick-disabled') }
  />
);

const BlankPrevArrow: React.FC<any> = (props: any) => (
  <BlankArrowButton
    { ...props }
    kind={ ArrowButtonKind.Previous }
    disabled={ props.className.includes('slick-disabled') }
  />
);

const defaultSettings: ReactSlickSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
};

export const Carousel: React.FC<IProps> = ({
  children,
  className = '',
  settings = {},
  arrowsKind,
}) => {
  const carouselRef = useRef<ISliderProps>(null);

  const getSettings = useCallback(() => {
    const __settings = { ...defaultSettings, ...settings };
    if (arrowsKind === CarouselArrowsKind.Blank) {
      __settings.nextArrow = <BlankNextArrow />;
      __settings.prevArrow = <BlankPrevArrow />;
    } else {
      __settings.nextArrow = <NextArrow />;
      __settings.prevArrow = <PrevArrow />;
    }
    return __settings;
  }, [settings]);

  return (
    <CarouselContainer className={ className }>
      <ReactSlickSlider ref={ carouselRef } { ...getSettings() }>
        { children }
      </ReactSlickSlider>
    </CarouselContainer>
  );
};
