import React, { FC } from 'react';

import { SvgIcon } from '../../SvgIcon';
interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  checkMarkColor?: string;
}

export const DoneGoodLogo: FC<IProps> = ({
  className = '',
  fill = 'white',
  checkMarkColor = 'white',
}) => (
  <SvgIcon
    className={ className }
    id='done-good-logo'
    width={ 230 }
    height={ 44 }
    fill={ fill }>
    <path d='M55.0137 16.4926C55.0137 12.792 55.0246 9.09154 55.0028 5.38831C55.0001 4.74249 55.1064 4.38007 55.8803 4.39369C59.6571 4.46999 63.453 4.28742 67.208 4.60897C71.9848 5.01499 75.7862 7.26853 77.413 11.9909C79.048 16.7378 78.4921 21.2721 74.8215 25.0271C72.5789 27.3216 69.6822 28.3543 66.5322 28.4742C62.9952 28.6105 59.45 28.5097 55.9103 28.5505C55.1718 28.5587 54.9947 28.2808 55.0001 27.5941C55.0274 23.8936 55.0137 20.1931 55.0137 16.4898V16.4926ZM61.0931 16.4926C61.0931 18.4246 61.0877 20.3566 61.0959 22.2886C61.0959 22.7573 61.0359 23.3077 61.7689 23.2859C63.4639 23.2314 65.1915 23.3567 66.8456 23.0624C70.2681 22.4575 72.3118 19.2775 71.9358 15.4898C71.5979 12.1081 69.1155 9.82183 65.6684 9.74008C64.513 9.71283 63.3549 9.77551 62.2022 9.71556C61.3684 9.67196 61.055 9.939 61.0795 10.7974C61.1286 12.6939 61.0959 14.5932 61.0959 16.4926H61.0931Z' fill={ fill } />
    <path d='M166.13 9.84908C164.448 10.7238 162.83 11.5658 161.236 12.3942C158.274 9.24414 155.81 8.59014 152.81 10.108C150.061 11.4977 148.581 14.7431 149.227 17.9668C149.886 21.2531 152.23 23.3704 155.532 23.6565C157.998 23.8718 160.473 22.4248 161.339 20.1522C160.851 20.1113 160.405 20.0486 159.958 20.0432C158.631 20.0268 157.301 20.0704 155.977 20.0078C155.68 19.9941 155.159 19.6535 155.151 19.4437C155.08 17.9286 155.113 16.4081 155.113 14.8194C159.464 14.8194 163.705 14.8194 167.942 14.8194C168.55 18.8415 166.53 26.1689 160.059 28.2862C153.143 30.5507 145.951 27.4088 143.651 21.016C141.262 14.3725 144.3 7.36663 150.679 4.98229C157.007 2.61701 163.601 4.69889 166.127 9.84635L166.13 9.84908Z' fill={ fill } />
    <path d='M224.21 13.0918V6.88159C224.21 5.23208 225.054 4.40732 226.741 4.40732H229.545V28.4661H224.281C224.237 27.8993 224.193 27.3733 224.144 26.7275C223.888 26.8801 223.665 26.9537 223.523 27.1036C220.466 30.2918 213.931 29.586 211.961 24.1252C210.925 21.2476 210.92 18.3237 212.01 15.4598C214.005 10.2251 220.477 9.24686 223.681 12.7457C223.763 12.8356 223.891 12.8847 224.207 13.0918H224.21ZM224.207 19.8797C224.207 19.1685 224.253 18.4518 224.188 17.746C224.152 17.3754 224.014 16.934 223.763 16.6751C222.646 15.5143 220.594 15.1573 219.125 15.795C217.55 16.4789 216.681 18.0867 216.768 20.1549C216.847 21.9943 217.877 23.5148 219.422 24.0107C220.812 24.4576 222.073 24.1197 223.308 23.3949C224.441 22.7273 224.182 21.6836 224.204 20.6945C224.21 20.4247 224.204 20.1522 224.204 19.8797H224.207Z' fill={ fill } />
    <path d='M177.722 28.9729C175.051 28.9266 172.067 27.6131 170.337 24.305C168.618 21.016 168.743 17.667 170.825 14.5551C172.779 11.6312 175.754 10.6121 179.136 10.702C183.61 10.8192 187.101 13.6123 187.959 17.6398C189.289 23.8718 185.12 28.9702 177.719 28.9756L177.722 28.9729ZM174.836 19.8552C174.844 22.1114 176.013 23.7737 177.842 24.1252C180.063 24.553 181.913 23.3813 182.471 21.1904C183.101 18.727 181.997 16.3291 179.926 15.6614C177.182 14.7758 174.825 16.7187 174.836 19.8552Z' fill={ fill } />
    <path d='M199.63 28.9838C195.873 28.8993 192.826 27.4905 191.186 23.9835C189.584 20.5609 189.829 17.1493 192.161 14.0919C195.336 9.9281 202.478 9.50028 206.427 13.1572C210.203 16.6533 210.152 23.1306 206.304 26.6267C204.421 28.3353 202.148 29.0002 199.633 28.9865L199.63 28.9838ZM203.595 19.8906C203.609 17.8523 202.702 16.3236 201.124 15.7241C198.922 14.8875 196.688 16.032 196.001 18.3483C195.227 20.9588 196.562 23.6837 198.829 24.1279C201.535 24.6566 203.576 22.8417 203.595 19.8933V19.8906Z' fill={ fill } />
    <path d='M89.6481 28.9729C81.4378 28.9566 78.3831 20.9424 81.3778 15.3426C83.2308 11.8765 86.4054 10.6175 90.1631 10.702C94.7165 10.8083 98.2372 13.6913 99.041 17.8251C100.251 24.0325 96.0572 29.0083 89.6454 28.9729H89.6481ZM93.6374 19.8797C93.6538 17.5553 92.4494 15.8985 90.4683 15.5279C88.5336 15.1628 86.825 16.1683 86.1438 18.0703C85.3617 20.2557 86.1029 22.7709 87.8387 23.7355C88.1575 23.9126 88.5199 24.0489 88.8796 24.1197C91.5801 24.6566 93.6156 22.8363 93.6374 19.8824V19.8797Z' fill={ fill } />
    <path d='M128.667 21.5801C128.953 24.7111 132.833 25.4604 137.346 23.324C138.065 24.4004 138.796 25.4904 139.602 26.6948C139.202 26.9946 138.866 27.3243 138.469 27.5287C135.185 29.2073 131.762 29.5288 128.307 28.2235C124.574 26.812 122.413 22.9316 122.923 18.8851C123.468 14.5742 126.375 11.5277 130.375 10.8491C136.297 9.84635 141.311 13.8902 141.019 20.8525C140.986 21.6427 140.496 21.5801 139.976 21.5801C136.607 21.5801 133.239 21.5801 129.871 21.5801C129.473 21.5801 129.076 21.5801 128.667 21.5801ZM135.676 17.9095C135.733 16.1574 134.291 15.0347 132.089 15.0456C130.002 15.0565 128.539 16.2364 128.615 17.9095H135.676Z' fill={ fill } />
    <path d='M102.409 11.1516H107.758C107.802 11.7756 107.843 12.3697 107.894 13.1027C108.189 12.9092 108.409 12.7893 108.603 12.6313C110.813 10.8219 113.325 10.3069 116.072 10.9554C118.184 11.4541 119.631 13.1517 119.672 15.3535C119.748 19.4927 119.702 23.6347 119.724 27.7766C119.726 28.3571 119.489 28.5505 118.933 28.5396C117.674 28.5178 116.413 28.5042 115.154 28.5451C114.467 28.5669 114.282 28.2862 114.287 27.6431C114.312 24.7574 114.29 21.8716 114.301 18.9859C114.306 17.6152 114.233 16.2855 112.693 15.6832C111.238 15.1137 109.251 15.6042 108.23 16.8522C107.965 17.1765 107.851 17.7079 107.848 18.1439C107.818 21.5365 107.832 24.9291 107.832 28.5124C106.134 28.5124 104.486 28.526 102.84 28.4879C102.693 28.4851 102.431 28.1336 102.431 27.9401C102.409 22.3785 102.412 16.8195 102.412 11.1462L102.409 11.1516Z' fill={ fill } />
    <path d='M84 32.6543H86.5097C86.8585 32.6543 87.1909 32.687 87.5016 32.7497C87.8122 32.8123 88.0847 32.9241 88.3163 33.0794C88.548 33.2347 88.7333 33.4445 88.8695 33.7089C89.0058 33.9732 89.0739 34.3056 89.0739 34.7062C89.0739 35.1068 89.0112 35.431 88.8831 35.6981C88.7551 35.9651 88.5807 36.1777 88.3572 36.3384C88.1338 36.4992 87.8722 36.6109 87.567 36.6763C87.2618 36.7417 86.9321 36.7772 86.5751 36.7772H85.4878V39.4095H84V32.6543ZM85.4878 35.5155H86.4797C86.6132 35.5155 86.7413 35.5019 86.8667 35.4774C86.992 35.4528 87.101 35.4092 87.2018 35.3493C87.2999 35.2893 87.379 35.2076 87.4416 35.1013C87.5016 34.9978 87.5316 34.8642 87.5316 34.7062C87.5316 34.5345 87.4907 34.3955 87.4117 34.292C87.3326 34.1885 87.2291 34.1067 87.1065 34.0495C86.9838 33.9923 86.8449 33.9568 86.6923 33.9405C86.5397 33.9241 86.3925 33.916 86.2535 33.916H85.4906V35.5182L85.4878 35.5155Z' fill={ fill } />
    <path d='M89.657 37.0878C89.657 36.7199 89.7252 36.3848 89.8587 36.0877C89.9922 35.7907 90.1748 35.5319 90.4064 35.3193C90.638 35.1068 90.9105 34.9433 91.2266 34.8288C91.5427 34.7144 91.8779 34.6571 92.2321 34.6571C92.5864 34.6571 92.9243 34.7144 93.2376 34.8288C93.5537 34.9433 93.8262 35.1068 94.0579 35.3193C94.2895 35.5319 94.4721 35.788 94.6056 36.0877C94.7391 36.3875 94.8072 36.7199 94.8072 37.0878C94.8072 37.4557 94.7391 37.7908 94.6056 38.0879C94.4721 38.3876 94.2895 38.6438 94.0579 38.8563C93.8262 39.0689 93.551 39.2324 93.2376 39.3468C92.9243 39.4613 92.5864 39.5185 92.2321 39.5185C91.8779 39.5185 91.54 39.4613 91.2266 39.3468C90.9132 39.2324 90.638 39.0689 90.4064 38.8563C90.1748 38.6438 89.9922 38.3876 89.8587 38.0879C89.7252 37.7881 89.657 37.4557 89.657 37.0878ZM91.0876 37.0878C91.0876 37.4366 91.1885 37.72 91.3874 37.938C91.589 38.1533 91.8697 38.2623 92.2321 38.2623C92.5946 38.2623 92.8752 38.1533 93.0769 37.938C93.2785 37.7227 93.3766 37.4393 93.3766 37.0878C93.3766 36.7363 93.2758 36.4556 93.0769 36.2376C92.8752 36.0223 92.5946 35.9133 92.2321 35.9133C91.8697 35.9133 91.589 36.0223 91.3874 36.2376C91.1885 36.4529 91.0876 36.7363 91.0876 37.0878Z' fill={ fill } />
    <path d='M96.6193 34.7689L97.5622 37.6764H97.5812L98.3742 34.7689H99.9383L100.797 37.6764H100.816L101.682 34.7689H103.132L101.483 39.404H100.072L99.1072 36.3248H99.0881L98.2298 39.404H96.7883L95.0906 34.7689H96.6193Z' fill={ fill } />
    <path d='M107.977 38.6056C107.748 38.8972 107.459 39.1234 107.108 39.2814C106.756 39.4395 106.397 39.5212 106.02 39.5212C105.644 39.5212 105.328 39.464 105.015 39.3495C104.699 39.2351 104.426 39.0716 104.195 38.859C103.963 38.6465 103.781 38.3904 103.647 38.0906C103.513 37.7909 103.445 37.4584 103.445 37.0905C103.445 36.7227 103.513 36.3875 103.647 36.0905C103.781 35.7907 103.963 35.5346 104.195 35.322C104.426 35.1095 104.702 34.946 105.015 34.8315C105.328 34.7171 105.663 34.6599 106.02 34.6599C106.35 34.6599 106.653 34.7171 106.922 34.8315C107.192 34.946 107.421 35.1095 107.609 35.322C107.797 35.5346 107.942 35.7907 108.042 36.0905C108.143 36.3902 108.195 36.7227 108.195 37.0905V37.5402H104.876C104.933 37.8127 105.059 38.0307 105.249 38.1941C105.44 38.3549 105.674 38.4367 105.955 38.4367C106.189 38.4367 106.388 38.3849 106.552 38.2786C106.715 38.1724 106.854 38.0388 106.977 37.8726L107.977 38.6083V38.6056ZM106.764 36.5619C106.77 36.3194 106.691 36.1123 106.525 35.9406C106.358 35.7689 106.146 35.6845 105.887 35.6845C105.729 35.6845 105.587 35.709 105.467 35.7608C105.347 35.8125 105.244 35.8779 105.157 35.957C105.072 36.036 105.004 36.1286 104.958 36.2322C104.909 36.3384 104.881 36.4474 104.876 36.5619H106.764Z' fill={ fill } />
    <path d='M109.064 34.7689H110.495V35.5128H110.514C110.667 35.2267 110.849 35.0114 111.059 34.8697C111.269 34.728 111.533 34.6544 111.852 34.6544C111.934 34.6544 112.018 34.6571 112.1 34.6653C112.182 34.6708 112.258 34.6844 112.329 34.7035V36.0087C112.228 35.976 112.127 35.9542 112.029 35.9379C111.931 35.9215 111.827 35.9134 111.718 35.9134C111.446 35.9134 111.228 35.9515 111.07 36.0278C110.912 36.1041 110.789 36.2104 110.702 36.3466C110.617 36.4829 110.56 36.6464 110.536 36.8371C110.511 37.0279 110.498 37.2377 110.498 37.4666V39.4013H109.067V34.7661L109.064 34.7689Z' fill={ fill } />
    <path d='M117.16 38.6056C116.931 38.8972 116.642 39.1234 116.291 39.2814C115.939 39.4395 115.58 39.5212 115.204 39.5212C114.828 39.5212 114.511 39.464 114.198 39.3495C113.882 39.2351 113.61 39.0716 113.378 38.859C113.146 38.6465 112.964 38.3904 112.83 38.0906C112.697 37.7909 112.629 37.4584 112.629 37.0905C112.629 36.7227 112.697 36.3875 112.83 36.0905C112.964 35.7907 113.146 35.5346 113.378 35.322C113.61 35.1095 113.885 34.946 114.198 34.8315C114.511 34.7171 114.847 34.6599 115.204 34.6599C115.533 34.6599 115.836 34.7171 116.106 34.8315C116.375 34.946 116.604 35.1095 116.792 35.322C116.98 35.5346 117.125 35.7907 117.226 36.0905C117.326 36.3902 117.378 36.7227 117.378 37.0905V37.5402H114.059C114.116 37.8127 114.242 38.0307 114.432 38.1941C114.623 38.3549 114.858 38.4367 115.138 38.4367C115.373 38.4367 115.571 38.3849 115.735 38.2786C115.898 38.1724 116.037 38.0388 116.16 37.8726L117.16 38.6083V38.6056ZM115.95 36.5619C115.956 36.3194 115.877 36.1123 115.71 35.9406C115.544 35.7689 115.332 35.6845 115.073 35.6845C114.915 35.6845 114.773 35.709 114.653 35.7608C114.533 35.8125 114.43 35.8779 114.343 35.957C114.258 36.036 114.19 36.1286 114.144 36.2322C114.095 36.3384 114.067 36.4474 114.062 36.5619H115.95Z' fill={ fill } />
    <path d='M123.111 39.404H121.795V38.7936H121.776C121.719 38.8754 121.645 38.9599 121.553 39.0471C121.46 39.1343 121.351 39.2106 121.229 39.2814C121.103 39.3522 120.967 39.4095 120.817 39.4531C120.667 39.4967 120.515 39.5212 120.354 39.5212C120.01 39.5212 119.7 39.4612 119.419 39.3441C119.138 39.2269 118.899 39.0607 118.7 38.8481C118.498 38.6356 118.345 38.3822 118.236 38.0906C118.127 37.799 118.073 37.4775 118.073 37.126C118.073 36.8017 118.122 36.491 118.22 36.1967C118.318 35.9024 118.46 35.6381 118.64 35.4092C118.82 35.1803 119.043 34.9978 119.302 34.8615C119.564 34.7253 119.858 34.6571 120.188 34.6571C120.487 34.6571 120.765 34.7035 121.021 34.7961C121.278 34.8888 121.49 35.0468 121.656 35.2675H121.675V32.1965H123.106V39.4068L123.111 39.404ZM121.795 37.0878C121.795 36.739 121.694 36.4556 121.496 36.2376C121.294 36.0223 121.013 35.9133 120.651 35.9133C120.288 35.9133 120.008 36.0223 119.806 36.2376C119.607 36.4529 119.506 36.7363 119.506 37.0878C119.506 37.4393 119.607 37.72 119.806 37.938C120.008 38.1533 120.288 38.2623 120.651 38.2623C121.013 38.2623 121.294 38.1533 121.496 37.938C121.697 37.7227 121.795 37.4393 121.795 37.0878Z' fill={ fill } />
    <path d='M127.213 32.1938H128.643V35.2648H128.662C128.828 35.0414 129.038 34.886 129.297 34.7934C129.556 34.7007 129.834 34.6544 130.131 34.6544C130.461 34.6544 130.758 34.7225 131.017 34.8588C131.278 34.995 131.499 35.1776 131.679 35.4065C131.859 35.6354 132 35.897 132.098 36.194C132.196 36.491 132.246 36.799 132.246 37.1232C132.246 37.472 132.191 37.7936 132.085 38.0879C131.976 38.3794 131.823 38.6329 131.622 38.8454C131.423 39.058 131.18 39.2242 130.902 39.3414C130.624 39.4585 130.311 39.5185 129.967 39.5185C129.801 39.5185 129.646 39.4967 129.502 39.4504C129.354 39.4068 129.221 39.3495 129.095 39.2787C128.97 39.2078 128.864 39.1315 128.771 39.0443C128.679 38.9599 128.605 38.8754 128.548 38.7909H128.529V39.4013H127.213V32.191V32.1938ZM128.529 37.0878C128.529 37.4366 128.63 37.72 128.828 37.938C129.03 38.1533 129.311 38.2623 129.673 38.2623C130.036 38.2623 130.316 38.1533 130.518 37.938C130.717 37.7227 130.818 37.4393 130.818 37.0878C130.818 36.7363 130.717 36.4556 130.518 36.2376C130.316 36.0223 130.036 35.9133 129.673 35.9133C129.311 35.9133 129.03 36.0223 128.828 36.2376C128.63 36.4529 128.529 36.7363 128.529 37.0878Z' fill={ fill } />
    <path d='M135.758 40.1588C135.663 40.4068 135.57 40.6275 135.48 40.8183C135.39 41.009 135.278 41.1698 135.142 41.3006C135.006 41.4314 134.832 41.5295 134.622 41.5949C134.412 41.663 134.134 41.6957 133.793 41.6957C133.425 41.6957 133.068 41.6385 132.725 41.524L132.916 40.3523C133.131 40.4477 133.355 40.4967 133.584 40.4967C133.742 40.4967 133.872 40.4804 133.976 40.445C134.077 40.4095 134.161 40.3578 134.229 40.2924C134.297 40.2242 134.352 40.1452 134.396 40.0498C134.439 39.9545 134.488 39.8427 134.537 39.7174L134.641 39.4503L132.611 34.777H134.156L135.349 37.8399H135.368L136.39 34.777H137.859L135.761 40.1643L135.758 40.1588Z' fill={ fill } />
    <path d='M141.453 32.6543H142.941V35.4583L145.562 32.6543H147.508L144.502 35.7907L147.764 39.404H145.685L142.938 36.1995V39.404H141.45V32.6516L141.453 32.6543Z' fill={ fill } />
    <path d='M151.01 38.8236H150.991C150.833 39.0716 150.62 39.2487 150.356 39.3577C150.091 39.4667 149.813 39.5185 149.522 39.5185C149.307 39.5185 149.097 39.4885 148.898 39.4286C148.699 39.3686 148.522 39.2787 148.369 39.1561C148.217 39.0362 148.097 38.8863 148.007 38.7092C147.917 38.532 147.873 38.3249 147.873 38.0879C147.873 37.8208 147.922 37.5946 148.02 37.4121C148.118 37.2268 148.252 37.0742 148.421 36.9543C148.59 36.8344 148.781 36.739 148.999 36.6736C149.217 36.6082 149.44 36.5564 149.672 36.5265C149.903 36.4938 150.135 36.4747 150.369 36.4692C150.601 36.4638 150.816 36.4583 151.012 36.4583C151.012 36.2049 150.922 36.0033 150.74 35.8534C150.557 35.7035 150.345 35.6299 150.097 35.6299C149.862 35.6299 149.647 35.679 149.454 35.7771C149.26 35.8752 149.086 36.0114 148.933 36.1831L148.17 35.401C148.437 35.1531 148.748 34.9678 149.105 34.8424C149.462 34.7171 149.83 34.6571 150.211 34.6571C150.631 34.6571 150.977 34.7089 151.247 34.8152C151.517 34.9215 151.735 35.0741 151.895 35.2784C152.056 35.4828 152.171 35.7308 152.233 36.0278C152.296 36.3248 152.329 36.6654 152.329 37.0524V39.4068H151.012V38.8263L151.01 38.8236ZM150.655 37.3739C150.546 37.3739 150.413 37.3794 150.249 37.3876C150.089 37.3985 149.931 37.423 149.783 37.4693C149.636 37.5156 149.508 37.581 149.402 37.6682C149.296 37.7581 149.244 37.8808 149.244 38.0415C149.244 38.2132 149.317 38.3413 149.465 38.423C149.612 38.5048 149.764 38.5484 149.922 38.5484C150.061 38.5484 150.198 38.5293 150.328 38.4912C150.459 38.453 150.574 38.3985 150.677 38.3277C150.778 38.2568 150.86 38.1696 150.92 38.0606C150.98 37.9516 151.01 37.8263 151.01 37.6791V37.3739H150.655Z' fill={ fill } />
    <path d='M153.222 34.7689H154.653V35.5128H154.672C154.825 35.2267 155.007 35.0114 155.217 34.8697C155.427 34.728 155.691 34.6544 156.01 34.6544C156.092 34.6544 156.176 34.6571 156.258 34.6653C156.34 34.6708 156.416 34.6844 156.487 34.7035V36.0087C156.386 35.976 156.285 35.9542 156.187 35.9379C156.089 35.9215 155.985 35.9134 155.876 35.9134C155.604 35.9134 155.386 35.9515 155.228 36.0278C155.07 36.1041 154.947 36.2104 154.86 36.3466C154.776 36.4829 154.718 36.6464 154.694 36.8371C154.669 37.0279 154.656 37.2377 154.656 37.4666V39.4013H153.225V34.7661L153.222 34.7689Z' fill={ fill } />
    <path d='M157.274 34.7689H158.648V35.3983H158.667C158.71 35.3084 158.776 35.2212 158.858 35.1313C158.939 35.0414 159.04 34.9623 159.152 34.8915C159.264 34.8206 159.397 34.7634 159.542 34.7198C159.689 34.6762 159.847 34.6517 160.018 34.6517C160.343 34.6517 160.632 34.7198 160.882 34.8533C161.133 34.9869 161.324 35.1967 161.449 35.4828C161.615 35.1831 161.814 34.9705 162.051 34.8452C162.288 34.7198 162.577 34.6544 162.921 34.6544C163.231 34.6544 163.495 34.7062 163.708 34.8125C163.921 34.9187 164.09 35.0604 164.212 35.243C164.335 35.4256 164.425 35.6381 164.479 35.8806C164.534 36.1259 164.561 36.3875 164.561 36.6682V39.404H163.13V36.7063C163.13 36.491 163.084 36.303 162.991 36.1477C162.899 35.9924 162.735 35.9133 162.501 35.9133C162.335 35.9133 162.198 35.9406 162.087 35.9951C161.975 36.0496 161.885 36.1232 161.82 36.2185C161.752 36.3139 161.705 36.4256 161.678 36.551C161.648 36.6791 161.634 36.8126 161.634 36.9516V39.404H160.204V36.9516C160.204 36.8698 160.201 36.7663 160.196 36.6464C160.19 36.5265 160.166 36.412 160.127 36.303C160.089 36.194 160.027 36.1041 159.942 36.0278C159.855 35.9515 159.73 35.9133 159.566 35.9133C159.381 35.9133 159.234 35.946 159.119 36.0142C159.005 36.0823 158.918 36.1695 158.858 36.2812C158.798 36.3929 158.757 36.5183 158.738 36.6573C158.719 36.7962 158.71 36.9434 158.71 37.096V39.404H157.28V34.7689H157.274Z' fill={ fill } />
    <path d='M168.482 38.8236H168.463C168.305 39.0716 168.093 39.2487 167.828 39.3577C167.564 39.4667 167.286 39.5185 166.994 39.5185C166.779 39.5185 166.569 39.4885 166.37 39.4286C166.171 39.3686 165.994 39.2787 165.842 39.1561C165.689 39.0362 165.569 38.8863 165.479 38.7092C165.389 38.532 165.346 38.3249 165.346 38.0879C165.346 37.8208 165.395 37.5946 165.493 37.4121C165.591 37.2268 165.725 37.0742 165.893 36.9543C166.062 36.8344 166.253 36.739 166.471 36.6736C166.689 36.6082 166.913 36.5564 167.144 36.5265C167.376 36.4938 167.607 36.4747 167.842 36.4692C168.073 36.4638 168.289 36.4583 168.485 36.4583C168.485 36.2049 168.395 36.0033 168.212 35.8534C168.03 35.7035 167.817 35.6299 167.569 35.6299C167.335 35.6299 167.12 35.679 166.926 35.7771C166.733 35.8752 166.558 36.0114 166.406 36.1831L165.643 35.401C165.91 35.1531 166.22 34.9678 166.577 34.8424C166.934 34.7171 167.302 34.6571 167.684 34.6571C168.103 34.6571 168.449 34.7089 168.719 34.8152C168.989 34.9215 169.207 35.0741 169.368 35.2784C169.529 35.4828 169.643 35.7308 169.706 36.0278C169.768 36.3248 169.801 36.6654 169.801 37.0524V39.4068H168.485V38.8263L168.482 38.8236ZM168.128 37.3739C168.019 37.3739 167.885 37.3794 167.722 37.3876C167.561 37.3985 167.403 37.423 167.256 37.4693C167.109 37.5156 166.981 37.581 166.874 37.6682C166.768 37.7581 166.716 37.8808 166.716 38.0415C166.716 38.2132 166.79 38.3413 166.937 38.423C167.084 38.5048 167.237 38.5484 167.395 38.5484C167.534 38.5484 167.67 38.5293 167.801 38.4912C167.932 38.453 168.046 38.3985 168.15 38.3277C168.251 38.2568 168.332 38.1696 168.392 38.0606C168.452 37.9516 168.482 37.8263 168.482 37.6791V37.3739H168.128Z' fill={ fill } />
    <path d='M172.992 32.6543H174.613L175.692 36.9843H175.712L177.123 32.6543H178.507L179.908 37.0987H179.927L181.063 32.6543H182.57L180.595 39.4068H179.268L177.78 34.7334H177.761L176.273 39.4068H174.995L172.992 32.6543Z' fill={ fill } />
    <path d='M185.611 38.8236H185.592C185.434 39.0716 185.222 39.2487 184.957 39.3577C184.693 39.4667 184.415 39.5185 184.123 39.5185C183.908 39.5185 183.698 39.4885 183.499 39.4286C183.301 39.3686 183.123 39.2787 182.971 39.1561C182.818 39.0362 182.698 38.8863 182.608 38.7092C182.518 38.532 182.475 38.3249 182.475 38.0879C182.475 37.8208 182.524 37.5946 182.622 37.4121C182.72 37.2268 182.854 37.0742 183.023 36.9543C183.192 36.8344 183.382 36.739 183.6 36.6736C183.818 36.6082 184.042 36.5564 184.273 36.5265C184.505 36.4938 184.737 36.4747 184.971 36.4692C185.203 36.4638 185.418 36.4583 185.614 36.4583C185.614 36.2049 185.524 36.0033 185.342 35.8534C185.159 35.7035 184.946 35.6299 184.698 35.6299C184.464 35.6299 184.249 35.679 184.055 35.7771C183.862 35.8752 183.687 36.0114 183.535 36.1831L182.772 35.401C183.039 35.1531 183.35 34.9678 183.707 34.8424C184.064 34.7171 184.431 34.6571 184.813 34.6571C185.233 34.6571 185.579 34.7089 185.848 34.8152C186.118 34.9215 186.336 35.0741 186.497 35.2784C186.658 35.4828 186.772 35.7308 186.835 36.0278C186.898 36.3248 186.93 36.6654 186.93 37.0524V39.4068H185.614V38.8263L185.611 38.8236ZM185.257 37.3739C185.148 37.3739 185.015 37.3794 184.851 37.3876C184.69 37.3985 184.532 37.423 184.385 37.4693C184.238 37.5156 184.11 37.581 184.004 37.6682C183.897 37.7581 183.846 37.8808 183.846 38.0415C183.846 38.2132 183.919 38.3413 184.066 38.423C184.213 38.5048 184.366 38.5484 184.524 38.5484C184.663 38.5484 184.799 38.5293 184.93 38.4912C185.061 38.453 185.175 38.3985 185.279 38.3277C185.38 38.2568 185.461 38.1696 185.521 38.0606C185.581 37.9516 185.611 37.8263 185.611 37.6791V37.3739H185.257Z' fill={ fill } />
    <path d='M187.843 32.1938H189.274V39.404H187.843V32.1938Z' fill={ fill } />
    <path d='M190.323 32.1938H191.753V39.404H190.323V32.1938Z' fill={ fill } />
    <path d='M197.171 38.6056C196.942 38.8972 196.653 39.1234 196.301 39.2814C195.95 39.4395 195.59 39.5212 195.214 39.5212C194.838 39.5212 194.522 39.464 194.209 39.3495C193.892 39.2351 193.62 39.0716 193.388 38.859C193.157 38.6465 192.974 38.3904 192.841 38.0906C192.707 37.7909 192.639 37.4584 192.639 37.0905C192.639 36.7227 192.707 36.3875 192.841 36.0905C192.974 35.7907 193.157 35.5346 193.388 35.322C193.62 35.1095 193.895 34.946 194.209 34.8315C194.522 34.7171 194.857 34.6599 195.214 34.6599C195.544 34.6599 195.846 34.7171 196.116 34.8315C196.386 34.946 196.615 35.1095 196.803 35.322C196.991 35.5346 197.135 35.7907 197.236 36.0905C197.337 36.3902 197.389 36.7227 197.389 37.0905V37.5402H194.07C194.127 37.8127 194.252 38.0307 194.443 38.1941C194.634 38.3549 194.868 38.4367 195.149 38.4367C195.383 38.4367 195.582 38.3849 195.745 38.2786C195.909 38.1724 196.048 38.0388 196.171 37.8726L197.171 38.6083V38.6056ZM195.958 36.5619C195.963 36.3194 195.884 36.1123 195.718 35.9406C195.552 35.7689 195.339 35.6845 195.081 35.6845C194.923 35.6845 194.781 35.709 194.661 35.7608C194.541 35.8125 194.437 35.8779 194.35 35.957C194.266 36.036 194.198 36.1286 194.151 36.2322C194.102 36.3384 194.075 36.4474 194.07 36.5619H195.958Z' fill={ fill } />
    <path d='M201.413 35.9161H200.154V37.4611C200.154 37.5892 200.16 37.7036 200.174 37.8099C200.187 37.9162 200.214 38.0061 200.261 38.0824C200.304 38.1587 200.372 38.2187 200.465 38.2595C200.558 38.3004 200.68 38.3222 200.833 38.3222C200.909 38.3222 201.01 38.314 201.133 38.2977C201.258 38.2813 201.351 38.2459 201.413 38.1887V39.3822C201.255 39.4395 201.089 39.4776 200.917 39.4967C200.746 39.5157 200.577 39.5239 200.411 39.5239C200.168 39.5239 199.947 39.4994 199.743 39.4476C199.539 39.3959 199.362 39.3168 199.209 39.2051C199.056 39.0934 198.936 38.949 198.852 38.7718C198.765 38.5947 198.724 38.3767 198.724 38.1233V35.9215H197.808V34.777H198.724V33.4036H200.154V34.777H201.413V35.9215V35.9161Z' fill={ fill } />
    <path d='M-0.000271759 22.0358C-0.0798625 10.2316 9.37497 0.343173 21.3822 0.0193208C33.6282 -0.312765 43.9448 9.39182 43.9997 21.9946C44.0518 34.1473 34.1634 44.011 22.0107 44.011C9.8086 44.011 0.00796177 34.2296 -0.000271759 22.0358ZM40.945 22.0275C40.9533 11.6066 32.4481 3.06847 22.0464 3.05749C11.5569 3.04377 3.12023 11.5023 3.05162 21.9809C2.98575 32.2536 11.5075 41.2446 22.4827 40.9729C32.4618 40.7259 40.8682 32.6021 40.945 22.0303V22.0275Z' fill={ checkMarkColor } />
    <path d='M17.2599 31.1064C15.4074 29.273 13.4231 27.308 11.4718 25.3758C12.1744 24.687 12.9044 23.9679 13.714 23.1747C14.842 24.3412 16.0441 25.5899 17.2243 26.8085C21.6402 22.4282 25.9628 18.1386 30.2442 13.8928C31.0209 14.675 31.7372 15.4023 32.459 16.1296C27.4476 21.0697 22.3345 26.1086 17.2627 31.1091L17.2599 31.1064Z' fill={ checkMarkColor } />
  </SvgIcon>
);

<svg width='230' height='44' viewBox='0 0 230 44' fill='none' xmlns='http://www.w3.org/2000/svg' />;
