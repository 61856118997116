import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

export const HeroSectionContainer = styled(Section)`
  overflow: hidden;
  padding: 100px 15px 0 !important;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    padding: 60px 15px !important;
  }
`;

export const BackgroundContainer = styled(Section)`
  background: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  .svg-icon {
    position: absolute;
  }

  .coin-b-1 {
    left: -3%;
    top: -8%;
    transform: scale(0.6) rotate(-10deg);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 6%;
      top: 5%;
      transform: unset;
    }
  }

  .coin-b-2 {
    left: 70%;
    top: -5%;
    transform: scale(0.9) rotate(35deg);
    
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 30%;
      top: 20%;
      transform: scale(1.2) rotate(50deg);
    }
  }

  .coin-b-3 {
    left: -10%;
    top: 57%;
    transform: scale(0.9) rotate(-65deg);
  }

  .coin-b-4 {
    right: -7%;
    top: 75%;
    transform: scale(1.5) rotate(-75deg);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 16%;
      top: 78%;
      transform: scale(0.9) rotate(45deg);
    }
  }

  .coin-b-5 {
    left: 42%;
    top: 85%;
    transform: scale(1.2) rotate(15deg);
  }

  .bottle {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      right: -5%;
      top: 44%;
      transform: scale(0.9);
    }
  }

  .earth-clouds {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      right: -5%;
      top: 75%;
      display: block;
    }
  }

  .leaf-a {
    left: -5%;
    top: 37%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 65%;
      top: -2%;
    }
  }

  .light {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      left: 27%;
      top: -12%;
      transform: scale(0.175);
    }
  }

  .food {
    left: -10%;
    top: 70%;
    
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 52%;
      top: 65%;
    }
  }

  .recycling {
    left: 75%;
    top: 30%;
    transform: scale(0.7);

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      left: 100%;
      top: 2%;
      transform: scale(0.9);
    }
  }
`;

export const ContentWrapper = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  position: relative;
  max-width: 1160px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    justify-content: space-between;
  }
`;

export const DebitCardTextContainer = styled.div`
  ${FlexCol}
  max-width: 400px;
  width: 100%;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: unset;
    width: 50%;
  }

  .debit-card-text {
    color: ${({ theme }) => theme.colors.offWhite};
    margin-top: 10px;
  }

  h2 {
    font-size: 40px;
    line-height: 2.5rem;
    margin-bottom: 5px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 71px;
      line-height: 4rem;
      margin-bottom: 5px;
    }
  }

  h4 {
    color: ${({ theme }) => theme.colors.white};
    width: 75%;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      width: 100%;
    }
  }
`;

export const KarmaCardAndAppImgContainer = styled.div`
  z-index: 1;
  height: 600px;
  overflow: hidden;

  .app-mockup {
    display: block;
    margin-top: 50px;
    width: 300px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      margin-top: 0;
    }
  }

  .app-and-card {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .app-mockup {
      display: none;
    }

    .app-and-card {
      display: block;
      max-width: 575px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    img {
      width: unset;
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    overflow: unset;
  }
`;

export const AppButtonsWrapper = styled.div`
  ${FlexRow}
  align-items: flex-start;
  margin-top: 20px;
  gap: 17px;

  img {
    height: 44px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    height: 53px;

    img {
      height: 53px;
    }
  }
`;
