import React from 'react';
import { ICta } from '../../CTAs';
import { IModalProps } from '../Modal';
import { Body, Graphic, InfoModalContainer, InfoModalCtas, Title } from './styles';

export interface IInfoModalProps extends IModalProps {
  graphic?: JSX.Element;
  title: string;
  body: string | JSX.Element;
  ctas?: ICta[];
}

export const InfoModal: React.FC<IInfoModalProps> = ({
  graphic,
  title,
  body,
  ctas,
  ...restProps
}) => (
  <InfoModalContainer { ...restProps }>
    { !!graphic && <Graphic className='info-modal-graphic'>{ graphic }</Graphic> }
    <Title className='info-modal-title'>{ title }</Title>
    <Body className={ `info-modal-body ${!ctas ? 'no-bottom-margin' : ''}` }>{ body }</Body>
    { !!ctas && <InfoModalCtas className='info-modal-ctas' ctas={ ctas } /> }
  </InfoModalContainer>
);
