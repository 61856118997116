import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const CoinIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}coin-icon` }
    id={ id }
    width={ 57 }
    height={ 60 }
    fill='none'
  >
    <g clipPath='url(#clip0_2437_7793)'>
      <path d='M28.49 58.24C43.6723 58.24 55.98 45.9323 55.98 30.75C55.98 15.5677 43.6723 3.26001 28.49 3.26001C13.3077 3.26001 1 15.5677 1 30.75C1 45.9323 13.3077 58.24 28.49 58.24Z' fill={ stroke } stroke={ stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M28.49 55.98C43.6723 55.98 55.98 43.6723 55.98 28.49C55.98 13.3077 43.6723 1 28.49 1C13.3077 1 1 13.3077 1 28.49C1 43.6723 13.3077 55.98 28.49 55.98Z' fill='white' stroke={ stroke } strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M35.7199 38.77C33.9971 40.2361 31.8392 41.0937 29.5799 41.21V45.21H26.6599V41.2599C24.9567 41.1464 23.2757 40.8102 21.6599 40.2599C20.2932 39.8473 19.022 39.1675 17.9199 38.2599L19.5199 34.67C20.4891 35.4318 21.5724 36.0358 22.7299 36.46C23.9832 36.9482 25.2999 37.2547 26.6399 37.37V30.25C25.1978 29.9281 23.786 29.4831 22.4199 28.92C21.3168 28.447 20.3455 27.7125 19.5899 26.78C18.7942 25.7119 18.3947 24.4003 18.4599 23.0699C18.4461 22.15 18.6259 21.2375 18.9877 20.3916C19.3495 19.5457 19.8851 18.7854 20.5599 18.16C22.2486 16.6791 24.3955 15.8246 26.6399 15.74V11.74H29.5599V15.69C30.8786 15.7648 32.1841 15.993 33.4499 16.37C34.6241 16.7085 35.745 17.2101 36.7799 17.86L35.3199 21.46C33.5543 20.4264 31.5925 19.7725 29.5599 19.54V26.73C31.0096 27.0514 32.4309 27.4895 33.8099 28.04C34.9198 28.5016 35.8987 29.2297 36.6599 30.16C37.4696 31.2257 37.8768 32.5432 37.8099 33.8799C37.8266 34.7964 37.649 35.706 37.2888 36.5489C36.9285 37.3917 36.3938 38.1487 35.7199 38.77ZM23.9899 24.77C24.7993 25.3315 25.704 25.7415 26.6599 25.98V19.64C25.6647 19.7356 24.7225 20.1334 23.9599 20.78C23.671 21.0468 23.4422 21.372 23.2887 21.7341C23.1353 22.0962 23.0607 22.4868 23.0699 22.8799C23.0578 23.2461 23.1353 23.6097 23.2956 23.939C23.456 24.2684 23.6943 24.5536 23.9899 24.77ZM32.3699 36.23C32.6568 35.9819 32.8867 35.6748 33.0438 35.3296C33.2009 34.9844 33.2814 34.6092 33.2799 34.23C33.2852 33.8494 33.1967 33.4733 33.0223 33.135C32.8479 32.7967 32.5929 32.5065 32.2799 32.29C31.4504 31.7142 30.5214 31.2972 29.5399 31.06V37.36C30.5734 37.2679 31.5572 36.875 32.3699 36.23Z' fill={ stroke } />
    </g>
    <defs>
      <clipPath id='clip0_2437_7793'>
        <rect width='56.99' height='59.25' fill='white' />
      </clipPath>
    </defs>
  </SvgIcon>
);
