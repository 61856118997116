import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Logo: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }logo-svg` }
    id={ id }
    width={ 149 }
    height={ 217 }
    fill='none'
  >
    <path d='M112.744 8.0261C92.6401 27.277 44.4782 87.4824 47.5712 115.504C48.8231 143.674 105.454 195.778 128.209 211.684C136.752 217.63 146.178 219.563 148.608 212.353C151.112 205.143 140.949 191.541 136.31 184.926C121.213 163.297 93.2293 137.877 91.2409 112.457C89.2526 87.0365 113.113 57.0081 124.822 33.2975C128.43 26.0134 136.384 10.9992 132.849 4.23538C129.314 -2.52844 120.256 0.816309 112.744 8.0261Z' fill='url(#paint0_linear_227_4)' />
    <path d='M59.7232 77.4485C51.6962 91.9424 46.4676 105.693 47.5722 115.504C48.8241 143.674 105.455 195.778 128.21 211.684C136.753 217.631 146.179 219.563 148.609 212.353C151.113 205.144 140.95 191.542 136.311 184.926C117.827 158.466 105.749 145.979 97.207 131.856C96.986 131.485 77.5445 106.808 69.2966 92.6113C62.3006 80.6446 60.9014 75.4417 60.9014 75.4417C60.9014 75.4417 60.3123 76.3336 59.7232 77.4485Z' fill='#286169' />
    <path d='M17.5268 216.888C27.2066 216.888 35.0536 168.336 35.0536 108.444C35.0536 48.5521 27.2066 0 17.5268 0C7.84702 0 0 48.5521 0 108.444C0 168.336 7.84702 216.888 17.5268 216.888Z' fill='#286169' />
    <defs>
      <linearGradient id='paint0_linear_227_4' x1='47.5382' y1='115.442' x2='141.338' y2='108.337' gradientUnits='userSpaceOnUse'>
        <stop offset='0.1197' stopColor='#1A3F45' />
        <stop offset='0.635' stopColor='#286169' />
      </linearGradient>
    </defs>
  </SvgIcon>
);
