import { observer } from 'mobx-react';
import { customAlphabet } from 'nanoid';
import equal from 'fast-deep-equal';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useToaster } from '../../contexts/toaster-store';
import { GroupCodeAvailability, GroupModel, GroupPrivacyStatus, MAX_CODE_LENGTH } from '../../models/group';
import { H1, H3, H4 } from '../../styles/components/header';
import { Label } from '../../styles/components/label';
import { IThemeProps, theme } from '../../styles/themes';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { Checkbox } from '../Checkbox';
import { CTAs } from '../CTAs';
import { FieldWithToolTip } from '../FieldWithToolTip';
import { IRadioButtonProps } from '../RadioButton';
import { RadioGroup, RadioGroupStack } from '../RadioGroup';
import { InfoIcon } from '../svgs/icons/InfoIcon';
import { TextField, TextFieldKind, TextFieldSize } from '../TextField';
import { GroupCodeSubFieldSection, GroupSettingField, GroupSettingsContainer, GroupSettingsHeader, GroupSettingsInner, MatchSetting, MatchTextFieldContainer, PrivacyStatusContainer, Setting } from './styles';
import { buildBaseKarmaUrl } from '../../lib/urlBuilders';

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', MAX_CODE_LENGTH);

interface IProps extends IThemeProps {
  allowReadOnly?: boolean;
  className?: string;
  group: GroupModel;
}

interface IPrivacyRadioButton extends IRadioButtonProps {
  context: GroupPrivacyStatus;
  description: string;
}

const privacyRadioButtons: IPrivacyRadioButton[] = [
  {
    id: 'public-group',
    context: GroupPrivacyStatus.Public,
    label: 'Public (Coming Soon)',
    disabled: true, // disabling for now until we support public groups.
    description: 'Public groups can be viewed and joined by anyone.',
  },
  {
    id: 'protected-group',
    context: GroupPrivacyStatus.Protected,
    label: 'Protected (Coming Soon)',
    disabled: true, // disabling for now until we support public groups.
    description: 'Protected groups can be viewed by anyone, but have rules in order to join (like requiring a specific email domain). They will show up in search results, and will be displayed on member\'s public profiles.',
  },
  {
    id: 'private-group',
    context: GroupPrivacyStatus.Private,
    label: 'Private',
    description: 'Private groups cannot be viewed by anyone other than it\'s members. They will also not show up in search results, or be displayed on member\'s public profiles.',
  },
];

const defaultMatchPercent = 100;
const defaultMatchMax = 150;

/**
 * the group settings found on the group admin page
 */
const GroupSettingsBase: React.FC<IProps> = ({
  className = '',
  group,
}) => {
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [isEditMode, setIsEditMode] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupCodeTimeout, setGroupCodeTimeout] = useState<number>(null);
  const groupCodeEngaged = useRef(false);
  const [groupCode, setGroupCode] = useState('');
  const [groupCodeAvailability, setGroupCodeAvailability] = useState<GroupCodeAvailability>(null);
  const [groupCodeError, setGroupCodeError] = useState('');
  const [groupPrivacyStatus, setGroupPrivacyStatus] = useState(GroupPrivacyStatus.Private);
  const [allowInvites, setAllowInvites] = useState(false);
  const [allowDomainRestriction, setAllowDomainRestriction] = useState(false);
  const [restrictedDomains, setRestrictedDomains] = useState('');
  const [restrictedDomainError, setRestrictedDomainError] = useState('');
  const [allowSubgroups, setAllowSubgroups] = useState(false);
  const [requireApproval, setRequireApproval] = useState(false);
  const [offsetMatching, setOffsetMatching] = useState(false);
  const [offsetMatchPercent, setOffsetMatchPercent] = useState(defaultMatchPercent);
  const [offsetMatchPercentError, setOffsetMatchPercentError] = useState('');
  const [offsetMatchMax, setOffsetMatchMax] = useState(defaultMatchMax);

  const checkCode = useCallback(async () => {
    if (!!groupCodeAvailability || !!groupCodeError) return;

    if (!groupCode || groupCode === group?.code) {
      setGroupCodeAvailability(null);
      return;
    }

    try {
      const codeStatus = await group.checkCode(groupCode);

      if (!codeStatus) return;

      if (!codeStatus.isValid) {
        setGroupCodeError('Invalid code. Only letters, numbers, and hyphens (-) allowed.');
        return;
      }

      setGroupCodeAvailability(codeStatus.available);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Checking Group Code Status',
        message: err.message,
      });
    } 
  }, [groupCode]);

  useEffect(() => {
    if (!!groupCode) {
      window.clearTimeout(groupCodeTimeout);
      setGroupCodeTimeout(window.setTimeout(checkCode, 300));
    }
  }, [groupCode]);

  const generateRandomGroupCode = useCallback(async () => {
    try {
      const randCode = nanoid();
      const codeStatus = await group.checkCode(randCode);
      if (codeStatus.available === GroupCodeAvailability.Available && codeStatus.isValid) {
        setGroupCode(randCode);
        setGroupCodeAvailability(codeStatus.available);
        setGroupCodeError('');
      } else {
        generateRandomGroupCode();
      }
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Generating Random Code',
        message: err.message,
      });
    }
  }, []);

  const getPrivacyRadioButtons = useCallback(() => {
    const _privacyRadioButtons = privacyRadioButtons.map(r => ({
      ...r,
      checked: r.context === groupPrivacyStatus,
    }));

    return _privacyRadioButtons;
  }, [groupPrivacyStatus]);

  const getSettingsCtas = () => isEditMode
    ? [
      {
        id: 'cancel-settings',
        text: 'Cancel',
        kind: ButtonKind.PrimaryGhost,
        onClick: reset,
      },
      {
        disabled: shouldNotAllowSaving(),
        id: 'save-settings',
        text: 'Save',
        kind: ButtonKind.Primary,
        onClick: onSaveSettingsClick,
      },
    ]
    : [
      {
        id: 'edit-settings',
        text: 'Edit',
        kind: ButtonKind.Primary,
        onClick: onEditSettingsClick,
      },
    ];

  const getCopyUrlCta = () => [
    {
      id: 'copy-url-cta',
      text: 'Copy Group Join URL',
      kind: ButtonKind.PrimaryGhost,
      onClick: onCopyUrlClick,
    },
  ];

  const onAllowDomainRestrictionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAllowDomainRestriction(e.target.checked);
  }, []);

  const reset = useCallback(() => {
    setIsEditMode(false);
    setGroupName('');
    setGroupCode('');
    setGroupPrivacyStatus(GroupPrivacyStatus.Private);
    setAllowDomainRestriction(false);
    setRestrictedDomains('');
    setAllowInvites(false);
    setAllowSubgroups(false);
    setRequireApproval(false);
    setOffsetMatching(false);
    setOffsetMatchPercent(defaultMatchPercent);
    setOffsetMatchPercentError('');
    setOffsetMatchMax(defaultMatchMax);
  }, []);

  const onEditSettingsClick = useCallback(() => {
    setGroupName(group.name);
    setGroupCode(group.code);
    setGroupPrivacyStatus(group.settings.privacyStatus);
    setAllowInvites(group.settings.allowInvite);
    setAllowDomainRestriction(group.settings.allowDomainRestriction);
    setRestrictedDomains(group.domains.join(', ') || '');
    setAllowSubgroups(group.settings.allowSubgroups);
    setRequireApproval(group.settings.approvalRequired);
    setOffsetMatching(group.settings.matching.enabled);
    setOffsetMatchPercent(group.settings.matching.matchPercentage);
    setOffsetMatchPercentError('');
    setIsEditMode(true);
    setOffsetMatchMax(group.settings.matching.maxDollarAmount);
  }, [group]);

  const onGroupCodeBlur = useCallback(() => {
    if (!groupCode) setGroupCodeError('A group code is required.');
  }, [groupCode]);

  const onGroupCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    groupCodeEngaged.current = true;
    setGroupCodeAvailability(null);
    setGroupCodeError('');
    // remove any invalid chars
    let val = e.target.value.split(/[^a-zA-Z0-9-]/gm).join('');
    if (val.length > MAX_CODE_LENGTH) val = val.slice(0, MAX_CODE_LENGTH);

    setGroupCode(val);
  }, []);

  const onGroupDomainsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRestrictedDomains(e.target.value);
    setRestrictedDomainError('');
  }, []);

  const onGroupNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  }, []);

  const onMatchAmountChange = useCallback((field: 'percent' | 'max') => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim() === '') {
      (field === 'percent' ? setOffsetMatchPercent : setOffsetMatchMax)(null);
      return;
    }

    let val = parseFloat(e.target.value.split(/\D+/g).join(''));

    if (field === 'percent') {
      if (val > 100) val = 100;
      setOffsetMatchPercentError('');
    }

    (field === 'percent' ? setOffsetMatchPercent : setOffsetMatchMax)(val);
  }, []);

  const onOfferOffsetMatchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOffsetMatching(e.target.checked);
  }, []);

  const onSaveSettingsClick = useCallback(async () => {
    if (shouldNotAllowSaving()) return null;
    
    const allDomains = restrictedDomains
      .split(',')
      .map(d => d.trim())
      .filter(d => !!d);

    // remove any duplicate domains
    const domains = new Set(allDomains);

    try {
      await group?.update({
        name: groupName,
        code: groupCode,
        settings: {
          ...group.settings,
          privacyStatus: groupPrivacyStatus,
          allowDomainRestriction,
          matching: {
            enabled: offsetMatching,
            matchPercentage: offsetMatching ? offsetMatchPercent : null,
            maxDollarAmount: offsetMatching ? offsetMatchMax : null,
          },
        },
        domains: Array.from(domains),
      });

      toaster.push({ message: 'Group Updated Successfully. ' });
      reset();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Updating Group',
        message: err.message,
      });
    }
  }, [
    groupName,
    groupCode,
    restrictedDomains,
    offsetMatching, 
    offsetMatchPercent, 
    offsetMatchMax,
  ]);

  const shouldNotAllowSaving = useCallback(() => {
    const allDomains = restrictedDomains
      .split(',')
      .map(d => d.trim())
      .filter(d => !!d);

    const restrictedDomainsMatch = equal(allDomains, [...group.domains]);

    return group.name === groupName
      && group.code === groupCode
      && group.settings.privacyStatus === groupPrivacyStatus
      && group.settings.allowInvite === allowInvites
      && group.settings.allowDomainRestriction === allowDomainRestriction
      && restrictedDomainsMatch
      && group.settings.allowSubgroups === allowSubgroups
      && group.settings.approvalRequired === requireApproval
      && group.settings.matching.enabled === offsetMatching
      && group.settings.matching.matchPercentage === offsetMatchPercent
      && !offsetMatchPercentError
      && group.settings.matching.maxDollarAmount === offsetMatchMax;
  }, [
    group,
    groupName,
    groupCode,
    groupPrivacyStatus,
    allowInvites,
    allowDomainRestriction,
    restrictedDomains,
    allowSubgroups,
    requireApproval,
    offsetMatching,
    offsetMatchPercent,
    offsetMatchPercentError,
    offsetMatchMax,
  ]);

  const validateRestrictedDomains = useCallback(() => {
    if (allowDomainRestriction && restrictedDomains.length === 0) {
      setRestrictedDomainError('At least one domain is required in order to allow restricted domains.');
      return;
    }

    const allInvalidDomains = restrictedDomains
      .split(',')
      .map(d => d.trim())
      .filter(d => !!d)
      .filter(d => !d.includes('.'));

    // remove any duplicates
    const invalidDomains = new Set(allInvalidDomains);

    if (!!invalidDomains.size) {
      const isPlural = invalidDomains.size > 0;
      setRestrictedDomainError(`The following domain${isPlural ? 's' : ''} ${isPlural ? 'are' : 'is'} invalid: ${Array.from(invalidDomains).join(', ')}. Ensure you entered a valid top-level domain (like .com or .io)`);
    }
  }, [restrictedDomains]);

  /****************** RENDERERS ******************/

  const renderDomainRestrictionField = () => {
    if (!allowDomainRestriction) return null;
    
    return (
      <Setting className='indent'>
        <TextField
          className='text-field setting-text-field'
          id='group-email-domain-restrictions-field'
          label='Enter allowed email domains'
          ariaDescribedBy='group-email-domain-restrictions-description'
          onBlur={ validateRestrictedDomains }
          onChange={ onGroupDomainsChange }
          placeholder='Enter the allowed email domains'
          value={ restrictedDomains }
        />
        {
          !!restrictedDomainError
            ? <p className='input-field-error'>{ restrictedDomainError }</p>
            : <p className='input-field-support-text' id='group-email-domain-restrictions-description'>If there are multiple, separate each with a comma. (example: theimpactkarma.com, protonmail.com)</p>
        }
      </Setting>
    );
  };

  const renderEditableSettings = () => (
    <GroupSettingsInner>
      <div>
        <TextField
          className='text-field'
          fieldSize={ TextFieldSize.Large }
          id='group-name-field'
          label='Group Name'
          onChange={ onGroupNameChange }
          placeholder='Enter a group name'
          value={ groupName }
        />
        <TextField
          className={ 'text-field group-code-field-input' }
          id='group-code-field'
          label='Group Code'
          onBlur={ onGroupCodeBlur }
          onChange={ onGroupCodeChange }
          placeholder='Enter a group code'
          value={ groupCode }
        />
        { renderGroupCodeSubFieldSection() }
        <PrivacyStatusContainer>
          <Label>Group Privacy Status:</Label>
          <RadioGroup
            name='group-privacy-level'
            radioButtons={ getPrivacyRadioButtons() }
            stack={ RadioGroupStack.Vertical }
          />
        </PrivacyStatusContainer>
      </div>
      <div>
        <Setting className='unsupported'>
          <Checkbox
            label='Allow Invites'
            id='allow-invites'
            ariaDescribedBy='allow-invites-description'
            checked={ allowInvites }
            disabled={ true } // disabling by default until invites are supported
          />
          <p id='allow-invites-description' className='input-field-support-text'>Allows invite emails to be sent to individuals. This invite will override any other group access requirements that have been set.</p>
        </Setting>
        <Setting>
          <Checkbox
            label='Allow Email Domain Restrictions'
            id='allow-email-restrictions'
            ariaDescribedBy='allow-domain-restrictions'
            checked={ allowDomainRestriction }
            onChange={ onAllowDomainRestrictionChange }
          />
          <p id='allow-domain-restrictions' className='input-field-support-text'>Restrict who can join this group by requiring members to have emails from specific domains.</p>
          { renderDomainRestrictionField() }
        </Setting>
        <Setting className='unsupported'>
          <Checkbox
            label='Allow Sub-Groups'
            id='allow-sub-groups'
            ariaDescribedBy='allow-subgroups-description'
            checked={ allowSubgroups }
            disabled={ true } // disabling by default until invites are supported
          />
          <p id='allow-subgroups-description' className='input-field-support-text'>Allows smaller teams within this group.</p>
        </Setting>
        <Setting className='unsupported'>
          <Checkbox
            label='Member Approval Required'
            id='member-approval-required'
            ariaDescribedBy='member-approval-required-description'
            checked={ requireApproval }
            disabled={ true } // disabling by default until invites are supported
          />
          <p id='member-approval-required-description' className='input-field-support-text'>When a user tries to join the group, an admin (or higher) within this group must first approve the request before the member is allowed to join.</p>
        </Setting>
        <Setting>
          <Checkbox
            label='Offer Carbon Offset Matching'
            id='offer-carbon-matching'
            ariaDescribedBy='offer-matching-description'
            checked={ offsetMatching }
            onChange={ onOfferOffsetMatchChange }
          />
          <p id='offer-matching-description' className='input-field-support-text'>Select an offset pledge to match any carbon offsets purchased by the members of this group.</p>
          { renderMatchFields() }
        </Setting>
      </div>
    </GroupSettingsInner>
  );

  const renderGroupCodeSubFieldSection = () => {
    let subText: JSX.Element;
    if (!!groupCodeError) {
      subText = <p className='code-availability input-field-error'>{ groupCodeError }</p>;
    }

    if (!subText && !!groupCodeAvailability && groupCodeEngaged.current) {
      subText = groupCodeAvailability === GroupCodeAvailability.Available
        ? <p className='code-availability input-field-success'>This code is available.</p>
        : <p className='code-availability input-field-error'>This code is not available.</p>;
    }

    return (
      <GroupCodeSubFieldSection>
        { subText || <div /> }
        <Button
          className='random-code-cta'
          disabled={ group.checkingCode }
          kind={ ButtonKind.PrimaryWithIcon }
          onClick={ generateRandomGroupCode }
        >
          Get Random Code
        </Button>
      </GroupCodeSubFieldSection>
    );
  };

  const renderHeader = () => (
    <GroupSettingsHeader>
      <H1>Settings</H1>
      <CTAs
        className='group-settings-ctas'
        ctas={ getSettingsCtas() }
      />
    </GroupSettingsHeader>
  );

  const renderMatchFields = () => {
    if (!offsetMatching) return null;

    return (
      <MatchSetting className='indent'>
        <MatchTextFieldContainer>
          <TextField
            className='text-field'
            id='group-match-percent-field'
            fieldKind={ TextFieldKind.Pill }
            label='Percent'
            onChange={ onMatchAmountChange('percent') }
            value={ `${typeof offsetMatchPercent === 'number' && offsetMatchPercent >= 0 ? offsetMatchPercent : ''}` }
          />
          { !!offsetMatchPercentError && <p className='input-field-error'>{ offsetMatchPercentError }</p> }
        </MatchTextFieldContainer>
        <MatchTextFieldContainer>
          <TextField
            className='text-field'
            id='group-match-max-field'
            ariaDescribedBy='max-match-description'
            fieldKind={ TextFieldKind.Pill }
            label={ (
              <span className='title-with-info'>
                Max Amount
                <FieldWithToolTip
                  align='center'
                  delay={ 0 }
                  placement={ [ 'top', 'bottom' ] }
                  tooltip='The max dollar amount that will be matched per member, per year.'
                >
                  <InfoIcon fill={ theme.colors.darkGray3 } />
                </FieldWithToolTip>
              </span>
            ) }
            onChange={ onMatchAmountChange('max') }
            value={ `${typeof offsetMatchMax === 'number' && offsetMatchMax >= 0 ? offsetMatchMax : ''}` }
          />
          <p id='max-match-description' className='input-field-support-text'>If left empty, or set to 0, no max will be set on this pledge.</p>
        </MatchTextFieldContainer>
      </MatchSetting>
    );
  };

  const renderReadOnlySettings = () => {
    const domainRestrictions = group.settings.allowDomainRestriction ? 'enabled' : 'disabled';
    const carbonOffsetMatching = group.settings.matching.enabled ? 'enabled' : 'disabled';

    const domains = group.settings.allowDomainRestriction
      ? group.domains.map(d => <p key={ d }>{ d }</p>)
      : [];
    
    const carbonOffsetMatchingValues = group.settings.matching.enabled
      ? [
        <p key='offset-matching-percent'>Percent: { group.settings.matching.matchPercentage }%</p>,
        <p key='offset-matching-max'>Max Amount: ${ group.settings.matching.maxDollarAmount }</p>,
      ]
      : [];

    return (
      <GroupSettingsInner>
        <div>
          <GroupSettingField>
            <H3>Name:</H3>
            <div className='setting-field-alt-value'>{ group.name }</div>
          </GroupSettingField>
          <GroupSettingField>
            <H3>Group Code:</H3>
            <div className='setting-field-alt-value'>{ group.code }</div>
          </GroupSettingField>
          <GroupSettingField>
            <H3>Group Privacy Status:</H3>
            <H3 className='setting-field-value enabled'>{ group.settings.privacyStatus }</H3>
          </GroupSettingField>
        </div>
        <div>
          <GroupSettingField className='unsupported'>
            <H4>Allow Invites:</H4>
            <H4 className='setting-field-value disabled'>Disabled</H4>
          </GroupSettingField>
          <GroupSettingField>
            <H4>Allow Email Domain Restrictions:</H4>
            <H4 className={ `setting-field-value ${domainRestrictions}` }>
              { domainRestrictions }
            </H4>
            { domains }
          </GroupSettingField>
          <GroupSettingField className='unsupported'>
            <H4>Allow Sub-Groups:</H4>
            <H4 className='setting-field-value disabled'>Disabled</H4>
          </GroupSettingField>
          <GroupSettingField className='unsupported'>
            <H4>Member Approval Required:</H4>
            <H4 className='setting-field-value disabled'>Disabled</H4>
          </GroupSettingField>
          <GroupSettingField>
            <H4>Enable Carbon Offset Matching:</H4>
            <H4 className={ `setting-field-value ${carbonOffsetMatching}` }>
              { carbonOffsetMatching }
            </H4>
            { carbonOffsetMatchingValues }
          </GroupSettingField>
        </div>
      </GroupSettingsInner>
    );
  };

  const renderUrlContainer = () => (
    <div className='group-code-copy-container'>
      <CTAs
        className='group-copy-cta'
        ctas={ getCopyUrlCta() }
      />
    </div>
  );

  const onCopyUrlClick = () => {
    navigator.clipboard.writeText(`${buildBaseKarmaUrl('karma')}?groupCode=${group.code}`);
    toaster.push({ message: 'Url Copied to Clipboard.' });
  };
  
  if (!group) return null;
  
  return (
    <GroupSettingsContainer className={ className }>
      { renderHeader() }
      { isEditMode ? renderEditableSettings() : renderReadOnlySettings() }
      { isEditMode && <CTAs className='settings-bottom-row' ctas={ getSettingsCtas() } /> }
      { isEditMode ? null : renderUrlContainer() }
    </GroupSettingsContainer>
  );
};

const GroupSettingsAsObserver = observer(GroupSettingsBase);
export const GroupSettings = withTheme(GroupSettingsAsObserver);
