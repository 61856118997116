import styled from 'styled-components';
import { TextField } from '../../../../components/TextField';
import { FlexColCenter } from '../../../../styles/styles';

export const SuccessPageContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  justify-content: center;

  .karma-logo {
    text-align: center;
    width: 113px;
  }
`;

export const ResultInfoSection = styled.div`
  text-align: center;
  max-width: 500px;

  .email-text {
    font-weight: 600;
  }

  .download-app-text {
    font-weight: 600;
  }
`;

export const FormField = styled(TextField)`
  &.error {
    input.pill-text-field,
    input.pill-text-field:focus:not(:disabled) { // required to over-specify focus state of default text input
      border: 2px solid ${({ theme }) => theme.colors.red};
      box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.red};
    }
  }

  input.pill-text-field:focus:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 1px inset ${({ theme }) => theme.colors.primary};
  }

  .toggle-password-visibility {
    svg {
      height: auto;
      max-height: unset;
      min-height: unset;
      min-width: 28px;
      min-width: 28px;
    }
  }
`;

export const AppButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;

  .google-button {
    height: 60px;
  }
`;

export const DisclaimerWrapper = styled.div`
  text-align: center;
  width: 70%;
  max-width: 800px;
  padding: 20px 0 0;
  margin: 0 auto;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 20px 0 80px;
  }

  @media (${({ theme }) => theme.breakpoints.xsMax}) {
    max-width: 90%;
  }

  p {
    font-size: 10px;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.lightGray1};
    margin-bottom: 0;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 12px;
    }
  }
`;

export const ButtonsSection = styled.div`
  ${FlexColCenter}
  align-items: center;

  p {
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
  }
`;
