import React from 'react';
import { BenefitCardContainer, BenefitCardVariant, GraphicContainer, InnerWrapper } from './styles';

export interface IBenefitCard {
  title: string;
  description?: string | JSX.Element;
  firstLine?: string;
  learnMore?: JSX.Element;
  graphic?: JSX.Element;
  variant?: BenefitCardVariant;
}

interface IProps extends IBenefitCard {
  className?: string;
}

export const BenefitCard: React.FC<IProps> = ({
  className,
  title,
  description,
  firstLine,
  learnMore,
  variant,
  graphic,
}) => (
  <BenefitCardContainer
    className={ className }
    variant={ variant }
    alignContent={ !!graphic ? 'left' : 'center' }
  >
    <InnerWrapper>
      { !!firstLine ? <p className='description'>{ firstLine }</p> : null }
      <p className='title'>{ title }</p>
      { !!description && (typeof description === 'string' ? <p className='description'>{ description }</p> : description) }

      { !!learnMore ? learnMore : null }
    </InnerWrapper>
    { !!graphic ?<GraphicContainer>{ graphic }</GraphicContainer> : null }
  </BenefitCardContainer>
);
