import { observer } from 'mobx-react';
import React from 'react';
import { CompareCompaniesModel, ICompareCompany } from '../../models/compare-companies';
import { ValuesComparison } from '../ValuesComparison';
import { ComparisonDataContainer } from './styles';
import { CompanyImpactCategories } from '../CompanyImpactCategories';

interface IProps {
  className?: string;
  company1: ICompareCompany;
  company2: ICompareCompany;
  company3: ICompareCompany;
  CompareModel: CompareCompaniesModel;
  shareSection?: JSX.Element;
}

const ComparisonDataBase: React.FC<IProps> = ({
  className = '',
  company1,
  company2,
  company3,
  CompareModel,
}) => (
  <ComparisonDataContainer className={ className }>
    <ValuesComparison
      className='values-comparison'
      anchorValues={ company1?.values }
      comparisonValues1={ company2?.values }
      comparisonValues2={ company3?.values }
      anchorCompanyName={ company1?.company.companyName }
      comparison1CompanyName={ company2?.company.companyName }
      comparison2CompanyName={ company3?.company.companyName }
      anchorCompany={ company1?.company }
      comparisonCompany1={ company2?.company }
      comparisonCompany2={ company3?.company }
    />
    <CompanyImpactCategories noVS className='impact-efforts' companies={ CompareModel.loadedCompanies.map(c => c.company) } />
  </ComparisonDataContainer>
);

export const ComparisonData = observer(ComparisonDataBase);
