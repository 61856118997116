import React, { useMemo } from 'react';
import { Bar, GraphicBarWrapper, Graphic, CompanyRatingGraphicContainer, GraphicLabels } from './styles';

interface IProps {
  className?: string;
  score: number;
}

interface IRanges {
  min: number;
  max: number;
  value: string;
}

const ratingRanges: IRanges[] = [
  { min: -16, max: -12, value: 'negative start' },
  { min: -11.9, max: -8, value: 'negative' },
  { min: -7.9, max: -4, value: 'negative' },
  { min: -3.9, max: -0.1, value: 'negative' },
  { min: 0, max: 3.9, value: 'neutral' },
  { min: 4, max: 7.9, value: 'neutral' },
  { min: 8, max: 11.9, value: 'positive' },
  { min: 12, max: 16, value: 'positive end' },
];

const ratingLabels: any[] = [-16, -12, -8, -4, 0, 4, 8, [12, 16]];

const renderRatingLabels = (labels: any[]) => labels.map((label) => {
  const className = Array.isArray(label) ? 'label last' : 'label';
  const key = Array.isArray(label) ? `${label.join('-')}` : label;
  const content = Array.isArray(label) ? label.map((n) => <div key={ n }>{ n }</div>) : label;

  return (
    <div className={ className } key={ key }>
      { content }
    </div>
  );
});

export const CompanyRatingGraphic: React.FC<IProps> = ({
  className = '',
  score,
}) => {
  const renderRanges = useMemo(() => {
    const elements = [];
    
    for (const [index, range] of ratingRanges.entries()) {
      const { min, max, value } = range;
      
      if (score >= min && score <= max) {
        elements.push(<div className={ `range ${value} selected` } key={ index } />);
      } else {
        elements.push(<div className={ `range ${value}` } key={ index } />);
      }
    }

    return elements;
  }, [score]);

  const renderGraphicBar = useMemo(() => {
    const scorePercent = ((score + 16) / 32) * 100;

    return (
      <GraphicBarWrapper location={ `${scorePercent}%` } >
        <span className='bar-number'>{ score }</span>
        <Bar />
      </GraphicBarWrapper>
    );
  }, [score]);

  return (
    <CompanyRatingGraphicContainer className={ className }>
      <Graphic>
        { renderRanges }
        { renderGraphicBar }
      </Graphic>
      <GraphicLabels>
        { renderRatingLabels(ratingLabels) }
      </GraphicLabels>
    </CompanyRatingGraphicContainer>
  );
};
