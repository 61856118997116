import React, { FC, ReactNode, useContext } from 'react';
import { ThemeProvider as StylesThemeProvider } from 'styled-components';
import { ThemeContext } from '../../contexts/theme-store';

interface IProps {
  children: ReactNode;
}

export const ThemeProvider: FC<IProps> = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <StylesThemeProvider theme={ theme }>
      { children }
    </StylesThemeProvider>
  );
};
