import React from 'react';
import { IComparisonValue } from '..';
import { ValueRowContainer } from './styles';
import { ValueCircle } from './ValueCircle';

interface IProps {
  className?: string;
  value: IComparisonValue;
  hasComparison1: boolean;
  hasComparison2: boolean;
}

export const ValueRow: React.FC<IProps> = ({
  className = '',
  value,
  hasComparison1,
  hasComparison2,
}) => (
  <ValueRowContainer className={ className }>
    <div className='value-title'>
      { value.name }
    </div>
    <div className='value-comparison-container'>
      <div className='left-cell cell'>
        <ValueCircle 
          hasValue={ !!value.anchor }
        />
      </div>
      <div className='right-cell cell'>
        {
          !!hasComparison1 && 
            <ValueCircle 
              hasValue={ !!value.comparison1 }
            />
        }
      </div>
      {
        !!hasComparison2 && 
          <div className='right-cell cell'>
            <ValueCircle 
              hasValue={ !!value.comparison2 }
            />
          </div>
      }
    </div>
  </ValueRowContainer>
);
