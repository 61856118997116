import styled from 'styled-components';
import { FlexColCenter, FlexCol, FlexRow } from '../../styles/styles';

export const ComparisonDataContainer = styled.div`
  .impact-efforts {
    background: none;
    max-width: 930px;
    margin: 0 auto;

    .impact-category-section {
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 80px;
    }

    .impact-category-header {
      flex-direction: column;
      margin-bottom: 30px;
    }
  }

  .header-section {
    ${FlexRow}
    margin-bottom: 20px;
    
    .social-share-button {
      margin-left: 10px;
    }
  }

  .overall-score, .impact-efforts {
    padding: 30px 15px 50px;
    
    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      .rating-bar {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
`;

export const CompanyOverviewContainer = styled.div`
  width: 100%;
`;

export const ComparisonCompanyContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  width: 100%;

  .company-holder {
    ${FlexCol}
    margin-top: 5px;
    gap: 5px;

    @media (${({ theme }) => theme.breakpoints.smMin}){
      flex-direction: row;
      gap: 20px
    }

    .company1-name {
      display: flex;
      justify-content: center;
      max-height: 25px;
      gap: 7px;

      p {
        font-weight: 600;
      }
  
      .circle1 {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.darkGray3 };
      }
    }
  
    .company2-name {
      display: flex;
      justify-content: center;
      max-height: 25px;
      gap: 7px;

      p {
        font-weight: 600;
      }
  
      .circle2 {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.darkGray3 };
        opacity: 0.2;
      }
    }
  
    .not-winner {
      opacity: 0.2;
    }
  }
`;
