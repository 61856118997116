import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../../styles/components/containers';
import { FlexColCenter } from '../../../styles/styles';

export const LoveKarmaWalletSectionContainer = styled(Section)`
  background-color: ${({ theme }) => theme.colors.secondary};
  overflow: hidden;
  padding: 100px 15px;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    padding: 150px 15px;
  }

  .karma-card-graphic {
    display: none;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      display: block;
      left: 75vw;
      position: absolute;
      transform: translateY(-50%);
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) { 
      left: 73vw;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: unset;
    }

    @media (${({ theme }) => theme.breakpoints.xlMin}) { 
      left: 70vw;
    }
  }

  .planting-tree-graphic {
    display: none;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      bottom: -24%;
      display: block;
      height: auto;
      position: absolute;
      right: 75vw;
      transform: translateY(-50%);
      width: 280px;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) { 
      position: absolute;
      right: 73vw;
      top: 50%;
      transform: translateY(-50%);
      width: unset;
    }

    @media (${({ theme }) => theme.breakpoints.xlMin}) { 
      right: 70vw;
    }
  }
`;

export const LoveKarmaWalletSectionInnerContainer = styled(MaxBoundaryContainer)`
  ${FlexColCenter}
  align-items: center;
  text-align: center;

  h2 {
    font-size: 40px;
  } 

  .adoption-rate-text {
    font-size: 34px;
    font-weight: 500;
    line-height: 2.5rem;
    margin: 5px 0;
    max-width: 500px;

    span {
      font-weight: 700;
    }
  }

  .tagline-container {
    margin-top: 15px;

    p {
      ${FlexColCenter}
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 5px;
      max-width: 325px;

      @media (${({ theme }) => theme.breakpoints.smMin}) {
        max-width: unset;
      }
    }
  }
`;
