import React from 'react';
import { EmployerPageContainer } from './styles';
import { EmployerHeroSection } from './HeroSection';
import { ByTheNumbersSection } from './ByTheNumbersSection';
import { BetterFutureSection } from './BetterFutureSection';
import { LoveKarmaWalletSection } from './LoveKarmaWalletSection';
import { DontTakeItFromUsSection } from './DontTakeItFromUsSection';

interface IProps {
  className?: string;
}

export const EmployerPage: React.FC<IProps> = ({
  className,
}) => (
  <EmployerPageContainer className={ className } title='Karma Wallet for Employers'>
    <EmployerHeroSection />
    <ByTheNumbersSection />
    <BetterFutureSection />
    <LoveKarmaWalletSection />
    <DontTakeItFromUsSection />
  </EmployerPageContainer>
);
