import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection } from '../../styles/components/containers';

export const PrivacyPolicyContainer = styled(MainContainer)``;

export const PrivacyPolicySectionWrapper = styled(MaxBoundarySection)`
  h1, h2, h3, h4, h5, .answer-text {
    margin-bottom: 10px;
  }

  .center {
    text-align: center;
  }

  .section-title-text {
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.primary};
  }

  li {
    margin-bottom: 20px;
  }

  a {
    color: ${({ theme }) => theme.colors.success};
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.lightBlue1};
      text-decoration: underline;
    }
  }
`;
