import React from 'react';
import { ApplyLoadingOverlayContainer } from './styles';

interface IProps {
  className?: string;
  display: boolean;
}

const loadingText = 'Processing... Please reload the page if there is no update after 1 minute.';

export const ApplyLoadingOverlay: React.FC<IProps> = ({
  className = '',
  display,
}) => {
  if (!display) return null;
  
  return (
    <ApplyLoadingOverlayContainer
      className={ className }
      text={ loadingText }
    />
  );
};
