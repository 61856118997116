import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../styles/styles';

export const OurImpactContentBlockContainer = styled.div`
  ${FlexRow}
  justify-content: space-between;
  margin-top: 120px;
  gap: 10%;
  align-items: center;

  &.flipped {
    flex-direction: row-reverse;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      flex-direction: column;
      margin-top: 80px;
    }

    .graphic-content {
      text-align: left;
    }
  }

  .section {
    width: 100%;

    &:first-of-type {
      padding-right: 20px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    flex-direction: column;
    gap: 15px;
    margin-top: 80px;
  }
`;

export const BodyContainer = styled.div`
  ${FlexCol}
  flex:2;
  
  .content-type {
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
  }

  .content-block-cta {
    margin: 20px auto 0 0;
  }
`;

export const GraphicContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex:1;
  justify-content: center;

  img {
    width: 100%;
    max-height:401px;
    height: auto;
    
    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      max-width: 288px;
    }
  }
`;
