import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { GroupMemberModel, GroupModel } from '../../models/group';
import { BanOption, GroupMembersListBottomRow, GroupMembersListContainer, GroupMembersListCount, GroupMembersListInner, ListHeader, MemberCheckboxContainer, MemberDataContainer, MemberItem, NoMembers } from './styles';
import { IMoreMenuOption, MoreMenu } from '../MoreMenu';
import { useToaster } from '../../contexts/toaster-store';
import { ToastType } from '../../models/toaster';
import { IUserGroup, UserGroupRole, UserGroupStatus } from '../../models/user-groups';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { Checkbox } from '../Checkbox';
import { CTAs } from '../CTAs';
import { ButtonKind } from '../Button/styles';
import { UserRoles } from '../../models/users';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { FieldWithToolTip } from '../FieldWithToolTip';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useUserSession } from '../../contexts/user';
import { Waypoint } from 'react-waypoint';

interface IProps extends IThemeProps {
  className?: string;
  group: GroupModel;
}

enum MemberOptions {
  Approve = 'approve',
  Remove = 'remove',
  Ban = 'ban',
  Unban = 'unban',
}

const GroupMembersListBase: React.FC<IProps> = ({
  className = '',
  group,
  theme,
}) => {
  const userSession = useUserSession();
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [usersGroup, setUsersGroup] = useState<IUserGroup>(null);
  const [selectableMembersCount, setSelectableMembersCount] = useState(0);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [allMembersSelected, setAllMembersSelected] = useState(false);

  const loadMore = () => {
    group?.members?.loadMore()
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Group Members',
          message: err.message,
        });
      });
  };

  useEffect(() => {    
    if (!group?.members?.firstPageLoaded) {
      loadMore();
    }

    userSession.loadGroups()
      .then(() => {
        setUsersGroup(userSession.groups.find(g => g.group._id === group?._id));
      })
      .catch((err) => {
        errorMessages.push({
          title: 'Error Loading Groups',
          message: err.message,
        });
      });
  }, [group]);

  useEffect(() => {
    if (!!group?.members.results.length && !!usersGroup) {
      let count = 0;
      for (const member of group.members.results) {
        if (showMemberCheckbox(member)) {
          count += 1;
        }
      }
      setSelectableMembersCount(count);
    }
  }, [group?.members, userSession?.groups, usersGroup, group?.members?.results?.length]);

  const onMemberSelectionChange = useCallback((member: GroupMemberModel) => () => {
    const isSelected = selectedMembers.find(sm => sm === member._id);
    let _selectedMembers = [...selectedMembers];
    
    if (isSelected) {
      _selectedMembers = _selectedMembers.filter(sm => sm !== member._id);
    } else {
      _selectedMembers.push(member._id);
    }

    setAllMembersSelected(!!selectableMembersCount && _selectedMembers.length === selectableMembersCount);
    setSelectedMembers(_selectedMembers);
  }, [selectedMembers, allMembersSelected, selectableMembersCount]);

  const getMoreMenuOptions = useCallback((member: GroupMemberModel) => {
    const menuOptions: IMoreMenuOption[] = [];

    if (
      group?.settings?.approvalRequired
      && (
        (userSession.role === UserRoles.Admin || userSession.role === UserRoles.SuperAdmin)
        || (member.status !== UserGroupStatus.Approved && member.status !== UserGroupStatus.Banned)
      )
    ) {
      menuOptions.push({
        id: MemberOptions.Approve,
        context: MemberOptions.Approve,
        content: 'Approve',
      });
    }

    if (member.status !== UserGroupStatus.Removed) {
      menuOptions.push({
        id: MemberOptions.Remove,
        context: MemberOptions.Remove,
        content: 'Remove',
      });
    }

    if (member.status !== UserGroupStatus.Banned) {
      menuOptions.push({
        id: MemberOptions.Ban,
        context: MemberOptions.Ban,
        content: <BanOption>Ban</BanOption>,
      });
    } else {
      menuOptions.push({
        id: MemberOptions.Unban,
        context: MemberOptions.Unban,
        content: 'Unban',
      });
    }
    
    return menuOptions;
  }, []);

  const onBatchStatusUpdate = useCallback((status: UserGroupStatus) => () => {
    group?.updateMembersStatus(status, selectedMembers)
      .then(() => {
        toaster.push({ message: `${selectedMembers.length} members updated.` });
      })
      .catch(err => {
        errorMessages.push({
          title: 'Error Updating Members',
          message: err.message,
        });
      });
  }, [allMembersSelected, selectedMembers]);

  const onMoreMenuOptionClick = useCallback((member: GroupMemberModel) => async (option: IMoreMenuOption) => {
    switch (option.context) {
      case MemberOptions.Approve:
        try {
          await group?.updateMemberStatus(member._id, UserGroupStatus.Approved);
          toaster.push({ message: 'User approved successfully.' });
        } catch (err: any) {
          errorMessages.push({
            title: 'Error Approving Member',
            message: err.message,
          });
        }
        break;
      case MemberOptions.Remove:
        try {
          await group?.updateMemberStatus(member._id, UserGroupStatus.Removed);
          toaster.push({ message: 'User removed successfully.' });
        } catch (err: any) {
          errorMessages.push({
            title: 'Error Removing Member',
            message: err.message,
          });
        }
        break;
      case MemberOptions.Ban:
        try {
          await group?.updateMemberStatus(member._id, UserGroupStatus.Banned);
          toaster.push({ message: 'User banned successfully.' });
        } catch (err: any) {
          errorMessages.push({
            title: 'Error Banning Member',
            message: err.message,
          });
        }
        break;
      case MemberOptions.Unban:
        try {
          await group?.updateMemberStatus(member._id, UserGroupStatus.Approved);
          toaster.push({ message: 'User unbanned successfully.' });
        } catch (err: any) {
          errorMessages.push({
            title: 'Error Unbanning Member',
            message: err.message,
          });
        }
        break;
      default:
        toaster.push({ message: `Invalid Option ${option.context}.`, type: ToastType.Error });
        break;
    }
  }, [group]);
  
  const onSelectAllChange = useCallback(() => {
    const newState = !allMembersSelected;
    const _selectedMembers = newState && !!selectableMembersCount && selectedMembers.length !== selectableMembersCount
      ? group?.members.results.filter(m => showMemberCheckbox(m)).map(m => m._id)
      : [];
    
    setSelectedMembers(_selectedMembers);
    setAllMembersSelected(newState);
  }, [allMembersSelected, selectedMembers, selectableMembersCount, group]);
 
  const renderMoreMenu = (member: GroupMemberModel) => {
    if (member._id === userSession._id) return null;
    if (
      userSession.role !== UserRoles.Admin
      && userSession.role !== UserRoles.SuperAdmin
      && !GroupMemberModel.hasPermissionToModify(usersGroup.role, member.role)
    ) {
      return null;
    }

    return (
      <MoreMenu
        options={ getMoreMenuOptions(member) }
        onOptionClick={ onMoreMenuOptionClick(member) }
      />
    );
  };

  const renderMembers = () => {
    let members: JSX.Element[] = [];

    if (!!group?.members?.results?.length) {
      members = group?.members.results.map(member => (
        <MemberItem
          key={ member._id }
          className={ member.status }
        >
          <MemberCheckboxContainer>
            {
              showMemberCheckbox(member) && (
                <Checkbox
                  checkColor={ theme.colors.darkGreen1 }
                  checked={ !!selectedMembers.find(sm => sm === member._id) || allMembersSelected }
                  id='member-checkbox'
                  label='Member Checkbox'
                  labelHidden
                  onChange={ onMemberSelectionChange(member) }
                />
              )
            }
          </MemberCheckboxContainer>
          <MemberDataContainer>
            <FieldWithToolTip
              className='member-name'
              tooltip={ member._id === userSession._id ? 'You' : member.name }
            >
              { member._id === userSession._id ? 'You' : member.name }
            </FieldWithToolTip>
            <FieldWithToolTip
              className='member-email'
              tooltip={ member.email }
            >
              { member.email }
            </FieldWithToolTip>
            <div className='member-role'>{ member.role }</div>
            <div className='member-status'>{ member.status }</div>
            <div>
              { renderMoreMenu(member) }
            </div>
          </MemberDataContainer>
        </MemberItem>
      ));
    }
    
    if (!group?.members?.allResultsFetched && !group?.members.busy) {
      members.push(<Waypoint key='waypoint' onEnter={ loadMore } topOffset={ 200 } />);
    } else if ( group?.members?.firstPageLoaded && !group?.members?.results?.length) {
      members.push((
        <NoMembers key='no-members'>
          No Members Yet...
        </NoMembers>
      ));
    }

    if (group?.members?.busy) {
      members.push((<LoadingSpinner className='loading-spinner' key='loading-spinner' />));
    }

    return members;
  };

  const renderMembersCount = () => {
    if (!group) return null;

    if (allMembersSelected) {
      return <GroupMembersListCount>{ `${selectableMembersCount} / ${group.members.total} members selected` }</GroupMembersListCount>;
    }

    if (selectedMembers.length) {
      return <GroupMembersListCount>{ `${selectedMembers.length} / ${group.members.total} members selected` }</GroupMembersListCount>;
    }

    return <GroupMembersListCount>{ `${group.members.total} members` }</GroupMembersListCount>;
  };

  const showMemberCheckbox = (member: GroupMemberModel) => {
    if (!member.isActiveMember) return false;

    // karma admins have full permissions, regardless of group role
    if ((userSession.role === UserRoles.Admin || userSession.role === UserRoles.SuperAdmin) && userSession._id !== member._id) return true;

    return GroupMemberModel.hasPermissionToModify(usersGroup?.role, member.role);
  };

  if (!userSession.groups?.length || userSession.loadingGroups || (userSession.role === UserRoles.None && !usersGroup)) return null;

  // only karma admins or group admins should be able to view this component
  if (
    userSession.role !== UserRoles.Admin
    && userSession.role !== UserRoles.SuperAdmin
    && usersGroup.role !== UserGroupRole.Admin
    && usersGroup.role !== UserGroupRole.SuperAdmin
    && usersGroup.role !== UserGroupRole.Owner
  ) {
    return null;
  }

  return (
    <GroupMembersListContainer className={ className }>
      { renderMembersCount() }
      <ListHeader>
        <MemberCheckboxContainer>
          <Checkbox
            checkColor={ theme.colors.darkGreen1 }
            checked={ allMembersSelected }
            id='select-all-members-checkbox'
            label='Select All Members'
            labelHidden
            onChange={ onSelectAllChange }
          />
        </MemberCheckboxContainer>
        <MemberDataContainer>
          <div className='dt-header-item member-name'>Name</div>
          <div className='dt-header-item member-email'>Email</div>
          <div className='dt-header-item member-role'>Role</div>
          <div className='dt-header-item member-status'>Status</div>
          <div className='m-header-item'>Members</div>
          <div />
        </MemberDataContainer>
      </ListHeader>
      <GroupMembersListInner>
        { renderMembers() }
      </GroupMembersListInner>
      <GroupMembersListBottomRow>
        { renderMembersCount() }
        <CTAs
          className=''
          ctas={ [
            {
              disabled: (!allMembersSelected && !selectedMembers.length),
              id: 'removeAll',
              text: 'Remove All',
              kind: ButtonKind.Primary,
              onClick: onBatchStatusUpdate(UserGroupStatus.Removed),
            },
            {
              disabled: (!allMembersSelected && !selectedMembers.length),
              id: 'banAll',
              text: 'Ban All',
              kind: ButtonKind.Danger,
              onClick: onBatchStatusUpdate(UserGroupStatus.Banned),
            },
          ] }
        />
      </GroupMembersListBottomRow>
    </GroupMembersListContainer>
  );
};

const GroupMembersListAsObserver = observer(GroupMembersListBase);
export const GroupMembersList = withTheme(GroupMembersListAsObserver);
