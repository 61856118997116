import styled from 'styled-components';
import { FlexCol, FlexRow, TruncateText } from '../../styles/styles';
import { CompanyLogo } from '../CompanyLogo';
import { Subcategories } from '../../models/unsdgs';

export const CompareCompaniesCardContainer = styled.div`
  ${FlexCol}
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  min-width: 294px;
  min-height: 90px;
  padding: 9px 20px;

  &.our-pick {
    border: 5px solid ${({ theme }) => theme.colors.primary};
    padding: 5px 16px;
  }

  @media (${({ theme }) => theme.breakpoints.xsMax}) {
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    width: 350px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    gap: 12px;
    padding: 20px 20px 28px 20px;
    max-width: 294px;
    max-height: 394px;

    &.our-pick {
      padding: 16px 16px 24px 16px;
    }
  }
`;

export const AccordionContainer = styled.div`
  .accordion {
    padding: unset;

    svg {
      height: 14px;
      margin: auto;
    }

    .header-row-container {
      margin: 3px 0 0 0;
    }
  }

  .chevron-wrapper {
    display: flex;
  }

  .expanded {
    .header-row-container {
      border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray};
      padding-bottom: 10px;
    }
  }

  .header {
    margin-right: 10px;
    width: 100%;
  }
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: none;
  }
`;

export const TopSectionContainer = styled.div`
  ${FlexRow}
  align-items: center;
  justify-content: space-between;
  min-height: 30px;

  .icon {
    margin-left: auto;

    svg {
      height: 10px;
      width: 10px;
    }
  }

  .our-pick {
    ${FlexRow}
    align-items: center;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 48px;
    font-size: 12px;
    font-weight: 600;
    gap: 8px;
    line-height: normal;
    padding: 7px 16px;

    .our-pick-text {
      color: ${({ theme }) => theme.colors.offWhite};
    }

    svg {
      height: 14px;
      width:14px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    min-height: 48px;

    .icon {
      margin-left: 14px;
    }

    .our-pick {
      margin-left: auto;
      padding: 5px 5px;

      .our-pick-text {
        display: none;
      }

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
`;

export const GraphicSectionContainerWrapper = styled.div`
  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    max-width: 80%;
    &.desktop {
      display: none;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    &.mobile {
      display: none;
    }
  }
`;

export const GraphicSectionContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 11px;
  
  .companyName {
    ${TruncateText}
    font-size: 18px;
    font-weight: 600;
    max-width: 100%;
    text-align: center;
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    ${FlexRow}
    gap: 8px;

    .companyName {
      font-size: 12px;
    }
  }
`;

export const CompareCompanyLogo = styled(CompanyLogo)`
  img {
    height: 48px;
    width: 48px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      height: 110px;
      width: 110px;
    }
  }

  .default-company-logo-container {
    min-width: 48px;
    max-height: 48px;

    svg {
      height: 48px;
      width: 48px;
  
      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        height: 110px;
        width: 110px;
      }
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      max-height: 110px;
    }
  }
`;

export const SubcategoriesContainer = styled.div`
  ${FlexCol}
  gap: 9px;
  padding: 8px 0px;

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    &.desktop {
      display: none;
    }
  }
`;

export const SubcategoryItem = styled.div`
  ${FlexRow}
  justify-content: space-between;

  .name {
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }

  .score {
    align-items: center;
    border-radius: 2px;
    color: ${({ color, theme }) => color === Subcategories.DiversityInclusion || color === 'total' ? theme.colors.white : theme.colors.black};
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    line-height: normal;
    max-height: 19px;
    min-width: 30px;
    padding: 4px;
    background-color: ${ ( { color, theme } ) => {
    switch (color) {
      case Subcategories.ClimateAction:
        return theme.colors.blue;
      case Subcategories.Sustainability:
        return theme.colors.gold;
      case Subcategories.CommunityWelfare:
        return theme.colors.secondary;
      case Subcategories.DiversityInclusion:
        return theme.colors.primary; 
      default:
        return theme.colors.black;
    }}};
  }

  &.total {
    border-top: solid 1px ${({ theme }) => theme.colors.lightGray1};
    padding-top: 10px;
  }
`;
