import styled from 'styled-components';
import { FlexCol, FlexColCenter } from '../../styles/styles';

export const UserAccountMenuContainer = styled.div``;

export const OptionsContainer = styled.div`
  ${FlexCol}
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  justify-content: space-between;
  margin: 0;
  min-width: 231px;
  overflow-y: scroll;
  padding: 0;
  scrollbar-width: none;
  z-index: 999;

  .mobile-nav-user-menu-option-container {
    ${FlexCol}
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const UserInfoContainer = styled.div`
  ${FlexCol}
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 20px;

  .user-info {
    font-weight: 600;
    margin-bottom: 0px;
  }

  .user-name {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 15px;
  }

  .user-email {
    color: ${({ theme }) => theme.colors.grey3};
    font-size: 12px;
    font-weight: 500;
  }
`;

export const NavigationOptionsContainer = styled.div`
  ${FlexCol}
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;

  .menu-option {
    border-radius: 0;
    font-weight: 600;
    font-size: 13px;
    justify-content: center;
    max-width: 150px;
    padding: 10px;
    width: 80%;

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey3};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const PageNavigationLinks = styled.div`
  ${FlexColCenter}
  align-items: center;
  gap: 15px;
  margin: 0;
  width: 100%;

  .user-menu-nav-link {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
  }
`;
