import React from 'react';
import { CompanyModel, IPartnerCompany } from '../../models/companies';
import { DefaultCompanyLogo } from '../svgs/graphics/DefaultCompanyLogo';
import { CompanyLogoContainer, ICompanyLogoProps } from './styles';

interface IProps extends ICompanyLogoProps {
  className?: string;
  company: CompanyModel | IPartnerCompany;
}

export const CompanyLogo: React.FC<IProps> = ({
  className = '',
  company,
  withBorder,
}) => (
  <CompanyLogoContainer
    className={ className }
    withBorder={ withBorder }
  >
    {
      !!company?.logo
        ? <img src={ company.logo } alt={ `Logo for ${company.companyName}` } />
        : <div className='default-company-logo-container'><DefaultCompanyLogo className='default-company-logo' /></div>
    }
  </CompanyLogoContainer>
);
