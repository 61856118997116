import { atom } from 'recoil';
import { IAppVersionAtom } from './types';

export const defaultAppVersionAtom: IAppVersionAtom = {
  appVersion: '',
  error: '',
  loaded: false,
  loading: false,
};

export const appVersionAtom = atom({
  key: 'appVersionState',
  default: defaultAppVersionAtom,
});
