import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const EyeWithSlashIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }eye-with-slash-icon` }
    id={ id }
    width={ 20 }
    height={ 17 }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M3.20708 0.292893C2.81656 -0.0976311 2.1834 -0.0976311 1.79287 0.292893C1.40235 0.683417 1.40235 1.31658 1.79287 1.70711L16.7929 16.7071C17.1834 17.0976 17.8166 17.0976 18.2071 16.7071C18.5976 16.3166 18.5976 15.6834 18.2071 15.2929L16.0223 13.1081C17.8464 11.5893 18.8399 9.74775 19.1409 9.1333C19.3338 8.7395 19.3415 8.27659 19.1424 7.86982C18.8693 7.31168 18.0462 5.78534 16.5777 4.38693C15.0988 2.9786 12.926 1.66667 9.99998 1.66667C8.31348 1.66667 6.86967 2.10386 5.66397 2.74977L3.20708 0.292893ZM7.16021 4.24602L8.33504 5.42085C8.8303 5.15264 9.39785 5 9.99997 5C11.933 5 13.5 6.567 13.5 8.5C13.5 9.10212 13.3473 9.66967 13.0791 10.1649L14.6008 11.6866C15.9962 10.5737 16.8522 9.185 17.2183 8.49999C16.9105 7.924 16.248 6.83476 15.1985 5.83528C13.9785 4.67354 12.2752 3.66667 9.99998 3.66667C8.92505 3.66667 7.98275 3.89055 7.16021 4.24602ZM11.4977 8.58351C11.4992 8.55588 11.5 8.52804 11.5 8.5C11.5 7.67157 10.8284 7 9.99997 7C9.97194 7 9.94409 7.00076 9.91646 7.00227L11.4977 8.58351ZM3.98279 5.17391C4.40274 5.53261 4.45238 6.16383 4.09368 6.58377C3.44488 7.34335 3.01239 8.06818 2.78165 8.5C3.08944 9.07598 3.7519 10.1652 4.80147 11.1647C6.02141 12.3265 7.72473 13.3333 9.99998 13.3333C10.5214 13.3333 11.0094 13.2807 11.4658 13.1871C12.0069 13.0762 12.5354 13.4249 12.6463 13.966C12.7572 14.507 12.4085 15.0355 11.8675 15.1464C11.2797 15.2669 10.6571 15.3333 9.99998 15.3333C7.074 15.3333 4.90113 14.0214 3.42223 12.6131C1.95376 11.2147 1.13067 9.68833 0.857546 9.13019C0.658513 8.72345 0.666148 8.26055 0.859061 7.86673C1.07747 7.42085 1.6338 6.38428 2.57293 5.2848C2.93163 4.86486 3.56285 4.81521 3.98279 5.17391Z' />
  </SvgIcon>
);
