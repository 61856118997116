import React, { createContext } from 'react';
import { AppType } from '../constants';
import { PromosModel } from '../models/promos';

interface IProps {
  app?: AppType;
  children: React.ReactNode;
}

export const PromosContext = createContext<PromosModel>(null);

export const usePromos = () => React.useContext(PromosContext);

export const PromosStore: React.FC<IProps> = ({ children }) => (
  <PromosContext.Provider value={ new PromosModel() }>
    { children }
  </PromosContext.Provider>
);
