import { ICompanyUnsdgScore } from '../models/companies';
import { IUNSDG, IUNSDGCategory, IUNSDGSubCategory } from '../models/unsdgs';

export interface ISortedUNSDGs {
  unsdg: IUNSDG;
  value: number;
}

export interface ISortedSubcategories {
  subcategory: IUNSDGSubCategory;
  unsdgs: ISortedUNSDGs[];
}

export interface ISortedCategories {
  category: IUNSDGCategory;
  subcategories: ISortedSubcategories[];
}

export const sortScores = (unsdgs: ICompanyUnsdgScore[] = []) => {
  const sorted: ISortedCategories[] = [];

  for (let i = 0; i < unsdgs.length; i++) {
    const { unsdg, value } = unsdgs[i];

    while (sorted.length < (unsdg.subCategory.category.index + 1)) {
      sorted.push({
        category: null,
        subcategories: [],
      });
    }

    sorted[unsdg.subCategory.category.index].category = unsdg.subCategory.category;

    while (sorted[unsdg.subCategory.category.index].subcategories.length < (unsdg.subCategory.categoryIndex + 1)) {
      sorted[unsdg.subCategory.category.index].subcategories.push({
        subcategory: null,
        unsdgs: [],
      });
    }

    sorted[unsdg.subCategory.category.index].subcategories[unsdg.subCategory.categoryIndex].subcategory = unsdg.subCategory;

    while (sorted[unsdg.subCategory.category.index].subcategories[unsdg.subCategory.categoryIndex].unsdgs.length < (unsdg.subCategoryIndex + 1)) {
      sorted[unsdg.subCategory.category.index].subcategories[unsdg.subCategory.categoryIndex].unsdgs.push({
        unsdg: null,
        value: 0,
      });
    }

    sorted[unsdg.subCategory.category.index].subcategories[unsdg.subCategory.categoryIndex].unsdgs[unsdg.subCategoryIndex].unsdg = unsdg;
    sorted[unsdg.subCategory.category.index].subcategories[unsdg.subCategory.categoryIndex].unsdgs[unsdg.subCategoryIndex].value = value;
  }

  return sorted;
};
