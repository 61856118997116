import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexRow } from '../../../styles/styles';

export const EmployeeBenefitsSectionContainer = styled(Section)`
  background-color: ${props => props.theme.colors.secondary + '33'};
  min-height: calc(100vh - 75px);
  width: 100%;
`;

export const EmployeeBenefitsSectionInnerContainer = styled(MaxBoundaryContainerLarge)``;

export const HeaderContainer = styled.div`
  text-align: center;

  h2 {
    font-size: 32px;
    line-height: 2.5rem;
  }
`;

export const EmployeeBenefitsContainer = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
  gap: 62px 75px;
  justify-content: center;
  margin-top: 80px;
`;
