import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { StripePaymentFormContainer } from './styles';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';

interface IProps {
  className?: string;
  clientSecret: string;
  onComplete: () => void;
}

const isStaging = window.location.hostname.includes('staging') || window.location.hostname.includes('localhost');
const stagingPublicKey = 'pk_test_51PJFKvIhaX1nT8IVJYLlL3zB8q0qa18xolp0w7An9KcQtYuOVyZ2lpdrPzZvPwhCgcb3sFXWBhjPfObIjFNkADks00w5Zd0B6Y';
// we do not have this yet
const prodPublicKey = 'pk_live_51PJFKvIhaX1nT8IVtcydLzQPkG6begxCD6V8lAtI5a0JHB0LBZ4GH5dbrRF7L9UCOp8VFlkXKPeKRw8EnntAg2lw00khuy1jAk';

export const StripePaymentForm: React.FC<IProps> = ({
  className = '',
  clientSecret,
  onComplete,
}) => {
  const [stripePromise] = useState(() => loadStripe(isStaging ? stagingPublicKey : prodPublicKey));

  return (
    <StripePaymentFormContainer className={ className }>
      <EmbeddedCheckoutProvider
        stripe={ stripePromise }
        options={ { clientSecret, onComplete } }
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </StripePaymentFormContainer>
  );
  
}; 
