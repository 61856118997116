import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { computed, makeObservable, observable } from 'mobx';
import { AppType } from '../constants';
import { BaseModel } from './base';

dayjs.extend(utc);

type PrivateStatementFields = '_busy' |
'_statement';

export interface IOffsetData {
  dollars: number;
  tonnes: number;
}

export interface IMatchedOffsetData extends IOffsetData {
  date: Dayjs;
}

export interface IOffsetsStatement {
  matchPercentage: number,
  maxDollarAmount: number,
  matched?: IMatchedOffsetData;
  toBeMatched: IOffsetData;
  totalMemberOffsets: IOffsetData;
}

export interface IStatementRef {
  _id: string;
  offsets?: IOffsetsStatement;
  date: Dayjs;
}

export class StatementModel extends BaseModel {
  private _busy = false;
  private _statement: IStatementRef = null;

  constructor(statementInfo: IStatementRef, app?: AppType) {
    super({}, null, app);
    makeObservable<StatementModel, PrivateStatementFields>(this, {
      _busy: observable,
      _statement: observable,
      busy: computed,
      offsets: computed,
      date: computed,
      _id: computed,
    });

    this._statement = statementInfo;
  }

  get busy() { return this._busy; }
  get offsets() { return this._statement.offsets; }
  get date() { return dayjs(this._statement.date).utc(); }
  get _id() { return this._statement._id; }

  public toJs = () => ({ ...this._statement });
}
