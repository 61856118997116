import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

interface IProps {
  backgroundImage: string;
}

export const TopSectionContainer = styled(Section)<IProps>`
  display: flex;
  background: ${({ theme }) => theme.colors.midGray};
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
`;

export const DataContainer = styled(MaxBoundaryContainer)`
  ${FlexCol}
  align-items: center;
  justify-content: center;
  gap: 60px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
  }
`;

export const CircleContainer = styled.div`
  ${FlexColCenter}
  align-items: center;
  background: ${({ theme }) => theme.colors.dark};
  width: 350px;
  height: 350px;
  padding: 50px;
  border-radius: 999px;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 455px;
    height: 455px;
    padding: 100px;
  }

  h1 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 35px;
    line-height: 35px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 52px;
      line-height: 52px;
    }
  }

  .the-bad-wrapper {
    display: flex;

    .the-bad-text {
      margin-left: 10px;
      color: ${({ theme }) => theme.colors.dark };
      text-shadow:-1px -1px 0 #fff, 
                  1px -1px 0 #fff, 
                  -1px  1px 0 #fff, 
                  1px  1px 0 #fff, 
                  1px  0px 0 #fff, 
                  -1px  0px 0 #fff, 
                  0px  1px 0 #fff, 
                  0px -1px 0 #fff;
    }
  }

  .logo-container {
    padding: 15px;
    max-width: 180px;
    max-height: 100px;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 5px;
    ${FlexColCenter}
    align-items: center;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding: 15px;
      max-width: 275px;
      max-height: 150px;
      margin-bottom: 40px;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 60%;
    margin-top: 20px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 90%;
      margin-top: 40px;
      font-size: 18px;
    }
  }
`;

export const SquareContainer = styled.div`
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.dark };
  width: 229px;
  height: 256px;
  border-radius: 9px;
  padding: 20px;
  text-align: center;
  position: relative;

  p {
    color: ${({ theme }) => theme.colors.midGray};
    font-weight: 600;
    font-size: 18px;
  }

  h3 {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.white};
  }  
`;
