import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexCol, FlexRow } from '../../styles/styles';

export const AccountHubGridContainer = styled(MainContainer)`
  .content-container {
    margin-top: 0;
  }

  .account-header {
    box-shadow: none;
    grid-area: headerContent;
    max-height: 75px;
    position: relative;
    width: 100%;
    background: transparent;
  }
`;

export const MainWrapper = styled(MaxBoundarySection)`
  background-color: ${({ theme }) => theme.colors.grey1};
  grid-area: mainContent;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: calc(100vh - 70px);
  min-width: 100%;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    padding: 40px 60px;
  }
`;

export const GridWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey1};
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-areas: 
    "sideNav headerContent"
    "mainContent mainContent";
  height: 100%;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    grid-template-columns: minmax(342px, 342px) minmax(70%, 100%);
    grid-template-areas: 
      "sideNav headerContent"
      "sideNav mainContent";
  }
`;

export const AccountContentArea = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
  width: 100%;
  gap: 32px;

  .full-width {
    width: 100%;
  }

  .half-width-container {
    ${FlexCol}
    gap: 32px;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${FlexRow}
    }
  }

  .half-width {
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      width: 50%;
    }
  }
`;
