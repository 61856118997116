import React from 'react';
import { H2M } from '../../styles/components/header';
import { OurImpactReportsContainer } from './styles';
import { PMarketing } from '../../styles/components/paragraph';

interface IProps {
  className?: string;
}

export const OurImpactReports: React.FC<IProps> = ({
  className = '',
}) => (
  <OurImpactReportsContainer className={ className }>
    <div className='section-header-content'>
      <H2M>Impact Reports</H2M>
      <PMarketing className='light'>Our annual Karma Wallet Impact Report helps us track how far we&apos;ve come &ndash; and set goals for the future. Transparency is at the core of our business, because you deserve the truth about the companies you support.</PMarketing>
    </div>
  </OurImpactReportsContainer>
);
