import { IGroup } from './group';

export enum UserGroupStatus {
  Unverified = 'unverified',
  Verified = 'unverified',
  Approved = 'approved',
  Removed = 'removed',
  Banned = 'banned',
  Left = 'left',
}

export enum UserGroupRole {
  Member = 'member',
  Admin = 'admin',
  SuperAdmin = 'superadmin',
  Owner = 'owner',
}
export interface IUserGroup {
  _id: string;
  group: IGroup;
  email: string;
  role: UserGroupRole;
  status: UserGroupStatus;
  joinedOn: Date;
}
