import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

export const HeroSectionContainer = styled(Section)`
  background-color: ${props => props.theme.colors.dark};
  overflow: hidden;
  padding: 75px 15px;
  width: 100%;
`;

export const HeroSectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexCol}
  align-items: center;

  h1 {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 32px;
    line-height: 28px;
    max-width: 960px;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .about-us-video-container {
    ${FlexColCenter}
    align-items: center;
    margin-top: 50px;
    width: 100%;

    video {
      border-radius: 16px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
      max-width: 700px;
      width: 100%;
    }
  }
`;

export const AboutImageContainer = styled.div`
  ${FlexRow}
  gap: 20px;
  margin-top: 30px;
  overflow: hidden;

  .image {
    background-image: url('https://cdn.karmawallet.io/uploads/ZBV90tv5rX-about-us-sprite.png');
    background-size: cover;
    height: 142px;
    min-width: 202px;
  }

  .image-1 {
    background-position: 0px 0;
  }

  .image-2 {
    background-position: -245px 0; 
  }
    
  .image-3 {
    background-position: -490px 0;
  }

  .image-4 {
    background-position: -735px 0;
  }

  .image-5 {
    background-position: -980px 0;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
    justify-content: center;
    margin-top: 50px;
    width: 100%;
  }
`;
