import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
  fill?: string;
}

export const HamburgerMenuIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}hamburger-menu-icon` }
    id={ id }
    width={ 30 }
    height={ 30 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M5 7.5H25M25 15H5M5 22.5H25' stroke={ stroke } strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);

export const AsymHamburgerMenuIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}hamburger-menu-icon2` }
    id={ id }
    width={ 24 }
    height={ 24 }
    fill={ fill }
  >
    <path d='M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z' fill={ fill } />
  </SvgIcon>
);
