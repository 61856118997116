import React, { FC } from 'react';
import { ThickArrowSvgIcon } from './styles';

export enum ArrowDirection {
  Up = 'up',
  Right = 'right',
  Down = 'down',
  Left = 'left',
}

interface IProps {
  className?: string;
  stroke?: string;
  fill?: string;
  direction?: ArrowDirection;
  id?: string;
}

export const ThickArrowIcon: FC<IProps> = ({ className = '', direction, stroke = 'white', fill = 'white', id = '' }) => (
  <ThickArrowSvgIcon
    className={ `${direction} ${className ? className + ' ' : ''}arrow-icon` }
    id={ id }
    width={ 23 }
    height={ 23 }
    stroke={ stroke }
    fill={ fill }
  >
    <path d='M4.60603 13.9215L9.58366 8.94391V21.3334V22.0834H10.3337H12.667H13.417V21.3334V8.9439L18.3947 13.9215L18.925 14.4519L19.4553 13.9215L21.1052 12.2716L21.6355 11.7413L21.1052 11.211L12.0307 2.13642L11.5003 1.60609L10.97 2.13642L1.89545 11.211L1.36512 11.7413L1.89545 12.2716L3.54538 13.9215L4.07571 14.4519L4.60603 13.9215Z' fill={ fill } stroke={ stroke } strokeWidth='1.5' />
  </ThickArrowSvgIcon>
);
