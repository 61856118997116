import styled from 'styled-components';
import { FlexRow, TruncateText } from '../../../../../styles/styles';
import { TextSMMedium } from '../../../../../styles/components/text';
import { SentimentValue } from '.';

export const SentimentSourceArticleItemContainer = styled.li`
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 5px;
  padding: 10px 10px;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    grid-template-columns: 2fr 4fr 3fr 1fr;
  }
`;

export const SourceName = styled.div`
  ${TruncateText}
  ${TextSMMedium}
  justify-self: start;
  max-width: 150px;
  width: 90%;
`;

export const SourceTitleContainer = styled.div`
  ${FlexRow}
  ${TruncateText}
  justify-self: start;
  max-width: 300px;
  position: relative;
  width: 100%;

  .new-window-icon {
    position: absolute;
    top: -3px;
    right: 0px;
    width: 8px;
    fill: ${({ theme }) => theme.colors.blue1};
  }

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    justify-self: center;
    width: 90%;
  }
`;

export const SourceTitle = styled.a`
  ${TextSMMedium}
  ${TruncateText}
  padding: 3px 0;
  max-width: 300px;
  width: 100%;
`;

export const PublicationDate = styled.div`
  ${TextSMMedium}
  justify-self: start;
  max-width: 100px;
  width: 90%;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    justify-self: center;
    text-align: center;
  }
`;

interface ISentimentBarProps {
  sentiment: SentimentValue;
}

export const SentimentRangeContainer = styled.div`
  justify-self: start;
  max-width: 188px;
  width: 80%;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    justify-self: center;
    width: 90%;
  }
`;

export const SentimentBar = styled.div<ISentimentBarProps>`
  background: linear-gradient(90deg, ${({ theme }) => theme.colors.negative } 0%,  ${({ theme }) => theme.colors.neutral } 50%, ${({ theme }) => theme.colors.positive } 100%);
  border-radius: 2px;
  height: 6px;
  position: relative;
  width: 100%;

  &::before {
    background: ${({ sentiment, theme }) => {
    switch (sentiment) {
      case SentimentValue.Positive:
        return theme.colors.positive;
      case SentimentValue.Neutral:
        return theme.colors.neutral;
      case SentimentValue.Negative:
        return theme.colors.negative;
    }}};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.white };
    content: '';
    height: 14px;
    left: ${({ sentiment }) => {
    switch (sentiment) {
      case SentimentValue.Positive:
        return 'calc(100% - 21px)';
      case SentimentValue.Neutral:
        return 'calc(50% - 7px)';
      case SentimentValue.Negative:
        return 'calc(0% + 7px)';
    }}};
    position: absolute;
    top: -5px;
    width: 14px;
  }
`;
