import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexCenter } from '../../styles/styles';

export const DonationsContainer = styled(MainContainer)``;

export const DonationsList = styled.div`
  margin-top: 40px;
`;

export const DonationsWrapper = styled(MaxBoundarySection)`
  h2 {
    text-align: center;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  position: relative;
  min-height: 50px;
`;

export const NoDonations = styled.div`
  ${FlexCenter}
  min-height: 100px;
  color: ${({ theme }) => theme.colors.lightGray1};
`;
