import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppType } from '../constants';
import { BaseModel } from './base';

type PrivateFields = '_uploadingImage' |
'_uploadingFile';

export enum ResourceTypes {
  GroupLogo = 'groupLogo',
  UserAvatar = 'userAvatar',
  Karma = 'karma',
  CompanyLogo = 'companyLogo'
}

export interface IUploadConfig {
  resourceType: ResourceTypes;
  resourceId?: string;
}

export interface IUploadResponse {
  filename: string;
  url: string;
}

export type FileTypes = 'png' | 'jpg' | 'jpeg' | 'gif' | 'svg' | 'webp' | '.csv';

export const defaultSupportedImageTypes = '.png,.jpg,.jpeg,.gif,.svg,.webp';
export const defaultSupportedFileTypes = '.csv';

export class FileUploaderModel extends BaseModel {
  private _uploadingImage = false;
  private _uploadingFile = false;

  constructor (app: AppType) {
    super({ basePath: '/upload' }, null, app);

    makeObservable<FileUploaderModel, PrivateFields>(this, {
      _uploadingImage: observable,
      _uploadingFile: observable,
      uploadingImage: computed,
      uploadingFile: computed,
      uploadImage: action.bound,
    });
  }

  get uploadingImage() { return this._uploadingImage; }
  get uploadingFile() { return this._uploadingFile; }

  public uploadImageFromUrl = async (externalUrl: string, fileId: string, filename: string) => {
    if (this._uploadingImage || !externalUrl) return;

    this._uploadingImage = true;

    const result = await this.webServiceHelper.sendFormDataRequest<IUploadResponse>({
      path: '/image/from-url',
      method: 'POST',
      data: {
        externalUrl,
        fileId,
        filename,
      },
    });

    if (result.success) {
      runInAction(async () => {
        this._uploadingImage = false;
      });

      return result.value;
    } else {
      runInAction(() => {
        this._uploadingImage = false;
      });

      throw new Error(result.error);
    }
  };

  public uploadImage = async (file: File, config: IUploadConfig) => {
    if (this._uploadingImage || !file) return;
    this._uploadingImage = true;

    // any validation here...
    const formData = new FormData();
    formData.append('file', file);
    formData.append('resourceType', config.resourceType);
    if (!!config.resourceId) formData.append('resourceId', config.resourceId);

    const result = await this.webServiceHelper.sendFormDataRequest<IUploadResponse>({
      path: '/image',
      method: 'POST',
      data: formData,
    });

    if (result.success) {
      runInAction(async () => {
        this._uploadingImage = false;
      });

      return result.value;
    } else {
      runInAction(() => {
        this._uploadingImage = false;
      });

      throw new Error(result.error);
    }
  };
}
