import { action, computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { GroupModel } from './group';

type PrivateFields = '_code' |
'_codePrepopulated' |
'_email' |
'_group' |
'_isOpen' |
'_reset';

export class JoinGroupModel extends BaseModel {
  private _isOpen = false;
  private _email = '';
  private _code = '';
  private _codePrepopulated = false;
  private _group: GroupModel = null;

  constructor () {
    super();
    makeObservable<JoinGroupModel, PrivateFields>(this, {
      _code: observable,
      _codePrepopulated: observable,
      _email: observable,
      _group: observable,
      _isOpen: observable,
      code: computed,
      email: computed,
      group: computed,
      isOpen: computed,
      _reset: action.bound,
      close: action.bound,
      closeConfirmation: action.bound,
      open: action.bound,
    });
  }

  get code() { return this._code; }
  get codePrepopulated() { return this._codePrepopulated; }
  get email() { return this._email; }
  get group() { return this._group; }
  get isOpen() { return this._isOpen; }

  close = (group: GroupModel) => {
    this._group = group;
    this._reset();
  };

  closeConfirmation = () => {
    this._group = null;
    this._reset();
  };

  open = (code = '', email = '') => {
    this._group = null;
    this._code = code;
    this._codePrepopulated = true;
    this._email = email;
    this._isOpen = true;
  };

  private _reset = () => {
    this._code = '';
    this._codePrepopulated = false;
    this._email = '';
    this._isOpen = false;
  };
}
