import React from 'react';
import { KarmaCollectivePageContainer } from './styles';
import { HeroSection } from './HeroSection';
import { WhatIsKarmaCollectiveSection } from './WhatIsKarmaCollectiveSection';
import { SuperPowerSection } from './SuperPowerSection';
import { HowItWorksSection } from './HowItWorksSection';
import { JoinTheMovementSection } from './JoinTheMovementSection';

interface IProps {
  className?: string;
}

export const KarmaCollective: React.FC<IProps> = ({
  className = '',
}) => (
  <KarmaCollectivePageContainer title='Karma Collective' className={ className }>
    <HeroSection />
    <WhatIsKarmaCollectiveSection />
    <SuperPowerSection />
    <HowItWorksSection />
    <JoinTheMovementSection />
  </KarmaCollectivePageContainer>
);
