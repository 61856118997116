import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const QuotationMark: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}quote-mark-graphic` }
    id={ id }
    height={ 18 }
    width={ 25 }
  >
    <path d='M14.216 17.888V10.598C14.216 3.47 17.78 0.608002 24.206 0.5L24.908 3.902C21.182 4.28 19.346 6.17 19.616 9.248H22.964V17.888H14.216ZM0.5 17.888V10.598C0.5 3.47 4.064 0.608002 10.49 0.5L11.192 3.902C7.466 4.28 5.63 6.17 5.9 9.248H9.248V17.888H0.5Z' fill='#287E8A' />
  </SvgIcon>
);
