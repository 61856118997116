import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { H1 } from '../../../../styles/components/header';
import { IThemeProps } from '../../../../styles/themes';
import { checkIfEmailMatchesDomains, isValidEmail, passwordRules } from '../../../../utils/input-validation';
import { Button, ButtonLink } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';
import { EyeToggle } from '../../../EyeToggle';
import { LockIcon } from '../../../svgs/icons/LockIcon';
import { TextFieldKind } from '../../../TextField';
import { FormField } from '../styles';
import { AccountCompletionContainer, CreateAccountFormContainer, GroupWelcomeContent, Header, JoinGroupTextContainer, StandardWelcomeContent, WelcomeContainer } from './styles';
import { LoadingSpinner } from '../../../loading/LoadingSpinner';
import { PasswordRules } from '../../../PasswordRules';
import { EnvelopeIcon } from '../../../svgs/icons/EnvelopeIcon';
import { VisitorModel } from '../../../../models/visitor';
import { UserIcon } from '../../../svgs/icons/UserIcon';
import { useUserSession } from '../../../../contexts/user';
import { GroupModel } from '../../../../models/group';
import { IUserEmail, IUserInterface, IUserRegistrationData } from '../../../../models/users';
import { AnimatedEllipsis } from '../../../AnimatedEllipsis';
import { CTAs, CtaType } from '../../../CTAs';
import { removeScrollInert } from '../../../../utils/removeScrollInert';
import { usePromos } from '../../../../contexts/promos';
import { removeNonDigits } from '../../../../lib/misc';
import { MapPinIcon } from '../../../svgs/icons/MapPinIcon';
import { validateZipcode } from '../../../../lib/formatValidators';

interface IProps extends IThemeProps {
  className?: string;
  onClose?: () => void;
}

const AccountCompletionBase: React.FC<IProps> = ({
  className = '',
  theme,
  onClose,
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const promos = usePromos();
  const visitorModel = useRef(new VisitorModel()).current;
  const user = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [genError, setGenError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [confirmationPasswordError, setConfirmationPasswordError] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [token, setToken] = useState('');
  const [joinGroupSuccess, setJoinGroupSuccess] = useState(false);
  const [group, setGroup] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [groupCode, setGroupCode] = useState('');
  const [redirect, setRedirect] = useState(null);
  const [createAccountSuccess, setCreateAccountSuccess] = useState(false);
  const [requestNewLinkSuccess, setRequestNewLinkSuccess] = useState(false);
  const [showJoinGroupRedirectText, setShowJoinGroupRedirectText] = useState(false);

  const verifyToken = useCallback(async () => {
    const token = searchParams.get('verifyaccount');

    if (!!token) { 
      setToken(token);

      try {
        await visitorModel.verifyCreateAccountToken(token);
        if (visitorModel.tokenVerified && visitorModel.isValidToken) {
          analytics.fireEvent('VerifyAccountToken_Success');
        }
      } catch (err) {
        analytics.fireEvent('VerifyAccountToken_Error');
      }

      searchParams.delete('verifyaccount');
      setSearchParams(searchParams);
    }
  }, []);

  const checkForRedirect = useCallback(() => {
    const redirectToUrl = searchParams.get('redirect');
    searchParams.delete('redirect');
    setSearchParams(searchParams);
    if (!redirectToUrl) return;
    setRedirect(redirectToUrl);
  }, [searchParams]);

  useEffect(() => {
    verifyToken();
    checkForRedirect();
  }, []);

  useEffect(() => {
    if (isValidEmail(email)) return;
    setEmailError('This isn\'t a valid e-mail address format');
  }, [email]);

  useEffect(() => {
    if (!!createAccountSuccess && !groupCode && !redirect) {
      navigate('/account/dashboard');
      onClose();
    }
  }, [createAccountSuccess, groupCode, redirect]);

  const onConfirmationPasswordChange = useCallback(() => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setGenError('');
    setConfirmationPasswordError('');
    setConfirmationPassword(newValue);

    if (password !== newValue) {
      setConfirmationPasswordError('invalid');
      setPasswordMatch(false);
    } else {
      setConfirmationPasswordError('');
      setPasswordMatch(true);
    }
  }, [confirmationPassword, password]);

  const onCreateAccountClick = async () => {
    analytics.fireEvent('FinishCAccount_CreateAccount_Click');
    try {
      const userInfo: IUserRegistrationData = { name, password, token };
      if (!!promos.activeCreateAccountPromo) userInfo.promo = promos.activeCreateAccountPromo._id;
      if (!!zipCode) userInfo.zipcode = zipCode;

      const userResponse = await user.register(userInfo);
      setCreateAccountSuccess(true);
      analytics.fireEvent('FinishAccount_CreateAccount_Success');
      handleJoinGroup(userResponse);
    } catch (err: any) {
      setGenError(err.message);
    }
  };

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGenError('');
    setEmailError('');
    setEmail(e.target.value);
  }, [email]);

  const onEmailFocus = useCallback(() => {
    analytics.fireEvent('FinishAccount_InvalidToken_Email_Interact');
  }, []);

  const onJoinGroupFinishClick = useCallback(() => {
    removeScrollInert();
    onClose();
    analytics.fireEvent('WelcomeModal_Finish_Joining_Group_Click');
  }, []);

  // const onLinkCardClick = useCallback(() => {
  //   analytics.fireEvent('WelcomeModal_Link_Card_Click');
  //   onClose();
  // }, []);

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGenError('');
    setNameError('');
    setName(e.target.value);
  }, [name]);

  const onNameFocus = useCallback(() => {
    analytics.fireEvent('FinishAccount_FieldInt_Name');
  }, []);

  const onZipCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setZipCodeError('');
    const digitsOnly = removeNonDigits(e.target.value);
    setZipCode(digitsOnly);

    if (digitsOnly.length > 0) {
      const zipCheck = validateZipcode(digitsOnly);
      setZipCodeError(!zipCheck.isValid ? zipCheck.message : '');
    } else {
      setZipCodeError('');
    }
  }, []);

  const onZipCodeFocus = useCallback(() => {
    analytics.fireEvent('FinishAccount_ZipCode_Interact');
  }, []);
  
  const onPasswordChange = useCallback((validate: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setGenError('');
    setPasswordError(false);
    setPassword(newValue);

    if (passwordMatch) {
      setConfirmationPasswordError(newValue !== confirmationPassword ? 'invalid' : '');
      setPasswordMatch(newValue === confirmationPassword);
    } else if (!passwordMatch && newValue === confirmationPassword) {
      setConfirmationPasswordError('');
      setPasswordMatch(true);
    }

    if (validate && !!newValue) {
      const isValid = !passwordRules.find(r => !r.validate(newValue));
      if (!isValid) setPasswordError(true);
    }
  }, [password, passwordMatch, confirmationPassword]);

  const onPasswordFocus = useCallback(() => {
    analytics.fireEvent('FinishAccount_Password_Interact');
  }, []);

  const onRequestNewLinkClick = useCallback(async() => {
    analytics.fireEvent('FinishAccount_NewToken_Click');

    try {
      await visitorModel.createSignupVisitor({ email });
      setRequestNewLinkSuccess(true);
    } catch (err: any) {
      setGenError(err.message);
    }
  }, [email]); 
  
  const onShowPasswordClick = useCallback(() => {
    setShowPassword(!showPassword);
    analytics.fireEvent(`FinishAccount_ShowPassword_${!showPassword}`);
  }, [showPassword]);

  const onShowConfirmationPasswordClick = useCallback(() => {
    setShowConfirmationPassword(!showConfirmationPassword);
    analytics.fireEvent(`FinishAccount_ShowConfPassword_${!showConfirmationPassword}`);
  }, [showConfirmationPassword]);

  const onSkipJoinGroupClick = useCallback(() => {
    analytics.fireEvent('WelcomeModal_Skip_Joining_Group_Click');
    removeScrollInert();
    onClose();
  }, []);

  const onSkipViewGroupClick = useCallback(() => {
    analytics.fireEvent('WelcomeModal_Skip_View_Group_Click');
    removeScrollInert();
    onClose();
  }, []);

  // check the group code to see if it is a valid group
  const checkGroupCode = async (groupCode: string) => {
    try {
      const groupToJoin = await GroupModel.getGroupByCode(groupCode);
      analytics.fireEvent('FinishAccount_GroupCode_Valid');
      setGroupName(groupToJoin.name);
      return groupToJoin;
    } catch {
      analytics.fireEvent('FinishAccount_GroupCode_Error');
    }
  };

  // if valid code, initiate join group sequence
  const handleJoinGroup = useCallback(async(userResponse: IUserInterface) => {
    if (!userResponse.groupCode) return;
    setGroupCode(userResponse.groupCode);
    const group = await checkGroupCode(userResponse.groupCode);
    let groupEmail = userResponse.user.emails.filter((e: IUserEmail) => !!e.primary)[0].email;
    const userEmails = userResponse.user.emails.map((e: IUserEmail) => e.email);

    if (!!group && group.settings.allowDomainRestriction) {
      const matchingEmail = checkIfEmailMatchesDomains(group.domains, userEmails);
      if (!!matchingEmail) groupEmail = matchingEmail;
      // if they do not have an email that matches the domain, show the button to finish joining the group (this will take them to the account page with join group modal open)
      if (!matchingEmail) setShowJoinGroupRedirectText(true);
    }

    try {
      await user.joinGroup(userResponse.groupCode, groupEmail);
      setJoinGroupSuccess(true);
      setGroup(group);
      setGroupName(group.name);
    } catch (err: any) {
      setGenError(err.message);
      analytics.fireEvent('CreateAccount_GroupCode_Error');
    }
  }, []);

  const JoinGroupCTAs = useMemo(() => [
    {
      className: 'finish-join-group',
      id: 'finish-join-group',
      text: 'Finish Joining Group',
      kind: ButtonKind.Primary,
      url: `/account?groupCode=${groupCode}`,
      onClick: onJoinGroupFinishClick,
      ctaType: CtaType.Link,
    },
    {
      className: 'skip-join-group',
      id: 'skip-join-group',
      text: 'Skip For Now',
      kind: ButtonKind.Blank,
      onClick: onSkipJoinGroupClick,
    },
  ], [groupCode, onSkipJoinGroupClick, onJoinGroupFinishClick]);

  const ViewGroupCTAs = useMemo(() => [
    {
      className: 'view-group',
      id: 'view-group',
      text: 'View Group Dashboard',
      kind: ButtonKind.Primary,
      url: !!group ? `/groups/${group._id}` : '/account',
      onClick: onJoinGroupFinishClick,
      ctaType: CtaType.Link,
    },
    {
      className: 'skip-join-group',
      id: 'skip-join-group',
      text: 'Skip For Now',
      kind: ButtonKind.Blank,
      onClick: onSkipViewGroupClick,
    },
  ], [group, onSkipViewGroupClick, onJoinGroupFinishClick]);

  const renderHeaderSection = useCallback(() => {
    if (!visitorModel.tokenVerified) return;
    let header = 'Finish Creating Your Account';
    let text = 'Enter your name, password, and zip code (optional) to complete account creation.';

    if (!visitorModel.isValidToken) {
      header = 'Resend Account Creation Link';
      text = 'This link has expired or is invalid. Confirm your email address and we\'ll send you a new link to finish creating your account.';
    }

    return (
      <Header className='create-account-header'>
        <H1>{ header }</H1>
        <p className='subtext'>{ text }</p>
      </Header>
    );
  }, [visitorModel.tokenVerified, visitorModel.isValidToken]);

  const renderFormField = () => {
    if (!visitorModel.tokenVerified) return;

    if (!visitorModel.isValidToken) {
      return (
        <FormField
          autoComplete='email'
          errorText={ !email ? null : emailError }
          fieldKind={ TextFieldKind.Pill }
          id='user-email-input'
          label='Email Address'
          labelHidden
          leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
          onChange={ onEmailChange }
          onFocus={ onEmailFocus }
          placeholder='Email Address'
          value={ email }
        />
      );
    }

    return (
      <>
        <FormField
          id='user-name-input'
          autoComplete='name'
          fieldKind={ TextFieldKind.Pill }
          label='Name'
          labelHidden
          maxLength={ 50 }
          leftAccessory={ <UserIcon fill={ theme.colors.disabled } /> }
          placeholder='Name'
          value={ name }
          onChange={ onNameChange }
          onFocus={ onNameFocus }
          errorText={ nameError }
        />
        <FormField
          id='user-password-input'
          autoComplete='new-password'
          fieldKind={ TextFieldKind.Pill }
          label='Password'
          labelHidden
          // ariaDescription='8 character minimum. One uppercase character. One lowercase character. One number. One special character: @$!%*?&'
          leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
          rightAccessory={ <EyeToggle show={ showPassword } onClick={ onShowPasswordClick } /> }
          placeholder='Password'
          type={ showPassword ? 'text' : 'password' }
          value={ password }
          onFocus={ onPasswordFocus }
          onChange={ onPasswordChange(true) }
        />
        <PasswordRules password={ password } darkTheme />
        <FormField
          disabled={ (!!password && !passwordError) ? false : true }
          id='confirm-password-input'
          autoComplete='new-password'
          label='Confirm Password'
          labelHidden
          fieldKind={ TextFieldKind.Pill }
          leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
          rightAccessory={ <EyeToggle show={ showConfirmationPassword } onClick={ onShowConfirmationPasswordClick } /> }
          placeholder='Confirm Password'
          type={ showConfirmationPassword ? 'text' : 'password' }
          value={ confirmationPassword }
          onChange={ onConfirmationPasswordChange() }
          errorText={ !!confirmationPasswordError ? 'Passwords are not matching' : null }
        />
        <FormField
          id='zip-code-input'
          autoComplete='postal-code'
          fieldKind={ TextFieldKind.Pill }
          label='Zip Code'
          labelHidden
          maxLength={ 5 }
          leftAccessory={ <MapPinIcon stroke={ theme.colors.disabled } /> }
          placeholder='Zip Code'
          value={ zipCode }
          onChange={ onZipCodeChange }
          onFocus={ onZipCodeFocus }
          errorText={ zipCodeError }
        />
      </>
    );
  };
  
  const promoDisclaimer = useMemo(() => {
    if (!promos.activeCreateAccountPromo) return null;
    return <p className='promo-legal'>{ promos?.activeCreateAccountPromo?.disclaimerText }</p>;
  }, [promos.activeCreateAccountPromo]);

  const renderCTA = useCallback(() => {
    if (!visitorModel.tokenVerified) return;

    if (visitorModel.isValidToken) {
      let buttonContent = <div>Create Account</div>;
      if (user.registering) buttonContent = <LoadingSpinner color={ theme.colors.white } className='loading-spinner' />;

      return (
        <Button
          kind={ ButtonKind.Primary }
          disabled={ passwordError || !password || !passwordMatch || !name || !!nameError || !!zipCodeError }
          onClick={ onCreateAccountClick }
          className='cta'
        >
          { buttonContent }
        </Button>
      );
    }

    return (
      <Button
        kind={ ButtonKind.Primary }
        disabled={ !!emailError || !email }
        onClick={ onRequestNewLinkClick }
        className='cta'
      >
        Request New Link
      </Button>
    );
  }, [visitorModel.tokenVerified, visitorModel.isValidToken, passwordError, emailError, email, password, passwordMatch, zipCodeError]);

  const renderContent = () => {
    let content = (
      <StandardWelcomeContent>
        <p className='subtext'>Congratulations on taking the first step towards living your values!</p>
        {
          !!redirect ? (
            <ButtonLink
              href={ redirect }
              kind={ ButtonKind.Blank }
              className='redirect-link'
            >
              Redirect to Shopping Site
            </ButtonLink>
          )
            : null
        }
      </StandardWelcomeContent>
    );

    if (!!groupCode) {
      content = (
        <GroupWelcomeContent>
          <p className='subtext'>Congratulations on taking the first step towards living your values!</p>
          { 
            showJoinGroupRedirectText
              ? (
                <JoinGroupTextContainer>
                  <p className='warning-text'>More information is needed to finish joining the <strong>{ groupName }</strong> group.</p>
                  <CTAs className='join-group-ctas' ctas={ JoinGroupCTAs } />
                </JoinGroupTextContainer>
              )
              : null
          }
          { !!user.joiningGroup ? <AnimatedEllipsis className='subtext' text={ `Joining the ${groupName} group` } /> : null }
          { joinGroupSuccess ? <p className='success-text'>You have successfully been added to the <strong>{ groupName }</strong> group!</p> : null }
          { joinGroupSuccess ? <CTAs className='view-group-ctas' ctas={ ViewGroupCTAs } /> : null }
        </GroupWelcomeContent>
      );
    }

    if (!!createAccountSuccess) {
      return (
        <WelcomeContainer>
          <Header>
            <H1>Welcome, { user.name }</H1>
            { content }
          </Header>
        </WelcomeContainer>
      );
    }

    return (
      <CreateAccountFormContainer>
        { renderHeaderSection() }
        <form>
          { renderFormField() }
          { !!genError && <p className='field-error'>{ genError }</p> }
          { !!requestNewLinkSuccess && <p className='field-success'>A new verification link has been sent to the email above.</p> }
          { renderCTA() }
          { promoDisclaimer }
        </form>
      </CreateAccountFormContainer>
    );
  };

  return (
    <AccountCompletionContainer className={ className }>
      { 
        !!visitorModel.verifyingToken
          ? <LoadingSpinner color={ theme.colors.white } />
          : renderContent() 
      }
    </AccountCompletionContainer>
  );
};

const AccountCompletionObserver = observer(AccountCompletionBase);
export const AccountCompletion = withTheme(AccountCompletionObserver);
