import styled from 'styled-components';
import { ButtonLink } from '../Button';
import { MaxBoundarySectionLarge, Section } from '../../styles/components/containers';
import { FlexCol, FlexRow } from '../../styles/styles';

export const RelatedCompaniesContainer = styled(Section)`
  background: ${({ theme }) => theme.colors.white};
  text-align: center;

  h2 {
    margin-bottom: 10px;
  }

  .sector {
    margin: 0 15px 30px;
  }
`;

export const RelatedCompaniesInnerWrapper = styled(MaxBoundarySectionLarge)`
  background:${({ theme }) => theme.colors.green1};
  border-radius: 8px;
  padding: 12px;
  max-width: 1296px;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 24px;
  }

  @media (max-width:1248px) {
    max-width: 910px;
  }

`;

export const CardsWrapper = styled.div`
  ${FlexCol}
  gap: 12px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
    flex-flow: wrap;
    justify-content: space-evenly;
  }

  .company-badge {
    width:  100%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      width: calc(50% - 15px);
    }
    
    @media (min-width: 1249px) {
      width: calc(25% - 15px);
    }
  }
`;

export const LoadingSpinnerContainer = styled.div`
  height: 60px;
  margin: 20px auto;
  width: 200px;
`;

export const SectorLink = styled(ButtonLink)`
  text-transform: uppercase;
  text-decoration: underline;
`;
