import React from 'react';
import { AdoptionStatementContainer, HeroSectionContainer, HeroSectionInnerContainer } from './styles';
import { H1M } from '../../../styles/components/header';
import { GenericTag } from '../../../components/GenericTag';
import { IThemeProps } from '../../../styles/themes';
import { withTheme } from 'styled-components';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
}

const EmployerHeroSectionBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();

  const onGetInTouchClick = () => {
    analytics.fireEvent('EmployerPage_Hero_GetInTouch_Click');
  };

  return (
    <HeroSectionContainer className={ className }>
      <HeroSectionInnerContainer>
        <GenericTag
          className='generic-tag'
          color={ theme.colors.secondary }
          tagText='Ethical Rewards'
        />
        <H1M>Karma Wallet for Employers</H1M>
        <p className='section-sub-text'>Karma Wallet makes integrating sustainability easy and simple for your organization.</p>
        <ButtonLink
          onClick={ onGetInTouchClick }
          href='/contact-us'
          color={ theme.colors.primary }
          kind={ ButtonKind.Primary }
        >
          Get In Touch
        </ButtonLink>
        <AdoptionStatementContainer>
          <p>Our clients see 70% adoption by their employees.</p>
        </AdoptionStatementContainer>
      </HeroSectionInnerContainer>
    </HeroSectionContainer>
  );
};

export const EmployerHeroSection = withTheme(EmployerHeroSectionBase);
