import styled from 'styled-components';
import { TextLGBold, TextMDDemiBold, TextSMDemiBold } from '../../../../styles/components/text';
import { Modal } from '../../../../components/modals/Modal';
import { TruncateText } from '../../../../styles/styles';

interface IFilterCompaniesModalProps {
  windowHeight: number;
}

export const SourcesModalContainer = styled(Modal)<IFilterCompaniesModalProps>`
  height: ${({ windowHeight }) => `calc(${windowHeight}px - 100px)`};
  max-width: 800px;
  max-height: 500px;
  overflow: hidden;
  padding: 25px 10px 15px;
  width: 90%;

  .modal-body {
    overflow: hidden;
    position: relative;
  }

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    padding: 30px;
  }
`;

export const SourcesModalInnerContainer = styled.div`
  height: 100%;
  position: relative;

  .sticky-container {
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;

    ::-webkit-scrollbar {
      background: transparent;
      color: transparent;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.grey3};
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.grey4};
    }
  }
`;

export const SourcesHeader = styled.h1`
  ${TextLGBold}
  margin: 10px 0;
  text-align: center;
`;

export const SourceColumns = styled.div`
  display: none;

  @media (${({ theme }) => theme.breakpoints.xsMin}) {
    display: grid;
    grid-template-columns: 2fr 4fr 3fr 1fr;
    justify-content: space-between;
    padding: 10px;
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    z-index: 1;
  }
`;

const BaseColumn = styled.div`
  ${TextSMDemiBold}
  ${TruncateText}
  max-width: 100px;
  width: 90%;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${TextMDDemiBold}
  }
`;

export const SourceName = styled(BaseColumn)`
  justify-self: start;
`;

export const BaseColumnCenter = styled(BaseColumn)`
  justify-self: center;
  text-align: center;
`;

export const SourceTitle = styled(BaseColumnCenter)``;

export const SourceSentiment = styled(BaseColumnCenter)``;

export const PublicationDate = styled(BaseColumnCenter)``;

export const SourceArticleItemsContainer = styled.ul`
  margin: 0;
  padding: 0;

  li:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.grey1};
  }
`;
