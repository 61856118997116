import styled from 'styled-components';

export const FieldWithToolTipAnchor = styled.span``;

export const FieldWithToolTipContainer = styled.div``;

export const TooltipContainer = styled.div`
  padding: 5px 10px;
  background: ${({ theme }) => theme.colors.lightYellow2};
`;
