import React from 'react';
import {
  ContentWrapper,
  ImageSection,
  TextSection,
  TopSection,
  BlogSectionContainer,
} from './styles';
import { ButtonKind } from '../Button/styles';
import { ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { H2M } from '../../styles/components/header';
import { GenericTag } from '../GenericTag';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';

interface IProps extends IThemeProps {
  className?: string;
}

const HomePageBlogSectionBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();

  const onCtaClick = () => {
    analytics.fireEvent('HP_ExploreTheBlog_Click');
  };

  const renderTopSection = () => (
    <TopSection>
      <TextSection>
        <GenericTag
          color={ theme.colors.tertiary }
          tagText='The Karma Blog:'
        />
        <H2M>
          Get the latest <br /> news & insights
        </H2M>
        <p>We do the work so you don't have to. Explore our research, reports, data, news and insights into the world of sustainability</p>
        <ButtonLink
          className='explore-blog-button'
          href='https://research.karmawallet.io'
          kind={ ButtonKind.Secondary }
          onClick={ onCtaClick }
          target='_blank'
        >
          Explore the Blog
        </ButtonLink>
      </TextSection>
      <ImageSection>
        <picture>
          <source 
            srcSet='https://cdn.karmawallet.io/images/MdjSOy1-RE-homepage-article-images-mobile.webp'
            media={ `(${theme.breakpoints.mdMax})` } />
          <img
            loading='lazy'
            src='https://cdn.karmawallet.io/images/Gnim94psxQ-homepage-article-images.webp'
            className='article-images'
            alt='Article Images'
          />
        </picture>
      </ImageSection>
    </TopSection>
  );

  return (
    <BlogSectionContainer className={ className } >
      <ContentWrapper>
        { renderTopSection() }
      </ContentWrapper>
    </BlogSectionContainer>
  );
};

export const HomePageBlogSection = withTheme(HomePageBlogSectionBase);
