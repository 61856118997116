import React, { useCallback, useEffect, useRef } from 'react';
import { useHeader } from '../../contexts/header';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
  transparent?: boolean;
  root?: Element | Document;
}

interface IObserverOptions {
  root?: Element | Document;
  rootMargin?: string;
  threshold?: number;
}

const UpdateHeaderBase: React.FC<IProps> = ({
  className = '',
  root,
}) => {
  const ref = useRef(null);
  const headerModel = useHeader();

  const handleIntersection = useCallback((entries: any) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        headerModel.setMakeSolid(true);
      } else {
        headerModel.setMakeSolid(false);
      }
    }
  }, []);

  useEffect(() => {
    const options: IObserverOptions = {
      rootMargin: '0px',
      threshold: 1.0,
    };
    if (root) options.root = root;
    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(ref.current);
    const target = ref.current;
    return () => observer.unobserve(target);
  }, []);

  return (
    <div ref={ ref } id='update-header' style={ { width: '100%', height: 0 } } className={ className } />
  );
};

export const UpdateHeader = observer(UpdateHeaderBase);
