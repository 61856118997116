import React, { createContext } from 'react';
import { AnalyticsModel } from '../models/analytics';

export const AnalyticsContext = createContext<AnalyticsModel>(null);

export const useAnalytics = () => React.useContext(AnalyticsContext);

export const AnalyticsStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <AnalyticsContext.Provider value={ new AnalyticsModel() }>
    { children }
  </AnalyticsContext.Provider>
);
