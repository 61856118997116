import styled from 'styled-components';
import { FlexCol, FlexColCenter, FlexRow } from '../../styles/styles';
import { MaxBoundaryContainerLarge, Section } from '../../styles/components/containers';
import { CompanyComparisonItemContainer } from './CompanyComparisonItem/styles';

export const CompanyImpactCategoriesContainer = styled(Section)``;

export const CompanyImpactCategoriesInnerWraper = styled(MaxBoundaryContainerLarge)`
  background: none;
`;

export const ContentWrapper = styled.div``;

export const CategorySection = styled.div`
  ${FlexCol}
  margin: 0 auto;

  &:first-of-type {
    margin-bottom: 50px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      margin-bottom: 30px;
    }
  }
`;

export const AverageComparisonItem = styled(CompanyComparisonItemContainer)``;

export const ComparisonWrapper = styled.div`
  ${FlexCol}
  align-items: center;
  margin: 24px 0 40px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
    align-items: center;
    gap: 20px;
    justify-content: space-between;
  }
`;

export const HeaderWrapper = styled.div`
  ${FlexColCenter}
  align-items: center;

  .category-content-wrapper {
    ${FlexCol}
    align-items: center;
    text-align: center;

    .category-content {
      ${FlexRow}
      align-items: center;
  
      .category-header {
        margin-left: 10px;
      }
  
      svg {
        height: 36px;
        width: 36px;
      }
    }

    .sub-category {
      color: ${({ theme }) => theme.colors.midGray};
      margin-top: 10px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    align-items: flex-start;

    .category-content-wrapper {
      align-items: flex-start;
    }
  }
`;

export const HeaderSection = styled.div`
  margin: 10px 0 16px;
  text-align: center;

  .sub-text {
    font-size: 14px;
    text-decoration: none;

    a {
      font-size: 14px;
    }
  }
`;
