import styled from 'styled-components';
import { Section } from '../../styles/components/containers';
import { FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { TextSMBold, TextSMMedium } from '../../styles/components/text';
import { Carousel } from '../Carousel';

export const KarmaCollectiveBrowseSectionContainer = styled(Section)`
  background:${({ theme }) => theme.colors.green1};
  border: solid 1px ${({ theme }) => theme.colors.grey2};
  border-radius: 8px;
  margin: 10px 0;
  padding: 16px;
  position: relative;

  .karma-collective-description {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey5};
    margin: 10px 0 14px;
  };
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 24px;

    .karma-collective-description {
      margin: 10px 0 24px;
    };
  }
`;

export const ContentContainer = styled.div`
  ${FlexRow}
  background:${({ theme }) => theme.colors.green1};
  gap: 12px;
  padding-bottom: 5px;
  overflow: auto;
  scrollbar-width: thin;
  
  .results-text {
    ${TextSMBold}
    color: ${({ theme }) => theme.colors.disabled};
    margin: auto;
  }

  @media (${({ theme }) => theme.breakpoints.xsMax}) {
    .company-badge {
      min-width: 75vw;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    .single-company {
      width: 100%;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    scrollbar-width: auto;

    ::-webkit-scrollbar {
      background: transparent;
      color: transparent;
      height: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.grey3};
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.grey4};
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .company-badge {
      min-width: 310px;
    }
  }
`;

export const LoadingSpinnerContainer = styled.div`
  height: 100px;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const BadgeCarousel = styled(Carousel)`
  ${FlexHorizontalCenter}
  display: block;
  justify-content: center;

  .company-badge {
    margin: 0 auto;
    width: 310px;

    .bottom-wrapper {
      flex-grow: 1; 
    }
  }

  .slick-next {
    left: 99%;
  }

  .slick-prev {
    right: 99%;
  }
`;
