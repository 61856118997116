import styled from 'styled-components';
import { FlexCol } from '../../styles/styles';

export const ActionSectionContainer = styled.div`
  ${FlexCol};
  align-items: center;
  text-align: center;

  .header {
    margin-bottom: 20px !important;
  }

  .subheader {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
    max-width: 900px;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      max-width: 600px;
    }
  }
`;
