import React, { useCallback, useMemo } from 'react';
import { IntroSection } from '../IntroSection';
import { MasterArticleContainer, BottomSectionWrapper, BottomSectionContainer, CompanyDataSquare, MakeImpactCTAContainer } from './styles';
import { useAnalytics } from '../../../contexts/analytics-store';
import { H2, H3 } from '../../../styles/components/header';
import { IndustryReportScoreGauge } from '../../../components/IndustryReportScoreGauge';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { BodySection } from '../BodySection';
import { IArticle, IArticleType } from '../../../models/article';
import { HeaderSection } from '../HeaderSection';

interface IProps {
  article: IArticle;
  classname?: string;
}

export const MasterArticle: React.FC<IProps> = ({
  article,
  classname='',
}) => {
  const analytics = useAnalytics();

  const onGetStartedClick = useCallback(() => {
    analytics.fireEvent('IndustryReport_GetStartedClick', article.company.companyName);
  }, [article]);

  const onSeeReportClick = useCallback(() => {
    analytics.fireEvent('IndustryReport_SeeReportClick', article.company.companyName);
  }, [article]);

  const renderBottomSection = useMemo(() => (
    article.type === IArticleType.GoodAndBad ? ( 
      <BottomSectionWrapper>
        <BottomSectionContainer>
          <CompanyDataSquare className='company-rating'>
            <div>
              <p>Company Rating</p>
              <H3>{ article.company.combinedScore }</H3>
            </div>
            <IndustryReportScoreGauge classname='report-score-gauge' combinedScore={ article.company.combinedScore } />
          </CompanyDataSquare>
          <ButtonLink
            className='see-report-cta'
            kind={ ButtonKind.Primary }
            onClick={ onSeeReportClick }
            href={ `/company/${article.company._id}/${article.company.slug}` }
          >
            See { article.company.companyName } Report
          </ButtonLink>
          <MakeImpactCTAContainer>
            <H2>Ready to turn spending into meaningful actions?</H2>
            <p>Shop with brands that have a lasting impact on the planet and people</p>
            <ButtonLink
              kind={ ButtonKind.Primary }
              onClick={ onGetStartedClick }
              href='?createaccount=true'
            >
              Get Started
            </ButtonLink>
          </MakeImpactCTAContainer>
        </BottomSectionContainer>
      </BottomSectionWrapper>)
      : null
  ), [article]);

  return (
    <MasterArticleContainer className={ classname }>
      <HeaderSection article={ article } />
      <IntroSection article={ article } />
      <BodySection article={ article } />
      { renderBottomSection }
    </MasterArticleContainer> 
  );
};
