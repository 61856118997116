import React, { useCallback, useEffect, useRef } from 'react';
import { RadioButton } from '../../RadioButton';
import { IDonationProps } from '../DonationOption';
import { CustomAmountField, CustomAmountFieldWrapper, CustomDonationOptionContainer } from './styles';

interface IProps extends IDonationProps {
  className?: string;
  customAmount: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onCustomClick(): void;
  description: JSX.Element;
}

export const CustomDonationOption: React.FC<IProps> = ({
  className = '',
  customAmount,
  description,
  isSelected,
  onChange,
  onCustomClick,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const _onInputRef = useCallback((ref: HTMLInputElement) => {
    inputRef.current = ref;
  }, []);

  useEffect(() => {
    if (isSelected) inputRef.current.focus();
  }, [isSelected]);

  const renderAmount = useCallback(() => (
    <CustomAmountFieldWrapper>
      <span className='dollar-sign'>$</span>
      <CustomAmountField
        id='custom-donation-amount-input-field'
        label='Enter a custom amount'
        onRef={ _onInputRef }
        labelHidden
        maxLength={ 6 }
        onChange={ onChange }
        onClick={ onCustomClick }
        placeholder='0'
        type='text'
        value={ customAmount }
      />
    </CustomAmountFieldWrapper>
  ), [customAmount]);

  const renderRadioButton = useCallback(() => (
    <RadioButton
      checked={ isSelected }
      id='donation-option-custom'
      label='Select A Custom Amount'
      labelHidden
      onClick={ onCustomClick }
    />
  ), [customAmount, isSelected]);

  return (
    <CustomDonationOptionContainer
      className={ className }
      key='donation-option-custom'
    >
      <div className='radio-button-wrapper'>
        { renderRadioButton() }
      </div>
      { renderAmount() }
      <div className='option-description'>{ description }</div>
    </CustomDonationOptionContainer>
  );
};
