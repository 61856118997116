import React from 'react';
import { H2M } from '../../../styles/components/header';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { ArticleContainer, DontTakeItFromUsSectionContainer, DontTakeItFromUsSectionInnerContainer, MainBodyContainer, QuoteContainer, QuoteSourceContainer, QuoteTextContainer } from './styles';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { QuotationMark } from '../../../components/svgs/graphics/QuotationMark';
import { GenericTag } from '../../../components/GenericTag';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
}

const DontTakeItFromUsSectionBase: React.FC<IProps> = ({
  className,
  theme,
}) => {
  const analytics = useAnalytics();

  const onContactUsClick = () => {
    analytics.fireEvent('EmployerPage_ContactUs_Click');
  };

  return (
    <DontTakeItFromUsSectionContainer className={ className }>
      <DontTakeItFromUsSectionInnerContainer>
        <H2M>Don't just take it from us.</H2M>
        <p>Explore our case studies for an in-depth look at some of the the programs we've launched to date.</p>
        <MainBodyContainer>
          <QuoteContainer>
            <QuoteTextContainer>
              <div>
                <QuotationMark className='quotation-left' />
                    Karma Wallet reinforces our stance as a forward-thinking leader in fostering a sustainable and responsible workplace culture.
                <QuotationMark className='quotation-right' />
              </div>
              <QuoteSourceContainer>
                <img src='https://cdn.karmawallet.io/uploads/FQYSR0cC7X-laura-m.png' alt='Picture of Laura MacLachlan' />
                <cite>
                    Laura MacLachlan<span>Head of HR at Ndustrial</span>
                </cite>
              </QuoteSourceContainer>
            </QuoteTextContainer>
          </QuoteContainer>
          <ArticleContainer>
            <img src='https://cdn.karmawallet.io/uploads/YXDEQya8rf-concept-paper.png' alt='concept paper' />
            <GenericTag className='case-study-tag' tagText='Case Study' color={ theme.colors.primary } />
            <h2><a target='blank' href='https://karmawallet.io/blog/2024/02/concept-paper-ndustrials-innovative-employee-recognition-and-reward-strategy-with-karma-wallet-and-heytaco/' rel='noreferrer'>Concept Paper: Ndustrial's Innovative Employee Recognition and Reward Strategy with Karma Wallet and HeyTaco</a></h2>
          </ArticleContainer>
        </MainBodyContainer>
        <ButtonLink
          onClick={ onContactUsClick }
          href='/contact-us'
          kind={ ButtonKind.Primary }
        >
          Contact Us
        </ButtonLink>
        <p className='consultation-text'>Schedule a consultation with our team and let's explore how Karma Wallet can fit into your existing ESG goals.</p>
      </DontTakeItFromUsSectionInnerContainer>
    </DontTakeItFromUsSectionContainer>
  );
};
  
export const DontTakeItFromUsSection = withTheme(DontTakeItFromUsSectionBase);
