import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { CircleWrapperContainer, GroupMemberDataSkeletonContainer } from './styles';

interface IProps {
  className?: string;
}

export const GroupMemberDataSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <GroupMemberDataSkeletonContainer className={ className }>
    <CircleWrapperContainer>
      <Skeleton className='circle-skeleton' />
      <Skeleton className='title-skeleton' />
    </CircleWrapperContainer>
    <CircleWrapperContainer>
      <Skeleton className='circle-skeleton' />
      <Skeleton className='title-skeleton' />
    </CircleWrapperContainer>
    <CircleWrapperContainer>
      <Skeleton className='circle-skeleton' />
      <Skeleton className='title-skeleton' />
    </CircleWrapperContainer>
  </GroupMemberDataSkeletonContainer>
);
