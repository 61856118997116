import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useAnalytics } from '../../../contexts/analytics-store';
import { Button } from '../../Button';
import { ButtonKind } from '../../Button/styles';
import { CashbackValuesContainer, CompanyCashbackLogo, CompanyCashbackModalContainer, Footer, Header } from './styles';
import { useAppData } from '../../../contexts/app';
import { H1 } from '../../../styles/components/header';
import { CompanyModel, MerchantRateType } from '../../../models/companies';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { IntegrationType } from '../../CompanyCashbackOffers';
import { capitalizeFirstLetter } from '../../../lib/misc';
import { LoadingSpinner } from '../../loading/LoadingSpinner';

interface IProps {
  className?: string;
  company: CompanyModel;
  onClose(): void;
  onClick(): void;
  hasKarmaCard?: boolean;
  isOpen: boolean;
  isLoggedIn: boolean;
  integration: IntegrationType;
}

const CompanyCashbackModalBase: React.FC<IProps> = ({
  className = '',
  company,
  onClose,
  onClick,
  hasKarmaCard,
  isOpen,
  integration,
}) => {
  const analytics = useAnalytics();
  const appData = useAppData();
  const errorMessages = useErrorMessages();
  const [errorLoadingRates, setErrorLoadingRates] = useState(false);
  
  useEffect(() => {
    try {
      company.loadMerchantRates();
    } catch(err: any) {
      setErrorLoadingRates(true);
      errorMessages.push({
        title: 'Error Loading Rates',
        message: err.message,
      });
    }
  }, [company]);

  const onStartShoppingClick = () => {
    analytics.fireEvent('Company_StartShopping_ModalClick', `${company._id}`);
    onClick();
  };

  const footer = useMemo(() => {
    if (!hasKarmaCard) return <Footer />;

    return (
      <Footer>
        <Button
          className='start-shopping-button'
          kind={ ButtonKind.Blank }
          onClick={ onStartShoppingClick }
        > 
          Start Shopping
        </Button>
      </Footer>
    );
  }, [hasKarmaCard]);

  const cashbackValues = useMemo(() => {
    if (company.loadingMerchantRates) return <LoadingSpinner />;
    if (errorLoadingRates) return <div className='error-text'>Error Loading Cashback Offers</div>;

    if (integration === IntegrationType.Kard) return (
      <ul>
        { 
          company.merchantRates.filter(rate => rate.integrations.kard).map(({ _id, maxAmount, integrations, maxRateType }) => (
            <li className='offer-item' key={ _id }>
              <div className='offer'>
                <span>{ capitalizeFirstLetter(integrations.kard.offerType) }</span>
                <span>
                  { maxRateType === MerchantRateType.Flat ? `$${maxAmount}` : `${maxAmount}%` }
                </span>
              </div>
              <div className='terms'>{ integrations.kard?.terms }</div>
            </li>
          ))
        }
      </ul>
    );

    if (integration === IntegrationType.Wildfire) return (
      <ul>
        { 
          company.merchantRates.filter(rate => rate.integrations.wildfire).map(({ _id, name, maxAmount, maxRateType }) => (
            <li className='offer-item offer' key={ _id }>
              <span>{ name }</span>
              <span>
                { maxRateType === MerchantRateType.Flat ? `$${maxAmount}` : `${maxAmount}%` }
              </span>
            </li>
          ))
        }
      </ul>
    );
  }, [company.merchantRates, company.loadingMerchantRates]);

  return (
    <CompanyCashbackModalContainer
      className={ className }
      onClose={ onClose }
      isOpen={ isOpen }
      windowHeight={ appData.windowVisualViewportHeight }
    >
      <div className='main-content-container'>
        <Header>
          <CompanyCashbackLogo company={ company } />
          <div className='header-text'>
            <H1>Current Cashback Offers</H1>
          </div>
        </Header>
        <p className='cashback-text'>Brands can change these offers at any time</p>
        <CashbackValuesContainer>
          { cashbackValues }
        </CashbackValuesContainer>
      </div>
      { footer }
    </CompanyCashbackModalContainer>
  );
};

export const CompanyCashbackModal = observer(CompanyCashbackModalBase);
