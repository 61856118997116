import React, { useCallback, useMemo } from 'react';
import { BodyContainer, GraphicContainer, OurImpactContentBlockContainer } from './styles';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { ButtonLink } from '../Button';
import { H2M } from '../../styles/components/header';
import { OurImpactCard } from '../OurImpactCard';

export enum ImpactContentBlockType {
  Certifications = 'Certifications',
  Partnership = 'Partnership',
}

export interface IBlockContent {
  title: string;
  impactContentType: ImpactContentBlockType;
  body: string;
  bodyAlignment: 'left' | 'right';
  ctaText: string;
  ctaLink: string;
  graphicSection: JSX.Element;
  customGraphic: boolean;
  customGraphicScore?: number;
  customGraphicText?: string;
  customGraphicSubtext?: string;
  customGraphicBg?: string;
  customGraphicTextColor?: string;
}

interface IProps {
  className?: string;
  content: IBlockContent
}

export const OurImpactContentBlock: React.FC<IProps> = ({
  className = '',
  content,
}) => {
  const { 
    title,
    impactContentType,
    body,
    bodyAlignment,
    ctaText,
    ctaLink,
    graphicSection, 
    customGraphic,
    customGraphicScore,
    customGraphicText,
    customGraphicSubtext,
    customGraphicBg,
    customGraphicTextColor,
  } = content;
  const analytics = useAnalytics();
  
  const contentTypeText = useMemo(() => {
    switch (impactContentType) {
      case ImpactContentBlockType.Certifications:
        return 'Certifications';
      case ImpactContentBlockType.Partnership:
        return 'Partnership';
      default:
        return '';
    }
  }, [impactContentType]);

  const onClick = useCallback(() => {
    analytics.fireEvent(`OurImpact_${impactContentType}_click`, title);
  }, [title, impactContentType]);

  const bodyContent = useMemo(() => (
    <BodyContainer className='section body-content'>
      <div className='content-type'>{ contentTypeText }</div>
      <H2M>{ title }</H2M>
      { body.split('\n').map((line, index) => (
        <p key={ index }>
          { line }
        </p>
      )) }
      <ButtonLink
        className='content-block-cta'
        kind={ ButtonKind.Primary }
        onClick={ onClick }
        href={ ctaLink }
        target='_blank'
        rel='noreferrer'
      >
        { ctaText }
      </ButtonLink>
    </BodyContainer>
  ), [contentTypeText, title, body, ctaLink, ctaText]);

  const graphicContainer = useMemo(() => (
    !customGraphic ? ( 
      <GraphicContainer className='section graphic-content'>
        { graphicSection }
      </GraphicContainer>)
      : 
      <GraphicContainer className='section graphic-content'>
        <OurImpactCard
          icon={ graphicSection }
          textColor={ customGraphicTextColor }
          bgColor={ customGraphicBg }
          score={ customGraphicScore }
          text={ customGraphicText }
          subtext={ customGraphicSubtext || null }
        />
      </GraphicContainer>
  ), [customGraphic, graphicSection, customGraphicTextColor, customGraphicBg, customGraphicScore, customGraphicText, customGraphicSubtext]);

  return (
    <OurImpactContentBlockContainer className={ `${className} ${bodyAlignment === 'right' ? 'flipped' : ''}` }>
      { bodyContent }
      { graphicContainer }
    </OurImpactContentBlockContainer>
  );
}; 
