import { animated, config, useSpring } from 'react-spring';
import React, { AllHTMLAttributes } from 'react';
import { CheckIcon } from '../svgs/icons/CheckIcon';

import { CheckboxContainer } from './styles';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';

export enum CheckboxType {
  Square = 'square',
  Circle = 'circle',
}
interface IProps extends AllHTMLAttributes<HTMLInputElement>, IThemeProps {
  // use to provide additional context
  // use in place of ariaDescribedBy when there isn't an element on the page with the needed description text
  // ariaDescription?: string;
  // use to provide additional context
  // use when an element currently exist with the description text, pass thru that element's id
  ariaDescribedBy?: string;
  checkColor?: string;
  className?: string;
  label?: string;
  labelHidden?: boolean;
  labelHeader?: string;
  labelDescription?: string;
  type?: CheckboxType;
}

//For animating with useSpring
const uncheckedConfig = {
  opacity: 0,
  transform: 'scale(0)',
};

const checkedConfig = {
  opacity: 1,
  transform: 'scale(1)',
};

const CheckboxBase: React.FC<IProps> = ({
  // ariaDescription,
  ariaDescribedBy,
  checked,
  checkColor,
  className = '',
  disabled,
  label,
  labelHeader,
  labelDescription,
  id, 
  labelHidden,
  theme,
  type = CheckboxType.Square,
  ...restProps
}) => {
  const checkedSpring = useSpring({
    config: config.gentle,
    from: checked ? uncheckedConfig : checkedConfig,
    to: checked ? checkedConfig : uncheckedConfig,
  });

  const renderCheckIcon = () => {
    let stroke: string;

    if (disabled) {
      stroke = theme.colors.lightGray1;
    } else if (checkColor) {
      stroke = checkColor;
    } else if (type === CheckboxType.Square) {
      stroke = theme.colors.primary;
    } else {
      stroke = theme.colors.green;
    }

    return <CheckIcon stroke={ stroke } />;
  };

  return (
    <CheckboxContainer className={ className } htmlFor={ !!labelHeader && !!labelDescription ? id : undefined }>
      <div className={ `checkbox ${ type }` }>
        <input
          id={ id }
          name={ id }
          aria-describedby={ ariaDescribedBy }
          // aria-description={ ariaDescription }
          { ...restProps }
          checked={ checked }
          disabled={ disabled }
          type='checkbox'
        />
        {
          (checked || disabled) && (
            <animated.div style={ disabled ? null : checkedSpring }>
              { renderCheckIcon() }
            </animated.div>
          )
        }
      </div>
      {
        !!labelHeader && !!labelDescription && (
          <span className={ `${labelHidden ? 'sr-only' : ''} label` }>
            <span className='label-header'>{ labelHeader }</span>
            <br />
            <span className='label-description'>{ labelDescription }</span>
          </span>
        )
      }
      {
        !!label && (
          <label
            className={ `${labelHidden ? 'sr-only' : ''} label` }
            htmlFor={ id }
          >
            { label }
          </label>
        )
      }
    </CheckboxContainer>
  );
};

export const Checkbox = withTheme(CheckboxBase);
