import React from 'react';
import { DebitCardTextContainer, KarmaCardAndAppImgContainer, BackgroundContainer, HeroSectionContainer, ContentWrapper, AppButtonsWrapper } from './styles';
import ActivateCardMockup from '../../../../public/assets/images/activate-app-mockup.png';
import ActivateCardMockupMobile from '../../../../public/assets/images/activate-app-mockup-mobile.png';
import { useAnalytics } from '../../../contexts/analytics-store';
import { CoinB } from '../../../components/svgs/graphics/CoinB';
import { theme } from '../../../styles/themes';
import { Bottle } from '../../../components/svgs/graphics/Bottle';
import { EarthWithClouds } from '../../../components/svgs/graphics/EarthWithClouds';
import { LeafA } from '../../../components/svgs/graphics/LeafA';
import { Light } from '../../../components/svgs/graphics/Light';
import { Food } from '../../../components/svgs/graphics/Food';
import { Recycling } from '../../../components/svgs/graphics/Recycling';
import { GenericTag } from '../../../components/GenericTag';
import { H2M, H2MInverted, H4 } from '../../../styles/components/header';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import AppStoreBadge from '../../../../public/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GooglePlayBadge from '../../../../public/assets/images/google-play-badge.svg';

interface IProps {
  className?: string;
}

export const QrCodeLandingPageHero: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();

  const onAppleClick = () => {
    analytics.fireEvent('QR_LandingPage_AppleStore_Click');
  };

  const onGoogleClick = () => {
    analytics.fireEvent('QR_LandingPage_GoogleStore_Click');
  };
  
  const renderBackground = () => (
    <BackgroundContainer>
      <CoinB
        className='svg-icon coin-b-1'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-2'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-3'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-4'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <CoinB
        className='svg-icon coin-b-5'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <Bottle
        className='svg-icon bottle'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <EarthWithClouds
        className='svg-icon earth-clouds'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <LeafA
        className='svg-icon leaf-a'
        fill={ 'none' }
        stroke={ theme.colors.secondary }
      />
      <Light
        className='svg-icon light'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
      <Food
        className='svg-icon food'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
      <Recycling
        className='svg-icon recycling'
        fill={ theme.colors.dark }
        stroke={ theme.colors.secondary }
      />
    </BackgroundContainer>
  );

  const appButtons = (
    <AppButtonsWrapper>
      <ButtonLink
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onAppleClick }
        href='https://apps.apple.com/app/karma-wallet/id6451440075'
      >
        <img src={ AppStoreBadge } alt='App Store Badge' />
      </ButtonLink>
      <ButtonLink
        kind={ ButtonKind.Blank }
        target='_blank'
        onClick={ onGoogleClick }
        href='https://play.google.com/store/apps/details?id=com.karmawalletnative.app'
      >
        <img className='google-button' src={ GooglePlayBadge } alt='Google Play Badge' />
      </ButtonLink>
    </AppButtonsWrapper>
  );

  return (
    <HeroSectionContainer
      className={ className }
      backgroundColor={ theme.colors.dark }
    >
      <ContentWrapper>
        { renderBackground() }
        <DebitCardTextContainer>
          <GenericTag
            color={ theme.colors.secondary }
            tagText='Activate Card'
          />
          <H2M className='debit-card-text'>Your journey</H2M>
          <H2MInverted className='inverted-text'>starts here</H2MInverted>
          <H4>Download the Karma Wallet App to get started!</H4>
          { appButtons }
        </DebitCardTextContainer>
        <KarmaCardAndAppImgContainer>
          <img
            alt='Karma Wallet App and Card'
            className='app-and-card'
            src={ ActivateCardMockup }
          />
          <img
            alt='Karma Wallet App and Card'
            className='app-mockup'
            src={ ActivateCardMockupMobile }
          />
        </KarmaCardAndAppImgContainer>
      </ContentWrapper>
    </HeroSectionContainer>
  );};
