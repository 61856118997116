import React from 'react';
import { AboutContainer } from './styles';
import { HeroSection } from './HeroSection';
import { CoreValuesSection } from './CoreValuesSection';
import { TeamSection } from './TeamSection';
import { EmployeeBenefitsSection } from './EmployeeBenefitsSection';
import { JourneySection } from './JoinTheJourneySection';
import { QuoteSection } from './QuoteSection';

interface IProps {
  className?: string;
}

export const AboutPage: React.FC<IProps> = ({
  className,
}) => (
  <AboutContainer title='About Us' className={ className }>
    <HeroSection />
    <CoreValuesSection />
    <TeamSection />
    <QuoteSection />
    <JourneySection />
    <EmployeeBenefitsSection />
  </AboutContainer>
);
