import * as React from 'react';
import { LinkItemSubtext, SubMenuLinkListContainer, SubmenuLinkItem } from './styles';
import { Link } from 'react-router-dom';
import { useHeader } from '../../../contexts/header';
import { SubMenuSelected } from '../../../models/header';
import { observer } from 'mobx-react';
import { ISubMenu, NavLinkType } from '../constants';
import { useUserSession } from '../../../contexts/user';

interface IProps {
  className?: string;
  subMenuItems: ISubMenu[];
  setMobileNavOpen?: (isOpen: boolean) => void;
  setMobileUserAccountMenuIsOpen?: (isOpen: boolean) => void;
}

const NavigationSubMenuBase: React.FunctionComponent<IProps> = ({
  className='',
  subMenuItems,
  setMobileNavOpen,
  setMobileUserAccountMenuIsOpen,
}) => {
  const appHeader = useHeader();
  const user = useUserSession();

  const onSubmenuLinkClick = (e:React.MouseEvent<HTMLLIElement, MouseEvent>, isLive: boolean) => {
    if (!isLive) {
      e.preventDefault();
      return;
    }
    appHeader.setSubMenuSelected(SubMenuSelected.None);
    if (!!setMobileUserAccountMenuIsOpen) setMobileUserAccountMenuIsOpen(false);
    setMobileNavOpen(false);
  };

  const onLastSubmenuItemBlur = (listItemId: string) => {
    if (listItemId === 'other-partnerships') {
      appHeader.setSubMenuSelected(SubMenuSelected.None);
    }
  };

  const renderSubMenu = () => ( 
    subMenuItems.map(( item ) => {
      if (user.hasKarmaWalletCard && item.id === NavLinkType.BecomeAMember) return null;

      const hrefPath = user.hasKarmaWalletCard ? item.pathname : item.altPathname || item.pathname;

      return (
        <SubmenuLinkItem
          onClick={ e => onSubmenuLinkClick(e, item.isLive) }
          onBlur={ () => onLastSubmenuItemBlur(item.id) }
          role='menuitem' 
          id={ item.id }
          key={ item.id }
        >
          { 
            <Link
              to={ hrefPath }
              onClick={ e => item.isLive ? null : e.preventDefault() }
              className={ `submenu-link ${item.isLive ? '' : ' disabled' }` }
              reloadDocument={ item.externalLink }
            >
              <div>
                { user.hasKarmaWalletCard ? item.text : item.altText || item.text }
                {
                  !!item.icon && (typeof(item?.icon) === 'string') ?
                    <img className='submenu-item-icon' src={ item.icon } />
                    : item.icon
                }
              </div>
              <LinkItemSubtext>{ user.hasKarmaWalletCard ? item.subText : item.altSubText || item.subText }</LinkItemSubtext>
            </Link>
          }
        </SubmenuLinkItem>);
    })
  );

  return (
    <SubMenuLinkListContainer className={ className }>
      { renderSubMenu() }
    </SubMenuLinkListContainer>
  );
};

const NavigationSubMenu = observer(NavigationSubMenuBase);

export default NavigationSubMenu;
