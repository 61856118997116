import React from 'react';
import { CoreValue } from '..';
import { CoreValueCardContainer } from './styles';

interface IProps {
  coreValue: CoreValue;
}

export const CoreValueCard: React.FC<IProps> = ({
  coreValue,
}) => (
  <CoreValueCardContainer>
    <div>
      <img src={ coreValue.image } alt={ coreValue.title } />
      <h3>{ coreValue.title }</h3>
    </div>
    <p>{ coreValue.description }</p>
  </CoreValueCardContainer>
);
