import React from 'react';
import { IEmployeeBenefit } from '..';
import { EmployeeBenefitCardContainer } from './styles';

interface IProps {
  benefit: IEmployeeBenefit;
}

export const EmployeeBenefitCard: React.FC<IProps> = ({ benefit }) => (
  <EmployeeBenefitCardContainer>
    <img src={ benefit.image } alt={ benefit.title } />
    <h3>{ benefit.title }</h3>
    <p>{ benefit.description }</p>
  </EmployeeBenefitCardContainer>
);
