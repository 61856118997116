import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useUserSession } from '../../contexts/user';
import { formatter } from '../../lib/misc';
import { buildRareUrl } from '../../lib/urlBuilders';
import { StatementModel } from '../../models/statements';
import { H4 } from '../../styles/components/header';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { AmountSectionContainer, GroupStatementSummaryContainer, UnitWrapperContainer } from './styles';

interface IProps {
  className?: string;
  selectedStatements: StatementModel[];
  groupId: string;
}

const GroupStatementSummaryBase: React.FC<IProps> = ({
  className = '',
  selectedStatements,
  groupId,
}) => {
  const userSession = useUserSession();
  const [donationUrl, setDonationUrl] = useState('');
  const [tonnesTotal, setTonnesTotal] = useState(0);
  const [dollarTotal, setDollarTotal] = useState(0);
  const [statementMonths, setStatementMonths] = useState<string[]>([]);

  useEffect(() => {
    setDonationUrl(buildRareUrl({
      amount: dollarTotal, 
      statementIds: selectedStatements.map(s => s._id).join(','),
      user_id: userSession._id,
      groupId,
      email: userSession.primaryEmail.email,
      redirectTo: window.location.href,
    }));
  }, [dollarTotal, selectedStatements]);

  useEffect(() => {
    let tonnes = 0;
    let dollars = 0;
    const months = [];

    for (const statement of selectedStatements) {
      if (statement.offsets) {
        tonnes += statement.offsets.toBeMatched.tonnes;
        dollars += statement.offsets.toBeMatched.dollars;
        months.push(statement.date.format('MMM, YYYY'));
      }
    }

    setTonnesTotal(tonnes);
    setDollarTotal(dollars);
    setStatementMonths(months);
  }, [selectedStatements]);

  const renderUnitWrapper = (label: string, text: string) => (
    <UnitWrapperContainer>
      <div className='label'>{ label }</div>
      <H4 className='unit'>{ text }</H4>
    </UnitWrapperContainer>
  );

  const renderAmountSection = () => (
    <AmountSectionContainer>
      { renderUnitWrapper('Tonnes Amount', tonnesTotal.toFixed(2)) }
      { renderUnitWrapper('Dollar Amount', formatter.format(dollarTotal)) }
    </AmountSectionContainer>
  );

  const renderContent = () => {
    if (statementMonths.length === 0) {
      return (
        <>
          <H4 className='header'>Match Summary</H4>
          <div className='empty-text'>No Statements Selected</div>
        </>
      );
    }

    return (
      <>
        <H4 className='header'>Match Summary</H4>
        <div className='months'>{ statementMonths.join('; ') }</div>
        { renderAmountSection() }
        <div className='divider-line' />
        <div className='statement-button-wrapper'>
          <ButtonLink
            className='match-offsets-cta'
            href={ donationUrl }
            kind={ ButtonKind.Primary }
            target='_blank'
          >
            Match Selected Statements
          </ButtonLink>
        </div>
      </>
    );
  };

  return (
    <GroupStatementSummaryContainer
      className={ className }
    >
      { renderContent() }
    </GroupStatementSummaryContainer>
  );
};

export const GroupStatementSummary = observer(GroupStatementSummaryBase);
