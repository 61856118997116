import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexColCenter, FlexRow } from '../../../styles/styles';

export const HeroSectionContainer = styled(Section)`
  padding: 65px 15px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 120px 15px;
  }
`;

export const HeroSectionInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    justify-content: space-evenly;
  }
`;

export const HeroTextContainer = styled.div`
  ${FlexColCenter}
  align-items: start;
  margin-bottom: 48px;
  max-width: 523px;

  h2 {
    font-size: 60px;
    line-height: 60px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 71px;
      line-height: 72px;
    }
  }

  .hero-text {
    color: ${({ theme }) => theme.colors.offWhite};
    display: inline;

    &.karma {
      display: block;
    }
  }

  .inverted-text {
    display: inline;
    margin-right: 14px;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 24px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      max-width: unset;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 0;
  }
`;

export const HeroLogosContainer = styled.div`
  ${FlexColCenter}
  gap: 16px;

  img {
    width: 448px;
  }

  .mobile-logos {
    margin: auto;
    max-width: 441px;
    width: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    .mobile-logos {
      max-width: 522px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    img {
      width: 648px;
    }
  }
`;
