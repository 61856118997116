import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const DiversityInclusionIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }diversity-inclusion-icon` }
    id={ id }
    fill={ fill || 'black' }
    width={ 25 }
    height={ 35 }
  >
    <path d='M11 5.5C11 8.395 8.645 10.75 5.75 10.75C2.855 10.75 0.5 8.395 0.5 5.5C0.5 2.605 2.855 0.25 5.75 0.25C8.645 0.25 11 2.605 11 5.5ZM19.25 10.75C22.145 10.75 24.5 8.395 24.5 5.5C24.5 2.605 22.145 0.25 19.25 0.25C16.355 0.25 14 2.605 14 5.5C14 8.395 16.355 10.75 19.25 10.75ZM5.75 12.25C2.855 12.25 0.5 14.605 0.5 17.5C0.5 20.395 2.855 22.75 5.75 22.75C8.645 22.75 11 20.395 11 17.5C11 14.605 8.645 12.25 5.75 12.25ZM24.5 17.5C24.5 20.395 22.145 22.75 19.25 22.75C16.355 22.75 14 20.395 14 17.5C14 14.605 16.355 12.25 19.25 12.25C22.145 12.25 24.5 14.605 24.5 17.5ZM21.5 17.5C21.5 16.2595 20.4905 15.25 19.25 15.25C18.0095 15.25 17 16.2595 17 17.5C17 18.7405 18.0095 19.75 19.25 19.75C20.4905 19.75 21.5 18.7405 21.5 17.5ZM11 29.5C11 32.395 8.645 34.75 5.75 34.75C2.855 34.75 0.5 32.395 0.5 29.5C0.5 26.605 2.855 24.25 5.75 24.25C8.645 24.25 11 26.605 11 29.5ZM8 29.5C8 28.2595 6.9905 27.25 5.75 27.25C4.5095 27.25 3.5 28.2595 3.5 29.5C3.5 30.7405 4.5095 31.75 5.75 31.75C6.9905 31.75 8 30.7405 8 29.5ZM24.5 29.5C24.5 32.395 22.145 34.75 19.25 34.75C16.355 34.75 14 32.395 14 29.5C14 26.605 16.355 24.25 19.25 24.25C22.145 24.25 24.5 26.605 24.5 29.5ZM21.5 29.5C21.5 28.2595 20.4905 27.25 19.25 27.25C18.0095 27.25 17 28.2595 17 29.5C17 30.7405 18.0095 31.75 19.25 31.75C20.4905 31.75 21.5 30.7405 21.5 29.5Z' fill={ fill } />
  </SvgIcon>
);
