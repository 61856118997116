import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';

type PrivateFields = '_accountMobileNavOpen' | '_accountMobileNavEngaged';

export interface IHeaderNavSettings {
  transparent: boolean;
  toSolidScrollPosition?: number;
}

export class AccountHubMobileNavModel extends BaseModel {
  private _accountMobileNavOpen = false;
  private _accountMobileNavEngaged = false;

  constructor () {
    super();

    makeObservable< AccountHubMobileNavModel, PrivateFields>(this, {
      _accountMobileNavOpen: observable,
      _accountMobileNavEngaged: observable,
      accountMobileNavOpen: computed,
      accountMobileNavEngaged: computed,
      setAccountMobileNavEngaged: action.bound,
      setAccountMobileNavOpen: action.bound,
    });
  }

  get accountMobileNavOpen () { return this._accountMobileNavOpen; }
  get accountMobileNavEngaged () { return this._accountMobileNavEngaged; }

  setAccountMobileNavOpen = (engaged: boolean) => {
    runInAction(() => this._accountMobileNavOpen = engaged);
  };

  setAccountMobileNavEngaged = (engaged: boolean) => {
    runInAction(() => this._accountMobileNavEngaged = engaged);
  };
}
