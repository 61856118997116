import styled from 'styled-components';
import { Section } from '../../../styles/components/containers';
import { FlexCol } from '../../../styles/styles';

export const SuperpowerSectionContainer = styled(Section)`
  ${FlexCol}
  align-items: center;
  overflow: hidden;
  padding: 65px 16px;
  text-align: center;

  h2 {
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 18px;
    max-width: 585px;
  }

  .karma-collective-logos {
    margin-top: 50px;
    max-width: 1159px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 90px 16px;
    
    h2, p {
      margin-bottom: 32px;
    }

    .karma-collective-logos {
      margin-top: 96px;
      max-width: 1976px;
    }
  }
`;
