import React from 'react';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { GroupOffsetStatementsSkeletonContainer } from './styles';

interface IProps {
  className?: string;
}

export const GroupOffsetStatementsSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <GroupOffsetStatementsSkeletonContainer className={ className }>
    <LoadingSpinner />
  </GroupOffsetStatementsSkeletonContainer>
);
