import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const FacebookIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }facebook-icon` }
    id={ id }
    width={ 21 }
    height={ 40 }
    stroke={ 'none' }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M13.5477 40V21.786H19.7676L20.6557 14.678H13.5477V10.2373C13.5477 8.23823 14.2131 6.6847 17.1003 6.6847H20.8756V0.242101C19.9903 0.242101 17.7685 0.0193513 15.3269 0.0193513C14.0901 -0.0639109 12.8501 0.122178 11.6923 0.564773C10.5345 1.00737 9.48648 1.69593 8.62066 2.58293C7.75484 3.46993 7.0918 4.53424 6.67731 5.70241C6.26281 6.87057 6.10673 8.11477 6.21985 9.34912V14.678H0V21.786H6.21985V40H13.5477Z' />
  </SvgIcon>
);
