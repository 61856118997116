import styled from 'styled-components';
import { FlexRow } from '../../styles/styles';
import { Button } from '../Button';

export const UNSDGsFilterPickerContainer = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
`;

export const UNSDGItem = styled(Button)`
  margin: 0 10px 10px 0;
  box-shadow: 2px 8px 25px 0px rgba(44, 44, 44, 0.05);

  &:hover {
    cursor: pointer;
  }

  img {
    background: ${({ theme }) => theme.colors.white};
    border: solid 1px ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.05);
  }

  &.selected {
    img {
      outline: 2px solid ${({ theme }) => theme.colors.dark}};
      outline-offset: -2px;
    }
  }
`;
