import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Skyscape: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}skyscape-graphic` }
    id={ id }
    height={ 205 } 
    width={ 219 }
  >
    <path d='M27.6766 109.198C46.2923 107.068 46.8798 134.129 59.07 134.202C72.9492 134.313 73.3898 113.09 72.8023 95.392C72.0312 72.3702 87.0119 64.6228 101.222 70.4609C114.293 75.8216 119.874 90.1414 116.68 106.591C113.155 124.839 93.6211 137.213 105.003 152.194C116.386 167.248 150.129 146.943 154.535 176.905C152.92 177.97 151.304 178.998 149.615 179.952C174.216 165.89 190.812 139.416 190.812 109.051C190.812 63.9619 154.278 27.428 109.189 27.428C64.1003 27.428 27.5664 63.9986 27.5664 109.051' fill='url(#paint0_linear_1780_12109)' />
    <path d='M149.615 68.735C164.412 83.9728 191.363 72.8474 191.032 51.588C191.289 38.5533 179.797 27.0607 166.762 27.3177C145.502 26.9873 134.34 53.9379 149.615 68.735ZM145.723 72.6271C127.034 54.2684 140.582 21.5531 166.762 21.8101C182.734 21.4797 196.87 35.6159 196.54 51.588C196.76 77.8043 164.081 91.353 145.723 72.6271Z' fill='black' />
    <path d='M164.008 11.8597V2.71704C164.008 -0.881267 169.515 -0.881267 169.515 2.71704V11.8964C169.515 15.4947 164.008 15.4947 164.008 11.8964V11.8597Z' fill='black' />
    <path d='M136.727 25.4452L130.228 18.9462C127.694 16.376 131.55 12.5206 134.12 15.0541L140.619 21.5531C143.153 24.1234 139.297 27.9787 136.727 25.4452Z' fill='black' />
    <path d='M127.07 54.3418H117.891C114.293 54.3418 114.293 48.8342 117.891 48.8342H127.07C130.669 48.8342 130.669 54.3418 127.07 54.3418Z' fill='black' />
    <path d='M140.619 81.6229L134.12 88.1219C131.55 90.6554 127.694 86.8001 130.228 84.2299L136.727 77.7309C139.297 75.1974 143.153 79.0527 140.619 81.6229Z' fill='black' />
    <path d='M169.515 91.2795V100.459C169.515 104.057 164.008 104.057 164.008 100.459V91.2795C164.008 87.6812 169.515 87.6812 169.515 91.2795Z' fill='black' />
    <path d='M196.797 77.7309L203.296 84.2299C205.829 86.8001 201.974 90.6554 199.404 88.1219L192.905 81.6229C190.371 79.0527 194.227 75.1974 196.797 77.7309Z' fill='black' />
    <path d='M206.491 48.8342H215.67C219.268 48.8342 219.268 54.3418 215.67 54.3418H206.491C202.892 54.3418 202.892 48.8342 206.491 48.8342Z' fill='black' />
    <path d='M192.905 21.5531L199.404 15.0541C201.974 12.5206 205.829 16.376 203.296 18.9462L196.797 25.4452C194.227 27.9787 190.371 24.1234 192.905 21.5531Z' fill='black' />
    <path d='M109.152 190.674C125.932 190.674 141.537 185.607 154.498 176.905C150.092 146.943 116.386 167.211 104.967 152.194C93.6209 137.213 113.118 124.839 116.643 106.591C119.837 90.1414 114.256 75.8216 101.185 70.4609C86.975 64.6228 71.9943 72.3702 72.7654 95.392C73.3896 113.09 72.9123 134.313 59.0331 134.202C46.8429 134.092 46.2554 107.068 27.6396 109.198C27.6396 154.213 64.1368 190.711 109.152 190.711V190.674Z' fill='url(#paint1_linear_1780_12109)' />
    <path d='M151.782 177.309C148.844 149.66 112.678 171.691 101.479 151.827C92.0792 136.222 110.071 122.416 113.375 108.427C116.276 97.742 114.991 84.9643 107.097 77.5106C99.7899 70.2406 85.8006 68.4414 79.632 77.9145C73.0963 87.6079 76.6212 100.386 75.483 111.658C75.0056 121.278 72.2151 137.066 59.2171 136.956C43.6489 136.038 44.5669 109.822 27.7503 111.952C26.2449 112.025 24.923 110.887 24.8496 109.381C24.8496 104.168 34.7633 106.774 37.7374 108.28C46.1457 112.686 49.5237 123.848 55.0681 129.613C58.4094 133.027 63.917 131.485 66.2302 127.41C75.0056 111.474 64.7247 90.5821 75.1525 74.7936C88.9215 55.627 117.745 70.1672 119.764 90.8758C122.188 103.507 117.451 116.541 110.695 126.785C107.684 131.889 104.6 137.03 104.343 142.023C104.453 155.242 117.304 156.233 128.209 156.086C142.456 155.719 155.417 160.088 157.289 176.538C157.803 180.099 152.369 180.98 151.855 177.345L151.782 177.309Z' fill='black' />
    <path fillRule='evenodd' clipRule='evenodd' d='M157.103 123.444C157.103 122.409 157.942 121.569 158.978 121.569H208.913C209.949 121.569 210.788 122.409 210.788 123.444C210.788 124.48 209.949 125.319 208.913 125.319H158.978C157.942 125.319 157.103 124.48 157.103 123.444Z' fill='black' />
    <path fillRule='evenodd' clipRule='evenodd' d='M69.571 158.842C59.6969 158.842 50.7102 164.526 44.3907 173.811L43.2427 175.497L41.6588 174.211C37.6408 170.949 33.0168 169.086 28.117 169.086C18.9152 169.086 10.552 175.578 5.82585 185.788H76.6207C77.6562 185.788 78.4957 186.628 78.4957 187.663C78.4957 188.699 77.6562 189.538 76.6207 189.538H0.279297L1.25057 186.994C6.04994 174.424 16.1045 165.336 28.117 165.336C33.3434 165.336 38.2095 167.105 42.4335 170.094C49.2125 160.976 58.7707 155.092 69.571 155.092C83.0071 155.092 94.5319 164.146 101.171 177.348C101.636 178.273 101.263 179.4 100.338 179.865C99.4129 180.33 98.2858 179.957 97.8206 179.032C91.6084 166.679 81.1761 158.842 69.571 158.842Z' fill='black' />
    <path d='M94.7229 44.4649C94.7229 44.4649 94.7229 44.2446 94.7229 44.1344C94.7229 34.9183 87.2693 27.4647 78.0532 27.4647C75.997 27.4647 74.0877 27.8686 72.2885 28.5662C70.2324 18.8361 61.6038 11.5293 51.2494 11.5293C40.8951 11.5293 32.8907 18.322 30.4674 27.5381C28.3745 26.8772 26.1714 26.51 23.8949 26.51C13.2102 26.51 4.39799 34.2941 2.70898 44.5016H94.7596L94.7229 44.4649Z' fill='white' />
    <path fillRule='evenodd' clipRule='evenodd' d='M29.2064 25.2498C32.3636 16.1799 40.6929 9.6543 51.2495 9.6543C61.7837 9.6543 70.6719 16.6096 73.6037 26.1691C75.0144 25.7968 76.4949 25.5897 78.0532 25.5897C88.3041 25.5897 96.5968 33.8817 96.5979 44.1324C96.6697 44.4896 96.6375 44.8677 96.4919 45.2192C96.2016 45.9198 95.518 46.3766 94.7596 46.3766H2.709C2.15822 46.3766 1.6353 46.1344 1.27904 45.7144C0.922789 45.2943 0.769238 44.7389 0.859151 44.1955C2.69446 33.1038 12.2718 24.635 23.895 24.635C25.7179 24.635 27.494 24.8513 29.2064 25.2498ZM92.7723 42.6266C92.0186 35.1563 85.7247 29.3397 78.0532 29.3397C76.2506 29.3397 74.5706 29.6923 72.9664 30.3144C72.4536 30.5132 71.8793 30.4769 71.3957 30.215C70.912 29.9531 70.5678 29.492 70.4541 28.9539C68.5772 20.0722 60.6988 13.4043 51.2495 13.4043C41.7879 13.4043 34.498 19.5825 32.2807 28.0149C32.1502 28.5112 31.8221 28.9323 31.3727 29.1801C30.9233 29.4278 30.3921 29.4806 29.9028 29.3261C27.9809 28.7192 25.9681 28.385 23.895 28.385C14.9242 28.385 7.39305 34.3995 5.04829 42.6266H92.7723Z' fill='black' />
    <path d='M135.442 202.974C130.669 188.544 117.083 178.116 101.075 178.116C85.0657 178.116 71.4803 188.544 66.707 202.974H135.479H135.442Z' fill='white' />
    <path fillRule='evenodd' clipRule='evenodd' d='M64.9269 202.385C69.9457 187.213 84.2304 176.241 101.075 176.241C117.793 176.241 131.99 187.05 137.108 202.045C137.264 202.319 137.354 202.636 137.354 202.974C137.354 204.01 136.514 204.849 135.479 204.849H66.707C66.1051 204.849 65.5397 204.56 65.1872 204.072C64.8347 203.584 64.7379 202.957 64.9269 202.385ZM132.74 201.099H69.4089C74.5974 188.705 86.8277 179.991 101.075 179.991C115.321 179.991 127.552 188.705 132.74 201.099Z' fill='black' />
    <path fillRule='evenodd' clipRule='evenodd' d='M130.446 138.131C130.446 137.096 131.286 136.256 132.321 136.256H198.082C199.118 136.256 199.957 137.096 199.957 138.131C199.957 139.167 199.118 140.006 198.082 140.006H132.321C131.286 140.006 130.446 139.167 130.446 138.131Z' fill='black' />
    <path fillRule='evenodd' clipRule='evenodd' d='M7.14844 68.5884C7.14844 67.5528 7.9879 66.7134 9.02344 66.7134H55.1038C56.1394 66.7134 56.9788 67.5528 56.9788 68.5884C56.9788 69.6239 56.1394 70.4634 55.1038 70.4634H9.02344C7.9879 70.4634 7.14844 69.6239 7.14844 68.5884Z' fill='black' />
    <defs>
      <linearGradient id='paint0_linear_1780_12109' x1='-0.779453' y1='219.02' x2='219.305' y2='-1.10133' gradientUnits='userSpaceOnUse'>
        <stop offset='0.23' stopColor='#55ED6D' />
        <stop offset='0.76' stopColor='#287E8A' />
      </linearGradient>
      <linearGradient id='paint1_linear_1780_12109' x1='6.23338' y1='227.869' x2='226.501' y2='7.63743' gradientUnits='userSpaceOnUse'>
        <stop offset='0.24' stopColor='#E5D976' />
        <stop offset='0.58' stopColor='#55ED6D' />
      </linearGradient>
    </defs>
  </SvgIcon>
);
