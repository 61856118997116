import styled from 'styled-components';
import { FlexCenter } from '../../styles/styles';

export interface ICompanyRatingTagProps {
  backgroundColor: string;
}

export const CompanyRatingTagContainer = styled.div<ICompanyRatingTagProps>`
  ${FlexCenter}
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  padding: 10px;
  height: 24px;
  margin-bottom: 5px;
  
  p {
    font-weight: 600;
    line-height: 1rem;
    margin: 0;
  }
`;
