import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';
import { theme } from '../../../../styles/themes';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const OnePercentForThePlanetLogo: FC<IProps> = ({
  className = '',
  id = '',
  fill = theme.colors.primary,
}) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }one-percent-logo` }
    id={ id }
    width={ 265 }
    height={ 320 }
    fill={ fill }
  >
    <path d='M132.628 -0.000152229C188.763 -0.0870056 235.138 45.6025 234.809 102.752C234.489 158.635 189.051 204.435 132.22 204.233C75.1795 204.032 29.6409 157.456 30.373 100.805C31.1005 44.5511 77.0691 -0.14186 132.628 -0.000152229ZM153.18 181.482C153.18 128.223 153.18 75.0961 153.18 21.9646C153.18 21.7086 153.148 21.4572 153.13 21.2012C153.13 21.1738 153.084 21.1464 153.061 21.1189C153.034 21.0915 153.006 21.0687 152.901 20.9727C152.695 20.9727 152.407 20.9727 152.114 20.9727C140.987 20.9727 129.86 20.9727 118.737 20.9635C118.115 20.9635 117.634 21.1327 117.163 21.5532C111.27 26.8193 105.372 32.0762 99.4472 37.3057C98.8066 37.8725 98.5778 38.4531 98.5733 39.2804C98.5595 44.9853 98.5 50.6857 98.4543 56.3906C98.4543 56.8934 98.4543 57.4008 98.4543 57.9996C103.469 58.2282 108.383 58.0636 113.379 58.2282V181.482H153.185H153.18ZM168 123.332C168.361 123.364 168.54 123.396 168.718 123.396C170.914 123.396 173.11 123.373 175.302 123.414C175.97 123.428 176.363 123.176 176.739 122.651C178.093 120.744 179.488 118.866 180.87 116.978C190.941 103.195 201.011 89.4086 211.081 75.6263C211.305 75.32 211.511 75.0001 211.813 74.5658C211.278 74.5429 210.953 74.5155 210.628 74.5155C208.652 74.5155 206.675 74.5475 204.703 74.4972C203.925 74.4789 203.44 74.7304 202.983 75.3612C191.572 91.0177 180.138 106.656 168.709 122.303C168.498 122.591 168.306 122.893 168.004 123.336L168 123.332ZM174.693 97.6597C181.053 97.6597 186.191 92.5033 186.168 86.1447C186.15 79.8456 181.012 74.7349 174.698 74.7304C168.347 74.7304 163.205 79.8867 163.218 86.2453C163.232 92.5262 168.389 97.6551 174.693 97.6551V97.6597ZM204.639 100.453C198.302 100.476 193.196 105.586 193.196 111.913C193.196 118.28 198.357 123.396 204.749 123.368C211.058 123.341 216.16 118.189 216.146 111.858C216.132 105.563 210.953 100.43 204.639 100.453Z' fill={ fill } />
    <path d='M164.573 274.909H176.963V319.867C176.565 319.89 176.208 319.927 175.856 319.927C172.927 319.931 169.999 319.904 167.075 319.95C166.298 319.963 165.817 319.712 165.341 319.099C159.563 311.644 153.757 304.211 147.96 296.769C147.649 296.367 147.328 295.973 147.031 295.599C146.939 295.617 146.903 295.617 146.871 295.631C146.843 295.644 146.807 295.672 146.802 295.695C146.784 295.8 146.765 295.909 146.765 296.019C146.761 303.841 146.756 311.666 146.752 319.488C146.752 319.524 146.743 319.561 146.738 319.598C146.733 319.634 146.729 319.671 146.715 319.703C146.701 319.735 146.674 319.762 146.605 319.858H134.462V274.941C134.732 274.905 135.007 274.841 135.281 274.841C138.612 274.831 141.938 274.859 145.269 274.818C145.96 274.809 146.317 275.138 146.697 275.622C152.26 282.781 157.838 289.935 163.411 297.084C163.722 297.482 164.033 297.875 164.477 298.433C164.673 296.938 164.55 295.644 164.573 294.355C164.596 293.075 164.577 291.795 164.577 290.515V274.9L164.573 274.909Z' fill={ fill } />
    <path d='M197.438 302.158V309.262H221.536V319.748C220.717 319.991 188.086 320.105 185.148 319.876C185.13 319.854 185.102 319.826 185.079 319.799C185.057 319.771 185.011 319.744 185.011 319.716C184.993 305.093 184.974 290.474 184.961 275.851C184.961 275.563 184.988 275.27 185.006 274.909H221.184V285.441C217.253 285.519 213.319 285.464 209.384 285.473C205.44 285.482 201.496 285.473 197.442 285.473C197.3 287.736 197.383 289.912 197.392 292.207C198.627 292.335 199.821 292.257 201.011 292.271C202.182 292.284 203.353 292.271 204.525 292.271H218.951V302.135C215.382 302.158 211.813 302.145 208.245 302.145C204.667 302.145 201.093 302.145 197.447 302.145L197.438 302.158Z' fill={ fill } />
    <path d='M89.9716 224.429C90.3605 224.411 90.7174 224.374 91.0742 224.374C97.402 224.374 103.73 224.36 110.062 224.374C112.816 224.383 115.543 224.658 118.156 225.599C122.566 227.186 125.664 230.061 126.849 234.71C127.517 237.338 127.526 239.999 126.886 242.636C125.966 246.408 123.669 249.127 120.311 251.002C119.835 251.267 119.341 251.504 118.705 251.834C121.981 256.656 125.275 261.415 128.606 266.315C128.258 266.375 128.034 266.448 127.805 266.448C123.852 266.457 119.904 266.434 115.95 266.471C115.282 266.475 114.976 266.164 114.656 265.68C112.217 261.991 109.746 258.325 107.321 254.622C106.896 253.973 106.447 253.667 105.647 253.708C104.375 253.776 103.098 253.726 101.666 253.726C101.634 255.92 101.657 258.028 101.652 260.126C101.652 262.197 101.652 264.272 101.652 266.388H89.9624V224.42L89.9716 224.429ZM101.707 244.469C101.954 244.51 102.096 244.552 102.234 244.552C104.755 244.552 107.28 244.616 109.797 244.529C110.799 244.492 111.837 244.268 112.771 243.898C114.706 243.13 115.626 241.475 115.557 239.263C115.493 237.219 114.463 235.683 112.491 235.053C111.467 234.723 110.35 234.582 109.27 234.545C107.042 234.468 104.814 234.518 102.586 234.527C102.307 234.527 102.028 234.582 101.707 234.614V244.483V244.469Z' fill={ fill } />
    <path d='M98.7105 274.553H110.821C117.186 289.592 123.555 304.654 129.933 319.726C129.077 319.972 118.563 320.059 116.554 319.844C115.479 317.239 114.386 314.583 113.274 311.877C112.89 311.877 112.56 311.877 112.235 311.877C107.147 311.877 102.06 311.877 96.9764 311.877C95.9333 311.877 95.9333 311.877 95.5535 312.823C94.7483 314.821 93.9521 316.823 93.1469 318.825C93.0096 319.163 92.8632 319.492 92.7031 319.867H79.764C79.764 319.867 79.7136 319.826 79.6999 319.799C79.6816 319.767 79.6633 319.726 79.6725 319.694C79.7365 319.488 79.8006 319.273 79.8829 319.076C86.1557 304.238 92.4331 289.4 98.7197 274.548L98.7105 274.553ZM109.65 302.081C108.003 297.88 106.388 293.779 104.777 289.674C104.7 289.683 104.622 289.693 104.544 289.702C102.92 293.793 101.296 297.88 99.6302 302.081H109.65Z' fill={ fill } />
    <path d='M60.5749 223.515C67.8909 223.634 73.8572 225.974 78.4463 231.286C87.844 242.17 84.3897 260.912 68.3622 266.1C60.2181 268.738 52.4628 267.504 45.7508 261.945C40.7728 257.822 38.389 252.364 38.206 245.896C37.8995 234.929 45.9841 225.334 57.4545 223.794C58.6121 223.638 59.7834 223.583 60.5749 223.515ZM59.7925 234.102C56.4114 234.198 53.1583 236.218 51.2961 240.246C49.7588 243.56 49.7268 247.002 51.2412 250.33C53.1445 254.499 56.4251 256.784 61.0782 256.739C65.6353 256.697 68.8243 254.426 70.6727 250.311C71.7205 247.98 71.8715 245.521 71.4048 243.011C70.4257 237.773 66.0837 234.106 59.7925 234.097V234.102Z' fill={ fill } />
    <path d='M211.424 250.558H196.477V266.247C195.617 266.494 186.397 266.567 184.842 266.352V224.443H196.431V239.884C197.319 240.131 209.855 240.195 211.379 239.971V224.607C212.239 224.315 221.865 224.265 223.082 224.521V266.251C222.295 266.498 213.351 266.58 211.488 266.352C211.351 263.76 211.447 261.141 211.429 258.526C211.411 255.902 211.429 253.274 211.429 250.554L211.424 250.558Z' fill={ fill } />
    <path d='M0.139059 274.891C0.477635 274.873 0.834513 274.845 1.19139 274.845C7.3727 274.845 13.554 274.822 19.7353 274.85C23.3956 274.868 26.9506 275.453 30.2357 277.167C34.9529 279.627 37.5243 283.54 38.0642 288.815C38.3067 291.169 38.1648 293.496 37.4923 295.759C35.9778 300.824 32.4594 303.932 27.5958 305.669C24.7407 306.688 21.7759 307.054 18.7562 307.05C17.1091 307.05 15.4619 307.05 13.8194 307.05C13.4625 307.05 13.101 307.05 12.6069 307.05V319.858H0.225991C-0.00277691 319.113 -0.0988594 276.793 0.134484 274.886L0.139059 274.891ZM12.6435 297.162C12.8402 297.203 12.9775 297.253 13.1148 297.253C15.0135 297.258 16.9123 297.295 18.8111 297.244C20.2066 297.208 21.57 296.961 22.8145 296.257C24.3427 295.393 25.2944 294.127 25.5414 292.38C26.0539 288.787 24.3473 286.351 20.7922 285.588C20.4719 285.519 20.1425 285.46 19.8177 285.455C17.5529 285.423 15.2926 285.409 13.0278 285.396C12.8997 285.396 12.767 285.482 12.6389 285.528V297.162H12.6435Z' fill={ fill } />
    <path d='M242.505 256.551H264.974V266.388H230.911V224.438H264.668V234.317H242.445C242.436 236.451 242.432 238.481 242.45 240.666C243.85 240.703 245.186 240.68 246.522 240.684C247.84 240.684 249.157 240.684 250.475 240.684H262.6V249.877C260.376 249.923 258.157 249.891 255.943 249.895C253.71 249.9 251.477 249.895 249.244 249.895C247.012 249.895 244.797 249.895 242.505 249.895V256.551Z' fill={ fill } />
    <path d='M12.0396 251.413V266.384H0.386128V224.566C1.15936 224.329 32.5005 224.255 33.9875 224.498V234.564C32.83 234.664 31.6404 234.596 30.4508 234.609C29.2429 234.623 28.035 234.609 26.8271 234.609H12.1311C11.9709 236.995 12.099 239.308 12.0487 241.727H31.8692V251.413H12.0396Z' fill={ fill } />
    <path d='M265.002 274.955V285.757C263.876 285.871 262.755 285.798 261.639 285.812C260.541 285.825 259.443 285.812 258.345 285.812H251.55V319.703C250.69 319.972 240.533 320.041 239.009 319.803V285.894C236.685 285.725 234.466 285.839 232.256 285.816C230.032 285.793 227.809 285.812 225.512 285.812V275.014C226.308 274.804 263.396 274.736 265.006 274.955H265.002Z' fill={ fill } />
    <path d='M55.9767 308.974H77.7234V319.753C76.9867 319.982 45.5906 320.082 43.4951 319.849V274.923H55.9767V308.979V308.974Z' fill={ fill } />
    <path d='M155.093 234.628H142.469V224.443H179.292V234.595C177.91 234.637 176.537 234.609 175.165 234.614C173.774 234.618 172.383 234.614 170.992 234.614H166.746V266.256C165.845 266.494 156.525 266.548 155.088 266.338V234.628H155.093Z' fill={ fill } />
    <path d='M258.363 312C260.564 312.037 262.284 313.838 262.225 316.041C262.165 318.226 260.349 319.972 258.175 319.927C255.975 319.881 254.286 318.094 254.332 315.867C254.378 313.655 256.139 311.964 258.363 312.005V312ZM258.235 312.663C256.432 312.681 255.014 314.135 255.014 315.963C255.014 317.787 256.446 319.25 258.244 319.255C260.07 319.259 261.57 317.723 261.534 315.886C261.497 314.057 260.051 312.645 258.23 312.663H258.235Z' fill={ fill } />
    <path d='M179.758 86.2224C179.749 89.052 177.498 91.2874 174.671 91.2782C171.861 91.2691 169.592 88.9789 169.606 86.1767C169.619 83.3563 171.875 81.1346 174.716 81.1392C177.571 81.1438 179.768 83.3608 179.758 86.2224Z' fill={ fill } />
    <path d='M204.689 106.843C207.508 106.852 209.764 109.115 209.754 111.936C209.745 114.742 207.467 116.991 204.644 116.987C201.816 116.978 199.579 114.724 199.593 111.894C199.606 109.056 201.844 106.839 204.685 106.848L204.689 106.843Z' fill={ fill } />
    <path d='M260.138 317.678C258.921 318.299 258.683 317.129 257.969 316.613C257.846 317.047 257.745 317.394 257.617 317.829C257.315 317.81 257.013 317.796 256.67 317.774V313.961C257.43 313.961 258.143 313.911 258.853 313.97C260.102 314.076 260.555 315.127 259.818 316.165C259.736 316.279 259.58 316.334 259.429 316.443C259.69 316.896 259.9 317.262 260.138 317.678ZM257.887 315.758C258.358 315.744 258.866 315.941 258.944 315.31C258.958 315.195 258.564 314.939 258.34 314.912C257.745 314.839 257.722 315.214 257.892 315.758H257.887Z' fill={ fill } />
  </SvgIcon>
);
