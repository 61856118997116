import React, { ChangeEvent, InputHTMLAttributes, KeyboardEventHandler, useEffect, useRef } from 'react';
import { Label } from '../../styles/components/label';

import { AccessoryContainer, TextFieldContainer } from './styles';

export enum TextFieldKind {
  Pill = 'pill',
  Regular = 'regular',
}

export enum TextFieldSize {
  Small = 'small',
  Large = 'large',
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  // use to provide additional context
  // use in place of ariaDescribedBy when there isn't an element on the page with the needed description text
  // ariaDescription?: string;
  // use to provide additional context
  // use when an element currently exist with the description text, pass thru that element's id
  ariaDescribedBy?: string;
  className?: string;
  errorText?: string;
  fieldKind?: TextFieldKind;
  fieldSize?: TextFieldSize;
  fieldSubtext?: string;
  id: string;
  label: string | JSX.Element;
  labelHidden?: boolean;
  leftAccessory?: JSX.Element;
  maxLength?: number;
  placeholder?: string;
  rightAccessory?: JSX.Element;
  value: string | number;
  // adding to make required
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  onRef?(ref: HTMLInputElement): void;
}

export const TextField: React.FC<IProps> = ({
  className = '',
  ariaDescribedBy,
  // ariaDescription,
  errorText,
  fieldKind = TextFieldKind.Regular,
  fieldSize = TextFieldSize.Small,
  fieldSubtext,
  id,
  label,
  labelHidden,
  leftAccessory,
  placeholder,
  rightAccessory,
  type = 'text',
  value,
  maxLength,
  onChange,
  onKeyPress,
  onRef,
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) onRef?.(inputRef.current); 
  }, [inputRef.current]);

  return (
    <TextFieldContainer className={ className }>
      <Label className={ `${labelHidden ? 'sr-only' : ''} ${fieldKind}-label` } htmlFor={ id }>{ label }</Label>
      <div className={ `input-container ${ fieldKind } ${ fieldSize } ${!!leftAccessory ? 'with-left-accessory' : ''} ${!!rightAccessory ? 'with-right-accessory' : '' }` }>
        { !!leftAccessory && <AccessoryContainer aria-hidden='true' className={ `left ${ fieldKind } ${ fieldSize }` }>{ leftAccessory }</AccessoryContainer> }
        <input
          { ...restProps }
          className={ `text-field-input ${fieldKind}-text-field ${!!errorText ? 'error' : ''}` }
          id={ id }
          aria-describedby={ ariaDescribedBy }
          // aria-description={ ariaDescription }
          onChange={ onChange }
          onKeyPress={ onKeyPress }
          placeholder={ placeholder }
          ref={ inputRef }
          type={ type }
          value={ value }
          maxLength={ maxLength }
        />
        { !!rightAccessory && <AccessoryContainer className={ `right ${ fieldKind } ${ fieldSize }` }>{ rightAccessory }</AccessoryContainer> }
      </div>
      { !!errorText && <p className='field-error'>{ errorText }</p> }
      { !errorText && !!fieldSubtext && <p className='field-text'>{ fieldSubtext }</p> }
    </TextFieldContainer>
  );
};
