import React, { useEffect } from 'react';
import { SuccessPageContainer, AppButtonsWrapper, ButtonsSection, ResultInfoSection } from './styles';
import { observer } from 'mobx-react';
import GooglePlayBadge from '../../../../../public/assets/images/google-play-badge.png';
import AppStoreButton from '../../../../../public/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import { ButtonLink } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { useUserSession } from '../../../../contexts/user';
import { ResultHeader } from '../../styles';

export const SuccessPageBase: React.FC = () => {
  const analytics = useAnalytics();
  const hasAccount = useUserSession().isLoggedIn; // eslint-disable-line
  const userSession = useUserSession();

  useEffect(() => {
    analytics.fireEvent('ApplyForCard_Success');
    userSession.setHasUnpaidMembership(false);
  }, []);

  const onAppleClick = () => {
    analytics.fireEvent('ApplyForCard_AppleStore_Click');
  };

  const onGoogleClick = () => {
    analytics.fireEvent('ApplyForCard_GoogleStore_Click');
  };

  return (
    <SuccessPageContainer>
      <ResultInfoSection>
        {
          !!hasAccount
            ? <ResultHeader>Payment Complete</ResultHeader>
            : <ResultHeader>Payment Complete & Account Created</ResultHeader> 
        }
        {
          !hasAccount ?
            <p>Your payment was accepted. Please check your email and verify your account to start using your Karma Wallet Card.</p> : null
        }
        <p className='download-app-text'>Download the app to get started.</p>
      </ResultInfoSection>
      <ButtonsSection>
        <AppButtonsWrapper>
          <ButtonLink
            kind={ ButtonKind.Blank }
            target='_blank'
            onClick={ onAppleClick }
            href='https://apps.apple.com/app/karma-wallet/id6451440075'
          >
            <img src={ AppStoreButton } alt='App Store Button' />
          </ButtonLink>
          <ButtonLink
            kind={ ButtonKind.Blank }
            target='_blank'
            onClick={ onGoogleClick }
            href='https://play.google.com/store/apps/details?id=com.karmawalletnative.app'
          >
            <img className='google-button' src={ GooglePlayBadge } alt='Google Play Badge' />
          </ButtonLink>
        </AppButtonsWrapper>
        <p>Standard text message and data rates, fees, and changes may apply.</p>
      </ButtonsSection>
    </SuccessPageContainer>
  ); 
};

export const SuccessPage = observer(SuccessPageBase);
