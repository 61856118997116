import React, { useCallback } from 'react';
import { RadioButton } from '../../RadioButton';
import { DonationOptionContainer } from './styles';

export interface IDonationProps {
  isSelected: boolean;
  type: string;
}

interface IProps extends IDonationProps {
  className?: string;
  index: number;
  onClick(type: string, index: number, amount: number): () => void;
  amount: number;
  description: string;
}

export const DonationOption: React.FC<IProps> = ({
  className = '',
  amount,
  isSelected,
  index,
  type,
  onClick,
  description,
}) => {
  const renderAmount = useCallback(() => (
    <div className={ `${isSelected? 'active' : 'inactive'} amount-text` }>
      ${ amount.toFixed(2) }
    </div>
  ), [amount, isSelected]);

  const renderRadioButton = useCallback(() => (
    <RadioButton
      id={ `donation-option-${index}` }
      label={ `Select Donation Amount of ${ amount.toString() }` }
      labelHidden
      onClick={ onClick(type, index, amount) }
      checked={ isSelected }
    />
  ), [amount, type, index, isSelected]);

  return (
    <DonationOptionContainer
      className={ className }
      key={ `donation-option-${index}` }
    >
      <div className='radio-button-wrapper'>
        { renderRadioButton() }
      </div>
      { renderAmount() }
      <div dangerouslySetInnerHTML={ { __html: description } } className='option-description' />
    </DonationOptionContainer>
  );
};
