import styled from 'styled-components';
import { FlexCol, NoScrollBar } from '../../styles/styles';
import { Modal } from '../modals/Modal';
import { Checkbox } from '../Checkbox';
import { _H4 } from '../../styles/components/header';

interface IFilterCompaniesModalProps {
  windowHeight: number;
  children: React.ReactNode;
}

interface ICashbackCheckboxProps {
  checked: boolean;
}

export const CashbackCheckbox = styled(Checkbox)<ICashbackCheckboxProps>`
  align-items: center;
  background: ${({ checked, theme }) => checked ? theme.colors.dark : theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row-reverse;
  font-weight: 500;
  font-size: 14px;
  gap: 10px;
  height: 44px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 24px;
  width: 100%;

  .label {
    color: ${({ checked, theme }) => checked ? theme.colors.white : theme.colors.dark};
    font-weight: 500;
    font-size: 16px;
    padding-left: 0;
  }

  .checkbox.square {
    background: ${({ checked, theme }) => checked ? theme.colors.green : theme.colors.white};
    border-radius: 50%;
    width: 24px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    font-size: 16px;
    max-width: 213px;
    padding: 10px 24px;
  }
`;

export const FilterCompaniesModalContainer = styled(Modal)<IFilterCompaniesModalProps>`
  ${NoScrollBar}
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  height: ${({ windowHeight }) => `calc(${windowHeight}px - 100px)`};
  max-width: 916px;
  overflow: scroll;
  padding: 20px;
  position: absolute;
  top: 75px;
  width: 90%;
  z-index: 1;
`;

export const Header = styled.div`
  ${FlexCol}
  align-items: center;

  .header-text {
    border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.darkGray2};
    font-weight: 500;
    padding-bottom: 10px;
    text-align: center;
    width: 100%;
  }
`;

export const ModalContent = styled.div`
  height: 80%;
  overflow: auto;
`;

export const ModalSection = styled.div`
  padding: 15px;

  .filter-header {
    ${_H4}
    color: ${({ theme }) => theme.colors.dark};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      font-size: 24px;
    }
  }
`;

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: sticky;
  
  .clear-all-button {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
`;

export const RatingSection = styled(ModalSection)``;
export const PillFilterSection = styled(ModalSection)``;
export const UNSDGFilterSection = styled(ModalSection)``;
export const CashbackSectionContainer = styled(ModalSection)``;
export const ValuesFilterSection = styled(ModalSection)``;
