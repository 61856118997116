import React from 'react';
import { ContactUsContainer } from './styles';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { ButtonLink } from '../Button';
import { useNavigate } from 'react-router';

interface IProps {
  className?: string;
}

export const ContactUs: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const onContactUsClick = () => {
    analytics.fireEvent('Footer_ContactUs');
    navigate('/contact-us');
  };

  return (
    <ContactUsContainer className={ className }>
      <p>Still Have Questions?</p>
      <ButtonLink
        className='contact-us-button'
        onClick={ onContactUsClick }
        kind={ ButtonKind.Blank }
        href='/contact-us'
      >
        Contact Us
      </ButtonLink>
    </ContactUsContainer>
  );
};
