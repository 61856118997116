import React from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const TooltipInfoIcon: React.FC<IProps> = ({
  className = '',
  fill = '#000000',
  id = '',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}info-icon` }
    id={ id }
    height={ 16 }
    width={ 16 }
    fill={ fill }
  >
    <g>
      <path d='M8,15C6.143,15 4.363,14.262 3.05,12.95C1.738,11.637 1,9.857 1,8C1,6.143 1.738,4.363 3.05,3.05C4.363,1.738 6.143,1 8,1C9.857,1 11.637,1.738 12.95,3.05C14.262,4.363 15,6.143 15,8C15,9.857 14.262,11.637 12.95,12.95C11.637,14.262 9.857,15 8,15ZM8,16C10.122,16 12.157,15.157 13.657,13.657C15.157,12.157 16,10.122 16,8C16,5.878 15.157,3.843 13.657,2.343C12.157,0.843 10.122,0 8,0C5.878,0 3.843,0.843 2.343,2.343C0.843,3.843 0,5.878 0,8C0,10.122 0.843,12.157 2.343,13.657C3.843,15.157 5.878,16 8,16Z' fill={ fill } />
      <path d='M8.93,6.588L6.64,6.875L6.558,7.255L7.008,7.338C7.302,7.408 7.36,7.514 7.296,7.807L6.558,11.275C6.364,12.172 6.663,12.594 7.366,12.594C7.911,12.594 8.544,12.342 8.831,11.996L8.919,11.58C8.719,11.756 8.427,11.826 8.233,11.826C7.958,11.826 7.858,11.633 7.929,11.293L8.93,6.588ZM9,4.5C9,4.765 8.895,5.02 8.707,5.207C8.52,5.395 8.265,5.5 8,5.5C7.735,5.5 7.48,5.395 7.293,5.207C7.105,5.02 7,4.765 7,4.5C7,4.235 7.105,3.98 7.293,3.793C7.48,3.605 7.735,3.5 8,3.5C8.265,3.5 8.52,3.605 8.707,3.793C8.895,3.98 9,4.235 9,4.5Z' fill={ fill } />
    </g>
  </SvgIcon>
);
