import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const Square: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }square` }
    id={ id }
    width={ 294 }
    height={ 298 }
    fill={ fill }
    aria-hidden='true'
  >
    <path d='M294 150.15C294 84.4647 293.622 29.566 280.889 16.0807C271.379 2.0054 214.694 0.00292969 146.17 0.00292969C85.6138 0.00292969 34.3057 0.8252 16.6239 11.3599C15.537 11.8436 14.4986 12.5885 13.5282 13.5849C12.1501 14.8135 11.17 16.1388 10.6071 17.5608C0.339683 35.4476 0 86.3704 0 146.406C0 207.844 0.0776374 259.734 11.1311 276.45C11.4611 277.012 11.8687 277.563 12.3442 278.105C13.5864 279.614 14.9256 280.717 16.3619 281.374C33.7622 292.199 84.9926 294.686 145.539 296.204C209.124 297.8 262.586 298.071 277.832 286.511C292.671 278.695 294 220.633 294 150.15Z' />
  </SvgIcon>
);
