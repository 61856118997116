import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { BaseModel } from './base';
import { Ratings } from './impact';

type PrivateFields = '_cashbackOnly' |
'_searchQuery' |
'_sectors' |
'_ratings' |
'_sort' |
'_unsdgs' |
'_values' |
'_clear';

export interface IParamsObject {
  sort: string;
  companyName?: string;
  'sectors.sector'?: string;
  rating?: string;
  'merchant!'?: string;
  evaluatedUnsdgs?: string;
  'values.value'?: string;
  karmaCollectiveMember?: string;
}

export enum CompanySortType {
  CompanyName = 'companyName',
  HighScore = '-combinedScore',
  LowScore = 'combinedScore',
}

export enum SortText {
  CompanyName = 'Name',
  HighScore = 'High - Low Rating',
  LowScore = 'Low - High Rating',
}

export interface SortOption {
  id: string;
  text: string;
  context: CompanySortType;
}

export const companyNameSortOption = {
  id: 'sort-by-name',
  text: 'Company Name', 
  context: CompanySortType.CompanyName,
};

export const highScoreSortOption = {
  id: 'sort-by-high-score',
  text: 'High Rating', 
  context: CompanySortType.HighScore,
};

export const lowScoreSortOption = {
  id: 'sort-by-low-score',
  text: 'Low Rating',
  context: CompanySortType.LowScore,
};

export const companyOptions: SortOption[] = [
  companyNameSortOption,
  highScoreSortOption,
  lowScoreSortOption,
];

export class BrowseByQueryModel extends BaseModel {
  private _cashbackOnly = false;
  private _clear = false;
  private _ratings: Ratings[] = [];
  private _unsdgs: string[] = [];
  private _searchQuery = '';
  private _sectors: string[] = [];
  private _sort: CompanySortType = null;
  private _values: string[] = [];

  constructor () {
    super();
    makeObservable<BrowseByQueryModel, PrivateFields>(this, {
      _cashbackOnly: observable,
      _clear: observable,
      _ratings: observable,
      _searchQuery: observable,
      _sectors: observable,
      _sort: observable,
      _unsdgs: observable,
      _values: observable,
      paramsObject: computed,
      searchQuery: computed,
      sectors: computed,
      sort: computed,
      unsdgs: computed,
      values: computed,
      clearCashback: action.bound,
      clearSectors: action.bound,
      clearRatings: action.bound,
      clearUnsdgs: action.bound,
      clearValues: action.bound,
      setSort: action.bound,
      setCashbackOnly: action.bound,
      toggleClear: action.bound,
      updateSectors: action.bound,
      updateRatings: action.bound,
      updateUnsdgs: action.bound,
      updateValues: action.bound,
    });
  }

  get cashbackOnly() { return this._cashbackOnly; }
  get clear() { return this._clear; }
  get ratings() { return this._ratings; }
  get searchQuery() { return this._searchQuery; }
  get sectors() { return this._sectors; }
  get selectedFiltersCount() { 
    return this._sectors.length + this._ratings.length + (this._cashbackOnly ? 1 : 0) + this._unsdgs.length | this._values.length; 
  }
  get sort() { return this._sort; }
  get unsdgs() { return this._unsdgs; }
  get values() { return this._values; }
  get paramsObject() { 
    const paramsObject: IParamsObject = { sort: this._sort };

    if (this._sectors.length > 0) paramsObject['sectors.sector'] = toJS(this._sectors.map(s => s)).join(',');
    if (this._ratings.length > 0) paramsObject.rating = toJS(this._ratings.map(r => r)).join(',');
    if (this._searchQuery) paramsObject.companyName = this._searchQuery;
    if (this._cashbackOnly) {
      paramsObject['merchant!']= 'null';
      // if no rating is selected, we still want to ensure that we do not retrieve any companies with cashback offers that are negative
      if (!this._ratings.length) paramsObject.rating = 'positive,neutral';
    }

    if (this._unsdgs) paramsObject.evaluatedUnsdgs = toJS(this._unsdgs.map(u => u)).join(',');
    if (this._values) paramsObject['values.value'] = toJS(this._values.map(v => v)).join(',');

    return paramsObject;
  }

  updateSectors(sector: string | string[]) {
    runInAction(() => {
      if (Array.isArray(sector)) {
        for (const s of sector) {
          if (this._sectors.includes(s)) this._sectors = [...this._sectors.filter(s => s !== s)];
          else this._sectors = [...this._sectors, s];
        }
      } else {
        if (this._sectors.includes(sector)) this._sectors = [...this._sectors.filter(s => s !== sector)]; 
        else this._sectors = [...this._sectors, sector];
      } 
    });
  }

  updateRatings(rating: Ratings) {
    runInAction(() => {
      if (this._ratings.includes(rating)) this._ratings = [...this._ratings.filter(s => s !== rating)];
      else this._ratings = [...this._ratings, rating];
    });
  }

  updateUnsdgs(unsdg: string) {
    runInAction(() => {
      if (this._unsdgs.includes(unsdg)) this._unsdgs = [...this._unsdgs.filter(u => u !== unsdg)];
      else this._unsdgs = [...this._unsdgs, unsdg];
    });
  }

  updateValues(value: string) {
    runInAction(() => {
      if (this._values.includes(value)) this._values = [...this._values.filter(v => v !== value)];
      else this._values = [...this._values, value];
    });
  }

  clearSectors() { runInAction(() => this._sectors = []); }
  clearRatings() { runInAction(() => this._ratings = []); }
  clearUnsdgs() { runInAction(() => this._unsdgs = []); }
  clearValues() { runInAction(() => this._values = []); }
  clearCashback() { runInAction(() => this._cashbackOnly = false); }
  setCashbackOnly(cashbackOnly: boolean) { runInAction(() => this._cashbackOnly = cashbackOnly); }
  setSort(sort: CompanySortType) { runInAction(() => this._sort = sort); }
  setSearchQuery(searchQuery: string) { runInAction(() => this._searchQuery = searchQuery); }
  toggleClear() { runInAction(() => this._clear = !this._clear); }
}
