import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { RelinkCardModal } from '../modals/RelinkCardModal';
import { PencilBanner } from './PencilBanner';
import { PencilBannersContainer } from './styles';
import { PayMembershipBanner } from './PayMembershipBanner';

interface IProps {
  className?: string;
}

const PencilBannersBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const [showRelinkCardModal, setShowRelinkCardModal] = useState(false);

  const onRelinkCardClick = useCallback(() => {
    setShowRelinkCardModal(true);
  }, []);

  useEffect(() => {
    user.loadCards()
      .catch(() => {
        analytics.fireEvent('PencilBanners');
      });
  }, [user.isLoggedIn]);

  useEffect(() => {
    if (!!user.hasUnlinkedCards) {
      analytics.fireEvent('HasUnlinkedCards');
    }
  }, [user.hasUnlinkedCards]);

  useEffect(() => {
    if (!!user.transactionsReady) {
      user.loadCards(true)
        .catch(() => {
          analytics.fireEvent('TransactionsReady_LoadCards_Error');
        });
    }
  }, [user.transactionsReady]);

  const onRelinkCardModalClose = useCallback(() => {
    analytics.fireEvent('RelinkCard_Confirm_Close');
    setShowRelinkCardModal(false);
  }, []);

  const onRelinkSuccess = useCallback(async () => {
    try {
      setShowRelinkCardModal(false);
      user.loadCards(true);
    } catch (err) {
      analytics.fireEvent('PencilBanner_LoadCards_Error');
    }
  }, [user]);

  return (
    <PencilBannersContainer className={ className }>
      {
        user.hasUnlinkedCards && (
          <PencilBanner key='card-relinking-banner'>
            { user.unlinkedCards.length >= 1 && 'One or more banks need relinking.' }
            <Button
              className='relink-card-cta'
              kind={ ButtonKind.Blank }
              onClick={ onRelinkCardClick }
            >
              RE-LINK »
            </Button>
          </PencilBanner>
        )
      }
      {
        user.isLoggedIn && user.hasUnpaidMembership && <PayMembershipBanner />
      }
      <RelinkCardModal
        isOpen={ showRelinkCardModal }
        onClose={ onRelinkCardModalClose }
        onRelinkSuccess={ onRelinkSuccess }
      />
    </PencilBannersContainer>
  );
};

export const PencilBanners = observer(PencilBannersBase);
