import styled from 'styled-components';
import { FlexColCenter, FlexRow } from '../../../styles/styles';

export const CardholderViewContainer = styled.div`
  ${FlexColCenter}
  padding: 60px 0;
  margin: 0 auto;
  text-align: center;
  width: 80%;
  max-width: 700px;

  img {
    max-width: 50%;
    margin: 20px auto 0;

    @media(${({ theme }) => theme.breakpoints.smMin}) {
      max-width: 30%;
    }
  }

  && > h5,p {
    max-width: 525px;
    margin: 0 auto;
  }

  h2 {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 3rem;
    margin: 0 auto;
    max-width: 250px;

    @media(${({ theme }) => theme.breakpoints.smMin}) {
      max-width: unset;
    }
  }

  p a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const AppButtonsContainer = styled.div`
  ${FlexRow}
  justify-content: center;
  gap: 20px;
  margin: 20px 0;

  img {
    max-width: unset;
    height: 40px;
    width: auto;
    margin: 0 auto;
  }
`;
