import styled from 'styled-components';
import { Section } from '../../styles/components/containers';
import { FlexCol, SecondaryFont, PrimaryFont, LineClamp, FlexRow } from '../../styles/styles';

export const CompanyCashbackContainer = styled.div`
  background: ${({ theme }) => theme.colors.lightGreen3};
  box-shadow: 0px 0px 25px 0px rgba(0, 28, 32, 0.05) inset;
  padding: 48px 12px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 96px 0; 
  }
`;

export const CompanyCashbackContainerInner = styled(Section)`
  ${FlexCol}
  align-items: center;
  border-radius: 16px;
  box-shadow: 2px 8px 25px 0px rgba(0, 28, 32, 0.10);
  margin: auto;
  max-width: 710px;
  padding: 48px 24px;
  text-align: center;
  width: 100%;

  .view-cashback-details {
    margin: 20px auto 0;
    width: 100%
  }

  .line-clamp {
    ${LineClamp}
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .cashback-sub-text {
    ${SecondaryFont};
    font-size: 14px;
    margin-bottom: 1.5rem;
    line-height: 1.3rem }

  a {
    margin-top: 1.5rem;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 96px;

    .cashback-sub-text {
      ${SecondaryFont};
      font-size: 23px;
      line-height: 1.8rem;
      max-width: 700px;
    }

    .cashback-percent-indicator {
      font-size: 20px;
    }
  }
`;

export const HeaderWrapperContainer = styled.div`
  ${FlexRow}
  align-items: center;
  margin-bottom: 24px;
  max-width: 248px;

  svg {
      margin-top: 14px;
      margin-left: -133px;
    }

  .cashback-title {
    font-size: 36px;
    line-height: 36px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 48px;
    max-width: unset;
    
    .cashback-title {
      font-size: 70px;
      line-height: 72px;
    }

    svg {
      margin-top: -35px;
      margin-left: 5px;
      height: 18px;
      width: 18px;
    }
  }
`;

export const CurrentOffersContainer = styled.div`
  ${FlexCol}
  align-items: center;

  .apply-wrapper {
    margin-top: 60px;
    max-width: 400px;

    .apply-now-link {
      text-decoration: underline;
    }
  }

  .new-customers-only-text {
    font-style: italic;
    font-size: 11px;
    color: ${({ theme }) => theme.colors.darkGray2};
  }

  .start-shopping-double {
    margin-top: 25px;
  }
`;

export const CashbackTagWrapper = styled.div`
  ${FlexCol}
  display: flex;

  .max-rate-tag {
    ${ PrimaryFont }
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    background: ${({ theme }) => theme.colors.green1};
    color: ${({ theme }) => theme.colors.dark};
    border-radius: 31px;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    padding: 7px 16px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 3px;
  }
  
  .new-customers-only {
    margin-top: 5px;
    font-style: italic;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.darkGray2};
  }
`;

export const CashbackCardsContainer = styled.div`
  ${FlexCol}
  width: 100%;
  gap: 24px;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    justify-content: space-around;
  }
`;

export const CashbackCard = styled.div`
  ${FlexCol}
  max-width: 240px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.midGray};
  position: relative;

  .start-shopping-cashback {
    margin-top: 10px;
    padding: 8px 22px;
    width: 100%;
  }

  .cashback-offers {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

export const CashbackCardTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  .up-to {
    color: ${({ theme }) => theme.colors.dark};
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .large-tag {
    padding: 7px 20px;
    font-size: 20px;
    width: 116px;
    height: 38px;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    text-align: center;
    color: ${({ theme }) => theme.colors.dark};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 0px 30px;
    margin-top: 10px;
  }
`;

export const ProtipContainer = styled.div`
  ${FlexCol}
  align-items: center;
  align-self: stretch;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  gap: 8px;
  line-height: normal;
  margin-top: 20px;
  max-width: 275px;
  
  div {
    color: ${({ theme }) => theme.colors.black};
  }

  .pro-tip {
    background: ${({ theme }) => theme.colors.tertiary};
    border-radius: 4px;
    flex: none;
    padding: 4px 8px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    max-width: unset;
    text-align: left;

    .pro-tip {
      padding: 7px 8px;
    }
  }
`;
