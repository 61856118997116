import React from 'react';
import { PublicationDate, SentimentBar, SentimentRangeContainer, SentimentSourceArticleItemContainer, SourceName, SourceTitle, SourceTitleContainer } from './styles';
import { ISentimentSourceArticle } from '../../../../../models/sentiment/types';
import dayjs from 'dayjs';
import { NewWindowIcon } from '../../../../../components/svgs/icons/NewWindowIcon';
import { IThemeProps } from '../../../../../styles/themes';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
  article?: ISentimentSourceArticle;
}

export enum SentimentValue {
  Positive = 'positive',
  Neutral = 'neutral',
  Negative = 'negative',
}

const SentimentSourceArticleItemBase: React.FC<IProps> = ({
  className,
  article,
  theme,
}) => {
  const analytics = useAnalytics();
  const { metadata, date } = article;
  const { source, title, url } = metadata;
  const sentiment = article?.sentiment?.overall?.value || SentimentValue.Neutral;

  const onSourceTitleClick = () => {
    analytics.fireEvent('Sentiment_SourcesModal_Article_Click', title);
  };
  
  return (
    <SentimentSourceArticleItemContainer className={ className }>
      <SourceName>{ source }</SourceName>
      <SourceTitleContainer>
        <SourceTitle
          href={ url }
          target='_blank'
          title={ title }
          rel='noreferrer'
          onClick={ onSourceTitleClick }
        >
          { title }
        </SourceTitle>
        <NewWindowIcon className='new-window-icon' fill={ theme.colors.grey4 } />
      </SourceTitleContainer>
      <SentimentRangeContainer className={ className }>
        <SentimentBar sentiment={ sentiment as SentimentValue } />
      </SentimentRangeContainer>
      <PublicationDate>{ dayjs(date).format('MM/DD/YY') }</PublicationDate>
    </SentimentSourceArticleItemContainer>
  );
};

export const SentimentSourceArticleItem = withTheme(SentimentSourceArticleItemBase);
