import React, { useCallback } from 'react';
import { useAnalytics } from '../../../contexts/analytics-store';
import { removeSpecialCharacters } from '../../../lib/misc';
import { H5 } from '../../../styles/components/header';
import { SourceContainer } from './styles';

export interface ISource {
  name: string
  description: string;
  link: string;
  img: string;
  className?: string;
}

export const Source: React.FC<ISource> = ({
  className = '',
  img,
  link,
  name,
  description,
}) => {
  const analytics = useAnalytics();

  const onSourceClick = useCallback(() => {
    analytics.fireEvent(`SourceClick_${removeSpecialCharacters(name)}`);
  }, []);
  
  return (
    <SourceContainer className={ className }>
      <div className={ `img-container ${!img ? 'no-logo' : ''}` }>
        {
          !!img && <img src={ img } alt={ name } />
        }  
      </div>
      <H5>
        <a
          href={ link }
          onClick={ onSourceClick }
          target='_blank'
          rel='noreferrer'
        >
          { name }
        </a>
      </H5>
      <p>{ description }</p>
    </SourceContainer>
  );
};
