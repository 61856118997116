import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection, Section } from '../../styles/components/containers';
import { FlexCenter, FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';
import { TextSMMedium, TextSMDemiBold } from '../../styles/components/text';

export const BrowseCompaniesContainer = styled(MainContainer)`
  background: ${({ theme }) => theme.colors.light};
  overflow: hidden;

  .company-result-item {
    .positive, .negative, .neutral {
      max-width: 50px;
      max-height: 50px;
    }
  }

  .sub-text {
    text-align: center;
    margin: 0 auto 20px;
    max-width: 850px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 40px;
    } 
  }
`;

export const GetKarmaCardSection = styled.div`
  ${FlexHorizontalCenter}
  gap: 14px;
  justify-content: space-between;
  margin-bottom: 14px;

  .karma-card {
    width: 34px;
  }

  .unlock-button {
    display: none;
  }
  
  .pill-tag {
    ${TextSMDemiBold}
    color: ${({ theme }) => theme.colors.primary};
    min-width: 187px;
  }

  .take-advantage {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.grey5};
    margin: unset;

    .get-card {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 700;
      text-decoration: none;
    }
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    .unlock-button {
      display: block;
    }
  }
`;

export const BrowseCompaniesInnerWrapper = styled(MaxBoundarySection)`
  ${FlexCol}
  max-width: 1400px;
  width: 100%;
  background: ${({ theme }) => theme.colors.light};
  padding-top: unset;

  h2 {
    margin: 0 auto 20px;
  }

  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0.6;
  }

  .back-to-top:hover {
    opacity: 0.8;
  }
 
  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    .dropdown-options-container {
      max-width: 100%;
    }

    h2 {
      margin-bottom: 20px;
    }
  }
`;

export const SearchContainer = styled(Section)`
  ${FlexCol}
  background: unset;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    padding-top: 60px;
  }
`;

export const SearchContainerInner = styled(MaxBoundarySection)`
  ${FlexCol}
  align-items: center;
  background: unset;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 0;
  
  .text-field {
    margin: unset;
    width: 100%;

    svg {
      margin-right: 3px;
    }
  }
  
  input.pill-text-field {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.midGray};
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 600;

    ::placeholder {
      color: ${({ theme }) => theme.colors.dark};
      font-weight: 400;
    }
  }

  input.pill-text-field:focus:not(:disabled) {
    box-shadow: none;
    background: ${({ theme }) => theme.colors.white};
    font-weight: 600;
  }

  .input-container.small input.pill-text-field {
    padding: 13px 35px;
    font-weight: 600;
    font-size: 16px;
  }

  .compare-button {
    ${FlexRow}
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    margin-top: 10px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 16px;

    .compare-button {
      font-size: 14px;
    }

    .input-container.small input.pill-text-field {
      padding: 13px 35px;
      font-size: 16px;
    }

    .text-field {
      max-width: 700px;
    }
  }
`;

export const CompareIconWrapper = styled.div`
  ${FlexColCenter}
  align-items: center;
  height: 16px;
  justify-content: center;
  width: 16px;
`;

export const NoResults = styled.div`
  ${FlexCenter}
  color: ${({ theme }) => theme.colors.darkGray3};
  padding: 10px;
`;

export const LoadingSpinnerContainer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 60px;
  width: 200px;
`;

export const CompaniesListContainer = styled.div`
  ${FlexRow}
  padding-top: 10px;
  flex-wrap: wrap;
  justify-content: space-between;

  .browse-company-card,
  .company-placeholder {
    width: 100%;
    margin-bottom: 20px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: calc(25% - 10px);
    }

    @media (${({ theme }) => theme.breakpoints.lgMax}) {
      width: calc(33.33% - 10px);
    }

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      width: calc(50% - 10px);
    }

    @media (${({ theme }) => theme.breakpoints.smMax}) {
      width: 100%;
    }
  }

  .company-placeholder {
    height: auto;
  }
  
  .browse-company-item {
    padding-left: 0px;
    padding-right: 0px;
    
    .default-company-logo {
      max-height: 80%;
    }

    .search-result-company-logo {
      border: 1px solid ${({ theme }) => theme.colors.lightGray1};
      max-width: 82px;
      min-width: 82px;
      border-radius: 5px;

      img {
        max-width: 70%;
        max-height: 70%;
      }
    }
  }

  .company-rating-indicator {
    width: 25px;
    height: 25px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 30px;
      height: 30px;
    }
  }
`;

export const ResultsContainer = styled.div`
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .sort-text {
      display: block;
    }
  }
`;

export const CompanyCount = styled.div`
  width: 100%;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.disabled};
  font-size: 14px;
`;
