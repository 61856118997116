import React from 'react';
import { AnimatedEllipsisContainer } from './styles';

interface IProps {
  className?: string;
  text: string;
}

export const AnimatedEllipsis: React.FC<IProps> = ({
  className = '',
  text,
}) => (
  <AnimatedEllipsisContainer className={ className }>
    { text }
  </AnimatedEllipsisContainer>
);
