import styled from 'styled-components';
import { FlexCenter, FlexCol, FlexHorizontalCenter, PrimaryFont, ScreenReaderOnly } from '../../styles/styles';

export const TextFieldContainer = styled.div`
  ${ FlexCol }
  justify-content: center;
  margin-bottom: 20px;
  font-weight: 500;

  label {
    display: block;
    color: ${({ theme }) => theme.colors.midGray};
    
    &.regular-label {
      margin-bottom: 5px;
    }

    &.pill-label {
      margin: 0 0 5px 10px;
    }

    &.sr-only {
      ${ScreenReaderOnly}
    }
  }

  .input-container {
    ${ FlexHorizontalCenter }
    position: relative;
    width: 100%;
    max-width: 100%;

    &.small {
      input {
        ${ PrimaryFont }
        padding: 0 8px;
        font-size: 16px;
        font-weight: 400;

        &.pill-text-field {
          padding: 11px 20px;
        }
      }

      &.with-left-accessory {
        input {
          padding-left: 30px;
        }
      }
  
      &.with-right-accessory {
        input {
          padding-right: 30px;
        }
      }
    }

    &.large {
      input {
        ${ PrimaryFont }
        padding: 0 12px;
        font-size: 20px;
        font-weight: 500;

        &.pill-text-field {
          padding: 16px 48px;
        }
      }

      &.with-left-accessory {
        input {
          padding-left: 55px;
        }
      }
  
      &.with-right-acessory {
        input {
          padding-right: 55px;
        }
      }
    }
  }

  input {
    flex-grow: 1;
    display: block;
    width: 100%;
    max-width: 100%;
    padding-bottom: 8px;
    border: none;
    background: #fff;
    color: ${({ theme }) => theme.colors.darkGray1};
    outline: none;
    transition: .25s ease-in-out;
    box-sizing: border-box;

    &.error.pill-text-field, &.error.pill-text-field:focus:not(:disabled) { // required to over-specify focus state of default text input
      border: 1px solid ${({ theme }) => theme.colors.danger};
    }

    &.regular-text-field {
      min-width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray1};
    }

    &.pill-text-field {
      min-width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      border-radius: 999px;
  
      &:focus:not(:disabled) {
        ${({ theme }) => `
          border: 1px solid ${theme.colors.darkGray3};
        `}
      }
  
      &:disabled {
        ${({ theme }) => `
          border: 1px solid ${theme.colors.lightGray1};
          background: ${theme.colors.lightGray2};
          color: ${theme.colors.disabled};
        `}
      }
  
      &::placeholder {
        color: ${({ theme }) => theme.colors.midGray};
      }
    }
  }

  .field-text, .field-error {
    margin: 5px 0 0;
    padding-left: 10px;
    text-align: left;
  }

  .field-error {
    color: ${({ theme }) => theme.colors.danger};
    margin-bottom: 0;
  }

  .field-text {
    padding-left: 25px;
    text-align: left;
  }
`;

export const AccessoryContainer = styled.div`
  ${ FlexCenter }
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);

  &.small {
    min-width: 16px;
    max-width: 16px;
    min-height: 20px;
    max-height: 20px;

    &.left {
      left: 10px;
    }
  
    &.right {
      left: calc(100% - 26px);
    }
  }

  &.large {
    min-width: 25px;
    max-width: 25px;
    min-height: 30px;
    max-height: 30px;

    &.left {
      left: 25px;
    }
  
    &.right {
      left: calc(100% - 50px);
    }
  }

  & > * {
    max-width: 100%;
    max-height: 100%;
  }
`;
