import { AppType } from '../constants';
import { CompanyModel, IWildfireOverview } from '../models/companies';
import { UserSessionModel } from '../models/users';

export interface IBuildRareUrl {
  amount: number;
  email: string;
  groupId?: string;
  redirectType?: string;
  redirectTo?: string;
  send_webhook_query_params?: boolean;
  statementIds?: string;
  user_id: string;
  [key: string]: string | string[] | number | number[] | boolean;
}

export const buildBaseKarmaUrl = (app: AppType) => {
  let baseUrl;

  const subDomain = app === 'karma'
    ? ''
    : `${app}.`;

  if (window.location.hostname.includes('staging')) {
    baseUrl = `https://${subDomain || 'frontend.'}staging.karmawallet.io`;
  } else if (window.location.hostname.includes('sandbox')) {
    baseUrl = `https://${subDomain || ''}${window.location.hostname.split('.').find(x => x.includes('sandbox'))}.karmawallet.io`;
  } else if (window.location.hostname.includes('localhost')) {
    baseUrl = 'http://localhost:3001';
  } else {
    baseUrl = `https://${subDomain}karmawallet.io`;
  }

  return baseUrl;
};

export const buildQueryParams = (args: { [key: string]: string | string[] | number | number[] | boolean }) => Object.entries(args)
  .map(([key, value]) => Array.isArray(value)
    ? value.map(v => `${key}=${v}`).join('&')
    : `${key}=${value}`)
  .join('&');

// rare required items should use underscores (send_webhook_query_params, user_id, amount, email)
// IK only uses camelCase (groupId, statementIds, redirectType)
export const buildRareUrl = ({ amount, redirectType, redirectTo, ...restArgs }: IBuildRareUrl) => {
  const redirectUrl = `redirect_url=${window.encodeURIComponent(redirectTo ? redirectTo : `${window.location.origin}/donations`)}`;

  const params = buildQueryParams({
    ...restArgs,
    amt: Math.floor(Number(amount) * 100),
    redirect_type: redirectType || 'success_page',
    send_webhook_query_params: true,
  });

  const subdomain = window.location.host.includes('sandbox') || window.location.host.includes('staging') || window.location.host.includes('localhost') ? 'sandbox' : 'app';

  return `https://${subdomain}.catch-carbon-payments.rare.org/checkout/5oXx69relu3XCYz?${redirectUrl}&${params}`;
};

export const buildWildfireCashbackUrl = (wildfireMerchantData: IWildfireOverview, userId: string) => {
  const deviceId = '16783413';
  const { merchantId, domain } = wildfireMerchantData || {};
  if (!merchantId || !domain) {
    return '';
  }
  const encodedDomain = window.encodeURI(`${domain}`);
  return `https://wild.link/e?c=${merchantId}&url=${encodedDomain}&tc=${userId}&d=${deviceId}`;
};

export const buildShoppingUrl = (user: UserSessionModel, company: CompanyModel) => {
  if (!user?._id || !company?.merchant) return '';
  let builtUrl;
  if (company.merchant.integrations?.wildfire) {
    builtUrl = buildWildfireCashbackUrl(company.merchant.integrations.wildfire, user._id);
  } else {
    builtUrl = company.url;
  }
  return builtUrl;
};
