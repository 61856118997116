import styled from 'styled-components';
import { MaxBoundarySectionLarge } from '../../styles/components/containers';
import { _H2 } from '../../styles/components/header';
import { FlexCol } from '../../styles/styles';

export const DeterminingCompanyImpactContainer = styled(MaxBoundarySectionLarge)`
  .header {
    ${_H2}
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .section {
    border-radius: 12px;
    padding: 30px 15px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding: 40px;
    }
  }

  .middle-section {
    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      H2 {
        font-size: 40px;
      }
    }
  }

  .unsdg-legend {
    margin-bottom: 20px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      height: 140px;
      flex-wrap: wrap;
      max-width: 850px;
      margin-bottom: 30px;
      padding-left: 40px;
  
      div {
        width: 50%;
        padding-right: 20px;
      }
    }

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      max-width: 350px;
    }
  }

  .overall-rating {
    border-radius: 12px;
  }

  .subheader-text {
    font-size: 20px;
  }

  .rating-bar {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
  }

  .impact-categories-hiw {
    width: 100%;
    padding: 0;

    .impact-category-section {
      .impact-category-header {
        .category-content-wrapper {
          margin: 0 auto;
          align-items: center;
        }
      }

      .impact-category-header > div {
        display: flex;
        flex-direction: column;

        & > div :not(.category-content-wrapper > div) {
          width: 100%;
        }
      }
    }
    
    .sub-text {
      display: none;
    }
  }
`;

export const ContentContainer = styled.div`
  ${FlexCol}
  gap: 20px;

  &.error {
    justify-content: center;
    min-height: 100px;
  }

  .error-text {
    text-align: center;
    color: ${({ theme }) => theme.colors.disabled};
  }
`;

export const LeftWrapper = styled.div`
  width: 49%;
`;

export const RightWrapper = styled(LeftWrapper)``;

export const ExampleDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkGray2};
  font-size: 14px;
  margin: 20px 0 0px;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    font-size: 16px;
  }
`;
