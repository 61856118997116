import React from 'react';
import { QRCodeLandingPageContainer } from './styles';
import { QrCodeLandingPageHero } from './Hero';
import { GetStartedSection } from '../../components/GetStartedSection';

interface IProps {
  className?: string;
}

export const QrCodeLanding: React.FC<IProps> = ({
  className = '',
}) => (
  <QRCodeLandingPageContainer title='Karma Wallet Activate Your Card' className={ className }>
    <QrCodeLandingPageHero />
    <GetStartedSection />
  </QRCodeLandingPageContainer>
);
