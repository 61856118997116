import styled, { css } from 'styled-components';
import { PrimaryFont, SecondaryFont } from '../styles';

const _BaseSecondary = css`
  ${ SecondaryFont }
  color: ${({ theme }) => theme.colors.dark};
`;

const _BasePrimary = css`
  ${ PrimaryFont }
  color: ${({ theme }) => theme.colors.dark};
`;

export const _H1M = css`
  ${_BaseSecondary}
  font-size: 86px;
  font-weight: 400;
  line-height: 88px;
`;

export const _H2M = css`
  ${_BaseSecondary}
  font-size: 70px;
  font-weight: 400;
  line-height: 4.25rem;
`;

export const _H2MobileMarketing = css`
  ${_BaseSecondary}
  font-size: 40px;
  font-weight: 400;
  line-height: 42px;
`;

export const _H1 = css`
  ${_BaseSecondary}
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 2.75rem;
`;

export const _H2 = css`
  ${_BaseSecondary}
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.18rem;
`;

export const _H3 = css`
  ${_BasePrimary}
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
`;

export const _H4 = css`
  ${_BasePrimary}
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
`;

export const _H5 = css`
  ${_BasePrimary}
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
`;

export const _H6 = css`
  ${_BasePrimary}
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
`;

export const _H4Marketing = css`
  ${_BaseSecondary}
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;

export const H1M = styled.h1`
  ${_H1M}
  font-size: 3.375rem;
  line-height: 3.5rem;

  @media (min-width: 992px) {
    ${_H1M}
  }
`;

export const H1MInverted = styled.h1`
  ${_H1M}
  font-size: 3.375rem;
  line-height: 3.5rem;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.offWhite};

  @media (min-width: 992px) {
    ${_H1M}
  }

  // Fallback for browsers that don't support this feature
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    span {
      color: ${({ theme }) => theme.colors.offWhite};
    }
  }
`;

export const H1 = styled.h1`
  ${_H2}

  @media (min-width: 992px) {
    ${_H1}
  }
`;

export const H2 = styled.h2`
  ${_H2}

  @media (min-width: 992px) {
    ${_H2}
  }
`;

export const H2MobileMarketing = styled.h2`
  ${_H2MobileMarketing}
`;

export const H2M = styled.h2`
  ${_BaseSecondary}
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;

  @media (min-width: 992px) {
    ${_H2M}
  }
`;

export const H2MInverted = styled.h2`
  ${_H1M}
  font-size: 54px;
  font-weight: 400;
  line-height: 56px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.offWhite};

  @media (min-width: 992px) {
    ${_H2M}
  }

  // Fallback for browsers that don't support this feature
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    span {
      color: ${({ theme }) => theme.colors.offWhite};
    }
  }
`;

export const H3 = styled.h3`
  ${_H4}

  @media (min-width: 992px) {
    ${_H3}
  }
`;

export const H4 = styled.h4`
  ${_H5}

  @media (min-width: 992px) {
    ${_H4}
  }
`;

export const H5 = styled.h5`
  ${_H6}

  @media (min-width: 992px) {
    ${_H5}
  }
`;

export const H6 = styled.h6`
  ${_H6}

  @media (min-width: 992px) {
    ${_H6}
  }
`;

export const AccountHubH1 = styled.h1`
  font-size: 22px;
  font-weight: 700;
`;

export const AccountHubH2LG = styled.h2`
  font-size: 22px;
  font-weight: 600;
`;

export const AccountHubH2 = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const AccountHubH3 = styled.h3`
  font-size: 18px;
  font-weight: 700;
`;
