import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../styles/components/containers';
import { FlexCol, FlexRow, SecondaryFont } from '../../styles/styles';

export const FeaturedArticleSectionContainer = styled(Section)`
  background: ${(props) => props.backgroundColor ? props.backgroundColor : ({ theme }) => theme.colors.dark};
`;

export const ArticlesSectionContainer = styled(Section)`
  background: ${({ theme }) => theme.colors.white};
`;

export const ContentWrapper = styled(MaxBoundaryContainer)`

h3 {
  ${SecondaryFont}
  color: ${({ theme }) => theme.colors.offWhite};
  font-size: 36px;
  text-align: center;
}

.the-good-bad-text {
  color: ${({ theme }) => theme.colors.darkYellow1};
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

@media (${({ theme }) => theme.breakpoints.lgMin}) {
  h3 {
    text-align: left;
  }
  
  .the-good-bad-text {
    text-align: left;
  }
}
`;

export const ArticleContainer = styled.div`
  ${FlexCol}
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const TopSection = styled.div`
  ${FlexCol}
  flex-direction: column-reverse;
  margin-bottom: 80px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    align-items: center;
    justify-content: space-between;
  }

  .error-text {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const TextSection = styled.div`
  ${FlexCol}
  align-items: start;

  .company-name {
    color: ${({ theme }) => theme.colors.tertiary};
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    margin: 10px 0 30px;
    max-width: 430px;
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    align-items: center;
    margin-top: 50px;

    p {
      text-align: center;
    }
  }
`;

export const ImageSection = styled.div`
  margin: 0 auto;
  position: relative;

  .featured-image {
    border-radius: 50%;
    height: 300px;
    margin: 0 auto;
    object-fit: cover;
    width: 300px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      width: 500px;
      height: 500px;
    }
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  bottom: -10px;
  display: flex;
  height: 80px;
  justify-content: center;
  position: absolute;
  right: 50px;
  width: 200px;

  .company-logo {
    max-height: 90%;
    width: 80%;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    height: 100px;
    right: 25%;
    width: 250px;
  }
`;
