import styled from 'styled-components';
import { Button } from '../Button';

export const EyeToggleContainer = styled(Button)`
  svg {
    min-width: 20px;
    min-width: 20px;
    min-height: unset;
    height: auto;
    max-height: unset;
  }
`;
