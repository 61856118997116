import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { H1 } from '../../../../styles/components/header';
import { IThemeProps } from '../../../../styles/themes';
import { Button } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';
import { AccountCompletionContainer, CreateAccountFormContainer, Header } from './styles';
import { useUserSession } from '../../../../contexts/user';

interface IProps extends IThemeProps {
  className?: string;
  onClose?: () => void;
}

const ChangeEmailCompletionBase: React.FC<IProps> = ({
  className = '',
  onClose,
}) => {
  const user = useUserSession();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [affirmToken, setAffirmToken] = useState('');
  const [genError, setGenError] = useState('');

  const onAffirmChangeEmail = useCallback( async () => {
    try{
      await user.affirmChangeEmail({ affirmToken });
      setSearchParams('');
      onClose();
      if (user.isLoggedIn) {
        navigate('/account/account-information');
      } else {
        navigate('?signin=true');
      }
    } catch (err: any) {
      setGenError(err.message);
    }
  }, [affirmToken]);

  useEffect(() => {
    const token = searchParams.get('affirmEmailChange');
    if (token) {
      setAffirmToken(token);
    }
  }, []);

  const renderHeaderSection = useCallback(() => {
    const header = 'You are nearly there!';
    const text = `Click the button below to finish your email changing request${user.isLoggedIn ? '.' : ' and sign in with your new email.'}`;

    return (
      <Header className='create-account-header'>
        <H1>{ header }</H1>
        <p className='subtext'>{ text }</p>
      </Header>
    );
  }, []);

  const renderCTA = useCallback(() => (
    <Button
      kind={ ButtonKind.Primary }
      disabled={ !affirmToken }
      onClick={ onAffirmChangeEmail }
      className='cta'
    >
      Complete Email Change
    </Button>
  ), [affirmToken]);

  const renderContent = () => (
    <CreateAccountFormContainer>
      { renderHeaderSection() }
      { renderCTA() }
      { !!genError && <p className='field-error'>{ genError }</p> }
    </CreateAccountFormContainer>
  );

  return (
    <AccountCompletionContainer className={ className }>
      { renderContent() }
    </AccountCompletionContainer>
  );
};

const ChangeEmailCompletionObserver = observer(ChangeEmailCompletionBase);
export const ChangeEmailCompletion = withTheme(ChangeEmailCompletionObserver);
