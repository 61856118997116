import React from 'react';
import { observer } from 'mobx-react';
import { ApplyForCardModel, CurrentPage } from '../../../models/karmaCard';
import { ApplyDisclaimerContainer } from './styles';
import { useKarmaCardLegal } from '../../../contexts/karma-card-legal';

interface IProps {
  className?: string;
  applyForCardModel: ApplyForCardModel;
}

export const ApplyDisclaimerBase: React.FC<IProps> = ({
  className = '',
  applyForCardModel,
}) => {
  const karmaCardLegalContext = useKarmaCardLegal();
  
  if (applyForCardModel.currentPage !== CurrentPage.Success) return null;
  if (!karmaCardLegalContext?.issuerStatement && !karmaCardLegalContext.issuerCashbackStatement) return null;

  return (
    <ApplyDisclaimerContainer className={ className }>
      <p>{ karmaCardLegalContext.issuerStatement }</p>
    </ApplyDisclaimerContainer>
  );
};

export const ApplyDisclaimer = observer(ApplyDisclaimerBase);
