import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useUserSession } from '../../contexts/user';
import { checkIsValidDollarAmountAndFormat } from '../../lib/formatValidators';
import { buildRareUrl } from '../../lib/urlBuilders';
import { ImpactModel } from '../../models/impact';
import { H5, H3 } from '../../styles/components/header';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CustomDonationOption } from './CustomDonationOption';
import { DonationOption } from './DonationOption';
import { DonationOptions, DonationOptionsErrorContainer, DonationOptionsSkeleton, DonationSectionContainer, DonationWidgetContainer } from './styles';

interface IProps {
  className?: string;
}

const headerSection = (
  <div className='header-section'>
    <H3>Support a Climate Impact Project</H3> 
    <div className='select-donation-amount-text'>
      Select a donation amount to offset your shopping carbon footprint.
    </div>
  </div>
);

const errorText = 'Please enter a valid dollar amount';

export const DonationSectionBase: React.FC<IProps> = ({
  className = '', 
}) => {
  const analytics = useAnalytics();
  const errorMessages = useErrorMessages();
  const impactModel = useRef(new ImpactModel()).current;
  const user = useUserSession();
  const [activeDonation, setActiveDonation] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [customAmountError, setCustomAmountError] = useState(false);
  const [customAmountTimeout, setCustomAmountTimeout] = useState<number>(null);
  const firedCustomAmountEvent = useRef(false);
  const donateEnabled = useMemo(() => (
    activeDonation !== 3 ||
      (activeDonation === 3 && !customAmountError && !!customAmount && customAmount !== '0')
  ), [customAmount, activeDonation]);

  const getTonnesPerDonationAmount = () => {
    if (!customAmount) return;
    impactModel.getTonnesPerAmount(customAmount)
      .catch((err) => {
        errorMessages.push({
          title: 'Error computing tonnes per donation amount',
          message: err.message,
        });

        analytics.fireEvent('OffsetEmissions_TonnesPerAmount_Error');
      });
  };

  useEffect(() => {
    impactModel.loadCarbonDonationSuggestions()
      .catch(() => {
        analytics.fireEvent('OffsetEmissions_DonationSuggestions_Error');
      });
  }, []);

  useEffect(() => {
    if (!customAmountError && !!customAmount) {
      window.clearTimeout(customAmountTimeout);

      setCustomAmountTimeout(window.setTimeout(() => {
        getTonnesPerDonationAmount();
      }, 200));

      if (!firedCustomAmountEvent.current) {
        analytics.fireEvent('OffsetEmissions_CustomAmount_Entered');
        firedCustomAmountEvent.current = true;
      }
    }
  }, [customAmount, customAmountError]);

  useEffect(() => {
    if (impactModel.carbonDonationSuggestions.length > 0) {
      setSelectedOption(impactModel.carbonDonationSuggestions[1].amount.toFixed(2));
    }
  }, [impactModel.carbonDonationSuggestions]);

  const onDonationClick = useCallback((type: string, i: number, amount: number) => () => {
    setActiveDonation(i);
    setCustomAmountError(false);
    setSelectedOption(amount.toFixed(2));
    // clear out the custom field
    setCustomAmount('');
    analytics.fireEvent(`OffsetEmissions_Donation_Click_${type}`);
  }, [activeDonation, selectedOption]);

  const onCustomAmountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { isValid, formattedAmount } = checkIsValidDollarAmountAndFormat(e.target.value);
    setCustomAmountError(!isValid && !!e.target.value);
    setCustomAmount(formattedAmount);
    setSelectedOption(formattedAmount);
  }, []);

  const onCustomAmountClick = useCallback(() => {
    setActiveDonation(impactModel.carbonDonationSuggestions.length);
  }, [impactModel.carbonDonationSuggestions]);

  const onSubmit = useCallback(() => {
    analytics.fireEvent('OffsetEmissions_Donate_Click');

    window.open(buildRareUrl({
      amount: parseInt(selectedOption, 10),
      email: user.primaryEmail.email,
      user_id: user._id,
    }), '_blank');
  }, [selectedOption, activeDonation, user?.primaryEmail, user?._id]);

  const getCustomDescriptionText = useCallback(() => {
    if (impactModel.loadingTonnesPerAmount) return <LoadingSpinner />;
    if (customAmountError) return <div className='error-text'>{ errorText }</div>;
    if (!!customAmount && !!impactModel.tonnesPerAmount?.tonnes) {
      return <div>Offsets { Number(impactModel.tonnesPerAmount?.tonnes).toFixed(2) } tonnes of CO<sub>2</sub> emissions</div>;
    }

    return <div>Enter a custom dollar amount to offset</div>;
  }, [customAmount, customAmountError, impactModel.tonnesPerAmount?.tonnes, impactModel.loadingTonnesPerAmount]);

  const renderDonationWidget = () => (
    <DonationWidgetContainer className={ className }>
      <H5 className='picker-title'>Suggested Donation Amounts</H5>
      <div className='options'>
        { 
          impactModel.carbonDonationSuggestions.map((opt, i) => (
            <DonationOption 
              key={ `donation-option-${opt.type}` }
              className={ `option ${activeDonation === i ? 'selected' : ''}` }
              amount={ opt.amount }
              index={ i }
              type={ opt.type }
              description={ opt.description }
              onClick={ onDonationClick }
              isSelected={ activeDonation === i }
            />
          ))
        }
        <CustomDonationOption 
          key={ 'donation-option-custom' }
          className={ 
            `option ${activeDonation === impactModel.carbonDonationSuggestions.length
              ? 'selected' : ''} ${!!customAmountError ? 'error-text'
              : ''
            }` 
          }
          customAmount={ customAmount }
          type='custom'
          isSelected={ activeDonation === impactModel.carbonDonationSuggestions.length }
          description={ getCustomDescriptionText() }
          onChange={ onCustomAmountChange }
          onCustomClick={ onCustomAmountClick }
        />
        <div className='sub-text'>
            Your average monthly and total CO<sub>2 </sub>emissions are calculated based on all spending data we have for you.
        </div>
      </div>
    </DonationWidgetContainer>
  );

  const renderDonationOptions = useCallback(() => {
    if (impactModel.loadingCarbonDonationSuggestions) return <DonationOptionsSkeleton />;

    if (!impactModel.loadingCarbonDonationSuggestions && !impactModel.carbonDonationSuggestions.length) {
      return (
        <DonationOptionsErrorContainer>
          <div>There was an issue loading the donation options, please reload or try again later.</div>
        </DonationOptionsErrorContainer>
      );
    }
    
    return (
      <DonationOptions>
        { renderDonationWidget() }
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onSubmit }
          className='cta'
          disabled={ !donateEnabled }
        >
          Donate To Catch Carbon
        </Button>
        <div className='donation-sub-text'>
          This tax-deductible donation benefits Rare.org, a 501 (c) (3) nonprofit organization dedicated to transparent and effective carbon offset projects.
        </div>
      </DonationOptions>
    );
  }, [activeDonation, selectedOption]);

  return (
    <DonationSectionContainer className={ className }>
      { headerSection }
      { renderDonationOptions() }
    </DonationSectionContainer>
  );
};

export const DonationSection = observer(DonationSectionBase);
