import React, { ChangeEvent, useCallback, useRef } from 'react';
import { defaultSupportedFileTypes, defaultSupportedImageTypes, FileTypes } from '../../models/fileUploader';
import { ButtonKind } from '../Button/styles';
import { EditButton } from './styles';

interface IProps {
  anchor: JSX.Element;
  className?: string;
  label: string;
  uploadType?: 'image' | 'file';
  onChange(file: File): void;
  supportedFileTypes?: FileTypes[];
}

export const FileUploader: React.FC<IProps> = ({
  anchor,
  className = '',
  uploadType = 'image',
  label, 
  onChange,
  supportedFileTypes,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);

  const getSupportedFileTypes = useCallback(() => {
    if (supportedFileTypes) return supportedFileTypes.join(',');

    return uploadType === 'image' ? defaultSupportedImageTypes : defaultSupportedFileTypes;
  }, [supportedFileTypes, uploadType]);

  const onEditButtonClick = useCallback(() => fileRef.current.click(), []);

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files && !!e.target.files[0]) onChange(e.target.files[0]);
  }, [onChange]);

  return (
    <EditButton
      className={ className } 
      kind={ ButtonKind.Blank }
      label={ label }
      onClick={ onEditButtonClick }
    >
      <input
        accept={ getSupportedFileTypes() }
        name={ uploadType }
        onChange={ onFileChange }
        ref={ fileRef }
        type='file'
      />
      { anchor }
    </EditButton>
  );
};
