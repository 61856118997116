import styled from 'styled-components';
import { FlexCol, FlexHorizontalCenter } from '../../styles/styles';

export const RadioGroupContainer = styled.div`
  &.vertical {
    ${ FlexCol }
    
    .radio-button {
      margin: 5px 0;
    }
  }

  &.horizontal {
    ${FlexHorizontalCenter}

    .radio-button {
      margin: 0 10px;
    }
  }
`;
