import React from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const InfoIcon: React.FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}info-icon` }
    id={ id }
    height={ 18 }
    width={ 18 }
  >
    <path d='M8.16675 8.14943C8.16675 7.6892 8.53984 7.3161 9.00008 7.3161C9.46032 7.3161 9.83342 7.6892 9.83342 8.14943V13.1494C9.83342 13.6097 9.46032 13.9828 9.00008 13.9828C8.53984 13.9828 8.16675 13.6097 8.16675 13.1494V8.14943Z' fill={ fill } />
    <path d='M9.00008 4.04258C8.53984 4.04258 8.16675 4.41568 8.16675 4.87592C8.16675 5.33615 8.53984 5.70925 9.00008 5.70925C9.46032 5.70925 9.83342 5.33615 9.83342 4.87592C9.83342 4.41568 9.46032 4.04258 9.00008 4.04258Z' fill={ fill } />
    <path fillRule='evenodd' clipRule='evenodd' d='M9.00008 0.666626C4.39771 0.666626 0.666748 4.39759 0.666748 8.99996C0.666748 13.6023 4.39771 17.3333 9.00008 17.3333C13.6025 17.3333 17.3334 13.6023 17.3334 8.99996C17.3334 4.39759 13.6025 0.666626 9.00008 0.666626ZM2.33341 8.99996C2.33341 12.6819 5.31818 15.6666 9.00008 15.6666C12.682 15.6666 15.6667 12.6819 15.6667 8.99996C15.6667 5.31806 12.682 2.33329 9.00008 2.33329C5.31818 2.33329 2.33341 5.31806 2.33341 8.99996Z' fill={ fill } />
  </SvgIcon>
);
