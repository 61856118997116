import React, { useMemo } from 'react';
import { OurImpactHeroContainer, OurImpactHeroInnerContainer } from './styles';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { useAppData } from '../../contexts/app';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { HeaderSize, SectionHeader } from '../SectionHeader';

interface IProps extends IThemeProps {
  className?: string;
}

export const OurImpactHeroBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const appData = useAppData();
  const analytics = useAnalytics();
  
  const onLearnMoreClick = () => { 
    analytics.fireEvent('OurImpact_LearnMore_Click');
  };
  
  const renderCertLogos = useMemo(() => (<div>
    <img src={ appData.isDesktop ? 'https://cdn.karmawallet.io/uploads/XcpO8-HMtv-our-impact-certs-desktop.png' : 'https://cdn.karmawallet.io/uploads/UBxDsYmCDO-our-impact-certs-mobile.webp' } alt='Our Impact Certification Graphics' /> 
  </div> ), [appData.isDesktop]);

  const heroContent = () => (
    <OurImpactHeroInnerContainer>
      <SectionHeader 
        className='header'
        headerText='Our Impact'
        headerSize={ HeaderSize.MARKETING }
        subText='The Karma Wallet mission is to create a better world for us all. And our partners help us do it. Through our certifications, donations, partnerships, and community (that’s you!), we can create meaningful impact for people and planet.'
      />
      { renderCertLogos }
      <ButtonLink
        kind={ ButtonKind.SecondaryMarketing }
        onClick={ onLearnMoreClick }
        href='/sources'
      >
        Learn More
      </ButtonLink>
    </OurImpactHeroInnerContainer>
  );

  return (
    <OurImpactHeroContainer
      className={ className }
      backgroundColor={ theme.colors.primary }
    >
      { heroContent() }
    </OurImpactHeroContainer>
  );
};

export const OurImpactHero = withTheme(OurImpactHeroBase);
