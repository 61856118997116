import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { removeSpecialCharacters } from '../../lib/misc';
import { SourcesModel } from '../../models/sources';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { SectionHeader } from '../SectionHeader';
import { LoadingContainer, LogosContainer, SourceContainer, TrustedSourcesContainer } from './styles';

interface IProps {
  className?: string;
}

const TrustedSourcesBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const sourcesModel = useRef(new SourcesModel()).current;
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    const query = 'limit=12';
    sourcesModel.loadSources(query)
      .catch(() => {
        setErrorLoading(true);
      });
  }, []);

  const onSourceClick = (sourceName: string) => () => {
    const source = removeSpecialCharacters(sourceName).split(' ').join('');
    analytics.fireEvent(`HowItWorks_SourceClick_${source}`);
  };

  const logosContainer = useMemo(() => {
    if (sourcesModel.busy) {
      return (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ); 
    }
    
    if (errorLoading ) return <div className='error-text'>Error loading sources</div>;  

    return (
      <LogosContainer>
        {
          sourcesModel.sources.map(({ name, url, logoUrl }) => (
            <SourceContainer key={ name }>
              <a
                href={ url }
                target='_blank'
                onClick={ onSourceClick(name) }
                rel='noreferrer'
              >
                <img src={ logoUrl } alt={ name } />
              </a>
            </SourceContainer>
          ))
        }
      </LogosContainer>
    );
  }, [sourcesModel.sources]);

  const onViewAllSourcesClick = () => {
    analytics.fireEvent('HowItWorks_ViewAllSourcesClick');
  };

  return (
    <TrustedSourcesContainer className={ className }>
      <SectionHeader 
        headerText='Trusted Data Sources'
      />
      { logosContainer }
      <a
        href='/sources'
        onClick={ onViewAllSourcesClick }
      >
        View All Sources
      </a>
    </TrustedSourcesContainer>
  );
};

export const TrustedSources = observer(TrustedSourcesBase);
