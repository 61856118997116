import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const ShareIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}share-icon` }
    id={ id }
    height={ 20 }
    width={ 20 }
    fill={ fill }
  >
    <path d='M10.8327 7.01188V11.7135C10.8327 11.9346 10.7449 12.1465 10.5886 12.3028C10.4323 12.4591 10.2204 12.5469 9.99935 12.5469C9.77834 12.5469 9.56637 12.4591 9.41009 12.3028C9.25381 12.1465 9.16602 11.9346 9.16602 11.7135V7.01188L8.23185 7.94688C8.07537 8.10324 7.86319 8.19105 7.64197 8.19097C7.42076 8.19089 7.20863 8.10294 7.05227 7.94646C6.8959 7.78998 6.8081 7.5778 6.80817 7.35658C6.80825 7.13537 6.8962 6.92324 7.05268 6.76688L9.41018 4.41104C9.48739 4.33339 9.57919 4.27177 9.68031 4.22971C9.78142 4.18766 9.88984 4.16602 9.99935 4.16602C10.1089 4.16602 10.2173 4.18766 10.3184 4.22971C10.4195 4.27177 10.5113 4.33339 10.5885 4.41104L12.946 6.76771C13.0234 6.84519 13.0848 6.93716 13.1267 7.03837C13.1686 7.13959 13.1901 7.24805 13.1901 7.35759C13.1901 7.46712 13.1685 7.57558 13.1265 7.67676C13.0845 7.77794 13.0231 7.86987 12.9456 7.94729C12.8681 8.02472 12.7761 8.08613 12.6749 8.12801C12.5737 8.16989 12.4653 8.19142 12.3557 8.19139C12.2462 8.19135 12.1377 8.16973 12.0366 8.12778C11.9354 8.08583 11.8434 8.02436 11.766 7.94688L10.8327 7.01188ZM6.66602 9.16688C6.88703 9.16688 7.09899 9.25467 7.25527 9.41096C7.41155 9.56724 7.49935 9.7792 7.49935 10.0002C7.49935 10.2212 7.41155 10.4332 7.25527 10.5895C7.09899 10.7457 6.88703 10.8335 6.66602 10.8335H5.83268V14.1669H14.166V10.8335H13.3327C13.1117 10.8335 12.8997 10.7457 12.7434 10.5895C12.5871 10.4332 12.4993 10.2212 12.4993 10.0002C12.4993 9.7792 12.5871 9.56724 12.7434 9.41096C12.8997 9.25467 13.1117 9.16688 13.3327 9.16688H14.166C14.608 9.16688 15.032 9.34247 15.3445 9.65503C15.6571 9.96759 15.8327 10.3915 15.8327 10.8335V14.1669C15.8327 14.6089 15.6571 15.0328 15.3445 15.3454C15.032 15.6579 14.608 15.8335 14.166 15.8335H5.83268C5.39065 15.8335 4.96673 15.6579 4.65417 15.3454C4.34161 15.0328 4.16602 14.6089 4.16602 14.1669V10.8335C4.16602 10.3915 4.34161 9.96759 4.65417 9.65503C4.96673 9.34247 5.39065 9.16688 5.83268 9.16688H6.66602Z' fill={ fill } />
  </SvgIcon>
);
