import styled from 'styled-components';
import { AbsoluteCenter } from '../../styles/styles';

export interface ICircleChartProps {
  size: number;
}

export interface ICircleChartDataProps {
  thickness: number;
}

export const CircleChartContainer = styled.div<ICircleChartProps>`
  position: relative;
  
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
`;

export const CircleChartDataContainer = styled.div<ICircleChartDataProps>`
  ${ AbsoluteCenter }
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
`;
