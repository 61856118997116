import React, { useCallback } from 'react';
import { TeamMember } from '..';
import { TeamMemberCardContainer } from './styles';
import { LinkedInIcon } from '../../../../components/svgs/icons/LinkedInIcon';

interface IProps {
  className?: string;
  teamMember: TeamMember;
  redirect: IPersonRedirect;
}

export interface IPersonRedirect {
  linkedIn?: string;
  other?: string;
}

export const TeamMemberCard: React.FC<IProps> = ({
  className,
  teamMember,
  redirect,
}) => {
  const renderMask = useCallback((redirect: IPersonRedirect) => {
    let icon: JSX.Element;

    if (!!redirect.linkedIn) icon = <LinkedInIcon />;
    
    return (
      <div className='mask'>
        { icon }
      </div>
    );
  }, [redirect]);

  return (
    <TeamMemberCardContainer
      className={ className }
      image={ teamMember.img }
      href={ teamMember.redirect.linkedIn || teamMember.redirect.other }
      target='_blank'
      rel='noreferrer'
    >
      <div className='info-container'>
        <div className='name'>{ teamMember.name }</div>
        <div className='title'>{ teamMember.title }</div>
      </div>
      { renderMask(teamMember.redirect) }
    </TeamMemberCardContainer>
  );
};
