import React from 'react';
import { CoreValuesContainer, CoreValuesHeader, CoreValuesSectionContainer, CoreValuesSectionInnerContainer } from './styles';
import { Link } from 'react-router-dom';
import { CoreValueCard } from './CoreValueCard';
import { H2M } from '../../../styles/components/header';

interface IProps {
  className?: string;
}

export interface CoreValue {
  id: number;
  title: string;
  description: string | JSX.Element;
  image: string;
}

const coreValues: CoreValue[] = [
  {
    id: 1,
    title: 'Trust',
    description: <>We rely on 40+ trusted third-party data sources to evaluate a company's social & environmental impact.<span className='trust-explore-text'> Explore our data partners <Link to={ '/our-impact' }>here</Link>.</span></>,
    image: 'https://cdn.karmawallet.io/uploads/nKfwX6tjAH-trusticon.svg',
  },
  {
    id: 2,
    title: 'Honesty',
    description: <>We never accept payment to adjust a company's report card. Explore 18,000+ report cards <Link to={ '/browse-and-compare-companies' }>here</Link> to discover the truth about the brands you support.</>,
    image: 'https://cdn.karmawallet.io/uploads/e5moGwUhXP-honestyicon.svg',
  },
  {
    id: 3,
    title: 'Transparency',
    description: <>We share the good and bad sustainability data for top brands, so you can easily understand how your dollars are make a difference in the real-world. Explore our research <a href='https://www.karmawallet.io/blog'>here</a></>,
    image: 'https://cdn.karmawallet.io/uploads/rmf39DQEb--transparencyicon.svg',
  },
];

export const CoreValuesSection: React.FC<IProps> = ({
  className,
}) => {
  const coreValuesCards = coreValues.map(coreValue => (
    <CoreValueCard key={ coreValue.id } coreValue={ coreValue } />
  ));

  return (
    <CoreValuesSectionContainer className={ className }>
      <CoreValuesSectionInnerContainer>
        <CoreValuesHeader>
          <H2M>Our Core Values</H2M>
        </CoreValuesHeader>
        <CoreValuesContainer>
          { coreValuesCards }
        </CoreValuesContainer>
      </CoreValuesSectionInnerContainer>
    </CoreValuesSectionContainer>
  );
};
