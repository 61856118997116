import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
  stroke?: string;
}

export const LeafA: FC<IProps> = ({
  className = '',
  id = '',
  fill = 'white',
  stroke = 'black',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}leaf-graphic` }
    id={ id }
    height={ 127 } 
    width={ 117 }
    fill={ fill }
    stroke={ stroke }
  >
    <g transform='matrix(1,0,0,1,-26.4147,-30.3674)'>
      <g>
        <path d='M41.276,126.284C40.325,130.605 45.769,133.152 49.427,138.301C55.908,147.42 58.954,155.747 58.954,155.747C58.954,155.747 58.926,143.66 66.441,139.394C74.823,134.635 103.916,135.979 108.108,127.035C112.3,118.092 101.388,84.119 102.036,83.362C107.322,77.195 113.164,125.653 113.164,125.653C113.164,125.653 128.119,112.223 128.063,105.333C127.988,96.202 113.926,70.465 113.443,68.905C112.92,67.214 120.384,70.052 124.95,77.875C128.244,83.519 130.249,97.148 130.249,97.148C130.249,97.148 140.214,79.386 141.291,71.202C142.367,63.018 141.876,50.742 137.646,46.55C133.416,42.359 124.403,42.353 117.304,45.408C113.224,47.164 105.258,55.631 105.258,55.631C105.258,55.631 102.347,39.449 95.645,35.413C88.157,30.904 69.281,29.753 58.056,32.618C45.817,35.743 34.389,63.324 34.389,63.324C34.389,63.324 48.555,56.323 55.108,55.488C62.426,54.556 80.443,55.755 76.721,58.044C72.998,60.332 43.138,62.649 34.618,69.072C27.704,74.284 27.101,95.169 27.101,95.169C27.101,95.169 38.898,85.848 50.982,79.724C61.629,74.328 72.797,73.078 73.111,73.807C73.477,74.655 62.185,79.681 51.594,86.88C42.772,92.877 34.664,100.841 32.053,104.891C28.385,110.579 36.365,125.235 36.365,125.235C36.365,125.235 43.443,114.344 51.559,106.202C59.99,97.743 69.518,92.085 72.119,95.681C72.39,96.058 63.454,103.39 54.824,111.042C48.657,116.511 42.253,121.844 41.276,126.284Z' fill={ fill } stroke={ stroke } strokeWidth='1.37px;' />
        <path d='M59.356,139.713C62.624,126.723 71.635,108.312 82.989,92.654C88.717,84.754 99.146,67.911 102.263,59.294' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M36.739,112.354C52.113,92.53 68.857,80.724 89.035,78.973' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M34.342,79.637C55.623,67.667 76.66,61.413 97.295,64.538' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M49.462,46.78C69.881,44.126 86.954,46.518 99.471,56.922' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M107.122,58.89C118.368,58.766 128.356,60.956 135.792,69.293' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M118.158,109.394C117.67,92.459 113.924,74.566 102.39,66.29' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M93.503,82.428C99.265,92.032 99.161,108.232 95.13,125.567' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M89.632,116.677C89.583,116.689 89.651,116.68 89.698,116.703' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M91.75,114.506C91.737,114.443 91.777,114.469 91.827,114.469' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M80.893,128.284C81.324,128.179 81.606,127.923 81.931,127.62C82.252,127.322 82.641,127.123 82.953,126.811' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M59.31,124.167C58.912,124.668 58.149,124.541 57.574,124.458C56.669,124.329 55.885,124.074 55.049,123.691' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M59.738,121.46C59.325,121.666 58.867,121.493 58.459,121.324' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M83.104,69.295C83.118,69.282 83.114,69.29 83.1,69.268' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M85.122,74.85C85.506,74.217 86.787,74.085 87.473,74.033' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M89.565,73.903C89.568,73.903 89.572,73.902 89.575,73.902' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M84.098,56.006C84.123,55.973 84.151,56.054 84.168,56.082' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M58.349,48.665C58.43,48.738 58.462,49.2 58.484,49.294C58.593,49.756 58.682,50.208 58.937,50.618' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M60.282,49.678C60.125,49.864 60.507,50.425 60.58,50.571' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M81.267,43.437C80.539,41.975 80.137,40.401 78.999,39.125' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M77.202,36.719C77.196,36.82 77.278,36.726 77.317,36.789' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M67.68,41.125C67.292,40.663 67.022,40.15 66.49,39.822' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M124.633,53.777C124.635,53.808 124.643,53.77 124.648,53.754' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M130.17,56.809C129.813,56.186 131.86,55.353 132.194,55.257' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M132.08,59.08C132.008,59.095 132.352,58.809 132.425,58.774' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M129.695,69.158C129.544,68.951 129.794,69.485 129.815,69.588C129.894,69.976 129.949,70.376 129.887,70.773C129.723,71.83 129.225,72.819 128.744,73.761' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M131.353,74.936C131.418,75.17 131.194,75.543 131.117,75.759' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M110.452,85.229C110.833,85.825 111.048,86.433 111.224,87.123' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M88.362,93.817C88.175,93.815 88.157,93.836 88.261,93.985' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M81.877,102.612C82.881,103.724 82.989,105.468 84.18,106.62' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M80.087,106.692C79.906,106.951 80.412,107.259 80.569,107.411' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M73.186,86.885C73.947,86.979 74.653,87.034 75.422,87.046' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M47.549,96.226C47.586,96.019 47.569,96.066 47.733,96.096' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M49.96,93.851C50.028,93.748 50.007,93.802 50.027,93.692' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M45.379,107.061C45.592,107.043 46.002,107.467 46.292,107.52' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M44.379,77.356C44.334,77.822 44.215,78.291 44.13,78.751' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M40.413,72.524C40.646,71.932 40.725,71.359 40.995,70.764' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M121.339,101.677C121.487,101.447 121.698,100.722 122.009,100.309' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M122.981,103.734C122.56,103.503 123.467,102.144 123.651,101.899' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M72.459,118.114C72.408,118.091 73.276,118.73 74.07,118.718' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M56.077,139.616C56.074,139.614 56.071,139.613 56.068,139.612' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M98.197,75.738C98.733,76.362 98.503,76.952 99.445,77.326' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
        <path d='M93.045,60.87C93.016,60.385 92.7,59.968 92.813,59.349' fill={ fill } fillRule='nonzero' stroke={ stroke } strokeWidth='0.59px;' />
      </g>
    </g>
  </SvgIcon>
);
