import { css } from 'styled-components';
import { PrimaryFont } from '../styles';

export const TextBase = css`
  ${PrimaryFont}
`;

export const TextXXS = css`
  ${TextBase}
  font-size: 10px;
  line-height: 14px;
`;

export const TextXS = css`
${TextBase}
font-size: 12px;
line-height: 16px;
`;

export const TextXSItalic = css`
  ${TextXS}
  font-style: italic;
  font-weight: 400;
`;

export const TextXSLight = css`
  ${TextXS}
  font-weight: 400;
`;

export const TextXSBoldLight = css`
  ${TextXS}
  font-weight: 500;
`;

export const TextXSDemiBold = css`
  ${TextXS}
  font-weight: 600;
`;

export const TextXSBold = css`
  ${TextXS}
  font-weight: 700;
`;

export const TextSM = css`
  ${TextBase}
  font-size: 14px;
  line-height: 18px;
`;

export const TextSMMedium = css`
  ${TextSM}
  font-weight: 500;
`;

export const TextSMDemiBold = css`
  ${TextSM}
  font-weight: 600;
`;

export const TextSMBold = css`
  ${TextSM}
  font-weight: 700;
`;

export const TextMD = css`
  ${TextBase}
  font-size: 16px;
  line-height: 20px;
`;

export const TextMDMedium = css`
  ${TextMD}
  font-weight: 500;
`;

export const TextMDDemiBold = css`
  ${TextMD}
  font-weight: 600;
`;

export const TextMDBold = css`
  ${TextMD}
  font-weight: 700;
`;

export const TextLG = css`
  ${TextBase}
  font-size: 18px;
  line-height: 24px;
`;

export const TextLGMedium = css`
  ${TextLG}
  font-weight: 500;
`;

export const TextLGDemiBold = css`
  ${TextLG}
  font-weight: 600;
`;

export const TextLGBold = css`
  ${TextLG}
  font-weight: 700;
`;

export const TextXL = css`
  ${TextBase}
  font-size: 32px;
  line-height: 34px;
`;

export const TextXLMedium = css`
  ${TextXL}
  font-weight: 500;
`;

export const TextXLDemiBold = css`
  ${TextXL}
  font-weight: 600;
`;

export const TextXLBold = css`
  ${TextXL}
  font-weight: 700;
`;
