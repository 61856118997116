import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { TextField } from '../../components/TextField';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexCol, FlexColCenter } from '../../styles/styles';
import { TextSMMedium } from '../../styles/components/text';

export const ManageSubscriptionsContainer = styled(MainContainer)`
  background: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  position: relative;
  z-index: 0;

  .content-container {
    min-height: unset;
  }
`;

export const ManageSubscriptionsInnerContainer = styled(MaxBoundarySection)`
  background: ${({ theme }) => theme.colors.primary};
  position: relative;
  z-index: 1;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    min-height: calc(100vh - 75px);
    padding-top: 80px;
  }
`;

export const BackgroundContainer = styled.div`
  background: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  .svg-icon, .sad-mascot {
    position: absolute;
  }

  .sad-mascot {
    right: -7%;
    top: -2%;
    width: 43%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      right: -8%;
      top: 3%;
    }
  }

  .earth-clouds {
    display: none;
    height: 200px;
    width: 200px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      right: 16%;
      top: 66%;
    }
  }

  .leaf-a {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      left: 0%;
      top: 14%;
      transform: scale(1.2) rotate(10deg);
    }
  }

  .leaf-b {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      right: -12%;
      top: 5%;
      transform: scale(0.6) rotate(33deg);
    }
  }

  .leaf-c {
    display: none;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      display: block;
      left: -3%;
      top: 50%;
      transform: scale(0.7) rotate(120deg);
    }
  }
`;

export const TopSectionContainer = styled.div`
  margin-bottom: 26px;
  text-align: center;
  z-index: 2;

  H1 {
    color: ${({ theme }) => theme.colors.white};
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    margin-bottom: 48px;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  min-height: 69px;
  position: relative;
`;

export const FormContainer = styled.div`
  ${FlexCol}
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 514px;
  min-height: 250px;
  padding: 32px 16px;
  width: 100%;
  z-index: 2;

  H5 {
    margin: 16px auto;
    text-align: center;
  }

  .pill-tag {
    font-size: 12px;
  }

  .resubscribe-usage-description {
    ${TextSMMedium}
    margin: unset;
    margin-bottom: 6px;
    text-align: center;
    width: 100%;
  }
 
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-width: 514px;
    padding: 32px;

    .pill-tag {
      font-size: 14px;
    }

    .resubscribe-usage-description {
      font-size: 16px;
    }
  }
`;

export const SubscriptionPreferencesSection = styled.div`
  ${FlexColCenter}
  max-width: 100%;
  padding-top: 0;

  form {
    width: 100%;

    .email {
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }

    .newsletter {
      align-items: start;
      padding: 10px;

      .checkbox {
        border: 2px solid ${({ theme }) => theme.colors.black};
      }
    }

    .submit-error {
      color: ${({ theme }) => theme.colors.danger};
      margin-top: 10px;
    }

    &.error {
      input.pill-text-field,
      input.pill-text-field:focus:not(:disabled) {
        border: 2px solid ${({ theme }) => theme.colors.danger};
        box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.danger};
      }
    }

    &.success {
      input.pill-text-field,
      input.pill-text-field:focus:not(:disabled) {
        border: 2px solid ${({ theme }) => theme.colors.success};
        box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.success};
      }
    }

    .field-error {
      padding: 0;
      line-height: 23px;
      font-size: 16px;
      margin: 5px 0 0;
      padding-left: 25px;
      text-align: left;
      color: ${({ theme }) => theme.colors.danger};
      margin-bottom: 0;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      max-width: 606px;
    }
  }

  .newsletter-unsubscribe-cta {
    ${FlexColCenter}
    margin-top: 20px;

    .unsubscribe-cta {
      width: 150px;
    }
  }
`;

export const FormField = styled(TextField)``;
export const SelectField = styled.select``;
