import styled from 'styled-components';

export const CircleBarChartContainer = styled.div`
  width: 100%;
  max-width: 556px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 100%;
    max-width: unset;
  }
`;

export const DonutSegment = styled.circle`
  fill: none;
  stroke-width: 14;
  transform-origin: center;
  cx: 50%;
  cy: 50%;
`;

export const DonutSegmentFill = styled.circle`
  fill: none;
  stroke-linecap: butt;
  stroke-width: 14;
  transform-origin: center;
  cx: 50%;
  cy: 50%;
`;

export const DonutCenterDisc = styled.g``;
