import React, { useCallback, useMemo } from 'react';
import { ButtonKind } from '../Button/styles';
import { Dropdown, IDropdownOption } from '../Dropdown';
import { FilterIcon } from '../svgs/icons/FilterIcon';
import { FilterButton, FilterIconWrapper, FilterSortBarContainer, FilterWrapper, SortWrapper } from './styles';
import { CompanySortType } from '../../models/browse-query';
import { SectorFilter } from '../SectorFilter';
import { XIcon } from '../svgs/icons/XIcon';
import { useBrowseQuery } from '../../contexts/browse-query';

interface IProps {
  className?: string;
  activeSort: string;
  onFilterClick: () => void;
  onSortClick: (option: IDropdownOption<CompanySortType>) => void;
  onSectorClick: (sectorId: string) => void;
  selectedFiltersCount: number;
  selectedSort: any;
  sortOptions: IDropdownOption<string>[];
}

export const FilterSortBar: React.FC<IProps> = ({
  className = '',
  activeSort,
  onFilterClick,
  onSortClick, 
  selectedSort, 
  sortOptions,
  onSectorClick,
  selectedFiltersCount,
}) => {
  const requestQueryModel = useBrowseQuery();
  
  const onClearAll = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    requestQueryModel.clearSectors();
    requestQueryModel.clearRatings();
    requestQueryModel.clearCashback();
    requestQueryModel.clearUnsdgs();
    requestQueryModel.clearValues();
    requestQueryModel.toggleClear();
  }, []);

  const sortDropdown = useMemo(() => {
    if (!selectedSort) return null;

    return (
      <Dropdown 
        className='sort-dropdown'
        id='sort-dropdown'
        options={ sortOptions }
        onOptionClick={ onSortClick }
        selectedOption={ selectedSort }
      />
    );
  }, [activeSort, selectedSort]);

  const renderFilterIcon = useMemo(() => {
    if (selectedFiltersCount === 0) return <FilterIcon />;

    return (
      <div
        className='remove-filters-icon' 
        onClick={ onClearAll }
      >
        <XIcon />
      </div>
    );
  }, [selectedFiltersCount]);

  const renderSelectedFilterCount = useMemo(() => {
    if (selectedFiltersCount === 0) return null;
    return <div className='filters-count'>{ selectedFiltersCount }</div>;
  }, [selectedFiltersCount]);

  return (
    <FilterSortBarContainer className={ className }>
      <SortWrapper className='sort-filter'>
        <label htmlFor='sort-dropdown' className='sort-by-label'>Sort by:</label>
        { sortDropdown }
      </SortWrapper>
      <SectorFilter className='sector-filter' onSectorClick={ onSectorClick } />
      <FilterWrapper>
        <p className='filter-by-label'>Filter by:</p>
        <FilterButton
          className='modal-filter'
          kind={ ButtonKind.Blank }
          label='Open Filter Modal'
          onClick={ onFilterClick }
        >
          <div className='label-text'>Filter By</div>
          <FilterIconWrapper>
            { renderFilterIcon }
          </FilterIconWrapper>
          { renderSelectedFilterCount }
        </FilterButton>
      </FilterWrapper>
    </FilterSortBarContainer>
  );
};
