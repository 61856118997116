import React from 'react';
import { IThemeProps } from '../../../styles/themes';
import { withTheme } from 'styled-components';
import { BenefitCardsContainer, BenefitTitleContainer, ByTheNumbersCard, ByTheNumbersCardsContainer, ByTheNumbersInnerContainer, ByTheNumbersSectionContainer, KarmaWalletEmployeeBenefitContainer } from './styles';
import { H2M } from '../../../styles/components/header';
import { ButtonLink } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
}

interface IEmployeeStatistic {
  id: number;
  titleNumber: string;
  titleText: string;
  description: string;
  sourceLink: string;
}

interface IEmployeeBenefit {
  title: string;
  description: string;
}

const employeeStatistics: IEmployeeStatistic[] = [
  {
    id: 1,
    titleNumber: '83%',
    titleText: 'of employees',
    description: 'don\'t believe their employers are doing enough to be sustainable and tackle climate change.',
    sourceLink: 'https://www.forbes.com/sites/forbesbusinesscouncil/2022/09/22/the-power-of-purpose-how-esg-affects-your-companys-talent/#:~:text=A%20report%20by%20Unily%20(registration,company%20with%20robust%20environmental%20policies',
  },
  {
    id: 2,
    titleNumber: '65%',
    titleText: 'of employees',
    description: 'said they would be more likely to work for a company with robust environmental policies.',
    sourceLink: 'https://www.forbes.com/sites/adigaskell/2021/10/31/employees-demand-that-we-become-more-sustainable/',
  },
  {
    id: 3,
    titleNumber: '71%',
    titleText: 'of job seekers',
    description: 'want to work for sustainable employers. Karma Wallet helps you reward your team - and meet your sustainability goals in the process.',
    sourceLink: 'https://thecsrjournal.in/ibm-employees-work-environmentally-sustainable-companies-pandemic/#google_vignette',
  },
];

const employeeBenefits: IEmployeeBenefit[] = [
  {
    title: 'Plant up to',
    description: '6 trees',
  },
  {
    title: 'Donate up to',
    description: '25 meals',
  },
];

const ByTheNumbersSectionBase: React.FC<IProps> = ({
  className,
}) => {
  const analytics = useAnalytics();

  const onContactUsClick = () => {
    analytics.fireEvent('EmployerPage_ContactUs_Click');
  };
  
  const renderEmployeeStatistics = () => employeeStatistics.map((statistic) => (
    <ByTheNumbersCard key={ statistic.id }>
      <div className='numbers-card-text'>
        <div className='number-text'>{ statistic.titleNumber }</div>
        <div className='title-text'> { statistic.titleText }</div>
        <div className='description'> { statistic.description }</div>
        <a href={ statistic.sourceLink } target='blank' rel='noreferrer' className='source-link'>Source</a>
      </div>
    </ByTheNumbersCard>
  ));

  const renderEmployeeBenefits = () => employeeBenefits.map((benefit) => (
    <div className='benefit-card' key={ benefit.title }>
      <div className='benefit-title'>{ benefit.title }</div>
      <div className='benefit-description'>{ benefit.description }</div>
    </div>
  ));

  return (
    <ByTheNumbersSectionContainer className={ className }>
      <ByTheNumbersInnerContainer>
        <H2M>By The Numbers</H2M>
        <ByTheNumbersCardsContainer>
          { renderEmployeeStatistics() }
        </ByTheNumbersCardsContainer>
        <KarmaWalletEmployeeBenefitContainer>
          <BenefitTitleContainer>
            Here's what happens if one employee spends $500 per month:
          </BenefitTitleContainer>
          <p className='benefits-subheader'>In one year, Karma Wallet will help them:</p>
          <BenefitCardsContainer>
            { renderEmployeeBenefits() }
          </BenefitCardsContainer>
          <ButtonLink
            onClick={ onContactUsClick }
            href='/contact-us'
            kind={ ButtonKind.Quinary }         
          >
            Contact Us
          </ButtonLink>
        </KarmaWalletEmployeeBenefitContainer>
      </ByTheNumbersInnerContainer>
    </ByTheNumbersSectionContainer>
  );
};

export const ByTheNumbersSection = withTheme(ByTheNumbersSectionBase);
