import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { ButtonKind } from '../Button/styles';
import { XIcon } from '../svgs/icons/XIcon';
import { CloseButtonContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  label: string;
  withBorder?: boolean;
  onClick(): void;
}

const CloseButtonBase: React.FC<IProps> = ({
  className = '',
  withBorder = false, 
  onClick,
  theme,
  label,
}) => (
  <CloseButtonContainer
    className={ `${ withBorder && 'with-border' } ${className}` }
    kind={ ButtonKind.Blank }
    label={ label }
    onClick={ onClick }
  >
    <XIcon stroke={ theme.colors.darkGray1 } />
  </CloseButtonContainer>
);

export const CloseButton = withTheme(CloseButtonBase);
