import styled from 'styled-components';
import { FlexCol, FlexRow, SecondaryFont } from '../../../../styles/styles';
import { Subcategories } from '../../../../models/unsdgs';

interface IScoreProps {
  subcategoryName: Subcategories,
}

export const SubcategoryItemContainer = styled.div`
  ${FlexCol}
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  @media( ${({ theme }) => theme.breakpoints.xsMin} ) {
    ${FlexRow}
  }
`;

export const SubcategoryNameContainer = styled.div`
  ${FlexRow}
  align-items: center;
  gap: 15px;

  svg {
    height: 36px;
    width: 36px;
  }
`;

export const SubcategoryScoreContainer = styled.div`
  ${FlexRow}
  align-items: center;
`;

export const Score = styled.p<IScoreProps>`
  ${SecondaryFont}
  ${FlexRow}
  background-color: ${ ( { subcategoryName, theme } ) => {
    switch (subcategoryName) {
      case Subcategories.ClimateAction:
        return theme.colors.blue;
      case Subcategories.Sustainability:
        return theme.colors.gold;
      case Subcategories.CommunityWelfare:
        return theme.colors.secondary;
      case Subcategories.DiversityInclusion:
        return theme.colors.primary; 
      default:
        return theme.colors.grey1;
    }
  }};
  color: ${ ( { subcategoryName, theme } ) => {
    switch (subcategoryName) {
      case Subcategories.DiversityInclusion:
        return theme.colors.white;
      default:
        return theme.colors.black;
    }
  }};
  border-radius: 7px;
  font-size: 36px;
  justify-content: center;
  height: 57px;
  line-height: 32px;
  margin: 0 0 0 10px;
  max-width: 85px;
  min-width: 85px;
  padding: 16px;
  text-align: center;
`;

export const IconContainer = styled.div`
  height: 36px;
  width: 36px;
`;
