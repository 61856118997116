import styled from 'styled-components';
import { AbsoluteCenter, FlexHorizontalCenter, PrimaryFont, ScreenReaderOnly } from '../../styles/styles';

export const RadioButtonContainer = styled.label`
  ${ FlexHorizontalCenter }
  alignItems: center;
  cursor: pointer;
  display: flex;
  position: relative;
  outline: none;

  input:focus:not(:active):not(.disabled) + .outer {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }

  &.disabled {
    .outer {
      background: ${({ theme }) => theme.colors.lightGray2};

      .inner {
        background: ${({ theme }) => theme.colors.disabled};
      }
    }

    .label {
      color: ${({ theme }) => theme.colors.disabled};
    }
  }

  input {
    &:focus {
      outline: none;
    }

    border: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
  }

  .outer {
    position: relative;
    width: 26px;
    height: 26px;
    border: 2px solid ${({ theme }) => theme.colors.disabled};
    border-radius: 50%;
    background: #fff;
    transition: .3s ease-in-out;

    &.checked {
      border: 2px solid ${({ theme }) => theme.colors.darkGray3};
      box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.darkGray3};
    }

    .inner {
      ${AbsoluteCenter}
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.primary};
    }
  }

  .label {
    ${ PrimaryFont }
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkGray1};

    &.sr-only {
      ${ScreenReaderOnly}
    }
  }
`;
