import React, { createContext, useEffect, useState } from 'react';

interface IApp {
  isDesktop: boolean;
  isLargeDesktop: boolean;
  lastWindowWidth: number;
  windowWidth: number;
  windowVisualViewportHeight: number;
}

export const AppDataContext = createContext<IApp>(null);

export const useAppData = () => React.useContext(AppDataContext);

export const AppDataStore: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [_appContext, setAppContext] = useState({
    isDesktop: window.innerWidth > 991,
    isLargeDesktop: window.innerWidth > 1199,
    windowWidth: window.innerWidth,
    lastWindowWidth: window.innerWidth,
    windowVisualViewportHeight: window.visualViewport.height,
  });

  const onWindowResize = () => {
    if (_appContext.windowWidth !== window.innerWidth) {
      const lastWindowWidth = _appContext.windowWidth;
      setAppContext({
        lastWindowWidth,
        isDesktop: window.innerWidth > 991,
        isLargeDesktop: window.innerWidth > 1199,
        windowWidth: window.innerWidth,
        windowVisualViewportHeight: window.visualViewport.height,
      });
    }
  };

  useEffect(() => {
    window.removeEventListener('resize', onWindowResize);
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  return (
    <AppDataContext.Provider value={ _appContext }>
      { children }
    </AppDataContext.Provider>
  );
};
