import styled from 'styled-components';
import { Button } from '../Button';

export const CloseButtonContainer = styled(Button)`
  &:hover {
    cursor: pointer;
  }

  &.with-border {
    border: solid 2px ${({ theme }) => theme.colors.darkGray1};
    border-radius: 50%;
  }

  .x-icon {
    width: 50%;
    height: 50%;
  }
`;
