import { action, computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';

type PrivateFields = '_isOpen' |
'_mode';

export enum SignInMode {
  CREATE_ACCOUNT = 'create-account',
  RESET_PASSWORD = 'reset-password',
  CREATE_PASSWORD = 'create-password',
  SIGN_IN = 'sign-in',
  ACCOUNT_COMPLETION = 'account-completion',
  ACCOUNT_COMPLETION_SUCCESS = 'account-completion-success',
  CHANGE_EMAIL = 'change-email',
  CHANGE_EMAIL_COMPLETION = 'change-email-completion',
  MEMBERSHIP = 'membership',
}

export class SignInModel extends BaseModel {
  private _isOpen = false;
  private _mode: SignInMode = null;

  constructor () {
    super();
    makeObservable<SignInModel, PrivateFields>(this, {
      _isOpen: observable,
      _mode: observable,
      isOpen: computed,
      mode: computed,
      close: action.bound,
      open: action.bound,
      setMode: action.bound,
    });
  }

  get isOpen() { return this._isOpen; }
  get mode() { return this._mode; }

  close = () => {
    this._isOpen = false;
    this._mode = null;
  };

  open = (mode: SignInMode) => {
    this._mode = mode;
    this._isOpen = true;
  };

  setMode = (mode: SignInMode) => {
    this._mode = mode;
  };
}
