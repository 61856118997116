import React, { useMemo } from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { UnsdgFailedIcon } from '../svgs/icons/UnsdgFailedIcon';
import { UnsdgNeutralIcon } from '../svgs/icons/UnsdgNeutralIcon';
import { UnsdgNoDataIcon } from '../svgs/icons/UnsdgNoDataIcon';
import { UnsdgPartialIcon } from '../svgs/icons/UnsdgPartialIcon';
import { UnsdgPassedIcon } from '../svgs/icons/UnsdgPassedIcon';

interface IProps extends IThemeProps {
  classname?: string,
  type: UnsdgIconType,
}

export enum UnsdgIconType {
  Passed = 'Passed',
  Partial = 'Partial',
  Failed = 'Failed',
  NoData = 'No Data',
  Neutral = 'Neutral'
}

const UNSDGLegendIconBase: React.FC<IProps> = ({
  classname = '',
  type,
  theme, 
}) => {
  const icon = useMemo(() => {
    if (!type) return;
    switch (type) {
      case UnsdgIconType.Passed:
        return <UnsdgPassedIcon fill={ theme.colors.green } className={ classname } />;
      case UnsdgIconType.Partial:
        return <UnsdgPartialIcon fill={ theme.colors.green } className={ classname } />;
      case UnsdgIconType.Failed:
        return <UnsdgFailedIcon fill={ theme.colors.red } className={ classname } />;
      case UnsdgIconType.Neutral:
        return <UnsdgNeutralIcon fill={ theme.colors.tertiary } className={ classname } />;
      case UnsdgIconType.NoData:
        return <UnsdgNoDataIcon fill={ theme.colors.lightGray1 } className={ classname } />;
      default: return null;
    }
  }, [type]);

  return <>{ icon }</>;
};

export const UNSDGLegendIcon = withTheme(UNSDGLegendIconBase);
