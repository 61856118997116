import dayjs from 'dayjs';

export const setLocalStorageItemWithExpiry = (key: string, value: any, expiration: dayjs.Dayjs) => {
  const storageItem = {
    value,
    expiry: expiration.format(),
  };

  localStorage.setItem(key, JSON.stringify(storageItem));
};

export const getLocalStorageItemWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  if (!item.expiry) return null;

  const now = dayjs();
  const exp = dayjs(item.expiry);
  const diff = exp.diff(now);

  if (diff < 0 || diff === 0) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const getLocalStorageWithExpirationDayjs = (key: string) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  } else {
    const parsedItem = JSON.parse(itemStr);
    const now = dayjs();
    const difference = now.diff(parsedItem.expiry);
    // if expired removed and return null
    if (difference > 0) {
      localStorage.removeItem(key);
      return null;
    } else {
      return parsedItem.key;
    }
  }
};

export const setLocalStorageWithExpirationDayjs = (key: string, value: string | number | Date, expirationInDays: number) => {
  const expirationDate = dayjs().add(expirationInDays, 'day');

  const item = {
    key: value,
    expiry: expirationDate,
  }; 

  localStorage.setItem(key, JSON.stringify(item));
};
