import styled from 'styled-components';
import { BasicContainerBoxShadow, FlexRow } from '../../../styles/styles';

export const CompanyComparisonItemContainer = styled.div`
  ${FlexRow}
  ${BasicContainerBoxShadow}
  align-items: center;
  gap: 4px;
  height: 82px;
  padding: 16px;
  width: 100%;

  .default-company-logo-container {
    min-width: unset;

    svg {
      max-height: 36px;
      width: unset;
    }
  }

  .company-logo {
    display: none;
  }

  .company-name {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 600;
    padding-right: 10px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 18px;
    }
  }

  .rating {
    height: 24px;
    margin-bottom: 0;
    margin-left: auto;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    .company-logo {
      display: flex;
      height: 36px;
      margin-right: 10px;
      max-width: 20%;

      img {
        width: unset;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    max-width: 500px;
    width: 70%;
  }
`;
