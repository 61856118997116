import styled from 'styled-components';
import { FlexColCenter, FlexRow } from '../../../styles/styles';

export const AccountHubUserMenuContainer = styled.div`
  ${FlexRow}
  justify-content: space-between;
  width: fit-content;
  min-width: 50px;

  .icon-container {
    ${FlexColCenter}
    margin-left: 10px;
    max-width: 18px;
    width: 18px;

    .chevron-icon {
      width: 20px;
    }

    svg {
      max-width: 18px;
    }
  }
`;
