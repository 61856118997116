import React, { useMemo } from 'react';
import { IndustryReportGauge } from '../svgs/graphics/IndustryReportGauge';
import { GaugeNeedle } from '../svgs/graphics/IndustryReportGauge/GaugeNeedle';
import { IndustryReportGaugeContainer } from './styles';

interface IProps {
  classname?: string;
  combinedScore: number;
}

export const IndustryReportScoreGauge: React.FC<IProps> = ({ classname = '', combinedScore = 0 }) => {
  const needleRotation = useMemo(() => combinedScore * 5.625, [combinedScore]);

  return (
    <IndustryReportGaugeContainer className={ classname } needleRotation={ needleRotation }>
      <IndustryReportGauge className='gauge' />
      <GaugeNeedle className='needle' />
    </IndustryReportGaugeContainer>
  );
};
