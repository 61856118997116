import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const EmojiSadIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }emoji-sad-icon` }
    id={ id }
    width={ 25 }
    height={ 26 }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M2.40869 12.9878C2.40869 7.32687 6.99777 2.73779 12.6587 2.73779C18.3196 2.73779 22.9087 7.32687 22.9087 12.9878C22.9087 18.6487 18.3196 23.2378 12.6587 23.2378C6.99777 23.2378 2.40869 18.6487 2.40869 12.9878ZM12.6587 0.737793C5.8932 0.737793 0.408691 6.2223 0.408691 12.9878C0.408691 19.7533 5.8932 25.2378 12.6587 25.2378C19.4242 25.2378 24.9087 19.7533 24.9087 12.9878C24.9087 6.2223 19.4242 0.737793 12.6587 0.737793ZM9.66549 17.3914C9.30449 17.8094 8.67302 17.8556 8.25505 17.4946C7.83708 17.1336 7.79089 16.5021 8.15189 16.0841C9.11479 14.9693 10.584 14.1798 12.6587 14.1798C14.7334 14.1798 16.2026 14.9693 17.1655 16.0841C17.5265 16.5021 17.4803 17.1336 17.0623 17.4946C16.6443 17.8556 16.0128 17.8094 15.6519 17.3914C15.0796 16.7288 14.1687 16.1798 12.6587 16.1798C11.1486 16.1798 10.2378 16.7288 9.66549 17.3914ZM7.90869 10.4878C7.90869 9.9355 8.35641 9.48779 8.90869 9.48779H8.91985C9.47213 9.48779 9.91985 9.9355 9.91985 10.4878C9.91985 11.0401 9.47213 11.4878 8.91985 11.4878H8.90869C8.35641 11.4878 7.90869 11.0401 7.90869 10.4878ZM16.4087 9.48779C15.8564 9.48779 15.4087 9.9355 15.4087 10.4878C15.4087 11.0401 15.8564 11.4878 16.4087 11.4878H16.4198C16.9721 11.4878 17.4198 11.0401 17.4198 10.4878C17.4198 9.9355 16.9721 9.48779 16.4198 9.48779H16.4087Z' fill={ fill } />
  </SvgIcon>
);
