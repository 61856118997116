import React, { createContext } from 'react';
import { SocketClient } from '../models/socket-client';

// declare const __SOME_CUSTOM_ENV_VAR__: string;

export const SocketContext = createContext<SocketClient>(null);

export const useSocket = () => React.useContext(SocketContext);

export const SocketStore: React.FC<React.PropsWithChildren> = ({ children }) => (
  <SocketContext.Provider value={ new SocketClient() }>
    { children }
  </SocketContext.Provider>
);
