import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const PencilIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }pencil-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    fill='none'
    stroke={ stroke }
  >
    <path d='M11.6668 5L13.4597 3.20711C13.8502 2.81658 14.4834 2.81658 14.8739 3.20711L16.7931 5.12623C17.1836 5.51675 17.1836 6.14992 16.7931 6.54044L15.0002 8.33333M11.6668 5L3.62639 13.0404C3.43885 13.228 3.3335 13.4823 3.3335 13.7475V15.6667C3.3335 16.219 3.78121 16.6667 4.3335 16.6667H6.25262C6.51783 16.6667 6.77219 16.5613 6.95972 16.3738L15.0002 8.33333M11.6668 5L15.0002 8.33333' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
