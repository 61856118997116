import React from 'react';
import { H2, H2M } from '../../styles/components/header';
import { CTAs, ICta } from '../CTAs';
import { ActionSectionContainer } from './styles';

interface IProps {
  className?: string;
  header: string | JSX.Element;
  subheader?: string;
  ctas: ICta[];
  isMarketing: boolean;
}

export const ActionSection: React.FC<IProps> = ({
  className = '',
  header, 
  ctas,
  subheader,
  isMarketing,
}) => {
  if (!ctas) return null;

  return (
    <ActionSectionContainer className={ className }>
      {
        !!isMarketing
          ? <H2M className='header'>{ header }</H2M>
          : <H2 className='header'>{ header }</H2>
      }
      {
        !!subheader
          ? <div className='subheader'>{ subheader }</div>
          : null
      }
      <CTAs className='action-ctas' ctas={ ctas } />
    </ActionSectionContainer>
  );
};
