import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const PeopleGraphic: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }people-graphic` }
    id={ id }
    width={ 36 }
    height={ 36 }
    fill='none'
  >
    <path d='M30.4297 5.92176C29.8209 5.69184 29.1433 6.01253 28.9323 6.63044L28.9283 6.6367L29.8048 4.06881C30.007 3.47566 29.7032 2.82857 29.1189 2.60793L29.0327 2.57538C28.4179 2.34327 27.7344 2.6722 27.5294 3.29889L25.7382 8.77265C25.6322 9.09651 25.2407 9.22272 24.9663 9.01698C24.8152 8.90371 24.7462 8.71106 24.7747 8.52438C24.9439 7.4152 25.5078 3.72991 25.7966 1.84202C25.8929 1.21293 25.4663 0.623571 24.8408 0.521407L24.734 0.50397C24.1009 0.40061 23.5053 0.835423 23.4078 1.4721L22.3845 8.15284C22.347 8.39781 22.1372 8.57866 21.8904 8.57866H21.6792C21.4031 8.57866 21.1793 8.3538 21.1793 8.07649V1.73957C21.1793 1.09917 20.6625 0.580048 20.0249 0.580048H19.9167C19.2792 0.580048 18.7624 1.09917 18.7624 1.73957V9.43991C18.7624 9.55382 18.6701 9.64621 18.5561 9.64621C18.1929 10.1172 17.5007 10.1611 17.0813 9.73987L15.4435 8.09477C14.6263 7.27394 13.2997 7.27675 12.4861 8.10327C12.1328 8.46208 12.1393 9.04061 12.4945 9.39745L15.7787 12.6963C16.3695 13.2898 16.2425 14.5745 16.3986 15.399L17.089 15.7391C17.3165 16.9409 17.4431 18.5457 18.401 19.3013C18.8744 19.9699 19.6552 20.2437 19.6552 21.0642V34.7864C19.6552 35.1865 19.9794 35.5108 20.3795 35.5108H27.5079V19.4719C27.5079 19.3116 27.5614 19.156 27.6596 19.0293C28.4212 18.0467 28.8359 16.8344 28.8335 15.5843C28.8312 14.3508 29.0685 13.1286 29.482 11.9665L31.1089 7.39361C31.3204 6.79905 31.0181 6.14395 30.4297 5.92176Z' fill='#FFE4C5' />
    <path d='M19.6543 21.8296V25.2679L20.6004 26.2181C21.1912 26.8117 21.5906 27.57 21.7467 28.3945L21.9106 29.261C22.1381 30.4628 22.7911 31.5412 23.7489 32.2969L24.4834 32.8762C24.8424 33.1594 25.052 33.5925 25.052 34.0509V35.5106H27.507V21.8296H19.6543Z' fill='#FFD7B3' />
    <path d='M31.1094 7.39371L29.4825 11.9665C29.069 13.1283 28.8321 14.3503 28.8342 15.5839C28.8364 16.8341 28.4221 18.0466 27.6601 19.0288C27.5623 19.1556 27.5087 19.3113 27.5087 19.4714V35.5105H25.2676V19.935C25.2676 19.5004 25.4023 19.0781 25.6442 18.7174C26.26 17.7982 26.5931 16.7117 26.5931 15.5948C26.5931 14.9972 26.6438 14.4018 26.7452 13.815C26.8466 13.229 26.9973 12.651 27.1979 12.0882L28.9291 6.6368L28.9305 6.63392C28.9312 6.63174 28.9327 6.62885 28.9342 6.6252C28.9363 6.62084 28.9378 6.61578 28.9399 6.61142C28.9501 6.58751 28.966 6.54912 28.9863 6.49983C28.987 6.49913 28.987 6.49835 28.987 6.49765C29.2448 5.96742 29.8671 5.70881 30.4306 5.92179C31.0189 6.14349 31.3209 6.79908 31.1094 7.39371Z' fill='#FFD7B3' />
    <path d='M3.55259 21.6201C4.14343 22.2136 4.54287 22.9719 4.6989 23.7964L4.86287 24.6629C5.09033 25.8647 5.74339 26.9432 6.70119 27.6988L7.43567 28.2782C7.79462 28.5613 8.00422 28.9944 8.00422 29.4528V34.7866C8.00422 35.1867 8.3285 35.511 8.72858 35.511H14.5574C14.9575 35.511 15.2818 35.1867 15.2818 34.7866V28.3958C15.2818 28.2354 15.3354 28.0798 15.4336 27.9531C16.193 26.9732 16.6075 25.7651 16.6075 24.5188C16.6075 23.3238 16.8122 22.1377 17.2125 21.0124L18.8828 16.3174C19.0943 15.7229 18.7921 15.0678 18.2037 14.8456C17.5949 14.6157 16.9173 14.9364 16.7062 15.5543L16.7052 15.5559L17.5787 12.9926C17.7809 12.3994 17.4771 11.7523 16.8928 11.5317L16.8066 11.4991C16.1918 11.267 15.5084 11.596 15.3033 12.2226L13.5121 17.6964C13.4061 18.0203 13.0147 18.1465 12.7403 17.9407C12.5892 17.8275 12.5202 17.6348 12.5487 17.4481C12.7178 16.339 13.2817 12.6537 13.5706 10.7658C13.6668 10.1368 13.2402 9.5474 12.6148 9.44523L12.5079 9.4278C11.8749 9.32444 11.2794 9.75925 11.1818 10.3959L10.1585 17.0767C10.121 17.3216 9.91116 17.5025 9.66437 17.5025H9.45315C9.17703 17.5025 8.95322 17.2776 8.95322 17.0003V10.6634C8.95322 10.023 8.43643 9.50388 7.7989 9.50388H7.69069C7.05317 9.50388 6.53637 10.023 6.53637 10.6634V17.9374C6.53637 18.1542 6.4759 18.3694 6.34885 18.5449C5.99117 19.0393 5.282 19.0923 4.85534 18.6637L3.21755 17.0186C2.40319 16.2006 1.08287 16.2006 0.268576 17.0186C-0.0895254 17.3783 -0.0895254 17.9616 0.268576 18.3213L3.55259 21.6201Z' fill='#C59071' />
    <path d='M18.8829 16.3173L17.2125 21.0125C16.8119 22.1374 16.6077 23.3239 16.6077 24.5183V24.519C16.6077 25.7649 16.1933 26.9731 15.4335 27.9532C15.3357 28.0799 15.2822 28.2357 15.2822 28.3957V34.7866C15.2822 35.1865 14.9577 35.511 14.5578 35.511H13.041V28.8594C13.041 28.4248 13.1757 28.0025 13.4177 27.6418C14.0334 26.7226 14.3665 25.636 14.3665 24.5191C14.3665 23.9215 14.4172 23.3261 14.5186 22.7394C14.62 22.1534 14.7707 21.5746 14.9714 21.0126L16.7055 15.5561L16.7062 15.5546C16.7062 15.5539 16.7062 15.5539 16.7069 15.5531C16.7076 15.551 16.7084 15.5481 16.7098 15.5459C16.7177 15.527 16.7359 15.4843 16.7583 15.4271C16.759 15.4257 16.7598 15.4243 16.7598 15.4228C17.0169 14.8918 17.6399 14.6325 18.2034 14.8455C18.7923 15.0678 19.0944 15.7226 18.8829 16.3173Z' fill='#9D6849' />
    <path d='M20.6028 23.648C21.1936 24.2415 21.5931 24.9998 21.7491 25.8243L21.913 26.6908C22.1405 27.8927 22.7936 28.971 23.7514 29.7267L24.4858 30.3061C24.8448 30.5892 25.0544 31.0223 25.0544 31.4807V34.7864C25.0544 35.1865 25.3787 35.5108 25.7788 35.5108H31.6076C32.0077 35.5108 32.3319 35.1865 32.3319 34.7864V30.4236C32.3319 30.2632 32.3855 30.1076 32.4838 29.9809C33.2432 29.0011 33.6577 27.7929 33.6577 26.5466C33.6577 25.3516 33.8623 24.1655 34.2627 23.0402L35.933 18.3453C36.1445 17.7507 35.8423 17.0956 35.2539 16.8734C34.6451 16.6435 33.9675 16.9642 33.7564 17.5821L33.7554 17.5837L34.6289 15.0204C34.8311 14.4272 34.5272 13.7802 33.943 13.5595L33.8567 13.527C33.242 13.2948 32.5585 13.6238 32.3535 14.2505L30.5623 19.7242C30.4563 20.0481 30.0649 20.1743 29.7904 19.9686C29.6393 19.8553 29.5704 19.6626 29.5988 19.476C29.768 18.3668 30.3319 14.6815 30.6208 12.7937C30.717 12.1646 30.2904 11.5752 29.665 11.4731L29.5581 11.4556C28.9251 11.3523 28.3295 11.7871 28.232 12.4238L27.2087 19.1045C27.1712 19.3495 26.9613 19.5303 26.7145 19.5303H26.5033C26.2272 19.5303 26.0034 19.3055 26.0034 19.0281V12.6912C26.0034 12.0508 25.4866 11.5316 24.8491 11.5316H24.7409C24.1033 11.5316 23.5865 12.0508 23.5865 12.6912V19.9652C23.5865 20.182 23.5261 20.3971 23.399 20.5727C23.0413 21.0671 22.3322 21.1201 21.9055 20.6915L20.2677 19.0464C19.4506 18.2255 18.1239 18.2284 17.3103 19.0549C16.9571 19.4137 16.9635 19.9922 17.3188 20.349L20.6028 23.648Z' fill='#FFCEC0' />
    <path d='M35.9317 18.3453L34.2613 23.0405C33.8608 24.1654 33.6565 25.3519 33.6565 26.5463C33.6565 27.7929 33.2415 29.0011 32.4824 29.9812C32.3839 30.1079 32.3303 30.2637 32.3303 30.4237V34.7865C32.3303 35.1863 32.0065 35.5108 31.6059 35.5108H30.0898V30.8873C30.0898 30.4527 30.2239 30.0304 30.4658 29.669C31.0822 28.7505 31.4147 27.664 31.4147 26.5463C31.4147 25.9494 31.4661 25.354 31.5675 24.7673C31.6689 24.1814 31.8196 23.6026 32.0203 23.0405L33.7543 17.5841L33.755 17.5826V17.5819C33.755 17.5812 33.755 17.5812 33.7557 17.5804V17.5789C33.7644 17.5623 33.8079 17.4667 33.8644 17.3493C34.0752 17.0053 34.451 16.7988 34.8466 16.7988C34.9813 16.7988 35.1182 16.8227 35.2522 16.8734C35.8411 17.0958 36.1432 17.7506 35.9317 18.3453Z' fill='#FDB0A0' />
  </SvgIcon>
);
