import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const DollarIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}dollar-icon` }
    id={ id }
    height={ 13 }
    width={ 7.39 }
    fill={ fill }
  >
    <g>
      <path d='M2.93262 12.1207C2.52095 12.0557 2.14178 11.9365 1.79512 11.7632C1.44845 11.579 1.15053 11.3515 0.901367 11.0807C0.652201 10.799 0.451784 10.474 0.300117 10.1057C0.159284 9.72654 0.0888672 9.31487 0.0888672 8.8707H2.03887C2.03887 9.3257 2.19053 9.69945 2.49387 9.99195C2.7972 10.2736 3.21428 10.4145 3.74512 10.4145C4.31928 10.4145 4.7472 10.2953 5.02887 10.057C5.31053 9.80779 5.45137 9.5207 5.45137 9.1957C5.45137 8.88154 5.32137 8.63779 5.06137 8.46445C4.80137 8.29112 4.47637 8.13945 4.08637 8.00945C3.69637 7.86862 3.26845 7.7332 2.80262 7.6032C2.34762 7.4732 1.92512 7.29445 1.53512 7.06695C1.14512 6.83945 0.820117 6.54695 0.560117 6.18945C0.300117 5.82112 0.170117 5.33904 0.170117 4.7432C0.170117 4.37487 0.235117 4.03362 0.365117 3.71945C0.505951 3.39445 0.695534 3.10737 0.933867 2.8582C1.18303 2.5982 1.47553 2.38695 1.81137 2.22445C2.1472 2.06195 2.52095 1.9482 2.93262 1.8832V0.501953H4.72012V1.8832C5.06678 1.9807 5.38637 2.12154 5.67887 2.3057C5.9822 2.48987 6.2422 2.71737 6.45887 2.9882C6.67553 3.2482 6.84345 3.55154 6.96262 3.8982C7.09262 4.24487 7.15762 4.62945 7.15762 5.05195H5.28887C5.28887 4.59695 5.15345 4.23945 4.88262 3.97945C4.61178 3.71945 4.23262 3.58945 3.74512 3.58945C3.25762 3.58945 2.87845 3.70862 2.60762 3.94695C2.33678 4.18529 2.20137 4.44529 2.20137 4.72695C2.20137 5.03029 2.33137 5.2632 2.59137 5.4257C2.85137 5.5882 3.17637 5.73445 3.56637 5.86445C3.95637 5.99445 4.37887 6.12987 4.83387 6.2707C5.2997 6.41154 5.72762 6.60112 6.11762 6.83945C6.50762 7.07779 6.83262 7.39195 7.09262 7.78195C7.35262 8.16112 7.48262 8.65945 7.48262 9.27695C7.48262 9.60195 7.4122 9.92154 7.27137 10.2357C7.13053 10.539 6.93553 10.8207 6.68637 11.0807C6.4372 11.3299 6.1447 11.5465 5.80887 11.7307C5.47303 11.9149 5.11012 12.0449 4.72012 12.1207V13.502H2.93262V12.1207Z' fill={ fill } />
    </g>
  </SvgIcon>
);
