import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
  ariaHidden?: boolean;
}

export const LinkedAccountsIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
  ariaHidden,
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}linked-accounts-icon` }
    id={ id }
    height={ 20 }
    width={ 20 }
    fill={ fill }
    aria-hidden={ ariaHidden }
  >
    <path d='M10.0002 0L18.2627 3.75H19.3752C19.541 3.75 19.7 3.81585 19.8172 3.93306C19.9344 4.05027 20.0002 4.20924 20.0002 4.375V6.875C20.0002 7.04076 19.9344 7.19973 19.8172 7.31694C19.7 7.43415 19.541 7.5 19.3752 7.5H18.7502V16.25C18.8898 16.2501 19.0253 16.2969 19.1352 16.383C19.2451 16.4691 19.323 16.5895 19.3565 16.725L19.9815 19.225C20.0047 19.3171 20.0066 19.4132 19.987 19.5062C19.9674 19.5991 19.9268 19.6863 19.8682 19.7611C19.8097 19.8358 19.7349 19.8963 19.6494 19.9377C19.564 19.9791 19.4702 20.0004 19.3752 20H0.62522C0.530259 20.0004 0.436461 19.9791 0.351006 19.9377C0.265551 19.8963 0.190705 19.8358 0.132195 19.7611C0.0736849 19.6863 0.0330629 19.5991 0.013438 19.5062C-0.00618691 19.4132 -0.00429456 19.3171 0.0189702 19.225L0.64397 16.725C0.677668 16.5896 0.755615 16.4693 0.865444 16.3833C0.975274 16.2972 1.11069 16.2503 1.25022 16.25V7.5H0.62522C0.45946 7.5 0.300489 7.43415 0.183279 7.31694C0.0660683 7.19973 0.000220232 7.04076 0.000220232 6.875V4.375C0.000220232 4.20924 0.0660683 4.05027 0.183279 3.93306C0.300489 3.81585 0.45946 3.75 0.62522 3.75H1.73772L10.0002 0ZM4.72147 3.75H15.2802L10.0002 1.25L4.72147 3.75ZM2.50022 7.5V16.25H3.75022V7.5H2.50022ZM5.00022 7.5V16.25H8.12522V7.5H5.00022ZM9.37522 7.5V16.25H10.6252V7.5H9.37522ZM11.8752 7.5V16.25H15.0002V7.5H11.8752ZM16.2502 7.5V16.25H17.5002V7.5H16.2502ZM18.7502 6.25V5H1.25022V6.25H18.7502ZM18.2627 17.5H1.73772L1.42522 18.75H18.5752L18.2627 17.5Z' fill={ fill } />
  </SvgIcon>
);
