import * as React from 'react';
import { useRef } from 'react';
import { DesktopNavItemsContainer, NavMenuItemContainer, SubMenuContainer, SubMenuLinkListContainer } from './styles';
import { useBanners } from '../../../contexts/banners';
import { useHeader } from '../../../contexts/header';
import { SubMenuSelected } from '../../../models/header';
import { observer } from 'mobx-react';
import { IHeaderMenu, headerMenus } from '../constants';
import NavigationSubMenu from '../NavigationSubMenu';
import { useLocation } from 'react-router';

interface IProps {
  className?: string;
  setMobileNavOpen?: (isOpen: boolean) => void;
}

const DesktopNavigationMenuBase: React.FunctionComponent<IProps> = ({
  className='',
  setMobileNavOpen,
}) => {
  const appHeader = useHeader();
  const subMenuRef = useRef<any>({});
  const location = useLocation();
  const bannersModel = useBanners();
  
  const onNavMenuItemFocus = (headerMenuId: SubMenuSelected) => {
    appHeader.setSubMenuSelected(headerMenuId as SubMenuSelected);
  };

  const onEscapeKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      appHeader.setSubMenuSelected(SubMenuSelected.None);
    }
  };

  const renderSubmenu = (headerMenu: IHeaderMenu) => 
    appHeader.subMenuSelected !== SubMenuSelected.None && (
      <SubMenuContainer
        ref={ el => subMenuRef.current = el }
        className={ `${ appHeader?.subMenuSelected === headerMenu?.id ? 'active' : 'hidden'}` }
        isBannerPresent={ bannersModel.showBanner }
      >
        <SubMenuLinkListContainer role='menu' aria-label={ `${headerMenu.text} menu` }>
          <NavigationSubMenu
            subMenuItems={ headerMenu.subMenu }
            setMobileNavOpen={ setMobileNavOpen }
          />
        </SubMenuLinkListContainer>
      </SubMenuContainer>);

  const menus = headerMenus.map(( headerMenu ) => (
    <NavMenuItemContainer
      id={ headerMenu.id }
      key={ headerMenu.id }
      className={ `nav-menu ${appHeader.subMenuSelected === headerMenu.id ? 'active' : '' }` }
      onMouseEnter={ () => appHeader.setSubMenuSelected(headerMenu.id as SubMenuSelected) }
      onMouseLeave={ () => appHeader.setSubMenuSelected(SubMenuSelected.None) }
      onFocus={ () => onNavMenuItemFocus(headerMenu.id as SubMenuSelected) }
      onKeyDown={ onEscapeKeyDown }
      tabIndex={ 0 }
      aria-haspopup='true'
      aria-expanded={ appHeader.subMenuSelected === headerMenu.id ? 'true' : 'false' }
    >
      <li role='menuitem' >
        { headerMenu.text }
        { renderSubmenu(headerMenu) }
      </li>
    </NavMenuItemContainer>
  ));

  if (location.pathname.includes('/account')) {
    return null;
  }

  return (
    <DesktopNavItemsContainer className={ className }>
      { menus }
    </DesktopNavItemsContainer>
  );
};

export const DesktopNavigationMenu = observer(DesktopNavigationMenuBase);
