import styled from 'styled-components';

export const ValueCircleContainer = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  height: 16px;
  position: relative;
  width: 16px;

  &.filled {
    background: ${({ theme }) => theme.colors.green};
    border: solid 1px ${({ theme }) => theme.colors.green};
  }

  & > svg {
    left: 2px;
    position: absolute;
    stroke-width: 2px;
    width: 10px;
  }
`;
