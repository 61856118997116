import React, { FC, useEffect } from 'react';
// import ReactGA from 'react-ga4';
import ReactGA from 'react-ga4';
import { AnalyticsStore } from '../../contexts/analytics-store';
import { AppDataStore } from '../../contexts/app';
import { BadgesStore } from '../../contexts/badges';
import { ErrorMessagesStore } from '../../contexts/error-messages-store';
import { SocketStore } from '../../contexts/socket-client';
import { ThemeStore } from '../../contexts/theme-store';
import { ToasterStore } from '../../contexts/toaster-store';
import { UserSessionStore } from '../../contexts/user';
import { BannersStore } from '../../contexts/banners';
import { GATrackingId } from '../../models/analytics';
import { GlobalStyles } from '../../styles/styles';
import { PromosStore } from '../../contexts/promos';
import { GlobalErrorModal } from '../modals/GlobalErrorModal';
import { MainRouter } from '../MainRouter';
import { ThemeProvider } from '../ThemeProvider';
import { Toaster } from '../Toaster';
import { GroupStore } from '../../contexts/group';
import { BrowserRouter } from 'react-router-dom';
import { Hooks } from '../Hooks';
import { HeaderStore } from '../../contexts/header';
import { SignInStore } from '../../contexts/sign-in';
import { KarmaCardLegalStore } from '../../contexts/karma-card-legal';
import { RecoilRoot } from 'recoil';
import { InitializeApp } from '../InitializeApp';
import RecoilNexus from 'recoil-nexus';
import { AccountHubMobileNavStore } from '../../contexts/accountHubMobileNav';

interface IProps {
  className?: 'string';
}

const AppBase: FC<IProps> = () => {
  const initializeGA = async () => {
    try {
      await ReactGA.initialize(GATrackingId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing Google Analytics', error);
    }
  };

  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <AppDataStore>
      <ErrorMessagesStore>
        <ToasterStore>
          <BannersStore>
            <ThemeStore>
              <ThemeProvider>
                <SocketStore>
                  <UserSessionStore app='karma'>
                    <AnalyticsStore>
                      <GroupStore>
                        <HeaderStore>
                          <BadgesStore>
                            <PromosStore>
                              <AccountHubMobileNavStore>
                                <KarmaCardLegalStore>
                                  <RecoilRoot>
                                    <RecoilNexus />
                                    <InitializeApp />
                                    <BrowserRouter>
                                      <Hooks />
                                      <GlobalStyles />
                                      <SignInStore>
                                        <MainRouter />
                                      </SignInStore>
                                      <GlobalErrorModal />
                                      <Toaster />
                                    </BrowserRouter>
                                  </RecoilRoot>
                                </KarmaCardLegalStore>
                              </AccountHubMobileNavStore>
                            </PromosStore>
                          </BadgesStore>
                        </HeaderStore>
                      </GroupStore>
                    </AnalyticsStore>
                  </UserSessionStore>
                </SocketStore>
              </ThemeProvider>
            </ThemeStore>
          </BannersStore>
        </ToasterStore>
      </ErrorMessagesStore>
    </AppDataStore>
  );
};

export const App = AppBase;
