import styled from 'styled-components';
import { FlexCenter, FlexCol, TruncateText } from '../../styles/styles';

export interface IRatingBarProps {
  backgroundColor: string;
  height: number;
}

export interface IRatingDotProps {
  location: string;
  alignment: 'left' | 'right';
}

export const RatingBarContainer = styled.div`
  ${FlexCenter}
  height: 100px;

  .emoji-icon {
    min-width: 25px;
  }
`;

export const BarWrapper = styled.div<IRatingBarProps>`
  background: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 2px 8px 25px 0px rgba(0,0,0,0.05);
  border-radius: 50px;
  height: 12px;
  margin: 0 5px;
  outline: solid 9px ${({ theme }) => theme.colors.white};
  padding: 0 10px;
  position: relative;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    height: ${({ height }) => height}px;
    outline: solid 12px ${({ theme }) => theme.colors.white};
  }
`;

export const BarPointWrapper = styled.div<IRatingDotProps>`
  ${FlexCol}
  left: calc(${({ location }) => location} - 10px);
  position: absolute;
  top: 5px;
  
  .point-text {
    ${TruncateText}
    ${({ alignment }) => alignment === 'left' ? 'left: 0' : 'right: 0'};
    background: white;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    max-width: 160px;
    position: absolute;
    padding: 7px 7px;
    z-index: 1;
  }

  .point:after {
    border-right: solid 1px ${({ theme }) => theme.colors.dark};
    border-left: solid 1px ${({ theme }) => theme.colors.dark};
    content: '';
    display: block;
    height: 35px;
    margin-left: 3px;
    position: absolute;
    z-index: 0;
  }

  &:nth-of-type(odd) {
    flex-direction: column-reverse;
    top: 5px;

    .point-text {
      top: 35px;
    }

    .point:after {
      margin-top: 5px;
    }
  }

  &:nth-of-type(even) {
    .point-text {
      bottom: 35px;
    }

    .point:after {
      margin-top: -30px;
    }
  }

  &:nth-of-type(3) {
    .point-text {
      top: 70px;
    }

    .point:after {
      height: 65px;
    }
  }
`;

export const BarContainer = styled.div`
  border-radius: 50px;
  height: 20px;
`;

export const Point = styled.div`
  background: ${({ theme }) => theme.colors.dark}};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;
