import styled from 'styled-components';
import { _H4, _H5 } from '../../../styles/components/header';

export const DonationOptionContainer = styled.div`
  .amount-text {
    ${_H4}
    width: 30%;
    min-width: 100px;
    border-radius: 20px;
    padding: 10px 20px;
    text-align: center;
    margin: 0 10px;
    background-color: transparent;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      ${_H5}
      padding: 4px;
      width: 25%;
      min-width: 80px;
    }
  }
`;
