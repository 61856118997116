import React, { useMemo } from 'react';
import { IntroContainer, IntroContainerWrapper } from './styles';
import dayjs from 'dayjs';
import { IArticle } from '../../../models/article';

interface Iprops {
  article: IArticle
}

export const IntroSection: React.FC<Iprops> = ({
  article,
}) => {
  const { title, introParagraph } = article;

  const datePublished = useMemo(() => {
    const date = new Date(article?.publishedOn || Date.now() );
    const formatedDate = dayjs(date).format('MMMM D, YYYY');
    return formatedDate;
  }, [article?.publishedOn]);

  return (
    <IntroContainerWrapper>
      <IntroContainer>
        <p className='date-written'>{ datePublished }</p>
        <h1 className='intro-title'>{ title }</h1>
        <>{ introParagraph.split('\n').map((paragraph: string, index: number) => <p className='intro-paragraph' key={ index }>{ paragraph }</p>) }</>
      </IntroContainer>
    </IntroContainerWrapper>
  );
};
