import styled from 'styled-components';
import { MainGroupContainer } from '../../../components/MainGroupContainer';
import { FlexCol } from '../../../styles/styles';

export const NotFoundContainer = styled(MainGroupContainer)``;

export const NotFoundContentContainer = styled.div`
  ${ FlexCol }
  align-items: center;
  width: 80vw;
  max-width: 700px;
  margin: 200px auto 0;

  p {
    padding: 20px 0 30px;
  }
`;
