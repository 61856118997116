export type AppType = 'karma' | 'admin' | 'developer';

declare const __REACT_APP_FRONTEND_URL__: string;
declare const __REACT_APP_DEVELOPER_URL__: string;

export const FRONTEND_URL = `${__REACT_APP_FRONTEND_URL__ || 'https://www.karmawallet.io'}`;
export const DEVELOPER_URL= `${__REACT_APP_DEVELOPER_URL__ || 'https://www.developer.karmawallet.io'}`;

export const PersonaEnvIdEnum = {
  staging: 'env_mWWczse3GEbjjD2fZSL4SwiLy8MR',
  production: 'env_ZmnA9H4U4Uq3iw7w9FeesRMwv5x7',
} as const;
export type PersonaEnvIdEnumValues = (typeof PersonaEnvIdEnum)[keyof typeof PersonaEnvIdEnum];

export const PersonaTemplateIdEnum = {
  // TODO: change this back to itmpl_AFqvVNPPTmMy752PwyeopspV8mSe
  dbVerification: 'itmpl_vgVhpStQRDohjxx9ZxvQ3eSCbsUD', // KW - 1 Data Collection
  KW5: 'itmpl_bbGdQaESkPsAfysa1mypvXtP7vcM',
  none: '',
} as const;
export type PersonaTemplateIdEnumValues = (typeof PersonaTemplateIdEnum)[keyof typeof PersonaTemplateIdEnum];
