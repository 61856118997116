import styled from 'styled-components';
import { Section } from '../../../styles/components/containers';
import { MainGroupContainer } from '../../../components/MainGroupContainer';

export const DashboardContainer = styled(MainGroupContainer)`
  .hero {
    background: ${({ theme }) => theme.colors.light};
  }

  .group-member-data-section {
    padding-bottom: 0;
  }
`;

export const GroupCtaSection = styled(Section)`
  text-align: center;

  p {
    padding: 10px 0 0;

    sub {
      font-size: 10px;
    }
  }

  .cta-section {
    justify-content: center;
  }
`;
