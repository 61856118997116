import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { TextField } from '../../components/TextField';
import { MaxBoundarySection } from '../../styles/components/containers';
import { H1 } from '../../styles/components/header';
import { FlexColCenter } from '../../styles/styles';

export const NewEmployerInfoContainer = styled(MainContainer)``;

export const NewEmployerHeaderText = styled(H1)`
  text-align: center;
  width: 70%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    text-align: left;
    width: 100%;
  }
`;

export const TopSection = styled(MaxBoundarySection)`
  ${FlexColCenter}
  align-items: center;

  .info-graphic-container {
    ${FlexColCenter}
    align-items: center;

    .new-employer-graphic {
      width: 70%;
      max-width: 400px;
      height: auto;
      margin-top: 20px;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        order: 2;
        align-self: flex-start;
        margin-top: 0px;
      }
    }

    .new-employer-info-section {
      ${FlexColCenter}
      align-items: center;

      .new-employer-info-text {
        font-size: 16px;
        text-align: center;
      }

      .get-started-button {
        width: 80%;
        max-width: 250px;
      }

      .new-employer-subheader {
        font-weight: 700;
        text-align: center;
        margin: 20px 0px;
        width: 70%;

        @media (${({ theme }) => theme.breakpoints.lgMin}) {
          text-align: left;
          width: 100%;
          margin: 100px 0 0;
        }
      }

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        order: 1;
        flex-basis: 70%;
        margin-right: 50px;

        .new-employer-info-text {
          margin: 60px 0px;
          font-size: 22px;
          line-height: 1.75rem;
          text-align: left;
        }

        .get-started-button {
          align-self: flex-start;
        }
      }
    }
    
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      flex-direction: row;
    }
  }
`;

export const CompaniesSection = styled(MaxBoundarySection)`
  ${FlexColCenter}
  align-items: center;

  img {
    margin-top: 30px;
    max-width: 700px;
    width: 90%;
  }

  .companies-subheader {
    font-weight: 700;
    text-align: center;
    margin: 20px 0px 0px;
    width: 80%;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 100%;
    }
  }
`;

export const WhatCanYouDoSection = styled(MaxBoundarySection)`
  ${FlexColCenter}
  align-items: center;

  .what-can-you-do-subheader {
    font-weight: 700;
    text-align: center;
    margin: 30px 0px;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      text-align: left;
      margin-top: 0px;
      margin-bottom: 60px;
    }
  }

  .what-can-you-do-info-container {
    ${FlexColCenter}

    .paragraph-container {
      text-align: center;

      p { 
        width: 100%;
      }
    }

    .offsets-example-graphic {
      margin: 20px 0px;
      max-width: 90%;
      height: auto;
      width: 400px;
    }

    @media(${({ theme }) => theme.breakpoints.lgMin}) {
      .paragraph-container p{
        width: 50%;
      }

      p {
        font-size: 22px;
        line-height: 1.75rem;
        text-align: left;
      }

      .offsets-example-graphic {
        float: right;
        margin-top: -280px;
        min-height: 450px;
        min-width: 450px;
        padding: 10px;
      }
    }
  }
`;

export const ContactSection = styled(MaxBoundarySection)`
  ${FlexColCenter}

  a {
    font-size: 14px;
    margin-top: 30px;
    @media(${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 16px;
    }
  }

  .form-container {
    ${FlexColCenter}
    align-items: center;

    @media(${({ theme }) => theme.breakpoints.lgMin}) {
      width: 887px;
    }
  }

  .contact-us-subheader {
    font-weight: 700;
  }

  p {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;

    @media(${({ theme }) => theme.breakpoints.lgMin}) {
      max-width: 400px;
    }
  }

  form {
    margin-top: 30px;
    width: 100%;

    .submit-error {
      color: ${({ theme }) => theme.colors.danger};
      margin-top: 10px;
    }

    .field-error {
      padding: 0;
      line-height: 23px;
      font-size: 16px;
      margin: 5px 0 0;
      padding-left: 25px;
      text-align: left;
      color: ${({ theme }) => theme.colors.danger};
      margin-bottom: 0;
    }

    @media(${({ theme }) => theme.breakpoints.lgMin}) {
      max-width: 606px;
    }
  }

  @media(${({ theme }) => theme.breakpoints.lgMin}) {
    .form-container {
      ${FlexColCenter}
      align-items: center;
      border: 2px solid ${({ theme }) => theme.colors.darkGray1};
      border-radius: 20px;
      padding: 60px 80px;
      align-self: center;
    }
  }

  select {
    width: 100%;
    border: 2px solid ${({ theme }) => theme.colors.lightGray2};
    border-radius: 200px;
    font-size: 16px;
    font-weight: 400;
    padding: 11px 40px 11px 20px;

    &.error {
      border: 2px solid ${({ theme }) => theme.colors.danger};
      box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.danger};
    }
  }

  select::marker {
    color: red;
  }

  .select-label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.darkGray1};
    display: block;
    margin: 0 0 8px 10px;
  }

  .ctas-container {
    ${FlexColCenter}
    margin-top: 30px;

    .opportunity-cta {
      width: 100%;

      @media(${({ theme }) => theme.breakpoints.smMin}) {
        max-width: 300px;
      }
    }
  }
`;

export const FormField = styled(TextField)``;

export const SelectField = styled.select``;
