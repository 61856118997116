import React from 'react';
import { GroupLogoSkeletonContainer, IGroupLogoProps } from './styles';

interface IProps extends IGroupLogoProps {
  className?: string;
}

export const GroupLogoSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <GroupLogoSkeletonContainer
    circle
    className={ `${className} group-logo-skele` }
  />
);
