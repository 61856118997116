import React, { useCallback, useEffect, useMemo } from 'react';
import { AccountHubNavMainContainer, MobileNavLinksList, MobileNavLinksListContainer, MobileNavLinksListItem, MobileNavMenuHeader, NavAltLinksList, NavLinksList, NavLinksListAltItem, NavLinksListContainer, NavLinksListItem } from './styles';
import { DashboardIcon } from '../../../components/svgs/icons/DashboardIcon';
import { Link, useLocation } from 'react-router-dom';
import { ImpactReportIcon } from '../../../components/svgs/icons/ImpactReportIcon';
import { KarmaCashIcon } from '../../../components/svgs/icons/KarmaCashIcon';
import { TransactionsIcon } from '../../../components/svgs/icons/TransactionsIcon';
import { LinkedAccountsIcon } from '../../../components/svgs/icons/LinkedAccountsIcon';
import { config, useSpring } from 'react-spring';
import { DashboardMenuIcon } from '../../../components/svgs/icons/DashboardMenuIcon/ index';
import { XIcon } from '../../../components/svgs/icons/XIcon';
import { ButtonKind } from '../../../components/Button/styles';
import { KWLogoWhite } from '../../../components/svgs/graphics/LogoWithText';
import { useAnalytics } from '../../../contexts/analytics-store';
import { AccountInformationIcon } from '../../../components/svgs/icons/AccountInformationIcon';
import { ButtonLink } from '../../../components/Button';
import { observer } from 'mobx-react';
import { useAccountHubMobileNav } from '../../../contexts/accountHubMobileNav';
import { DoneGoodIcon } from '../../../components/svgs/logos/DoneGoodIcon';
import { useUserSession } from '../../../contexts/user';

interface IProps {
  className?: string;
}

enum AccountHubNavLinkType {
  Dashboard = 'dashboard',
  AccountInformation = 'account-information',
  ImpactReports = 'impact-reports',
  KarmaCash = 'cashback',
  Transactions = 'transactions',
  LinkedAccounts = 'linked-cards',
  ShopNow = 'shop-now',
  CompanyRatings = 'browse-and-compare-companies',
  ImpactInsider = 'impact-insider',
}

interface IAccountHubNavLink {
  id?: AccountHubNavLinkType;
  icon?: JSX.Element;
  text: string;
  path: string;
}

interface IAccountHubAltNavLink extends IAccountHubNavLink {
  subText: string;
  redirect?: boolean;
  altSubText?: string;
}

const accountHubDesktopAltLinks: IAccountHubAltNavLink[] = [
  {
    id: AccountHubNavLinkType.ShopNow,
    text: 'Shop Now',
    subText: '10% cashback + FREE shipping',
    altSubText: '6000+ Eco-Friendly Products',
    path: 'https://donegood.co/',
    icon: <DoneGoodIcon />,
  },
  {
    id: AccountHubNavLinkType.CompanyRatings,
    text: 'Company Ratings',
    subText: 'Browse & Compare',
    path: `/${AccountHubNavLinkType.CompanyRatings}`,
  },
  {
    id: AccountHubNavLinkType.ImpactInsider,
    text: 'Impact Insider',
    subText: 'News & Insights',
    path: 'https://karmawallet.io/blog',
    redirect: true,
  },
];

const accountHubNavLinks: IAccountHubNavLink[] = [
  {
    id: AccountHubNavLinkType.Dashboard,
    icon: <DashboardIcon />,
    text: 'Dashboard',
    path: AccountHubNavLinkType.Dashboard,
  },
  {
    id: AccountHubNavLinkType.ImpactReports,
    icon: <ImpactReportIcon />,
    text: 'Impact Reports',
    path: AccountHubNavLinkType.ImpactReports,
  },
  {
    id: AccountHubNavLinkType.KarmaCash,
    icon: <KarmaCashIcon />,
    text: 'Karma Cash',
    path: AccountHubNavLinkType.KarmaCash,
  },
  {
    id: AccountHubNavLinkType.Transactions,
    icon: <TransactionsIcon />,
    text: 'Rated Transactions',
    path: AccountHubNavLinkType.Transactions,
  },
  {
    id: AccountHubNavLinkType.LinkedAccounts,
    icon: <LinkedAccountsIcon />,
    text: 'Linked Accounts',
    path: AccountHubNavLinkType.LinkedAccounts,
  },
  {
    id: AccountHubNavLinkType.AccountInformation,
    icon: <AccountInformationIcon />,
    text: 'Account Information',
    path: AccountHubNavLinkType.AccountInformation,
  },
];

const AccountHubNavBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const mobileNavModel = useAccountHubMobileNav();
  const location = useLocation();
  const user = useUserSession();

  const mobileNavFrom = {
    height: '56px',
    width: '100px',
  };
  
  const mobileNavTo = {
    height: `${window.innerHeight - 20}px`,
    width: `${window.innerWidth - 20}px`,
  };

  useEffect(() => {
    // prevent scrolling when this modal is open
    document.body.classList[mobileNavModel.accountMobileNavOpen ? 'add' : 'remove']('no-scroll');
  }, [mobileNavModel.accountMobileNavOpen]);

  const onKarmaWalletLogoClick = useCallback(() => {
    analytics.fireEvent('AccountNav_KWLogo_Clicked');
  },[]);

  const onMobileMenuClick = useCallback((mobileMenuOpen: boolean) => {
    analytics.fireEvent(`AccountMobileNav_${mobileMenuOpen ? 'Open' : 'Close'}_Clicked`);
    mobileNavModel.setAccountMobileNavEngaged(mobileMenuOpen);
    mobileNavModel.setAccountMobileNavOpen(!mobileMenuOpen);
  }, [mobileNavModel.accountMobileNavOpen]);

  const onMobileMenuItemClick = useCallback((option: string) => {
    const optionClicked = option.replace(' ', '_');
    analytics.fireEvent(`AccountMobileNav_${optionClicked}_Clicked`);
    mobileNavModel.setAccountMobileNavEngaged(true);
    mobileNavModel.setAccountMobileNavOpen(false);
  }, [mobileNavModel.accountMobileNavOpen]);

  const onDesktopMenuItemClick = useCallback((option: string) => {
    const optionClicked = option.replace(' ', '_');
    analytics.fireEvent(`AccountDesktopNav_${optionClicked}_Clicked`);
  }, []);

  const mobileNavigationSpring = useSpring({
    config: config.stiff,
    from: mobileNavModel.accountMobileNavOpen || !mobileNavModel.accountMobileNavEngaged ? mobileNavFrom : mobileNavTo,
    to: mobileNavModel.accountMobileNavOpen ? mobileNavTo : mobileNavFrom,
  });

  const mobileNavHeader = useMemo(() => (
    <MobileNavMenuHeader
      key='mobile-nav-header'
      onClick={ () => onMobileMenuClick(mobileNavModel.accountMobileNavOpen) }
      kind={ ButtonKind.Blank }
    >
      {
        mobileNavModel.accountMobileNavOpen
          ? <><XIcon stroke='white' />Close</>
          : <><DashboardMenuIcon fill='white' />Menu</>
      }
    </MobileNavMenuHeader>
  ), [mobileNavModel.accountMobileNavOpen]);

  const mobileNavLinksList = useMemo(() => (
    <MobileNavLinksList key='mobile-nav-links-list'>
      {
        accountHubNavLinks.map((link, index) => 
          <MobileNavLinksListItem
            key={ index }
            className={ `account-nav-link ${location.pathname.includes(link.path) ? 'active' : ''}` }  
          >
            <Link
              onClick={ () => onMobileMenuItemClick(link.text) }
              to={ link.path }
              className='account-nav-link-text'
            >
              <div>{ link.icon }</div>
              <span>{ link.text }</span>
            </Link>
          </MobileNavLinksListItem>,
        )
      }
    </MobileNavLinksList>
  ), [accountHubNavLinks]);

  const renderMobileAccountHubNavLinks = () => {
    const content: JSX.Element[] = [mobileNavHeader];

    if (mobileNavModel.accountMobileNavOpen) content.push(mobileNavLinksList);
    
    return (
      <MobileNavLinksListContainer
        aria-label='Mobile Navigation'
        className={ 'mobile-nav' }
        style={ mobileNavigationSpring }
      >
        { content }
      </MobileNavLinksListContainer>
    );
  };

  const renderDesktopAccountHubAltLinks = useCallback(() => {
    if (!location.pathname.includes('/account')) return null;

    const altLinks = accountHubDesktopAltLinks.map((link) => (
      <NavLinksListAltItem
        key={ link.id }
        className={ 'account-nav-alt-link-item' }  
        onClick={ () => onDesktopMenuItemClick(link.text) }
      >
        <Link
          className='account-nav-alt-link'
          target={ link.redirect ? '_blank' : '_self' }
          to={ link.path }
        >
          <div>
            <span className='alt-link-text'>{ link.text }</span>
            <span className='alt-link-icon'>{ link.icon }</span>
          </div>
          <span className='sub-text'>{ user.hasKarmaWalletCard ? link.subText : link.altSubText || link.subText }</span>
        </Link>
      </NavLinksListAltItem>
    ));

    return altLinks;
  }, [accountHubDesktopAltLinks]);

  const renderDesktopAccountHubNavLinks = useCallback(() => {
    
    const links = accountHubNavLinks.map((link) => (
      <NavLinksListItem
        key={ link.id }
        className={ `account-nav-link ${location.pathname.includes(link.path) ? 'active' : ''}` }  
        onClick={ () => onDesktopMenuItemClick(link.text) }
      >
        <Link
          to={ link.path }
          className='account-nav-link-text'
        >
          <div>{ link.icon }</div>
          <span>{ link.text }</span>
        </Link>
      </NavLinksListItem>
    ));

    return (
      <NavLinksListContainer aria-label='Main Navigation'>
        <ButtonLink
          href='/'
          className='KW-logo-link'
          kind={ ButtonKind.Blank }
          label='Karma Wallet Home Page'
          onClick={ onKarmaWalletLogoClick }
        >
          <KWLogoWhite className='KW-logo' />
        </ButtonLink>
        <NavLinksList>
          { links }
        </NavLinksList>
        <NavAltLinksList>
          { renderDesktopAccountHubAltLinks() }
        </NavAltLinksList>
      </NavLinksListContainer>
    );
  }, [accountHubNavLinks]);

  return (
    <AccountHubNavMainContainer className={ className }>
      { renderDesktopAccountHubNavLinks() }
      { renderMobileAccountHubNavLinks() }
    </AccountHubNavMainContainer>
  );
};

export const AccountHubNav = observer(AccountHubNavBase);
