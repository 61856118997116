import styled from 'styled-components';
import { FlexCenter } from '../../../styles/styles';

export const AccountAvatarContainer = styled.div`
  ${FlexCenter}
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 48px;
  height: 48px;

  h2 {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 18px;
    margin-top: 4px;
  }
`;
