import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
  fill?: string;
}

export const FilterIcon: FC<IProps> = ({ className = '', stroke = '', id = '', fill = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}filter-icon` }
    id={ id }
    width={ 12 }
    height={ 7 }
    stroke={ stroke }
    fill={ fill }
  >
    <path d='M4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H7.5C7.63261 6 7.75979 6.05268 7.85355 6.14645C7.94732 6.24021 8 6.36739 8 6.5C8 6.63261 7.94732 6.75979 7.85355 6.85355C7.75979 6.94732 7.63261 7 7.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM2 3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H9.5C9.63261 3 9.75979 3.05268 9.85355 3.14645C9.94732 3.24021 10 3.36739 10 3.5C10 3.63261 9.94732 3.75979 9.85355 3.85355C9.75979 3.94732 9.63261 4 9.5 4H2.5C2.36739 4 2.24021 3.94732 2.14645 3.85355C2.05268 3.75979 2 3.63261 2 3.5ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z' fill={ fill } />
  </SvgIcon>
);
