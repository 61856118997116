import React, { useMemo } from 'react';
import { DataContainer, SquareContainer } from './styles';
import { HeaderContainer } from '../HeaderContainer';
import { CircleContainer, TopSectionContainer } from './styles';
import { H1M, H3 } from '../../../styles/components/header';
import { IndustryReportScoreGauge } from '../../../components/IndustryReportScoreGauge';
import { ArticleHeaderTypes, IArticle, IArticleType } from '../../../models/article';

interface IProps {
  article: IArticle;
  className?: string;
}

export const HeaderSection: React.FC<IProps> = ({
  className,
  article,
}) => {
  const { company, headerType, type, headerLogo, headerTitle, title, headerBackground } = article;
  const circleImage = useMemo(() => {
    if (!headerLogo && !company) return null;

    return (
      <div className='logo-container'>
        <img 
          className='altrenate-logo' 
          src={ !!company?.logo ? company?.logo : headerLogo } 
          alt={ `${title} graphic` } 
        /> 
      </div>
    );
  }, [article]);

  const circleTitleText = useMemo(() => {
    if (type === IArticleType.CompanySpotlight) {
      return (
        <>
          <H1M>Company</H1M>
          <div className='the-bad-wrapper'>
            { type === IArticleType.CompanySpotlight ? <H1M className='the-bad-text'>Spotlight</H1M> : null }
          </div>
        </>
      );
    }

    if (type === IArticleType.GoodAndBad) {
      return (
        <>
          <H1M>The Good</H1M>
          <div className='the-bad-wrapper'>
            <H1M>&</H1M><H1M className='the-bad-text'>The Bad</H1M>
          </div>
          <p>Let's dive into the truth about { company.companyName }</p>
        </>
      );
    }

    return <H1M>{ !!headerTitle ? headerTitle : title }</H1M>;
  }, [article]);

  const companyRatingGauge = useMemo(() => {
    if (headerType !== ArticleHeaderTypes.CompanyAndRating) return null;

    return (
      <SquareContainer>
        <div>
          <p>Company Rating</p>
          <H3>{ company.combinedScore }</H3>
        </div>
        <IndustryReportScoreGauge classname='report-score-gauge' combinedScore={ company.combinedScore } />
      </SquareContainer>
    );
  }, [article]);

  return (
    <TopSectionContainer className={ className } backgroundImage={ headerBackground }>
      <HeaderContainer article={ article }>
        <DataContainer>
          <CircleContainer>
            { circleImage }
            { circleTitleText }
          </CircleContainer>
          { companyRatingGauge }
        </DataContainer>
      </HeaderContainer>
    </TopSectionContainer>
  );
};
