import { useIdleTimer } from 'react-idle-timer';
import { useUserSession } from '../contexts/user';

export const useSessionIdle = (): any => {
  const user = useUserSession();

  const onIdle = (): void => {
    if (!user.isLoggedIn) return null;
    user.setSessionIsIdle(true);
  };
  
  useIdleTimer({ onIdle, timeout: 1000 * 60 * 5 });

  return null;
};
