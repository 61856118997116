import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const UserIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }user-icon` }
    id={ id }
    width={ 14 }
    height={ 17 }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M3.83329 6.00004C3.83329 4.25114 5.25105 2.83337 6.99995 2.83337C8.74886 2.83337 10.1666 4.25114 10.1666 6.00004C10.1666 7.74894 8.74886 9.16671 6.99995 9.16671C5.25105 9.16671 3.83329 7.74894 3.83329 6.00004ZM6.99995 0.833374C4.14648 0.833374 1.83329 3.14657 1.83329 6.00004C1.83329 7.57723 2.53998 8.98936 3.654 9.93705C1.61013 10.9429 0.166626 12.8512 0.166626 15.1667C0.166626 15.719 0.614341 16.1667 1.16663 16.1667H12.8333C13.3856 16.1667 13.8333 15.719 13.8333 15.1667C13.8333 12.8512 12.3898 10.9429 10.3459 9.93705C11.4599 8.98935 12.1666 7.57723 12.1666 6.00004C12.1666 3.14657 9.85343 0.833374 6.99995 0.833374ZM6.99996 11.1667C9.35676 11.1667 11.1506 12.5151 11.6759 14.1667H2.32402C2.84934 12.5151 4.64316 11.1667 6.99996 11.1667Z' />
  </SvgIcon>
);
