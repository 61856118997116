import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { Section } from '../../styles/components/containers';

export const ActionsContainer = styled.div`
  text-align: center;

  &:first-of-type {
    margin: 30px 0;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin: 75px 0;
    }
  }

  .job-instructions {
    margin: 30px 0;
  }

  .job-ctas {
    justify-content: center;
  }
`;

export const CareerContainer = styled(MainContainer)``;

export const CareerInner = styled.div`
  position: relative;
  overflow: hidden;

  .circle,
  .triangle,
  .boomerang {
    position: absolute;
    z-index: 1;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      display: none;
    }
  }

  .circle {
    top: 170px;
    left: -150px;
    height: 300px;
    width: 330px;
    height: auto;
    fill: ${({ theme }) => theme.colors.lightGreen1};
  }

  .triangle {
    top: 60%;
    left: 10vw;
    width: 200px;
    height: auto;
    fill: ${({ theme }) => theme.colors.lightYellow2};
    transform: rotateZ(30deg);
  }

  .boomerang {
    top: 30%;
    right: -40px;
    width: 200px;
    height: auto;f
    fill: ${({ theme }) => theme.colors.primary};
    transform: rotateZ(30deg);
  }
`;

export const Header = styled(Section)`
  background: ${({ theme }) => theme.colors.lightYellow2};

  .header-inner {
    width: calc(100% - 30px);
    max-width: 920px;
    margin: 0 auto;
    text-align: center;
  }

  .job-details {
    justify-content: center;
    padding-top: 10px;
  }
`;

export const JobDescription = styled(Section)`
  position: relative;
  width: calc(100% - 30px);
  max-width: 920px;
  margin: 0 auto;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray1}`};
  background: none;
  z-index: 2;

  & > h3 {
    margin-bottom: 10px;
  }
`;
