import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../styles/components/containers';

export const OurImpactWrapper = styled(Section)`
  background: ${props => props.backgroundColor};
`;

export const OurImpactPartnershipsContainer = styled(MaxBoundaryContainer)`

  .header {
    margin-top: 60px;
  }
  
  .title {
    margin-bottom: 15px;
  }
`;
