import styled from 'styled-components';
import { FlexHorizontalCenter } from '../../../styles/styles';

export const SourceContainer = styled.div`
  .img-container {
    ${ FlexHorizontalCenter }
    height: 80px;
    margin-bottom: 10px;

    &.no-logo {
      @media (${({ theme }) => theme.breakpoints.mdMax}) {
        height: 0;
      }
    }

    & > * {
      width: auto;
      max-height: 100%;
      max-width: 200px;
    }
  }

  h5 {
    a {
      color: ${({ theme }) => theme.colors.darkGreen1};
      text-decoration: none;
      
      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: underline;
      }

      &:focus-visible {
        outline: ${({ theme }) => `1px dashed ${theme.colors.primary}`};
        outline-offset: 1px;
      }
    }
  }
`;
