import styled from 'styled-components';
import { Button } from '../Button';
import { CompanyLogo } from '../CompanyLogo';
import { CompanyRatingIndicator } from '../CompanyRatingIndicator';
import { LegacyUNSDGScores } from '../LegacyUNSDGScores';
import { _H5 } from '../../styles/components/header';
import { FlexCenter, FlexCol, FlexRow, LineClamp, PrimaryFont } from '../../styles/styles';

export const ComparisonSquareContainer = styled.div`
  position: relative;
  width: 48%;
  height: 290px;
`;

export const ComparisonCompanyContainer = styled.div`
  position: relative;
  height: 100%;
  border: solid 1px ${({ theme }) => theme.colors.lightGray1};
  border-radius: 20px;

  &.our-pick {
    border: solid 3px ${({ theme }) => theme.colors.primary};
    position: relative;

    .our-pick-section {
      ${FlexCenter}

      position: absolute;
      bottom: -1px;
      width: 100.25%;
      height: 50px;
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
      background: ${({ theme }) => theme.colors.primary};

      .text {
        ${PrimaryFont}
        font-weight: 700;
        font-size: 18px;
        line-height: 50px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
      }

      svg {
        margin-left: 5px;
      }
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    width: 20px;
    height: 20px;

    &.x-icon {
      right: 10px;
    }
  }

`;

export const ComparisonCompanyContent = styled.div`
  ${FlexCol}
  padding: 20px;

  .companyName {
    ${LineClamp}
    ${_H5}
    margin-top: 15px;
    text-align: center;
    text-decoration: none;
    min-height: 50px;
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }
`;

export const ComparisonCompanyLogo = styled(CompanyLogo)`
  margin: 10px auto;
  max-width: 90%;
  height: 100px;

  img {
    width: auto;
    max-width: 70%;
  }
`;

export const ComparisonCompanyRatingIndicator = styled(CompanyRatingIndicator)`
  width: 75px;
  height: 75px;
  margin: 20px auto 0;
`;

export const NoData = styled.div`
  ${FlexCenter}
  height: 200px;
  color: ${({ theme }) => theme.colors.lightGray1};
`;

export const UNSDGScores = styled(LegacyUNSDGScores)`
  margin-top: 30px;
`;

export const SectorsContainer = styled.div`
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  background ${({ theme }) => theme.colors.lightBlue2};

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    text-align: left;
  }
`;

export const SectorWrapper = styled.div`
  &:first-of-type {
    margin-bottom: 5px;
  }

  .sector-text, .sector-type {
    display: inline;
  }

  .sector-type {
    font-weight: bold;
    display: inline;
    padding-right: 5px;
  }
`;

export const PickCompanyContainer = styled(Button)`
  ${FlexCol}
  position: relative;
  width: 100%;
  height: 100%;
  border: solid 1px ${({ theme }) => theme.colors.lightGray1};
  border-radius: 20px;

  .add-container {
    ${FlexRow}
  }

  .error-text {
    color: ${({ theme }) => theme.colors.red};
    margin-bottom: 5px;
  }
`;
