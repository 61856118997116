import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useAnalytics } from '../../../contexts/analytics-store';
import { useUserSession } from '../../../contexts/user';
import { CreditCardIcon } from '../../svgs/icons/CreditCardIcon';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { RelinkCardModalContainer } from './styles';
import { ButtonKind } from '../../Button/styles';
import { CtaType } from '../../CTAs';

interface IProps extends IThemeProps {
  isOpen: boolean;
  onClose(): void;
  onRelinkSuccess(): void;
}

const RelinkCardModalBase: React.FC<IProps> = ({
  onRelinkSuccess,
  theme,
  ...restProps
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();

  const onCardClick = useCallback(() => {
    analytics.fireEvent('RelinkCard_Confirm_Click');
  }, []);

  const unlinkedInstitutions = useMemo(() => user.unlinkedCards.reduce( (acc, currentValue) => {
    if (acc.indexOf(currentValue.institution) !== -1 ) return acc;
    if (currentValue.status === 'unlinked') acc.push(currentValue.institution);
    return acc;
  }, []), [user.unlinkedCards] );

  const cardCtas = useMemo(() => unlinkedInstitutions.map(institution => ({
    id: institution,
    text: `Re-link ${institution}`,
    kind: ButtonKind.Primary,
    ctaType: CtaType.Plaid,
    onClick: onCardClick,
    onPlaidSuccess: onRelinkSuccess,
  })), [unlinkedInstitutions]);

  return (
    <RelinkCardModalContainer
      { ...restProps }
      graphic={ <CreditCardIcon stroke={ theme.colors.darkGray1 } /> }
      title={ 'Re-link a Bank' }
      body='Select the bank you would like to re-link.'
      ctas={ cardCtas }
    />
  );
};

export const RelinkCardModalAsObserver = observer(RelinkCardModalBase);
export const RelinkCardModal = withTheme(RelinkCardModalAsObserver);
