import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

export enum KebabStack {
  Horizontal,
  Vertical,
}

interface IProps {
  className?: string;
  fill?: string;
  stack?: KebabStack;
  id?: string;
}

export const KebabIcon: FC<IProps> = ({ className = '', fill = '', id = '', stack = KebabStack.Horizontal }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }kebab-icon` }
    id={ id }
    width={ stack === KebabStack.Horizontal ? 13 : 3 }
    height={ 16 }
    stroke={ 'none' }
    fill={ fill }
  >
    {
      stack === KebabStack.Horizontal
        ? <path fillRule='evenodd' d='M1.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM13 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
        : <path fillRule='evenodd' d='M0 2.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm0 5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zM1.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z' />
    }
  </SvgIcon>
);
