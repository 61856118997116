import { observer } from 'mobx-react-lite';
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { PlaidLinkError, PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { useSearchParams } from 'react-router-dom';
import { buildBaseKarmaUrl } from '../lib/urlBuilders';
import { PlaidModel } from '../models/plaid';
import { useAnalytics } from './analytics-store';
import { useErrorMessages } from './error-messages-store';
import { useToaster } from './toaster-store';
import { useUserSession } from './user';

export const PlaidContext = createContext<PlaidModel>(null);

export const usePlaid = () => React.useContext(PlaidContext);

const PlaidStoreBase: React.FC<React.PropsWithChildren> = ({ children }) => {
  const errorMessages = useErrorMessages();
  const analytics = useAnalytics();
  const toaster = useToaster();
  const user = useUserSession();
  const plaidModel = useRef(new PlaidModel()).current;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOauth, setIsOauth] = useState(false);

  const reset = () => {     
    plaidModel.resetLinkToken();

    if (isOauth) {
      searchParams.delete('oauth_state_id');
      setSearchParams(searchParams);
      setIsOauth(false);
    }
  };

  const _onExit = useCallback((error: PlaidLinkError) => {
    setTimeout(() => {
      document.body.style.overflow = null;
    }, 1000);

    if (error) {
      analytics.fireEvent(`Plaid_Error_${error.display_message}`);
      plaidModel.onError?.();
    }

    reset();
  }, []);

  const _onSuccess = async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
    let errorThrown = false;
    analytics.fireEvent('Plaid_AddCard_Success');
    try {
      await plaidModel.exchangeLinkToken(publicToken, metadata);
      reset();
      toaster.push({
        message: 'Card successfully linked! We\'ll send an email when transactions are processed.',
      });
      setTimeout(() => {
        document.body.style.overflow = null;
      }, 1000);
      
      plaidModel.onSuccess?.();
    } catch (err: any) {
      errorThrown = true;
      errorMessages.push({
        title: 'Error Linking Card',
        message: err,
      });
      analytics.fireEvent('Plaid_ExchangeLinkToken_Error');

      setTimeout(() => {
        document.body.style.overflow = null;
      }, 1000);
      reset();
    }

    if (errorThrown) return;

    try {
      await user.loadCards(true);
    } catch (err: any) {
      analytics.fireEvent('Plaid_ReloadCards_Error');
    }
  };

  const plaidLink = usePlaidLink({
    onSuccess: _onSuccess,
    onExit: _onExit,
    token: isOauth ? plaidModel.linkTokenFromLocalStorage : plaidModel.linkToken,
    receivedRedirectUri: isOauth ? `${buildBaseKarmaUrl('karma')}?oauth_state_id=${searchParams.get('oauth_state_id')}` : null,
  });

  useEffect(() => {
    if (!!searchParams.get('oauth_state_id')) {
      setIsOauth(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (plaidLink.ready && !plaidModel.preventPlaidExec) plaidLink.open();
  }, [plaidLink.ready, plaidModel.preventPlaidExec, plaidLink]);

  return (
    <PlaidContext.Provider value={ plaidModel }>
      { children }
    </PlaidContext.Provider>
  );
};

export const PlaidStore = observer(PlaidStoreBase);
