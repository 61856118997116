import React, { useMemo } from 'react';
import { AsFeaturedInSectionContainer } from './styles';
import { H2MobileMarketing } from '../../styles/components/header';
import { useAppData } from '../../contexts/app';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';

interface IProps {
  className?: string;
}

export const AsFeaturedInSection: React.FC<IProps> = ({
  className = '',
}) => {
  const appData = useAppData();
  const analytics = useAnalytics();

  const onExplorePressClick = () => {
    analytics.fireEvent('HP_AsFeaturedInSection_ExplorePress_Click');
  };

  const image = useMemo(() => {
    if (appData.isDesktop) {
      return 'https://cdn.karmawallet.io/uploads/czVxEVQDP5-presslogosdesktop.webp';
    } else {
      return 'https://cdn.karmawallet.io/uploads/UlToEh85Lh-presslogosmobile.webp';
    }
  }, [appData]);

  return (
    <AsFeaturedInSectionContainer className={ className }>
      <H2MobileMarketing className='header-text'>As featured in</H2MobileMarketing>
      <img loading='lazy' src={ image } alt='Press Logos' />
      <ButtonLink
        kind={ ButtonKind.Primary }
        href='https://blog.karmawallet.io/press/'
        className='press-button'
        onClick={ onExplorePressClick }
      >
        See All Press
      </ButtonLink>
    </AsFeaturedInSectionContainer>
  );
};
