import styled, { css } from 'styled-components';
import { _H5 } from '../../../styles/components/header';
import { FlexHorizontalCenter, FocusVisible, PrimaryFont, SecondaryFont } from '../../../styles/styles';

export const BottomRow = styled.div`
  ${FlexHorizontalCenter}
`;

export const BottomRowItem = styled.div`
  ${FlexHorizontalCenter}
  ${SecondaryFont}
  height: 30px;
  font-size: 14px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    stroke: ${({ theme }) => theme.colors.darkGray1};
  }
`;

export const DonationItemContainer = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray1};
`;

export const DonationItemSkeletonContainer = styled(DonationItemContainer)`
  .donation-project-name {
    width: 180px;
    height: 20px;
  }

  .donation-amount {
    width: 65px;
    height: 20px;
  }

  .donation-date {
    width: 80px;
    height: 15px;
  }

  .donation-tonnes {
    width: 170px;
    height: 15px;
  }
`;

const donationWrapperStyles = css`
  display: block;
`;

export const DonationLinkWrapper = styled.a`
  ${donationWrapperStyles}
  ${FocusVisible}
  text-decoration: none;
`;

export const DonationWrapper = styled.div`
  ${donationWrapperStyles}
`;

export const TopRow = styled.div`
  ${FlexHorizontalCenter}
  justify-content: space-between;
`;

export const TopRowItem = styled.div`
  ${_H5}
  ${PrimaryFont}
  margin-bottom: 0.5rem;
`;
