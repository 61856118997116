import styled from 'styled-components';
import { CustomModal } from '../CustomModal';

export const BodyText = styled.p`
  margin: 0;
  line-height: 1.4em;
`;

export const DisclaimerText = styled(BodyText)`
  margin-top: 20px;
  font-size: 12px;
`;

export const JoinGroupModalContainer = styled(CustomModal)`
  width: 90%;
  max-width: 700px;

  .text-field {
    margin-top: 20px;
  }

  .error {
    margin: 5px 0 0;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.danger};
    text-align: center;
    line-height: 1.4em;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin: 5px 0 0;
    }
  }

  .join-group-button {
    width: 125px;
    height: 42px;
    position: relative;

    .join-group-loading-spinner {
      .spinner {
        height: 30px;
        width: 30px;
      }
    }
  }
`;
