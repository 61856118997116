import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../../styles/components/containers';
import { FlexColCenter, FlexRow } from '../../../styles/styles';

export const QuoteSectionContainer = styled(Section)``;

export const QuoteSectionInnerContainer = styled(MaxBoundaryContainer)`
  ${FlexColCenter}
  align-items: center;
  gap: 20px;

  img {
    height: auto;
    width: 346px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      max-width: 378px;
      width: 100%;
    }
  }

  blockquote {
    margin: 0 auto;
    max-width: 850px;
    position: relative;
    width: 80%;
      
    .left-quote {
      position: absolute;
      left: -35px;
      top: -5px;
    }

    div {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 20px;
      font-style: italic;
      font-weight: 500;
      line-height: 28px;
      position: relative;

      .right-quote {
        bottom: -10px;
        position: absolute;
        right: 0;
        transform: rotate(180deg);
      }

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        font-size: 26px;
      }
    }

    footer {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      margin-top: 30px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    ${FlexRow}
  }
`;
