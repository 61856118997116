import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
  ariaHidden?: boolean;
}

export const KarmaCashIcon: FC<IProps> = ({
  className = '',
  fill = '',
  id = '',
  ariaHidden,
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}karma-cash-icon` }
    id={ id }
    height={ 20 }
    width={ 20 }
    fill={ fill }
    aria-hidden={ ariaHidden }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M13.75 18.75C15.0761 18.75 16.3479 18.2232 17.2855 17.2855C18.2232 16.3479 18.75 15.0761 18.75 13.75C18.75 12.4239 18.2232 11.1521 17.2855 10.2145C16.3479 9.27678 15.0761 8.75 13.75 8.75C12.4239 8.75 11.1521 9.27678 10.2145 10.2145C9.27678 11.1521 8.75 12.4239 8.75 13.75C8.75 15.0761 9.27678 16.3479 10.2145 17.2855C11.1521 18.2232 12.4239 18.75 13.75 18.75ZM20 13.75C20 15.4076 19.3415 16.9973 18.1694 18.1694C16.9973 19.3415 15.4076 20 13.75 20C12.0924 20 10.5027 19.3415 9.33058 18.1694C8.15848 16.9973 7.5 15.4076 7.5 13.75C7.5 12.0924 8.15848 10.5027 9.33058 9.33058C10.5027 8.15848 12.0924 7.5 13.75 7.5C15.4076 7.5 16.9973 8.15848 18.1694 9.33058C19.3415 10.5027 20 12.0924 20 13.75Z' fill={ fill } />
    <path d='M11.7971 14.93C11.8559 15.675 12.4446 16.255 13.5009 16.325V16.875H13.9696V16.3213C15.0634 16.245 15.7021 15.66 15.7021 14.8125C15.7021 14.04 15.2146 13.6425 14.3396 13.4375L13.9696 13.35V11.85C14.4396 11.9037 14.7371 12.16 14.8084 12.515H15.6309C15.5721 11.7963 14.9559 11.235 13.9696 11.1738V10.625H13.5009V11.1875C12.5671 11.2787 11.9321 11.84 11.9321 12.635C11.9321 13.3375 12.4046 13.785 13.1909 13.9675L13.5009 14.0437V15.6337C13.0209 15.5612 12.7021 15.2963 12.6309 14.93H11.7971ZM13.4971 13.2375C13.0359 13.1313 12.7859 12.9125 12.7859 12.585C12.7859 12.2175 13.0559 11.9425 13.5009 11.8625V13.2375H13.4971ZM14.0371 14.17C14.5984 14.3 14.8559 14.51 14.8559 14.8812C14.8559 15.305 14.5346 15.595 13.9696 15.6488V14.155L14.0371 14.17Z' fill={ fill } />
    <path d='M1.25 0C0.918479 0 0.600537 0.131696 0.366117 0.366117C0.131696 0.600537 0 0.918479 0 1.25L0 11.25C0 11.5815 0.131696 11.8995 0.366117 12.1339C0.600537 12.3683 0.918479 12.5 1.25 12.5H6.35375C6.42625 12.07 6.535 11.6525 6.67625 11.25H3.75C3.75 10.587 3.48661 9.95107 3.01777 9.48223C2.54893 9.01339 1.91304 8.75 1.25 8.75V3.75C1.91304 3.75 2.54893 3.48661 3.01777 3.01777C3.48661 2.54893 3.75 1.91304 3.75 1.25H16.25C16.25 1.91304 16.5134 2.54893 16.9822 3.01777C17.4511 3.48661 18.087 3.75 18.75 3.75V8.16C19.225 8.585 19.6463 9.07 20 9.6025V1.25C20 0.918479 19.8683 0.600537 19.6339 0.366117C19.3995 0.131696 19.0815 0 18.75 0L1.25 0Z' fill={ fill } />
    <path d='M12.4975 6.35373L12.5 6.24998C12.4997 5.81947 12.3883 5.39632 12.1765 5.02151C11.9647 4.64671 11.6597 4.33295 11.291 4.11063C10.9224 3.8883 10.5026 3.76495 10.0722 3.75251C9.6419 3.74007 9.21565 3.83897 8.83476 4.03963C8.45387 4.24029 8.13126 4.53591 7.89816 4.89785C7.66506 5.2598 7.52938 5.6758 7.50426 6.10558C7.47914 6.53536 7.56543 6.96434 7.75477 7.35098C7.94411 7.73762 8.23009 8.06881 8.585 8.31248C9.6647 7.28407 11.0272 6.60194 12.4975 6.35373Z' fill={ fill } />
  </SvgIcon>
);
