import styled from 'styled-components';
import { NewModal } from '../NewModal';

export const PromoHomeModalContainer = styled(NewModal)`
  width: 750px;
  min-height: 560px;
  max-width: 90%;
  background: ${({ theme }) => theme.colors.lightBlue2};

  .modal-body {
    max-width: 460px;
    text-align: center;

    div {
      margin: 20px 0;
    }

    .promo-amount {
      font-size: 57px;
      font-weight: 700;
      padding-bottom: 15px;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .promo-cta {
    margin: 0 auto;
  }
`;
