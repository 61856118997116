import React from 'react';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';
import { AccordionSectionContainer, ContentContainer, HeaderRowContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  header: string | React.ReactNode;
  expanded: boolean;
  chevronColor?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const AccordionSectionBase: React.FC<IProps> = ({
  className = '',
  header,
  children,
  expanded,
  chevronColor,
  theme,
  onClick,
}) => (
  <AccordionSectionContainer className={ className }>
    <HeaderRowContainer className='header-row-container'>
      {
        typeof header === 'string' 
          ? <div className='h3 alternate-size'>{ header }</div>
          : header
      }
      <div className='chevron-wrapper' onClick={ onClick }>
        <ChevronIcon
          direction={ expanded ? ChevronDirection.Up : ChevronDirection.Down }
          stroke={ chevronColor || theme.colors.darkGray1 }
        />
      </div>
    </HeaderRowContainer>
    <ContentContainer className={ expanded ? 'expanded' : '' }>
      { children } 
    </ContentContainer>
  </AccordionSectionContainer>
);

export const AccordionSection = withTheme(AccordionSectionBase);
