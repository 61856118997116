import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { H1 } from '../../../../styles/components/header';
import { IThemeProps } from '../../../../styles/themes';
import { isValidEmail, passwordRules } from '../../../../utils/input-validation';
import { Button } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';
import { TextFieldKind } from '../../../TextField';
import { FormField } from '../styles';
import { AccountCompletionContainer, CreateAccountFormContainer, Header } from './styles';
import { EnvelopeIcon } from '../../../svgs/icons/EnvelopeIcon';
import { useUserSession } from '../../../../contexts/user';
import { LockIcon } from '../../../svgs/icons/LockIcon';
import { EyeToggle } from '../../../EyeToggle';

interface IProps extends IThemeProps {
  className?: string;
  onClose?: () => void;
}

const ChangeEmailBase: React.FC<IProps> = ({
  className = '',
  theme,
  onClose,
}) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState('');
  const [genError, setGenError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [succesfulEmailChange, setSuccesfulEmailChange] = useState(false);
  const [verifyToken, setVerifyToken] = useState('');

  const onConfirmChangeEmail = useCallback( async () => {
    try{
      await user.confirmChangeEmail({ verifyToken, email, password });
      setSuccesfulEmailChange(true);
      setSearchParams('');
    } catch (err: any) {
      setGenError(err.message);
    }
  }, [email, password, verifyToken]);

  useEffect(() => {
    const token = searchParams.get('verifyEmailChange');
    if (token) {
      setVerifyToken(token);
    }
  }, []);

  useEffect(() => {
    if (isValidEmail(email)) return;
    setEmailError('This isn\'t a valid e-mail address format');
  }, [email]);

  const onShowPasswordClick = useCallback(() => {
    setShowPassword(!showPassword);
    analytics.fireEvent(`ShowPasswordToggleClick_${!showPassword}`);
  }, [showPassword]);

  const onPasswordChange = useCallback((validate: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    setGenError('');
    if (validate && !!e.target.value) {
      const isValid = !passwordRules.find(r => !r.validate(e.target.value));
      setPasswordError(isValid ? '' : 'invalid');
    } else {
      setPasswordError('');
    }

    setPassword(e.target.value);
  }, [password]);
  
  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGenError('');
    setEmailError('');
    setEmail(e.target.value);
  }, [email]);

  const onConfirmEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGenError('');
    setConfirmEmailError('');
    setConfirmEmail(e.target.value);
  }, [confirm]);

  const onConfirmEmailBlur = useCallback(() => {
    if (confirmEmail !== email) {
      setGenError('Emails do not match');
    }
  }, [email, confirmEmail]);

  const renderHeaderSection = useCallback(() => {
    const header = 'Finish Changing Your Email';
    const text = 'Enter your new email address and current password to complete the change. We will send a confirmation email to your new email address.';

    return (
      <Header className='create-account-header'>
        <H1>{ header }</H1>
        <p className='subtext'>{ text }</p>
      </Header>
    );
  }, []);

  const renderFormFields = () => (
    <>
      <FormField
        errorText={ !email ? null : emailError }
        fieldKind={ TextFieldKind.Pill }
        id='user-email-input'
        label='New Email Address'
        labelHidden
        leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
        onChange={ onEmailChange }
        placeholder='New Email Address'
        value={ email }
      />
      <FormField
        errorText={ !confirmEmail ? null : confirmEmailError }
        fieldKind={ TextFieldKind.Pill }
        id='user-confirm-email-input'
        label='Confirm New Email Address'
        labelHidden
        leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
        onChange={ onConfirmEmailChange }
        onBlur={ onConfirmEmailBlur }
        placeholder='Confirm New Email Address'
        value={ confirmEmail }
      />
      <FormField
        id='user-password-input'
        className={ !!passwordError ? 'error' : '' }
        fieldKind={ TextFieldKind.Pill }
        label='Password'
        labelHidden
        leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
        rightAccessory={ <EyeToggle show={ showPassword } onClick={ onShowPasswordClick } /> }
        placeholder='Password'
        type={ showPassword ? 'text' : 'password' }
        value={ password }
        errorText={ passwordError }
        onChange={ onPasswordChange(false) }
      />
    </>
  );

  const renderCTA = useCallback(() => (
    <Button
      kind={ ButtonKind.Primary }
      disabled={ !!emailError || !email || !password || !confirmEmail || !!passwordError || !!confirmEmailError || !!genError }
      onClick={ onConfirmChangeEmail }
      className='cta'
    >
      Confirm Email Change
    </Button>
  ), [passwordError, emailError, email, password]);

  const renderChangeEmailContent = () => (
    <CreateAccountFormContainer>
      { renderHeaderSection() }
      <form>
        { renderFormFields() }
        { !!genError && <p className='field-error'>{ genError }</p> }
        { renderCTA() }
      </form>
    </CreateAccountFormContainer>
  );

  const renderSuccessfulEmailChange = () => {
    const header = 'Finish Changing Your Email';
    const text = 'We have sent a confirmation email to your new email address. Please check your inbox and follow the instructions to complete the change.';
    
    return (
      <CreateAccountFormContainer>
        <Header className='create-account-header'>
          <H1>{ header }</H1>
          <p className='subtext'>{ text }</p>
        </Header>
        <Button
          kind={ ButtonKind.Primary }
          disabled={ !succesfulEmailChange }
          onClick={ onClose }
          className='cta'
        >
          Close
        </Button>
      </CreateAccountFormContainer>
    );
  };

  return (
    <AccountCompletionContainer className={ className }>
      { 
        succesfulEmailChange ? renderSuccessfulEmailChange() : renderChangeEmailContent() 
      }
    </AccountCompletionContainer>
  );
};

const ChangeEmailObserver = observer(ChangeEmailBase);
export const ChangeEmail = withTheme(ChangeEmailObserver);
