import styled from 'styled-components';
import { FlexCol } from '../../styles/styles';

interface IMainContainerProps {
  offset: number;
}

export const MainContainerContainer = styled.div<IMainContainerProps>`
  ${ FlexCol }
  min-width: 100%;

  .content-container {
    flex-grow: 1;
    min-height: 100vh;
    margin-top: ${({ offset }) => offset}px;

    &.transparent {
      margin-top: ${({ offset }) => offset - 75}px;
    }
  }
`;
