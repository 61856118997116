import styled from 'styled-components';
import { _H2 } from '../../../styles/components/header';
import { CTAs } from '../../CTAs';
import { Modal } from '../Modal';

export const CustomModalBody = styled.div`
  padding: 0 0 20px;
  margin-top: 20px;
`;

export const CustomModalContainer = styled(Modal)`
  padding: 35px;
  box-size: border-box;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 60px;
  }
`;

export const CustomModalHeader = styled.div`
  ${_H2}
  color: ${({ theme }) => theme.colors.primary};
`;

export const CustomModalCtas = styled(CTAs)``;
