import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ButtonKind } from '../../components/Button/styles';
import { Checkbox } from '../../components/Checkbox';
import { CTAs, CtaType } from '../../components/CTAs';
import { useAnalytics } from '../../contexts/analytics-store';
import { INewsletterUnsubscribeRQ, SubscriptionCode, SubscriptionModel } from '../../models/subscriptions';
import { H1, H5 } from '../../styles/components/header';
import { isValidEmail } from '../../utils/input-validation';
import { BackgroundContainer, FormContainer, LoadingSpinnerContainer, ManageSubscriptionsContainer, ManageSubscriptionsInnerContainer, SubscriptionPreferencesSection, TopSectionContainer } from './styles';
import { useSearchParams } from 'react-router-dom';
import { PillTag } from '../../components/PillTag';
import { PillTagKind, PillTagSize } from '../../components/PillTag/styles';
import { EarthWithClouds } from '../../components/svgs/graphics/EarthWithClouds';
import { LeafA } from '../../components/svgs/graphics/LeafA';
import { theme } from '../../styles/themes';
import { LeafB } from '../../components/svgs/graphics/LeafB';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';

interface IProps {
  className?: string;
}

interface FormDescription {
  header: string;
  content: string | JSX.Element;
}

interface FormDescriptions {
  ResubscribeSuccessful: FormDescription;
  UnsubscribeSuccessful: FormDescription;
  UnsubscribeError: FormDescription;
  Default: FormDescription;
}

const formDescriptions: FormDescriptions = {
  ResubscribeSuccessful: {
    header: 'Welcome Back!',
    content: 'You have been resubscribed to our mailing list.',
  },
  UnsubscribeSuccessful: {
    header: 'We are sorry to see you go!',
    content: 'You have been removed from our mailing list. If there are any emails you would still like to receive, please select them below and click Subscribe.',
  },
  UnsubscribeError: {
    header: 'Error Updating your subscriptions',
    content: 'An error occurred while updating your subscription preferences. Please try again later. If the issue persists, please contact support for assistance.',
  },
  Default: {
    header: 'Updating your subscriptions',
    content: (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    ),
  },
};

const enum RequestStatus {
  Unsubscribing = 'Unsubscribing...',
  UnsubscribeError = 'Error Unsubscribing',
  UnsubscribeSuccessful = 'Unsubscribed Successfully',
  Resubscribing = 'Resubscribing...',
  ResubscribeSuccessful = 'Subscribed Successfully',
}

const NewsletterUnsubscribeBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.Unsubscribing);
  const [requestError, setRequestError] = useState('');
  const [accountUpdatesChecked, setAccountUpdatesChecked] = useState(false);
  const [cardUpdatesChecked, setCardUpdatesChecked] = useState(false);
  const [generalUpdatesChecked, setGeneralUpdatesChecked] = useState(false);
  const [doneGoodChecked, setdoneGoodChecked] = useState(false);

  const renderBackground = () => (
    <BackgroundContainer>
      <EarthWithClouds
        className='svg-icon earth-clouds'
        fill={ theme.colors.white }
        stroke={ theme.colors.black }
      />
      <LeafA
        className='svg-icon leaf-a'
        fill={ theme.colors.white }
        stroke={ theme.colors.black }
      />
      <LeafB
        className='svg-icon leaf-b'
        fill={ theme.colors.white }
        stroke={ theme.colors.black }
      />
      <LeafB
        className='svg-icon leaf-c'
        fill={ theme.colors.white }
        stroke={ theme.colors.black }
      />
      <img alt='Sad elephant mascot crying because you unsubscribed from our emails' src='https://cdn.karmawallet.io/uploads/7z6C_xB3nL-sadwally.png' className='sad-mascot' />
    </BackgroundContainer>
  );

  useEffect(() => {
    setEmail(searchParams.get('email'));
  }, [searchParams]);

  useEffect(() => {
    if (email) {
      onSubmitRequest();
    }
  }, [email]);

  const setSuccessfulRequest = () => {
    analytics.fireEvent('NewsletterUnsubscribe_Success');
  };

  const setUnsuccessfulRequest = (errorMessage: string) => {
    setRequestError(`Failed to update subscription settings. ${errorMessage}`);
    analytics.fireEvent('NewsletterUnsubscribe_Error');
    setTimeout(() => {
      setRequestError('');
    }, 2000);
  };

  const onAccountUpdatesChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccountUpdatesChecked(e.target.checked);
  }, []);

  const onGeneralUpdatesChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGeneralUpdatesChecked(e.target.checked);
  }, []);

  const onCardUpdatesChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCardUpdatesChecked(e.target.checked);
  }, []);

  const onDoneGoodChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setdoneGoodChecked(e.target.checked);
  }, []);

  const getSubscriptionList = useCallback((): SubscriptionCode[] => { 
    const subscriptionList: SubscriptionCode[] = [];

    if (accountUpdatesChecked) subscriptionList.push(SubscriptionCode.AccountUpdates);
    if (cardUpdatesChecked) subscriptionList.push(SubscriptionCode.DebitCardHolders);
    if (generalUpdatesChecked) subscriptionList.push(SubscriptionCode.GeneralUpdates);
    if (doneGoodChecked) subscriptionList.push(SubscriptionCode.DoneGood);

    return subscriptionList;

  },[accountUpdatesChecked, cardUpdatesChecked, generalUpdatesChecked, doneGoodChecked]);

  const onSubmitRequest = useCallback(async (e?: React.FormEvent) => {
    e?.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    const subscriptionList = getSubscriptionList();
    const resubscribe = subscriptionList.length > 0;

    const unsubscribeRQ: INewsletterUnsubscribeRQ = {
      email,
      resubscribeList: subscriptionList,
    };

    if (!validateFields) return;
    analytics.fireEvent('NewsletterUnsubscribe_Submit_Request');

    try {
      const subscriptions = new SubscriptionModel('');
      setRequestStatus(resubscribe ? RequestStatus.Resubscribing : RequestStatus.Unsubscribing);

      await subscriptions.unsubscribe(unsubscribeRQ);
      if (!subscriptions.success) throw new Error(subscriptions.errorMessage);

      setSuccessfulRequest();
      setRequestStatus(resubscribe ? RequestStatus.ResubscribeSuccessful : RequestStatus.UnsubscribeSuccessful);

    } catch (err: any) {
      if (err.message === 'No active subscriptions.' ) {
        setRequestStatus(RequestStatus.UnsubscribeSuccessful);
      } else {
        setUnsuccessfulRequest(err.message);
        setRequestStatus(RequestStatus.UnsubscribeError);
      }
    }
  }, [email, accountUpdatesChecked, generalUpdatesChecked, cardUpdatesChecked, doneGoodChecked]);

  const validateFields = () => (
    !!email && !!isValidEmail(email) && requestStatus !== RequestStatus.Resubscribing && accountUpdatesChecked || cardUpdatesChecked || generalUpdatesChecked || doneGoodChecked
  );

  const renderFormDescription = () => {  
    const renderDescription = (header: string, content: string | JSX.Element) => (
      <>
        <H5>{ header }</H5>
        <p className='resubscribe-usage-description'>{ content }</p>
      </>
    );
  
    switch (requestStatus) {
      case RequestStatus.ResubscribeSuccessful:
        return renderDescription(
          formDescriptions.ResubscribeSuccessful.header,
          formDescriptions.ResubscribeSuccessful.content,
        );
      case RequestStatus.UnsubscribeSuccessful:
        return renderDescription(
          formDescriptions.UnsubscribeSuccessful.header,
          formDescriptions.UnsubscribeSuccessful.content,
        );
      case RequestStatus.UnsubscribeError:
        return renderDescription(
          formDescriptions.UnsubscribeError.header,
          formDescriptions.UnsubscribeError.content,
        );
      default:
        return (
          <>
            <H5>{ formDescriptions.Default.header }</H5>
            { formDescriptions.Default.content }
          </>
        );
    }
  };

  const renderForm = () => ( 
    <form aria-label='Choose to opt-in to specific newsletters'>
      { renderFormDescription() }
      { requestStatus === RequestStatus.UnsubscribeSuccessful && (
        <>
          <Checkbox
            className={ 'newsletter' }
            labelHeader='Karma Wallet Account Updates'
            labelDescription='Get important Karma Wallet account updates and data insights'
            onChange={ onAccountUpdatesChange }
            checked={ accountUpdatesChecked }
            id='account-updates-checkbox'
          />
          <Checkbox
            className={ 'newsletter' }
            labelHeader='General Updates'
            labelDescription='Get general company updates and our weekly newsletter'
            onChange={ onGeneralUpdatesChange }
            checked={ generalUpdatesChecked }
            id='general-updates-checkbox'
          />
          <Checkbox
            className={ 'newsletter' }
            labelHeader='Debit Card Account Updates'
            labelDescription='Get important Karma Wallet Card updates and account insights'
            onChange={ onCardUpdatesChange }
            checked={ cardUpdatesChecked }
            id='card-updates-checkbox'
          />
          <Checkbox
            className={ 'newsletter' }
            labelHeader='DoneGood'
            labelDescription='Subscribe to get sales, promotions, and updates from the DoneGood Marketplace'
            onChange={ onDoneGoodChange }
            checked={ doneGoodChecked }
            id='done-good-updates-checkbox'
          />
          <CTAs
            className={ 'newsletter-unsubscribe-cta' }
            ctas={ [
              {
                id: 'unsubscribe-cta',
                className: 'unsubscribe-cta',
                disabled: !validateFields(),
                onClick: onSubmitRequest,
                text: 'Subscribe',
                ctaType: CtaType.Submit,
                kind: ButtonKind.Primary,
              },
            ] }
          />
        </>
      ) }
      { !!requestError && requestStatus !== RequestStatus.UnsubscribeSuccessful && <p className='submit-error'>{ requestError }</p> } 
    </form>
  );

  return (
    <ManageSubscriptionsContainer className={ className } title='Manage Subscriptions'>
      <ManageSubscriptionsInnerContainer>
        { renderBackground() }
        <SubscriptionPreferencesSection>
          <TopSectionContainer className='top-section'>
            <H1>{ requestStatus }</H1>
          </TopSectionContainer>
          <FormContainer>
            <PillTag kind={ PillTagKind.Grey } size={ PillTagSize.Medium } className='pill-tag'>{ email }</PillTag>
            { renderForm() }
          </FormContainer>
        </SubscriptionPreferencesSection>
      </ManageSubscriptionsInnerContainer>
    </ManageSubscriptionsContainer>
  );
};

export const NewsletterUnsubscribe = observer(NewsletterUnsubscribeBase);
