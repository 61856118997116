import React from 'react';
import { SvgIcon } from '../../SvgIcon';

export const CopyIcon = ({ 
  className = '',
  stroke = '',
  id = '',
}) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}copy-icon icon` }
    id={ id }
    height={ 30 }
    width={ 30 }
    fill='none'
    stroke={ stroke }
  >
    <path d='M18.75 11.25H23.25C24.9069 11.25 26.25 12.5931 26.25 14.25V23.25C26.25 24.9069 24.9069 26.25 23.25 26.25H14.25C12.5931 26.25 11.25 24.9069 11.25 23.25V18.75M18.75 11.25H14.25C12.5931 11.25 11.25 12.5931 11.25 14.25V18.75M18.75 11.25V6.75C18.75 5.09315 17.4069 3.75 15.75 3.75H6.75C5.09315 3.75 3.75 5.09315 3.75 6.75V15.75C3.75 17.4069 5.09315 18.75 6.75 18.75H11.25' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
