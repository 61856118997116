import React, { useCallback, useState } from 'react';
import { ITinyPopoverProps, TinyPopover } from '../TinyPopover';
import { FieldWithToolTipAnchor, FieldWithToolTipContainer, TooltipContainer } from './styles';

interface IProps extends Partial<ITinyPopoverProps> {
  className?: string;
  delay?: number;
  tooltip: string | JSX.Element;
}

export const FieldWithToolTip: React.FC<IProps> = ({
  align = 'start',
  children,
  className = '',
  delay = 1500,
  placement = ['bottom', 'top'],
  tooltip,
}) => {
  const [tooltipTimeout, setTooltipTimeout] = useState<number>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const onFieldMouseEnter = useCallback(() => {
    if (!!tooltipTimeout) return null;

    setTooltipTimeout(window.setTimeout(() => {
      setShowTooltip(true);
    }, delay));
  }, [tooltipTimeout, delay]);

  const onFieldMouseLeave = useCallback(() => {
    window.clearTimeout(tooltipTimeout);
    setTooltipTimeout(null);
    setShowTooltip(false);
  }, [tooltipTimeout]);

  const renderAnchor = useCallback(() => (
    <FieldWithToolTipAnchor>
      { children }
    </FieldWithToolTipAnchor>
  ), []);

  const renderTooltip = () => (
    <TooltipContainer>
      { tooltip }
    </TooltipContainer>
  );

  return (
    <FieldWithToolTipContainer
      className={ className }
      onMouseEnter={ onFieldMouseEnter }
      onMouseLeave={ onFieldMouseLeave }
    >
      <TinyPopover
        align={ align }
        anchor={ renderAnchor() }
        engageOnHover={ true }
        isOpen={ showTooltip }
        placement={ placement }
      >
        { renderTooltip() }
      </TinyPopover>
    </FieldWithToolTipContainer>
  );
};
