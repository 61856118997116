import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const CreditCardIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}credit-card-icon` }
    id={ id }
    width={ 90 }
    height={ 60 }
    fill='none'
  >
    <rect x='1' y='1' width='88' height='58' rx='4' fill='#fff' stroke={ stroke } strokeWidth='2' />
    <rect y='13.3335' width='90' height='12.5' fill={ stroke } />
    <line x1='11.833' y1='38.5835' x2='38.9997' y2='38.5835' stroke={ stroke } strokeWidth='2' strokeLinecap='round' />
    <line x1='11.833' y1='44.8333' x2='28.2854' y2='44.8333' stroke={ stroke } strokeWidth='2' strokeLinecap='round' />
  </SvgIcon>
);
