import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { FlexCol, FlexRow, FlexColCenter } from '../../styles/styles';
import { H4, _H4 } from '../../styles/components/header';

export const ApplyForCardContainer = styled(MainContainer)`
  .content-container {
    min-height: 90vh;
    
    @media (${({ theme }) => theme.breakpoints.lgMax}) {
      min-height: unset;
    }
  }

  .hidden {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  ${FlexCol}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    min-height: 90vh;
  }
  
  .progress-bar {
    margin: 30px auto;
  }

  .left-rail {
    width: 100%;
    
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 30%;
    }
  }

  .loading-inner-wrapper {
    left: calc(50% - 170px);
    max-width: 340px;
    position: absolute;
    top: 30%;
  }

  .spinner-text {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-top: 200px;
    padding: 20px;
    text-align: center;
  }
`;

export const FormWrapper = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.grey1};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 40px 10px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 60px 15px;
    width: 50%;
  }
`;

export const InnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 0px 28px 2px rgba(0, 0, 0, 0.28);
  margin: 0 auto;
  max-width: 100%;
  min-height: 300px;
  padding: 40px 20px;
  width: 600px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-height: 90%;
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    padding: 40px;
  }

  @media (min-width: 2000px) {
    margin-left: 120px;
  }
`;

export const SubText = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 10px;
  text-align: center;
`;

export const Row = styled.div`
  ${FlexRow}
  align-items: flex-start;
  justify-content: space-between;

  .text-field {
    width: 49%;
  }
`;

export const Page = styled.div`
  margin: 0 auto;
  max-width: 800px;

  &.hidden {
    display: none;
  }
`;

export const CTAContainer = styled.div`
  ${FlexColCenter}
  margin: 26px auto 10px;

  button, a {
    width: 100%;
  }

  .previous-button {
    margin-top: 10px;
  }

  .arrow-icon-container {
    ${FlexRow}
    background: ${({ theme }) => theme.colors.white + '1A'};
    border-radius: 6px;
    height: 22px;
    justify-content: center;
    margin-left: 8px;
    width: 22px;

    svg {
      height: auto;
      width: 16px;
    }

    .disabled {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const DescriptionText = styled.p``;

export const StatusText = styled(H4)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const SolutionText = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export const SecurityText = styled.p`
  background: ${({ theme }) => `${theme.colors.gold}40`};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gold};
  font-size: 11px;
  font-weight: 500;
  line-height: 1.5;
  padding: 8px 16px;
`;

export const ResponseText = styled.p`
  margin: 0 auto;
  max-width: 500px;

  .email-text {
    font-weight: 600;
  }
`;

export const ResultHeader = styled.p`
  ${_H4}
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 40px;
  text-align: center;
`;
