import React from 'react';
import { RefreshIcon } from '../../../components/svgs/icons/RefreshIcon';
import { formatCurrency } from '../../../lib/formatters';
import { TransactionModel } from '../../../models/transactions';
import { BottomRow, BottomRowItem, DonationItemContainer, DonationLinkWrapper, DonationWrapper, TopRow, TopRowItem } from './styles';

interface IProps {
  className?: string;
  donation: TransactionModel;
}

export const DonationItem: React.FC<IProps> = ({
  className = '',
  donation,
}) => {
  const renderContent = () => {
    const content = (
      <>
        <TopRow>
          <TopRowItem>{ donation.integrations.rare.projectName }</TopRowItem>
          <TopRowItem>${ formatCurrency(donation.amount) }</TopRowItem>
        </TopRow>
        <BottomRow>
          <BottomRowItem>{ donation.date.format('MMM DD, YYYY') }</BottomRowItem>
          {
            !!donation.integrations?.rare?.offsetsPurchased && (
              <BottomRowItem>
                <RefreshIcon />            
                { donation.integrations?.rare?.offsetsPurchased } tonnes carbon offset
              </BottomRowItem>
            )
          }
        </BottomRow>
      </>
    );

    if (!!donation.integrations.rare?.certificateUrl) {
      return (
        <DonationLinkWrapper
          href={ donation.integrations.rare.certificateUrl }
          target='_blank'
          rel='noreferrer'
        >
          { content }
        </DonationLinkWrapper>
      );
    }

    return (
      <DonationWrapper>
        { content }
      </DonationWrapper>
    );
  };

  return (
    <DonationItemContainer className={ className }>
      { renderContent() }
    </DonationItemContainer>
  );
};
