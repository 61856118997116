import React, { MouseEvent, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { config, useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';
import { Button } from '../../Button';
import { ButtonKind } from '../../Button/styles';
import { XIcon } from '../../svgs/icons/XIcon';
import { ModalContainer, ModalOverlay, ModalScrim } from './styles';
import { LoadingSpinner } from '../../loading/LoadingSpinner';

export interface IModalProps {
  className?: string;
  closeOnOverlayClick?: boolean;
  isOpen?: boolean;
  onClose(): void;
  processing?: boolean;
  children?: React.ReactNode;
}

interface IProps extends IModalProps, IThemeProps { }

const from = {
  opacity: 0,
  top: '20%',
};

const to = {
  opacity: 1,
  top: '25%',
};

const overlayFrom = {
  opacity: 0,
};

const overlayTo = {
  opacity: 0.7,
};

const ModalBase: React.FC<IProps> = ({
  children,
  className = '',
  closeOnOverlayClick,
  isOpen,
  onClose,
  theme,
  processing,
}) => {
  const spring = useSpring({
    config: config.gentle,
    from: from,
    to: isOpen ? to : from,
  });

  const modalSpring = useSpring({
    config: config.gentle,
    from: overlayFrom,
    to: overlayTo,
  });

  const onModalClose = useCallback(() => {
    document.body.classList.remove('no-scroll');
    document.getElementById('root').removeAttribute('inert');
    onClose();
  }, []);

  const onModalClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    document.body.classList[!!isOpen ? 'add' : 'remove']('no-scroll');
    if (!!isOpen) document.getElementById('root').setAttribute('inert', 'true');
    if (!isOpen) document.getElementById('root').removeAttribute('inert');

    return () => {
      document.body.classList.remove('no-scroll');
      document.getElementById('root').removeAttribute('inert');
    };
  }, [isOpen]);

  const modalBody = (
    <ModalOverlay onClick={ !!closeOnOverlayClick ? onClose : null }>
      <ModalScrim style={ modalSpring } />
      <ModalContainer
        className={ `modal ${className}` }
        onClick={ onModalClick }
        style={ spring }
        role='dialog'
        aria-modal='true'
      >
        <Button
          className='modal-close-button'
          kind={ ButtonKind.Blank }
          label='Close Modal'
          onClick={ onModalClose }
        >
          <XIcon stroke={ theme.colors.darkGray1 } />
        </Button>
        <div className='modal-body'>
          { children }
        </div>
        { !!processing ? <div className='processing-state-background'><LoadingSpinner /></div> : null }
      </ModalContainer>
    </ModalOverlay>
  );

  if (!isOpen) return null;

  return (
    <>
      { ReactDOM.createPortal(modalBody, document.querySelector('#modal')) }
    </>
  );
};

export const Modal = withTheme(ModalBase);
