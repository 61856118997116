import React, { ChangeEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { CompaniesSection, ContactSection, FormField, NewEmployerHeaderText, NewEmployerInfoContainer, SelectField, TopSection, WhatCanYouDoSection } from './styles';
import { NewEmployerGraphic } from '../../components/svgs/graphics/NewEmployer';
import { H3 } from '../../styles/components/header';
import { Button } from '../../components/Button';
import { ButtonKind } from '../../components/Button/styles';
import { useAnalytics } from '../../contexts/analytics-store';
import { OffsetsExampleGraphic } from '../../components/svgs/graphics/OffsetsExample';
import { TextFieldKind } from '../../components/TextField';
import { CTAs, CtaType } from '../../components/CTAs';
import { isValidEmail } from '../../utils/input-validation';
import { Label } from '../../styles/components/label';
import { useToaster } from '../../contexts/toaster-store';
import { GroupOffsetInfoModel, InterestCategory, IOffsetInfo } from '../../models/group-offset-info';

interface IProps {
  className?: string;
}

const GroupOffsettingInfoBase: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  const toaster = useToaster();
  const [organizationName, setOrganizationName] = useState('');
  const [organizationNameError, setOrganizationNameError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [opportunity, setOpportunity] = useState(InterestCategory.None);
  const [opportunityError, setOpportunityError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [requestError, setRequestError] = useState('');
  
  const onGetStartedClick = () => {
    document.querySelector('.form-container').scrollIntoView({ behavior: 'smooth' });
    analytics.fireEvent('GroupProgramPage_GetStarted_Click');
  };

  const onOrganizationNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOrganizationNameError('');
    setOrganizationName(e.target.value);
  }, [organizationName]);

  const onFirstNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFirstNameError('');
    setFirstName(e.target.value);
  }, [firstName]);

  const onLastNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLastNameError('');
    setLastName(e.target.value);
  }, [lastName]);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(e.target.value);
  }, [email]);

  const onEmailBlur = useCallback(() => () => {
    if (!isValidEmail(email)) {
      setEmailError('This isn\'t a valid e-mail address format');
      return;
    } 
  }, [email, emailError]);

  const onFieldBlur = useCallback((type: string) => () => {
    switch (type) {
      case 'name':
        if (firstName === '') {
          setFirstNameError('Please fill in your first name');
        }
        break;

      case 'organization':
        if (organizationName === '') {
          setOrganizationNameError('Please fill in your organization name');
        }
        break; 

      case 'last':
        if (lastName === '') {
          setLastNameError('Please fill in your last name');
        }
        break;

      case 'opportunity':
        if (opportunity === InterestCategory.None) {
          setOpportunityError(true);
        }
        break;
    
      default:
        break;
    }
  }, [organizationName, firstName, lastName, organizationNameError, firstNameError, lastNameError, opportunity, opportunityError]);

  const formReset = () => {
    setEmail('');
    setLastName('');
    setFirstName('');
    setOrganizationName('');
    setOpportunity(InterestCategory.None);
  };

  const onOpportunityChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setOpportunityError(false);

    const interestCategory = Object.values(InterestCategory).includes(e.target.value as InterestCategory) ? e.target.value as InterestCategory : InterestCategory.None;
    setOpportunity(interestCategory);
  }, [opportunity]);

  const setSuccessfulRequest = () => {
    setSuccess(true);
    toaster.push({ message: 'Your request for more information was sent successfully!' });
    analytics.fireEvent('GroupProgramPage_Submit_Request_Success');

    formReset();
    setTimeout(() => {
      setSuccess(false);
    }, 800);
  };

  const setUnsuccessfulRequest = () => {
    setSuccess(false);
    setRequestError('Failed to submit form, please try again.');
    analytics.fireEvent('GroupProgramPage_Submit_Request_Failed');
    setTimeout(() => {
      setRequestError('');
    }, 2000);
  };

  const onSubmitRequestClick = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    const userRequestInfo: IOffsetInfo = {
      firstName,
      lastName,
      email,
      organization: organizationName,
      interestCategory: opportunity,
    };

    try {
      if (!validateFields) return;
      analytics.fireEvent('GroupProgramPage_Submit_Request');

      try {
        const groupOffsetInfo = new GroupOffsetInfoModel('');
        await groupOffsetInfo.requestGroupOffsetInfo(userRequestInfo);
        if (!groupOffsetInfo.success) {
          throw new Error('Request for group offset info failed');
        }
        setSuccessfulRequest();
      } catch (err) {
        throw new Error('Request for group offset infor failed');
      }
    } catch (err: any) {
      setUnsuccessfulRequest();
    }
  }, [firstName, lastName, opportunity, email, organizationName, success]);

  const validateFields = () => (
    !!email && !!firstName && !!lastName && !!opportunity && (opportunity !== InterestCategory.None) && !!organizationName && !emailError && !!isValidEmail(email)
  );

  const renderForm = ()=> (
    <form aria-label='Request more information on offset program'>
      <FormField
        id='organization-name-input'
        fieldKind={ TextFieldKind.Pill }
        label='Organization Name'
        maxLength={ 75 }
        value={ organizationName }
        onChange={ onOrganizationNameChange }
        errorText={ organizationNameError }
        onBlur={ onFieldBlur('organization') }
      />
      <FormField
        id='first-name-input'
        fieldKind={ TextFieldKind.Pill }
        label='Your First Name'
        maxLength={ 75 }
        value={ firstName }
        onChange={ onFirstNameChange }
        errorText={ firstNameError }
        onBlur={ onFieldBlur('name') }
      />
      <FormField
        id='last-name-input'
        fieldKind={ TextFieldKind.Pill }
        label='Your Last Name'
        maxLength={ 75 }
        value={ lastName }
        onChange={ onLastNameChange }
        errorText={ lastNameError }
        onBlur={ onFieldBlur('last') }
      />
      <FormField
        id='email-input'
        fieldKind={ TextFieldKind.Pill }
        label='Your Email'
        maxLength={ 75 }
        value={ email }
        errorText={ emailError }
        onChange={ onEmailChange }
        onBlur = { onEmailBlur() }
      />
      <Label className={ 'select-label pill-label' } htmlFor='pet-select'>Which oppportunity interests you?</Label>
      <SelectField
        id='opportunity-select'
        className={ !!opportunityError ? 'error' : '' }
        onChange={ onOpportunityChange }
        required={ true }
        onBlur={ onFieldBlur('opportunity') }
        value={ opportunity }
      >
        <option value=''>--Please choose an option--</option>
        <option value={ InterestCategory.EmployerBenefit }>Employee Benefits Program</option>
        <option value={ InterestCategory.NonProfit }>Collaborating as a Non-Profit</option>
        <option value={ InterestCategory.SocialMediaCommunity }>Collaborating as an Influencer</option>
        <option value={ InterestCategory.Other }>Other</option>
      </SelectField>
      { !!opportunityError && <p className='field-error'>This field is required</p> }
      <CTAs
        className='ctas-container'
        ctas={ [
          {
            id: 'opportunity-cta',
            className: 'opportunity-cta',
            disabled: !validateFields(),
            onClick: onSubmitRequestClick,
            text: 'Submit Request',
            ctaType: CtaType.Submit,
            kind: ButtonKind.Secondary,
          },
        ] }
      />
      { !!requestError && <p className='submit-error'>{ requestError }</p> } 
    </form>
  );

  return (
    <NewEmployerInfoContainer className={ className } title='Group Carbon Offset Program Info'>
      <TopSection>
        <NewEmployerHeaderText>Karma Wallet for Your Team</NewEmployerHeaderText>
        <div className='info-graphic-container'>
          <NewEmployerGraphic className='new-employer-graphic' />
          <div className='new-employer-info-section'>
            <H3 className='new-employer-subheader'>Unite your team through shared values</H3>
            <p className='new-employer-info-text'>Karma Wallet partners with organizations to provide their team with a data-driven technology platform that drives social and climate impact. As more people look for values-based businesses to support, Karma Wallet makes integrating sustainability easy and simple for your organization.</p>
            <Button 
              className='get-started-button'
              kind={ ButtonKind.Secondary }
              onClick={ onGetStartedClick }
            >
              Get Started
            </Button>
          </div>
        </div>
      </TopSection>
      <CompaniesSection>
        <H3 className='companies-subheader'>Companies driving change with our group program</H3>
        <img src='https://cdn.karmawallet.io/uploads/bQpAV_ePd8-employer-page-companies-hires.png' alt='Company logos' />
      </CompaniesSection>
      <WhatCanYouDoSection>
        <div className='what-can-you-do-info-container'>
          <H3 className='what-can-you-do-subheader'>What Can You Do</H3>
          <div className='paragraph-container'>
            <p className='what-can-you-do-text-1'>Your team gets a personalized Karma Score, a Monthly Impact Report, and can earn cashback for shopping with better brands. Invite your team to join with one click of a personalized link. The group dashboard makes it easy to quantify impact as a company, and share your goals and successes in ESG/impact reports & social channels.</p>
            <OffsetsExampleGraphic className='offsets-example-graphic' />
            <p className='what-can-you-do-text-2'>Group admins can purchase team-matched carbon offsets to motivate and unite employees. Published monthly statements make it easy to see your team’s impact, the amount you have chosen to offset, and make a tax-deductible donation within minutes, amplifying your team’s impact.</p>
          </div>
        </div>
      </WhatCanYouDoSection>
      <ContactSection>
        <div className='form-container'>
          <H3 className='contact-us-subheader'>Contact Us</H3>
          <p>Learn how we can turn your sustainability commitments into actions</p>
          { renderForm() }
          { /* <a
            href='https://drive.google.com/file/d/1wBZl-Ln8DxX4FIGPT78fmkU2XRrrD8Nc/view?usp=share_link'
            onClick={ onViewBenefitProgramPDF }
            target='_blank'
            rel='noreferrer' 
          >
            Karma Wallet Group Benefit Program PDF
          </a> */ }
        </div>
      </ContactSection>
    </NewEmployerInfoContainer>
  );
};

export const GroupOffsettingInfo = observer(GroupOffsettingInfoBase);
