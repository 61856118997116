import { getRecoil, setRecoil } from 'recoil-nexus';
import { appVersionAtom } from './atom';
import { IAppVersionAtom, IAppVersionRequestData } from './types';
import { WebServiceHelper } from '../../lib/webServiceHelperRecoil';

const getAppVersionData = () => getRecoil(appVersionAtom);

const setAppVersionAtom = (data: IAppVersionAtom) => {
  setRecoil(appVersionAtom, data);
};

const setAppVersionSuccess = (data: IAppVersionRequestData) => {
  setAppVersionAtom({
    appVersion: data.version,
    error: '',
    loaded: true,
    loading: false,
  });
};

const setAppVersionError = (errorMessage: string) => {
  setAppVersionAtom({
    appVersion: '',
    error: errorMessage,
    loaded: true,
    loading: false,
  });
};

const setAppVersionLoading = () => {
  const data = getAppVersionData();
  setAppVersionAtom({
    ...data,
    loading: true,
  });
};

export const loadAppVersion = async () => {
  try {
    if (getAppVersionData().loaded || getAppVersionData().loading) return;
    setAppVersionLoading();

    const result = await WebServiceHelper.sendRequest({
      path: '/misc/app-version',
      method: 'GET',
    });
  
    if (result.success) {
      setAppVersionSuccess(result.data);
    } else {
      setAppVersionError(result.error || 'Error loading app version');
    }
  } catch (error: any) {
    setAppVersionError(error);
  }
};
