import React, { createContext } from 'react';

interface IDoneGoodVersionContext {
  isDoneGood: boolean;
}

interface IDoneGoodVersionStoreProps {
  isDoneGood: boolean;
  children: React.ReactNode;
}

const DoneGoodVersionContext = createContext<IDoneGoodVersionContext>(null);

export const useDoneGoodVersion = () => React.useContext(DoneGoodVersionContext);

export const DoneGoodVersionStore: React.FC<IDoneGoodVersionStoreProps> = ({
  children,
  isDoneGood,
}) => (
  <DoneGoodVersionContext.Provider value={ { isDoneGood } }>
    { children }
  </DoneGoodVersionContext.Provider>
);
