import React, { useCallback, useRef } from 'react';
import ReactSlickSlider, { Settings as ReactSlickSettings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselContainer, SectorCarouselLocation } from './styles';
import { ArrowButtonKind } from '../ArrowButton';
import { BlankArrowButton } from '../BlankArrowButton';
import { useAppData } from '../../contexts/app';

interface ISliderProps extends ReactSlickSlider {
  innerSlider: {
    state: {
      currentSlide: number;
    }
  }
}

interface IProps {
  className?: string;
  settings?: ReactSlickSettings;
  children?: React.ReactNode;
  location?: SectorCarouselLocation;
}

const NextArrow: React.FC<any> = (props: any) => (
  <BlankArrowButton
    { ...props }
    kind={ ArrowButtonKind.Next }
    disabled={ props.className.includes('slick-disabled') }
  />
);

const PrevArrow: React.FC<any> = (props: any) => (
  <BlankArrowButton
    { ...props }
    kind={ ArrowButtonKind.Previous }
    disabled={ props.className.includes('slick-disabled') }
  />
);

const defaultSettings: ReactSlickSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 3,
};

export const SectorCarousel: React.FC<IProps> = ({
  children,
  className = '',
  settings = {},
  location,
}) => {
  const appData = useAppData();
  const carouselRef = useRef<ISliderProps>(null);

  const getSettings = useCallback(() => {
    const __settings = { ...defaultSettings, ...settings };

    if (appData.windowWidth > 1750) { 
      __settings.slidesToShow = location === 'accountHub' ? 10 : 8;
    }

    if (appData.windowWidth < 1200) { 
      __settings.slidesToShow = location === 'accountHub' ? 3 : 8;
    }
    if (appData.windowWidth < 1100) { 
      __settings.slidesToShow = location === 'accountHub' ? 3 : 6;
    }
    if (appData.windowWidth < 600) { 
      __settings.slidesToShow = location === 'accountHub' ? 3 : 5;
    }
    if (appData.windowWidth < 500) { 
      __settings.slidesToShow = location === 'accountHub' ? 2 : 4;
      __settings.dots = true;
      __settings.arrows = false;
    }
    __settings.nextArrow = <NextArrow />;
    __settings.prevArrow = <PrevArrow />;
    return __settings;
  }, [settings, appData.windowWidth]);

  return (
    <CarouselContainer className={ className } location={ location }>
      <ReactSlickSlider ref={ carouselRef } { ...getSettings() }>
        { children }
      </ReactSlickSlider>
    </CarouselContainer>
  );
};
