import styled from 'styled-components';
import { FlexCenter, FlexHorizontalCenter, PrimaryFont, ScreenReaderOnly } from '../../styles/styles';
import { TextXSBoldLight } from '../../styles/components/text';

export const CheckboxContainer = styled.label`
  ${ FlexHorizontalCenter }
  position: relative;

  &:hover {
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 100%;
    z-index: 1;
    
    &:focus {
      outline: none;
    }

    &:focus-visible:not(:active):not(:disabled) + .checkbox {
      outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
      outline-offset: 1px;
    }

    &:focus,
    &:checked + .checkbox {
      ${({ theme }) => `
        border: 2px solid ${theme.colors.darkGray3};
        box-shadow: 0px 2px 0px ${theme.colors.darkGray3};
        transition: .25s ease-in-out;
      `}
    }

    &:disabled + .checkbox {
      background: ${({ theme }) => theme.colors.lightGray2};

      svg {
        stroke: ${({ theme }) => theme.colors.disabled};
      }
    }

    border: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
  }

  .checkbox {
    ${ FlexCenter }
    position: relative;
    min-width: 26px;
    max-width: 26px;
    min-height: 26px;
    max-height: 26px;
    margin: 2px 0 2px 2px;
    border: 2px solid ${({ theme }) => theme.colors.disabled};
    transition: .25s ease-in-out;

    &.square {
      border-radius: 5px;
    }

    &.circle {
      border-radius: 50%;
    }

    svg {
      width: 16px;
      margin-top: 6px;
    }
  }

  .label {
    ${ PrimaryFont }
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkGray1};
    
    &:hover {
      cursor: pointer;
    }

    &.sr-only {
      ${ScreenReaderOnly}
    }
  }

  .label-header {
    font-size: 16px;
  }

  .label-description {
    ${TextXSBoldLight}
  }
`;
