import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from '../../../contexts/analytics-store';
import { useJoinGroupModal } from '../../../contexts/join-group';
import { ButtonKind } from '../../Button/styles';
import { InfoModal } from '../InfoModal';
import { Claps } from '../../svgs/graphics/Claps';
import { removeScrollInert } from '../../../utils/removeScrollInert';

interface IProps {
  className?: string;
}

const JoinGroupConfirmationModalBase: React.FC<IProps> = ({
  className = '',
}) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const joinGroup = useJoinGroupModal();

  const onCloseClick = useCallback(() => {
    analytics.fireEvent('JoinedGroupConfirmationClick_Close');
    removeScrollInert();
    joinGroup.closeConfirmation();
  }, [joinGroup]);

  const onGoToAccountClick = useCallback(() => {
    analytics.fireEvent('JoinedGroupConfirmationClick_GoToAccount');
    removeScrollInert();
    navigate('/account');
    joinGroup.closeConfirmation();
  }, [joinGroup]);

  const onGoToGroupClick = useCallback(() => {
    analytics.fireEvent('JoinedGroupConfirmationClick_GoToGroupDashboard');
    removeScrollInert();
    navigate(`/groups/${joinGroup.group._id}`);
    joinGroup.closeConfirmation();
  }, [joinGroup]);

  if (!joinGroup.group) return null;

  return (
    <InfoModal
      className={ className }
      graphic={ <Claps /> }
      title='Congrats!'
      body={ <>You have successfully joined the <strong>{ joinGroup.group?.name }</strong> group.</> }
      isOpen={ !!joinGroup.group }
      ctas={ [
        {
          id: 'go-to-group-cta',
          text: 'Go to Group Dashboard',
          kind: ButtonKind.Primary,
          onClick: onGoToGroupClick,
        },
        {
          id: 'go-to-account-cta',
          text: 'Go Back to Account',
          kind: ButtonKind.PrimaryGhost,
          onClick: onGoToAccountClick,
        },
      ] }
      onClose={ onCloseClick }
    />
  );
};

export const JoinGroupConfirmationModal = observer(JoinGroupConfirmationModalBase);
