import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../styles/styles';

interface IRatingScoreBar {
  location: string;
}

export const CompanyRatingGraphicContainer = styled.div`
  ${FlexCol}
  margin-top: 19px;
  position: relative;
  width: 100%;
`;

export const Graphic = styled.div`
  ${FlexRow}
  gap: 2px;
  width: 100%;

  .range {
    height: 10px;
    opacity: 0.25;
    width: 12.5%;
  }

  .negative {
    background-color: ${({ theme }) => theme.colors.negative};
  }

  .neutral {
    background-color: ${({ theme }) => theme.colors.neutral};
  }

  .positive {
    background-color: ${({ theme }) => theme.colors.positive};
  }

  .start {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
  }

  .end {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
  }

  .selected {
    opacity: unset;
  }
`;

export const GraphicLabels = styled.div`
  ${FlexRow}
  gap: 2px;
  margin-top: 2px;
  width: 100%;

  .label {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    margin-left: -2px;
    opacity: 0.15;
    width: 12.5%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      ${FlexRow}
      font-size: 12px;
    }
  }

  .last {
    ${FlexRow}
    justify-content: space-between;
  }
`;

export const GraphicBarWrapper = styled.div<IRatingScoreBar>`
  ${FlexCol}
  align-items: center;
  gap: 5px;
  left: calc(${({ location }) => location} - 11px);
  position: absolute;
  top: -25px;
  ${({ location }) => location === '100%' && 'margin-left: -9px;'}

  .bar-number {
    background: ${({ theme }) => theme.colors.dark};
    border-radius: 2px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    padding: 0px 6px;
  }
`;

export const Bar = styled.div`
  width: 6px;
  height: 16px;
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.dark};
`;
