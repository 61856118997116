import styled from 'styled-components';
import { Section } from '../../styles/components/containers';
import { BasicContainer, FlexHorizontalCenter, FlexCol } from '../../styles/styles';
import { _H4 } from '../../styles/components/header';

export const BackedByResearchSectionContainer = styled(Section)`
  background: ${({ theme }) => theme.colors.green};

  .header {
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const ContentWrapperContainer = styled.div`
  ${BasicContainer}
  ${FlexCol}
  text-align: center;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.light};
  position: relative;

  .content-wrapper {
    ${FlexCol}
    align-items: center;
    width: 100%;
  }

  .view-sources-button {
    margin: 15px auto 0;
  }

  .subtext {
    ${_H4}
    margin-bottom: 5px;
  }

  .clipboard-graphic {
    display: none;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      display: block;
      position: absolute;
      bottom: -22px;
      right: -22px;
      transform: rotate(20deg);
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 730px;
  }
`;

export const ListItemsContainer = styled.ul`
  ${FlexCol}
  align-items: flex-start;
  max-width: 220px;
  margin: 0 auto;
  padding: 0;

  .list-item {
    ${FlexHorizontalCenter}
    margin: 10px 0 5px;

    .item-text {
      margin-left: 10px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
   width: 50%;
  }
`;
