import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { WebServiceHelper } from '../lib/webServiceHelper';
import { BaseModel } from './base';
import { CollectionModel } from './collection';

type PrivateJobsFields = '_busy' |
'_jobs' |
'_init';

type PrivateJobFields = '_busy' |
'_job';

export interface IJobPosting {
  _id?: string;
  title: string;
  instructions: string;
  department: string;
  jobLocation: string; // could not use `location` as is a reserved keyword on backend
  applicationUrl: string;
  description: string;
  published: boolean;
}

export class JobPostingModel extends BaseModel implements IJobPosting {
  private _busy = false;
  private _job: IJobPosting = null;

  constructor (job?: IJobPosting) {
    super();
    makeObservable<JobPostingModel, PrivateJobFields>(this, {
      _busy: observable,
      _job: observable,
      _id: computed,
      applicationUrl: computed,
      busy: computed,
      title: computed,
      instructions: computed,
      description: computed,
      department: computed,
      jobLocation: computed,
      published: computed,
      load: action.bound,
      save: action.bound,
    });

    this._job = job;
  }

  get _id() { return this._job?._id; }
  get applicationUrl() { return this._job?.applicationUrl || ''; }
  get busy() { return this._busy; }
  get title() { return this._job?.title || ''; }
  get instructions() { return this._job?.instructions || ''; }
  get description() { return this._job?.description || ''; }
  get department() { return this._job?.department || ''; }
  get jobLocation() { return this._job?.jobLocation || ''; }
  get published() { return this._job?.published || false; }

  load = async () => {
    if (!!this.busy || !!this.description) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IJobPosting>({
      path: `/job-postings/${this._id}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._job = result.value;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });
    }
  };

  save = async (data: IJobPosting) => {
    if (this.busy) return;
    this._busy = true;

    if (!data.title) throw new Error('A job title is required.');
    if (!data.description) throw new Error('A job description is required.');
    if (!data.department) throw new Error('A job department is required.');
    if (!data.jobLocation) throw new Error('A job location is required.');

    const result = await this.webServiceHelper.sendRequest<IJobPosting>({
      path: `/job-postings${!!this._id ? `/${this._id}` : ''}`,
      method: !!this._id ? 'PUT' : 'POST',
      data,
    });

    if (result.success) {
      runInAction(() => {
        this._job = result.value;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  static load = async (jobId: string) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IJobPosting>({
      path: `/job-postings/${jobId}`,
      method: 'GET',
    });

    if (result.success) {
      return new JobPostingModel(result.value);
    } else {
      throw new Error(result.error);
    }
  };
}

export class JobPostingsModel extends BaseModel {
  private _busy = false;
  private _jobs: CollectionModel<IJobPosting, JobPostingModel> = null;

  constructor () {
    super();
    makeObservable<JobPostingsModel, PrivateJobsFields>(this, {
      _busy: observable,
      _jobs: observable,
      busy: computed,
      jobs: computed,
      _init: action.bound,
      addJob: action.bound,
    });
    this._init();
  }

  get busy() { return !!this._busy; }
  get jobs() { return this._jobs; }

  addJob = async (newJob: JobPostingModel) => {
    if (!this._busy) {
      this._busy = true;
      this.jobs.unshift(newJob);
    }
  };

  private _init = () => {
    this._jobs = new CollectionModel<IJobPosting, JobPostingModel>(
      '/job-postings',
      (jobPosting: IJobPosting) => new JobPostingModel(jobPosting),
    );
  };
}
