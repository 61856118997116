import React, { useCallback } from 'react';
import { Categories, ISubcategoryGroup, UNSDGModel } from '../../../models/unsdgs';
import { H3 } from '../../../styles/components/header';
import { ButtonKind } from '../../Button/styles';
import { UNSDGsPickerContainer, SubcategorySectionContainer, UNSDGsWrapper, UNSDGWrapper, DescriptionContainer } from './styles';
import { Earth } from '../../svgs/graphics/Earth';
import { People } from '../../svgs/graphics/People';

interface IProps {
  className?: string;
  subcategory: ISubcategoryGroup;
  activeUNSDG: UNSDGModel;
  onUNSDGClick: (unsdg: UNSDGModel) => () => void;
}

export const SubcategorySection: React.FC<IProps> = ({
  className = '',
  subcategory,
  activeUNSDG,
  onUNSDGClick,
}) => {
  const renderCategoryIcon = () => {
    if (subcategory.category === Categories.People) return <People className='category-graphic' />;
    return <Earth className='category-graphic' />;
  };

  const renderUNSDGsPicker = useCallback(() => (
    <UNSDGsPickerContainer>
      { renderCategoryIcon() }
      <UNSDGsWrapper>
        {
          subcategory.unsdgs.map(unsdg => (
            <UNSDGWrapper
              key={ `unsdg-${unsdg.goalNum}` }
              kind={ ButtonKind.Blank }
              onClick={ onUNSDGClick(unsdg) }
              className={ unsdg === activeUNSDG ? 'active' : '' }
            >
              <img src={ unsdg.img } />
            </UNSDGWrapper>
          ))
        }
      </UNSDGsWrapper>
    </UNSDGsPickerContainer>
  ), [subcategory, activeUNSDG, subcategory, onUNSDGClick]);

  const renderDescriptionSection = () => (
    <DescriptionContainer>
      <H3>{ activeUNSDG.title }</H3>
      <p className='unsdg-description-text'>{ activeUNSDG.description }</p>
    </DescriptionContainer>
  );

  return (
    <SubcategorySectionContainer className={ className }>
      { renderUNSDGsPicker() }
      { renderDescriptionSection() }
    </SubcategorySectionContainer>
  );
};
