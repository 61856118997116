import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const OnePercentPlanetWhite: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}one-percent-white` }
    id={ id }
    width={ 104 }
    height={ 44 }
    fill='none'
  >
    <mask id='mask0_135_884' maskUnits='userSpaceOnUse' x='0' y='0' width='104' height='44'>
      <path d='M103.543 0H0V43.3338H103.543V0Z' fill='white' />
    </mask>
    <g mask='url(#mask0_135_884)'>
      <path d='M0 21.6669C0 9.69667 9.69656 0 21.6667 0C33.6368 0 43.3331 9.69667 43.3331 21.6669C43.3331 33.6371 33.6368 43.3339 21.6667 43.3339C9.69656 43.3339 0 33.6371 0 21.6669Z' fill='white' />
      <path d='M0.165039 19.0155C1.27266 21.2405 2.49688 23.3877 3.65308 25.603C4.29434 26.837 5.82946 29.1105 5.49911 30.5874C5.33394 31.3452 4.23606 31.695 4.29434 32.5209C4.35262 33.3176 5.674 34.0463 6.20837 34.4544C7.17998 35.1928 8.0933 36.0091 8.99687 36.8252C10.1822 37.9035 10.7361 39.1569 11.0178 40.5465C12.6987 41.4984 14.5253 42.2271 16.4393 42.7034C17.7121 41.3821 18.9364 40.0897 20.4812 39.0208C21.3168 38.4379 22.2884 37.8356 22.7547 36.9029C23.3863 35.6592 22.677 34.9013 22.3078 33.8325C21.9775 32.8706 21.6471 32.5014 20.5978 31.9185C18.8392 30.9274 16.76 30.5679 14.8265 30.0433C13.0776 29.5672 11.2899 29.1105 9.52153 28.7219C8.49164 28.4984 7.06341 28.4012 6.33469 27.5559C5.29509 26.3026 4.50808 24.097 4.0806 22.5424C3.88625 21.8235 3.12842 19.171 4.07088 18.5783C4.53722 18.2868 5.68371 18.8018 5.99463 18.5685C6.28612 18.3451 6.14038 17.6747 6.14038 17.3346C6.15009 15.1485 7.59778 13.9243 9.45354 12.9527C10.9984 12.1462 12.6889 11.6798 14.331 11.126C14.9431 10.922 16.0021 10.7471 16.4879 10.2807C17.615 9.21195 16.8765 6.14169 16.8765 4.65512C16.8668 2.45928 14.4864 2.61474 12.7667 1.89575C6.01406 4.94661 1.09777 11.3689 0.165039 19.0155Z' fill='#C9C9C9' />
      <path d='M30.0024 4.98437C31.7124 6.19887 34.8895 8.86108 33.2378 11.1541C32.7714 11.8051 31.7901 12.8155 31.9941 13.7385C32.3925 15.5652 34.5009 14.6713 35.4725 14.2146C36.1818 13.8843 37.0272 13.6317 37.8238 13.7288C38.2416 13.7774 39.5531 14.0883 39.7572 14.4964C40.214 15.4097 38.0278 16.3133 37.5226 16.6728C36.1135 17.6833 34.8506 18.8104 34.0831 20.3746C33.6556 21.2491 32.3439 23.6975 32.8977 24.7566C33.228 25.3784 34.6952 25.5047 35.2684 25.6796C36.2789 25.9906 37.3088 26.35 38.29 26.7484C39.0187 27.0496 41.0882 27.6617 41.4768 28.4293C42.0405 29.5175 39.6698 30.4308 39.0479 31.0235C37.9502 32.0534 35.9778 35.3083 36.8329 37.1253C40.8455 33.1999 43.3329 27.7297 43.3329 21.6669C43.3329 11.6885 36.5898 3.28406 27.4082 0.767578C27.5636 2.47761 28.5838 3.9739 30.0024 4.98437Z' fill='#C9C9C9' />
      <path d='M47.4336 11.6208H54.5749V13.7875H49.9112V15.3032H54.1282V17.363H49.9112V20.5499H47.4336V11.6208Z' fill='white' />
      <path d='M55.459 16.1102V16.0811C55.459 13.516 57.5285 11.4368 60.278 11.4368C63.0275 11.4368 65.0775 13.4869 65.0775 16.0519V16.0811C65.0775 18.6461 63.008 20.7254 60.2585 20.7254C57.4993 20.7254 55.459 18.6753 55.459 16.1102ZM62.5516 16.1102V16.0811C62.5516 14.7888 61.6188 13.6715 60.2585 13.6715C58.908 13.6715 58.0045 14.7694 58.0045 16.0519V16.0811C58.0045 17.3733 58.9372 18.4907 60.2878 18.4907C61.6481 18.5004 62.5516 17.4025 62.5516 16.1102Z' fill='white' />
      <path d='M66.4277 11.6218H70.6542C72.0141 11.6218 72.9664 11.9813 73.5593 12.5934C74.0836 13.1084 74.3462 13.7885 74.3462 14.6727V14.7018C74.3462 16.0621 73.6174 16.9754 72.5099 17.4418L74.6376 20.5509H71.7812L69.9836 17.8498H69.9543H68.8956V20.5509H66.418V11.6218H66.4277ZM70.5375 15.9066C71.3829 15.9066 71.8588 15.4985 71.8588 14.8476V14.8184C71.8588 14.1189 71.3439 13.7594 70.518 13.7594H68.8956V15.9066H70.5375Z' fill='white' />
      <path d='M80.2236 13.7892H77.5518V11.6226H85.3828V13.7892H82.7012V20.5516H80.2236V13.7892Z' fill='white' />
      <path d='M86.5303 11.6226H88.9981V14.9649H92.1752V11.6226H94.6527V20.5516H92.1752V17.1607H88.9981V20.5516H86.5303V11.6226Z' fill='white' />
      <path d='M96.2949 11.6226H103.475V13.7309H98.7433V15.0815H103.028V17.0344H98.7433V18.4433H103.543V20.5516H96.2949V11.6226Z' fill='white' />
      <path d='M47.375 22.3284H51.4754C53.8945 22.3284 55.4491 23.572 55.4491 25.6901V25.7193C55.4491 27.9734 53.71 29.1588 51.3393 29.1588H50.0277V31.889H47.375V22.3284ZM51.2811 27.0795C52.2233 27.0795 52.7967 26.584 52.7967 25.8359V25.8067C52.7967 24.9906 52.2233 24.5533 51.2714 24.5533H50.0277V27.0795H51.2811Z' fill='white' />
      <path d='M56.5664 22.3284H59.2188V29.5668H63.8533V31.889H56.5664V22.3284Z' fill='white' />
      <path d='M68.2928 22.2612H70.8481L74.9189 31.8899H72.082L71.3825 30.1798H67.6905L67.0102 31.8899H64.2217L68.2928 22.2612ZM70.6148 28.12L69.5463 25.3995L68.4676 28.12H70.6148Z' fill='white' />
      <path d='M75.8428 22.3284H78.3204L82.2554 27.3807V22.3284H84.8789V31.889H82.5565L78.4662 26.6423V31.889H75.8428V22.3284Z' fill='white' />
      <path d='M86.5693 22.3284H94.2646V24.5825H89.1928V26.0302H93.7883V28.1191H89.1928V29.6348H94.3325V31.889H86.5693V22.3284Z' fill='white' />
      <path d='M98.0243 24.651H95.1484V22.3289H103.543V24.651H100.677V31.8895H98.0243V24.651Z' fill='white' />
      <path d='M101.27 31.0543C101.27 30.5976 101.639 30.209 102.105 30.209C102.571 30.209 102.941 30.5879 102.941 31.0446V31.0543C102.941 31.5109 102.571 31.8899 102.105 31.8899C101.639 31.8899 101.27 31.5109 101.27 31.0543ZM102.805 31.0543C102.805 30.6656 102.503 30.3547 102.105 30.3547C101.717 30.3547 101.406 30.6754 101.406 31.0543C101.406 31.4332 101.707 31.7538 102.105 31.7538C102.503 31.7538 102.805 31.4332 102.805 31.0543ZM101.765 30.617H102.163C102.367 30.617 102.513 30.7045 102.513 30.8988C102.513 31.0251 102.445 31.1223 102.338 31.1611L102.533 31.4526H102.26L102.095 31.2H101.998V31.4526H101.765V30.617ZM102.154 31.0154C102.232 31.0154 102.28 30.9765 102.28 30.9183C102.28 30.86 102.232 30.8211 102.154 30.8211H102.008V31.0154H102.154Z' fill='white' />
      <path d='M17.5757 38.5345V12.3496L14.4277 12.3205L14.4569 8.08426L18.5376 4.45044H26.0383V38.5345H17.5757Z' fill='#001C20' />
      <path d='M36.7258 15.8096H38.4844L30.906 26.1864H29.1475L36.7258 15.8096Z' fill='#001C20' />
      <path d='M28.1572 18.2954C28.1572 19.6363 29.2551 20.7342 30.596 20.7342C31.9368 20.7342 33.0347 19.6363 33.0347 18.2954C33.0347 16.9546 31.9368 15.8567 30.596 15.8567C29.2454 15.8567 28.1572 16.9449 28.1572 18.2954ZM29.5175 18.2954C29.5175 17.7027 30.0033 17.2169 30.596 17.2169C31.1886 17.2169 31.6744 17.7027 31.6744 18.2954C31.6744 18.8881 31.1886 19.3739 30.596 19.3739C30.0033 19.3642 29.5175 18.8881 29.5175 18.2954Z' fill='#001C20' />
      <path d='M34.5107 23.7473C34.5107 25.0881 35.6087 26.1861 36.9494 26.1861C38.2902 26.1861 39.3883 25.0881 39.3883 23.7473C39.3883 22.4065 38.2902 21.3086 36.9494 21.3086C35.599 21.3086 34.5107 22.4065 34.5107 23.7473ZM35.8711 23.7473C35.8711 23.1546 36.3568 22.6688 36.9494 22.6688C37.542 22.6688 38.028 23.1546 38.028 23.7473C38.028 24.34 37.542 24.8258 36.9494 24.8258C36.3568 24.8258 35.8711 24.34 35.8711 23.7473Z' fill='#001C20' />
    </g>
  </SvgIcon>
);
