import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const BriefcaseIcon: FC<IProps> = ({ className = '', id = '', fill }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }briefcase-icon-graphic` }
    id={ id }
    width={ 90 }
    height={ 90 }
    fill={ fill }
  >
    <path d='M17.9 64.6842V71.8421H73.1V64.6842H17.9ZM28.25 25.3158V18.1579C28.25 16.1895 28.9262 14.5038 30.2786 13.1008C31.6287 11.7003 33.2525 11 35.15 11H55.85C57.7475 11 59.3724 11.7003 60.7248 13.1008C62.075 14.5038 62.75 16.1895 62.75 18.1579V25.3158H73.1C74.9975 25.3158 76.6225 26.0173 77.9749 27.4202C79.325 28.8208 80 30.5053 80 32.4737V71.8421C80 73.8105 79.325 75.4962 77.9749 76.8992C76.6225 78.2997 74.9975 79 73.1 79H17.9C16.0025 79 14.3787 78.2997 13.0286 76.8992C11.6762 75.4962 11 73.8105 11 71.8421V32.4737C11 30.5053 11.6762 28.8208 13.0286 27.4202C14.3787 26.0173 16.0025 25.3158 17.9 25.3158H28.25ZM17.9 53.9474H73.1V32.4737H62.75V36.1421C62.75 37.1561 62.4188 37.9912 61.7564 38.6474C61.0963 39.3035 60.2775 39.6316 59.3 39.6316C58.3225 39.6316 57.5037 39.288 56.8436 38.6008C56.1812 37.9161 55.85 37.0667 55.85 36.0526V32.4737H35.15V36.1421C35.15 37.1561 34.82 37.9912 34.1598 38.6474C33.4974 39.3035 32.6775 39.6316 31.7 39.6316C30.7225 39.6316 29.9025 39.288 29.2402 38.6008C28.58 37.9161 28.25 37.0667 28.25 36.0526V32.4737H17.9V53.9474ZM35.15 25.3158H55.85V18.1579H35.15V25.3158Z' fill={ fill } />
  </SvgIcon>
);
