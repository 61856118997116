import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';
import { theme } from '../../../../styles/themes';

interface IProps {
  className?: string;
  id?: string;
  fill?: string;
}

export const VisaLogo: FC<IProps> = ({
  className = '',
  id = '',
  fill = theme.colors.primary,
}) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }visa-logo` }
    id={ id }
    width={ 82 }
    height={ 29 }
    fill={ fill }
  >
    <path d='M31.1515 0.995507L20.591 28.0712H13.7003L8.50361 6.46406C8.18823 5.13415 7.91346 4.64634 6.95297 4.08408C5.388 3.17009 2.80042 2.31258 0.523438 1.78113L0.67874 0.995507H11.7697C13.1842 0.995507 14.4529 2.00449 14.7755 3.75546L17.5207 19.4243L24.3039 0.995507H31.1515ZM58.1503 19.2317C58.1766 12.0867 48.9516 11.6913 49.0161 8.5C49.0329 7.52696 49.8978 6.49487 51.7781 6.23042C52.7147 6.09949 55.2856 5.99679 58.2077 7.44223L59.3521 1.70154C57.7848 1.08793 55.7635 0.5 53.2547 0.5C46.8132 0.5 42.2784 4.18164 42.2402 9.4525C42.1995 13.3498 45.4776 15.5218 47.9481 16.8209C50.4855 18.1483 51.3385 18.9981 51.329 20.1868C51.3122 22.0019 49.3029 22.8055 47.4297 22.8363C44.1516 22.8928 42.2497 21.8864 40.7325 21.1264L39.5498 27.0597C41.0742 27.8119 43.8816 28.4641 46.8013 28.5C53.6466 28.5 58.1264 24.8646 58.1503 19.2368M75.162 28.0764H81.1901L75.9289 1.00064H70.3619C69.1123 1.00064 68.0563 1.7837 67.588 2.98524L57.8087 28.0738H64.6516L66.0134 24.0327H74.3759L75.162 28.0738V28.0764ZM67.8914 18.4846L71.3224 8.31515L73.2959 18.4846H67.8914ZM40.4649 1.00321L35.0771 28.079H28.5592L33.9494 1.00321H40.4673H40.4649Z' fill={ fill } />
  </SvgIcon>
);
