import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const HomeElectricity: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}home-electricity-graphic` }
    id={ id }
    height={ 98 }
    width={ 93 }
  >
    <path d='M91.2881 42.563L46.2717 2L1.25537 42.563C1.1395 42.6976 1.058 42.8738 1.0215 43.0685C0.984994 43.2632 0.995146 43.4674 1.05067 43.6547C1.1062 43.8419 1.20451 44.0034 1.33281 44.1181C1.4611 44.2329 1.61345 44.2957 1.77 44.2982H10.02V96.9203H82.539V44.2982H90.7968C90.9514 44.2899 91.1006 44.223 91.2254 44.1061C91.3501 43.9891 91.4449 43.8274 91.4975 43.6413C91.5502 43.4553 91.5584 43.2534 91.5211 43.0614C91.4838 42.8694 91.4027 42.6959 91.2881 42.563Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' />
    <path d='M57.9823 49.9465H34.2834C32.5059 49.9465 31.0649 51.3854 31.0649 53.1602V58.6974C31.0649 60.4723 32.5059 61.9111 34.2834 61.9111H57.9823C59.7598 61.9111 61.2007 60.4723 61.2007 58.6974V53.1602C61.2007 51.3854 59.7598 49.9465 57.9823 49.9465Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M46.1334 75.706C49.1552 75.706 51.6048 73.1262 51.6048 69.9438C51.6048 66.7615 49.1552 64.1816 46.1334 64.1816C43.1117 64.1816 40.6621 66.7615 40.6621 69.9438C40.6621 73.1262 43.1117 75.706 46.1334 75.706Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M57.0026 61.9077V68.3351C57.0026 68.7613 56.833 69.17 56.5312 69.4713C56.2294 69.7727 55.8201 69.942 55.3933 69.942H36.8729C36.4461 69.942 36.0368 69.7727 35.735 69.4713C35.4332 69.17 35.2637 68.7613 35.2637 68.3351V61.9077H57.0026Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M39.5149 36.7109C39.9392 36.7109 40.346 36.8792 40.646 37.1787C40.946 37.4783 41.1145 37.8845 41.1145 38.3081V49.9466H35.8926V40.328C35.8926 39.3687 36.2742 38.4487 36.9535 37.7703C37.6329 37.092 38.5542 36.7109 39.5149 36.7109Z' fill='#F0E05D' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
    <path d='M56.373 49.9465H51.1495V38.3097C51.1495 37.8861 51.3181 37.4798 51.618 37.1803C51.918 36.8808 52.3249 36.7125 52.7491 36.7125C53.2248 36.7125 53.6958 36.8061 54.1353 36.9878C54.5748 37.1696 54.9741 37.436 55.3105 37.7719C55.6468 38.1078 55.9137 38.5065 56.0957 38.9453C56.2778 39.3842 56.3714 39.8545 56.3714 40.3295V49.9465H56.373Z' fill='#F0E05D' stroke='#323232' strokeWidth='2' strokeMiterlimit='10' />
  </SvgIcon>
);
