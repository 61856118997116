import styled from 'styled-components';
import { MainGroupContainer } from '../../../components/MainGroupContainer';

export const StatementsContainer = styled(MainGroupContainer)`
  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    .statements-section {
      padding: 10px 0px 30px; 
    }
  }
`;
