import { useEffect } from 'react';
import { loadAppVersion } from '../../models/misc/api';

export const InitializeApp: React.FC = () => {
  const loadAppData = async () => {
    await loadAppVersion();
  };

  useEffect(() => {
    loadAppData();
  }, []);
  
  return null;
};
