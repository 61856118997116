import { PersonaTemplateIdEnumValues, PersonaTemplateIdEnum } from '../constants';
import { CompanyModel, ICompanySector, IDataSource, IEvaluatedUnsdg, IGroupedEvaluatedUnsdg, IGroupedEvaluatedUnsdgSubcategory } from '../models/companies';
import { ISectorInfo } from '../models/compare-companies';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const debounce = (func: (...args: any[]) => void, wait: number, immediate?: boolean) => {
  let timeout: number;
  // tslint:disable-next-line: only-arrow-functions space-before-function-paren
  return function (...args: any[]) {
    // eslint-disable-next-line prefer-rest-params
    const later = () => {
      timeout = null;
      if (!immediate) {
        func(...args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
};

export const getSectorAverage = (company: CompanyModel) => {
  const primarySector = getPrimaryTier1Sector(company);
  const sectorAverage = primarySector.averageScores.avgScore.toFixed(2);
  return sectorAverage;
};

export const getNumberOfEvaluatedUnsdgs = (company: CompanyModel) => {
  let number = 0;
  const data = company.evaluatedUnsdgs;
  data.forEach( (arrayItem: IGroupedEvaluatedUnsdg) => {
    arrayItem.subcategories.forEach((subcategories: IGroupedEvaluatedUnsdgSubcategory) => {
      number += subcategories.unsdgs.filter ( (item: IEvaluatedUnsdg) => item.evaluated === true).length;
    });
  });

  return number;
};

export const getPrimaryTier1Sector = (company: CompanyModel) => {
  const primarySector = company.sectors.find(s => s.primary)?.sector;

  if (!primarySector) return null;
  return primarySector;

  // return primarySector.tier === 1
  //   ? primarySector
  //   : company.sectors.find(s => s.sector._id === primarySector?.parentSectors[0].toString()).sector;
};

export const formatter = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

export const removeSpecialCharacters = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '');

export const getUniqueSectors = (sectors: ICompanySector[]) => {
  const uniqueIds = new Set<string>();
  return sectors.filter((e: ICompanySector) => uniqueIds.has(e.sector._id)
    ? false
    : uniqueIds.add(e.sector._id));
}; 

export const getRandomItemFromArray = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)];

export const sortByPrimarySector = (sectors: ISectorInfo[]) => sectors.sort((a, b) => {
  if (a.primary && !b.primary) return -1;
  if (!a.primary && b.primary) return 1;
  return 0;
});

export const getUniqueCompanyDataSources = (dataSources: IDataSource[]): IDataSource[] => dataSources.reduce((accumulator, dataSource) => {
  const dataSourceExists = accumulator.find(obj => obj.name === dataSource.name);
  if (!dataSourceExists) {
    accumulator.push(dataSource);
  }
  return accumulator;
}, []);

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const removeNonDigits = (str: string) => str.replace(/\D/g, '');

export const capitalizeFirstLetters = (str: string) => str
  .split(' ')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  .join(' ');

export const getTemplateNameFromId = (templateId: PersonaTemplateIdEnumValues): string => {
  let currentTemplate = '';
  Object.entries(PersonaTemplateIdEnum).forEach(([key, value]) => {
    if (value === templateId) {
      currentTemplate = key;
    }
  });
  return currentTemplate;
};

export const replaceSpecialCharsWithDot = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '.');  
