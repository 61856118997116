import { createGlobalStyle, css } from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';

export const PrimaryFont = css`
  font-family: AvenirNext;  
`;

export const SecondaryFont = css`
  font-family: OrangeSquash;
`;

export const ParagraphText = css`
  ${PrimaryFont}
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`;

export const BlogParagraphText = css`
  ${PrimaryFont}
  ${ParagraphText}
  line-height: 32px;
  margin-top: 10px;
`;

export const InvertedText = css`
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.white};

  // Fallback for browsers that don't support this feature
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
  body.no-scroll {
    overflow: hidden;
  }

  * {
    ${PrimaryFont}
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.black};
    line-height: 1.3em;
    box-sizing: border-box;

    &:focus-visible {
      outline: 1px dashed ${({ theme }) => theme.colors.black};
      outline-offset: 1px;
    }
  }

  p {
    margin-bottom: 1rem;
    line-height: 23px;
  }

  ul {
    margin-bottom: 1rem;
    padding-left: 40px;
    list-type: disc;
  }

  .bold {
    font-weight: bold;
  }
`;

export const AbsoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FlexCenter = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexColCenter = css`
  ${FlexCol}
  justify-content: center;
`;

export const FlexRow = css`
  display: flex;
  flex-direction: row;
`;

export const FlexRowReverse = css`
  display: flex;
  flex-direction: row-reverse;
`;

export const FlexHorizontalCenter = css`
  display: flex;
  flex-direction: row; // need to override other styles that may apply a flex-direction
  align-items: center;
`;

export const NoScrollBar = css`
  &::-webkit-scrollbar {
    display: none;
  }

  // works in FF
  scrollbar-width: none;
`;

export const AccountHubBasicContainer = css`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray2};
  padding: 20px;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    padding: 32px;
  }
`;

export const BasicContainer = css`
  background: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  padding: 32px;
`;

export const BasicContainerBoxShadow = css`
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.05);
`;

export const TruncateText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// you will need to add css for the number of lines you want - eg: line-clamp/-webkit-line-clamp: 2;
export const LineClamp = css`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const BoxShadow = css`
  box-shadow: 0 4px 8px -8px ${({ theme }) => theme.colors.darkGray3};
`;

export const InsetBoxShadow = css`
  box-shadow: 0 4px 8px -8px inset ${({ theme }) => theme.colors.darkGray3};
`;

export const InsetAndOuterBoxShadow = css`
  box-shadow: 0 4px 8px -8px ${({ theme }) => theme.colors.darkGray3}, inset 0 4px 8px -8px ${({ theme }) => theme.colors.darkGray3};
`;

export const AnchorStyles = css`
  color: ${({ theme }) => theme.colors.darkGreen1};
  text-decoration: none;

  &:focus-visible,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }
`;

export const ScreenReaderOnly = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const FocusVisible = css`
  &:focus-visible {
    outline: ${({ theme }) => `1px dashed ${theme.colors.darkGray1}`};
    outline-offset: 1px;
  }
`;
