import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';

export const HomeContainer = styled(MainContainer)`
  section {
    padding: 90px 15px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding: 130px 15px;
    }
  }

  &.logged-in {
    section {
      padding: 30px 15px;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        padding: 60px 15px;
      }
    }
  }

  h2 {
    margin-bottom: 30px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 40px;
    }
  }
`;
