import { useEffect } from 'react';
import { useSocket } from '../contexts/socket-client';
import { SocketClient } from '../models/socket-client';

const useSocketRoom = (roomType: string, room: string, dependencies?: any[]): SocketClient => {
  const socket = useSocket();

  useEffect(() => {
    if (!roomType || !room) return;
    socket.emit(`room/join/${roomType}`, { room });
    return () => {
      socket.emit(`room/leave/${roomType}`, { room });
    };
  }, dependencies ? [socket.isConnected, ...dependencies] : [socket.isConnected]);

  return socket;
};

export default useSocketRoom;
