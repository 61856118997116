import React from 'react';
import { AccountHubUserMenuContainer } from './styles';
import { AccountAvatar } from '../AccountAvatar';
import { observer } from 'mobx-react';
import { ChevronDirection, ChevronIcon } from '../../../components/svgs/icons/ChevronIcon';
import { IThemeProps } from '../../../styles/themes';
import { withTheme } from 'styled-components';
import { ButtonKind } from '../../../components/Button/styles';
import { Button } from '../../../components/Button';
import { XIcon } from '../../../components/svgs/icons/XIcon';
import { UserSessionModel } from '../../../models/users';

interface IProps extends IThemeProps {
  className?: string;
  onClick?(): void;
  isOpen: boolean;
  user: UserSessionModel;
}

const AccountHubUserMenuBase: React.FC<IProps> = ({
  className = '',
  theme,
  onClick,
  isOpen,
  user,
}) => (
  <AccountHubUserMenuContainer className={ className }>
    <Button
      kind={ ButtonKind.Blank }
      onClick={ onClick }
    >
      <AccountAvatar userName={ user.name } />
      <div className='icon-container'>
        {
          !isOpen
            ? (
              <ChevronIcon
                className='chevron-icon'
                stroke={ theme.colors.dark }
                direction={ ChevronDirection.Down }
              />
            )
            : (
              <XIcon
                className='x-icon'
                stroke={ theme.colors.dark }
              />
            )
        }
      </div>
    </Button>    
  </AccountHubUserMenuContainer>
);

const AccountHubUserMenuObserver = observer(AccountHubUserMenuBase);
export const AccountHubUserMenu = withTheme(AccountHubUserMenuObserver);
