import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection, Section } from '../../styles/components/containers';
import { FlexCenter, FlexCol, FlexHorizontalCenter, FlexRow, PrimaryFont, TruncateText } from '../../styles/styles';
import { ComparisonSquareContainer } from '../../components/ComparisonSquare/styles';
import { _H6 } from '../../styles/components/header';

interface IAltScrollControlsProps {
  position: 'top' | 'bottom';
}

export const AltScrollControlsContainer = styled.div<IAltScrollControlsProps>`
  ${FlexHorizontalCenter};
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 0 10px;
  ${({ position }) => position === 'top' ? 'bottom: calc(100% - 50px)' : 'top: calc(100% - 30px)'};

  button:last-child {
    justify-content: flex-end;
  }

  svg {
    width: 12px;
    height: 12px;
    stroke: ${({ theme }) => theme.colors.darkGray1};
  }

  span {
    ${TruncateText}
    display: inline-block;
    max-width: 90%;
    padding: 0 5px;
    font-size: 12px;
  }

  @media (min-width: 650px) {
    display: none;
  }
`;

export const CompareCompaniesContainer = styled(MainContainer)`
  background: ${({ theme }) => theme.colors.light};

  .m-our-pick {
    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      display: none;
    }
  }

  .sector-text-skeleton {
    width: 300px;
    height: 20px;
    margin: 0 auto;
  }

  .edit-choices-button {
    width: 100%;
    height: 40px;
    background: ${({ theme }) => theme.colors.lightGreen2};
    border-radius: 0;

    &.in-comparison-container {
      background: none;
      height: auto;
      color: ${({ theme }) => theme.colors.darkGray3};
    }

    svg {
      margin-right: 10px;
    }

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      display: none;
    }
  }
`;

export const CompareInnerWrapper = styled(MaxBoundarySection)`
  min-height: 100vh;
  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    padding-right: 0;
    padding-left: 0;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const ComparePageHeader = styled.div`
  margin: 24px 15px 20px;
  text-align: center;

  .title {
    font-size: 24px;
  }

  p {
    font-size: 14px ;
    font-weight: 500;
    margin: 20px 0;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin: 40px 15px;

    p {
      font-size: 16px;
    }
  }
`;

export const OverallRatingsContainer = styled.div`
  ${FlexRow}
  align-items: center;
  justify-content: space-between;
  margin: 20px auto 30px;
  max-width: 960px;
  padding: 0px 15px;
  width: 100%;
  
  H4 {
    ${_H6}
  }

  Button {
    ${FlexCenter}
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 60px;
    color:  ${({ theme }) => theme.colors.primary};
    font-size: 12px;
    font-weight: 600;
    gap: 8px;
    line-height: normal;
    padding: 3px 16px;

    svg {
      width: 19px;
      height: 12px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin: 40px auto;

    H4 {
      font-size: 24px;
    }

    Button {
      font-size: 16px;
      padding: 10px 28px;

      svg {
        width: 16px;
      }
    }
  }
`;

export const CompareCompaniesWrapper = styled.div`
  ${FlexHorizontalCenter}
  align-items: stretch;
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
`;

export const CompanyContainer = styled(ComparisonSquareContainer)`
  width: 70%;
`;

export const ComparisonContainer = styled.div`
  ${FlexCol}
  width: 100%;

  .primary-sector-text {
    ${PrimaryFont}
    padding: 20px 15px 0;
    font-weight: bold;
    text-align: center;
  }

  .error-comparison {
    text-align: center;
    margin: 30px auto;
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const ControlsContainer = styled.div`
  ${FlexCol}
  position: relative;
  display: none;
  width: 100%;
  height: 650px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 350px;
    min-width: 350px;
    height: 735px;
    display: flex;
  }

  .dt-company-picker {
    padding-top: 10px;
    height: 840px;
  }

  .error-text {
    color: ${({ theme }) => theme.colors.disabled};
    padding: 30px;
    margin-top: 40px;
    text-align: center;
  }
`;

export const EmptyContainer = styled.div`
  ${FlexCenter}
  position: relative;
  margin-bottom: 2px;
  padding: 0 10px;
  background: ${({ theme }) => theme.colors.light};

  & > div {
    max-width: 60%;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray2};
  }
`;

export const ComparisonCompanyCardsContainer = styled.div`
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MaxReached = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.disabled};
  text-align: center;

  &.max-reached {
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const OurPickSection = styled(Section)`
  ${FlexCenter}
  padding-bottom: 0;
  background: ${({ theme }) => theme.colors.light};
`;

export const CompareCompaniesCardContainer = styled.div`
  ${FlexRow}
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: center;
  margin: auto;
  max-width: 960px;
  padding: 0px 15px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    gap: 24px;
  }
`;
