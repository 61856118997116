import React from 'react';
import { SentimentSubcategoryCardContainer } from './styles';
import { selectedSentimentSubcategoryAtom } from '../../../../../models/sentiment/atom';
import { useRecoilState } from 'recoil';
import { SentimentSubcategory } from '../../../../../models/sentiment/types';
import { ChevronDirection, ChevronIcon } from '../../../../../components/svgs/icons/ChevronIcon';
import { IThemeProps } from '../../../../../styles/themes';
import { withTheme } from 'styled-components';
import { useAppData } from '../../../../../contexts/app';
import { useAnalytics } from '../../../../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
  title: SentimentSubcategory;
  icon?: string;
  isSummary?: boolean;
  mobileOpen?: boolean;
}

const summaryIcon = 'https://cdn.karmawallet.io/uploads/summary-icon.svg';

const SentimentSubcategoryCardBase: React.FC<IProps> = ({
  className,
  title,
  icon,
  isSummary,
  mobileOpen,
  theme,
}) => {
  const appData = useAppData();
  const analytics = useAnalytics();
  const [selectedSentiment, setSelectedSentiment] = useRecoilState(selectedSentimentSubcategoryAtom);

  const onCardClick = () => {
    setSelectedSentiment(title as SentimentSubcategory);
    analytics.fireEvent('CompanyPage_Sentiment_Subcategory_Click', title);
  };

  return (
    <SentimentSubcategoryCardContainer
      className={ className }
      onClick={ onCardClick }
      isSummary={ isSummary }
      active={ selectedSentiment === title }
    >
      <img
        src={ isSummary ? summaryIcon : icon }
        alt={ title }
        aria-hidden={ true }
      />
      <div className='sentiment-subcategory-card-title'>{ title }</div>
      {
        selectedSentiment === title && appData.windowWidth < 992 &&
          <ChevronIcon
            stroke={ theme.colors.dark }
            className='chevron-icon'
            direction={ mobileOpen ? ChevronDirection.Up : ChevronDirection.Down }
          />
      }
    </SentimentSubcategoryCardContainer>
  );
};

export const SentimentSubcategoryCard = withTheme(SentimentSubcategoryCardBase);
