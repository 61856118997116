import styled from 'styled-components';

export enum SectorCarouselLocation {
  AccountHub = 'accountHub',
}

interface IProps {
  location?: SectorCarouselLocation,
}

export const CarouselContainer = styled.div<IProps>`
  .slick-slide {
    border: 1px solid transparent;
  }

  .slick-next,
  .slick-prev {
    &:before {
      display: none;
    }

    .next,
    .previous {
      display: flex !important; // important required here to override inline style applied by react-slick
    }
  }

  .slick-next {
    left: 100%;
    right: unset;
  }

  .slick-prev {
    left: unset;
    right: 100%;
  }

  .slick-next, .slick-prev {
    ${({ location }) => location === 'accountHub' ? 'top: 49%;' : ''}
  }

  svg {
    ${({ location, theme }) => location === 'accountHub' ? `stroke: ${theme.colors.primary}!important;` : ''}
  }

  .slick-dots {
    ${({ location }) => location !== 'accountHub' ? 'padding-top: 10px;' : ''}
    position: static;

    li {
      margin: 0 5px;

      button {
        background: ${({ theme }) => theme.colors.lightGray1};
        border-radius: 50%;
        border: none;
        height: 10px;
        outline: none;
        padding: 0px;
        width: 10px;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;
