import React from 'react';
import { HeaderContainer, TeamCardsContainer, TeamSectionContainer, TeamSectionInnerContainer } from './styles';
import { H2M } from '../../../styles/components/header';

// our team headshots
import AnushkaHeadshot from '../../../../public/assets/images/people/team-photos/anushka.png';
import JayantHeadshot from '../../../../public/assets/images/people/team-photos/jayant.png';
import JohnDHeadshot from '../../../../public/assets/images/people/team-photos/john.png';
import JulietHeadshot from '../../../../public/assets/images/people/team-photos/juliet.png';
import KedarHeadshot from '../../../../public/assets/images/people/team-photos/kedar.png';
import RyenHeadshot from '../../../../public/assets/images/people/team-photos/ryen.png';
import SaraHeadshot from '../../../../public/assets/images/people/team-photos/sara.png';
import SenadHeadshot from '../../../../public/assets/images/people/team-photos/senad.png';
import AndyHeadshot from '../../../../public/assets/images/people/team-photos/andy.png';
import BrittainyHeadshot from '../../../../public/assets/images/people/team-photos/brittainy.png';
import ElizabethHeadshot from '../../../../public/assets/images/people/team-photos/elizabeth.png';
import DaleyHeadshot from '../../../../public/assets/images/people/team-photos/daley.png';
import DiegoHeadshot from '../../../../public/assets/images/people/team-photos/diego.png';
import JordanWallHeadshot from '../../../../public/assets/images/people/team-photos/jordan.png';
import AceHeadshot from '../../../../public/assets/images/people/team-photos/ace.png';
import JenHeadshot from '../../../../public/assets/images/people/council/jen.png';
import StuartHeadshot from '../../../../public/assets/images/people/council/stuart.png';
import NaoshiHeadshot from '../../../../public/assets/images/people/council/naoshi.png';
import NatalieHeadshot from '../../../../public/assets/images/people/council/natalie.png';
import BrendanHeadshot from '../../../../public/assets/images/people/council/brendan.png';
import DougHeadshot from '../../../../public/assets/images/people/council/doug.png';
import JoshHeadshot from '../../../../public/assets/images/people/council/josh.png';
import { IPersonRedirect, TeamMemberCard } from './TeamMemberCard';

interface IProps {
  className?: string;
}

export interface TeamMember {
  id: string;
  name: string;
  title: string;
  redirect: IPersonRedirect;
  img: string;
}

const ourTeam: TeamMember[] = [
  {
    id: 'jayant',
    name: 'Jayant Khadilkar',
    title: 'CEO, Co-Founder',
    redirect: { linkedIn: 'https://www.linkedin.com/in/jayant-khadilkar-9345052/' },
    img: JayantHeadshot,
  },
  {
    id: 'kedar',
    name: 'Kedar Karkare',
    title: 'Co-Founder & Partnerships',
    redirect: { linkedIn: 'https://www.linkedin.com/in/kedar-karkare/' },
    img: KedarHeadshot,
  },
  {
    id: 'jordan',
    name: 'Jordan Wall',
    title: 'Director of Product',
    redirect: { linkedIn: 'https://www.linkedin.com/in/jordanwilderwall/' },
    img: JordanWallHeadshot,
  },
  {
    id: 'diego',
    name: 'Diego Betancourt',
    title: 'Software Engineer',
    redirect: { linkedIn: 'https://www.linkedin.com/in/diego-a-betancourt/' },
    img: DiegoHeadshot,
  },
  {
    id: 'ryen',
    name: 'Ryen Parno',
    title: 'Business Development',
    redirect: { linkedIn: 'https://www.linkedin.com/in/ryen-parno-515874130/' },
    img: RyenHeadshot,
  },
  {
    id: 'sara',
    name: 'Sara Wall',
    title: 'Sr. Software Engineer',
    redirect: { linkedIn: 'https://www.linkedin.com/in/saramorganwall/' },
    img: SaraHeadshot,
  },
  {
    id: 'senad',
    name: 'Senad Zenkovic',
    title: 'Software Engineer',
    redirect: { linkedIn: 'https://www.linkedin.com/in/senad-zenkovic/' },
    img: SenadHeadshot,
  },
  {
    id: 'brittainy',
    name: 'Brittainy Smith',
    title: 'Sr. Project Manager',
    redirect: { linkedIn: 'https://www.linkedin.com/in/brittainynsmith/' },
    img: BrittainyHeadshot,
  },
  {
    id: 'elizabeth',
    name: 'Elizabeth Rodgers',
    title: 'Director of Compliance',
    redirect: { linkedIn: 'https://www.linkedin.com/in/elizabeth-j-rodgers/' },
    img: ElizabethHeadshot,
  },
  {
    id: 'john',
    name: 'John D\'Alessandro',
    title: 'Sr. Software Engineer',
    redirect: { linkedIn: 'https://www.linkedin.com/in/dalessandrojohn/' },
    img: JohnDHeadshot,
  },
  {
    id: 'juliet',
    name: 'Juliet Payne',
    title: 'Marketing Manager',
    redirect: { linkedIn: 'https://www.linkedin.com/in/juliet-n-payne/' },
    img: JulietHeadshot,
  },
  {
    id: 'anushka',
    name: 'Anushka Nair',
    title: 'Data Scientist',
    redirect: { linkedIn: 'https://www.linkedin.com/in/anushka-nair-8391b2187/' },
    img: AnushkaHeadshot,
  },
  {
    id: 'andy',
    name: 'Andy Delgado',
    title: 'Software Engineer',
    redirect: { linkedIn: 'https://www.linkedin.com/in/adelgado0723/' },
    img: AndyHeadshot,
  },
  {
    id: 'daley',
    name: 'Daley Crowley',
    title: 'Sr. Marketing Coordinator',
    redirect: { linkedIn: 'https://www.linkedin.com/in/daley-crowley/' },
    img: DaleyHeadshot,
  },
  {
    id: 'ace',
    name: 'Ace Alexander',
    title: 'Customer Support Specialist',
    redirect: { linkedIn: 'https://www.linkedin.com/in/acecilyalexander/' },
    img: AceHeadshot,
  },
];

const advisors: TeamMember[] = [
  {
    id: 'doug',
    name: 'Douglas Sabo',
    title: 'Payments & Banking',
    redirect: { linkedIn: 'https://www.linkedin.com/in/douglas-sabo/' },
    img: DougHeadshot,
  },
  {
    id: 'jen',
    name: 'Jen Millard',
    title: 'Loyalty & Rewards',
    redirect: { linkedIn: 'https://www.linkedin.com/in/jenmillard/' },
    img: JenHeadshot,
  },
  {
    id: 'naoshi',
    name: 'Naoshi Yamauchi',
    title: 'Digital Strategy & Marketing',
    redirect: { linkedIn: 'https://www.linkedin.com/in/naoshi-yamauchi-3b6b143/' },
    img: NaoshiHeadshot,
  },
  {
    id: 'josh',
    name: 'Josh Kampel',
    title: 'Fintech & Fundraising',
    redirect: { linkedIn: 'https://www.linkedin.com/in/joshkampel/' },
    img: JoshHeadshot,
  },
  {
    id: 'brendan',
    name: 'Brendan Lewis',
    title: 'PR & Communications',
    redirect: { linkedIn: 'https://www.linkedin.com/in/brendanplewis/' },
    img: BrendanHeadshot,
  },
  {
    id: 'stuart',
    name: 'Stuart Take',
    title: 'Operations & Governance',
    redirect: { linkedIn: 'https://www.linkedin.com/in/stuart-take-0a04614b/' },
    img: StuartHeadshot,
  },
  {
    id: 'natalie',
    name: 'Natalie Birdwell',
    title: 'Business Development & Partnerships',
    redirect: { linkedIn: 'https://www.linkedin.com/in/nataliebirdwell/' },
    img: NatalieHeadshot,
  },
];

export const TeamSection: React.FC<IProps> = ({
  className,
}) => {
  const teamCards = ourTeam.map((teamMember) =>
    <TeamMemberCard
      className='team-member-card'
      redirect={ teamMember.redirect }
      key={ teamMember.id }
      teamMember={ teamMember }
    />);

  const advisorCards = advisors.map((advisorMember) =>
    <TeamMemberCard
      className='team-member-card'
      redirect={ advisorMember.redirect }
      key={ advisorMember.id }
      teamMember={ advisorMember }
    />);
  
  return (
    <TeamSectionContainer className={ className }>
      <TeamSectionInnerContainer>
        <HeaderContainer>
          <H2M>Meet the Team</H2M>
          <p>Our team is like our family. Meet the humans building the products you use.</p>
        </HeaderContainer>
        <TeamCardsContainer>
          { teamCards }
        </TeamCardsContainer>
        <H2M>Advisors</H2M>
        <TeamCardsContainer className='advisors'>
          { advisorCards }
        </TeamCardsContainer>
      </TeamSectionInnerContainer>
    </TeamSectionContainer>
  );
};
