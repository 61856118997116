import styled from 'styled-components';
import { MaxBoundaryContainerLarge } from '../../styles/components/containers';
import { FlexCenter, FlexHorizontalCenter } from '../../styles/styles';
import { Link } from 'react-router-dom';

export const GlobalHeaderContainer = styled.div`
  ${FlexCenter};
  align-items: stretch;
  position: relative;
  width: 100%;
  height: 75px;
  padding: 0 15px;
  z-index: 100;
  color: #fff;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 8px 25px -2px rgba(0, 0, 0, 0.05);

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    padding: 0 20px;
  }
`;

export const DeveloperSiteLogoLink = styled(Link)`
  text-decoration: none;

  &:hover,
  &:focus-visible {
    color: ${({ theme }) => theme.colors.lightGray2};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.lightGray2};
  }

  .logo-text {
    font-size: 16px;
    font-weight: 800;
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.primary};
    transform: translatey(2px); /* to align right with the svg */
  }

  .logo-container {
    ${FlexHorizontalCenter}
    justify-content: flex-start;
    height: 100%;
  }
`;

export const GlobalHeaderInner = styled(MaxBoundaryContainerLarge)`
  ${FlexHorizontalCenter};
  justify-content: space-between;
  max-width: unset;
  padding-right: 0px;

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    padding-right: 40px;
  }

  .logo {
    outline: none;

    &:focus-visible {
      outline: ${({ theme }) => `1px dashed ${theme.colors.darkGray1}`};
      outline-offset: 1px;
    }

    svg {
      height: 40px;
      width: auto;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {      
      svg {
        height: 50px;
      }
    }
  }
`;

export const NavContainer = styled.div`
  ${FlexHorizontalCenter}
  align-self: stretch;
  flex-grow: 1;

  .nav-link {
    ${FlexCenter}
    align-self: stretch;
    position: relative;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-weight: 600;
    }

    &.active {
      pointer-events: none;

      &:before {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 12px;
        height: 7px;
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
