import { makeObservable, observable, computed, runInAction, action } from 'mobx';
import { BaseModel } from './base';
import { SubscriptionCode } from './subscriptions';
import { IUrlParam } from './users';

type PrivateFields = '_isValidToken' |
'_loading' |
'_success' |
'_tokenVerified' |
'_verifyingToken';

export interface IVisitorBase {
  email: string;
  params?: IUrlParam[];
}

export interface INewsletterSignup extends IVisitorBase {
  subscriptionCodes: SubscriptionCode[];
}

export interface ICreateAccountRequest extends IVisitorBase {
  sscid?: string;
  sscidCreatedOn?: string;
  xTypeParam?: string;
  groupCode?: string;
}

export class VisitorModel extends BaseModel {
  private _isValidToken = false;
  private _loading = false;
  private _success = false;
  private _tokenVerified = false;
  private _verifyingToken = false;

  constructor() {
    super();
    makeObservable<VisitorModel, PrivateFields>(this, {
      _isValidToken: observable,
      _loading: observable,
      _success: observable,
      _tokenVerified: observable,
      _verifyingToken: observable,
      isValidToken: computed,
      loading: computed,
      success: computed,
      tokenVerified: computed,
      verifyingToken: computed,
      createSignupVisitor: action.bound,
      subscribeToNewsletter: action.bound,
      verifyCreateAccountToken: action.bound,
    });
  }

  get isValidToken() { return this._isValidToken; }
  get loading() { return this._loading; }
  get success() { return this._success; }
  get tokenVerified() { return this._tokenVerified; }
  get verifyingToken() { return this._verifyingToken; }

  public subscribeToNewsletter = async (email: string, subscriptionCodes: SubscriptionCode[], params?: IUrlParam[]) => {
    if (this._loading) return;

    this._loading = true;
    const data: INewsletterSignup = { email, subscriptionCodes };
    if (!!params.length) data.params = params;

    const result = await this.webServiceHelper.sendRequest<INewsletterSignup>({
      path: '/visitor/newsletter-signup',
      method: 'POST',
      data: data,
    });

    if (result.success) {
      runInAction(() => {
        this._success = true;
        this._loading = false;
      });
    } else {
      runInAction(() => {
        this._success = false;
        this._loading = false;
      });
      
      throw new Error(result.error);
    }
  };

  public createSignupVisitor = async (info: ICreateAccountRequest) => {
    if (this._loading) return;
    this._loading = true;

    const result = await this.webServiceHelper.sendRequest<ICreateAccountRequest>({
      path: '/visitor/create-account-request',
      method: 'POST',
      data: info,
    });

    if (result.success) {
      runInAction(() => {
        this._success = true;
        this._loading = false;
      });
    } else {
      runInAction(() => {
        this._success = false;
        this._loading = false;
      });
      throw new Error(result.error);
    }
  };

  public verifyCreateAccountToken = async (token: string) => {
    if (this._verifyingToken) return;

    this._verifyingToken = true;

    const result = await this.webServiceHelper.sendRequest<string>({
      path: '/visitor/token/verify',
      method: 'POST',
      data: { token },
    });

    if (result.success) {
      runInAction(() => {
        this._tokenVerified = true;
        this._isValidToken = true;
        this._verifyingToken = false;
      });
    } else {
      runInAction(() => {
        this._tokenVerified = true;
        this._isValidToken = false;
        this._verifyingToken = false;
      });
    }
  };
}
