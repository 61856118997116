import React from 'react';
import { H2M } from '../../styles/components/header';
import { BackgroundContainer, CommunityImpactContainer, CommunityImpactHeaderWrapper, CommunityImpactInnerWrapper, ImpactValueCard, ImpactValuesContainer, TopValuesContainer, TopValuesWrapper } from './styles';
import { ButtonLink } from '../Button';
import { useAnalytics } from '../../contexts/analytics-store';
import { ButtonKind } from '../Button/styles';
import TopValueCard from '../TopValueCard';
import { PrintWaveA } from '../svgs/shapes/PrintWave';
import { LeafA } from '../svgs/graphics/LeafA';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { Light } from '../svgs/graphics/Light';
import { HandWithMobileApp } from '../svgs/graphics/HandWithMobileApp';
import { MobilePhoneWithApp } from '../svgs/graphics/MobilePhoneWithApp';
interface IProps extends IThemeProps{
  className?: string;
}

export interface ITopValueItem {
  value: string;
  icon: string;
  dollarValue: string;
  purchases: string;
}

interface IImpactPoint {
  name: string;
  value: string;
}

const impactPoints: IImpactPoint[] = [
  {
    name: 'Dollars Towards Change',
    value: '$265,449,728',
  },
  {
    name: 'Purchases',
    value: '2,210,874',
  },
];

const topValues: ITopValueItem[] = [
  {
    value: 'Inclusive Employment',
    icon: 'https://cdn.karmawallet.io/value-icons/value-inclusive-employment.svg',
    dollarValue: '$8,796,404',
    purchases: '169,897',
  },
  {
    value: 'LGBTQ+ Friendly',
    icon: 'https://cdn.karmawallet.io/uploads/SDDIOJ57xC-value-lgtb-friendly.svg',
    dollarValue: '$8,229,287',
    purchases: '213,221',
  },
  {
    value: 'Transparent Practices',
    icon: 'https://cdn.karmawallet.io/value-icons/value-transparent-practices.svg',
    dollarValue: '$7,148,964',
    purchases: '170,365',
  },
];

export const OurImpactSectionBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();

  const onLearnMoreClick = () => {
    analytics.fireEvent('HP_OurImpact_LearnMore_Click');
  };

  const renderImpactPoints = () => impactPoints.map((point) => (
    <ImpactValueCard key={ point.name }>
      <p className='point-name'>{ point.name }</p>
      <p className='point-value'>{ point.value }</p>
    </ImpactValueCard>
  ));

  const renderTopValues = () => topValues.map((topValue) => (
    <TopValueCard key={ topValue.value } valueItem={ topValue } />
  ));

  const renderBackground = () => (
    <BackgroundContainer>
      <LeafA className='leaf-a' stroke={ theme.colors.secondary } fill='none' />
      <Light className='light' stroke={ theme.colors.secondary } fill={ theme.colors.dark } />
      <PrintWaveA className='wave-a' />
      <HandWithMobileApp className='hand-with-mobile-app' />
      <MobilePhoneWithApp className='phone' fill='none' stroke={ theme.colors.secondary } />
    </BackgroundContainer>
  );

  return (
    <CommunityImpactContainer className={ className }>
      { renderBackground() }
      <CommunityImpactInnerWrapper>
        <CommunityImpactHeaderWrapper>
          <span>KARMA WALLET</span>
          <H2M>Community Impact</H2M>
          <p>The Karma Wallet community is creating a difference with every dollar. Here's how:</p>
          <ImpactValuesContainer>
            { renderImpactPoints() }
          </ImpactValuesContainer>
        </CommunityImpactHeaderWrapper>
        <TopValuesWrapper>
          <span>Top Values</span>
          <p>Each brand has different values. Here are the ones our members supported the most.</p>
          <TopValuesContainer>
            { renderTopValues() }
          </TopValuesContainer>
        </TopValuesWrapper>
        <ButtonLink
          href='/our-impact'
          className='learn-more'
          kind={ ButtonKind.Secondary }
          onClick={ onLearnMoreClick }
        >
          Learn More
        </ButtonLink>
        <p className='last-update-text'>Last updated 5/29/24</p>
      </CommunityImpactInnerWrapper>
    </CommunityImpactContainer>
  );
};

export const OurImpactSection = withTheme(OurImpactSectionBase);
