export enum EquivalencyGraphics {
  Airplane = 'airplane',
  Car = 'car',
  GarbageTruck = 'garbageTruck',
  HomeElectricity = 'homeElectricity',
  HomeEnergy = 'homeEnergy',
  LightBulb = 'lightBulb',
  Recycle = 'recycle',
  Sappling = 'sappling',
  SmartPhone = 'smartPhone',
  Trees = 'trees',
}
