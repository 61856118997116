import React from 'react';
import { MarketingBannerContainer } from './styles';
import { BannerItem } from './BannerItem';
import { observer } from 'mobx-react';
import { useBanners } from '../../contexts/banners';

interface IProps {
  className?: string;
}

const MarketingBannersBase: React.FC<IProps> = ({
  className = '',
}) => {
  const bannersModel = useBanners();

  if (!bannersModel.activeBanner) return null;

  return (
    <MarketingBannerContainer className={ className }>
      <BannerItem
        className={ `${bannersModel.activeBanner.color}` }
        banner={ bannersModel.activeBanner }
      />
    </MarketingBannerContainer>
  );
};

export const MarketingBanners = observer(MarketingBannersBase);
