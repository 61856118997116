import styled from 'styled-components';
import { FormContainer } from '../styles';

export const CreatePasswordContainer = styled(FormContainer)`
  min-height: 400px;

  .cta {
    margin: 30px auto 0;
  }
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 40px;

  & > H1 {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 15px;
  }

  .subtext {
    color: ${({ theme }) => theme.colors.grey2};
  }
`;
