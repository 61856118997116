import React from 'react';
import { IconContainer, Score, SubcategoryItemContainer, SubcategoryNameContainer, SubcategoryScoreContainer } from './styles';
import { H4 } from '../../../../styles/components/header';
import { SustainabilityIcon } from '../../../../components/svgs/icons/SustainabillityIcon';
import { Subcategories } from '../../../../models/unsdgs';
import { CommunityWellfareIcon } from '../../../../components/svgs/icons/CommunityWellfareIcon';
import { DiversityInclusionIcon } from '../../../../components/svgs/icons/DiversityInclusionIcon';
import { ClimateActionIcon } from '../../../../components/svgs/icons/ClimateActionIcon';
import { IThemeProps } from '../../../../styles/themes';
import { withTheme } from 'styled-components';
import { WarningNegativeIcon } from '../../../../components/svgs/icons/WarningNegativeIcon';

interface ISubcategoryItemProps extends IThemeProps {
  className?: string;
  subcategoryName: string;
  score: number;
}

const SubcategoryItemBase: React.FC<ISubcategoryItemProps> = ({
  className = '',
  score,
  subcategoryName,
  theme,
}) => {
  const renderIcon = () => {
    switch (subcategoryName) {
      case Subcategories.ClimateAction:
        return <ClimateActionIcon fill={ theme.colors.black } />;
      case Subcategories.Sustainability:
        return <SustainabilityIcon fill={ theme.colors.black } />;
      case Subcategories.CommunityWelfare:
        return <CommunityWellfareIcon fill={ theme.colors.black } />;
      case Subcategories.DiversityInclusion:
        return <DiversityInclusionIcon fill={ theme.colors.black } />;
      default:
        return null;
    }
  };

  const renderNegativeWarningIcon = () => (
    <WarningNegativeIcon fill={ theme.colors.red } />
  );

  return (
    <SubcategoryItemContainer className={ className }>
      <SubcategoryNameContainer>
        <IconContainer>
          { renderIcon() }
        </IconContainer>
        <H4>{ subcategoryName }</H4>
      </SubcategoryNameContainer>
      <SubcategoryScoreContainer>
        { score < 0 ? renderNegativeWarningIcon() : null }
        <Score subcategoryName={ subcategoryName as Subcategories }>{ score }</Score>
      </SubcategoryScoreContainer>
    </SubcategoryItemContainer>
  );
};

export const SubcategoryItem = withTheme(SubcategoryItemBase);
