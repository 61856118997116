import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const PersonIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }person-icon` }
    id={ id }
    width={ 15 }
    height={ 17 }
    fill={ fill }
  >
    <path d='M10.1786 4.25C10.1786 3.54552 9.89637 2.86989 9.39404 2.37175C8.89171 1.8736 8.2104 1.59375 7.5 1.59375C6.7896 1.59375 6.10829 1.8736 5.60596 2.37175C5.10363 2.86989 4.82143 3.54552 4.82143 4.25C4.82143 4.95448 5.10363 5.63011 5.60596 6.12825C6.10829 6.6264 6.7896 6.90625 7.5 6.90625C8.2104 6.90625 8.89171 6.6264 9.39404 6.12825C9.89637 5.63011 10.1786 4.95448 10.1786 4.25ZM3.21429 4.25C3.21429 3.12283 3.66581 2.04183 4.46954 1.2448C5.27327 0.447767 6.36336 0 7.5 0C8.63664 0 9.72673 0.447767 10.5305 1.2448C11.3342 2.04183 11.7857 3.12283 11.7857 4.25C11.7857 5.37717 11.3342 6.45817 10.5305 7.2552C9.72673 8.05223 8.63664 8.5 7.5 8.5C6.36336 8.5 5.27327 8.05223 4.46954 7.2552C3.66581 6.45817 3.21429 5.37717 3.21429 4.25ZM1.65067 15.4062H13.3493C13.0513 13.3045 11.2299 11.6875 9.03013 11.6875H5.96987C3.77009 11.6875 1.94866 13.3045 1.65067 15.4062ZM0 16.0139C0 12.7434 2.67188 10.0938 5.96987 10.0938H9.03013C12.3281 10.0938 15 12.7434 15 16.0139C15 16.5584 14.5547 17 14.0056 17H0.99442C0.445313 17 0 16.5584 0 16.0139Z' fill={ fill } />
  </SvgIcon>
);
