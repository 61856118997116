import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';

dayjs.extend(utc);

type PrivateFields = '_busy' |
'_unsdg';

type PrivateFieldsUNSDGs = '_busy' |
'_unsdgs';

export enum Subcategories {
  Sustainability = 'Sustainability',
  ClimateAction = 'Climate Action',
  CommunityWelfare = 'Community Welfare',
  DiversityInclusion = 'Diversity & Inclusion'
}

export enum Categories {
  People = 'People', 
  Planet = 'Planet'
}

interface IBase {
  _id: string;
  createdOn?: string;
  lastModified?: string;
}

export interface IUNSDGCategory extends IBase {
  index: number;
  name: string;
}

export interface IUNSDGSubCategory extends IBase {
  category: IUNSDGCategory;
  categoryIndex: number;
  name: Subcategories;
}

export interface ILogos {
  outline: string;
  solid: string;
  _id: string;
  color: string;
}

export interface IUNSDG extends IBase {
  description: string;
  goalNum: number;
  howToAcquire: string;
  img: string;
  sourceUrl: string;
  subCategory: IUNSDGSubCategory;
  subCategoryIndex: number;
  subTitle: string;
  title: string;
  unsdgSubTitle: string;
  logos: ILogos;
}

export interface ISubcategoryGroup {
  category: string;
  subcategory: Subcategories;
  unsdgs: UNSDGModel[];
}

export class UNSDGModel extends BaseModel {
  private _busy = false;
  private _unsdg: IUNSDG = null;

  constructor (unsdgInfo: IUNSDG) {
    super();
    makeObservable<UNSDGModel, PrivateFields>(this, {
      _busy: observable,
      _unsdg: observable,
      busy: computed,
      _id: computed,
      createdOn: computed,
      description: computed, 
      goalNum: computed, 
      howToAcquire: computed, 
      img: computed, 
      lastModified: computed, 
      logos: computed,
      sourceUrl: computed, 
      subCategory: computed, 
      subCategoryIndex: computed, 
      subTitle: computed, 
      title: computed, 
      unsdgSubTitle: computed,
    });

    this._unsdg = unsdgInfo;
  }

  get _id() { return this._unsdg._id; }
  get busy() { return this._busy; }
  get createdOn() { return dayjs(this._unsdg.createdOn).local(); }
  get description() { return this._unsdg.description; }
  get goalNum() { return this._unsdg.goalNum; }
  get howToAcquire() { return this._unsdg.howToAcquire; }
  get img() { return this._unsdg.img; }
  get lastModified() { return !!this._unsdg.lastModified ? dayjs(this._unsdg.lastModified).local() : null; }
  get logos() { return this._unsdg.logos; }
  get sourceUrl() { return this._unsdg.sourceUrl; }
  get subCategory() { return this._unsdg.subCategory; }
  get subCategoryIndex() { return this._unsdg.subCategoryIndex; }
  get subTitle() { return this._unsdg.subTitle; }
  get title() { return this._unsdg.title; }
  get unsdgSubTitle() { return this._unsdg.unsdgSubTitle; }
}

export class UNSDGsModel extends BaseModel {
  private _busy = false;
  private _unsdgs: UNSDGModel[] = [];

  constructor () {
    super();
    makeObservable<UNSDGsModel, PrivateFieldsUNSDGs>(this, {
      _busy: observable,
      _unsdgs: observable,
      busy: computed,
      groupedUNSDGs: computed,
      unsdgs: computed,
      load: action.bound,
    });
  }

  get busy() { return this._busy; }
  get unsdgs() { return this._unsdgs; }
  get groupedUNSDGs() { 
    return [
      {
        category: Categories.Planet, 
        subcategory: Subcategories.Sustainability,
        unsdgs: this._unsdgs.filter(unsdg => unsdg.subCategory?.name === Subcategories.Sustainability),
      },
      {
        category: Categories.Planet, 
        subcategory: Subcategories.ClimateAction,
        unsdgs: this._unsdgs.filter(unsdg => unsdg.subCategory?.name === Subcategories.ClimateAction),
      },
      {
        category: Categories.People, 
        subcategory: Subcategories.CommunityWelfare,
        unsdgs: this._unsdgs.filter(unsdg => unsdg.subCategory?.name === Subcategories.CommunityWelfare),
      },
      {
        category: Categories.People, 
        subcategory: Subcategories.DiversityInclusion,
        unsdgs: this._unsdgs.filter(unsdg => unsdg.subCategory?.name === Subcategories.DiversityInclusion),
      },
    ];
  }

  public load = async () => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IUNSDG[]>({
      path: '/unsdgs',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._unsdgs = result.value.map(unsdg => new UNSDGModel(unsdg));
        this._busy = false;
      }); 
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}
