import React, { useCallback, useState } from 'react';
import { ButtonKind } from '../Button/styles';
import { KebabIcon } from '../svgs/icons/KebabIcon';
import { TinyPopover } from '../TinyPopover';
import { MoreMenuContainer, MoreMenuOption, MoreMenuOptionOverride, MoreMenuTrigger, OptionsContainer } from './styles';

export interface IMoreMenuOption {
  id: string;
  context: any;
  content?: string | JSX.Element;
  bankName?: string;
  /**
   * CAUTION: using this property will override
   * the default behavior of the more menu. use
   * with caution.
   */
  optionOverride?: JSX.Element;
}

interface IProps {
  className?: string;
  options: IMoreMenuOption[];
  onOptionClick(option: IMoreMenuOption | string): void;
  bankName?: string;
}

export const MoreMenu: React.FC<IProps> = ({
  className = '',
  options,
  onOptionClick,
  bankName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onMoreMenuOptionClick = useCallback((option: any) => () => {
    if (!!bankName) onOptionClick(bankName);
    if (!option.optionOverride) onOptionClick(option);
    setIsOpen(false);
  }, []);

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onTriggerClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const renderAnchor = useCallback(() => (
    <MoreMenuTrigger
      kind={ ButtonKind.Blank }
      onClick={ onTriggerClick }
    >
      <KebabIcon />
    </MoreMenuTrigger>
  ), []);

  const renderOptions = useCallback(() => {
    const menuOptions = options.map(option => {
      if (option.optionOverride) {
        return (
          <MoreMenuOptionOverride
            key={ option.id }
            onClick={ onMoreMenuOptionClick }
          >
            { option.optionOverride }
          </MoreMenuOptionOverride>
        );
      }

      return (
        <MoreMenuOption
          key={ option.id }
          kind={ ButtonKind.Blank }
          onClick={ onMoreMenuOptionClick(option) }
        >
          { option.content }
        </MoreMenuOption>
      );
    });

    return (
      <OptionsContainer>
        { menuOptions }
      </OptionsContainer>
    );
  }, [options]);

  return (
    <MoreMenuContainer className={ className }>
      <TinyPopover
        align='end'
        dismissOnOutsideAction
        anchor={ renderAnchor() }
        isOpen={ isOpen }
        onRequestClose={ onRequestClose }
        placement={ ['bottom', 'top'] }
      >
        { renderOptions() }
      </TinyPopover>
    </MoreMenuContainer>
  );
};
