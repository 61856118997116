import React, { useRef } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { CircleChartContainer, CircleChartDataContainer, ICircleChartProps } from './styles';

export interface ICircleChartData {
  value: number;
}

interface IProps extends ICircleChartProps, IThemeProps {
  animate?: boolean;
  className?: string;
  colors?: string[];
  data: ICircleChartData[];
  fill?: string;
  thickness?: number;
  children: React.ReactNode;
}

const fillData = [
  { value: 0 },
  { value: 1 },
];

const CircleChartBase: React.FC<IProps> = ({
  animate = true,
  children,
  className = '',
  colors = [],
  data,
  fill,
  size,
  theme,
  thickness = 15,
}) => {
  const _colors = useRef([
    ...colors,
    theme.colors.primary,
    theme.colors.green,
    theme.colors.tertiary,
    theme.colors.darkYellow2,
  ]).current;

  const renderCells = () => {
    const cells = data.map((entry, index) => (
      <Cell
        key={ `cell-${index}` }
        fill={ _colors[index % _colors.length] }
        strokeWidth={ 0 }
      />
    ));

    return cells;
  };

  return (
    <CircleChartContainer size={ size } className={ className }>
      <ResponsiveContainer width='100%' height={ size }>
        <PieChart width={ size } height={ size }>
          {
            !!fill && (
              <Pie
                data={ fillData }
                dataKey='value'
                cx={ (size - thickness) / 2 }
                cy={ (size - thickness) / 2 }
                outerRadius={ (size - (thickness * 4)) / 2 }
                strokeWidth={ 0 }
                fill={ fill }
                startAngle={ 90 }
                endAngle={ -450 }
                isAnimationActive={ animate }
              />
            )
          }
          <Pie
            data={ data }
            dataKey='value'
            cx={ (size - thickness) / 2 }
            cy={ (size - thickness) / 2 }
            innerRadius={ (size - (thickness * 4)) / 2 }
            outerRadius={ (size - thickness * 2) / 2 }
            fill='#82ca9d'
            startAngle={ 90 }
            endAngle={ -450 }
            isAnimationActive={ animate }
          >
            { renderCells() }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <CircleChartDataContainer thickness={ thickness }>
        { children }
      </CircleChartDataContainer>
    </CircleChartContainer>
  );
};

export const CircleChart = withTheme(CircleChartBase);
