import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { MaxBoundarySection } from '../../styles/components/containers';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import { TextLGMedium } from '../../styles/components/text';
import { H2 } from '../../styles/components/header';

export const FAQsContainer = styled(MainContainer)``;

export const QuestionGroupAccordion = styled(Accordion)`
margin-top: 60px;
padding; 10px 0 50px;

  .question-text {
    ${TextLGMedium}
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
    max-width: 90%;
  }
`;

export const QuestionGroupTitle = styled(H2)`
  font-size: 32px;
`;

export const KarmaWalletMembershipAccordion = styled(QuestionGroupAccordion)``;
export const KarmaWalletCardAccordion = styled(QuestionGroupAccordion)``;
export const KarmaWalletCardCashbackAccordion = styled(QuestionGroupAccordion)``;
export const KarmaWalletCardDonationsAccordion = styled(QuestionGroupAccordion)``;
export const KarmaWalletMarketplaceAccordion = styled(QuestionGroupAccordion)``;
export const KarmaScoreToolAccordion = styled(QuestionGroupAccordion)``;
export const SecurityAndPrivacyAccordion = styled(QuestionGroupAccordion)``;
export const AssessingImpactAccordion = styled(QuestionGroupAccordion)``;
export const CarbonOffsetsAccordion = styled(QuestionGroupAccordion)``;
export const PartnershipsAccordion = styled(QuestionGroupAccordion)``;
export const GeneralAccordion = styled(QuestionGroupAccordion)``;

export const AccordionItem = styled(Item)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};

  .answer-text {
    margin-bottom: 0;

    &+p {
      margin-top: 10px;
    }
  }

  ul {
    margin: 15px 0;

    li {
      margin: 5px 0;
    }
  }

  .szh-accordion__item {
    &-btn {
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      margin: 0;
      padding: 15px 0;
      width: 100%;
    }

    &-content {
      transition: height 0.75s cubic-bezier(0, 0, 0, 1);
    }
  }

  .szh-accordion__item-panel {
    padding: 5px 20px 20px 20px;
  }

  .chevron-down {
    margin-left: auto;
    margin-right: 5px;
    transition: transform 0.75s cubic-bezier(0, 0, 0, 1);
  }

  &.szh-accordion__item--expanded {
    .chevron-down {
      transform: rotate(90deg);
    }
  }
`;

export const FAQSectionWrapper = styled(MaxBoundarySection)`
  max-width: 970px;

  .pathward-disclaimer-text {
    margin-top: 60px;
  }

  .page-header-section {
    margin-bottom: 40px;
  }

  .center {
    text-align: center;
  }

  a {
    color: ${({ theme }) => theme.colors.success};
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.lightBlue1};
      text-decoration: underline;
    }
  }

  ol {
    margin: 0 20px;
    
    li {
      margin-bottom: 10px;
    }
  }
`;
