import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { IndustryReportContainer } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../components/loading/LoadingSpinner';
import { useAnalytics } from '../../contexts/analytics-store';
import { MasterArticle } from './MasterArticle';
import { ArticleModel, IArticle, IArticleType } from '../../models/article';

interface IProps {
  className?: string;
}

const IndustryReportsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const articleModel = useRef(new ArticleModel(null)).current;
  const { articleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState<IArticle>(null);

  useEffect(() => {
    setLoading(true);
    loadArticle(articleId);
  }, []);

  const loadArticle = useCallback( async ( articleId: string ) => {
    try {
      await articleModel.loadArticle(articleId);
      if (!articleModel.article.enabled) navigate('/');
      setArticle(articleModel.article);
      let articleInfo;
      if (!!articleModel.article.company) { 
        articleInfo = articleModel.article.company.companyName;
      } else {
        articleInfo = articleModel.article.headerTitle;
      }
      analytics.fireEvent('IndustryReport_ArticleLoaded', articleInfo);
    } catch (error) {
      navigate('/');
    } finally {
      setLoading(false);
    }
  }, []);

  const previewInfo = useMemo(() => {
    if (!article) return null;

    return {
      title: article.title,
      description: article.description,
    };
  }, [article]);
  
  const renderContent = useCallback(()=>{
    if (!!loading || !article) return <LoadingSpinner />;
    return <MasterArticle article={ article } />;
  }, [article, loading]);

  if (!article || !previewInfo) return null;

  let tabName: string;

  switch (article.type) {
    case IArticleType.General:
      tabName = `Feature: ${article.title}`;
      break;
    case IArticleType.Feature:
      tabName = `Feature: ${article.title}`;
      break;
    case IArticleType.CompanySpotlight:
      tabName = `Company Spotlight: ${article.company.companyName}}`;
      break;
    case IArticleType.IndustryReport:
      tabName = `Industry Report: ${article.title}`;
      break;
    case IArticleType.SourceSpotlight:
      tabName = `Source Spotlight: ${article.title}`;
      break;
    default:
      tabName = `The Good & The Bad: ${article.company.companyName}`;
  }

  return (
    <IndustryReportContainer 
      className={ className }
      title={ tabName }
    >
      { renderContent() }
    </IndustryReportContainer>
  );
};

export const IndustryReport = observer(IndustryReportsBase);
