import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FlexCol, FlexColCenter, FlexHorizontalCenter, FlexRow, PrimaryFont } from '../../styles/styles';

export interface IFooterLinkProps {
  disabled?: boolean;
}

export const AltLinksContainer = styled.div`
  ${FlexCol}
  margin: 50px 0;
  padding: 0 20px;
  width: 100%;
  align-items: center;

  p, a {
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
  }

  .copyright {
    margin-bottom: 0;

    @media (${({ theme }) => theme.breakpoints.lgMax}) {
      margin-top: 60px;
    }
  }

  &.dt-alt-links-container {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.xlMin}) {
    align-items: flex-end;

    &.mb-alt-links-container {
      display: none;
    }

    &.dt-alt-links-container {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;

      a {
        padding: 0;
      }
    }
  }
`;

const FooterLinkStyles = css`
  ${ PrimaryFont }
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  &:hover,
  &:focus-visible {
    color: ${({ theme }) => theme.colors.tertiary};
  }

  &:focus-visible {
    outline: ${({ theme }) => `1px dashed ${theme.colors.darkGray1}`};
    outline-offset: 1px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    font-size: 16px;
  }
`;

export const FooterAnchor = styled.a`
  ${ FooterLinkStyles }
`;

export const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.dark};
  padding: 40px 15px 0 15px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 60px 15px 0 15px;
  }
`;

export const FooterInnerContainer = styled.div`
  ${FlexCol}
  width: calc(100% - 30px);
  max-width: 1520px;
  margin: 0 auto;
  align-items: center;
`;

export const FooterLink = styled(Link)<IFooterLinkProps>`
  ${FooterLinkStyles}
  color: ${({ theme }) => theme.colors.white};

  ${({ disabled, theme }) => disabled &&
  `color: ${theme.colors.grey4};
  
    &:hover,
    &:focus-visible {
      color:  ${theme.colors.grey4};
      cursor: default;
    }
  `}
`;

export const FooterLinkGroupContainer = styled.div``;

export const FooterLinkContainer = styled.div`
  padding: 5px 0;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 10px 15px;
    text-align: left;
  }
`;

export const LegalContainer = styled.div`
  ${FlexRow}
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  justify-self: flex-end;
  margin-top: 10px;
  padding-left: 20px;

  a, p {
    font-size: 12px;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    margin: 0;
  }

  a {
    display: block;
    outline: none;
    margin: 0 5px;

    &:hover,
    &:focus-visible {
      color: ${({ theme }) => theme.colors.tertiary};
    }

    &:focus-visible {
      outline: ${({ theme }) => `1px dashed ${theme.colors.darkGray1}`};
      outline-offset: 1px;
    }
  }
`;

export const LinksContainer = styled.nav`
  ${FlexColCenter}
  gap: 20px;
  margin: 30px 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
    margin: 50px 0;
  }
`;

export const CertificationsContainer = styled.div`
  ${FlexHorizontalCenter}
  gap: 20px;
  justify-content: center;
  max-width: 400px;
  opacity: 0.5;
  padding: 0 20px;
  width: 80%;

  svg {
    width: auto;
    min-height: 45px;
    max-height: 45px;
    max-width: 160px;
  }

  img {
    min-height: 50px;
    max-height: 50px;
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 20px;

  svg {
    max-height: 67px;
    height: 67px;
    min-height: 67px;
    display: inline-block;
    width: auto;
  }

  a {
    min-width: 100%;
    outline: none;

    &:focus-visible {
      outline: ${({ theme }) => `1px dashed ${theme.colors.darkGray1}`};
      outline-offset: 1px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 0;
  }
`;
