import styled from 'styled-components';
import { _H4, _H3 } from '../../styles/components/header';

export const AccordionSectionContainer = styled.div`
  padding: 10px 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 20px 0;
  } 

  .chevron-icon {
    margin-right: 10px;
    position: relative;
  }
`;

export const HeaderRowContainer = styled.div`
  ${_H4}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  &:hover {
    cursor: pointer;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${_H3}
  } 
`;

export const ContentContainer = styled.div`
  display: none;

  &.expanded {
    display: block;
  }
`;
