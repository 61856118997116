import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { _H3, _H4 } from '../../styles/components/header';
import { SecondaryFont, PrimaryFont } from '../../styles/styles';

export const UtilizingUNSDGsContainer = styled(MaxBoundarySection)`
  margin: 0 auto;

  .subheader-text, p {
    font-size: 20px;

    div {
      font-weight: 400;
    }
    
    span, a {
      font-size: 20px;
    } 
  }
  
  .header-wrapper {
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 30px;
    } 

    a {
      ${SecondaryFont}
      font-size: 14px;
      color: ${({ theme }) => theme.colors.darkGray2};
      font-weight: 700;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        ${PrimaryFont}
        ${_H3}
      } 
    }
  }
`;

export const SubcategoryNav = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px 0;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    justify-content: space-between;
    max-width: 900px;
    padding: 0px 15px;
    margin: 0 auto;
  }

  .unsdg-picker-button {
    ${SecondaryFont}
    font-weight: normal;
    font-size: 14px;
    padding: 0 10px 20px;

    &.active {
      text-decoration: underline;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${PrimaryFont}
      ${_H4}
    } 
  }

  .subcat-skeleton {
    width: 200px;
    height: 20px;
  }
`;
