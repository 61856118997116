import { getRecoil, setRecoil } from 'recoil-nexus';
import { IMembershipPriceAtom } from './types';
import { membershipPriceAtom } from './atom';
import { WebServiceHelper } from '../../lib/webServiceHelperRecoil'; 

const getMembershipPriceData = () => getRecoil(membershipPriceAtom);

const setMembershipPriceData = (data: IMembershipPriceAtom) => {
  setRecoil(membershipPriceAtom, data);
};

const setMembershipPriceDataLoading = () => {
  const data = getMembershipPriceData();
  setMembershipPriceData({
    ...data,
    loading: true,
  });
};

const setMembershipPriceDataAtom = (price: string) => {
  setMembershipPriceData({
    price,
    error: false,
    loading: false,
  });
};

const setMembershipPriceDataError = () => {
  setMembershipPriceData({
    price: null,
    error: true,
    loading: false,
  });
};

export const loadMembershipPrice = async (promoCode?: string) => {
  try {
    setMembershipPriceDataLoading();

    const res = await WebServiceHelper.sendRequest({
      method: 'GET',
      path: `/karma-card/membership-price${promoCode ? `/${promoCode}` : ''}`, 
    });

    if (res.success) {
      setMembershipPriceDataAtom(res.data);
    } 
    else setMembershipPriceDataError();
  } catch (err) {
    setMembershipPriceDataError();
  }
};
