import React from 'react';
import { CompanyLogo } from '../../CompanyLogo';
import { CompanyRatingTag } from '../../CompanyRatingTag';
import { CompanyModel, ImpactCategory } from '../../../models/companies';
import { CompanyComparisonItemContainer } from './styles';

interface IProps {
  className?: string;
  company?: CompanyModel;
  key?: any;
  type?: any;
}

export const CompanyComparisonItem: React.FC<IProps> = ({
  className = '',
  company,
  type,
  key,
}) => (
  <CompanyComparisonItemContainer className={ className } key={ key }>
    <CompanyLogo className='company-logo' company={ company } />
    <span className='company-name'>{ company?.companyName }</span>
    <CompanyRatingTag rating={ type === ImpactCategory.Planet ? company.categoryScores[1].score * 2 : company.categoryScores[0].score * 2 } className='rating' />
  </CompanyComparisonItemContainer>
);
