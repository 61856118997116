import styled from 'styled-components';
import { FlexHorizontalCenter } from '../../styles/styles';

export const CTAsContainer = styled.div`
  ${ FlexHorizontalCenter }
  justify-content: flex-end;
  padding: 10px 0 2px;

  & > *:not(:first-child) {
    margin-left: 10px;
  }
`;
