import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  strokeWidth?: number;
  id?: string;
}

export const CheckIcon: FC<IProps> = ({ className = '', stroke = '', id = '', strokeWidth }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}check-icon` }
    id={ id }
    width={ 20 }
    height={ 16 }
    stroke={ stroke }
    strokeWidth={ strokeWidth }
    fill='none'
  >
    <path d='M1.25 9.25L6.25 14.25L18.75 1.75' strokeWidth={ strokeWidth ? strokeWidth : '2' } strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
