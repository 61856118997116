import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const IndustryReportGauge: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}gauge-graphic` }
    id={ id }
    height={ 129 }
    width={ 250 }
  >
    <path d='M24.8595 106.334C24.3401 109.251 23.9443 112.21 23.6781 115.206L6.46875 113.701C6.78308 110.149 7.25315 106.642 7.87162 103.186L24.8595 106.334Z' fill='#EF8683' />
    <path d='M6 123.935C6.01104 120.775 6.1447 117.645 6.39673 114.547L23.6063 116.053C23.3968 118.654 23.2849 121.283 23.2739 123.935H6Z' fill='#EF8683' />
    <path d='M25.012 105.499C25.5636 102.545 26.2424 99.6369 27.0424 96.7791L10.4331 92.0163C9.48222 95.4025 8.67686 98.8494 8.02441 102.35L25.012 105.499Z' fill='#EF8683' />
    <path d='M30.1394 87.4775C29.065 90.249 28.1081 93.0793 27.275 95.9618L10.666 91.1993C11.652 87.7822 12.787 84.4282 14.0627 81.1448L30.1394 87.4775Z' fill='#EF8683' />
    <path d='M30.4494 86.6866C31.556 83.9009 32.7816 81.1759 34.1202 78.5169L18.7255 70.6731C17.1366 73.8226 15.6833 77.0523 14.373 80.3541L30.4494 86.6866Z' fill='#EF8683' />
    <path d='M38.946 69.9814C37.3565 72.502 35.8744 75.097 34.5058 77.76L19.1113 69.916C20.732 66.7594 22.4894 63.6843 24.3753 60.6988L38.946 69.9814Z' fill='#EF8683' />
    <path d='M39.4024 69.2646C41.0191 66.7477 42.7434 64.3062 44.5687 61.947L30.9558 51.3114C28.7906 54.1055 26.7466 56.9985 24.832 59.9823L39.4024 69.2646Z' fill='#EF8683' />
    <path d='M50.9332 54.4864C48.8892 56.6626 46.9398 58.9283 45.0912 61.277L31.4785 50.6417C33.6681 47.8572 35.9788 45.1726 38.4028 42.5957L50.9332 54.4864Z' fill='#EF8683' />
    <path d='M51.5167 53.8696C53.5796 51.709 55.7363 49.6389 57.9805 47.666L46.6474 34.6288C43.9864 36.965 41.4302 39.4176 38.9863 41.979L51.5167 53.8696Z' fill='#EF8683' />
    <path d='M65.6483 41.5488C63.2237 43.3 60.879 45.1551 58.6209 47.1076L47.2881 34.0706C49.9627 31.7556 52.7415 29.5576 55.6164 27.4844L65.6483 41.5488Z' fill='#EF8683' />
    <path d='M66.3395 41.0541C68.772 39.3275 71.2838 37.7049 73.8675 36.1925L65.2292 21.2302C62.1657 23.0201 59.1892 24.9426 56.3076 26.99L66.3395 41.0541Z' fill='#EF8683' />
    <path d='M82.5646 31.6498C79.8436 32.908 77.1874 34.2823 74.6022 35.7664L65.9639 20.8044C69.0262 19.0441 72.174 17.4159 75.3994 15.9272L82.5646 31.6498Z' fill='#EF8683' />
    <path d='M83.3369 31.2962C86.0503 30.0692 88.8271 28.9572 91.6606 27.9666L86.0353 11.6292C82.6765 12.8007 79.3862 14.1181 76.1719 15.5739L83.3369 31.2962Z' fill='#EF8683' />
    <path d='M101.071 25.181C98.151 25.8935 95.2799 26.732 92.4642 27.6897L86.8389 11.3523C90.1744 10.2156 93.5765 9.22248 97.0375 8.38031L101.071 25.181Z' fill='#EF8683' />
    <path d='M101.898 24.9832C104.789 24.3032 107.727 23.7462 110.706 23.3178L108.302 6.20984C104.77 6.71503 101.289 7.37512 97.8643 8.18246L101.898 24.9832Z' fill='#EF8683' />
    <path d='M120.474 22.389C117.462 22.5308 114.485 22.8032 111.547 23.2005L109.143 6.09259C112.623 5.61969 116.152 5.29686 119.721 5.13092L120.474 22.389Z' fill='#EF8683' />
    <path d='M121.323 22.3523C122.662 22.3002 124.007 22.2739 125.359 22.2739C127.011 22.2739 128.654 22.3133 130.286 22.3908L131.191 5.14017C129.258 5.04701 127.314 5 125.359 5C123.755 5 122.158 5.03172 120.569 5.0943L121.323 22.3523Z' fill='#E5D976' />
    <path d='M140.054 23.324C137.12 22.901 134.145 22.6025 131.135 22.4349L132.039 5.1839C135.606 5.38071 139.131 5.73411 142.608 6.23732L140.054 23.324Z' fill='#E5D976' />
    <path d='M140.896 23.4486C143.871 23.9031 146.804 24.4859 149.69 25.1913L153.869 8.42627C150.451 7.58863 146.976 6.89824 143.449 6.36218L140.896 23.4486Z' fill='#E5D976' />
    <path d='M159.098 27.9808C156.291 26.9982 153.427 26.1345 150.514 25.3963L154.694 8.63153C158.147 9.504 161.54 10.5271 164.866 11.6933L159.098 27.9808Z' fill='#E5D976' />
    <path d='M159.898 28.2649C162.723 29.2804 165.49 30.4165 168.192 31.6676L175.494 16.0084C172.293 14.5245 169.014 13.1783 165.666 11.9774L159.898 28.2649Z' fill='#E5D976' />
    <path d='M176.888 36.2139C174.315 34.7069 171.671 33.3094 168.962 32.0277L176.264 16.3682C179.476 17.8849 182.609 19.5407 185.656 21.3278L176.888 36.2139Z' fill='#E5D976' />
    <path d='M177.619 36.6464C180.19 38.1812 182.687 39.8256 185.105 41.5737L195.259 27.5977C192.395 25.5251 189.435 23.5768 186.388 21.7599L177.619 36.6464Z' fill='#E5D976' />
    <path d='M192.77 47.6941C190.529 45.722 188.2 43.8465 185.791 42.0744L195.945 28.0984C198.802 30.1962 201.561 32.4183 204.216 34.7565L192.77 47.6941Z' fill='#E5D976' />
    <path d='M193.404 48.2579C195.631 50.2503 197.77 52.3388 199.814 54.5173L212.447 42.7362C210.026 40.1539 207.491 37.6792 204.851 35.3203L193.404 48.2579Z' fill='#55ED6D' />
    <path d='M206.175 61.9801C204.347 59.615 202.417 57.3326 200.393 55.1391L213.026 43.358C215.427 45.9556 217.715 48.6603 219.88 51.464L206.175 61.9801Z' fill='#55ED6D' />
    <path d='M206.692 62.6543C208.497 65.0293 210.2 67.4853 211.794 70.0166L226.445 60.8618C224.557 57.8612 222.538 54.9507 220.397 52.1379L206.692 62.6543Z' fill='#55ED6D' />
    <path d='M216.616 78.5543C215.271 75.8794 213.811 73.2716 212.244 70.7374L226.895 61.5823C228.755 64.584 230.485 67.674 232.079 70.845L216.616 78.5543Z' fill='#55ED6D' />
    <path d='M216.995 79.3148C218.31 81.9849 219.512 84.7207 220.594 87.5163L236.725 81.324C235.444 78.0105 234.019 74.7684 232.458 71.6056L216.995 79.3148Z' fill='#55ED6D' />
    <path d='M223.218 95.1915C222.524 92.8615 221.75 90.5666 220.897 88.3097L237.029 82.1174C238.044 84.7997 238.965 87.5281 239.789 90.2985L223.218 95.1915Z' fill='#55ED6D' />
    <path d='M223.456 96.0065C224.279 98.8584 224.981 101.762 225.556 104.71L242.518 101.428C241.838 97.9333 241.005 94.4927 240.027 91.1138L223.456 96.0065Z' fill='#55ED6D' />
    <path d='M226.965 114.4C226.676 111.408 226.257 108.455 225.715 105.545L242.678 102.263C243.323 105.711 243.82 109.212 244.162 112.757L226.965 114.4Z' fill='#55ED6D' />
    <path d='M227.043 115.246C227.296 118.11 227.431 121.008 227.443 123.935H244.718C244.705 120.454 244.544 117.008 244.24 113.604L227.043 115.246Z' fill='#55ED6D' />
  </SvgIcon>
);
