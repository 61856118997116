import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAnalytics } from '../../contexts/analytics-store';
import { useUserSession } from '../../contexts/user';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { TinyPopover } from '../TinyPopover';
import { NavigationOptionsContainer, OptionsContainer, PageNavigationLinks, UserAccountMenuContainer, UserInfoContainer } from './styles';
import { IThemeProps } from '../../styles/themes';
import { withTheme } from 'styled-components';
import { AccountAvatar } from '../../pages/Account/AccountAvatar';
import { useAppData } from '../../contexts/app';
import { AccountHubUserMenu } from '../../pages/Account/AccountHubUserMenu';
import { useAccountHubMobileNav } from '../../contexts/accountHubMobileNav';
import MobileNavigationMenu from '../AppHeader/MobileNavigationMenu';

enum MenuOptionType {
  Account = 'account',
  SignOut = 'sign-out',
}

interface IMenuOption {
  id: MenuOptionType;
  text: string;
}

interface IProps extends IThemeProps {
  className?: string;
  setMobileNavIsOpen(isOpen: boolean): void;
  setMobileUserAccountMenuIsOpen?(isOpen: boolean): void;
}

interface IOptionsProps {
  className?: string;
  onClick(): void;
  setMobileNavIsOpen(isOpen: boolean): void;
  setMobileUserAccountMenuIsOpen?(isOpen: boolean): void;
}

const userAccountMenuOptions: IMenuOption[] = [
  {
    id: MenuOptionType.Account,
    text: 'Your Account',
  },
  {
    id: MenuOptionType.SignOut,
    text: 'Sign Out',
  },
];

export const UserAccountMenuOptionsBase: React.FC<IOptionsProps> = ({
  className = '',
  onClick,
  setMobileNavIsOpen,
  setMobileUserAccountMenuIsOpen,
}) => {
  const analytics = useAnalytics();
  const userSession = useUserSession();
  const appData = useAppData();
  const navigate = useNavigate();
  const mobileNavModel = useAccountHubMobileNav();

  const onMenuOptionClick = useCallback(({ id }: IMenuOption) => () => {
    switch (id) {
      case MenuOptionType.Account:
        analytics.fireEvent('UserAccountMenuOptionClicked_Account');
        mobileNavModel.setAccountMobileNavEngaged(false);
        navigate('/account/dashboard');
        break;
      case MenuOptionType.SignOut:
        analytics.fireEvent('UserAccountMenuOptionClicked_Signout');
        userSession.logout();
        navigate('/');
        break;
      default:
        break;
    }

    onClick();
  }, []);

  const renderOptions = useCallback(() => {
    const menuOptions = userAccountMenuOptions.map(option => (
      <Button
        className='menu-option'
        key={ option.id }
        kind={ ButtonKind.Blank }
        label='More Options'
        onClick={ onMenuOptionClick(option) }
      >
        { option.text }
      </Button>
    ));

    return (
      <OptionsContainer className={ className }>
        {
          !appData.isDesktop ?
            <PageNavigationLinks>
              <MobileNavigationMenu setMobileNavOpen={ () => setMobileNavIsOpen(false) } setMobileUserAccountMenuIsOpen={ setMobileUserAccountMenuIsOpen } />
            </PageNavigationLinks>
            : null
        }
        {
          !userSession.isLoggedIn ? null :
            <div className='mobile-nav-user-menu-option-container'>
              <AccountAvatar userName={ userSession?.name } />
              <UserInfoContainer>
                <p className='user-info user-name'>{ userSession?.name }</p>
                <p className='user-info user-email'>{ userSession?.emails.find( email => email.primary === true).email }</p>
              </UserInfoContainer>
              <NavigationOptionsContainer>
                { menuOptions }
              </NavigationOptionsContainer>
            </div>
        }
      </OptionsContainer>
    );
  }, [userSession, appData.isDesktop]);

  return (
    <>
      { renderOptions() }
    </>
  );
};

export const UserAccountMenuOptions = observer(UserAccountMenuOptionsBase);

const UserAccountMenuBase: React.FC<IProps> = ({
  className = '',
  setMobileNavIsOpen,
  setMobileUserAccountMenuIsOpen,
}) => {
  const analytics = useAnalytics();
  const userSession = useUserSession();
  const [isOpen, setIsOpen] = useState(false);

  const onOptionClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onTriggerClick = useCallback(() => {
    const newState = !isOpen;
    setIsOpen(newState);
    analytics.fireEvent(`UserAccountMenuClicked_${newState ? 'open' : 'close' }`);
  }, [isOpen]);

  const renderAnchor = useCallback(() => (
    <AccountHubUserMenu
      onClick={ onTriggerClick }
      isOpen={ isOpen }
      user={ userSession }
    />
  ), [onTriggerClick]);

  return (
    <UserAccountMenuContainer className={ className }>
      <TinyPopover
        align='end'
        dismissOnOutsideAction
        anchor={ renderAnchor() }
        isOpen={ isOpen }
        onRequestClose={ onRequestClose }
        placement={ ['bottom', 'top'] }
      >
        <UserAccountMenuOptions
          onClick={ onOptionClick }
          setMobileNavIsOpen={ setMobileNavIsOpen }
          setMobileUserAccountMenuIsOpen={ setMobileUserAccountMenuIsOpen }
        />
      </TinyPopover>
    </UserAccountMenuContainer>
  );
};

const UserAccountMenuObserver = observer(UserAccountMenuBase);
export const UserAccountMenu = withTheme(UserAccountMenuObserver);
