import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { TextLGDemiBold, TextSMMedium } from '../../../styles/components/text';
import { FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';

interface IBetterFutureStepCardProps {
  color?: string;
}

export const BetterFutureSectionContainer = styled(Section)``;

export const BetterFutureInnerContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexColCenter}
  align-items: center;
  text-align: center;

  a {
    margin-top: 29px;
  }

  h2 {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
  }

  p {
    ${TextLGDemiBold}
    margin-bottom: 43px;
  }
`;

export const StepsWrapper = styled.div`
  span {
    ${TextLGDemiBold};
  }
`;

export const BetterFutureStepCardsWrapper = styled.div`
  ${FlexCol}
  align-items: flex-start;

  span {
    margin: 32px 0 16px 0;
  }
`;

export const BetterFutureStepCardsContainer = styled.div`
  ${FlexColCenter}
  flex-wrap: wrap;
  gap: 24px;
  max-width: 900px;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    ${FlexRow}
  }
`;

export const BetterFutureStepCard = styled.div<IBetterFutureStepCardProps>`
  ${FlexColCenter}
  background-color: ${({ color }) => color + '44' };
  border-radius: 16px;
  padding: 24px;
  position: relative;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    flex: 1;
    flex-basis: 278px;
    max-width: unset;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: 278px;
  }

  .step-number {
    ${FlexColCenter}
    ${TextLGDemiBold}
    background-color: ${({ color }) => color };
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.white };
    height: 25px;
    left: 15px;
    padding-top: 2px;
    position: absolute;
    top: 15px;
    width: 23px;
  }

  .step-title {
    ${TextLGDemiBold}
    color: ${({ theme }) => theme.colors.black };
    margin-bottom: 15px;
  }

  .step-description {
    ${TextSMMedium}
    color: ${({ theme }) => theme.colors.black };
  }
`;
