import { observer } from 'mobx-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { GroupModel } from '../../models/group';
import { H1 } from '../../styles/components/header';
import { GroupLogo } from '../GroupLogo';
import { GroupHeroSkeleton } from './skeleton';
import { GroupHeroContainer, GroupHeroInner } from './styles';

interface IProps {
  className?: string;
  group: GroupModel;
}

const GroupHeroBase: React.FC<IProps> = ({
  className = '',
  group,
}) => {
  const location = useLocation();

  if (group?.busy) return <GroupHeroSkeleton className={ className } />;

  return (
    <GroupHeroContainer className={ className }>
      <GroupHeroInner>
        <GroupLogo
          allowEditing={ location.pathname.includes('/account') }
          className='group-logo'
          group={ group }
        />
        <H1 className='header'>{ group?.name }</H1>
      </GroupHeroInner>
    </GroupHeroContainer>
  );
};

export const GroupHero = observer(GroupHeroBase);
