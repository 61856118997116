import styled from 'styled-components';

export const KarmaScoreContainer = styled.div`
  display: flex;
  margin: 0 auto;

  .checkbox-container {
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      flex-direction: row;
      width: unset;
    }
  }
`;
