import styled from 'styled-components';
import { CustomModal } from '../CustomModal';
import { SecondaryFont } from '../../../styles/styles';

export const SessionEndingModalContainer = styled(CustomModal)`
  max-width: 700px;
  max-height: 90vh;
  width: 90%;
    
  .ctas-container {
    justify-content: center;
  }

  .modal-content {
    text-align: center;

    h1 {
      ${SecondaryFont}
      color: ${({ theme }) => theme.colors.primary};
      font-size: 40px
      text-align: center;
    }
  }
`;
