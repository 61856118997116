import React, { useRef, useState } from 'react';
import { GirlWithMagnifierGraphic } from '../../components/svgs/graphics/GirlWithMagnifier';
import { IThemeProps } from '../../styles/themes';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { ContactUsPageBoundaryContainer, ContactUsPageContainer, ContactUsPageInnerWrapper, FormContainer, MessageField, SelectField, TitleContainer } from './styles';
import { H1M } from '../../styles/components/header';
import { TextField, TextFieldKind } from '../../components/TextField';
import { EnvelopeIcon } from '../../components/svgs/icons/EnvelopeIcon';
import { Button } from '../../components/Button';
import { ButtonKind } from '../../components/Button/styles';
import { PersonIcon } from '../../components/svgs/icons/PersonIcon';
import { PhoneIcon } from '../../components/svgs/icons/PhoneIcon';
import { validateEmail, validatePhoneNumber } from '../../lib/formatValidators';
import { useToaster } from '../../contexts/toaster-store';
import { ToastType } from '../../models/toaster';
import { ContactUsModel } from '../../models/contact-us';
import { useUserSession } from '../../contexts/user';
import { useAnalytics } from '../../contexts/analytics-store';

interface IProps extends IThemeProps {
  className?: string;
}

interface ITopicOption {
  value: Topic;
  text: string;
}

enum Topic {
  Support = 'Support',
  Press = 'Press',
  Inf = 'Influencer/Affiliate Program',
  B2B = 'B2B Partnership',
  Employer = 'Employer Program',
  NonProfit = 'Non-Profit Partnership',
  Data = 'Data Partner',
  Other = 'Other'
}

const topicOptions: ITopicOption[] = [
  { value: Topic.Support, text: 'Support' },
  { value: Topic.Press, text: 'Press' },
  { value: Topic.Inf, text: 'Influencer/Affiliate Program' },
  { value: Topic.B2B, text: 'B2B Partnership' },
  { value: Topic.Employer, text: 'Employer Program' },
  { value: Topic.NonProfit, text: 'Non-Profit Partnership' },
  { value: Topic.Data, text: 'Data Partner' },
  { value: Topic.Other, text: 'Other' },
];

const ContactUsBase: React.FC<IProps> = ({ className, theme }) => {
  const analytics = useAnalytics();
  const user = useUserSession();
  const toaster = useToaster();
  const contactUsModel = useRef(new ContactUsModel()).current;
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

  const fieldsContainValues = () => firstName?.length > 0 && email.length && message.length > 0 && selectedTopic !== '';
  const fieldsContainErrors = () => firstNameError === '' || emailError === '' || messageError === '';

  const enableSubmitButton = () => fieldsContainValues() && fieldsContainErrors() && validateEmail(email) || formSubmitting || contactUsModel.busy;

  const onContactUsSubmit = async () => {
    setFormSubmitting(true);
    try {
      await contactUsModel.submitContactUs({ email, firstName, lastName, phone, topic: selectedTopic, message, id: user._id });
      resetFields();
      toaster.push({ message: 'Your message was sent succesfully.' });
      analytics.fireEvent('ContactUsPage_SubmitSuccess');
    } catch (error) {
      toaster.push({ message: 'Your message was not sent, please try again later.', type: ToastType.Error });
      setFormSubmitting(false);
      analytics.fireEvent('ContactUsPage_SubmitFailed');
    } finally {
      setFormSubmitting(false);
    }
  };

  const resetFields = () => {
    setSelectedTopic('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  const selectChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTopic(event.target.options[event.target.selectedIndex].value);
  };

  const onMessageFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
    setMessageError(event.target.value.length === 0 ? 'A message is required' : '');
  };

  const onInformationFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'first-name':
        setFirstName(event.target.value);
        break;
      case 'last-name':
        setLastName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'phone':
        setPhone(event.target.value);
        break;
    }
  };

  const onInformationFieldBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'first-name':
        setFirstNameError(event.target.value === '' ? 'First Name is required' : '');
        break;
      case 'email':
        setEmailError(event.target.value === '' ? 'Email is required' : '');
        validateEmail(event.target.value) ? setEmailError('') : setEmailError('Please enter a valid email address');
        break;
      case 'phone':
        if (event.target.value.length > 0) {
          validatePhoneNumber(event.target.value).isValid ? setPhoneError('') : setPhoneError('Please enter a valid phone number');
        }
        break;
    }
  };

  const onInformationFieldFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'first-name':
        setFirstNameError('');
        break;
      case 'email':
        setEmailError('');
        break;
      case 'phone':
        setPhoneError('');
        break;
    }
  };

  const renderSelectField = () => {
    const options = topicOptions.map((option, index) => <option key={ index } value={ option.value }>{ option.text }</option>);

    return (
      <SelectField
        onChange={ selectChangeHandler }
        value={ selectedTopic }
        required
      >
        <option
          value=''
          disabled
        >
          Select a Topic
        </option>
        { options }
      </SelectField>
    );
  };

  const renderContactUsForm = () => (
    <form>
      <fieldset>
        <legend className='legend'>How can we help you</legend>
        { renderSelectField() }
        <div className='name-container'>
          <TextField
            className='name-field contact-us-field'
            id='first-name'
            label='First Name'
            labelHidden
            placeholder='First Name'
            leftAccessory={ <PersonIcon fill={ theme.colors.grey3 } /> }
            value={ firstName }
            type='text'
            required
            errorText={ firstNameError }
            fieldKind={ TextFieldKind.Pill }
            onChange={ onInformationFieldChange }
            onBlur={ onInformationFieldBlur }
            onFocus={ onInformationFieldFocus }
          />
          <TextField
            className='name-field contact-us-field'
            fieldKind={ TextFieldKind.Pill }
            id='last-name'
            label='Last Name'
            labelHidden
            placeholder='Last Name (optional)'
            value={ lastName }
            type='text'
            leftAccessory={ <PersonIcon fill={ theme.colors.grey3 } /> }
            onChange={ onInformationFieldChange }
          />
        </div>
        <TextField
          errorText={ emailError }
          required
          className='email-field contact-us-field'
          fieldKind={ TextFieldKind.Pill }
          id='email'
          label='Email'
          labelHidden
          placeholder='Email'
          leftAccessory={ <EnvelopeIcon stroke={ theme.colors.grey3 } /> }
          value={ email }
          type='email'
          onChange={ onInformationFieldChange }
          onBlur={ onInformationFieldBlur }
          onFocus={ onInformationFieldFocus }
        />
        <TextField
          className='phone-field contact-us-field'
          id='phone'
          label='Phone'
          errorText={ phoneError }
          placeholder='Phone Number (optional)'
          labelHidden
          type='tel'
          value={ phone }
          fieldKind={ TextFieldKind.Pill }
          leftAccessory={ <PhoneIcon fill={ theme.colors.grey3 } /> }
          onChange={ onInformationFieldChange }
          onBlur={ onInformationFieldBlur }
          onFocus={ onInformationFieldFocus }
        />
        <MessageField
          className='message-field'
          errorText={ messageError }
          id='message'
          label='Message'
          labelHidden
          onChange={ onMessageFieldChange }
          placeholder='Message...'
          required
          value={ message }
        />
        <Button
          disabled={ !enableSubmitButton() }
          kind={ ButtonKind.Primary }
          label='Submit'
          onClick={ () => onContactUsSubmit() }
        >
        Submit
        </Button>
      </fieldset>
    </form>
  );

  return (
    <ContactUsPageContainer className={ className } title='Contact Us'>
      <ContactUsPageBoundaryContainer backgroundColor={ theme.colors.dark }>
        <ContactUsPageInnerWrapper>
          <TitleContainer>
            <H1M className='title'><span className='invert'>Send</span> Us A Message</H1M>
            <p>Want to get in touch with the Karma Wallet team? Let's chat!</p>
            <GirlWithMagnifierGraphic
              className='girl-with-magnifier-graphic'
              fill={ theme.colors.primary }
              stroke={ theme.colors.dark }
            />
          </TitleContainer>
          <FormContainer>
            { renderContactUsForm() }
          </FormContainer>
        </ContactUsPageInnerWrapper>
      </ContactUsPageBoundaryContainer>
    </ContactUsPageContainer>
  );
};

const ContactUsObserver = observer(ContactUsBase);
export const ContactUs = withTheme(ContactUsObserver);
