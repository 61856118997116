import styled from 'styled-components';
import { FlexCol } from '../../styles/styles';

interface IProps {
  textColor?: string;
}

export const ArticleCardContainer = styled.div<IProps>`
  ${FlexCol}
  align-items: flex-start;
  min-width: 247px;
  width: 90%;
  max-width: 400px;
  margin-bottom: 20px;
  padding: 20px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 20px 40px 20px 0;
    width: 33%;
  }

  .image-container {
    ${FlexCol}
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.midGray};
    border-radius: 16px;
    height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;

    img {
      max-width: 100%;
      max-height: 95%;
    }
  }

  .article-title {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    color: ${(props) => props.textColor === 'dark' ? ({ theme }) => theme.colors.white : ({ theme }) => theme.colors.dark };
    font-size: 14px;
    line-height: 22px;
  }

  .cta {
    background-color: ${(props) => props.textColor === 'dark' ? ({ theme }) => theme.colors.darkYellow1 : ({ theme }) => theme.colors.primary };
    border-color: ${(props) => props.textColor === 'dark' ? ({ theme }) => theme.colors.darkYellow1 : ({ theme }) => theme.colors.primary };
    color: ${(props) => props.textColor === 'dark' ? ({ theme }) => theme.colors.darkGray1 : ({ theme }) => theme.colors.white };
    text-align: center;
    display: block;
  }
`;
