import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const RefreshIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }refresh-icon` }
    id={ id }
    width={ 30 }
    height={ 30 }
    stroke={ stroke }
    fill='none'
  >
    <path d='M22.5 17.5L22.5 12.5M22.5 12.5L26.25 16.25M22.5 12.5L18.75 16.25' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.5 12.5L7.5 17.5M7.5 17.5L11.25 13.75M7.5 17.5L3.75 13.75' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M22.5 17.5C22.5 21.6421 19.1421 25 15 25C12.7787 25 10.783 24.0344 9.40973 22.5' fill='transparent' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.49958 12.5C7.49958 8.35786 10.8574 5 14.9996 5C17.2208 5 19.2165 5.96563 20.5898 7.5' fill='transparent' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
