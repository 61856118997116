import styled from 'styled-components';
import { FlexColCenter, FlexHorizontalCenter } from '../../styles/styles';
import { TextMDMedium } from '../../styles/components/text';

export enum BenefitCardVariant {
  Green = 'green',
  Blue = 'blue',
  Orange = 'orange',
}
interface IProps {
  variant?: BenefitCardVariant;
  alignContent?: 'left' | 'center';
}

export const BenefitCardContainer = styled.div<IProps>`
  ${FlexHorizontalCenter}
  background-color: ${({ theme }) => `${theme.colors.secondary}0D`};
  border: 1px solid ${({ theme, variant }) => {
    switch (variant) {
      case BenefitCardVariant.Blue:
        return theme.colors.primary;
      case BenefitCardVariant.Green:
        return theme.colors.secondary;
      case BenefitCardVariant.Orange:
        return theme.colors.orange1;
      default:
        return theme.colors.secondary;
    }
  }};
  border-radius: 20px;
  gap: 18px;
  justify-content: center;
  min-height: 110px;
  min-width: 235px;
  padding: 8px;
  text-align: ${({ alignContent }) => alignContent};
  width: 100%;

  p {
    margin: unset;
  }

  .description {
    ${TextMDMedium}
    color: ${({ theme }) => theme.colors.white};
    width: ${({ alignContent }) => (alignContent === 'left' ? '150px' : 'unset')};
  }

  .title {
    color: ${({ theme, variant }) => {
    switch (variant) {
      case BenefitCardVariant.Blue:
        return theme.colors.primary;
      case BenefitCardVariant.Green:
        return theme.colors.secondary;
      case BenefitCardVariant.Orange:
        return theme.colors.orange1;
      default:
        return theme.colors.secondary;
    }
  }};
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
  }

  @media (${({ theme }) => theme.breakpoints.smMin}) {
    .title {
      font-size: 23px;
    }
  }
`;

export const InnerWrapper = styled.div`
  ${FlexColCenter}
  gap: 4px;
  height: 100%;
  justify-content: center;
`;

export const GraphicContainer = styled.div`
  min-width: 48px;
  height: auto;
`;
