import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { ICampaign } from './campaigns';
import { inDateRange } from '../lib/dates';

type PrivateFields = '_busy' |
'_promos' |
'_activeCreateAccountPromo' |
'_activeLinkCardAndCreateAccountPromo' |
'_activeLinkCardPromo' |
'_checkedForPromos';

type PrivatePromoFields = '_busy' |
'_promo' |
'_isAdmin';

export enum IPromoTypes {
  CASHBACK = 'cashback',
  GIFTCARD = 'giftcard',
  OTHER = 'other',
}

export enum IPromoEvents {
  CREATE_ACCOUNT = 'Create Account',
  LINK_CARD = 'Link Card',
}

export enum IPromoEventGroups {
  CREATE_ACCOUNT = 'Create Account',
  LINK_CARD = 'Link Card',
  CREATE_AND_LINK = 'Create Account and Link Card',
}

export interface IPromoUpdate {
  amount: number;
  campaign?: string;
  disclaimerText: string;
  enabled: boolean;
  endDate?: string;
  events: IPromoEvents[];
  headerText: string;
  imageUrl?: string;
  limit: number;
  name: string;
  promoText: string;
  startDate?: string;
  successText: string;
  type: IPromoTypes;
}

export interface IPromo {
  _id: string;
  amount: number;
  campaign?: ICampaign;
  disclaimerText?: string;
  enabled: boolean;
  endDate?: Date;
  events: IPromoEvents[];
  headerText: string;
  imageUrl?: string;
  limit: number;
  name: string;
  promoText: string;
  startDate?: Date;
  successText: string;
  type: IPromoTypes;
}

export class PromoModel extends BaseModel {
  private _busy = false;
  private _isAdmin = false;
  private _promo: IPromo = null;

  constructor (promoInfo: IPromo, isAdmin = false) {
    super();
    makeObservable<PromoModel, PrivatePromoFields>(this, {
      _busy: observable,
      _id: computed,
      _isAdmin: observable,
      _promo: observable,
      amount: computed,
      busy: computed,
      campaign: computed,
      disclaimerText: computed,
      enabled: computed,
      endDate: computed,
      events: computed,
      headerText: computed,
      imageUrl: computed,
      limit: computed,
      name: computed,
      promoText: computed,
      startDate: computed,
      successText: computed,
      createPromo: action.bound,
      updatePromo: action.bound,
    });

    this._isAdmin = !!isAdmin;
    this._promo = promoInfo;
  }

  get _id() { return this._promo?._id; }
  get amount() { return this._promo?.amount; }
  get busy() { return this._busy; }
  get campaign() { return this._promo?.campaign; }
  get disclaimerText() { return this._promo?.disclaimerText; }
  get enabled() { return this._promo?.enabled; }
  get endDate() { return this._promo?.endDate; }
  get events() { return this._promo?.events; }
  get headerText() { return this._promo?.headerText; }
  get imageUrl() { return this._promo?.imageUrl; }
  get limit() { return this._promo?.limit; }
  get name() { return this._promo?.name; }
  get promoText() { return this._promo?.promoText; }
  get startDate() { return this._promo?.startDate; }
  get successText() { return this._promo?.successText; }
  get type() { return this._promo?.type; }

  public updatePromo = async (data: IPromoUpdate) => {
    if (this._busy || !this._isAdmin) return;
    runInAction(() => this._busy = true );

    const result = await this.webServiceHelper.sendRequest<IPromo>(
      {
        path: `/admin/promo/${this._id}`,
        method: 'PUT',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  public createPromo = async (data: IPromoUpdate) => {
    if (this._busy || !this._isAdmin) return;
    runInAction(() => this._busy = true);

    const result = await this.webServiceHelper.sendRequest<IPromo>(
      {
        path: '/admin/promo',
        method: 'POST',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._promo = result.value;
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };
}

export class PromosModel extends BaseModel {
  private _activeCreateAccountPromo: PromoModel = null;
  private _activeLinkCardAndCreateAccountPromo: PromoModel = null;
  private _activeLinkCardPromo: PromoModel = null;
  private _busy = false;
  private _checkedForPromos = false;
  private _promos: PromoModel[] = [];

  constructor() {
    super();
    makeObservable<PromosModel, PrivateFields>(this, {
      _activeCreateAccountPromo: observable,
      _activeLinkCardAndCreateAccountPromo: observable,
      _activeLinkCardPromo: observable,
      _busy: observable,
      _checkedForPromos: observable,
      _promos: observable,
      promos: computed,
      activeCreateAccountPromo: computed,
      loadPromos: action.bound,
    });
  }

  get activeCreateAccountPromo () { return this._activeCreateAccountPromo; }
  get activeLinkCardAndCreateAccountPromo () { return this._activeLinkCardAndCreateAccountPromo; }
  get activeLinkCardPromo () { return this._activeLinkCardPromo; }
  get busy () { return this._busy; }
  get checkedForPromos () { return this._checkedForPromos; }
  get promos () { return this._promos; }

  public loadPromos = async (isAdmin = false) => {
    runInAction(() => this._busy = true);

    const result = await this.webServiceHelper.sendRequest<IPromo[]>({
      path: '/promo',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._busy = false;
        this._promos = result.value.map((promo) => new PromoModel(promo, isAdmin));
        const urlparams = new URLSearchParams(window.location.search);
        const currentlyActivePromos = this._promos.filter(p => (!!p.startDate && !!p.endDate && inDateRange(p.endDate, p.startDate) || (!p.startDate && !p.endDate)) && p.enabled);
        for (const promo of currentlyActivePromos) {
          // if there is an associated campaign but the param does not match the campaign name, skip this promo
          if (!!promo.campaign && !urlparams.get(promo.campaign.paramKeyToUse)) continue;
          if (!!promo.campaign && !!urlparams.get(promo.campaign.paramKeyToUse) && urlparams.get(promo.campaign.paramKeyToUse)?.indexOf(promo.campaign.name) === -1) continue;

          if (promo.events.length === 1) {
            if (promo.events.includes(IPromoEvents.CREATE_ACCOUNT)) this._activeCreateAccountPromo = promo;
            if (promo.events.includes(IPromoEvents.LINK_CARD)) this._activeLinkCardPromo = promo;
          }

          if (promo.events.length === 2) {
            if (promo.events.includes(IPromoEvents.CREATE_ACCOUNT) && promo.events.includes(IPromoEvents.LINK_CARD)) {
              this._activeLinkCardAndCreateAccountPromo = promo;
            }
          }
        }
      });
    }
    
    runInAction(() => {
      this._busy = false;
      this._checkedForPromos = true;
    });
  };

  public updatePromo = async (id: string, data: IPromo) => {
    runInAction(() => {
      this._promos = this._promos.map((promo) => {
        if (promo._id === id) {
          return new PromoModel(data, true);
        } else {
          return promo;
        }
      });
    });
  };

  public addPromo = async (data: IPromo) => {
    runInAction(() => this._promos.push(new PromoModel(data, true)));
  };
}
