import React, { useMemo } from 'react';
import { ApplyForCardModel } from '../../../models/karmaCard';
import { ApplyPersonaModalContainer } from './styles';
import { useAnalytics } from '../../../contexts/analytics-store';
import { getTemplateNameFromId } from '../../../lib/misc';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
  applyForCardModel: ApplyForCardModel;
}

export const ApplyPersonaModalBase: React.FC<IProps> = ({
  className = '',
  applyForCardModel,
}) => {
  const analytics = useAnalytics();
  
  const templateName = useMemo(() => 
    getTemplateNameFromId(applyForCardModel.currentTemplateId), 
  [applyForCardModel.currentTemplateId]);

  const onCancel = () => {
    analytics.fireEvent(`Inquiry_Cancel_${templateName ? '_' + templateName : ''}`);
    applyForCardModel.setOpenPersonaModal(false);
    applyForCardModel.setAwaitingApplicationDecision(false);
  };

  const onComplete = async () => {
    analytics.fireEvent('ApplyForCard_Application_Sucessful');
    applyForCardModel.setOpenPersonaModal(false);
  };
  
  if (!applyForCardModel.openPersonaModal || !applyForCardModel.customInquiryData) return null;
  
  return (
    <ApplyPersonaModalContainer
      className={ className }
      accountId={ applyForCardModel.personaAccountId }
      fields={ applyForCardModel.customInquiryData }
      templateId={ applyForCardModel.currentTemplateId }
      onComplete={ onComplete }
      onCancel={ onCancel }
      isOpen={ applyForCardModel.openPersonaModal }
    />
  );
};

export const ApplyPersonaModal = observer(ApplyPersonaModalBase);
