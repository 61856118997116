import React, { createContext } from 'react';
import { AppType } from '../constants';
import { UserSessionModel } from '../models/users';
import { useSocket } from './socket-client';

interface IProps {
  app?: AppType;
  children: React.ReactNode;
}

export const UserSessionContext = createContext<UserSessionModel>(null);

export const useUserSession = () => React.useContext(UserSessionContext);

export const UserSessionStore: React.FC<IProps> = ({ children, app }) => {
  const socket = useSocket();
  return (
    <UserSessionContext.Provider value={ new UserSessionModel(socket, app) }>
      { children }
    </UserSessionContext.Provider>
  );
};
