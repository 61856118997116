import React from 'react';
import { OurImpactContainer } from './styles';
import { OurImpactHero } from '../../components/OurImpactHero';
import { OurImpactDataSources } from '../../components/OurImpactDataSources';
import { OurImpactReports } from '../../components/OurImpactReports';
import { OurImpactCertifications } from '../../components/OurImpactCertifications';
import { OurImpactPartnerships } from '../../components/OurImpactPartnerships';

interface IProps {
  className?: string;
}

export const OurImpact: React.FC<IProps> = ({
  className = '',
}) => (
  <OurImpactContainer title='Our Impact' className={ className }>
    <OurImpactHero />
    <OurImpactCertifications />
    <OurImpactPartnerships />
    <OurImpactDataSources />
    <OurImpactReports />
  </OurImpactContainer>
);
