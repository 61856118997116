import styled from 'styled-components';
import { MaxBoundaryContainer } from '../../styles/components/containers';
import { FlexCenter, FlexCol, FlexHorizontalCenter, PrimaryFont, TruncateText } from '../../styles/styles';

export const Accessory = styled.div`
  ${ FlexCenter }
`;

export const GroupCodeSubFieldSection = styled.div`
  ${ FlexHorizontalCenter }
  justify-content: space-between;
  margin-bottom: 20px;

  .random-code-cta {
    padding: 0;
  }
`;

export const GroupSettingField = styled.div`
  ${FlexHorizontalCenter}
  flex-wrap: wrap;

  &:not(:last-child) {
    padding-bottom: 30px;
  }

  &.unsupported {
    opacity: 0.4;
  }

  h3,
  h4 {
    text-transform: capitalize;

    &:not(:last-child) {
      padding-right: 10px;
    }
  }

  .setting-field-value {
    &.enabled {
      color: ${ ({ theme }) => theme.colors.green };
    }

    &.disabled {
      color: ${({ theme }) => theme.colors.red }
    }
  }

  .setting-field-alt-value {
    ${ PrimaryFont }
    ${ TruncateText }
    min-width: 100%;
    max-width: 100%;
    font-size: 24px;
    line-height: 30px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  p {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    font-size: 15px;
  }
`;

export const GroupSettingsContainer = styled(MaxBoundaryContainer)`
  h1 {
    text-align: center;
  }

  .settings-bottom-row {
    padding-top: 15px;
  }

  .group-code-copy-container {
    ${FlexCol}
    align-items: flex-start;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding: 20px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    .settings-bottom-row {
      display: none;
    }
  }
`;

export const GroupSettingsHeader = styled.div`
  position: relative;

  .url-container {
    display: flex;
  }

  .group-settings-ctas {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const GroupSettingsInner = styled.div`
  & > * {
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray1}` };
    }
  }

  .text-field {
    &:not(:last-child):not(.setting-text-field) {
      margin-bottom: 20px;
    }

    &.group-code-field-input {
      margin-bottom: 0;
    }

    input {
      background: transparent;
    }
  }

  .input-field-error,
  .input-field-success {
    margin: 0;
    font-size: 12px;
    line-height: 1.2em;
  }

  .input-field-error {
    color: ${({ theme }) => theme.colors.red};
  }

  .input-field-success {
    color: ${({ theme }) => theme.colors.green};
  }

  .input-field-support-text {
    margin: 0;
    padding: 3px 0 0 35px;
    font-size: 14px;
    line-height: 1.2em;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${ FlexHorizontalCenter }
    align-items: stretch;

    & > * {
      min-width: 50%;
      max-width: 50%;
      padding: 40px 20px 0;
      
      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }
`;

export const MatchTextFieldContainer = styled.div`
  min-width: 125px;
  max-width: 125px;
  margin: 15px 20px 0 0;

  &:last-child {
    min-width: 160px;
    max-width: 160px;
  }

  .text-field {
    &:not(:last-child):not(.setting-text-field) {
      margin-bottom: 5px;
    }
  }

  .pill-label {
    text-align: center;

    .title-with-info {
      ${ FlexHorizontalCenter }
      ${ PrimaryFont }
      justify-content: center;

      svg {
        width: 12px;
        height: 12px;
        margin: 0 0 0 5px;
      }
    }
  }

  .input-field-support-text {
    padding: 0;
    text-align: center;
  }

  .input-container.small input {
    font-size: 20px;
    font-weight: 500;
    background: #fff;
    line-height: 28px;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
    }
  }
`;

export const Setting = styled.div`
  padding: 5px 0;

  &.indent {
    padding-left: 20px;
  }

  &.unsupported {
    opacity: 0.4;
  }

  .setting-text-field {
    padding: 15px 0 0 20px;
  }

  p {
    margin: 0;
  }
`;

export const MatchSetting = styled(Setting)`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
`;

export const PrivacyStatusContainer = styled.div`
  ${ FlexCol }
`;

export const SettingsBottomRow = styled.div`
  min-width: 100%;
  max-width: 100%;
`;
