import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const EmojiNeutralIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }emoji-neutral-icon` }
    id={ id }
    width={ 25 }
    height={ 26 }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M2.40869 12.9879C2.40869 7.32694 6.99777 2.73785 12.6587 2.73785C18.3196 2.73785 22.9087 7.32694 22.9087 12.9879C22.9087 18.6488 18.3196 23.2379 12.6587 23.2379C6.99777 23.2379 2.40869 18.6488 2.40869 12.9879ZM12.6587 0.737854C5.8932 0.737854 0.408691 6.22237 0.408691 12.9879C0.408691 19.7533 5.8932 25.2379 12.6587 25.2379C19.4242 25.2379 24.9087 19.7533 24.9087 12.9879C24.9087 6.22237 19.4242 0.737854 12.6587 0.737854ZM9.02403 17.7312C8.47543 17.7949 7.97907 17.4018 7.91537 16.8532C7.85167 16.3046 8.24475 15.8082 8.79335 15.7445C9.61414 15.6492 10.8464 15.5816 12.6587 15.5816C14.4709 15.5816 15.7032 15.6492 16.524 15.7445C17.0726 15.8082 17.4657 16.3046 17.402 16.8532C17.3383 17.4018 16.8419 17.7949 16.2933 17.7312C15.5789 17.6482 14.4312 17.5816 12.6587 17.5816C10.8862 17.5816 9.73843 17.6482 9.02403 17.7312ZM7.90869 10.4878C7.90869 9.93556 8.35641 9.48785 8.90869 9.48785H8.91985C9.47213 9.48785 9.91985 9.93556 9.91985 10.4878C9.91985 11.0401 9.47213 11.4878 8.91985 11.4878H8.90869C8.35641 11.4878 7.90869 11.0401 7.90869 10.4878ZM16.4087 9.48785C15.8564 9.48785 15.4087 9.93556 15.4087 10.4878C15.4087 11.0401 15.8564 11.4878 16.4087 11.4878H16.4198C16.9721 11.4878 17.4198 11.0401 17.4198 10.4878C17.4198 9.93556 16.9721 9.48785 16.4198 9.48785H16.4087Z' fill={ fill } />
  </SvgIcon>
);
