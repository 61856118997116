import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { BasicContainer, FlexCenter, FlexCol, PrimaryFont } from '../../styles/styles';

export const DonationSectionContainer = styled.div`
  ${FlexCol}
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;

  sub {
    font-size: 10px;
  }

  .header-section {
    margin-bottom: 20px;
    text-align: center;
  }

  .select-donation-amount-text {
    ${PrimaryFont}
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: row;
    margin-top: 50px;

    .header-section {
      text-align: left;
      padding-right: 20px;
    }
  }
`;

export const DonationOptions = styled.div`
  width: 100%;
  max-width: 560px;

  .cta {
    margin: 20px auto;
  }

  .donation-sub-text {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGray3};
    text-align: center;
  }
`;

export const DonationWidgetContainer = styled.div`
  .picker-title {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.darkGray3};
    border: solid 2px ${({ theme }) => theme.colors.darkGray1};
    border-bottom: none;
    padding: 10px 20px;
    border-radius: 20px 20px 0 0;
    width: 80%;
    margin: 0 auto;
    text-align: center;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      display: none;
    }
  }

  .options {
    ${BasicContainer}
    ${FlexCol}
    border-radius: 20px;
    background-color: #fff;
    padding: 16px;

    .option:first-of-type {
      border-radius: 20px 20px 0 0; 
    }

    .option:not(:last-of-type) {
      border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray2};
    }

    .option:last-of-type {
      border-radius: 0 0 20px 20px;
    }
  }

  .option {
    ${FlexCenter}
    border: none;
    background-color: #fff;
    padding: 10px;

    .radio-button-wrapper {
      width: 10%;
    }

    &.selected {
      .amount-text {
        color: #fff;
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }

    .option-description {
      position: relative;
      margin-bottom: 0;
      width: 65%;

      .spinner {
        width: 20px;
        height: 20px;
      }
    }
  }

  .sub-text {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;

    & > p {
      color: ${({ theme }) => theme.colors.disabled};
      margin: 0 auto;
      text-align: center;
      max-width: 500px;
    }
  }
        
  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    .options {
      padding: 10px;
      
      .option {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

export const DonationOptionsSkeleton = styled(Skeleton)`
  width: 90vw;
  height: 400px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: 560px;
  }
`;

export const DonationOptionsErrorContainer = styled.div`
 ${FlexCenter}
  width: 90vw;
  border-radius: 20px;
  height: 400px;
  background: ${({ theme }) => theme.colors.light};

  & > div {
    color: ${({ theme }) => theme.colors.lightGray1};
    max-width: 300px;
    margin: 0 auto;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: 560px;
  }
`;
