import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { CardsContainer, CompanyInfoContainer, CompareCompanyCard, CompareModalControlsContainer, FilterCompaniesModalContainer, PlaceholderCard } from './styles';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { CompareCompaniesModel, ISelectedCompany } from '../../models/compare-companies';
import { Button } from '../Button';
import { XIcon } from '../svgs/icons/XIcon';
import { ButtonKind } from '../Button/styles';
import { DefaultCompanyLogo } from '../svgs/graphics/DefaultCompanyLogo';
import { config, useSpring } from 'react-spring';

interface IProps extends IThemeProps {
  className?: string;
  isOpen: boolean;
  companies: ISelectedCompany[];
  compareCompaniesModel?: CompareCompaniesModel;
}

const to = {
  bottom: '0px',
};

const from = {
  bottom: '-100%',
};

const CompareCompaniesModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  companies,
  compareCompaniesModel,
  theme,
}) => {
  const analytics = useAnalytics();

  const spring = useSpring({
    config: config.default,
    from: from,
    to: isOpen ? to : from,
  });

  const onCompareClick = useCallback(() => {
    analytics.fireEvent('Compare_Modal_Compare_Click');
    window.open(`/compare-companies?company1=${companies[0]._id}&company2=${companies[1]._id}${ !!companies[2] ? `&company3=${companies[2]._id}` : '' }`, '_blank');
  },[companies]);

  const onClearAllClick = useCallback(() => {
    analytics.fireEvent('Compare_Modal_Clear_All_Click');
    compareCompaniesModel?.removeSelectedCompanies();
  }, []);

  const onRemoveCompanyClick = useCallback((companyId: string) => {
    compareCompaniesModel.removeSelectedCompany(companyId);
  }, []);

  const renderPlaceholderCard = useCallback((key: string) => (
    <PlaceholderCard key={ key }>
      <p>Add up to 3 companies to compare</p>
    </PlaceholderCard>
  ), []);

  const renderCompareCompanyCards = useMemo(() => companies.map((company) => (
    <CompareCompanyCard key={ company?._id }>
      <CompanyInfoContainer>
        {
          !!company?.companyLogo
            ? (
              <div className='logo-container'>
                <img src={ company?.companyLogo } alt={ company?.companyName } />
              </div>
            )
            : (
              <div className='logo-container'>
                <DefaultCompanyLogo />
              </div>
            )
        }
        {
          !!company?.companyLogo
            ? (
              <div className='company-name'>
                { company?.companyName }
              </div>
            ) 
            : (
              <div className='company-name'>
                { company?.companyName }
              </div>
            ) 
        }
      </CompanyInfoContainer>
      <Button 
        className='remove-button'
        kind={ ButtonKind.Blank }
        onClick={ () => onRemoveCompanyClick(company._id) }
      >
        <XIcon stroke={ theme.colors.black } /></Button>
    </CompareCompanyCard>
  )),[companies, compareCompaniesModel]);

  const renderContents = useMemo(() => {
    const content: JSX.Element[] = renderCompareCompanyCards;

    if ( content.length < 2) {
      content.push(renderPlaceholderCard('placeholder-1'));
    }

    if ( content.length < 3) {
      content.push(renderPlaceholderCard('placeholder-2'));
    }

    return <CardsContainer>{ content }</CardsContainer>;
  }, [renderCompareCompanyCards, renderPlaceholderCard]);

  const renderButtons = useMemo(() => {
    if (companies.length === 0) return null;

    return (
      <CompareModalControlsContainer>
        <Button
          disabled={ companies.length < 2 }
          kind={ ButtonKind.Primary }
          onClick={ onCompareClick }
        >
          Compare
        </Button>
        <Button
          kind={ ButtonKind.Blank }
          onClick={ onClearAllClick }
        >
          Clear All
        </Button>
      </CompareModalControlsContainer>
    );
  }, [companies, onCompareClick, onClearAllClick]);

  return (
    <FilterCompaniesModalContainer
      className={ className }
      style={ spring }
    > 
      { renderContents }
      { renderButtons }
    </FilterCompaniesModalContainer>
  );
};

const CompareCompaniesModalObserver = observer(CompareCompaniesModalBase);
export const CompareCompaniesModal = withTheme(CompareCompaniesModalObserver);
