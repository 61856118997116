import React, { Ref } from 'react';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';

import { _ArrowButton, ArrowButtonContainer } from './styles';

export enum ArrowButtonKind {
  Next = 'next',
  Previous = 'previous',
  Up = 'up',
}

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  kind: ArrowButtonKind;
  ref?: Ref<HTMLButtonElement>;
  onClick(): void;
}

export const ArrowButton: React.FC<IProps> = ({
  className = '',
  kind,
  onClick,
  ref,
  ...restProps
}) => (
  <ArrowButtonContainer className={ className }>
    <_ArrowButton
      { ...restProps }
      className={ `arrow-button ${kind}` }
      onClick={ onClick }
      ref={ ref }
    >
      <ChevronIcon direction={ kind === ArrowButtonKind.Next ? ChevronDirection.Right : kind === ArrowButtonKind.Up ? ChevronDirection.Up : ChevronDirection.Left } />
    </_ArrowButton>
  </ArrowButtonContainer>
);
