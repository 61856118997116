import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { sortScores, ISortedCategories } from '../../lib/unsdgs';
import { ICompanyUnsdgScore } from '../../models/companies';
import { People } from '../svgs/graphics/People';
import { Planet } from '../svgs/graphics/Planet';
import { LegacyUNSDGScoresContainer } from './styles';

/**
 * class names provided for all elements to support
 * customized styling across pages.
 */

interface IProps {
  className?: string;
  unsdgs: ICompanyUnsdgScore[];
  withCategoryGraphics?: boolean;
}

const LegacyUNSDGScoresBase: React.FC<IProps> = ({
  className = '',
  unsdgs,
  withCategoryGraphics,
}) => {
  const [sortedScores, setSortedScores] = useState<ISortedCategories[]>([]);

  useEffect(() => {
    setSortedScores(sortScores(unsdgs));
  }, [unsdgs]);

  const renderCategoryGraphic = useCallback((category: string) => {
    // TODO: host these and add them to resource in
    //   db vs having them static here.
    if (!withCategoryGraphics) return;

    return category === 'Planet'
      ? <Planet className='category-graphic' />
      : <People className='category-graphic' />;
  }, []);

  const renderUNSDGScores = () => {
    if (!sortedScores.length) return null;

    const categories = sortedScores.map(sortedScore => {
      const category = (
        <div key={ `${sortedScore.category._id}-name` } className='unsdg-category-name'>
          { renderCategoryGraphic(sortedScore.category.name) }
          { sortedScore.category.name }
        </div>
      );

      const subcategories = sortedScore.subcategories.map(subcategory => {
        const unsdgs = subcategory.unsdgs.map(unsdg => (
          <div
            key={ unsdg.unsdg.title }
            className={ `unsdg ${ unsdg.value > 0 ? 'active' : 'inactive' }` }
          />
        ));

        return (
          <div
            key={ subcategory.subcategory.name }
            className='unsdg-subcategory-name'
          >
            { subcategory.subcategory.name }
            <div className='unsdgs-container'>{ unsdgs }</div>
          </div>
        );
      });

      return (
        <div
          key={ sortedScore.category.name }
          className='unsdg-category'
        >
          { category }
          <div className='unsdg-subcategories-container'>
            { subcategories }
          </div>
        </div>
      );
    });

    return (
      <div className='unsdg-scores-container'>
        { categories }
      </div>
    );
  };

  return (
    <LegacyUNSDGScoresContainer className={ className }>
      { renderUNSDGScores() }
    </LegacyUNSDGScoresContainer>
  );
};

export const LegacyUNSDGScores = observer(LegacyUNSDGScoresBase);
