import styled from 'styled-components';
import { FlexCenter } from '../../styles/styles';

export const PartnershipSectionContainer = styled.div`
  ${FlexCenter}
  justify-content: space-between;
  width: 100%;
  max-width: 420px;
  margin: 0 auto 20px;

  .kw-logo {
    height: 80px;
    width: 160px;
  }

  .partner-logo {
    height: 80px;
  }

  .plus-icon {
    width: 36px;
    height: 36px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    text-align: center;
    margin-bottom: 50px;

    .kw-logo {
      height: 100px;
      width: 200px;
    }
  
    .partner-logo {
      height: 100px;
    }
  }
`;
