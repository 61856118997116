import React from 'react';
import { H2 } from '../../../styles/components/header';
import { ButtonKind } from '../../../components/Button/styles';
import { useAnalytics } from '../../../contexts/analytics-store';
import { theme } from '../../../styles/themes';
import { SuperpowerSectionContainer } from './styles';
import { ApplyButton } from '../styles';

interface IProps {
  className?: string;
}

export const SuperPowerSection: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();
  
  const onApplyForFreeClick = () => {
    analytics.fireEvent('KarmaCollectivePage_ApplyForFree_Click');
  };

  return (
    <SuperpowerSectionContainer backgroundColor={ theme.colors.grey1 } className={ className }>
      <H2>Turn Your Values Into a Superpower</H2>
      <div className='content'>
        <p>Karma Wallet turns your ethical & sustainable practices into fuel for growth by matching you with customers that share your values.</p>
        <p>Offer exclusive deals & cashback to your target audience through Karma Wallet and use our impact analytics to see how you stack up against your competition.</p>
        <p>Become a part of our partner ecosystem with a reach of over 2M+.</p>
        <ApplyButton
          onClick={ onApplyForFreeClick }
          kind={ ButtonKind.Primary }
          href='https://docs.google.com/forms/d/e/1FAIpQLSd6ViYM9iVkK_T_HWNDnNUDtQwFjmT4k2p8613IwOO7KQOEeg/viewform'
          target='_blank'
          rel='noreferrer'
        >
          Apply for Free
        </ApplyButton>
      </div>
      <img
        src='https://cdn.karmawallet.io/uploads/neHj46TZgZ-karma-collective-logos-row-layout.png'
        className='karma-collective-logos'
        alt='Karma Collective Logos'
      />
    </SuperpowerSectionContainer>
  );
};
