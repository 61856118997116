import React from 'react';
import { CheckIcon } from '../../../svgs/icons/CheckIcon';
import { ValueCircleContainer } from './styles';
import { IThemeProps } from '../../../../styles/themes';
import { withTheme } from 'styled-components';

interface IProps extends IThemeProps {
  className?: string;
  hasValue: boolean;
}

export const ValueCircleBase: React.FC<IProps> = ({
  className = '',
  hasValue,
  theme,
}) => (
  <ValueCircleContainer className={ `${className} ${hasValue ? 'filled' : ''}` }>
    { 
      hasValue && 
      <CheckIcon
        stroke={ theme.colors.dark }
        strokeWidth={ 3 }
      />
    }
  </ValueCircleContainer>
);

export const ValueCircle = withTheme(ValueCircleBase);
