import React from 'react';
import { UNSDGLegendIcon, UnsdgIconType } from '../UNSDGLegendIcon';
import { UNSDGsLegendWithTextContainer } from './styles';

interface IProps {
  className?: string;
}

export const UNSDGsLegendWithText: React.FC<IProps> = ({
  className = '',
}) => (
  <UNSDGsLegendWithTextContainer className={ className }>
    <div>
      <UNSDGLegendIcon type={ UnsdgIconType.Passed } /><p><b>Passed:</b> Successfully met the criteria</p>
    </div>
    <div>
      <UNSDGLegendIcon type={ UnsdgIconType.Partial } /><p><b>Partial:</b> Working towards the criteria</p>
    </div>
    <div>
      <UNSDGLegendIcon type={ UnsdgIconType.Neutral } /><p><b>Neutral:</b> Has data that supports both a pass and fail score, overall deemed inconclusive</p>
    </div>
    <div>
      <UNSDGLegendIcon type={ UnsdgIconType.Failed } /><p><b>Failed:</b> Working against the criteria</p>
    </div>
    <div>
      <UNSDGLegendIcon type={ UnsdgIconType.NoData } /><p><b>No data:</b> No data to rate against criteria</p>
    </div>
  </UNSDGsLegendWithTextContainer>
);
