import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { ButtonKind } from '../../../components/Button/styles';
import { IArticle } from '../../../models/article';
import { HeaderButtonContainer } from '../styles';
import { useAnalytics } from '../../../contexts/analytics-store';

interface IProps {
  article: IArticle;
  children?: React.ReactNode;
}

export const HeaderContainer: React.FC<IProps> = ({
  article,
  children,
}) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  
  const handleClick = useCallback( () => {
    analytics.fireEvent('IndustryReport_Company_Click', article.company?.companyName);
    navigate(`/company/${article.company._id}/${article.company.slug}`);
  }, [article]);

  if (article.company) {
    return (
      <HeaderButtonContainer
        kind={ ButtonKind.Blank }
        onClick={ handleClick }
        label='View Company'
      >
        { children }
      </HeaderButtonContainer>
    );
  }

  return (
    <>
      { children }
    </>
  );
};
