import React from 'react';
import { IArticle, IArticleType } from '../../../models/article';
import { ArticleInnerContainer, ArticleWrapper } from './styles';

interface IProps {
  className?: string;
  article: IArticle;
}

export const BodySection: React.FC<IProps> = ({
  className='',
  article,
}) => {
  const { body } = article;
  
  return (
    <ArticleWrapper className={ className }>
      {
        article.type === IArticleType.General
          ? (
            <div className='article-body-container' dangerouslySetInnerHTML={ { __html: body } } />
          )
          : (
            <ArticleInnerContainer>
              <div dangerouslySetInnerHTML={ { __html: body } } />
            </ArticleInnerContainer>
          )
      }
    </ArticleWrapper>
  );
};
