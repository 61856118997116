import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { useUserSession } from '../../../../contexts/user';
import { H1 } from '../../../../styles/components/header';
import { IThemeProps } from '../../../../styles/themes';
import { isValidEmail, passwordRules } from '../../../../utils/input-validation';
import { Button } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';
import { EyeToggle } from '../../../EyeToggle';
import { LockIcon } from '../../../svgs/icons/LockIcon';
import { TextFieldKind } from '../../../TextField';
import { FormField } from '../styles';
import { CreatePasswordContainer, Header } from './styles';
import { LoadingSpinner } from '../../../loading/LoadingSpinner';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { useSignInModal } from '../../../../contexts/sign-in';
import { useToaster } from '../../../../contexts/toaster-store';
import { SignInMode } from '../../../../models/sign-in';
import { PasswordRules } from '../../../PasswordRules';
import { EnvelopeIcon } from '../../../svgs/icons/EnvelopeIcon';

interface IProps extends IThemeProps {
  className?: string;
}

const CreatePasswordBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const analytics = useAnalytics();
  const errorMessages = useErrorMessages();
  const signInModal = useSignInModal();
  const toaster = useToaster();
  const user = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [genError, setGenError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const token = searchParams.get('createpassword');

    if (!!token) { 
      setToken(token);
      user.verifyPasswordToken(token)
        .catch(() => {
          analytics.fireEvent('NewPassword_Invalid_Token');
        });
      searchParams.delete('createpassword');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isValidEmail(email)) return;
    setEmailError('This isn\'t a valid e-mail address format');
  }, [email]);
  
  const onShowPasswordClick = useCallback(() => {
    setShowPassword(!showPassword);
    analytics.fireEvent(`NewPassword_ShowPass_${!showPassword}`);
  }, [showPassword]);

  const onPasswordChange = useCallback((validate: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setGenError('');
    setPasswordError(false);
    setPassword(newValue);

    if (validate && !!newValue) {
      const isValid = !passwordRules.find(r => !r.validate(newValue));
      if (!isValid) setPasswordError(true);
    } 
  }, [password]);

  const onPasswordFocus = useCallback(() => {
    analytics.fireEvent('NewPassword_Password_Interact');
  }, []);

  const onEmailFocus = useCallback(() => {
    analytics.fireEvent('NewPassword_Email_Interact');
  }, []);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGenError('');
    setEmailError('');
    setEmail(e.target.value);
  }, [email]);

  const onCreatePasswordClick = useCallback(() => {
    analytics.fireEvent('NewPassword_ResetPassword_Click');

    user.resetPassword({ token, newPassword: password })
      .then(() => {
        analytics.fireEvent('NewPassword_ResetPassword_Success');
        toaster.push({ message: 'Your password was successfully updated. Use your new password to log in.' });
        signInModal.setMode(SignInMode.SIGN_IN);
      })
      .catch((err) => {
        analytics.fireEvent('NewPassword_ResetPassword_Error');
        errorMessages.push({
          title: 'Error Updating Password',
          message: err,
        });
      });
  }, [password, token]);

  const onRequestNewLinkClick = useCallback(() => {
    analytics.fireEvent('NewPassword_NewToken_Click');

    user.createPasswordToken({ email })
      .then(() => {
        analytics.fireEvent('NewPassword_NewToken_Success');
        signInModal.close();
        toaster.push({ message: 'Password reset email sent - it will expire in 15 minutes.' });
      })
      .catch((err) => {
        analytics.fireEvent('NewPassword_NewToken_Error');
        errorMessages.push({
          title: 'Error Creating New Password Link',
          message: err.message,
        });
      });
  }, [email]); 

  const renderHeaderSection = useCallback(() => {
    if (!user.passwordTokenChecked) return;
    let header = 'Create New Password';
    let text = 'Enter a new password below.';

    if (!user.passwordTokenValid) {
      header = 'Link Expired';
      text = 'This link has expired. Confirm your email address and we\'ll send you a new password reset link.';
    }

    return (
      <Header>
        <H1>{ header }</H1>
        <p className='subtext'>{ text }</p>
      </Header>
    );
  }, [user.passwordTokenValid, user.passwordTokenChecked]);

  const renderFormField = useCallback(() => {
    if (!user.passwordTokenChecked) return;

    if (!user.passwordTokenValid) {
      return (
        <FormField
          autoComplete='email'
          errorText={ !email ? null : emailError }
          fieldKind={ TextFieldKind.Pill }
          id='user-email-input'
          label='Email Address'
          labelHidden
          leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
          onChange={ onEmailChange }
          onFocus={ onEmailFocus }
          placeholder='Email Address'
          value={ email }
        />
      );
    }

    return (
      <>
        <FormField
          // ariaDescription='8 character minimum. One uppercase character. One lowercase character. One number. One special character: @$!%*?&'
          autoComplete='new-password'
          fieldKind={ TextFieldKind.Pill }
          id='user-password-input'
          label='Password'
          labelHidden
          leftAccessory={ <LockIcon fill={ theme.colors.disabled } /> }
          onChange={ onPasswordChange(true) }
          onFocus={ onPasswordFocus }
          placeholder='Password'
          rightAccessory={ <EyeToggle show={ showPassword } onClick={ onShowPasswordClick } /> }
          type={ showPassword ? 'text' : 'password' }
          value={ password }
        />
        <PasswordRules password={ password } darkTheme />
      </>
    );
  }, [
    user.passwordTokenChecked,
    user.passwordTokenValid,
    email,
    password,
    emailError,
    passwordError,
    showPassword,
  ]);
  
  const renderCTA = useCallback(() => {
    if (!user.passwordTokenChecked) return;

    if (user.passwordTokenValid) {
      return (
        <Button
          kind={ ButtonKind.Primary }
          disabled={ passwordError || !password }
          onClick={ onCreatePasswordClick }
          className='cta'
        >
          Create Password
        </Button>
      );
    }

    return (
      <Button
        kind={ ButtonKind.Primary }
        disabled={ !!emailError || !email }
        onClick={ onRequestNewLinkClick }
        className='cta'
      >
        Request New Link
      </Button>
    );
  }, [user.passwordTokenChecked, user.passwordTokenValid, passwordError, emailError, email, password]);

  if (user.verifyingPasswordToken) {
    return (
      <CreatePasswordContainer>
        <LoadingSpinner color={ theme.colors.white } />
      </CreatePasswordContainer>
    );
  } 

  return (
    <CreatePasswordContainer className={ className }>
      { renderHeaderSection() }
      <form>
        { renderFormField() }
        { renderCTA() }
      </form>
      { !!genError && <p className='field-error'>{ genError }</p> }
    </CreatePasswordContainer>
  );
};

const CreatePasswordObserver = observer(CreatePasswordBase);
export const CreatePassword = withTheme(CreatePasswordObserver);
