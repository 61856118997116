import styled from 'styled-components';
import { MaxBoundaryContainer } from '../../styles/components/containers';
import { FlexCol, PrimaryFont } from '../../styles/styles';

export const GroupMemberDataContainer = styled(MaxBoundaryContainer)`
  ${ FlexCol }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const MembersChartChildrenContainer = styled.div`
  .header {
    ${PrimaryFont}
    font-size: 96px;
    line-height: 96px;
    font-weight: 900;
  }

  .sub-text {
    text-align: center;
  }
`;

export const OffsetChildrenContainer = styled.div`
  H2 {
    margin-bottom: 5px;

    &:nth-of-type(2) {
      margin-top: 10px;
    }
  }
`;

export const CircleWrapperContainer = styled.div`
  ${ FlexCol }
  align-items: center;
  margin-bottom: 40px;

  .circle-label-text {
    text-align: center;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-bottom: 0;
  }
`;

export const GroupMemberDataSkeletonContainer = styled(GroupMemberDataContainer)`
  .circle-skeleton {
    width: 250px;
    height: 250px;
    margin: 0 20px;
    border-radius: 50%;
  }

  .title-skeleton {
    width: 180px;
    height: 30px;
    margin-top: 25px;
  }
`;
