import React from 'react';
import { IThemeProps } from '../../../styles/themes';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { JourneySectionContainer, JourneySectionInnerContainer, TextContent } from './styles';
import { PrintWaveA } from '../../../components/svgs/shapes/PrintWave';
import { H2M } from '../../../styles/components/header';
import { useAppData } from '../../../contexts/app';
import { KWTimelineMobile } from '../../../components/svgs/graphics/KWTimeline';

interface IProps extends IThemeProps {
  className?: string;
}

const JourneySectionBase: React.FC<IProps> = ({
  className,
}) => {
  const appData = useAppData();

  return (
    <JourneySectionContainer className={ className }>
      <PrintWaveA className='wave-a' />
      <JourneySectionInnerContainer>
        <TextContent>
          <H2M>Join The Journey</H2M>
          <p>Our story began in 2020 with one simple question:<strong>“How can you use your money to change the world?”</strong></p>
          <p>Together father-son founders Jayant Khadilkar and Kedar Karkare built Karma Wallet - a financial ecosystem that leverages cutting-edge technology and data to give consumers the power to create change in the world with every single purchase.</p>
        </TextContent>
        {
          appData.windowWidth < 992 ? <KWTimelineMobile className='kw-timeline-mobile' /> : null
        }
      </JourneySectionInnerContainer>
    </JourneySectionContainer>
  );
};

const JourneySectionObserver = observer(JourneySectionBase);
export const JourneySection = withTheme(JourneySectionObserver);
