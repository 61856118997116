import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Airplane: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}airplane-graphic` }
    id={ id }
    height={ 88 } 
    width={ 85 }
  >
    <path d='M42.4998 67.5598L27.1309 78.5353V86.4645L42.4998 81.2391V67.5598Z' fill='#BABAC2' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M42.5 67.5598L57.869 78.5353V86.4645L42.5 81.2391V67.5598Z' fill='#BABAC2' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M42.5 29.4507V50.5237L1.53564 64.2244V54.6891L42.5 29.4507Z' fill='#BABAC2' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M42.5 29.4507V50.5237L83.4643 64.2244V54.6891L42.5 29.4507Z' fill='#BABAC2' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M48.1246 7.14634V83.172L42.6124 81.2499L36.1841 83.3915V7.14634C36.1841 5.65916 36.7748 4.23281 37.8265 3.18071C38.8782 2.12862 40.3048 1.53684 41.7928 1.53542H42.4945C43.2331 1.53331 43.9649 1.67689 44.6479 1.95791C45.3308 2.23894 45.9516 2.6519 46.4746 3.17313C46.9977 3.69436 47.4126 4.31363 47.6957 4.99542C47.9789 5.67722 48.1246 6.40815 48.1246 7.14634Z' fill='#DFDCE3' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
