import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppType } from '../constants';
import { BaseModel } from './base';

type PrivateFields = '_addItems' |
'_busy' |
'_firstPageLoaded' |
'_init' |
'_results' |
'_totalDocs';

export interface ICollectionResponse<T> {
  docs: T[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number;
  nextPage: number;
}

export interface ICollectionConfig {
  limit?: number;
}

export class CollectionModel<T, U> extends BaseModel {
  private _busy = false;
  private _baseApiUrl = '';
  private _firstPageLoaded = false;
  private _hasNextPage = false;
  private _index: Set<string> = new Set();
  private _nextPage: number = null;
  private _limit = 25;
  private _results: U[] = [];
  private _totalDocs = 0;
  private _transformer: (result: T) => U = null;

  constructor (baseApiUrl: string, transformer: (result: T) => U, config: ICollectionConfig = {}, app?: AppType) {
    super({}, null, app);
    makeObservable<CollectionModel<T, U>, PrivateFields>(this, {
      _busy: observable,
      _firstPageLoaded: observable,
      _results: observable,
      _totalDocs: observable,
      busy: computed,
      firstPageLoaded: computed,
      results: computed,
      _addItems: action.bound,
      _init: action.bound,
      loadMore: action.bound,
      push: action.bound,
      refresh: action.bound,
      reset: action.bound,
      unshift: action.bound,
    });
    const { limit } = config;

    this._limit = limit || 25;
    this._init(baseApiUrl, transformer);
  }

  get allResultsFetched() { return this._totalDocs === this._results.length; }
  get busy() { return this._busy; }
  get firstPageLoaded() { return this._firstPageLoaded; }
  get results() { return this._results; }
  get total() { return this._totalDocs; }

  public loadMore = async (queryParams: string | { [key: string]: any } = {}, preventAutoQueryGeneration?: boolean) => {
    if (this._busy || (this.allResultsFetched && this.firstPageLoaded) || (this.firstPageLoaded && !this._hasNextPage)) return;

    this._busy = true;

    const queryParamsStr = preventAutoQueryGeneration
      ?`?${queryParams}&page=${this._nextPage}&limit=${this._limit}`
      : '';

    const result = await this.webServiceHelper.sendRequest<ICollectionResponse<T>>({
      path: this._baseApiUrl,
      method: 'GET',
      queryParams: preventAutoQueryGeneration ? queryParamsStr : { ...(queryParams as { [key: string]: any }), page: this._nextPage, limit: this._limit },
      preventAutoQueryGeneration,
    });

    if (result.success) {
      runInAction(() => {
        this._totalDocs = result.value.totalDocs;
        this._addItems(result.value.docs.map(r => this._transformer(r)), 'push');
        this._hasNextPage = result.value.hasNextPage;
        this._firstPageLoaded = true;
        this._busy = false;
        this._nextPage = result.value.nextPage;
      });
    } else {
      runInAction(() => {
        this._firstPageLoaded = true;
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public refresh = async (queryParams: string | { [key: string]: any } = {}, preventAutoQueryGeneration?: boolean) => {
    this.reset();
    return this.loadMore(queryParams, preventAutoQueryGeneration);
  };

  public reset = () => {
    this._busy = false;
    this._firstPageLoaded = false;
    this._totalDocs = 0;
    this._index = new Set();
    this._results = [];
    this._nextPage = null;
  };

  public push = (item: U) => {
    this._addItems([item], 'push');
  };

  public unshift = (item: U) => {
    this._addItems([item], 'unshift');
  };

  private _addItems = (items: U[], method: 'push' | 'unshift') => {
    const itemsToAdd: U[] = [];
    items.forEach(i => {
      // use Set to prevent duplicates
      if (!this._index.has((i as any)._id)) {
        this._index.add((i as any)._id);
        itemsToAdd.push(i);
      }
    });

    if (itemsToAdd.length) {
      if (method === 'push') {
        this._results = [...this._results, ...itemsToAdd];
      } else if (method === 'unshift') {
        this._results = [...itemsToAdd, ...this._results];
      }
    }
  };

  private _init = (baseApiUrl: string, transformer: (result: T) => U) => {
    this._baseApiUrl = baseApiUrl;
    this._transformer = transformer;
  };
}
