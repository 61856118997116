import styled from 'styled-components';
import { TextLGDemiBold } from '../../../../styles/components/text';

export const EmployeeBenefitCardContainer = styled.div`
  max-width: 338px;
  text-align: center;

  p {
    margin-top: 7px;
    padding: 0 19px;
  }

  h3 {
    ${TextLGDemiBold}
  }

  img {
    height: auto;
    margin-bottom: 17px;
    max-width: 338px;
  }
`;
