import React, { createContext, FC, useState } from 'react';
import { DefaultTheme } from 'styled-components';
import { theme as defaultKarmaTheme } from '../styles/themes';

export const ThemeContext = createContext(null);

export const ThemeStore: FC<React.PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<DefaultTheme>(defaultKarmaTheme);

  return (
    <ThemeContext.Provider value={ { setTheme, theme } }>
      { children }
    </ThemeContext.Provider>
  );
};
