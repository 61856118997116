import React, { useMemo } from 'react';
import { SectionHeaderContainer, SubheaderText } from './styles';
import { H1, H1M, H2, H3 } from '../../styles/components/header';

export enum HeaderSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  MARKETING = 'marketing',
}

interface IProps {
  className?: string;
  headerText: string;
  subText?: React.ReactNode;
  headerSize?: HeaderSize;
  children?: React.ReactNode;
}

export const SectionHeader: React.FC<IProps> = ({
  className = '',
  headerText,
  subText,
  children,
  headerSize,
}) => {
  const headerTextComponent = useMemo(() => {
    if (headerSize === HeaderSize.SMALL) return <H3>{ headerText }</H3>;
    if (headerSize === HeaderSize.LARGE) return <H1>{ headerText }</H1>;
    if (headerSize === HeaderSize.MARKETING) return <H1M>{ headerText }</H1M>;
    return <H2>{ headerText }</H2>;
  }, [headerSize]);

  return (
    <SectionHeaderContainer className={ className }>
      { headerTextComponent }
      { !!children ? children : null }
      { subText && <SubheaderText className='subheader-text'>{ subText }</SubheaderText> }
    </SectionHeaderContainer>
  );
};
