import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';

import { SvgIcon } from '../../SvgIcon';
interface IProps {
  className?: string;
  id?: string;
}

export const KarmaWhiteKLogo: FC<IProps> = ({ className = '' }) => {
  const uniqueId = uuid();

  return (
    <SvgIcon
      className={ className }
      id={ uniqueId }
      width={ 57 }
      height={ 81 }
      fill='none'
    >
      <path d='M43.13 2.99597C35.4391 10.1818 17.0148 32.6548 18.198 43.1144C18.6769 53.6296 40.341 73.0784 49.0461 79.0158C52.314 81.2353 55.92 81.9567 56.8497 79.2655C57.8075 76.5743 53.9198 71.497 52.145 69.0278C46.3698 60.9541 35.6645 51.4655 34.9038 41.9769C34.1432 32.4883 43.2709 21.2796 47.7502 12.4291C49.1306 9.71013 52.1732 4.10575 50.8209 1.581C49.4687 -0.943741 46.0035 0.304759 43.13 2.99597Z' fill='url(#paint0_linear_135_799)' />
      <path d='M22.8471 28.9095C19.7764 34.3197 17.7762 39.4524 18.1988 43.1147C18.6777 53.6298 40.3418 73.0787 49.0469 79.016C52.3148 81.2355 55.9208 81.9569 56.8505 79.2657C57.8083 76.5745 53.9206 71.4972 52.1458 69.028C45.0747 59.1509 40.4545 54.4899 37.1866 49.2184C37.1021 49.0797 29.6647 39.8686 26.5095 34.5694C23.8331 30.1025 23.2979 28.1604 23.2979 28.1604C23.2979 28.1604 23.0725 28.4933 22.8471 28.9095Z' fill='white' />
      <path d='M6.70489 80.9583C10.4079 80.9583 13.4098 62.8352 13.4098 40.4792C13.4098 18.1231 10.4079 0 6.70489 0C3.00188 0 0 18.1231 0 40.4792C0 62.8352 3.00188 80.9583 6.70489 80.9583Z' fill='white' />
      <defs>
        <linearGradient id='paint0_linear_135_799' x1='18.1854' y1='43.0913' x2='54.0581' y2='40.3066' gradientUnits='userSpaceOnUse'>
          <stop offset='0.1197' stopColor='#909090' />
          <stop offset='0.635' stopColor='white' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
