import { observer } from 'mobx-react-lite';
import React, { createContext, useRef } from 'react';
import { JoinGroupConfirmationModal } from '../components/modals/JoinGroupConfirmationModal';
import { JoinGroupModal } from '../components/modals/JoinGroupModal';
import { JoinGroupModel } from '../models/join-group';

export const JoinGroupContext = createContext<JoinGroupModel>(null);

export const useJoinGroupModal = () => React.useContext(JoinGroupContext);

const JoinGroupStoreBase: React.FC<React.PropsWithChildren> = ({ children }) => {
  const joinGroup = useRef(new JoinGroupModel()).current;

  return (
    <JoinGroupContext.Provider value={ joinGroup }>
      { children }
      <JoinGroupModal
        isOpen={ joinGroup.isOpen }
        onClose={ joinGroup.close }
      />
      <JoinGroupConfirmationModal />
    </JoinGroupContext.Provider>
  );
};

export const JoinGroupStore = observer(JoinGroupStoreBase);
