import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const WarningNegativeIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }warning-negative-icon` }
    id={ id }
    fill={ fill || 'black' }
    width={ 35 }
    height={ 35 }
  >
    <path d='M21.4673 7.03488L30.9604 22.8463C32.3246 25.1172 31.6546 28.105 29.463 29.5188C28.7274 29.9951 27.87 30.2489 26.9937 30.2498H8.00615C5.4264 30.2498 3.33398 28.0823 3.33398 25.4062C3.33398 24.501 3.57907 23.6155 4.03948 22.8463L13.534 7.03488C14.8968 4.76397 17.7783 4.06838 19.9699 5.48222C20.5762 5.87322 21.0891 6.40447 21.4673 7.03488ZM17.5007 24.5831C17.8764 24.5831 18.2367 24.4339 18.5024 24.1682C18.7681 23.9025 18.9173 23.5422 18.9173 23.1665C18.9173 22.7907 18.7681 22.4304 18.5024 22.1647C18.2367 21.8991 17.8764 21.7498 17.5007 21.7498C17.1249 21.7498 16.7646 21.8991 16.4989 22.1647C16.2332 22.4304 16.084 22.7907 16.084 23.1665C16.084 23.5422 16.2332 23.9025 16.4989 24.1682C16.7646 24.4339 17.1249 24.5831 17.5007 24.5831ZM17.5007 11.8331C17.1249 11.8331 16.7646 11.9824 16.4989 12.2481C16.2332 12.5137 16.084 12.8741 16.084 13.2498V18.9165C16.084 19.2922 16.2332 19.6525 16.4989 19.9182C16.7646 20.1839 17.1249 20.3331 17.5007 20.3331C17.8764 20.3331 18.2367 20.1839 18.5024 19.9182C18.7681 19.6525 18.9173 19.2922 18.9173 18.9165V13.2498C18.9173 12.8741 18.7681 12.5137 18.5024 12.2481C18.2367 11.9824 17.8764 11.8331 17.5007 11.8331Z' fill={ fill } />
  </SvgIcon>
);
