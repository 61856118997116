import styled from 'styled-components';
import { LineClamp } from '../../styles/styles';

export const ValuesComparisonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  margin: 50px auto 0;
  max-width: 930px;
  padding: 40px 25px;
  width: 90%;

  & > h2 {
    margin-bottom: 30px;
    text-align: center;
  }

  .no-values {
    color: ${({ theme }) => theme.colors.disabled};
    margin: 40px 0;
    text-align: center;
  }

  .left-cell, .right-cell {
    display: flex;
    justify-content: flex-end;
    width: 30%;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 25%;
    }
  }

  .middle-cell {
    display: flex;
    justify-content: left;
    width: 40%;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 50%;
    }
  }

  .disclaimer-text {
    font-size: 14px;
    text-align: left;
    margin: 20px auto 0;
    max-width: 860px;
  }
`;

export const ValuesWrapper = styled.div`
  max-width: 860px;
  margin: 0 auto;
`;

export const HeaderRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  .value-title {
    min-width: 100px;
  }

  .left-cell, .right-cell {
    ${LineClamp}
    align-items: center;
    font-weight: 700;
    text-align: center;
    white-space: wrap;
    -webkit-line-clamp: 2;
  }

  .header-cell {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 30%;

    p {
      margin: auto 0;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 25%;
    }
  }

  img {
    padding: 0 15px;
    max-width: 100%;
    max-height: 40px;
  }

  .value-cell {
    ${LineClamp}
    white-space: wrap;
    -webkit-line-clamp: 2;
    min-width: 200px;
  }

  .value-comparison-container {
    display: flex;
    gap: 10px;
    justify-content: end;
    min-width: 70%;
  }
`;

export const BodyWrapper = styled.div`
  margin: 0 auto;
`;
