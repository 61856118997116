import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Earth: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }planet-graphic` }
    id={ id }
    width={ 36 }
    height={ 36 }
    fill='none'
  >
    <path d='M18 0C17.6148 0 17.2323 0.0120937 16.8531 0.0359297L1.86701 10.0081C0.671906 12.416 0 15.1296 0 18C0 23.2647 2.2602 28.0015 5.86329 31.293C5.86329 31.293 11.7295 35.328 18 35.328C26.39 35.328 35.1177 27.9411 35.1177 18C35.1177 6.21288 23.9029 0.990211 23.9029 0.990211C22.0537 0.348609 20.0675 0 18 0Z' fill='#008CDF' />
    <path d='M35.8237 15.4712L31.46 14.9731C31.46 24.9143 23.4011 32.9731 13.46 32.9731C10.745 32.9731 8.17171 32.3692 5.86328 31.2928C7.26081 32.5696 8.86042 33.6288 10.6085 34.4171L11.6684 34.358L13.0124 35.2996C14.5962 35.7554 16.2696 35.9998 18.0001 35.9998C27.9412 35.9998 36.0001 27.9409 36.0001 17.9998C36.0001 17.1415 35.9399 16.2973 35.8237 15.4712Z' fill='#0069D0' />
    <path d='M25.6165 1.68534C25.0605 1.42547 24.489 1.19316 23.9037 0.990234L22.3117 2.3903C21.8757 2.76309 21.7715 3.40617 22.0922 3.90427C22.4005 4.38309 23.0238 4.55016 23.5484 4.3286L23.8706 4.19248C24.2662 4.02541 24.7297 4.08588 25.0505 4.37156C25.6173 4.87655 25.5186 5.76902 24.8825 6.14541L21.1441 8.35751C20.4139 8.78958 19.9501 9.57504 19.9653 10.4234C19.981 11.3045 20.4748 12.0222 21.467 12.1136C23.5203 12.3027 23.2247 10.1273 24.4307 10.1273H28.5669C29.1965 10.1273 29.7424 10.598 29.797 11.2253C29.8592 11.9399 29.2974 12.5392 28.5957 12.5392H23.6359C21.7067 12.5392 20.0244 14.0258 19.9665 15.9541C19.9066 17.9495 21.5062 19.5858 23.4881 19.5858H23.9926C24.9546 19.5858 25.7345 20.3657 25.7345 21.3277V27.5179C25.7345 27.7017 25.7668 27.8829 25.8274 28.052L27.2842 28.0206L31.8198 20.6445L32.4028 16.4171L33.8815 14.2762C33.8815 14.2763 31.0231 4.21259 25.6165 1.68534Z' fill='#98DB7C' />
    <path d='M35.8232 15.4716C34.9638 9.35193 31.014 4.21504 25.6152 1.68555C29.203 4.97603 31.4595 9.72156 31.4595 14.9735C31.4595 20.128 29.2984 24.7707 25.8262 28.0521C26.0391 28.6602 26.6426 29.0825 27.3441 28.9823C27.7915 28.9184 28.1713 28.6174 28.3942 28.2243L32.6902 20.6491C33.0705 19.9785 33.1685 19.1842 32.9625 18.4413L32.6295 17.2399C32.52 16.8451 32.8429 16.4641 33.2503 16.5074L33.4872 16.5325C34.1249 16.6001 34.7619 16.3988 35.245 15.9771L35.8232 15.4716Z' fill='#77CC7D' />
    <path d='M17.8321 1.59159L16.8532 0.0361328C10.2695 0.449148 4.65037 4.39741 1.86719 10.0083L3.00147 17.8442C3.12873 18.7237 3.65186 19.4965 4.42115 19.9415C4.8637 20.1975 5.05734 20.7351 4.87966 21.2145L4.49238 22.2592C4.14391 23.1993 4.43802 24.2568 5.22173 24.882L6.25595 25.7072C6.6097 25.9894 6.89263 26.3504 7.08206 26.7615L9.76947 32.5932L11.3403 33.7017L12.4494 32.9454L12.0472 31.2644C11.8203 30.3161 11.9418 29.3178 12.3897 28.4517L14.134 25.0784C14.6144 24.1492 14.1734 23.0091 13.1927 22.6452L11.7597 22.1133C11.3045 21.9445 11.0025 21.5101 11.0025 21.0247V20.9128C11.0025 20.2714 10.4825 19.7515 9.84119 19.7515H7.34995C6.73942 19.7515 6.13973 19.5912 5.6107 19.2864L5.00298 18.9363C4.54138 18.6703 4.22737 18.2072 4.15115 17.6799L4.11234 17.4113C4.0146 16.7356 4.32074 16.0651 4.89527 15.6962L5.25238 15.4669C5.68502 15.1892 6.24878 15.2289 6.63817 15.5646L6.95648 15.839C7.34404 16.1731 7.94795 15.9751 8.06242 15.4763L8.40189 13.9972C8.58281 13.2091 9.16049 12.5718 9.92718 12.3147L12.4326 11.4744C13.1481 11.2345 13.4482 10.3866 13.0431 9.74994L11.9042 7.96028C11.5912 7.46852 10.9622 7.28493 10.4338 7.53116L9.49321 7.96956C9.00672 8.19639 8.40056 8.09915 8.0722 7.67453C7.64856 7.1268 7.81879 6.33986 8.41645 6.01178L10.7803 4.71395C11.5644 4.28343 12.5472 4.5168 13.054 5.25388L13.2736 5.57331C13.5984 6.04602 14.1352 6.32833 14.7088 6.32833H15.7725C16.4776 6.32833 17.1132 5.90322 17.3824 5.25156L18.0154 3.71938C18.3034 3.02237 18.235 2.22904 17.8321 1.59159Z' fill='#98DB7C' />
    <path d='M10.6088 34.417C11.3828 34.7663 12.185 35.0613 13.0126 35.2996L12.4494 32.945C11.5345 32.8941 10.6427 32.7757 9.76953 32.5928L10.6088 34.417Z' fill='#77CC7D' />
  </SvgIcon>
);
