import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { FlexHorizontalCenter, FocusVisible, TruncateText } from '../../styles/styles';

const companyLogoMobileSize = 50;
const companyLogoDesktopSize = 70;

// TODO: fix hacky solution to corect typing issue
export const CompanySearchResultItemContainer = styled(Link as React.ForwardRefExoticComponent<LinkProps & any & React.RefAttributes<HTMLAnchorElement>>)`
  ${FlexHorizontalCenter}
  ${FocusVisible}
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  text-decoration: none;

  &:hover {
    background: ${({ theme }) => theme.colors.light};
  }
  
  .search-result-company-logo {
    margin-right: 10px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-right: 20px;
    }
  }
`;

export const OwnedBy = styled.div`
  ${TruncateText}
  display: inline-block;
  max-width: 85%;
  padding: 2px 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  margin-top: 2px;
  background: ${({ theme }) => theme.colors.primary};
`;

export const Cashback = styled.div`
  ${TruncateText}
  display: inline-block;
  max-width: 85%;
  padding: 2px 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 2px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.darkGreen2};
`;

export const PrimaryInfo = styled.div`
  align-items: flex-start;
  min-width: calc(100% - ${companyLogoMobileSize + 10}px);
  max-width: calc(100% - ${companyLogoMobileSize + 10}px);

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    min-width: calc(100% - ${companyLogoDesktopSize}px);
    max-width: calc(100% - ${companyLogoDesktopSize}px);
  }
  
  p {
    ${TruncateText}
    max-width: 85%;
    margin: 0;
    line-height: 1.1rem;
  }

  .badge-holder {
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;

    .pill {
      font-size: 14px;
      margin-right: 5px;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .company-name {
    font-size: 14px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      padding-bottom: 4px;
      font-size: 18px;
    }
  }

  .company-sectors {
    font-size: 12px;
    
    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      font-size: 14px;
    }
  }
`;

export const PrimaryInfoContainer = styled.div`
  ${FlexHorizontalCenter}
  min-width: calc(100% - 40px);
  max-width: calc(100% - 40px);

  .search-result-company-logo {
    min-width: ${companyLogoMobileSize}px;
    width: ${companyLogoMobileSize}px;
    max-width: ${companyLogoMobileSize}px;
    min-height: ${companyLogoMobileSize}px;
    height: ${companyLogoMobileSize}px;
    max-height: ${companyLogoMobileSize}px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      min-width: ${companyLogoDesktopSize}px;
      width: ${companyLogoDesktopSize}px;
      max-width: ${companyLogoDesktopSize}px;
      min-height: ${companyLogoDesktopSize}px;
      height: ${companyLogoDesktopSize}px;
      max-height: ${companyLogoDesktopSize}px;
    }
  }
`;

export const SecondaryInfoContainer = styled.div``;
