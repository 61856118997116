import React from 'react';
import { CategoryContainer, CategoryHeaderContainer, SentimentCategoriesMenuContainer, SentimentCategoriesMobileMenuContainer } from './styles';
import { SentimentSubcategoryCard } from './SentimentSubcategoryCard';
import { Planet } from '../../../../components/svgs/graphics/Planet';
import { PeopleGraphic } from '../../../../components/svgs/graphics/PeopleGraphic';
import { useAppData } from '../../../../contexts/app';
import { selectedSentimentSubcategoryAtom } from '../../../../models/sentiment/atom';
import { useRecoilValue } from 'recoil';
import { SentimentSubcategory } from '../../../../models/sentiment/types';
import { ISentimentSubcategory, sentimentCategories } from '../../../../constants/sentiment';

interface IProps {
  className?: string;
}

export const SentimentCategories: React.FC<IProps> = ({
  className,
}) => {
  const appData = useAppData();
  const selectedSentimentCategory = useRecoilValue(selectedSentimentSubcategoryAtom);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const renderMobileCategoryCards = () => {
    const allCategories = [...sentimentCategories.planet, ...sentimentCategories.people, ...sentimentCategories.summary];
    const selectedCategory = allCategories.find(category => category?.title === selectedSentimentCategory);
    const _categories = allCategories.filter(category => category?.title !== selectedCategory?.title);
    const categoriesToDisplay = [selectedCategory, ..._categories];

    const categories = categoriesToDisplay.map((category, index) => (
      <SentimentSubcategoryCard
        className='mobile-category-card'
        key={ index }
        title={ category?.title as SentimentSubcategory }
        icon={ category?.icon }
        mobileOpen={ mobileOpen }
      />
    ));

    return categories;
  };

  const renderSubcategoryCards = (category: string, subcategories: ISentimentSubcategory[]) => {
    const cards = subcategories.map((category, index) => (
      <SentimentSubcategoryCard
        key={ index }
        title={ category.title as SentimentSubcategory }
        icon={ category.icon }
      />
    ));

    return (
      <CategoryContainer>
        <CategoryHeaderContainer>
          {
            category === 'Planet' ? 
              <Planet className='categories-header-graphic' /> :
              <PeopleGraphic className='categories-header-graphic' />
          }
          <div className='category-header-text'>{ category }</div>
        </CategoryHeaderContainer>
        { cards }
      </CategoryContainer>
    );
  };

  if (!appData.isDesktop) {
    return (
      <SentimentCategoriesMobileMenuContainer
        onClick={ () => setMobileOpen(!mobileOpen) }
        mobileOpen={ mobileOpen }
      >
        { renderMobileCategoryCards() }
      </SentimentCategoriesMobileMenuContainer>
    );
  } 

  return (
    <SentimentCategoriesMenuContainer className={ className }>
      <SentimentSubcategoryCard isSummary title={ SentimentSubcategory.Summary } />
      { renderSubcategoryCards('Planet', sentimentCategories.planet) }
      { renderSubcategoryCards('People', sentimentCategories.people) }
    </SentimentCategoriesMenuContainer>
  );
};
