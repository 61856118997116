import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { FlexCenter } from '../../styles/styles';
import { CTAs } from '../CTAs';

export interface IGroupLogoProps {
  withBorder?: boolean;
}

export const EditButtonContainer = styled.div`
  ${ FlexCenter }
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightGray1};

  svg {
    stroke: ${({ theme }) => theme.colors.darkGray1};
  }
`;

export const GroupLogoContainer = styled.div<IGroupLogoProps>`
  ${ FlexCenter }
  position: relative;

  & > *:not(.file-uploader) {
    display: block;
    width: 80%;
    max-height: 80%;

    &.default-group-logo {
      ${ FlexCenter }
     width: 100%;
     height: 100%;
    }
  }

  ${({ withBorder, theme }) => withBorder
    ? `
      border: 2px solid ${theme.colors.darkGray1};
      border-radius: 50%;
    `
    : null}

  .file-uploader {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(0, -50%);
  }
`;

export const GroupLogoSkeletonContainer = styled(Skeleton)<IGroupLogoProps>``;

export const UploaderCTAs = styled(CTAs)`
  min-width: 200px;
`;
