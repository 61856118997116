import dayjs, { Dayjs } from 'dayjs';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { EquivalencyGraphics } from '../constants/equivalencies';
import { WebServiceHelper } from '../lib/webServiceHelper';
import { BaseModel } from './base';
import { CompanyModel, ICompany } from './companies';
import { ISector, SectorModel } from './sectors';

type PrivateFields = '_busy' |
'_carbonDonationSuggestions' |
'_carbonImpact' |
'_featuredCashbackCompanies' |
'_loadingCarbonDonationSuggestions' |
'_loadingCarbonImpact' |
'_loadingFeaturedCashbackCompanies' |
'_loadingLowerImpactPurchases' |
'_loadingShopBy' |
'_loadingTonnesPerAmount' |
'_loadingUserImpact' |
'_lowerImpactPurchases' |
'_monthlyBreakdown' |
'_ratings' |
'_shopBy' |
'_shopByCompaniesBySectorLoading' |
'_tonnesPerAmount' |
'_totalScores' |
'_totalTransactions';

enum CarbonSuggestionType {
  Total = 'total',
  Suggested = 'suggested', 
  None = 'none'
}

export enum Ratings {
  Positive = 'positive',
  Neutral = 'neutral',
  Negative = 'negative',
}

interface IOffsets {
  donationsCount: number;
  totalDonated: number;
  totalOffset: number;
}

export interface IUserScoreBreakdown {
  date: Dayjs;
  negative: number;
  positive: number;
  neutral: number;
  score: number;
  transactionCount: number, 
  _id: string;
}

interface IRatingsRange {
  min: number;
  max: number;
}

export interface IRatings {
  negative: IRatingsRange;
  neutral: IRatingsRange;
  positive: IRatingsRange;
}

export interface ITonnesPerAmount {
  tonnes: string;
}

export interface IUserImpact {
  monthlyBreakdown: IUserScoreBreakdown[];
  totalScores: IUserScoreBreakdown;
  totalTransactions: number;
  ratings: IRatings;
}

export interface IEquivalency {
  text: string;
  icon: EquivalencyGraphics;
  textNoQuantity: string;
  quantity: number;
  type: string;
}

interface IAmericanEmissions {
  monthly: number;
  annually: number;
}

export interface ICarbonImpact {
  offsets: IOffsets;
  netEmissions: number;
  totalEmissions: number;
  monthlyEmissions: number;
  averageAmericanEmissions: IAmericanEmissions;
  equivalencies: IEquivalency[];
}

interface IShopBySectors {
  remainingCategoriesCount: number;
  sectors: ISector[];
}

interface IShopBy {
  companies: {[key: string]: CompanyModel[]};
  sectors: SectorModel[];
  remainingCategoriesCount: number;
}

interface ITopCompaniesBySector {
  companies: ICompany[];
}

export interface ILowerImpactPurchase {
  _id: string;
  company: CompanyModel;
  rating: string;
  transactionsCount: number;
  totalSpent: number;
}

export interface ICarbonDonationSuggestion {
  amount: number;
  description: string;
  type: CarbonSuggestionType;
}

export class ImpactModel extends BaseModel {
  private _busy = false;
  private _carbonDonationSuggestions: ICarbonDonationSuggestion[] = [];
  private _carbonImpact: ICarbonImpact = null;
  private _featuredCashbackCompanies: CompanyModel[] = [];
  private _loadingCarbonDonationSuggestions = false;
  private _loadingCarbonImpact = false;
  private _loadingFeaturedCashbackCompanies = false;
  private _loadingLowerImpactPurchases = false;
  private _loadingShopBy = false;
  private _loadingTonnesPerAmount = false;
  private _loadingUserImpact = false;
  private _lowerImpactPurchases: ILowerImpactPurchase[] = [];
  private _monthlyBreakdown: IUserScoreBreakdown[] = null;
  private _ratings: IRatings = null;
  private _shopBy: IShopBy = null;
  private _shopByCompaniesBySectorLoading = false;
  private _tonnesPerAmount: ITonnesPerAmount = null;
  private _totalScores: IUserScoreBreakdown = null;
  private _totalTransactions: number = null;

  constructor () {
    super();
    makeObservable<ImpactModel, PrivateFields>(this, {
      _busy: observable,
      _carbonDonationSuggestions: observable,
      _carbonImpact: observable,
      _featuredCashbackCompanies: observable,
      _loadingCarbonDonationSuggestions: observable,
      _loadingCarbonImpact: observable,
      _loadingFeaturedCashbackCompanies: observable,
      _loadingLowerImpactPurchases: observable,
      _loadingShopBy: observable,
      _loadingTonnesPerAmount: observable,
      _loadingUserImpact: observable,
      _lowerImpactPurchases: observable,
      _monthlyBreakdown: observable,
      _ratings: observable,
      _shopBy: observable,
      _shopByCompaniesBySectorLoading: observable,
      _tonnesPerAmount: observable,
      _totalScores: observable,
      _totalTransactions: observable,
      busy: computed,
      carbonImpact: computed,
      loadingCarbonDonationSuggestions: computed,
      loadingCarbonImpact: computed,
      loadingFeaturedCashbackCompanies: computed,
      loadingLowerImpactPurchases: computed,
      loadingShopBy: computed,
      loadingTonnesPerAmount: computed,
      loadingUserImpact: computed,
      lowerImpactPurchases: computed,
      monthlyBreakdown: computed,
      ratings: computed,
      shopBy: computed, 
      shopByCompaniesBySectorLoading: computed,
      tonnesPerAmount: computed,
      totalScores: computed,
      totalTransactions: computed,
      getShopByCompaniesBySector: action.bound, 
      getTonnesPerAmount: action.bound,
      loadCarbonDonationSuggestions: action.bound,
      loadCarbonImpact: action.bound,
      loadLowerImpactPurchases: action.bound,
      loadShopBy: action.bound,
      loadUserImpact: action.bound,
    });

    this._init();
  }

  get busy() { return this._shopByCompaniesBySectorLoading || this._loadingShopBy; }
  get carbonDonationSuggestions() { return this._carbonDonationSuggestions; }
  get carbonImpact() { return this._carbonImpact; }
  get featuredCashbackCompanies() { return this._featuredCashbackCompanies; }
  get loadingCarbonDonationSuggestions() { return this._loadingCarbonDonationSuggestions; }
  get loadingCarbonImpact() { return this._loadingCarbonImpact; }
  get loadingFeaturedCashbackCompanies() { return this._loadingFeaturedCashbackCompanies; }
  get loadingLowerImpactPurchases() { return this._loadingLowerImpactPurchases; }
  get loadingShopBy() { return this._loadingShopBy; }
  get loadingTonnesPerAmount() { return this._loadingTonnesPerAmount; }
  get loadingUserImpact() { return this._loadingUserImpact; }
  get lowerImpactPurchases() { return this._lowerImpactPurchases; }
  get monthlyBreakdown() { return this._monthlyBreakdown; }
  get ratings() { return this._ratings; }
  get shopBy() { return this._shopBy; }
  get shopByCompaniesBySectorLoading() { return this._shopByCompaniesBySectorLoading; }
  get tonnesPerAmount() { return this._tonnesPerAmount; }
  get totalScores() { return this._totalScores; }
  get totalTransactions() { return this._totalTransactions; }

  public getShopByCompaniesBySector = async (sectorId: string) => {
    if (this._shopByCompaniesBySectorLoading || this._shopBy.companies[sectorId]) return;
    runInAction(() => this._shopByCompaniesBySectorLoading = true);

    const result = await this.webServiceHelper.sendRequest<ITopCompaniesBySector>({
      path: `/impact/top-companies?config=shop-by&sector=${sectorId}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._shopBy.companies[sectorId] = result.value.companies.map(comp => new CompanyModel(comp));
        this._shopByCompaniesBySectorLoading = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._shopByCompaniesBySectorLoading = false;
      });

      throw new Error(result.error);
    }
  };

  public loadFeaturedCashbackCompanies = async () => {
    if (this._loadingFeaturedCashbackCompanies) return;
    runInAction(() => this._loadingFeaturedCashbackCompanies = true);

    const result = await this.webServiceHelper.sendRequest<ICompany[]>({
      path: '/impact/featured-cashback',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._featuredCashbackCompanies = result.value.map(comp => new CompanyModel(comp));
        this._loadingFeaturedCashbackCompanies = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingFeaturedCashbackCompanies = false;
      });

      throw new Error(result.error);
    }
  };

  public loadShopBy = async () => {
    if (this._loadingShopBy) return;
    runInAction(() => this._loadingShopBy = true);

    const result = await this.webServiceHelper.sendRequest<IShopBySectors>({
      path: '/impact/top-sectors?config=shop-by',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._shopBy.sectors = result.value.sectors.map(sector => new SectorModel(sector));
        this._shopBy.remainingCategoriesCount = result.value.remainingCategoriesCount;
        this._loadingShopBy = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingShopBy = false;
      });

      throw new Error(result.error);
    }
  };

  public loadCarbonDonationSuggestions = async () => {
    if (this._loadingCarbonDonationSuggestions) return;
    this._loadingCarbonDonationSuggestions = true;

    const result = await this.webServiceHelper.sendRequest<ICarbonDonationSuggestion[]>({
      path: '/impact/carbon/offset/donation-suggestions',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._carbonDonationSuggestions = result.value;
        this._loadingCarbonDonationSuggestions = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingCarbonDonationSuggestions = false;
      });

      throw new Error(result.error);
    }
  };

  public loadCarbonImpact = async () => {
    if (this._loadingCarbonImpact) return;
    this._loadingCarbonImpact = true;

    const result = await this.webServiceHelper.sendRequest<ICarbonImpact>({
      path: '/impact/carbon', 
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._carbonImpact = result.value;
        this._loadingCarbonImpact = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingCarbonImpact = false;
      });

      throw new Error(result.error);
    }
  };

  public getTonnesPerAmount = async (amount: string) => {
    if (this._loadingTonnesPerAmount) return;

    this._loadingTonnesPerAmount = true;

    const result = await this.webServiceHelper.sendRequest<ITonnesPerAmount>({
      path: `/impact/carbon/tonnes-by-dollar-amount?amount=${amount}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._tonnesPerAmount = result.value;
        this._loadingTonnesPerAmount = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingTonnesPerAmount = false;
      });

      throw new Error(result.error);
    }
  };

  public loadLowerImpactPurchases = async (queryParams: object) => {
    if (this._loadingLowerImpactPurchases) return;
    this._loadingLowerImpactPurchases = true;

    const result = await this.webServiceHelper.sendRequest<ILowerImpactPurchase[]>({
      path: '/impact/user/lower-impact-purchases',
      method: 'GET',
      queryParams,
    });

    if (result.success) {
      runInAction(() => {
        this._lowerImpactPurchases = result.value;
        this._loadingLowerImpactPurchases = false;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingLowerImpactPurchases = false;
      });

      throw new Error(result.error);
    }
  };

  public loadUserImpact = async () => {
    if (this._loadingUserImpact) return;
    this._loadingUserImpact = true;

    const result = await this.webServiceHelper.sendRequest<IUserImpact>({
      path: '/impact/user/data', 
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        const { monthlyBreakdown, totalTransactions, ratings, totalScores } = result.value;
        this._loadingUserImpact = false;
        this._ratings = ratings;
        this._monthlyBreakdown = !!monthlyBreakdown ? monthlyBreakdown.reverse().map(i => ({ ...i, date: dayjs(i.date) })) : null;
        this._totalScores = totalScores;
        this._totalTransactions = totalTransactions;
      });
    }

    if (result.error) {
      runInAction(() => {
        this._loadingUserImpact = false;
      });

      throw new Error(result.error);
    }
  };

  private _init = () => {
    this._shopBy = { 
      companies: {},
      remainingCategoriesCount: 0,
      sectors: [],
    };
  };

  static getUserImpactScoreRange = (range: IRatings, score: number) => {
    if (score >= range?.positive?.min) {
      return Ratings.Positive;
    }
  
    if (score < range?.positive?.min && score >= range.neutral.min) {
      return Ratings.Neutral;
    }
  
    return Ratings.Negative;
  };

  static getRatings = async () => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IRatings>({
      path: '/impact/ratings',
      method: 'GET',
    });

    if (result.success) {
      return result.value;
    }
    
    throw new Error(result.error);
  };

  static roundTo100or0 = (number: number) => {
    if (number < 0) return 0;
    if (number > 100) return 100;

    return number;
  };
}
