import styled from 'styled-components';
import { _H5, _H4 } from '../../../styles/components/header';
import { FlexCenter } from '../../../styles/styles';
import { TextField } from '../../TextField';

export const CustomDonationOptionContainer = styled.div`
  .error-text {
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const CustomAmountFieldWrapper = styled.div`
  ${FlexCenter}
  min-width: 100px;
  margin: 0 10px;
  width: 30%;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.light};

  .dollar-sign {
    ${_H5}

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${_H4}
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    width: 25%;
  }
`;

export const CustomAmountField = styled(TextField)`
  margin-bottom: 0;

  #custom-donation-amount-input-field {
    ${_H5}
    background: ${({ theme }) => theme.colors.light};
    margin-bottom: 0;
    padding: 0;
    width: 90%;
    text-align: center;
    border-radius: 0;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${_H4}
    }
  }
`;
