import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../contexts/analytics-store';
import { useJoinGroupModal } from '../../../contexts/join-group';
import { useUserSession } from '../../../contexts/user';
import { isValidEmailFormat } from '../../../lib/formatValidators';
import { debounce } from '../../../lib/misc';
import { GroupModel } from '../../../models/group';
import { IThemeProps } from '../../../styles/themes';
import { removeScrollInert } from '../../../utils/removeScrollInert';
import { ButtonKind } from '../../Button/styles';
import { EnvelopeIcon } from '../../svgs/icons/EnvelopeIcon';
import { KeyIcon } from '../../svgs/icons/KeyIcon';
import { TextField, TextFieldKind } from '../../TextField';
import { BodyText, DisclaimerText, JoinGroupModalContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  isOpen: boolean;
  onClose(groupJoined: GroupModel): void;
}

const JoinGroupModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  theme,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUserSession();
  const analytics = useAnalytics();
  const joinGroup = useJoinGroupModal();
  const [code, setCode] = useState(joinGroup.code || '');
  const codePrepopulated = useMemo(() => !!joinGroup.code, [joinGroup.code]);
  const [codeError, setCodeError] = useState('');
  const [email, setEmail] = useState(joinGroup.email || '');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [groupToJoin, setGroupToJoin] = useState<GroupModel>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!!groupToJoin && groupToJoin.settings.allowDomainRestriction) {
      let existingEmail = '';

      for (const groupDomain of groupToJoin.domains) {
        const e = user.emails.find(e => e.email?.split('@')?.[1] === groupDomain);
        if (!!e) {
          existingEmail = e.email;
          break;
        }
      }

      setEmail(existingEmail);
      checkEmailRequirements(existingEmail);
    }
  }, [groupToJoin]);

  const getGroupByCode = useCallback(debounce((c: string) => {
    if (!!c) {
      GroupModel.getGroupByCode(c)
        .then(group => {
          const alreadyInGroup = user.groups.find(g => g.group.code === group.code);

          if (!!alreadyInGroup) {
            analytics.fireEvent('JoinGroupCodeEntered_AlreadyAMember');
            setCodeError('You\'re already a member of this group.');
          } else {
            analytics.fireEvent('JoinGroupCodeEntered_Valid');
            setGroupToJoin(group);
          }
        })
        .catch(err => {
          analytics.fireEvent('JoinGroupCodeEntered_Error');
          setCodeError(err.message);
        });
    }
  }, 500), []);

  useEffect(() => {
    if (joinGroup.codePrepopulated) analytics.fireEvent('JoinGroupPrepopulation');
  }, [joinGroup.codePrepopulated]);

  useEffect(() => {
    if (joinGroup.code) {
      setCode(joinGroup.code);
      getGroupByCode(joinGroup.code);
    }
    if (joinGroup.email) setEmail(joinGroup.email);
  }, [joinGroup.isOpen, joinGroup.code, joinGroup.email]);

  const reset = useCallback(() => {
    setCode('');
    setCodeError('');
    setGroupToJoin(null);
    setEmail('');
    setEmailError('');
    setIsValidEmail(false);
    removeScrollInert();
  }, []);

  const checkEmailRequirements = useCallback(debounce((e: string) => {
    if (!!e && isValidEmailFormat(e)) {
      const domain = e.split('@')?.[1];
      const requirementsMet = groupToJoin.domains.find(d => domain === d);
      if (requirementsMet) {
        setIsValidEmail(true);
        analytics.fireEvent('JoinGroupEmailEntered_Valid');
      } else {
        analytics.fireEvent('JoinGroupEmailEntered_Invalid');
        setEmailError(`This email does not satisfy the requirements of this group. Must have one of the following domain(s): ${groupToJoin.domains.join(', ')}`);
      }
    }
  }, 500), [groupToJoin]);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setIsValidEmail(false);
    setEmail(e.target.value);
    checkEmailRequirements(e.target.value);
  }, [groupToJoin]);

  const onGroupCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== joinGroup.code && joinGroup.codePrepopulated) {
      analytics.fireEvent('JoinGroupPrepopulatedFieldEdit');
    }

    setGroupToJoin(null);
    setCodeError('');
    setCode(e.target.value);
    getGroupByCode(e.target.value);
  }, [codePrepopulated]);

  const disallowJoin = (
    !!codeError || !!emailError || ((!email || !isValidEmail) && groupToJoin?.settings?.allowDomainRestriction)
  );

  const onJoinGroupClick = useCallback(async () => {
    setIsProcessing(prev => !prev);
    try {
      analytics.fireEvent('JoinGroupModalJoinClick');
      if (disallowJoin) return;
      await user.joinGroup(code, email);
      setIsProcessing(false);
      reset();
      onClose(groupToJoin);
    } catch (err: any) {
      setIsProcessing(false);
      analytics.fireEvent('JoinGroupModalJoinError');
      setEmailError(err.message);
    }
  }, [code, codeError, email, emailError, groupToJoin, isValidEmail]);

  const onJoinGroupModalClose = useCallback(() => {
    analytics.fireEvent('JoinGroupModalCloseClick');
    // clear any query params if found...
    navigate(location.pathname);
    reset();
    onClose(null);
  }, [location, onClose]);

  const shouldDisableJoin = () => !code
      || !!codeError
      || (groupToJoin?.settings?.allowDomainRestriction && !isValidEmail)
      || !!emailError
      || !groupToJoin;

  const ctas = useMemo(() => ([
    {
      id: 'join-group',
      className: 'join-group-button',
      disabled: shouldDisableJoin(),
      text: 'Join Group',
      kind: ButtonKind.Primary,
      onClick: onJoinGroupClick,
    },
  ]), [onJoinGroupClick, shouldDisableJoin, user.joiningGroup]);

  return (
    <JoinGroupModalContainer
      className={ className }
      title={ 'Join a Group' }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onJoinGroupModalClose }
      processing={ isProcessing }
    >
      <BodyText>
        Connecting your information increases the collective impact of your group or company. Your data is safe and your group will never see any transaction details.
      </BodyText>
      <TextField
        id='group-code-field'
        className='text-field'
        fieldKind={ TextFieldKind.Pill }
        label='Group Code'
        labelHidden
        placeholder='Enter a Group Code'
        leftAccessory={ <KeyIcon fill={ theme.colors.disabled } /> }
        value={ code }
        onChange={ onGroupCodeChange }
      />
      { codeError && <p className='error'>{ codeError }</p> }
      {
        groupToJoin?.settings.allowDomainRestriction && !codeError && (
          <>
            <TextField
              id='group-code-field'
              className='text-field'
              fieldKind={ TextFieldKind.Pill }
              label='Email Address'
              labelHidden
              placeholder='Enter your Email Address'
              leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
              value={ email }
              onChange={ onEmailChange }
            />
            { emailError && <p className='error'>{ emailError }</p> }
            <DisclaimerText>
              *An email matching the required domains for this group is required. Your group email address is used for a one-time verification step only. Updates from Karma Wallet will only go to your primary account email address.
            </DisclaimerText>
          </>
        )
      }
    </JoinGroupModalContainer>
  );
};

const JoinGroupModalAsObserver = observer(JoinGroupModalBase);
export const JoinGroupModal = withTheme(JoinGroupModalAsObserver);
