import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { getUniqueSectors } from '../../lib/misc';
import { CompanyModel, ICompanySector } from '../../models/companies';
import { H2 } from '../../styles/components/header';
import { CardsWrapper, LoadingSpinnerContainer, RelatedCompaniesContainer, RelatedCompaniesInnerWrapper, SectorLink } from './styles';
import { CompanyBadge } from '../CompanyBadge';
import { LoadingSpinner } from '../loading/LoadingSpinner';

interface IProps {
  className?: string;
  baseCompanyId: string;
  company: CompanyModel;
}

export const RelatedCompaniesBase: React.FC<IProps> = ({
  className = '',
  baseCompanyId,
  company,
}) => {
  const analytics = useAnalytics();
  const [errorLoading, setErrorLoading] = useState(false);
  const [sectors, setSectors] = useState<ICompanySector[]>([]);
  const [relatedCompanies, setRelatedCompanies] = useState([]);

  const loadRelatedCompanies = useCallback(() => {
    if (!company || !baseCompanyId) return null;
    const tierOneSectors = company.sectors.filter(s => s.sector.tier === 1);
    const primarySector = company.sectors.find(s => !!s.primary).sector._id;
    setSectors(getUniqueSectors(tierOneSectors));

    const query = {
      sectors: primarySector,
      ratings: 'positive',
      count: 4,
      excludedCompanyIds: baseCompanyId,
    };

    company.loadPositiveImpactCompanies(query)
      .then(() => {
        setErrorLoading(false);
      })
      .catch(() => {
        setErrorLoading(true);
        analytics.fireEvent('Company_RelatedCompanies_Error');
      });
  }, [company, baseCompanyId]);

  useEffect(() => {
    loadRelatedCompanies();
  }, [company]);

  useEffect(() => {
    if (!!company?.positiveImpactCompanies.length) {
      setRelatedCompanies(company.positiveImpactCompanies);
    }
  }, [company?.positiveImpactCompanies]);

  const onSectorClick = useCallback(() => {
    analytics.fireEvent('Company_RelatedCompanies_Sector_Click');
  }, []);

  const onCompanyClick = useCallback(() => {
    analytics.fireEvent('Company_RelatedCompanies_Company_Click');
  }, []);

  const renderCards = useCallback(() => {
    let content: JSX.Element[] = [];

    if (company?.loadingPositiveImpactCompanies) {
      content.push((
        <LoadingSpinnerContainer key='loading-spinner'>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ));
    }
 
    if (relatedCompanies.length > 0) {
      content = [
        ...content,
        ...company.positiveImpactCompanies.map(c => (
          <CompanyBadge 
            key={ c._id }
            company={ c }
            onClick={ onCompanyClick }
            className='company-badge'
          />
        )),
      ];
    }

    return (
      <CardsWrapper>
        { content }
      </CardsWrapper>
    );
  }, [relatedCompanies, company?.loadingPositiveImpactCompanies, company]);

  const renderSectorLabels = useCallback(() => {
    if (!sectors.length) return null;

    return sectors.map((s: ICompanySector) => (
      <SectorLink
        className='sector'
        href={ `/browse-and-compare-companies?sector=${ s.sector._id}` }
        key={ s.sector._id }
        onClick={ onSectorClick }
      >
        { s.sector.name }
      </SectorLink>
    ));
  }, [sectors, company]);

  if (!company || !!company.loadingPositiveImpactCompanies || errorLoading || !relatedCompanies.length) return null;

  return (
    <RelatedCompaniesContainer className={ className }>
      <H2 id='related-companies'>Related Positive Impact Companies</H2>
      { renderSectorLabels() }
      <RelatedCompaniesInnerWrapper>
        { renderCards() }
      </RelatedCompaniesInnerWrapper>
    </RelatedCompaniesContainer>
  );
};

export const RelatedCompanies = observer(RelatedCompaniesBase);
