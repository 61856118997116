import { observer } from 'mobx-react';
import React from 'react';
import { ButtonKind } from '../../../components/Button/styles';
import { CTAs, CtaType } from '../../../components/CTAs';
import { GroupHero } from '../../../components/GroupHero';
import { GroupMemberData } from '../../../components/GroupMemberData';
import { GroupTotalEquivalency } from '../../../components/GroupTotalEquivalency';
import { useGroup } from '../../../contexts/group';
import { Section } from '../../../styles/components/containers';
import { H3 } from '../../../styles/components/header';
import { DashboardContainer, GroupCtaSection } from './styles';

interface IProps {
  className?: string;
}

const DashboardBase: React.FC<IProps> = ({
  className = '',
}) => {
  const { group } = useGroup();

  const getCTAs = () => [
    {
      id: 'offset-emissions-btn',
      text: 'Purchase Offsets',
      kind: ButtonKind.Primary,
      ctaType: CtaType.Link,
      openNewTab: true, 
      url: '/offset-emissions',
    },
  ];

  return (
    <DashboardContainer className={ className } title={ group?.name }>
      <GroupHero className='hero' group={ group } />
      <Section className='group-member-data-section'>
        <GroupMemberData group={ group } />
      </Section>
      <GroupCtaSection>
        <H3>Offset Your Shopping Carbon Emissions</H3>
        <p>Consider donating to the <em>Climate Impact Project</em> bundle to offset your CO<sub>2</sub> emissions.</p>
        <CTAs
          className='cta-section'
          ctas={ getCTAs() } 
        />
      </GroupCtaSection>
      <GroupTotalEquivalency className='equivalency' group={ group } />
    </DashboardContainer>
  );
};

export const Dashboard = observer(DashboardBase);
