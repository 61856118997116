import styled from 'styled-components';
import { FlexColCenter } from '../../styles/styles';

export const PromosContentContainer = styled.div`
  ${FlexColCenter}
  text-align: center;
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    height: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    width: 320px;
  }
`;

export const PromoHeaderContainer = styled.div``;

export const PromoContentContainer = styled.div`
  z-index: 1;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.darkGray1}54;

  @media (${({ theme }) => theme.breakpoints.mdMin}) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (${({ theme }) => theme.breakpoints.smMax}) {
   &.success-state {
     padding-top: 40px;
   } 
  }

  h1 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 25px;
  }

  .giveaway-amount {
    font-size: 50px;
    color: ${({ theme }) => theme.colors.secondary};

    @media (${({ theme }) => theme.breakpoints.smMax}) {
      font-size: 40px;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;
