import React from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { H1M, H5 } from '../../styles/components/header';
import { BlogRedirectPageContainer, BlogRedirectSectionWrapper } from './styles';
import { Skyscape } from '../../components/svgs/graphics/Skyscape';
import { ButtonLink } from '../../components/Button';
import { ButtonKind } from '../../components/Button/styles';

interface IProps {
  className?: string;
}

export const BlogRedirect: React.FC<IProps> = ({
  className = '',
}) => {
  const analytics = useAnalytics();

  const onCtaClick = () => () => {
    analytics.fireEvent('OhNo_Page_GoToBlog_Click');
  };

  return (
    <BlogRedirectPageContainer className={ className }>
      <BlogRedirectSectionWrapper>
        <Skyscape />
        <H1M className='center'>Oh no! This page is temporarily down.</H1M>
        <H5>This page is being worked on by the Karma Wallet team. While we work to make this experience better, feel free to checkout our blog articles to learn more about the world of sustainbility!</H5>
        <ButtonLink
          kind= { ButtonKind.Primary }
          href='https://karmawallet.io/blog/'
          onClick={ onCtaClick }
        >
          Go To Blog
        </ButtonLink>
      </BlogRedirectSectionWrapper>
    </BlogRedirectPageContainer>
  );
};
