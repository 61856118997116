import React from 'react';
import { EmployeeBenefitsContainer, EmployeeBenefitsSectionContainer, EmployeeBenefitsSectionInnerContainer, HeaderContainer } from './styles';
import { H2M } from '../../../styles/components/header';
import { EmployeeBenefitCard } from './EmployeeBenefitCard';

interface IProps {
  className?: string;
}

export interface IEmployeeBenefit {
  id: number;
  title: string;
  description: string;
  image: string;
}

const benefits: IEmployeeBenefit[] = [
  {
    id: 1,
    title: 'Complete Transparency',
    description: 'Our foundation is transparency. Our leadership shares everything about the company with the team - the good and the bad - so everyone can work together on meaningful solutions.',
    image: 'https://cdn.karmawallet.io/uploads/vCcjqLsjON-founders-transparency.png',
  },
  {
    id: 2,
    title: 'Feedback Culture',
    description: 'Your voice matters. All team members are empowered to speak directly with leadership about their opinions, insights, comments, and concerns. And - we routinely turn to our community to guide our company',
    image: 'https://cdn.karmawallet.io/uploads/lbQVk7I1Wg-feedbackculture.png',
  },
  {
    id: 3,
    title: 'Remote First',
    description: 'We\'re an intentionally remote-first company. This helps us reduce our collective carbon footprint from commuting - and work with amazing individuals across the globe.',
    image: 'https://cdn.karmawallet.io/uploads/_LzAO1B18p-remotefirst.png',
  },
  {
    id: 4,
    title: 'Quarterly Bonding',
    description: 'Each quarter, the Karma Wallet team meets up in our hometown of Raleigh, North Carolina to connect, get creative, and brainstorm ways to create even more impact.',
    image: 'https://cdn.karmawallet.io/uploads/3FHjhAtqoj-quarterlybonding.png',
  },
  {
    id: 5,
    title: 'Flexible PTO',
    description: 'By offering flexible PTO, our team members are able to prioritize a healthy work-life balance while navigating things like doctors appointments, family vacations, and sick days.',
    image: 'https://cdn.karmawallet.io/uploads/gn3SO0Iab8-flexiblepto.png',
  },
];

export const EmployeeBenefitsSection: React.FC<IProps> = ({
  className,
}) => {
  const employeeBenefits = benefits.map(benefit => (
    <EmployeeBenefitCard key={ benefit.id } benefit={ benefit } />
  ));

  return (
    <EmployeeBenefitsSectionContainer className={ className }>
      <EmployeeBenefitsSectionInnerContainer>
        <HeaderContainer>
          <H2M>Company Benefits</H2M>
          <p>How we cultivate a company culture that puts people first.</p>
        </HeaderContainer>
        <EmployeeBenefitsContainer>
          { employeeBenefits }
        </EmployeeBenefitsContainer>
      </EmployeeBenefitsSectionInnerContainer>
    </EmployeeBenefitsSectionContainer>
  );
};
