import React from 'react';
import { LoadingOverlayContainer } from './styles';
import { SpinnerSize } from '../LoadingSpinner/styles';
import { theme } from '../../../styles/themes';
import { LoadingSpinner } from '../LoadingSpinner';

interface IProps {
  className?: string;
  size?: SpinnerSize;
  text?: string;
  color?: string;
}

export const LoadingOverlay: React.FC<IProps> = ({
  className = '',
  size = SpinnerSize.Large,
  text,
  color = theme.colors.grey3,
}) => (
  <LoadingOverlayContainer className={ className }>
    <div className='loading-inner-wrapper'>
      <LoadingSpinner className='loading-spinner' size={ size } color={ color } />
      {
        text && <div className='spinner-text'>{ text }</div>
      }
    </div>
  </LoadingOverlayContainer>
);
