import React from 'react';
import { ArticlesContainer } from './styles';
import { observer } from 'mobx-react';
import { ArticlesSection } from '../../components/ArticlesSection';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';

interface IProps extends IThemeProps {
  className?: string;
}

const ArticlesBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => (
  <ArticlesContainer className={ className } title='Industry Reports'>
    <ArticlesSection backgroundColor={ theme.colors.primary } theBadFillColor={ theme.colors.primary } />
  </ArticlesContainer>
);

export const ArticlesObserver = observer(ArticlesBase);
export const Articles = withTheme(ArticlesObserver);
