import styled from 'styled-components';
import { Button } from '../Button';
import { FlexCol, FlexColCenter, FlexRow } from '../../styles/styles';

export const FilterSortBarContainer = styled.div`
  align-items: stretch;
  align-self: center;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas:
  'sort-filter sector-filter'
  'modal-filter modal-filter';
  justify-items: stretch;
  padding: 20px 0 10px;
  position: relative;
  row-gap: 20px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .sort-filter {
    justify-self: stretch;
    order: 1;
    padding-right: 5px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-right: 0;
      order: 1;
      padding: unset;
    }
  }

  .sector-filter {
    grid-column-start: 1;
    grid-column-end: 3;
    justify-self: center;
    order: 3;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      order: 2;
    }
  }
`;

export const FilterButton = styled(Button)`
  ${ FlexRow }
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.midGray};
  border-radius: 8px;
  height: 51px;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  width: 100%;

  .remove-filters-icon {
    cursor: pointer;

    svg {
      height: 10px;
      stroke: ${({ theme }) => theme.colors.black};
      width: 10px;
    }
  }

  .filters-count {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    line-height: 16px;
    position: absolute;
    right: -5px;
    top: -6px;
    width: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  .label-text {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const FilterIconWrapper = styled.div`
  ${FlexColCenter}
  align-items: center;
  height: 16px;
  justify-content: center;
  width: 10%;
`;

export const SortWrapper = styled.div`
  ${FlexCol}
  margin-bottom: 4px;
  width: 100%;

  .sort-by-label {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 6px;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-width: 165px;
    width: 165px;
  }

  .chevron-icon {
    stroke: ${({ theme }) => theme.colors.darkGray1};
  }

  .sort-text {
    font-size: 14px;
  }

  .sort-dropdown {
    align-items: center;
    align-self: center;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.midGray};
    border-radius: 8px;
    display: flex;
    gap: 10px;
    height: 51px;
    padding: 10px;
    position: relative;

    .dropdown-trigger {
      border: none;
      box-shadow: none;
      font-weight: 600;
      padding: 0;

      &.is-open:after {
        content: '';
        display: none;
      }
    }

    .dropdown-options-container {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.midGray};
      box-shadow: none;
      position: absolute;
      margin-top: 5px;
      width: 100%;
      top: 56px;
      min-width: 100%;

      .dropdown-option {
        color: ${({ theme }) => theme.colors.darkGray1};
        font-size: 14px;
        font-weight: 600;
      }
    }

    .selected-dropdown-option {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const FilterWrapper = styled.div`
  ${FlexCol}
  margin-bottom: 4px;
  justify-self: stretch;
  order: 2;
  padding-left: 5px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-left: 0;
    order: 3;
    max-width: 165px;
    padding: unset;
    width: 165px
  }

  .filter-by-label {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 1.3em;
    color: transparent;
  }
`;
