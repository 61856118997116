import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { StatementItemSkeletonContainer } from './styles';

interface IProps {
  className?: string;
}

export const StatementItemSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <StatementItemSkeletonContainer className={ className }>
    <Skeleton className='statement-row' />
  </StatementItemSkeletonContainer>
);
