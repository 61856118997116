import styled from 'styled-components';
import { MaxBoundarySection } from '../../styles/components/containers';
import { FlexColCenter } from '../../styles/styles';

export const CashbackRedirectContainer = styled(MaxBoundarySection)`
  margin: 0 auto;
  text-align: center;
`;

export const LogInContent = styled.div`
  ${FlexColCenter}
  text-align: center;
  max-width: 700px;
  margin: 0 auto;

  .sign-in-button {
    margin: 20px auto 0;
  }

  span {
    display: inherit;
    margin: 10px auto 0;
    font-size: 14px;

    button {
      margin: 0 5px;
      text-decoration: underline;
    }
  }

  .explanation-text {
    margin-top: 20px;

    .p {
      font-size: 12px;
    }
  }
`;
