import styled from 'styled-components';
import { FlexCenter, FlexCol, FlexHorizontalCenter } from '../../styles/styles';

export const GroupStatementRowContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray1};
  }
  
  &.selected {
    background: ${({ theme }) => theme.colors.light};
  }

  .checkbox {
    background-color: ${({ theme }) => theme.colors.white};
  }

  .matched {
    color: ${({ theme }) => theme.colors.green};
  }

  .unmatched {
    color: ${({ theme }) => theme.colors.red};
  }

  .dollar-amount-cell {
    font-weight: 500;
    line-height: 22px;
  }
`;

export const StatementCellContainer = styled.div`
  font-size: 14px;
`;

export const StatementItemSkeletonContainer = styled.div`
  .statement-row {
    width: 100%;
    height: 40px;
    display: flex;
  }
`;

export const StatementItemContainer = styled.div``;

export const DesktopRowContainer = styled.div`
  display: none;
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${ FlexHorizontalCenter }
    justify-content: space-between;
    padding: 10px;

    .checkbox-cell {
      width: 5%;
    }

    .cell.month-cell {
      text-align: left;
      width: 16%;
    }
  }
`;

export const MobileStatementContainer = styled.div` 
  border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray1};

  .animation-wrapper {
    overflow: hidden;
    height: 0;
  }

  .top-row {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    &.expanded {
      .dollar-amount-cell {
        display: none;
      }
    }

    .checkbox-cell {
      width: 30px;
    }

    .left-wrapper, .right-wrapper {
      ${FlexCenter}
    }

    .month-cell {
      margin-left: 10px;
    }

    .dollar-amount-cell {
      margin-right: 10px;
    }

    .chevron-button {
      border: none;
      background: none;
    }

    .chevron-icon {
      width: 14px;
      height: 14px;
    }
  }

  .dollars {
    align-items: center;
  }

  .statement-details {
    ${FlexCol}
    width: 100%;
    padding: 15px;

    &:not(:last-of-type) {
      border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray1};
    }

    .statement-button-wrapper {
      margin: 0 auto;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: none;
  }
`;

export const StatementDetailItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }

  .unit {
    font-size: 14px;
  }

  .dollar-amount-cell {
    margin-right: 0;
  }
`;
