import styled from 'styled-components';
import { FlexCenter } from '../../../styles/styles';
import { IThemeProps } from '../../../styles/themes';

interface IPencilBannerProps extends IThemeProps {
  background?: string;
  location?: string;
}

export const PencilBannerContainer = styled.div<IPencilBannerProps>`  
  ${FlexCenter}
  padding: 5px 15px;
  width: 100%;

  ${({ location }) => location.includes('/account')
    ? `border-radius: 8px;
       margin-bottom: 20px;` 
    : '' }

  ${({ background, theme }) => !!background
    ? `background: ${background}`
    : `
        &:nth-child(4n+1) {
          background: ${theme.colors.darkYellow1};
          color: ${theme.colors.dark};
        }

        &:nth-child(4n+2) {
          background: ${theme.colors.primary};
        }

        &:nth-child(4n+3) {
          background: ${theme.colors.green};
        }

        &:nth-child(4n+4) {
          background: ${theme.colors.primary};
        }
      ` }
`;
