import React, { useCallback, useMemo } from 'react';
import { ValueModel } from '../../models/values';
import { BodyWrapper, HeaderRowContainer, ValuesComparisonContainer, ValuesWrapper } from './styles';
import { ValueRow } from './ValueRow';
import { CompanyModel } from '../../models/companies';
import { H4 } from '../../styles/components/header';

interface IProps {
  className?: string;
  anchorValues: ValueModel[];
  comparisonValues1: ValueModel[];
  comparisonValues2?: ValueModel[];
  anchorCompanyName: string;
  comparison1CompanyName: string;
  comparison2CompanyName?: string;
  anchorCompany: CompanyModel;
  comparisonCompany1: CompanyModel;
  comparisonCompany2?: CompanyModel;
}

export interface IComparisonValue {
  name: string;
  anchor: boolean;
  comparison1: boolean;
  comparison2?: boolean;
}

export const ValuesComparison: React.FC<IProps> = ({
  className = '',
  anchorValues,
  comparisonValues1,
  comparisonValues2,
  anchorCompanyName,
  comparison1CompanyName,
  comparison2CompanyName,
  anchorCompany,
  comparisonCompany1,
  comparisonCompany2,
}) => {
  const comparisonArray = useMemo(() => {
    if (!anchorValues?.length && (!comparisonValues1?.length || !comparisonValues1)) return [];

    const comparisonArray: IComparisonValue[] = [];

    if (anchorValues.length) {
      anchorValues.forEach(v => {
        comparisonArray.push({
          name: v.name,
          anchor: true,
          comparison1: false,
          comparison2: false,
        });
      });

      if (!comparisonValues1) {
        return comparisonArray;
      } else {
        comparisonValues1.forEach(v => {
          const existingValue = comparisonArray.find(c => c.name === v.name);
          if (!!existingValue) {
            existingValue.comparison1 = true;
          } else {
            comparisonArray.push({
              name: v.name,
              anchor: false,
              comparison1: true,
              comparison2: false,
            });
          }
        });
      }
      if (!comparisonValues2) {
        return comparisonArray;
      } else {
        comparisonValues2.forEach(v => {
          const existingValue = comparisonArray.find(c => c.name === v.name);
          if (!!existingValue) {
            existingValue.comparison2 = true;
          } else {
            comparisonArray.push({
              name: v.name,
              anchor: false,
              comparison1: false,
              comparison2: true,
            });
          }
        });
      }
    } else if (!anchorValues.length && comparisonValues1.length) {
      comparisonValues1.forEach(v => {
        comparisonArray.push({
          name: v.name,
          anchor: false,
          comparison1: true,
          comparison2: false,
        });
      });
    } else if (!anchorValues.length && !comparisonValues1.length && comparisonValues2.length) {
      comparisonValues2.forEach(v => {
        comparisonArray.push({
          name: v.name,
          anchor: false,
          comparison1: false,
          comparison2: true,
        });
      });
    }

    return comparisonArray;
  }, [anchorValues, comparisonValues1, comparisonValues2]);
    
  const renderHeaderRow = useCallback(() => {
    if (!anchorCompanyName) return null;

    return (
      <HeaderRowContainer>
        <H4 className='value-title'>Values</H4>
        <div className='value-comparison-container'>
          <div className='anchor-cell header-cell'>
            {
              !!anchorCompany.logo ?
                <img
                  src={ anchorCompany.logo }
                  alt={ anchorCompany.companyName }
                />
                : <p>{ anchorCompany.companyName }</p>
            }
          </div>
          <div className='comparison-1-cell header-cell'>
            { 
              comparison1CompanyName
                ? !!comparisonCompany1.logo
                  ?
                  <img
                    src={ comparisonCompany1.logo }
                    alt={ comparison1CompanyName + ' logo' }
                  />
                  : <p>{ comparison1CompanyName }</p>
                : null
            }
          </div>
          {
            !!comparison2CompanyName && 
            (
              <div className='comparison-2-cell header-cell'>
                {
                  comparison2CompanyName
                    ? !!comparisonCompany2.logo
                      ?
                      <img
                        src={ comparisonCompany2.logo }
                        alt={ comparison2CompanyName + ' logo' }
                      />
                      : <p>{ comparison2CompanyName }</p>
                    : null
                }
              </div>
            )
          }
        </div>
      </HeaderRowContainer>
    );
  }, [anchorCompanyName, comparison1CompanyName, comparison2CompanyName]);

  const renderValues = useCallback(() => {
    if (!comparisonArray) return null;

    return (
      <BodyWrapper>
        {
          comparisonArray.map((value: IComparisonValue) => (
            <ValueRow
              className='value'
              key={ value.name }
              value={ value }
              hasComparison1={ !!comparison1CompanyName }
              hasComparison2={ !!comparison2CompanyName }
            />
          ))
        }
      </BodyWrapper>
    );
  }, [comparisonArray]);

  return (
    <ValuesComparisonContainer className={ className }>
      {
        !anchorValues || (!anchorValues?.length && !comparisonValues1?.length) ?
          <div className='no-values'>No Values to Compare</div> :
          (
            <ValuesWrapper>
              { renderHeaderRow() }
              { renderValues() }
            </ValuesWrapper>
          )
      }
      <div className='disclaimer-text'>*Not all companies have Company Values mapped yet</div>
    </ValuesComparisonContainer>
  );
};
