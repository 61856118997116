import styled from 'styled-components';
import { FlexCol } from '../../styles/styles';

export const UNSDGsLegendWithTextContainer = styled.div`
  ${FlexCol}  
  align-items: center;
  margin: 0 auto;
  width: 100%;

  div {
    display: flex;
    width: 95%;

    p {
      margin-left: 10px;
      text-align: start;
      width: 95%;
    }
  }
`;
