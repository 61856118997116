import React from 'react';
import { H1, H4, H5 } from '../../styles/components/header';
import { DigitalWalletTermsContainer, DigitalWalletTermsWrapper } from './styles';

interface IProps {
  className?: string;
}

export const DigitalWalletTerms: React.FC<IProps> = ({
  className = '',
}) => (
  <DigitalWalletTermsContainer className={ className } title={ 'Digital Wallet Terms and Conditions' }>
    <DigitalWalletTermsWrapper>
      <H1 className='center'>Digital Wallet Terms & Conditions</H1>
      <p className='center last-update'>Date of last update February, 2024</p>

      <p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE CREATING, ACTIVATING OR USING YOUR CARD IN THE DIGITAL WALLET, BECAUSE BY DOING SO, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS.</p>
      <p>IF YOU DO NOT AGREE TO THESE TERMS, YOU MUST NOT CREATE, ACTIVATE OR USE A CARD IN A DIGITAL WALLET.</p>

      <H4 className='section-title-text'>Terms for Adding Your Karma Wallet Physical or Virtual Card to a Digital Wallet</H4>
      <p>These Terms (the “Terms”) apply when you choose to add your physical or virtual Card (“Card”) to a Digital Wallet (“Digital Wallet”). In these Terms, “you” and “your” refer to the cardholder or a person authorized by the cardholder to use the Card. “We,” “us,” and “our,” refer to Karma Wallet and Pathward, N.A., member FDIC.</p>

      <H5 className='section-title-text'>1. Adding Your Card.</H5>
      <p>You can add an eligible Card to the Digital Wallet by following the instructions of the Digital Wallet provider. When you add a Card to the Digital Wallet, the Digital Wallet allows you to use the Card to enter into transactions where the Digital Wallet is accepted.</p>

      <H5 className='section-title-text'>2. Terms that Apply.</H5>
      <p>The terms of use for the Card do not change when you add your Card to the Digital Wallet and these Terms do not amend or supersede such Card agreements. You understand that the Digital Wallet simply provides another way for you to make purchases with the Card and that your existing agreements or terms of service for the Card continue to apply to any Card transactions made through the Digital Wallet.</p>
      <p>We do not charge you any additional fees for adding your Card to the Digital Wallet or using your Card in the Digital Wallet. However, the Digital Wallet provider and other third parties, such as wireless companies or data service providers, may charge you fees (for example, your mobile service carrier may impose data usage or text message charges for your use of or interaction with the Digital Wallet).</p>

      <H5 className='section-title-text'>3. We are Not Responsible for the Digital Wallet.</H5>
      <p>We are not the provider of the Digital Wallet, and we are not responsible for providing the Digital Wallet service to you. We are not responsible in any way for any failure of the Digital Wallet, or the inability to use the Digital Wallet for any transaction.</p>
      <p>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE USE OF DIGITAL WALLET IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY DIGITAL WALLET IS PROVIDED TO YOU "AS IS" AND "AS AVAILABLE", WITH ALL DEFECTS THAT MAY EXIST FROM TIME TO TIME AND WITHOUT WARRANTY OF ANY KIND, AND WE HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO ANY DIGITAL WALLET.</p>

      <H5 className='section-title-text'>4. Suspension and Removal of a Card from Digital Wallet.</H5>
      <p>We reserve the right to discontinue offering or supporting the Digital Wallet for any reason and we can block a Card in the Digital Wallet from purchases at any time.</p>

      <H5 className='section-title-text'>5. Privacy.</H5>
      <p>You agree that we may share your information with the Digital Wallet provider, a payment network, and others in order to (i) enable Card transactions through the Digital Wallet, and (ii) to make information available to you about your Card transactions. When you use the Card within the Digital Wallet, you will share transaction information with the Digital Wallet provider in addition to us. We do not control the privacy and security of your information once it is shared with the Digital Wallet provider. Please read the privacy policy of the Digital Wallet provider to understand how they protect information provided to them.</p>

      <H5 className='section-title-text'>6. Security.</H5>
      <p>You are solely responsible for maintaining the confidentiality of your passwords, login ID, and any other credentials or means that you use to access the Digital Wallet.</p>
      <p>Call us immediately if you lose your phone or if someone has used your Digital Wallet or your credentials without your authorization. When you get a new phone, please be sure to delete all your Cards and other personal information from the Digital Wallet on the old phone.</p>
    </DigitalWalletTermsWrapper>
  </DigitalWalletTermsContainer>
);
