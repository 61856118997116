import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Car: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}car-graphic` }
    id={ id }
    height={ 63 }
    width={ 129 }
  >
    <path d='M107.558 51.2602H9.08319C5.22062 51.2602 2.12207 48.7488 2.12207 45.7097V30.9365C2.12207 29.0334 2.8823 27.2083 4.23553 25.8626C5.58875 24.5169 7.42412 23.7609 9.33787 23.7609H25.1277C26.8907 23.7656 28.5925 23.1187 29.9029 21.9459L49.1521 4.80901C50.7216 3.4324 52.7471 2.68082 54.8398 2.69855H94.5692C95.3527 2.69092 96.1255 2.88039 96.8156 3.24937C97.5058 3.61835 98.0909 4.15485 98.5166 4.80901L108.852 21.2706C109.355 22.0194 110.042 22.6278 110.848 23.0378C111.654 23.4479 112.553 23.646 113.458 23.6132H121.395C121.979 23.5849 122.564 23.6717 123.114 23.8689C123.664 24.066 124.17 24.3695 124.602 24.7618C125.034 25.1541 125.384 25.6276 125.631 26.1548C125.878 26.6821 126.018 27.2528 126.043 27.8341V45.7097C125.944 47.2606 125.238 48.711 124.075 49.749C122.912 50.787 121.386 51.3297 119.824 51.2602H107.558Z' fill='#fff' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.1416 23.7443H91.1734C91.5274 23.7387 91.8768 23.6622 92.2017 23.5192C92.5266 23.3762 92.8206 23.1695 93.0669 22.9109C93.3133 22.6522 93.5071 22.3468 93.6374 22.012C93.7677 21.6771 93.8319 21.3194 93.8263 20.9594C93.8237 20.538 93.7218 20.1234 93.5291 19.7504L84.9934 2.69824' stroke='#323232' strokeWidth='2' strokeLinecap='round' fill='transparent' strokeLinejoin='round' />
    <path d='M67.4551 3.03906L67.4551 23.1628' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M118.722 41.8223H124.816C125.921 41.8223 126.816 42.7177 126.816 43.8223V49.266C126.816 50.3706 125.921 51.266 124.816 51.266H118.722V41.8223Z' fill='#BABAC2' stroke='#323232' strokeWidth='2' />
    <path d='M1.34912 43.8223C1.34912 42.7177 2.24455 41.8223 3.34912 41.8223H13.4911V51.266H3.34912C2.24455 51.266 1.34912 50.3706 1.34912 49.266V43.8223Z' fill='#BABAC2' stroke='#323232' strokeWidth='2' />
    <path d='M29.5654 61.8298C35.4628 61.8298 40.2436 57.049 40.2436 51.1516C40.2436 45.2542 35.4628 40.4734 29.5654 40.4734C23.668 40.4734 18.8872 45.2542 18.8872 51.1516C18.8872 57.049 23.668 61.8298 29.5654 61.8298Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M29.5647 56.4137C32.4709 56.4137 34.8267 54.0576 34.8267 51.1513C34.8267 48.245 32.4709 45.8889 29.5647 45.8889C26.6586 45.8889 24.3027 48.245 24.3027 51.1513C24.3027 54.0576 26.6586 56.4137 29.5647 56.4137Z' fill='#fff' />
    <path d='M101.743 61.8312C107.268 61.8312 111.747 57.3521 111.747 51.8268C111.747 46.3016 107.268 41.8225 101.743 41.8225C96.218 41.8225 91.7393 46.3016 91.7393 51.8268C91.7393 57.3521 96.218 61.8312 101.743 61.8312Z' fill='#323232' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M101.743 56.7568C104.466 56.7568 106.673 54.5495 106.673 51.8265C106.673 49.1036 104.466 46.8962 101.743 46.8962C99.0202 46.8962 96.813 49.1036 96.813 51.8265C96.813 54.5495 99.0202 56.7568 101.743 56.7568Z' fill='#fff' />
    <path d='M2.69824 29.6804H16.8639' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.6626 29.6804H62.059' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
