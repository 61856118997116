import { observer } from 'mobx-react';
import React from 'react';
import { withTheme } from 'styled-components';
import { GroupHero } from '../../../components/GroupHero';
import { GroupMembersList } from '../../../components/GroupMembersList';
import { GroupSettings } from '../../../components/GroupSettings';
import { useGroup } from '../../../contexts/group';
import { Section } from '../../../styles/components/containers';
import { IThemeProps } from '../../../styles/themes';
import { AccountContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
}

const AccountBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const { group } = useGroup();

  return (
    <AccountContainer className={ className } title={ !!group?.name ? `${group?.name} Account` : 'Group Account' }>
      <GroupHero group={ group } />
      <Section>
        <GroupMembersList group={ group } />
      </Section>
      <Section backgroundColor={ theme.colors.light }>
        <GroupSettings group={ group } />
      </Section>
    </AccountContainer>
  );
};

const AccountAsObserver = observer(AccountBase);
export const Account = withTheme(AccountAsObserver);
