import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { FlexCenter, FlexCol, FlexColCenter, FlexRow } from '../../../styles/styles';
import { TextLGDemiBold } from '../../../styles/components/text';

export const WhatIsKarmaCollectiveSectionContainer = styled(Section)`
  padding: 65px 15px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 120px 15px;
  }
`;

export const WhatIsKarmaCollectiveInnerSectionContainer = styled(MaxBoundaryContainerLarge)`
  ${FlexCol}
  align-items: center;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    justify-content: space-evenly;
  }
`;

export const WhatIsKarmaCollectiveTextContainer = styled.div`
  ${FlexColCenter}
  align-items: start;
  max-width: 528px;

  h1, p {
    margin-bottom: 16px;
    text-align: start;
  }

  .bold {
    ${TextLGDemiBold}
  }
  
  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    align-items: start;
    justify-content: flex-start;
    width: 50%;
  }
`;

export const ROIContainer = styled.div`
  ${FlexCol}
  align-items: center;
  margin-top: 50px;

  .abbreviations {
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    max-width: 242px;
    margin-bottom: 0;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 648px;

    .abbreviations {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

export const ROITopSectionContainer = styled.div`
  ${FlexCenter}
  gap: 24px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;

  .left-content {
    ${FlexCol}
    gap: 10px;
  }
  
  .horizontal-line {
    border: 1px solid ${({ theme }) => theme.colors.grey2};
  }

  .benefit-box {
    ${FlexCenter}
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 20px;
    font-weight: 700;
    gap: 6px;
    line-height: 29px;
    padding: 10px 15px;
  }

  svg {
    width: 14px;
  }

  .roi-box {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    gap: 14px;
    font-size: 32px;
    line-height: 32px;

    svg {
      width: 23px;
    }

    .left-content {
      gap: 16px;
    }

    .benefit-box {
      font-size: 32px;
      padding: 24px 16px;
    }
  }
`;

export const ROIBottomSectionContainer = styled.div``;
