import styled from 'styled-components';
import { SvgIcon } from '../../SvgIcon';

export const ArrowSvgIcon = styled(SvgIcon)`
  &.up {
    transform: rotate3d(0, 0, 1, -90deg);
  }

  &.left {
    transform: rotate3d(0, 0, 1, 180deg);
  }

  &.down {
    transform: rotate3d(0, 0, 1, 90deg);
  }
`;
