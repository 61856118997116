import styled from 'styled-components';
import { FlexCol } from '../../../../styles/styles';
import { TextLGMedium } from '../../../../styles/components/text';

export const CoreValueCardContainer = styled.div`
  ${FlexCol}
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  flex: 1;
  min-height: 425px;
  max-width: 375px;
  padding: 60px 32px 30px;
  text-align: center;

  div {
    margin-bottom: 30px;

    img {
      height: 80px;
      max-width: 84px;
      margin-bottom: 10px;
    }
  }

  p {
    ${TextLGMedium}
    line-height: 1.75em;

    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
