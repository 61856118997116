import React, { useEffect } from 'react';
import { PendingPageContainer } from './styles';
import { observer } from 'mobx-react';
import { ResponseText, ResultHeader } from '../../styles';
import { useAnalytics } from '../../../../contexts/analytics-store';

const PendingPageBase: React.FC = () => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.fireEvent('ApplyForCard_Pending');
  }, []);

  return (
    <PendingPageContainer>
      <ResultHeader>Applicaton is Pending</ResultHeader>
      <ResponseText className='response-text'>
        Thank you for providing documentation. We are reviewing and will get back to you via email. If you need to reach out, please email <span className='email-text'>support@karmawallet.io</span>.
      </ResponseText>
    </PendingPageContainer>
  );
};

export const PendingPage = observer(PendingPageBase);
