import React, { useCallback, useRef, useState } from 'react';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useToaster } from '../../contexts/toaster-store';
import { FileUploaderModel, ResourceTypes } from '../../models/fileUploader';
import { GroupModel } from '../../models/group';
import { IThemeProps } from '../../styles/themes';
import { ButtonKind } from '../Button/styles';
import { FileUploader } from '../FileUploader';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { PencilIcon } from '../svgs/icons/PencilIcon';
import { PeopleIcon } from '../svgs/icons/PeopleIcon';
import { EditButtonContainer, GroupLogoContainer, IGroupLogoProps, UploaderCTAs } from './styles';

interface IProps extends IGroupLogoProps, IThemeProps {
  allowEditing?: boolean;
  className?: string;
  group: GroupModel;
}

const EditButton: React.FC = () => (
  <EditButtonContainer>
    <PencilIcon />
  </EditButtonContainer>
); 

const GroupLogoBase: React.FC<IProps> = ({
  allowEditing,
  className = '',
  group,
  withBorder,
  theme,
}) => {
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const fileUploader = useRef(new FileUploaderModel('karma')).current;
  const [fileToUpload, setFileToUpload] = useState<File>(null);
  const [previewImg, setPreviewImg] = useState('');
  const [loadingPreview, setLoadingPreview] = useState(false);

  const onCancelUploadClick = useCallback(() => {
    setFileToUpload(null);
    setPreviewImg('');
  }, []);

  const onLogoChange = useCallback((file: File) => {
    if (!!file) {
      setFileToUpload(file);
      setLoadingPreview(true);
      const reader = new FileReader();

      reader.onload = function (e: ProgressEvent<FileReader>) {
        setPreviewImg(e.target.result.toString());
        setLoadingPreview(false);
      };

      reader.readAsDataURL(file);
    }
  }, []);

  const onSaveUploadClick = useCallback(async () => {
    if (!!fileToUpload && !fileUploader.uploadingImage) {
      try {
        const { url } = await fileUploader.uploadImage(fileToUpload, {
          resourceType: ResourceTypes.GroupLogo,
          resourceId: group._id,
        });

        await group.update({ logo: url });

        toaster.push({ message: 'Group logo updated successfully.' });
      } catch (err: any) {
        errorMessages.push({
          title: 'Error Updatiung Group Logo',
          message: err.message,
        });
      } 
    }
  }, [fileToUpload, fileUploader.uploadingImage]);

  const renderEditButton = () => {
    if (!allowEditing) return null;

    if (!previewImg) {
      return (
        <FileUploader
          className='file-uploader'
          label='Edit Group Logo'
          anchor={ <EditButton /> }
          onChange={ onLogoChange }
        />
      );
    }

    return (
      <UploaderCTAs
        className='file-uploader'
        ctas={ [
          {
            id: 'save-group-logo-upload',
            text: 'Save',
            kind: ButtonKind.Primary,
            onClick: onSaveUploadClick,
          },
          {
            id: 'cancel-group-logo-upload',
            text: 'Cancel',
            kind: ButtonKind.PrimaryGhost,
            onClick: onCancelUploadClick,
          },
        ] }
      />
    );
  };

  const renderImage = () => {
    if (loadingPreview) return <LoadingSpinner />;
    if (!!previewImg) return <img src={ previewImg } alt='preview of newly uploaded logo' />;

    return !!group?.logo
      ? <img src={ group.logo } alt={ `Logo for ${group.name}` } />
      : <PeopleIcon className='default-group-logo' fill={ theme.colors.lightGray1 } />;
  };

  return (
    <GroupLogoContainer
      className={ className }
      withBorder={ withBorder }
    >
      { !!fileUploader.uploadingImage && <LoadingSpinner /> }
      { renderImage() }
      { renderEditButton() }
    </GroupLogoContainer>
  );
};

export const GroupLogo = withTheme(GroupLogoBase);
