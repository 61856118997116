import * as React from 'react';
import { MobileNavItemsContainer, MobileNavMenuItemContainer, SubMenuContainer } from './styles';
import { ChevronDirection, ChevronIcon } from '../../svgs/icons/ChevronIcon';
import { theme } from '../../../styles/themes';
import { useHeader } from '../../../contexts/header';
import { SubMenuSelected } from '../../../models/header';
import { observer } from 'mobx-react';
import { headerMenus } from '../constants';
import NavigationSubMenu from '../NavigationSubMenu';

interface IProps {
  className?: string;
  setMobileNavOpen: (isOpen: boolean) => void;
  setMobileUserAccountMenuIsOpen?: (isOpen: boolean) => void;
}

const MobileNavigationMenuBase: React.FunctionComponent<IProps> = ({
  className='',
  setMobileNavOpen,
  setMobileUserAccountMenuIsOpen,
}) => {
  const appHeader = useHeader();

  const renderMainMenus = () => {
    if (appHeader.subMenuSelected === SubMenuSelected.None) {
      return headerMenus.map(( headerMenu ) => (
        <MobileNavMenuItemContainer
          expanded={ false }
          key={ headerMenu.id }
          onClick={ ()=> appHeader.setSubMenuSelected(headerMenu.id as SubMenuSelected) }
        >
          { headerMenu.text }
          <ChevronIcon direction={ ChevronDirection.Right } stroke={ theme.colors.primary } />
        </MobileNavMenuItemContainer>
      ));
    }
  };

  const submenu = headerMenus.map(( headerMenu ) => {
    if (headerMenu.id === appHeader.subMenuSelected ) {
      return (
        <React.Fragment key={ 'submenu-list' }>
          <MobileNavMenuItemContainer
            expanded
            onClick={ () => appHeader.setSubMenuSelected(SubMenuSelected.None) }
            key={ headerMenu.id }
          >
            <ChevronIcon direction={ ChevronDirection.Left } stroke={ theme.colors.primary } />
            { headerMenu.text }
          </MobileNavMenuItemContainer>
          <SubMenuContainer key='subMenuContainer'>
            <NavigationSubMenu
              subMenuItems={ headerMenu.subMenu }
              setMobileNavOpen={ setMobileNavOpen }
              setMobileUserAccountMenuIsOpen={ setMobileUserAccountMenuIsOpen }
            />
          </SubMenuContainer>
        </React.Fragment>
      );
    }
  });

  return (
    <MobileNavItemsContainer
      className={ className }
    >
      { renderMainMenus() }
      { submenu }
    </MobileNavItemsContainer>
  );
};

const MobileNavigationMenu = observer(MobileNavigationMenuBase);

export default MobileNavigationMenu;
