import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  id?: string;
}

export const Sappling: FC<IProps> = ({ className = '', id = '' }) => (
  <SvgIcon
    className={ `${className ? className + ' ' : ''}sappling-graphic` }
    id={ id }
    height={ 71 }
    width={ 71 }
  >
    <path d='M54.4638 1H61.6356C61.6356 4.80417 60.1244 8.45253 57.4344 11.1425C54.7445 13.8324 51.0961 15.3436 47.2919 15.3436H40.1201C40.1201 11.5395 41.6313 7.89111 44.3213 5.20115C47.0112 2.5112 50.6596 1 54.4638 1Z' fill='#95D28B' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M53.8783 6.37329L32.3628 19.8178' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M56.848 24H69.9998V26.3836C69.9998 31.4522 67.9863 36.3131 64.4023 39.8971C60.8183 43.4811 55.9574 45.4945 50.8889 45.4945H37.7371V43.1109C37.7371 38.0424 39.7505 33.1814 43.3345 29.5975C46.9185 26.0135 51.7794 24 56.848 24Z' fill='#95D28B' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M58.3528 32.0706L32.3628 49.0906' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M27.8892 29.3733H18.9192C14.1667 29.3733 9.60893 27.4854 6.24845 24.1249C2.88797 20.7644 1.00007 16.2066 1.00007 11.4542H9.97007C14.7225 11.4542 19.2803 13.3421 22.6408 16.7026C26.0013 20.0631 27.8892 24.6209 27.8892 29.3733Z' fill='#95D28B' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.7004 18.1663L31.8685 31.8684' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M32.2314 3.17896L32.2314 69.2736' stroke='#323232' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <line x1='22.9084' y1='69' x2='41.8175' y2='69' stroke='#323232' strokeWidth='2' strokeLinecap='round' />
  </SvgIcon>
);
