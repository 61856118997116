import styled from 'styled-components';
import { FlexCol } from '../../../styles/styles';
import { Section } from '../../../styles/components/containers';

interface IProps {
  backgroundImage: string;
}

export const DiscoverTheTruthSectionContainer = styled.div<IProps>`
  background: ${({ theme }) => theme.colors.dark};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
`;

export const InnerWrapperContainer = styled(Section)`
  background: transparent;
  margin: 0 auto;
  max-width: 880px;
  position: relative;
  text-align: center;
  z-index: 1;

  span {
    .live-your {
      color: ${({ theme }) => theme.colors.offWhite};
      display: inline;
      
      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        display: inline;
      }
    }

    .values {
      color: transparent;
      display: inline;

      @media (${({ theme }) => theme.breakpoints.lgMin}) {
        display: inline;
      }
    }
  }

  p {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 18px;
    margin: 30px auto 0;
    max-width: 510px;
    text-align: center;
  }

  h3 {
    color: ${({ theme }) => theme.colors.offWhite};
    margin-bottom: 20px;
  }

  .get-started-button {
    background: ${({ theme }) => theme.colors.offWhite};
    margin: 30px auto 0;

    &:hover {
      border-color: ${({ theme }) => theme.colors.offWhite};
    }
  }
`;

export const DataSourcesContainer = styled.div`
  ${FlexCol}
  align-items: center;
  margin-top: 30px;
  padding: 0 20px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin-top: 60px;
  }

  .top-data-sources-text {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .data-sources {
    display: block;
    width: 243px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      max-width: 850px;
      width: 100%;
    }
  }
`;
