import styled, { css } from 'styled-components';
import { ModalOverlay } from '../../components/modals/Modal/styles';

interface ISectionProps {
  backgroundColor?: string;
}

export const Section = styled.section<ISectionProps>`
  padding: 40px 15px;
  background: ${({ backgroundColor }) => backgroundColor || '#fff'};

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    padding: 60px 15px;
  }
`;

export const TextSection = styled.section`
  padding: 0 15px;
`;

export const TextSectionContainer = styled.div`
  max-width: 710px;
  width: 100%;
  margin: 0 auto;
`;

export const MaxBoundary = css`
  max-width: 1070px;
  width: 100%;
  margin: 0 auto;
`;

export const MaxBoundaryLarge = css`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
`;

export const MaxBoundaryContainer = styled.div`
  ${MaxBoundary}
`;

export const MaxBoundaryContainerLarge = styled.div`
  ${MaxBoundaryLarge}
`;

export const MaxBoundarySection = styled(Section)`
  ${MaxBoundary}
`;

export const MaxBoundarySectionLarge = styled(Section)`
  ${MaxBoundaryLarge}
`;

export const ModalOverlayWithTransition = styled(ModalOverlay)`
  transition: 1s filter linear;
  -webkit-transition: 1s -webkit-filter linear;
  -moz-transition: 1s -moz-filter linear;
  -ms-transition: 1s -ms-filter linear;
  -o-transition: 1s -o-filter linear;
`;
