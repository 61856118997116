import React from 'react';
import { EquivalencyGraphics } from '../../constants/equivalencies';
import { Airplane } from '../svgs/graphics/Airplane';
import { Car } from '../svgs/graphics/Car';
import { GarbageTruck } from '../svgs/graphics/GarbageTruck';
import { HomeElectricity } from '../svgs/graphics/HomeElectricity';
import { HomeEnergy } from '../svgs/graphics/HomeEnergy';
import { LightBulb } from '../svgs/graphics/LightBulb';
import { RecycleCan } from '../svgs/graphics/RecycleCan';
import { Sappling } from '../svgs/graphics/Sappling';
import { Smartphone } from '../svgs/graphics/Smartphone';
import { Trees } from '../svgs/graphics/Trees';
import { EquivalencyGraphicContainer } from './styles';

const graphicClassName = 'equivalency-graphic';

interface IProps {
  className?: string;
  icon: EquivalencyGraphics;
}

export const EquivalencyGraphic: React.FC<IProps> = ({
  className = '',
  icon,
}) => {
  const renderGraphic = () => {
    switch (icon) {
      case EquivalencyGraphics.Airplane :
        return <Airplane className={ graphicClassName } />;
      case EquivalencyGraphics.Car :
        return <Car className={ graphicClassName } />;
      case EquivalencyGraphics.GarbageTruck :
        return <GarbageTruck className={ graphicClassName } />;
      case EquivalencyGraphics.HomeElectricity :
        return <HomeElectricity className={ graphicClassName } />;
      case EquivalencyGraphics.HomeEnergy :
        return <HomeEnergy className={ graphicClassName } />;
      case EquivalencyGraphics.LightBulb :
        return <LightBulb className={ graphicClassName } />;
      case EquivalencyGraphics.Recycle :
        return <RecycleCan className={ graphicClassName } />;
      case EquivalencyGraphics.Sappling :
        return <Sappling className={ graphicClassName } />;
      case EquivalencyGraphics.SmartPhone :
        return <Smartphone className={ graphicClassName } />;
      default:
        return <Trees className={ graphicClassName } />;
    }
  };
  
  return (
    <EquivalencyGraphicContainer className={ className }>
      { renderGraphic() }
    </EquivalencyGraphicContainer>
  );
};
