import React, { useCallback, useEffect, useMemo } from 'react';
import { AbandonedPageContainer, ResultHeader } from './styles';
import { observer } from 'mobx-react';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { CurrentPage } from '../../../../models/karmaCard';
import { ResponseText } from '../../styles';

interface IProps {
  className?: string;
  pageType?: string;
}

const AbandonedPageBase: React.FC<IProps> = ({
  className,
  pageType,
}) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.fireEvent(`ApplyForCard_Abandoned_${pageType}`);
  }, []);

  const pageTypeTextHeader = useMemo(() => pageType === CurrentPage.AbandonedWithInfo ? 'Whoops!'
    : pageType === CurrentPage.ReturningAbandonedWithInfo ? 'Welcome Back'
      : 'Whoops!', [ pageType ]);

  const onContinueButtonClick = () => {
    analytics.fireEvent(`ApplyForCard_Abandoned_${pageType}_Continue`);
    //! TODO Add logic that will handle continue button click.
  };

  const renderPagebody = useCallback(() => {
    let text = '';
    if (pageType === CurrentPage.ReturningAbandonedWithInfo) {
      text = 'Click the button below to continue your application.';
    } else if (pageType === CurrentPage.AbandonedWithInfo) {
      text = 'If you would like to continue the application, please click the button below.';
    } else {
      text = 'If you would like to continue with identity verification, check your email for a link.';
    }

    return (
      <div>
        <ResponseText className='response-text'>
          { text }
        </ResponseText>
        { 
          pageType === CurrentPage.ReturningAbandonedWithInfo ||
          pageType === CurrentPage.AbandonedWithInfo && (
            <Button
              onClick={ onContinueButtonClick }
              className='cta-button'
              kind={ ButtonKind.Primary }
            >
              Continue
            </Button>)
        }
      </div>
    );
  }, [pageType]);

  return (
    <AbandonedPageContainer className={ className }>
      <ResultHeader>{ pageTypeTextHeader }</ResultHeader>
      { renderPagebody() }
    </AbandonedPageContainer>
  );
};

export const AbandonedPage = observer(AbandonedPageBase);
