import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';

type PrivateFields = '_badges' |
'_loading';

export interface IBadge {
  badgeCategory: string;
  badgeDescription: string;
  badgeId: number;
  badgeName: string;
  image: string;
  order: number;
  shortDescription: string;
  totalCompanies: number;
  _id: number;
}

export class BadgesModel extends BaseModel {
  private _loading = false;
  private _badges: IBadge[] = [];

  constructor () {
    super();
    makeObservable<BadgesModel, PrivateFields>(this, {
      _loading: observable,
      _badges: observable,
      badges: computed,
      hasBadges: computed,
      loading: computed,
      loadBadges: action.bound,
    });
  }

  get badges() { return this._badges; }
  get hasBadges() { return this._badges.length > 0; }
  get loading() { return this._loading; }

  public loadBadges = async () => {
    if (!this._loading) {
      this._loading = true;

      const result = await this.webServiceHelper.sendRequest<IBadge[]>({
        path: '/badge',
        method: 'GET',
      });

      if (result.success) {
        runInAction(() => {
          this._badges = result.value;
          this._loading = false;
        });
      } else {
        runInAction(() => {
          this._loading = false;
        });

        throw new Error(result.error);
      }
    }
  };
}
