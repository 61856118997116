import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { withTheme } from 'styled-components';
import { useAnalytics } from '../../../../contexts/analytics-store';
import { useSignInModal } from '../../../../contexts/sign-in';
import { useToaster } from '../../../../contexts/toaster-store';
import { useUserSession } from '../../../../contexts/user';
import { SignInMode } from '../../../../models/sign-in';
import { H1, H3 } from '../../../../styles/components/header';
import { IThemeProps } from '../../../../styles/themes';
import { isValidEmail } from '../../../../utils/input-validation';
import { CTAs } from '../../../CTAs';
import { EnvelopeIcon } from '../../../svgs/icons/EnvelopeIcon';
import { TextFieldKind } from '../../../TextField';
import { FormContainer, FormField } from '../styles';
import { Button } from '../../../Button';
import { ButtonKind } from '../../../Button/styles';

interface IProps extends IThemeProps {
  className?: string;
}

const ResetPasswordBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const signInModal = useSignInModal();
  const analytics = useAnalytics();
  const toaster = useToaster();
  const user = useUserSession();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const disablePasswordReset = useCallback(() => !email ||
      !!emailError ||
      !isValidEmail(email), [email, emailError]);

  const onEmailBlur = useCallback(() => {
    if (!isValidEmail(email)) setEmailError('This isn\'t a valid e-mail address');
  }, [email, emailError]);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    setEmail(e.target.value);
  }, [email]);

  const onEmailFocus = useCallback(() => {
    analytics.fireEvent('ResetPassword_Email_Interact');
  }, []);

  const onGetStartedClick = useCallback(() => {
    signInModal.setMode(SignInMode.SIGN_IN);
    analytics.fireEvent(`SignInModalModeChange_${SignInMode.CREATE_ACCOUNT}`);
  }, [signInModal.mode]);

  const onResetPasswordClick = useCallback(() => {
    analytics.fireEvent('ResetPassword_ResetPassword_Click');
    user.createPasswordToken({ email })
      .then(() => {
        analytics.fireEvent('ResetPassword_SendEmail_Success');
        signInModal.close();
        toaster.push({
          message: 'A password reset email was sent, it will expire in 15 minutes.',
        });
      })
      .catch(() => {
        analytics.fireEvent('ResetPassword_SendEmail_Error');
      });
  }, [email]);

  return (
    <FormContainer className={ className }>
      <H1>Reset Password</H1>
      <H3>Confirm your email address and we’ll send you instructions.</H3>
      <form>
        <FormField
          id='user-email-input'
          autoComplete='email'
          className={ !!emailError ? 'error' : '' }
          fieldKind={ TextFieldKind.Pill }
          label='Email Address'
          labelHidden
          leftAccessory={ <EnvelopeIcon stroke={ theme.colors.disabled } /> }
          placeholder='Email Address'
          errorText={ !!emailError ? emailError : '' }
          value={ email }
          onBlur={ onEmailBlur }
          onChange={ onEmailChange }
          onFocus={ onEmailFocus }
        />
        <CTAs
          className='ctas-container'
          ctas={ [
            {
              id: 'reset-password-cta',
              disabled: disablePasswordReset(),
              onClick: onResetPasswordClick,
              text: 'Reset Password',
            },
          ] }
        />
      </form>
      <Button
        className='bottom-action new-to-karma'
        kind={ ButtonKind.Blank }
        onClick={ onGetStartedClick }
      >
        Back to Sign In
      </Button>
    </FormContainer>
  );
};

const ResetPasswordAsObserver = observer(ResetPasswordBase);
export const ResetPassword = withTheme(ResetPasswordAsObserver);
