import { AppType } from '../constants';
import { WebServiceHelper } from '../lib/webServiceHelper';
import { SocketClient } from './socket-client';

interface IConfig {
  basePath?: string;
}

export class BaseModel {
  protected _app: AppType;
  protected _webServiceHelper: WebServiceHelper = null;
  protected _socketClient: SocketClient = null;
  protected _basePath: string = null;

  constructor({ basePath }: IConfig = {}, socketClient?: SocketClient, app?: AppType) {
    this._app = app;
    this._basePath = basePath;
    this._socketClient = socketClient;
  }

  get socketClient() { return this._socketClient; }

  get webServiceHelper() {
    if (!this._webServiceHelper) {
      // only want to instantiate object when used...
      this._webServiceHelper = new WebServiceHelper(this._basePath, this._app);
    }
  
    return this._webServiceHelper;
  }
}
