import { observer } from 'mobx-react';
import React, { useRef, useState, useMemo } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { useUserSession } from '../../contexts/user';
import { formatter } from '../../lib/misc';
import { buildRareUrl } from '../../lib/urlBuilders';
import { StatementModel } from '../../models/statements';
import { IThemeProps, theme } from '../../styles/themes';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { Checkbox } from '../Checkbox';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';
import { DesktopRowContainer, GroupStatementRowContainer, MobileStatementContainer, StatementCellContainer, StatementDetailItemContainer, StatementItemContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  selected: boolean;
  statement: StatementModel;
  onClick(): void;
  groupId: string;
}

const collapsedConfig = {
  height: '0px',
};

const chevronDownConfig = {
  transform: 'rotate(0deg)',
};

const chevronUpConfig = {
  transform: 'rotate(180deg)',
};

const GroupStatementRowBase: React.FC<IProps> = ({
  className = '',
  statement,
  onClick,
  selected,
  groupId,
}) => {
  const userSession = useUserSession();
  const [expanded, setExpanded] = useState(false);
  const expansionEngaged = useRef(false);
  const { date, _id, offsets } = statement;
  const { toBeMatched, totalMemberOffsets, matched } = offsets;
  const memberOffsetsInTonnes = totalMemberOffsets.tonnes.toFixed(2);
  const toBeMatchedTonnes = toBeMatched.tonnes.toFixed(2);
  const toBeMatchedDollar = formatter.format(toBeMatched.dollars);
  const donationUrl = buildRareUrl({
    amount: toBeMatched.dollars,
    statementIds: _id,
    user_id: userSession._id,
    groupId,
    email: userSession.primaryEmail.email,
    redirectTo: window.location.href,
  });

  const isMatchable = useMemo(() => !matched && toBeMatched.dollars > 0, [offsets.toBeMatched, offsets.matched]);

  const [matchedClass, matchedText] = useMemo(() => {
    if (matched) return ['matched', 'Matched'];
    if (!matched && isMatchable) return ['unmatched', 'Unmatched'];
    
    return ['unmatchable', '--'];
  }, [offsets.matched, isMatchable]);

  const expandedConfig = {
    height: isMatchable ? '240px' : '160px',
  };

  const expandSpring = useSpring({
    config: config.gentle,
    from: expanded || !expansionEngaged.current ? collapsedConfig : expandedConfig,
    to: expanded ? expandedConfig : collapsedConfig,
  });

  const chevronSpring = useSpring({
    config: config.gentle,
    from: expanded || !expansionEngaged.current ? chevronDownConfig : chevronUpConfig,
    to: expanded ? chevronUpConfig : chevronDownConfig,
  });

  const handleExpandRow = () => {
    expansionEngaged.current = true;
    setExpanded(!expanded);
  };

  const renderStatementDetailsItem = (className: string, text: string, label: string, textClassName: string) => (
    <StatementDetailItemContainer className={ className }>
      <div className='label'>{ label }</div>
      <div className={ textClassName }>{ text }</div>
    </StatementDetailItemContainer>
  );

  const renderStatementCell = (className: string, text: string) => (
    <StatementCellContainer className={ `${className} cell` }>{ text }</StatementCellContainer>
  );

  const renderItem = () => (
    <StatementItemContainer>
      <DesktopRowContainer>
        <div className='checkbox-cell'>
          { 
            isMatchable && (
              <Checkbox
                className='statement-checkbox'
                label='Select Statement'
                labelHidden
                id={ `statement-checkbox-${statement._id}` }
                checked={ selected }
                onChange={ onClick }
              />
            )
          }
        </div>
        { renderStatementCell('month-cell', date.format('MMM, YYYY')) }
        { renderStatementCell('member-cell', memberOffsetsInTonnes) }
        { renderStatementCell('group-tonnes-cell', `${toBeMatchedTonnes}`) }
        { renderStatementCell(`${matchedClass} dollar-amount-cell`, toBeMatchedDollar) }
        { renderStatementCell(`${matchedClass} status-cell`, `${matchedText}`) }
      </DesktopRowContainer>
      <MobileStatementContainer>
        <div className={ `${expanded ? 'expanded' : ''} top-row` }>
          <div className='left-wrapper'>
            <div className='checkbox-cell'>
              {
                isMatchable && (
                  <Checkbox
                    className='statement-checkbox'
                    label='Select Statement'
                    labelHidden
                    id={ `statement-checkbox-${statement._id}` }
                    checked={ selected }
                    onChange={ onClick }
                  />
                )
              }
            </div>
            <div className='month-cell cell-mobile'>{ date.format('MMM, YYYY') }</div>
          </div>
          <div className='right-wrapper'>
            <div className={ `${matchedClass} dollar-amount-cell cell-mobile` }>
              { toBeMatchedDollar }
            </div>
            <button className='chevron-button' onClick={ handleExpandRow }>
              <animated.div style={ chevronSpring }>
                <ChevronIcon
                  direction={ ChevronDirection.Down }
                  stroke={ theme.colors.disabled }
                />
              </animated.div>
            </button>
          </div>
        </div>
        <animated.div className='animation-wrapper' style={ expandSpring }>
          <div className='statement-details'>
            { renderStatementDetailsItem('member-tonnes', `${memberOffsetsInTonnes} tonnes`, 'Member Offsets', 'unit') }
            { renderStatementDetailsItem('match-tonnes', `${toBeMatchedTonnes} tonnes`, 'Offsets to Match', 'unit') }
            { 
              renderStatementDetailsItem(
                'match dollars',
                !matched ? toBeMatchedDollar : `${toBeMatchedDollar} (matched)`,
                'Total Group Offsets',
                `${matchedClass} dollar-amount-cell cell-mobile`,
              ) 
            }
            { 
              isMatchable && (
                <div className='statement-button-wrapper'>
                  <ButtonLink
                    className='match-offsets-cta'
                    href={ donationUrl }
                    kind={ ButtonKind.Primary }
                    target='_blank'
                  >
                    Match This Month
                  </ButtonLink>
                </div>
              )
            }
          </div>
        </animated.div> 
      </MobileStatementContainer>
    </StatementItemContainer>
  );

  return (
    <GroupStatementRowContainer
      key={ _id }
      className={ `${className} ${selected ? 'selected' : 'unselected'}` }
    >
      { renderItem() }
    </GroupStatementRowContainer>
  );
};

const GroupStatementRowAsObserver = observer(GroupStatementRowBase);
export const GroupStatementRow = withTheme(GroupStatementRowAsObserver);
