import styled from 'styled-components';
import { FlexCol } from '../../../styles/styles';
import { MaxBoundaryContainer, TextSectionContainer } from '../../../styles/components/containers';

export const ArticleWrapper = styled(MaxBoundaryContainer)`
  margin-bottom: 80px;

  .article-body-container {
    margin: 0 auto;
    max-width: 950px;
    width: 90%;
  }
`;

export const ArticleInnerContainer = styled(TextSectionContainer)`
  ${FlexCol}
  padding: 0 15px;

  .shop-link {
    margin-bottom: 60px;
  }

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 0px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-bottom: 0px;
    }
  }

  // Sustainable Cashback Article Specific Styles
  .article-container {
    ${FlexCol}

    h2 {
      margin-top: 0;
    }

    img {
      padding: 10px;
      max-width: 300px;
    }

    @media (${({ theme }) => theme.breakpoints.smMin}) {
      flex-direction: row;
    }
  }
`;
